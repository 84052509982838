import React from 'react';
import PropTypes from 'prop-types';

import {PageContainer} from '@ding/core/src/components/Predefined';

import HeaderHolder from '../HeaderHolder';

import DesktopHeaderToolbar from './DesktopHeaderToolbar';
import HeaderLogo from './HeaderLogo';

const DesktopHeader = ({children, ...props}) => (
  <HeaderHolder role='banner' {...props}>
    <PageContainer style={{display: 'flex', zIndex: 1}} role='presentation'>
      {/* Logos */}
      <HeaderLogo id='Logo' style={{top: 0, display: 'flex', alignItems: 'center'}} />

      {/* Right menu */}
      <DesktopHeaderToolbar />
    </PageContainer>
  </HeaderHolder>
);

DesktopHeader.displayName = 'DesktopHeader';

DesktopHeader.propTypes = {
  truncated: PropTypes.bool, // when fixed
};

export default DesktopHeader;
