import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {TitledCheckbox} from '@ding/controls/src/';

const RememberMeCheckbox = (props) => {
  const t = useTranslate();

  return (
    <TitledCheckbox {...props}>
      {t('website.auth.titles.remember_me')}
    </TitledCheckbox>
  );
};

RememberMeCheckbox.displayName = 'RememberMeCheckbox';

export default RememberMeCheckbox;
