import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  EMAIL_TAKEN_ERR_CODE,
  WRONG_EMAIL_OR_PASS_ERR_CODE,
} from '@ding/auth/src/constants';

import {reactFormat} from '@ding/core/src/helpers';

import {FormSubmitErrors} from '@ding/control-groups/src/Form';
import {
  Text,
  TextButton,
} from '@ding/core/src/components/Predefined';

import AUTH_TABS from '../constants/authTabs';

import ResetPasswordLink from '../../Layout/Links/ResetPasswordLink';

const AuthSubmitErrors = ({email, onChangeModalAuthTab, ...props}) => {
  const codeI18n = useMemo(
    () => ({
      [WRONG_EMAIL_OR_PASS_ERR_CODE]: t => t('website.auth.errors.not_remember_password'),
      [EMAIL_TAKEN_ERR_CODE]: (t) => {
        const loginTriggerProps = {
          weight: 800,
          uppercase: false,
          style: {
            display: 'inline',
          },
        };

        return reactFormat(
          t('website.auth.errors.email_taken'),
          [
            <Text.Primary
              key='email'
              weight={800}
            >
              {email}
            </Text.Primary>,

            <TextButton.Primary
              key='login'
              {...loginTriggerProps}
              onClick={
                () => onChangeModalAuthTab(AUTH_TABS.LOGIN)
              }
            >
              {t('website.auth.titles.login')}
            </TextButton.Primary>,

            <ResetPasswordLink
              key='reset-password'
              weight={800}
            >
              {R.toLower(t('website.auth.titles.remind_password'))}
            </ResetPasswordLink>,
          ],
        );
      },
    }),
    [
      onChangeModalAuthTab,
      email,
    ],
  );

  return (
    <FormSubmitErrors
      {...props}
      codeI18n={codeI18n}
    />
  );
};

AuthSubmitErrors.displayName = 'AuthSubmitErrors';

AuthSubmitErrors.propTypes = {
  email: PropTypes.string,
  onChangeModalAuthTab: PropTypes.func,
};

export default React.memo(AuthSubmitErrors);
