import {useEffect} from 'react';
import {matchPath} from 'react-router';
import * as R from 'ramda';

import useReactRouter from '@ding/core/src/hooks/useReactRouter';

import {prefixWithRootAddress} from '@ding/layout/src/components/Links/utils';
import {useChunksRoutesState} from '@ding/routes/src/ChunksRoutesProvider';
import {createAlternateLinks, queryFieldsIntoRouteParams} from '@ding/i18n/src/languages';

const pickRoute = (routings, urlPath) => {
  for (let i = 0; i < routings.length; i++) {
    const {path, exact, strict} = routings[i];
    const match = matchPath(urlPath, {
      path, exact, strict,
    });

    if (match) {
      return {
        ...routings[i],
        match,
      };
    }
  }
  return undefined;
};

const AlternateLinksUpdate = ({queryData}) => {
  const {location: {pathname}} = useReactRouter();
  const {getChunksRoutes} = useChunksRoutesState();
  const routes = R.defaultTo(
    [],
    getChunksRoutes?.(),
  );

  const translatedParams = queryFieldsIntoRouteParams(queryData);

  useEffect(() => {
    /* NOTE: There's CSR only code to udpate <link rel='alternate'/> in <head>
      I used vanilia JS, because react-helmet:
      - disallow to set position in <head> and Google recommend add alternates as high as possible
      - added links at the bottom on <head> and that was really tricky to change
      - wasn't updating old alternates, so every trigger added new link rel
      - and I found this ticket that SEO bots have problems with data-react-helmet attribute
        https://github.com/nfl/react-helmet/issues/79

      CSR support for alternate links:
      - create links if they are missing
        i.e server didn't placed them because first SSR was triggered
        on route where alternate links are not used (like account routes)
      - update links if route changed in CSR
      - remove links if new route don't have any links, but before route change there was old links
    */
    const route = pickRoute(routes, pathname);
    const alternateLinks = createAlternateLinks(route, route?.match, translatedParams);
    if (!alternateLinks.length) {
      const alternateLinksElements = document.head.querySelectorAll('link[rel=\'alternate\'][hrefLang]:not([type])');
      if (alternateLinksElements.length)
        alternateLinksElements.forEach(el => el.remove());
    }

    alternateLinks.forEach(({hrefLang, href}) => {
      const alternate = document.head.querySelector(`link[rel='alternate'][hrefLang='${hrefLang}']:not([type])`);
      if (alternate)
        alternate.setAttribute('href', prefixWithRootAddress(href));
      else {
        const newLinkElement = document.createElement('link');
        newLinkElement.rel = 'alternate';
        newLinkElement.hreflang = hrefLang;
        newLinkElement.href = href;

        document.head.prepend(newLinkElement);
      }
    });
  }, [translatedParams]);

  return null;
};

AlternateLinksUpdate.displayName = 'AlternateLinksUpdate';

export default AlternateLinksUpdate;
