import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {ID_SCHEMA} from '@adretail/schemas';

import env from '@cdd/internal-server/src/constants/env';
import * as idleCallback from '@adretail/basic-helpers/src/async/idleCallback';

import injectScript from '@adretail/basic-helpers/src/async/injectScript';
import asyncOnceFork from '../helper/asyncOnceFork';

const SDK_SCRIPT_ID = 'facebook-jssdk';

const isFBApiLoaded = () => !!document.getElementById(SDK_SCRIPT_ID) && window.FB;

export const asyncLoadFBApi = asyncOnceFork((lang, config) => new Promise(
  (resolve, reject) => {
    if (isFBApiLoaded()) {
      resolve(window.FB);
      return;
    }

    window.fbAsyncInit = async () => {
      delete window.fbAsyncInit;

      try {
        const {FB} = window;
        FB.init(config);
        resolve(FB);
      } catch (e) {
        reject(e);
      }
    };

    injectScript(`https://connect.facebook.net/${lang}/sdk.js`).setAttribute('id', SDK_SCRIPT_ID);
  },
));

const checkGrantedScopesPermissions = (scopes, grantedScopes) => R.all(
  R.contains(R.__, grantedScopes),
  scopes,
);

const apiLocalizations = {
  pl: 'pl_PL',
  uk: 'uk_UA',
};

/**
 * Component that provides FB API helpers
 *
 * @export
 */
export default class FBApiConsumer extends React.Component {
  static propTypes = {
    appId: ID_SCHEMA,
    scopes: PropTypes.arrayOf(PropTypes.string),
    lang: PropTypes.string,
    loadOnMount: PropTypes.bool,
  };

  static defaultProps = {
    appId: env.client?.facebook?.appId,
    scopes: ['email', 'public_profile'], // use: user_location ?
    lang: apiLocalizations[APP_LANGUAGE] ?? apiLocalizations.pl,
    loadOnMount: true,
  };

  state = {
    error: false,
    loading: !isFBApiLoaded(),
  };

  componentDidMount() {
    const {loadOnMount} = this.props;
    const {loading} = this.state;

    if (loadOnMount && loading)
      idleCallback.create(::this.asyncLoadScript);
  }

  loginUser = reRequest => new Promise(
    async (resolve, reject) => {
      const {scopes} = this.props;
      const FB = await this.asyncLoadScript();

      const parseFbResponse = (response) => {
        const {status, authResponse} = response;
        if (!R.isNil(authResponse.grantedScopes) && status === 'connected') {
          if (checkGrantedScopesPermissions(scopes, R.split(',', authResponse.grantedScopes)))
            resolve(authResponse);
          else {
            // loop
            this
              .loginUser(true)
              .then(resolve)
              .catch(reject);
          }
        } else
          reject(response);
      };

      const loginRequest = () => {
        FB.login(
          parseFbResponse,
          {
            scope: R.join(',', scopes) || undefined,
            return_scopes: true,
            ...reRequest === true && {
              auth_type: 'rerequest',
            },
          },
        );
      };

      if (reRequest === true)
        loginRequest();
      else {
        FB.getLoginStatus((statusResponse) => {
          if (statusResponse.status === 'connected')
            resolve(statusResponse.authResponse);
          else
            loginRequest();
        });
      }
    },
  );

  apiCall = (path, method, params) => new Promise(
    async (resolve) => {
      const FB = await this.asyncLoadScript();
      FB.api(path, method, params, resolve);
    },
  );

  /**
   * @returns {FB}
   */
  async asyncLoadScript() {
    const {appId, lang} = this.props;

    if (isFBApiLoaded())
      return window.FB;

    try {
      const handler = await asyncLoadFBApi(
        lang,
        {
          appId,
          status: true,
          cookie: true,
          xfbml: true,
          oauth: true,
          version: 'v3.2',
        },
      );

      this.setState(
        {
          loading: false,
        },
      );

      return handler;
    } catch (e) {
      this.setState(
        {
          error: true,
          loading: false,
        },
      );
    }

    return null;
  }

  render() {
    const {children} = this.props;
    const {
      error,
      loading,
    } = this.state;

    return children(
      {
        error,
        loading,
        loginUser: this.loginUser,
        apiCall: this.apiCall,
      },
      window?.FB,
    );
  }
}
