import React, {useState} from 'react';

import {DARK, WHITE, LIGHT_CARD_GRAY} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import outsideClick from '@adretail/basic-decorators/src/events/outsideClick';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {useTrackers} from '@ding/interia';

import ModalHolder from '@adretail/basic-components/src/BasicModal/ModalHolder';
import ModalPortal from '@adretail/basic-components/src/BasicModal/ModalPortal';

import {ExpandedSearchAutocomplete, ExpandedAutocompleteDropdown} from '@ding/search/src/components';
import {Flex, Text} from '@ding/core/src/components/Predefined';
import {LeafletsGrid} from '@ding/cards/src/Leaflet/Grid';

import useCurrentlyGridVisibleLeaflet from '@ding/slide-viewer/src/Viewer/containers/Desktop/Sidebars/ViewerLeafletsSidebarContainer/hooks/useCurrentlyGridVisibleLeaflet';
import SelectedForUserSidebarChunksList from '@ding/slide-viewer/src/Viewer/containers/Desktop/Sidebars/ViewerLeafletsSidebarContainer/SelectedForUserSidebarChunksList';

import {floatingButtonShadow} from '@ding/core/src/styles/zDepth';
import SearchIcon from '@ding/core/src/icons/Search';

import ToolbarButton from '../ToolbarButton';

const RECOMMENDED_HEADER_HEIGHT = 50;

const ViewerSidebarAutocomplete = styled(
  ExpandedSearchAutocomplete,
  {
    width: 370,
    color: DARK,
    padding: 4,

    '& input': {
      height: 40,
      background: LIGHT_CARD_GRAY,
      borderRadius: 24,
    },
  },
);

export const SearchLeafletsGrid = provideProps(
  ({itemProps}) => ({
    itemProps: {
      spaced: false,
      showTitles: false,
      showDate: true,
      dateFormat: 'numeric',
      size: 'tiny',
      imageFit: 'cover',
      ...itemProps,
    },
    padding: 'small',
    gridConfig: {
      xs: 4,
    },
  }),
  true,
)(LeafletsGrid);

SearchLeafletsGrid.displayName = 'SearchLeafletsGrid';

const ExpandedEmptyAutocompleteDropdown = (props) => {
  const currentlyVisibleLeafletInfo = useCurrentlyGridVisibleLeaflet();

  return (
    <>
      <ExpandedAutocompleteDropdown
        {...props}
        animStyle={{
          boxShadow: 'initial',
          paddingInline: 20,
        }}
      >
        <SelectedForUserSidebarChunksList
          insert={{}}
          currentlyVisibleLeaflet={currentlyVisibleLeafletInfo}
          leafletsGrid={SearchLeafletsGrid}
        />
      </ExpandedAutocompleteDropdown>
    </>
  );
};

const ExpandedAutocompleteDropdownNoShadow = props => (
  <ExpandedAutocompleteDropdown
    {...props}
    animStyle={{boxShadow: 'initial'}}
  />
);

export const SearchSubHeader = () => {
  const t = useTranslate();

  return (
    <Flex.Centered
      style={{height: RECOMMENDED_HEADER_HEIGHT, color: DARK}}
    >
      <Text
        weight={600}
        center
        uppercase
        block
      >
        {t('website.viewer.info.worth_to_see')}
      </Text>
    </Flex.Centered>
  );
};

const SearchContainerWrapper = styled.div({
  background: WHITE,
  extend: floatingButtonShadow,
  position: 'absolute',
  right: 0,
  top: 'calc(-50% + 9px)',
  zIndex: 1001,
});

const SearchContainer = outsideClick(React.forwardRef((_, ref) => {
  const [empty, setEmpty] = useState(true);

  return (
    <SearchContainerWrapper ref={ref}>
      <ViewerSidebarAutocomplete
        flat
        resetOnSelect={false}
        showPopupWhenEmptyPhrase
        dropdownContentMargin={empty ? RECOMMENDED_HEADER_HEIGHT : 0}
        onValueChange={(v) => {
          setEmpty(v.length === 0);
        }}
        dropdownComponent={empty
          ? ExpandedEmptyAutocompleteDropdown
          : ExpandedAutocompleteDropdownNoShadow}
      />
      <SearchSubHeader />
    </SearchContainerWrapper>
  );
}));

const SearchButton = (props) => {
  const {ga4} = useTrackers();
  const [toggled, setToggled] = useState(false);
  const modalPortalSelector = () => document.querySelector('#viewer-content-slider') || document.body;

  return (
    <>
      <ToolbarButton
        onClick={() => {
          setToggled(true);
          ga4.openSearch();
        }}
        icon={(
          SearchIcon
        )}
        {...props}
      >
        {toggled && (
          <>
            <ModalPortal portalParentSelector={modalPortalSelector}>
              <ModalHolder
                in
                style={{background: 'rgba(0, 0, 0, 0.5)', zIndex: 1}}
              />
            </ModalPortal>

            <SearchContainer
              onOutsideClick={() => setToggled(false)}
            />
          </>
        )}
      </ToolbarButton>
    </>
  );
};

export default SearchButton;
