import React from 'react';
import PropTypes from 'prop-types';

import parents from '@adretail/basic-helpers/src/dom/parents';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {useUA} from '@adretail/basic-components/src/Context/UAProvider';

export const HOVERABLE_SCROLLBAR_TAG = 'hoverable-scrollbar';

export const getParentScrollableTag = node => node && parents(
  HOVERABLE_SCROLLBAR_TAG,
  node,
);

const HoverableHolder = styled(
  HOVERABLE_SCROLLBAR_TAG,
  {
    display: 'block',
    width: '100%',
    height: '100%',
    visibility: 'hidden',
    overflow: 'auto',

    '& > *': {
      visibility: 'visible',
      minHeight: '100%',
    },

    '&:hover': {
      visibility: 'visible',
    },
  },
  {
    classPropName: 'class',
  },
);

const HoverableScrollbarContainer = ({children, wrapChildren, className}) => {
  const ua = useUA();
  let content = children;

  if (wrapChildren) {
    content = (
      <div>
        {children}
      </div>
    );
  }

  return (
    <HoverableHolder
      {...ua.mobile && {
        overflow: 'initial',
      }}
      className={className}
    >
      {content}
    </HoverableHolder>
  );
};

HoverableScrollbarContainer.displayName = 'HoverableScrollbarContainer';

HoverableScrollbarContainer.propTypes = {
  wrapChildren: PropTypes.bool,
};

HoverableScrollbarContainer.defaultProps = {
  wrapChildren: true,
};

export default HoverableScrollbarContainer;
