import React from 'react';

import AUTH_TABS from '@ding/auth/src/client/AuthModal/constants/authTabs';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {Button} from '@ding/controls/src/';
import AuthResponsiveModalToggle from '../AuthResponsiveModalToggle';

const LoginButton = ({children, ...props}) => {
  const t = useTranslate();

  return (
    <AuthResponsiveModalToggle
      triggerComponent={Button.Primary}
      initialTab={AUTH_TABS.LOGIN}
      {...props}
    >
      {children || t('website.auth.titles.login')}
    </AuthResponsiveModalToggle>
  );
};

LoginButton.displayName = 'LoginButton';

export default LoginButton;
