import React from 'react';
import createIcon from '../utils/createIcon';

export default createIcon(
  'Alerts',
  {
    viewBox: '0 0 22.949 24',
  },
  <>
    <path d='M30.5,24.664c-.03-.021-3.063-2.26-3.063-9.572a7.983,7.983,0,0,0-15.961,0c0,7.3-3.024,9.546-3.051,9.565a.97.97,0,0,0-.4,1.1.99.99,0,0,0,.947.692H29.946a.983.983,0,0,0,.56-1.792M19.453,9.152a5.974,5.974,0,0,1,6,5.935,16.713,16.713,0,0,0,2.3,9.4h-16.6a16.7,16.7,0,0,0,2.3-9.4,5.975,5.975,0,0,1,5.995-5.935' transform='translate(-7.982 -7.297)' />
    <path d='M26.166,37.728a1.034,1.034,0,0,0-1.41.374,1.4,1.4,0,0,1-2.411,0,1.023,1.023,0,0,0-.627-.482,1.012,1.012,0,0,0-.775.11,1.028,1.028,0,0,0-.376,1.41h0a3.446,3.446,0,0,0,5.968,0,1.032,1.032,0,0,0-.374-1.41' transform='translate(-12.081 -16.862)' />
  </>,
);
