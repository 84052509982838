import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import * as R from 'ramda';

import {
  PROPS_SCHEMA,
  REACT_COMPONENT_CLASS_SCHEMA,
} from '@adretail/schemas';

import {
  DARK,
  WHITE,
  CARD_GRAY,
  LIGHT_BORDER,
  DARK_BORDER,
  PRIMARY_FILL,
  PRIMARY_FILL_DIVIDER,
} from '@ding/constants/src/colorSchema';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import suppressEvent from '@adretail/basic-helpers/src/inputs/suppressEvent';

import TimesIcon from '@ding/core/src/icons/Times';
import IconButton from '@ding/controls/src/Button/IconButton';
import {PlainUnorderedList} from '@ding/core/src/components/Predefined';

const DEFAULT_HEADER_PADDING = 20;

const css = {
  header: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: [0, DEFAULT_HEADER_PADDING],
    minHeight: 56,
    borderBottom: '1px solid',
    lineHeight: '14px',
  },

  noHeaderPadding: {
    padding: 0,
  },

  primary: {
    background: PRIMARY_FILL,
    borderColor: PRIMARY_FILL_DIVIDER,
    color: WHITE,
  },

  light: {
    background: WHITE,
    borderColor: LIGHT_BORDER,
    color: DARK,
  },

  dark: {
    background: DARK,
    borderColor: DARK_BORDER,
    color: WHITE,
  },

  gray: {
    background: CARD_GRAY,
    borderColor: LIGHT_BORDER,
  },

  none: {
    background: 'none',
    borderColor: 'inherit',
    color: 'inherit',
  },

  title: {
    display: 'inline-block',
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 700,
  },

  leftToolbar: {
    marginRight: 20,

    '&:empty': {
      display: 'none',
    },
  },

  rightToolbar: {
    marginLeft: 'auto',
    marginRight: 'initial',
  },

  noTitleStylesClose: {
    marginRight: DEFAULT_HEADER_PADDING,
  },
};

const TitledSidebarHeader = ({
  style, children, classes, rightToolbar,
  type, noTitleStyles, leftTopHeaderButtons,
  closeButtonProps,
  closeButtonComponent: CloseButtonComponent,
  onClose,
}) => {
  let closeButton = null;

  const buttonSharedProps = {
    onClick: (e) => {
      suppressEvent(e);
      onClose();
    },

    ...closeButtonProps,
  };

  if (CloseButtonComponent) {
    closeButton = (
      <CloseButtonComponent {...buttonSharedProps} />
    );
  } else if (onClose) {
    closeButton = (
      <IconButton
        type={(
          type === 'primary' || type === 'dark'
            ? 'light'
            : 'dark'
        )}
        iconSize='small'
        icon={TimesIcon}
        {...buttonSharedProps}
      />
    );
  }

  return (
    <div
      style={style}
      className={c(
        classes.header,
        classes[type || 'light'],
        noTitleStyles && classes.noHeaderPadding,
      )}
    >
      {leftTopHeaderButtons && (
        <span className={classes.leftToolbar}>
          {leftTopHeaderButtons}
        </span>
      )}
      {(
        noTitleStyles
          ? children
          : (
            <span className={classes.title}>
              {children}
            </span>
          )
      )}

      {(closeButton || rightToolbar) && (
        <PlainUnorderedList
          inline
          itemSpace='medium'
          align='center'
          className={c(
            classes.rightToolbar,
            noTitleStyles && classes.noTitleStylesClose,
          )}
        >
          {rightToolbar && (
            <li>
              {rightToolbar}
            </li>
          )}

          <li>
            {closeButton}
          </li>
        </PlainUnorderedList>
      )}
    </div>
  );
};

TitledSidebarHeader.displayName = 'TitledSidebarHeader';

TitledSidebarHeader.propTypes = {
  closeButtonProps: PROPS_SCHEMA,
  closeButtonComponent: REACT_COMPONENT_CLASS_SCHEMA,

  leftTopHeaderButtons: PropTypes.node,
  noTitleStyles: PropTypes.bool,
  type: PropTypes.oneOf([
    'light',
    'primary',
    'gray',
    'dark',
    'none',
  ]),
  onClose: PropTypes.func,
};

TitledSidebarHeader.defaultProps = {
  type: 'primary',
};

export default R.compose(
  React.memo,
  injectClassesSheet(css, {index: 4}),
)(TitledSidebarHeader);
