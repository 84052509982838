import React, {useState, useEffect} from 'react';
import c from 'classnames';

import {WHITE, ICON_BUTTON_COLOR} from '@ding/constants/src/colorSchema';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import mountElementEvents from '@adretail/basic-helpers/src/dom/mountElementEvents';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import GoToPageTop from '@ding/core/src/icons/GoToPageTop';
import {RectangleIconButton} from '@ding/controls/src';

const css = {
  '@keyframes btnSlide': {
    '0%': {
      opacity: '0',
      transform: 'translateY(100px)',
    },

    '100%': {
      opacity: '1',
      transform: 'translateY(0)',
    },
  },
  '@keyframes btnSlideLeave': {
    '0%': {
      opacity: '1',
      transform: 'translateY(0)',
    },

    '100%': {
      opacity: '0',
      transform: 'translateY(100px)',
    },
  },

  button: {
    position: 'fixed',
    bottom: 'calc(env(safe-area-inset-bottom, 0) + 65px)', // because of home bar on ios add extra bottom spacing
    right: 10,
    zIndex: 998,
    padding: '0 8.5px',
    backgroundColor: WHITE,
    transition: 'visibility 250ms ease-in-out',

    '& i': {
      width: 30,
      height: 30,
    },
    '& svg': {
      color: ICON_BUTTON_COLOR,
    },
  },

  buttonAnimationEnter: {
    animation: 'btnSlide 300ms ease-in-out',

  },
  buttonAnimationLeave: {
    animation: 'btnSlideLeave 300ms ease-in-out',
  },
};

const GoToPageTopButton = ({classes}) => {
  const [visible, setVisible] = useState(false);
  const t = useTranslate('website.shared.buttons');

  useEffect(
    () => mountElementEvents(
      true,
      {
        scroll: () => setVisible(window.pageYOffset > 350),
      },
      window,
    ),
    [],
  );

  const scrollToTop = () => {
    if (visible)
      window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return (
    <RectangleIconButton
      style={{visibility: visible ? 'visible' : 'hidden'}}
      className={c(
        classes.button,
        classes[`buttonAnimation${visible ? 'Enter' : 'Leave'}`],
      )}
      icon={GoToPageTop}
      iconOnly
      onClick={scrollToTop}
      title={t('scroll_to_top')}
      aria-label={t('scroll_to_top')}
    />
  );
};

export default injectClassesSheet(css)(GoToPageTopButton);
