import React from 'react';
import PropTypes from 'prop-types';

import rootApiGqlClient from '@ding/api/src/clients/rootApiGqlClient';

import {
  TinyGqlClient,
  TinyGqlProvider,
} from '@ding/tiny-gql/src/react';

import createAuthorizationHeaders from '@ding/auth/src/helpers/createAuthorizationHeaders';
import {IntervalRefreshToken} from '@ding/auth/src/gql/components';
import {
  AuthStateProvider,
  useAuthState,
} from '@ding/auth/src/client/AuthContext';

import {CreateDefaultUserToken} from '@ding/auth/src/gql/components/CreateGuestUserMutation';

import {NsfwProvider} from '@ding/control-groups/src/Nsfw';
import {TempFavouritesStateProvider} from '@ding/notifications/src/context/TempFavouritesState';
import {TempShoppingListStateProvider} from '@ding/shopping-list/src/context/TempShoppingListState';
import {GeolocationStateProvider} from '@ding/geolocation/src/client/components/GeolocationContext';

import TrackersProvider from '@ding/interia/src/context/TrackersProvider';
import AbTestsProvider from '@ding/features/src/AbTestsProvider';
import {ProvideDingFeatures} from '@ding/features';
import {ChunksRoutesProvider} from '@ding/routes/src/ChunksRoutesProvider';

const AuthGQLProvider = ({children}) => {
  const {getToken} = useAuthState();

  return (
    <TinyGqlProvider
      client={
        TinyGqlClient.cloneWithHeaders(
          rootApiGqlClient,
          createAuthorizationHeaders(
            getToken(),
          ),
          {
            batching: false,
          },
        )
      }
    >
      <>
        <CreateDefaultUserToken />
        <IntervalRefreshToken />
        {children}
      </>
    </TinyGqlProvider>
  );
};

const PageProviders = ({children, features, abTests}) => {
  let providers = (
    <AuthStateProvider>
      <NsfwProvider>
        {() => (
          <ChunksRoutesProvider>
            <TrackersProvider>
              <GeolocationStateProvider>
                <TempFavouritesStateProvider>
                  <TempShoppingListStateProvider>
                    <AuthGQLProvider>
                      {children}
                    </AuthGQLProvider>
                  </TempShoppingListStateProvider>
                </TempFavouritesStateProvider>
              </GeolocationStateProvider>
            </TrackersProvider>
          </ChunksRoutesProvider>
        )}
      </NsfwProvider>
    </AuthStateProvider>
  );

  if (features) {
    providers = (
      <ProvideDingFeatures features={features}>
        {providers}
      </ProvideDingFeatures>
    );
  }

  if (abTests) {
    providers = (
      <AbTestsProvider tests={abTests}>
        {providers}
      </AbTestsProvider>
    );
  }

  return providers;
};

PageProviders.displayName = 'PageProviders';

PageProviders.propTypes = {
  features: PropTypes.any,
};

export default PageProviders;
