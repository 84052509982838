import React from 'react';
import PropTypes from 'prop-types';

import {
  WHITE,
  DARK,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {Flex} from '@ding/core/src/components/Predefined';

import IconButton from './IconButton';

export const CountTooltip = styled(
  Flex.Centered,
  {
    position: 'absolute',
    right: -6,
    top: -6,
    minWidth: 16,
    height: 16,
    borderRadius: 16,
    padding: 2,
    background: DARK,
    color: WHITE,
    fontSize: 10,
    lineHeight: '14.5px',
  },
);

/**
 * Icon with number in right top cornet
 *
 * @export
 */
const CounterIconButton = ({count, ...props}) => (
  <IconButton {...props}>
    {count > 0 && (
      <CountTooltip>
        {count}
      </CountTooltip>
    )}
  </IconButton>
);

CounterIconButton.displayName = 'CounterIconButton';

CounterIconButton.propTypes = {
  count: PropTypes.number,
};

export default React.memo(CounterIconButton);
