import leafletsDescription from '../docs/leafletsDescription.txt';

export default {
  title: 'Gazetki promocyjne',

  section: {
    title: 'Gazetki',
    header: 'Gazetki promocyjne - aktualne oferty',
    category_header: 'Gazetki promocyjne: %{}',
    description: leafletsDescription,
  },

  meta: {
    title: 'Gazetki promocyjne - Aktualne oferty sklepów%{pageSuffix}%{domainSuffix}',
    description: 'Zobacz aktualne gazetki promocyjne i reklamowe sieci handlowych. Sprawdź aktualne i nadchodzące promocje w sklepach!',

    category_title: '%{categoryName} - Aktualne gazetki promocyjne%{pageSuffix}%{domainSuffix}',
    category_description: 'Zobacz aktualne gazetki promocyjne z kategorii: %{categoryName}. Sprawdź aktualne promocje w sklepach',
  },
};
