import PropTypes from 'prop-types';

import renderFunctionalChildren from '@adretail/basic-helpers/src/ui/renderFunctionalChildren';
import useAdblockCheck from '../hooks/useAdblockCheck';

const AdblockDetect = ({whitelist, hideDuringCheck, children}) => {
  const adblockStatus = useAdblockCheck(whitelist || {});

  if (!hideDuringCheck || adblockStatus !== null) {
    return renderFunctionalChildren(
      children,
      adblockStatus,
    );
  }

  return null;
};

AdblockDetect.displayName = 'AdblockDetect';

AdblockDetect.propTypes = {
  hideDuringCheck: PropTypes.bool,
  whitelist: PropTypes.shape(
    {
      adblockPlus: PropTypes.bool,
    },
  ),
};

AdblockDetect.defaultProps = {
  hideDuringCheck: true,
  whitelist: {
    adblockPlus: true,
  },
};

export default AdblockDetect;
