import React, {useState, useEffect, useRef} from 'react';

import {WHITE} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {popupZDepth} from '@ding/core/src/styles/zDepth';
import fullLayer from '@ding/core/src/styles/fullLayer';

import useMounted from '@adretail/basic-hooks/src/base/useMounted';

import {useDingFeatures} from '@ding/features';
import SlideToUpAppear from '@adretail/basic-components/src/Anim/SlideToUpAppear';
import EUBadgeImage from './EUBadgeImage';

/**
 * component (EUFloatingImage) don't used anymore, see issue: #30635
 *
 * To use in Home/index.jsx:
 * const ua = useUA();
 * {!ua.mobile && (
 *   <LazyHydrate whenIdle>
 *     <EUFloatingImage />
 *   </LazyHydrate>
 * )}
 *
 * useHideBadgeOnScroll is still used in IOSPinApp.jx as logic
 *   for showing badge about PWA app
 */

export const useHideBadgeOnScroll = (defaultToggle = true, onToggle) => {
  const [toggled, setToggled] = useState(defaultToggle);
  const oldPageYOffsetRef = useRef(null);
  const scrollDeltaRef = useRef(0);

  useEffect(
    () => {
      const onScroll = () => {
        const {pageYOffset} = window;

        if (!oldPageYOffsetRef.current)
          oldPageYOffsetRef.current = pageYOffset;
        else
          scrollDeltaRef.current += Math.abs(window.pageYOffset - oldPageYOffsetRef.current);

        if (scrollDeltaRef.current >= 30) {
          const newToggle = !defaultToggle;

          setToggled(newToggle);
          onToggle?.(newToggle); // eslint-disable-line no-unused-expressions

          document.removeEventListener('scroll', onScroll);
        }
      };

      document.addEventListener('scroll', onScroll);
      return () => {
        document.removeEventListener('scroll', onScroll);
      };
    },
    [],
  );

  return [toggled, setToggled];
};

const EUFloatingImageHolder = styled.div(
  {
    extend: popupZDepth,
    position: 'fixed',
    left: '50%',
    bottom: 0,
    padding: [10, 20],
    background: WHITE,
    borderRadius: [8, 8, 0, 0],
    zIndex: 999,

    '& > div': {
      position: 'relative',
      width: 576,
      maxWidth: 'calc(100vw - 32px)',
      height: 0,
      paddingBottom: '11%',

      '& > img': {
        extend: [fullLayer],
      },
    },
  },
);

const EUFloatingImage = () => {
  const features = useDingFeatures();
  const mounted = useMounted();
  const [toggled] = useHideBadgeOnScroll();

  if (!mounted || features.disableEuBadge)
    return null;

  return (
    <SlideToUpAppear
      appear={false}
      in={toggled}
    >
      {style => (
        <EUFloatingImageHolder
          style={{
            ...style,
            transform: `${style.transform || ''} translateX(-50%)`,
          }}
        >
          <div>
            <EUBadgeImage expanded />
          </div>
        </EUFloatingImageHolder>
      )}
    </SlideToUpAppear>
  );
};

export default EUFloatingImage;
