import * as R from 'ramda';

/**
 * change attachment class in image url
 * @param {string} replacement new attachment class, replacement string
 * @param {string} str image src
 * @returns new string with changed image attachment framing class
 */
const changeImageClass = R.replace(/C\d{1,3}(?=[^C]*$)/);

export default changeImageClass;
