import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {DescriptionFormRow} from '@ding/control-groups/src/Form/Groups';

const CompanyIdentifiersGroup = () => {
  const t = useTranslate();

  return (
    <DescriptionFormRow {...t('website.contact_info.company_identifiers')} />
  );
};

CompanyIdentifiersGroup.displayName = 'CompanyIdentifiersGroup';

export default CompanyIdentifiersGroup;
