import * as FAVOURITE_TYPES from '../constants/favouriteTypes';

/**
 * Somet types in search box contains kind=TAG,
 * it is generally CUSTOM type. Do not use ramda
 * and other stuff here, single if is faster
 *
 * @export
 */
const castToFavouriteKind = kind => (
  kind === FAVOURITE_TYPES.CONTRACTOR
    ? kind
    : FAVOURITE_TYPES.CUSTOM
);

export default castToFavouriteKind;
