import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import c from 'classnames';

import {PRIMARY_FILL} from '@ding/constants/src/colorSchema';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';

import useReactRouter from '../../hooks/useReactRouter';
import Text from './Text';

export const isAbsoluteLink = url => !!url && url.indexOf && url.indexOf('http') === 0;

const ReplaceStateLink = ({to, children, ...props}) => {
  const reactRouter = useReactRouter();

  return (
    <div
      {...props}
      onClick={
        () => {
          reactRouter?.history.replace(to);
        }
      }
    >
      {children}
    </div>
  );
};

const css = {
  base: {
    textDecoration: 'none',
    color: 'inherit',
  },

  block: {
    display: 'block',
  },

  accented: {
    '&:hover': {
      color: PRIMARY_FILL,
    },
  },

  inline: {
    textDecoration: 'underline',
    fontWeight: 'bold',
  },

  underlineHover: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

const BlankLinkComponent = forwardRef(({
  to, block, children, plainAnchor,
  type, size, weight, underlineHover,
  uppercase, replaceState, classes, className,
  accented, inline, awaitPromise, onClick,
  ...props
}, ref) => {
  const reactRouter = useReactRouter(); // NOTE: don't deconstruct this in some elements useReactRouter return undefined
  className = c(
    className,
    classes.base,
    underlineHover && classes.underlineHover,
    accented && classes.accented,
    block && classes.block,
    inline && classes.inline,
  );

  const textProps = (type || size || weight || uppercase) && {
    uppercase,
    type,
    size,
    weight,
  };

  const content = (
    textProps
      ? <Text {...textProps}>{children}</Text>
      : children
  );

  if (replaceState) {
    return (
      <ReplaceStateLink
        to={to}
        className={className}
        ref={ref}
        {...props}
      >
        {children}
      </ReplaceStateLink>
    );
  }

  if (plainAnchor || isAbsoluteLink(to)) {
    return (
      <a
        ref={ref}
        href={to}
        className={className}
        onClick={onClick}
        {...props}
      >
        {content}
      </a>
    );
  }

  return (
    <Link
      ref={ref}
      className={className}
      {...awaitPromise && {
        onClick: async (e) => {
          /*
            NOTE: awaitPromise is used to load chunk after click on relative link
            if awaitPromise will not be provided, chunk still will be downloaded
            but react-loadable will create LoadingSpinner component (bad ux expierence)
            See: @ding/layout/src/components/Links/utils/createLinkComponent
          */
          e.preventDefault();
          const loadChunk = awaitPromise();
          const {preventRedirect} = await onClick?.(e) || {};
          await loadChunk;
          if (!preventRedirect) {
            reactRouter?.history.push(to);
          }
        },
      }}
      {...!awaitPromise && onClick && {
        onClick,
      }}
      to={to}
      {...props}
    >
      {content}
    </Link>
  );
});

BlankLinkComponent.displayName = 'BlankLinkComponent';

BlankLinkComponent.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  underlineHover: PropTypes.bool,
  accented: PropTypes.bool,
  inline: PropTypes.bool,
};

export default injectClassesSheet(css)(BlankLinkComponent);
