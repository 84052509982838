import React from 'react';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import {trackComponent} from '@ding/interia';

import ContractorsLink from '@ding/layout/src/components/Links/ContractorsLink';
import StoreIcon from '@ding/core/src/icons/navbar/Shops';
import IconButton from '@ding/controls/src/Button/IconButton';

import {HeaderItem} from '../../HeaderToolbar';

const StoresIcon = ({subtitle, margin, onClick}) => (
  <li>
    <HeaderItem
      subtitle={subtitle.name}
      title={subtitle.title}
      aria-label={subtitle.title}
      margin={margin}
      component={IconButton}
      componentProps={{
        link: provideProps({injectOnHover: true, onClick})(ContractorsLink),
        icon: StoreIcon,
        type: 'light',
        iconProps: {
          size: 'big',
        },
      }}
    />
  </li>
);

StoresIcon.displayName = 'StoresIcon';

export default trackComponent(
  {
    onClick({ga4}) {
      ga4.menuClickShops();
    },
  },
)(StoresIcon);
