import React from 'react';
import PropTypes from 'prop-types';

import {LEAFLET_CARD_SCHEMA} from '@ding/constants/src/typeSchema';

import textEllipsisOverflow from '@ding/core/src/styles/textEllipsisOverflow';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import DateDurationText from '@ding/core/src/components/Predefined/DateDurationText';

import {CardHoverActiveTitle} from '../../CardHolder';

const css = {
  base: {
    extend: textEllipsisOverflow,

    display: 'inline-block',
    width: '100%',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: 13,
    textAlign: 'center',
  },
};

const LeafletDurationBar = ({classes, dateFormat, leaflet, ...props}) => (
  <>
    <CardHoverActiveTitle>
      <DateDurationText
        className={classes.base}
        duration={leaflet.duration}
        format={dateFormat}
        withYear={dateFormat !== 'numeric'}
        forceSeparator=' - '
        {...props}
      />
    </CardHoverActiveTitle>
  </>
);

LeafletDurationBar.displayName = 'LeafletDurationBar';

LeafletDurationBar.propTypes = {
  leaflet: LEAFLET_CARD_SCHEMA.isRequired,
  dateFormat: PropTypes.string,
};

export default injectClassesSheet(css)(LeafletDurationBar);
