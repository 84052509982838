import React from 'react';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';

import {Text} from '@ding/core/src/components/Predefined';

const SeparatorItem = styled.li(
  {
    display: 'inline-block',
    fontSize: 22,

    ...createBreakpointClasses({
      xs: {
        margin: '0 10px',
      },

      md: {
        margin: '0 25px',
      },
    }),
  },
);

const PaginationTruncateItem = () => (
  <SeparatorItem>
    <Text.Muted>
      ...
    </Text.Muted>
  </SeparatorItem>
);

PaginationTruncateItem.displayName = 'PaginationTruncateItem';

export default PaginationTruncateItem;
