import {gql, GraphQLFragment} from '@ding/tiny-gql';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import DefaultTinyQuery from '@ding/control-groups/src/DefaultTinyQuery';
import useQuery from '@ding/tiny-gql/src/react/hooks/useQuery';

import TagFragment from '@ding/api/src/gql/fragments/Tag.graphql';
import ProductCardFragment from '@ding/api/src/gql/fragments/ProductCard.graphql';
import ContractorFragment from '@ding/api/src/gql/fragments/Contractor.graphql';
import CustomerFlagsFragment from '@ding/api/src/gql/fragments/CustomerFlags.graphql';

import SearchDataFragment from './fragments/SearchData.graphql';

export const PAGE_SEARCH_QUERY = new GraphQLFragment(
  'pageSearchQuery',
  gql`
    ${TagFragment}
    ${ContractorFragment}
    ${SearchDataFragment}
    ${CustomerFlagsFragment}
    ${ProductCardFragment}
    query pageSearchQuery(
      $segmentLimit: Int = 10,
      $phrase: String!,
      $includeWithoutLeaflets: Boolean = true,
      $withProducts: Boolean = false,
      $withContractors: Boolean = false,
      $withTags: Boolean = true,
    ) @locale(lang: ${APP_LANGUAGE}) {
      # list is primary used by GQLAutocomplete components
      # to pick values list from different queries
      list: pageSearch(phrase: $phrase) {
        ...SearchData
      }
    }
  `,
);

export const PageSearchQuery = provideProps(
  ({variables}) => ({
    query: PAGE_SEARCH_QUERY,
    responseSelector: 'list',
    variables: {
      segmentLimit: 10,
      ...variables,
    },
  }),
  true,
)(DefaultTinyQuery);

export const usePageSearchQuery = params => useQuery(
  PAGE_SEARCH_QUERY,
  {
    responseSelector: 'list',
    ...params,
  },
);

export default PAGE_SEARCH_QUERY;
