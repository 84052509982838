import styled from '@adretail/fast-stylesheet/src/react/styled';
import {Flex} from '@ding/core/src/components/Predefined';

const ExpandSectionBar = styled(
  Flex.Centered,
  {
    width: '100%',
    marginTop: 20,

    '&:empty': {
      margin: 0,
    },
  },
);

ExpandSectionBar.displayName = 'ExpandSectionBar';

export default ExpandSectionBar;
