import React from 'react';
import PropTypes from 'prop-types';

import PenIcon from '../../../icons/Pen';
import RoundedBox from '../RoundedBox';
import ExpandedImage from './ExpandedImage';

const RoundedIconBox = ({
  className,
  src,
  alt,
  defaultIcon,
  size,
  muted,
  color,
}) => (
  <RoundedBox
    {...{
      className,
      size,
      color,
    }}
  >
    {
      src
        ? (
          <ExpandedImage
            src={src}
            alt={alt}
            muted={muted}
          />
        )
        : defaultIcon
    }
  </RoundedBox>
);

RoundedIconBox.displayName = 'RoundedIconBox';

RoundedIconBox.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,

  color: PropTypes.string,
  size: PropTypes.string,
  muted: PropTypes.bool,

  defaultIcon: PropTypes.node,
};

RoundedIconBox.defaultProps = {
  muted: false,
  color: 'gray',
  defaultIcon: (
    <PenIcon
      size='small'
      type='light'
    />
  ),
};

export default RoundedIconBox;
