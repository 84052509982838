import React from 'react';

import {ERRORS_LIST_SCHEMA} from '@ding/constants/src/typeSchema';

import LinkedForm from '@adretail/basic-components/src/Form/LinkedForm';
import AsyncSpinnerControl from '@ding/controls/src/AsyncSpinnerControl';

import {Margin} from '@ding/core/src/components/Predefined';
import {ValidationGroupProvider} from '@ding/controls/src/Input/ValidatedInput';

import FormSubmitErrors from './FormSubmitErrors';

const AsyncSpinnerForm = ({errors, ...props}) => (
  <ValidationGroupProvider>
    <AsyncSpinnerControl
      controlComponent={LinkedForm}
      asyncHandlers={[
        'beforeSubmitValueMapper',
        'onSubmit',
      ]}
      errors={
        errors && (
          <Margin
            top={4}
            expanded
            block
          >
            <FormSubmitErrors errors={errors} />
          </Margin>
        )
      }
      {...props}
    />
  </ValidationGroupProvider>
);

AsyncSpinnerForm.displayName = 'AsyncSpinnerForm';

AsyncSpinnerForm.propTypes = {
  errors: ERRORS_LIST_SCHEMA,
};

export default AsyncSpinnerForm;
