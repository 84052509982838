import React from 'react';

import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  REACT_COMPONENT_CLASS_SCHEMA,
} from '@adretail/schemas';

import selectResponseData from '@adretail/basic-helpers/src/getters/selectResponseData';
import AsyncSSRComponent from '@adretail/async-cache-utils/src/components/AsyncSSRComponent';

import {CLIENT_CONTEXT_SCHEMA} from '../constants/schema';
import * as FETCH_POLICY from '../constants/fetchPolicy';

import {defaultQueryCacheKey} from './helpers';

import provideClientContext from './decorators/provideClientContext';
import {QueryRefetchStoreItem} from './hooks/useQueryRefetchStore';
import {postHydrateExecutor} from './hooks/useQuery';

export {
  FETCH_POLICY,
};

/**
 * Tiny query executor, it should be used inside AsyncSSRComponent if ssr enabled
 *
 * @export
 */
export default
@provideClientContext
class TinyGqlQuery extends React.Component {
  static propTypes = {
    query: PropTypes.objectOf(PropTypes.any), // document in graphql-tag is placed in plain JSON :(
    skip: PropTypes.bool,

    clientFetchPolicy: PropTypes.oneOf(R.values(FETCH_POLICY)),
    cacheKey: PropTypes.shape({
      // if more than 0 component is cached, otherwise it is just helper to check update
      expire: PropTypes.number,
      name: PropTypes.string, // if provided tiny gql query result might be cached in redis
    }),

    // config used to call graphql function form TinyClient
    // it is optional, if not provided React.Context based client
    // will be used
    clientContext: CLIENT_CONTEXT_SCHEMA,

    // data bassed to query
    // @see: Some systems use `input: {}` based params
    variables: PropTypes.objectOf(PropTypes.any),

    // flags
    allowSSR: PropTypes.bool,
    allowBatching: PropTypes.bool,

    // optimistic value picked from selector
    // if provided - loading option is disabled
    optimisticResponse: PropTypes.objectOf(PropTypes.any),

    // selects data from response
    responseSelector: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
    ]),

    asyncPromiseComponent: REACT_COMPONENT_CLASS_SCHEMA,
    errorComponent: REACT_COMPONENT_CLASS_SCHEMA,
    loadingComponent: REACT_COMPONENT_CLASS_SCHEMA,

    onLoaded: PropTypes.func,
  };

  static defaultProps = {
    allowBatching: true,
    asyncPromiseComponent: AsyncSSRComponent,
    clientFetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
  };

  state = {
    error: false,
  };

  componentDidCatch() {
    this.setState(
      {
        error: true,
      },
    );
  }

  queryExecutor = () => {
    const {
      clientContext,
      cacheKey,
      query,
      variables,
      headers,
      allowBatching,
      clientFetchPolicy,
    } = this.props;

    const queryCacheKey = defaultQueryCacheKey(
      query,
      cacheKey,
      variables,
    );

    return {
      cacheKey: queryCacheKey,

      // promiseFnFlags is provided from createClientContext
      // client might be changed
      // refetchData might call promiseFn with different gql client
      // e.q. when whole tree is not updated yet but user changed token
      promiseFn: ({client, cachePolicy}) => (
        (client || clientContext.client).query(
          query,
          variables,
          headers,
          {
            allowBatching,
            cachePolicy,
            type: clientFetchPolicy,
            key: queryCacheKey.name,
          },
        )
      ),
    };
  };

  /**
   * Loads data provided from hydration to internal
   * client side context cache
   */
  cacheHydrationData = (data, {cacheKey}) => {
    const {
      query,
      variables,
      clientContext: {client},
      clientFetchPolicy,
    } = this.props;

    postHydrateExecutor(
      {
        query,
        client,
        variables,
        data,
        queryCacheKey: cacheKey,
        clientFetchPolicy,
      },
    );
  }

  /**
   * Renders AsyncSSRComponent, creates client if is null and customContext is provided
   *
   * @param {TinyGqlClient} client
   */
  render() {
    const {error} = this.state;
    const {
      skip,
      allowSSR,
      children,
      clientContext,

      // used in store
      variables,
      query,

      // data selectors
      optimisticResponse,
      responseSelector,

      // components
      loadingComponent,
      errorComponent,
      alwaysRenderComponent,
      asyncPromiseComponent: AsyncPromiseComponent,

      onLoaded,
    } = this.props;

    // TinyGQLClient contains CLIENT SIDE CACHE
    const {refetchQuery} = clientContext;
    const {
      cacheKey,
      promiseFn,
    } = this.queryExecutor();

    if (error)
      return null;

    if (skip)
      return children(null, {});

    const renderPromiseResolver = (data, {refetchData, loadData, uuid, ...helpers}) => {
      const notLoaded = (helpers.loading || data === undefined) && optimisticResponse;
      let childs = null;

      // during loading resolves component with optimistic repsonse
      if (notLoaded) {
        childs = children?.(
          optimisticResponse,
          {
            ...helpers,

            // just prevent crashes
            refetchCurrentQuery: R.T,
            refetchQuery: R.T,
          },
        );
      } else {
        childs = children?.(
          selectResponseData(responseSelector, data),
          {
            ...helpers,
            refetchCurrentQuery: async (flags) => {
              const responseData = await refetchQuery(
                query,
                variables,
                {
                  withoutLoadingSpinner: true,
                  ...flags,
                },
              );

              return responseData && selectResponseData(responseSelector, responseData);
            },

            refetchQuery: (...args) => {
              // search store for query and refetches it
              if (args.length)
                return refetchQuery(...args);

              // refetch current query
              return refetchQuery(query, variables);
            },
          },
        );
      }

      return (
        <>
          {!notLoaded && (
            <QueryRefetchStoreItem
              refetchConfig={{
                queryUUID: uuid,
                variables,
                query,
                refetchData,
                loadData,
                clientContext,
              }}
            />
          )}

          {childs}
        </>
      );
    };

    // AsyncPromiseComponent contains SERVER SIDE CACHE
    // used to resolve fields during hydration
    // do not use it as a single cache in client - it might cause race
    // conditions during hydration() phrase
    return (
      <AsyncPromiseComponent
        cacheKey={cacheKey}
        onHydrated={this.cacheHydrationData}
        {...{
          promiseFn, // main loader
          clientContext,
          allowSSR,
          errorComponent,
          alwaysRenderComponent,
          onLoaded,
        }}
        {...(
          optimisticResponse
            ? {
              alwaysRenderComponent: true,
              loadingComponent: null,
            }
            : {
              loadingComponent,
            }
        )}
      >
        {renderPromiseResolver}
      </AsyncPromiseComponent>
    );
  }
}
