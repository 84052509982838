import React, {useState} from 'react';
import c from 'classnames';

import {
  PINKY_PRIMARY_RED,
  WHITE,
  WARN_BOX_COLOR,
  WARN_BOX_BACKGROUND,
  WARN_BOX_BORDER,
  INFO_BOX_BACKGROUND,
  INFO_BOX_BORDER,
  INFO_BOX_COLOR,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';
import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';

import TimesIcon from '@ding/core/src/icons/Times';
import TextButton from '@ding/core/src/components/Predefined/TextButton';

/**
 * @todo Merge with BorderedRoundedBox
 */
const RoundedAlert = styled.div(
  {
    base: {
      margin: [15, 0],
      padding: 15,
      borderRadius: 5,
      lineHeight: '20px',

      '& > strong': {
        display: 'flex',
        alignItems: 'center',
        margin: [0, 3, 10, 0],

        '& i': {
          marginRight: 7,
        },
      },

      '& > div': {
        textAlign: 'center',
      },

      ...createBreakpointClasses(
        {
          xs: {
            fontSize: '12px',
          },
          md: {
            fontSize: '14px',
          },
        },
      ),
    },

    red: {
      color: WHITE,
      background: PINKY_PRIMARY_RED,
    },

    warn: {
      color: WARN_BOX_COLOR,
      background: WARN_BOX_BACKGROUND,
      border: `1px solid ${WARN_BOX_BORDER}`,
    },

    info: {
      color: INFO_BOX_COLOR,
      background: INFO_BOX_BACKGROUND,
      border: `1px solid ${INFO_BOX_BORDER}`,
    },

    noTopSpace: {
      marginTop: 0,
    },

    noBottomSpace: {
      marginBottom: 0,
    },
  },
  {
    omitProps: ['type', 'noTopSpace', 'noBottomSpace'],
    classSelector: (classes, {noTopSpace, noBottomSpace, type = 'danger'}) => [
      classes[type],
      noTopSpace && classes.noTopSpace,
      noBottomSpace && classes.noBottomSpace,
    ],
  },
);

export const CloseableRoundedAlert = injectClassesSheet(
  {
    base: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    content: {
      composes: '$base',
      justifyContent: 'flex-start',
    },

    closeButton: {
      marginLeft: 10,
      color: 'inherit',
    },
  },
)(({children, classes, className, onClose, ...props}) => {
  const [closed, setClosed] = useState();

  if (closed)
    return null;

  return (
    <RoundedAlert
      {...props}
      className={c(
        classes.base,
        className,
      )}
    >
      <div className={classes.content}>
        {children}
      </div>

      <TextButton
        className={classes.closeButton}
        onClick={
          () => {
            setClosed(true);
            onClose?.();
          }
        }
      >
        <TimesIcon
          type='inherit'
          size='tiny'
        />
      </TextButton>
    </RoundedAlert>
  );
});

export default RoundedAlert;
