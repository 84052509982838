import PropTypes from 'prop-types';
import * as R from 'ramda';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import mapObjWithKeys from '@adretail/basic-helpers/src/base/mapObjWithKeys';

import {DIVIDER_GRAY} from '@ding/constants/src/colorSchema';
import {
  BREAKPOINTS,
  createBreakpoint,
  createBreakpointClasses,
} from '../../helpers/createBreakpoints';

/**
 * Tiny implementation based on material ui grid
 *
 * @see
 * https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Grid/Grid.js
 */
const COL_CLASS_NAME = 'col';

const GRID_SIZES = R.times(R.add(1), 12);

/**
 * Generate column name based on break point and size, bootstrap style
 * @example
 *  fn(xs, 2) => col-xs-2
 *
 * @param {String} breakpoint
 * @param {String} size
 *
 * @return {String}
 */
const genColumnClassName = (breakpoint, size) => (
  breakpoint
    ? `${COL_CLASS_NAME}-${breakpoint}-${size}`
    : `${COL_CLASS_NAME}-${size}`
);

const genColumnSizeClasses = breakpointKey => (classes, gridSizeIndex) => {
  const className = genColumnClassName(breakpointKey, gridSizeIndex);
  const width = `${(gridSizeIndex / GRID_SIZES.length) * 100}%`;

  return {
    ...classes,
    [className]: {
      maxWidth: width,
      flexGrow: 0,
      flexBasis: width,
    },
  };
};

const genGridStyles = mapObjWithKeys(
  ([
    breakpointKey,
    breakpointSize,
  ]) => ([
    createBreakpoint.minWidth(breakpointSize),
    R.reduce(
      genColumnSizeClasses(breakpointKey),
      {},
      GRID_SIZES,
    ),
  ]),
);

/**
 * Generate class names from column sizes, do not use ramda here
 * it should be as fast as it is possible(ramda is creating functions in every render)
 *
 * @param {Object}  jssClasses  JSS classes set
 * @param {Object}  breakpoints
 * @param {Object}  props
 *
 * @return {String}
 */
const getGeneratedColumnClasses = (jssClasses, breakpoints, props) => {
  let classes = '';

  for (const prop in props) { // eslint-disable-line no-restricted-syntax
    if (prop in breakpoints)
      classes += ` ${jssClasses[genColumnClassName(prop, props[prop])]}`;
  }

  return classes;
};

export const Column = styled.div(
  {
    col: {
      ...createBreakpointClasses(
        {
          xs: {
            padding: '0 6px 9px',
          },

          md: {
            padding: 10,
          },
        },
      ),

      '&:empty': {
        padding: '0 !important',
      },
    },

    'padding-small': {
      padding: 5,
    },

    'padding-medium': {
      padding: 10,
    },

    'padding-big': {
      padding: 15,
    },

    grow: {
      flexGrow: 1,
      maxWidth: '100%',
      flexBasis: 0,
    },

    centered: {
      '& > *': {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },

    noPadding: {
      padding: 0,
    },

    noLeftPadding: {
      paddingLeft: [0, '!important'],
    },

    noRightPadding: {
      paddingRight: [0, '!important'],
    },

    ...genColumnSizeClasses(),
    ...genGridStyles(BREAKPOINTS),
  },
  {
    omitProps: [
      'gridConfig', 'centered', 'grow',
      'noPadding', 'noLeftPadding', 'noRightPadding',
      'padding',
      ...R.keys(BREAKPOINTS),
    ],
    classSelector: (classes, props) => [
      classes.col,
      props.padding && classes[`padding-${props.padding}`],
      props.centered && classes.centered,
      props.noPadding && classes.noPadding,
      props.noLeftPadding && classes.noLeftPadding,
      props.noRightPadding && classes.noRightPadding,
      props.grow && classes.grow,
      getGeneratedColumnClasses(classes, BREAKPOINTS, props.gridConfig || props),
    ],
  },
);

Column.displayName = 'Column';

Column.propTypes = {
  padding: PropTypes.string,
  centered: PropTypes.bool,
  noPadding: PropTypes.bool,
  noLeftPadding: PropTypes.bool,
  noRightPadding: PropTypes.bool,

  grow: PropTypes.bool,
  gridConfig: PropTypes.objectOf(PropTypes.number), // alternative to lg passed directly to props

  ...R.mapObjIndexed(
    R.always(PropTypes.number),
    BREAKPOINTS,
  ),
};

Column.defaultProps = {
  centered: true,
  grow: true,
};

const Grid = styled.div(
  {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
);

Grid.displayName = 'Grid';

Grid.Column = Column;

/**
 * Column for text only purposes
 */
Grid.TextColumn = styled(
  Column,
  createBreakpointClasses(
    {
      xs: {
        padding: '0 6px 14px',
      },
      md: {
        padding: [5, 10],
      },
    },
  ),
);

/**
 * Column with divider on the left
 */
Grid.DividedColumn = styled(
  Column,
  {
    height: 'auto',

    ...createBreakpointClasses(
      {
        md: {
          padding: 0,

          '&:not(:first-child)': {
            paddingLeft: 25,
            borderLeft: ['1px', 'solid', DIVIDER_GRAY],
          },

          '&:not(:last-child)': {
            paddingRight: 25,
          },
        },
      },
    ),
  },
  {
    centered: false,
    gridConfig: {
      xs: 12,
      md: 6,
    },
  },
);

export default Grid;
