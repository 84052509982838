import styled from '@adretail/fast-stylesheet/src/react/styled';

const CheckboxListItem = styled.li(
  {
    width: '100%',
    margin: [10, 0],

    '&:first-child': {
      marginTop: 0,
    },
  },
);

CheckboxListItem.displayName = 'CheckboxListItem';

export default CheckboxListItem;
