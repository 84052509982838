export const LIGHT_RED = '#d43921';
export const LIGHT_MUTED_RED = '#e35945';
export const PINKY_PRIMARY_RED = '#dd4545';

export const WHITE = '#ffffff';
export const YELLOW = '#feed00';
export const DARK = '#000000';
export const LIGHT_GRAY = '#f3f3f3'; // TODO: check if changing to LIGHT_CARD_GRAY is possible
export const MUTED_GRAY = '#b3b3b3';
export const DARK_MUTED_GRAY = '#787878';
export const DARK_GRAY = '#5c5a5d'; // TODO: used ones prepare to remove
export const LIGHT_BLUE = '#f2fbff'; // TODO: remove this color is unused
export const GRASS_GREEN = '#13c46f';
export const LIGHT_MUTED_GRASS_GREEN = '#ecfff1';

// basic fill colors
export const LIGHT_BORDER = '#ebebeb';
export const HOVERED_LIGHT_BORDER = '#d8d8d8';
export const DARK_BORDER = '#434343';

export const HIGHTLIGHT_PRIMARY_FILL = '#e0503a'; // add 10% to LIGHT_RED
export const DARK_FILL_DIVIDER = '#333333';

export const PRIMARY_FILL = LIGHT_RED;
export const PRIMARY_FILL_DIVIDER = LIGHT_MUTED_RED;
export const PRIMARY_FILL_SHADOW = 'rgba(221,61,37,0.5)';

// extra see through
export const SEE_THROUGH_WHITE = `${WHITE}D7`;
export const SEE_THROUGH_DARK = `${DARK}D7`;
export const SEE_THROUGH_PRIMARY = `${HIGHTLIGHT_PRIMARY_FILL}33`;

// control groups
export const SLIDER_DOT_BACKGROUND = '#d6d6d6';
export const SLIDER_ACTIVE_DOT_FILL = PRIMARY_FILL;

// single page sections colors
export const DIVIDER_GRAY = 'rgba(208, 208, 208, 0.5)';

export const LIGHT_CARD_BORDER = '#dadada';
export const DARK_CARD_GRAY = '#777777';
export const DARK_MUTED_CARD_GRAY = '#979797'; //TODO: unnecessary color in used places can be changed
export const LIGHT_CARD_GRAY = '#f7f7f7';
export const CARD_GRAY = '#e9e9e9';

export const PAGE_BACKGROUND = LIGHT_CARD_GRAY;

export const PRICE_BACKGROUND = PRIMARY_FILL;
export const DISCOUNT_BACKGROUND = YELLOW;

export const TABLE_BORDER = '#dedede';
export const TEXT_MUTED = MUTED_GRAY;
export const TEXT_DARK_MUTED = DARK_MUTED_GRAY;

// buttons
export const DEFAULT_BUTTON_BACKGROUND = CARD_GRAY;

export const FB_BUTTON_BACKGROUND = '#1877F2';
export const FB_BUTTON_HOVER_BACKGROUND = '#166fe5';

export const TWITTER_BUTTON_BACKGROUND = '#1d9bf0';
export const TWITTER_BUTTON_HOVER_BACKGROUND = '#0c7abf';

export const RIBBON_GREEN = '#00cf27';
export const RIBBON_RED = '#d43921';

export const BELL_INACTIVE_GRAY = '#a8a8a8';

export const THIN_GRAY_BUTTON_COLOR = DARK_GRAY; //TODO: used ones check if this button type is used
export const THIN_GRAY_BUTTON_BORDER = '#cbcbcb';
export const ICON_BUTTON_COLOR = '#4a4A4A';

// modals
export const MODAL_CONTENT_TOOLBAR_COLOR = '#757575';

// inputs
export const INPUT_LIGHT_GRAY_BACKGROUND = LIGHT_CARD_GRAY;

export const INPUT_FOREGROUND = DARK;
export const INPUT_LIGHT_FOREGROUND = DARK;

export const INPUT_BACKGROUND = CARD_GRAY;
export const INPUT_LIGHT_BACKGROUND = WHITE;

export const ERROR_INPUT_BACKGROUND = INPUT_BACKGROUND; // '#fecec7';
export const ERROR_INPUT_BORDER = PRIMARY_FILL;

export const TOGGLE_BACKGROUND = '#7b7b7b';
export const TOGGLE_LIGHT_BACKGROUND = LIGHT_CARD_GRAY;
export const TOGGLE_NORMAL_BACKGROUND = CARD_GRAY;
export const TOGGLE_CIRCLE = WHITE;
export const TOGGLE_HOVER_BACKGROUND = '#bbbbbb';

// viewer
export const VIEWER_PAGE_NUMBER_FOREGROUND = '#aaaaaa';
export const VIEWER_TOOLBAR_DIVIDER = '#e6e6e6';
export const VIEWER_NAVBAR_BACKGROUND = '#ffffff';
export const VIEWER_DESTKOP_BACKGROUND = VIEWER_NAVBAR_BACKGROUND;

export const FLOATING_VIEWER_NAVBAR_HOVER_BACKGROUND = '#ececec';
export const VIEWER_NAVBAR_HOVER_BACKGROUND = 'rgba(0, 0, 0, 0.03)';
export const VIEWER_MODULE_TOOLTIP_BACKGROUND = '#262626';
export const VIEWER_MODULE_BUTTON_SECONDARY = '#006cff';

// Notifications
export const NOTIFICATION_LIGHT_RED = 'rgba(221, 61, 37, 0.05)';
export const NOTIFICATION_LIGHT_GREEN = '#ecfff1';
export const NOTIFICATION_LIGHT_BLUE = '#f2fbff';

export const NOTIFICATION_CONTRACTOR_TITLE_GRAY = '#999999';
export const NOTIFICATION_HEADER_GRAY = '#666';

export const BREADCRUMBS_TEXT_GRAY = '#9f9f9f';
export const BREADCRUMBS_BORDER_GRAY = 'rgba(233, 233, 233, 1)'; //TODO: not used, mark to remove

export const TOAST_BACKGROUND = '#13c46f';
export const TOAST_FOREGROUND = WHITE;

export const TOAST_DANGER_BACKGROUND = PRIMARY_FILL;
export const TOAST_DANGER_FOREGROUND = WHITE;

export const TOAST_DARK_BACKGROUND = DARK;
export const TOAST_DARK_FOREGROUND = WHITE;

// Boxes
export const DEFAULT_BOX_BORDER = 'rgba(0, 0, 0, .1)';

export const LIGHT_MUTED_BOX_BORDER = '#e4e4e4';
export const LIGHT_MUTED_BOX_BACKGROUND = WHITE;

export const LIGHT_RED_BOX_BORDER = '#fbd4cf';
export const LIGHT_RED_BOX_BACKGROUND = '#ffe4e0';

export const LIGHT_GREEN_BOX_BORDER = '#c4eac2';
export const LIGHT_GREEN_BOX_BACKGROUND = '#ebffe0';

export const WARN_BOX_BACKGROUND = '#fff3d0';
export const WARN_BOX_COLOR = '#755420';
export const WARN_BOX_BORDER = '#e6dbb6';

export const INFO_BOX_BACKGROUND = '#dfeafe';
export const INFO_BOX_BORDER = '#c8d5ec';
export const INFO_BOX_COLOR = '#1f71a2';
