import React from 'react';
import {adopt} from 'react-adopt';
import {gql, GraphQLFragment} from '@ding/tiny-gql';

import {OAUTH_REGISTER_ROUTE_STATE} from '@ding/auth/src/constants/schemas';

import formatDate from '@adretail/basic-helpers/src/time/formatDate';

import TinyGqlMutation from '@ding/tiny-gql/src/react/TinyGqlMutation';
import {AuthStateConsumer} from '../../client/AuthContext';

const OAUTH_REGISTER_MUTATION = new GraphQLFragment(
  'oauthActionMutation',
  gql`
    mutation oauthActionMutation(
      $oauth: CustomerOmniauth!,
      $findOnly: Boolean,
    ) {
      oauthCustomer(input: {
        oauth: $oauth,
        findOnly: $findOnly,
      }) {
        token
        errors
        alreadyRegistered
      }
    }
  `,
);

export const toOAuthMutationInput = ({provider, keys}, formValue) => ({
  accessToken: keys.accessToken,
  expiresAt: Date.now() + (keys.expiresIn * 1000),
  provider,
  info: formValue && {
    birthDate: formatDate(formValue.age?.date),
    marketingTerms: !!formValue.agreement?.license,
  },
});

/**
 * Force split setTokenAndRefetch, oauthRegister into separate methods
 * due to Modal close issues
 *
 * @export
 */
const OAuthActionMutation = adopt(
  {
    authState: <AuthStateConsumer />,

    oauthAction: ({prevStepAuth, authState, render}) => (
      <TinyGqlMutation mutation={OAUTH_REGISTER_MUTATION}>
        {(mutate, utils) => {
          const setTokenAndRefetch = (token) => {
            authState.setToken(token);
            return utils.refetchQuery('*');
          };

          const setFreshLogin = (value) => {
            authState.setFreshLogin(value);
          };

          /**
           * If findOnly is provided muttion will never try to create new user
           *
           * @param {Object} formValue
           * @param {AuthData} authData
           * @param {Boolean} findOnly
           *
           * @returns {Rromise}
           */
          const fetchOAuthToken = async (
            formValue,
            stepAuth = prevStepAuth, // it is provided from previous step in register
            findOnly = false,
          ) => mutate(
            {
              oauth: toOAuthMutationInput(stepAuth, formValue),
              findOnly,
            },
          );

          return render(
            {
              ...utils,
              setTokenAndRefetch,
              setFreshLogin,
              fetchOAuthToken,
            },
          );
        }}
      </TinyGqlMutation>
    ),
  },
);

OAuthActionMutation.displayName = 'OAuthActionMutation';

OAuthActionMutation.propTypes = {
  auth: OAUTH_REGISTER_ROUTE_STATE,
};

export default OAuthActionMutation;
