import * as R from 'ramda';

import {TIME_SUFFIXES} from '@ding/constants';
import env from '@cdd/internal-server/src/constants/env';

import * as Routes from './routes';
import Sections from './sections';
import Smaker from './external/smaker';

import footerDescription from './docs/footerDescription.txt';

export default {
  pl: {
    nsfw: {
      modal: {
        confirm_age: 'Potwierdź swój wiek',
        confirm_title: 'Informujemy, że w wybranej gazetce znajdują się informacje handlowe dotyczące napojów alkoholowych lub innych treści przeznaczonych dla osób pełnoletnich. Kontynuowanie przeglądania gazetki jest możliwe wyłącznie pod warunkiem złożenia poniższego oświadczenia:',
        confirm_checkbox_title: 'Oświadczam, że ukończyłem/-am 18 rok życia oraz chcę zapoznać się z ofertą kierowaną do osób pełnoletnich, w tym dotyczącą napojów alkoholowych.',
        buttons: {
          enter: 'Przejdź do strony',
          cancel: 'Anuluj',
        },
      },
    },
    smaker: Smaker,
    website: {
      routes: Routes,
      ...Sections,

      // fallback
      title_address: env.shared.website.titleAddress,
      ...R.pick(
        ['name', 'address'],
        env.shared.website,
      ),

      meta: {
        page_number: 'str. %{}',

        defaults: {
          og: {
            title: env.shared.website.titleAddress,
            description: 'Kliknij i zobacz promocje!',
          },
        },
      },

      page_name: {
        name: 'Ding',
        titleAddress: 'Ding.pl',
      },

      pagination: {
        label: 'Paginacja, akcualna strona %{}',
        next: 'Następna strona',
        prev: 'Poprzedna strona',
        list: 'Lista wyboru stron',
        page: 'Strona %{}',
      },

      bottom_bar: {
        leaflets: {
          name: 'Gazetki',
          title: 'Gazetki promocyjne',
        },
        offers: {
          name: 'Produkty',
          title: 'Oferty z gazetek promocyjnych',
        },
        products: {
          name: 'Oferty',
          title: 'Oferty z gazetek promocyjnych',
        },
        contractors: {
          name: 'Sklepy',
          title: 'Sklepy: Sieci handlowe w Polsce',
        },
        blog: {
          name: 'Blog',
          title: 'Wiadomości handlowe',
        },
        search: {
          name: 'Szukaj',
          title: 'Szukaj na ding.pl',
        },
        shopping_list: 'Zakupy',
        shopping_sundays: {
          name: 'Dni handlu',
          title: 'Kalendarz dni handlowych',
        },
        menu: {
          name: 'Więcej',
          title: 'Rozwiń Menu',
        },
        home: 'Przejdz na stronę główną',
        shopping: 'Zakupy',
        notifications: 'Powiadomienia',
        auth: 'Konto',
        location: 'Lokalizacja',
        help: {
          header: 'Pomoc',
          contact: 'Napisz do nas',
          mobile_app: 'Pobierz aplikację mobilną',
        },
      },

      alerts: {
        register_to_save_favourites: 'Nie jesteś zalogowany. %{} lub %{}, aby na stałe zapamiętać swoje ulubione sieci, ustawienia i listę zakupową.',
      },

      header: {
        env_alert: 'Klikasz w środowisku %{}!',
        old_browser: 'Używasz przestarzałej przeglądarki. Serwis może nie działać prawidłowo!',
        change_language: {
          text: 'Відкрийте для себе Ding українською. ',
          link: 'Перейти на українську версію сайту.',
        },
        buttons: {
          open_menu: 'Otwórz menu',
        },
        menu_items: {
          leaflets: 'Gazetki',
          products: 'Oferty z gazetek',
          contractors: 'Sklepy',
          articles: 'Blog',
          shopping_sundays: 'Niedziele handlowe',
          contact: 'Napisz do nas',
          mobile_app: 'Aplikacja mobilna',
          about_product: 'Informacje o produkcie',
        },
        footer_items: {
          leaflets: 'Gazetki',
          start: 'Start',
          shopping_list: 'Zakupy',
          notifications: 'Nowości',
          search: 'Szukaj',
          more: 'Więcej',
        },
        search: {
          label: 'Szukaj na ding.pl',
          placeholder: 'Szukaj sieci lub produktu',
          truncated_placeholder: 'Szukaj sieci lub produktu',
          see_other_popular_leaflets: 'Zobacz inne popularne gazetki:',

          search_results: {
            empty: 'Brak wyników dla frazy:',

            leaflets: {
              default: 'Gazetki',
            },

            leafletsPages: {
              default: 'Strony gazetek',
            },

            products: {
              default: 'Produkty',
            },

            articles: {
              default: 'Newsy',
            },

            contractors: {
              default: 'Sklepy',
            },
          },
        },
      },

      form: {
        categories_select: {
          title: 'Kategorie',
        },

        contractors_select: {
          title: 'Sieci handlowe',
          placeholder: 'Wpisz nazwę sieci',
        },

        product_select: {
          title: 'Produkty',
          placeholder: 'Wpisz nazwę produktu',
        },

        address_autocomplete: {
          title: 'Adres',
          city_placeholder: 'Wpisz miasto...',
          placeholder: 'Wpisz adres...',
        },

        price_range: {
          title: 'Cena',
          min: 'Od',
          max: 'Do',
        },
      },

      errors: {
        validators: {
          password: '5-32 znaków, min. 1 litera, min. 1 cyfra lub znak specjalny',
          passwords_not_equal: 'Hasła różnią się',
          password_equal_to_previous: 'Nowe hasło jest takie samo jak poprzednie',
        },

        form: {
          submit: {
            single: 'Napotkano błąd podczas wysyłania formularza',
            multiple: 'Napotkano błędy podczas wysyłania formularza',
            default: 'Błąd podczas wysyłania formularza',
          },
        },

        incorrect_slug: {
          contractor: 'Przeniesiono na porpawną stronę szukanej sieci',
        },
      },

      contact_info: {
        service_provider: {
          title: 'Usługę świadczy',
          description: 'INTERIA.PL sp. z o.o.',
        },

        location: {
          title: 'Siedziba',
          description: {
            street: 'Ul. Kotlarska 11',
            city: '31-539 Kraków, Polska',
            national_court_register: 'Sąd Rejonowy w Krakowie',
            court_department: 'XI Wydział Krajowego Rejestru Sądowego',
          },
        },

        contact: {
          title: 'Kontakt',
          description: {
            phone: 'Tel: +48 12 646 28 12',
            fax: 'Fax: +48 12 646 27 10',
            email: 'E-mail: bok@firma.interia.pl',
            address: 'Ul. Kotlarska 11, 31-539 Kraków',
          },
        },

        company_identifiers: {
          description: {
            krs: 'KRS 0001009169',
            nip: 'NIP 6772118727',
            regon: 'REGON 357054315',
          },
        },
      },

      footer: {
        description: footerDescription,
        copyrights: {
          accept_regulations: 'Korzystanie z portalu oznacza akceptację ',
          regulations: 'Regulaminu',
          privacy: 'Prywatność',
          copyright: 'Copyright by INTERIA.PL 1999-%{}. Wszystkie prawa zastrzeżone.',
        },
        headers: {
          stores: 'Sklepy',
          top_contractors: 'TOP sklepy',
          leaflets_offers: 'Oferty z gazetek',
        },
      },

      placeholders: {
        empty_search_result: 'Brak podpowiedzi dla %{}!',
        empty_contractors: 'Brak sieci!',
        empty_leaflets: 'Brak gazetek!',
        empty_products: 'Brak produktów!',
        empty_articles: 'Brak artykułów!',

        loading: 'Wczytywanie...',
        loading_section: 'Wczytywanie sekcji...',
        loading_leaflets: 'Wczytywanie gazetek...',
      },

      modals: {
        geolocalize: {
          your_localization: 'Twoja lokalizacja',
        },
      },

      filters: {
        category: 'Kategoria',
        sort: 'Sortuj',

        default_selected: {
          all: 'Wszystkie',
          newest: 'Najnowsze',
        },

        sort_options: {
          distance: 'Blisko Ciebie',
          pub_date: 'Najnowsze',
          end_date: 'Kończące się',
          name: 'Nazwa',
          popularity: 'Popularne',
          price_asc: 'Najtańszy',
          price_desc: 'Najdroższy',
        },
      },

      shared: {
        time: {
          [TIME_SUFFIXES.SECONDS]: '%{} s.',
          [TIME_SUFFIXES.MINUTES]: '%{} min.',
          [TIME_SUFFIXES.HOURS]: '%{} godz.',
          [TIME_SUFFIXES.DAYS]: '%{} dni',
          [TIME_SUFFIXES.WEEKS]: '%{} tyg.',
          [TIME_SUFFIXES.MONTHS]: '%{} mies.',
          [TIME_SUFFIXES.YEARS]: '%{} lat',
        },
        breadcrumbs: {
          root: `${env.shared.website.titleAddress} - gazetki promocyjne`,
          leaflets: 'Gazetki promocyjne',
          articles: 'Blog',
          authors: 'Autorzy',
          products: 'Oferty',
          contractor_product: 'Oferty %{}',
          contractor_promotion: '%{} - %{} promocja',
          contractors: 'Sieci',
          shopping_sundays: 'Niedziele Handlowe',
          stores_by_city: 'Sklepy według miast',
          contact: 'Napisz do nas',
          mobile_app: 'Aplikacja mobilna',
        },

        date: {
          closed: 'nieczynne',
          no_data: 'brak danych',
          twenty_four: '24h',
          day: 'Dzień',
          month: 'Miesiąc',
          year: 'Rok',
          days: [
            'ndz',
            'pon',
            'wt',
            'śr',
            'czw',
            'pt',
            'sob',
          ],
          days_full: [
            'niedziela',
            'poniedziałek',
            'wtorek',
            'środa',
            'czwartek',
            'piątek',
            'sobota',
          ],
        },

        confirm: {
          ok: 'ok',
          cancel: 'anuluj',
        },

        sections: {
          error: {
            loading_error: 'Upss, coś poszło nie tak 😢',
          },

          articles: {
            header: 'Blog',
            title: 'Artykuły',
            see_more: 'zobacz więcej',
            see_all: 'zobacz wszystkie artykuły',
            read_more_article: 'Czytaj dalej',
            author: 'Autor',
          },

          contractors: {
            header: 'Sieci handlowe',
            title: 'Sieci',
            see_more: 'Więcej sieci handlowych',
            see_other_contractor_leaflets: 'Zobacz inne gazetki sieci %{}',
          },

          leaflets: {
            header: 'Gazetki promocyjne',
            title: 'Superoferty z gazetek',
            see_all: 'Pokaż wszystkie gazetki',
            see_more: 'Pokaż więcej gazetek',
            more: 'Więcej gazetek',
            see_latest: 'Zobacz najnowszą gazetkę',
          },

          leaflets_for_user: {
            header: 'Gazetki wybrane dla Ciebie',
            title: 'Gazetki',
          },

          leaflets_offers: {
            header: 'Superoferty z gazetek',
            title: 'Top oferty',
          },

          product_template: {
            header: 'Informacje o produkcie',
          },

          selected_leaflets_offers: {
            header: 'Wybrane oferty z gazetek',
            title: 'Top oferty',

            see_all: 'zobacz wszystkie oferty',
            see_more_offers: 'Pokaż więcej ofert',
            see_more_contractor_offers: 'Więcej ofert z gazetek %{}',
          },

          categories: {
            header: 'Kategorie gazetek',
            title: 'Kategorie',
          },

          similar_products: {
            header: 'Podobne produkty',
          },

          nearest_stores: {
            header: 'Sklepy %{} w pobliżu',
          },

          popular_leaflets: {
            embed_header: 'Popularna gazetka',
            leaflets_header: 'Polecane gazetki',
          },
        },
        logo: {
          image_alt: 'Logo',
        },
        header: {
          menu: 'Menu',
        },
        buttons: {
          scroll: {
            left: 'Przewiń w lewo',
            right: 'Przewiń w prawo',
          },
          scroll_to_top: 'Przejdz na górę strony',
        },
        toasts: {
          link_copied: 'Link został skopiowany!',
          copied_text: {
            success: 'Pomyślnie skopiowano tekst',
            failed: 'Nie udało się skopiować tekstu',
          },
        },
        titles: {
          language_uk: 'PL',
          language_pl: 'UA',
          counted_title: '%{name} (%{count} ofert)',
          see_all: 'Zobacz wszystkie',
          share: 'Udostępnij',
          share_on: 'Udostępnij ma %{}',
          copy_link: 'Skopiuj link',
          all: 'Wszystkie',
          click_here: 'Kliknij tutaj',
          ok: 'OK',
          settings: 'Ustawienia',
          top_offer: 'Top oferta',
          see_more: 'Zobacz więcej',
          closed: 'Zamknięte',
          close: 'Zamknij',
          useful_links: 'Przydatne linki',
          email: 'E-Mail',
          password: 'Hasło',
          repeat_password: 'Powtórz hasło',
          or: 'lub',
          more: 'więcej',
          less: 'zwiń',
          collapse: 'zwiń',
          here: 'tutaj',
          see: 'Zobacz',
          see_leaflet: 'Gazetka',
          privacy: 'Prywatność',
          leaflets: 'Gazetki',
          articles: 'Artykuły',
          notifications: 'Powiadomienia',
          shopping_list: 'Lista zakupów',
          next: 'Dalej',
          filters: 'Filtry',
          filter: 'Filtruj',
          clear: 'Wyczyść',
          add: 'Dodaj',
          added: 'Dodano',
          send: 'Wyślij',
          back: 'Cofnij',
          apply_changes: 'Zastosuj zmiany',
          valid: 'Ważna',
          discount: {
            percentage: '%{}% taniej!',
          },
          duration_labels: {
            begin: 'Od:',
            end: 'Do:',
          },
          address: {
            with_street: '%{}, ul. %{} %{}',
            with_street_without_prefix: '%{} %{} %{}',

            street: 'ul. %{} %{}',
            street_without_prefix: '%{} %{}',

            region: 'pow. %{}',
            province: 'woj. %{}',
            blacklisted_street_prefixes: /^(os\.|al\.)/i,
          },
          prefix: {
            price: 'Cena:',
            contractor: 'Sieć:',
            shop: 'Sklep:',
          },
          price: {
            pln: '%{} zł',
          },
        },
        cards: {
          product: {
            image_alt: 'Podgląd produktu',
            archived: 'Oferta archiwalna',
          },
          leaflet: {
            image_alt: 'Okładka gazetki',
            title: 'Gazetka %{}',
            archived: 'Gazetka nieaktualna',
            currently_visible: 'Teraz oglądasz',
            ribbons: {
              hit: 'hit',
            },
          },
          article: {
            image_alt: 'Okładka artykułu',
          },
          follow_us_on: {
            header: 'Nie przegap żadnych najciekawszych artykułów!',
            call_to_action: 'Kliknij i obserwuj %{} na',
          },
          author: {
            image_alt: 'Autor artykuły',
            see_more: 'Więcej artykułów autora',
          },
          contractor: {
            logo_alt: '%{} promocje',
          },
        },
      },
    },
  },
};
