import * as R from 'ramda';

import sum from 'hash-sum';
import {findOperationName} from './dataUtils';

const gqlOperationCacheKey = (operationName, variables) => (
  `${operationName}(${!variables ? '' : sum(variables)})`
);

export const findQueryOperationName = findOperationName('query');

/**
 * Returns true if operation of comparated cache key
 * is equal to provided operationName, used to wildcard
 * check queries without providing props
 */
export const hasSameQueryOperation = R.curry(
  (operationName, cacheKey) => R.startsWith(`${operationName}(`, cacheKey),
);

export const gqlQueryCacheKey = (query, variables) => gqlOperationCacheKey(
  findQueryOperationName(query),
  variables,
);

export default gqlOperationCacheKey;
