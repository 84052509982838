import * as R from 'ramda';

export const MATCH_PAGE_REGEX = /\/(\d+)($|\\?|#)$/;

export const safeReplacePage = (page) => {
  const replaceStr = `/${page}`;

  return R.ifElse(
    R.test(MATCH_PAGE_REGEX),
    R.replace(MATCH_PAGE_REGEX, replaceStr),
    str => `${R.when(R.endsWith('/'), R.init)(str)}${replaceStr}`,
  );
};

const defaultLinkBuilder = ({location}, number) => ({
  to: {
    pathname: safeReplacePage(number)(location.pathname),
    search: location.search,
  },
});

export default defaultLinkBuilder;
