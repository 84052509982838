import {ConditionalUAComponent} from '@adretail/basic-components/src/Context/UAProvider';
import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import DesktopHeader from './Desktop';
import MobileHeader from './Mobile';
import {HeaderItem} from './HeaderToolbar';

export {default as HeaderHolder} from './HeaderHolder';
export {default as HeaderToolbar} from './HeaderToolbar';
export {default as HeaderAlert} from './HeaderAlert';
export {HeaderItem};

const Header = provideProps(
  {
    desktopComponent: DesktopHeader,
    mobileComponent: MobileHeader,
  },
)(ConditionalUAComponent);

Header.displayName = 'PageHeader';

export default Header;
