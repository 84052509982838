import React from 'react';

import {LoginMutation} from '@ding/auth/src/gql/components';
import LoginForm from './Form';

const RegisterFormContainer = props => (
  <LoginMutation>
    {loginUserUtils => (
      <LoginForm
        {...props}
        errors={loginUserUtils.errors}
        loginUserUtils={loginUserUtils}
      />
    )}
  </LoginMutation>
);

RegisterFormContainer.displayName = 'RegisterFormContainer';

export default React.memo(RegisterFormContainer);
