import * as R from 'ramda';
import createContextPack from '@ding/tiny-context-state';

const preserveRefAssoc = (key, value) => (obj) => {
  if (obj[key] === value)
    return obj;

  return {
    ...obj,
    [key]: value,
  };
};

export const genShoppingListItemID = (item) => {
  if (!R.isNil(item.productId))
    return item.productId;

  if ('leafletSelection' in item)
    return `shopping-list-item-${item.kind}-${item.id}`;

  return item.id;
};

/**
 * @see
 *  TinyGQLClient is not supporting Rails style subscription
 *  and splitting queries into single items types, it also caches
 *  in front(and server) side items, use context to update all components
 *  without sockets and refetches
 */
const {
  Consumer,
  Provider,
  useStateContext,
} = createContextPack(
  {
    initialState: {},
    selectors: {
      isInShoppingList: () => (state, item) => R.propOr(
        null,
        genShoppingListItemID(item),
        state,
      ),
    },
    actions: {
      removeShoppingListItem: item => preserveRefAssoc(genShoppingListItemID(item), false),
      addShoppingListItem: item => preserveRefAssoc(genShoppingListItemID(item), true),
      replaceListItemKey: (oldKey, newKey) => state => ({
        ...R.omit([oldKey], state),
        [newKey]: state[oldKey],
      }),
    },
  },
);

export const useTempShoppingListState = useStateContext;

export const TempShoppingListStateConsumer = Consumer;

export const TempShoppingListStateProvider = Provider;
