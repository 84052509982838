import {gql, GraphQLFragment} from '@ding/tiny-gql';

const PUBLISHERS_WIDGET_ITEM_FRAGMENT = gql`
  fragment WidgetItem on ListNode {
    id
    slots(rejectWithLeafletIds: $rejectWithLeafletIds) {
      id
      name
      coverOnlyForAdults
      contractor {
        id
        logo
        name
      }
      leaflet {
        id
        cover(size: "C303")
        name
        duration {
          begin
          end
        }
      }
      image
      link
      logo
      promotion {
        begin
        end
      }
    }
  }
`;

export const PUBLISHERS_WIDGET_BY_PLACEMENT_LEAFLETS_QUERY = new GraphQLFragment(
  'widgetByPlacementQuery',
  gql`
    ${PUBLISHERS_WIDGET_ITEM_FRAGMENT}
    query widgetByPlacementQuery(
      $publisherId: ID,
      $placement: String!,
      $rejectWithLeafletIds: [ID!],
    ) {
      widget: widgetByPlacement(
        publisherId: $publisherId,
        placement: $placement,
      ) {
        ...WidgetItem,
      }
    }
  `,
);

export const PUBLISHERS_WIDGET_LEAFLETS_QUERY = new GraphQLFragment(
  'widgetQuery',
  gql`
    ${PUBLISHERS_WIDGET_ITEM_FRAGMENT}
    query widgetQuery(
      $id: ID!,
      $rejectWithLeafletIds: [ID!],
    ) {
      widget(id: $id) {
        ...WidgetItem,
      }
    }
  `,
);
