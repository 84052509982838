import styled from '@adretail/fast-stylesheet/src/react/styled';

const Table = styled.table(
  {
    margin: 0,
  },
);

Table.Column = styled(
  'td',
  {
    margin: 0,
  },
);

Table.RowContainer = styled(
  'tr',
  {
    margin: 0,
  },
);

Table.Caption = styled(
  'caption',
  {
    margin: 0,
  },
);

export default Table;
