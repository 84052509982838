import React from 'react';
import PropTypes from 'prop-types';

import {
  WHITE,
  PRIMARY_FILL,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import CheckIcon from '@ding/core/src/icons/Check';
import CheckboxContainer from '../CheckboxContainer';

export const squareHolderSizes = {
  small: {
    width: 20,
    height: 20,
  },

  medium: {
    width: 25,
    height: 25,
  },
};

export const CheckScaledIcon = styled(
  CheckIcon,
  {
    base: {
      position: 'absolute',
      top: 4,
      left: 5,
      width: 15,
      color: WHITE,
    },
  },
);

const SquareHolder = styled(
  ({active, children, parentProps, ...props}) => (
    <CheckboxContainer
      fill='light'
      {...props}
    >
      {active && (
        <CheckScaledIcon />
      )}
      {children}
    </CheckboxContainer>
  ),
  {
    base: {
      width: 25,
      height: 25,
      textAlign: 'center',
      borderRadius: 6,
    },

    ...squareHolderSizes,

    active: {
      background: [PRIMARY_FILL, '!important'],
    },
  },
  {
    index: 2,
    classSelector: (classes, {active, size}) => [
      active && classes.active,
      classes[size || 'medium'],
    ],
  },
);

SquareHolder.displayName = 'SquareHolder';

SquareHolder.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.string,
};

export default SquareHolder;
