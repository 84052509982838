import React from 'react';
import * as R from 'ramda';

import {parameterize} from '@ding/core/src/helpers';

import {usePageSearchQuery} from '@ding/search/src/gql/pageSearchQuery';
import {languages} from '@ding/i18n/src/languages';
import createLinkComponent from '../../utils/createLinkComponent';

export const TAG_URL_SCHEMA = `${languages.uk.prefix}/znyzhky/:name,:id(\\d+)`;

export const genTagURL = ({id, name}) => (
  `${languages.uk.prefix}/znyzhky/${parameterize(name)},${id}`
);

const TagLink = createLinkComponent('tag', genTagURL);

export const LeadContractorTagLink = ({contractorName, ...props}) => {
  const {data, loading} = usePageSearchQuery(
    {
      variables: {
        segmentLimit: 1,
        phrase: contractorName,
        withProducts: false,
        withContractors: false,
        includeWithoutLeaflets: true,
      },
    },
  );

  if (!data || loading || R.isEmpty(data.tags))
    return null;

  return (
    <TagLink
      tag={data.tags[0]}
      {...props}
    />
  );
};

export default TagLink;
