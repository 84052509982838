import styled from '@adretail/fast-stylesheet/src/react/styled';

const Clearfix = styled.div(
  {
    clear: 'both',
  },
);

Clearfix.displayName = 'Clearfix';

export default Clearfix;
