import contractorsDescription from '../docs/contractorsDescription.txt';

export default {
  title: 'Sieci handlowe',

  section: {
    title: 'Sieci',
    header: 'Sieci handlowe',
    description: contractorsDescription,
  },

  meta: {
    title: 'Sieci handlowe - Sprawdź najnowsze promocje%{domainSuffix}',
    description: 'Zobacz najnowsze gazetki promocyjne i reklamowe hipermarketów, supermarketów i innych sieci handlowych z całej Polski!',

    category_title: 'Sieci handlowe z kategorii: %{categoryName}%{domainSuffix}',
    category_description: 'Zobacz najnowsze gazetki promocyjne i reklamowe hipermarketów, supermarketów i innych sieci handlowych z kategorii: %{categoryName}',
  },
};
