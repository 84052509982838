import React, {useState} from 'react';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {SEARCH_MODAL_UUID} from '@ding/search/src/constants';
import SearchIcon from '@ding/core/src/icons/Search';
import SearchModal from '@ding/search/src/containers/SearchModal';
import {SearchSubHeader} from '@ding/slide-viewer/src/Viewer/containers/Desktop/ContentItemsToolbar/Items/SearchButton';

import {IconButton} from '@ding/controls/src/';
import {URLModalTrigger} from '@ding/controls/src/ResponsiveModalToggle';
import {GQLWorthToSeeGrid} from '@ding/cards/src/Leaflet/Grid';

import {HeaderItem} from '../../HeaderToolbar';

const SearchLeafletsGrid = provideProps(() => ({
  emptyPlaceholderComponent: null,
  skeletonLoading: true,
  itemProps: {
    spaced: false,
    showTitles: false,
    showDate: true,
    dateFormat: 'numeric',
    size: 'tiny',
    imageFit: 'cover',
  },
  padding: 'small',
  gridConfig: {
    xs: 4,
  },
}),
true,
)(GQLWorthToSeeGrid);

const SearchItem = ({subtitle, onClick, ...props}) => {
  const [haveSearchResults, setHaveSearchResults] = useState(true);
  const t = useTranslate('website.bottom_bar.search');

  return (
    <HeaderItem
      subtitle={subtitle}
      onClick={onClick}
    >
      <URLModalTrigger
        uuid={SEARCH_MODAL_UUID}
        type='light'
        icon={SearchIcon}
        title={t('title')}
        aria-label={t('title')}
        triggerComponent={IconButton}
        modalComponent={SearchModal}
        modalProps={{
          noSearchParamsRenderFn: () => {
            setHaveSearchResults(false);
            return (<SearchLeafletsGrid />);
          },
          headerRenderFn: value => (
            !haveSearchResults && (!value || value?.length <= 0)
          ) && <SearchSubHeader />,
        }}
        {...props}
      />
    </HeaderItem>
  );
};

SearchItem.displayName = 'SearchItem';

export default SearchItem;
