import useMutation from '@ding/tiny-gql/src/react/hooks/useMutation';
import {useAuthState} from '../../../client/AuthContext';

import {REFRESH_USER_TOKEN_MUTATION} from '../../refreshUserToken';
import {useCreateGuestUserMutation} from '../CreateGuestUserMutation';

const useRefetchTokenSetter = () => {
  const authState = useAuthState();
  const [mutateRefreshToken] = useMutation(REFRESH_USER_TOKEN_MUTATION);
  const [createGuestUser] = useCreateGuestUserMutation();

  const refreshToken = async () => {
    try {
      const {token} = await mutateRefreshToken(
        {
          token: authState.getToken(),
        },
      );

      if (token)
        authState.setToken(token);
      else
        throw new Error('useRefetchTokenSetter: FIXME! Abort to set NULL user token! Generate blank anonymous user!');
    } catch (e) {
      // todo: Error catch
      console.error(e);
      authState.setToken(
        (await createGuestUser()).token,
      );
    }

    return null;
  };

  return {
    refreshToken,
    authState,
  };
};

export default useRefetchTokenSetter;
