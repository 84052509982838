import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'ArrowRight',
  {
    viewBox: '0 0 9 16',
  },
  <path d='M.32,15.67a1.13,1.13,0,0,1,0-1.58L6.33,8l-6-6.09A1.13,1.13,0,0,1,.32.33a1.1,1.1,0,0,1,1.57,0L8.68,7.21A1.14,1.14,0,0,1,9,8a1.11,1.11,0,0,1-.33.79L1.89,15.67A1.1,1.1,0,0,1,.32,15.67Z' />,
);
