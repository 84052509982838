import React, {useCallback, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';

import ssr from '@adretail/basic-helpers/src/base/ssr';
import IdleRender from '@adretail/basic-components/src/IdleRender';

import useReactRouter from '@ding/core/src/hooks/useReactRouter';
import useLocalStorageFlagAccessor from '@ding/core/src/hooks/useLocalStorageFlagAccessor';

import {textToolbarFn} from './toolbars/ExpandTextToolbarRow';

export const ASYNC_CHUNK_DATA_ATTRIB = 'data-async-chunk';

const useRememberScrollFlag = () => useLocalStorageFlagAccessor(
  'prev-grid-scroll-position',
);

const useListRestoreScrollInitials = (rememberScrollPosition) => {
  const cacheLoaded = useRef(null);
  const rememberScrollFlagAccessor = useRememberScrollFlag();

  const {
    history,
    location,
  } = useReactRouter();

  let initialState = null;
  if (!ssr
        && cacheLoaded.current === null
        && rememberScrollPosition) {
    // revert scroll on push() action
    if (window.__hydrated && (history.action === 'POP' || location?.state?.resetScroll === false)) {
      initialState = rememberScrollFlagAccessor.get();

      const prevScrollY = initialState?.__cache?.scrollY;
      prevScrollY && setTimeout(() => {
        window.scrollTo(0, prevScrollY);
      }, 10);

      cacheLoaded.current = true;
    } else
      cacheLoaded.current = false;

    rememberScrollFlagAccessor.set(null);
  }

  return {
    initialState: initialState || {
      prevChunk: null,
      visibleChunksCount: 1,
      __cache: {},
    },
    cacheLoaded: !!cacheLoaded.current,
    rememberScrollFlagAccessor,
  };
};

/**
 * Renders array of chunk, last chunk contains `more` button that triggers
 * component to load new chunk etc
 *
 * @export
 */
const ExpandableFunctionalChunksList = React.forwardRef(({
  className,
  asyncChunkResolverComponent: AsyncChunkComponent,
  asyncChunkPropsFn,
  pickChunkInfoFn,
  listRenderFn,
  headerRenderFn,
  toolbarRenderFn,
  insertRenderFn,
  rememberScrollPosition,
  allowIdleLoad,
  onScroll,
  ...props
}, ref) => {
  const containerRef = useRef(null);
  const {
    initialState,
    cacheLoaded,
    rememberScrollFlagAccessor,
  } = useListRestoreScrollInitials(rememberScrollPosition);

  const [state, setState] = useState(initialState);

  const {
    prevChunk,
    visibleChunksCount,
    __cache,
  } = state;

  const onResetChunks = useCallback(() => {
    setState(
      {
        visibleChunksCount: 1,
      },
    );
  }, []);

  const onLoadNextChunk = useCallback(
    (prev) => {
      setState(
        {
          prevChunk: prev,
          visibleChunksCount: prev.index + 2, // index starts from 0
        },
      );
    },
    [],
  );

  const getContainerReservedSpace = () => (
    [...containerRef
      .current
      .querySelectorAll(`[${ASYNC_CHUNK_DATA_ATTRIB}]`),
    ]
      .map(el => el.offsetHeight)
  );

  // cache is created on new route
  const {
    chunkSpaces,
    chunkIndex: cachedChunkIndex,
  } = __cache || {};

  const renderChunk = (chunkIndex) => {
    const chunkVariables = {
      visibleChunksCount: state.visibleChunksCount,
      chunkIndex,
      prevChunk,
    };

    const cachedHeight = chunkSpaces && chunkSpaces[chunkIndex];
    const content = (
      <AsyncChunkComponent
        key={chunkIndex}
        {...props}
        {...asyncChunkPropsFn && asyncChunkPropsFn(chunkVariables, props)}
      >
        {(data, ...args) => {
          const chunkInfo = pickChunkInfoFn(chunkIndex, data);
          if (state.visibleChunksCount > chunkInfo.totalChunks)
            state.visibleChunksCount = Math.min(1, chunkInfo.totalChunks);

          let header = null;
          let toolbar = null;

          if (headerRenderFn && !chunkIndex && chunkInfo.totalChunks > 0) {
            header = headerRenderFn(
              {
                data,
                chunkInfo,
              },
            );
          }

          if (toolbarRenderFn && chunkIndex === state.visibleChunksCount - 1) {
            if (chunkIndex < chunkInfo.totalChunks) {
              toolbar = toolbarRenderFn(
                {
                  data,

                  chunkIndex,
                  chunkInfo,
                  visibleChunksCount: state.visibleChunksCount,

                  onLoadNextChunk: () => onLoadNextChunk(chunkInfo),
                  onResetChunks,
                },
              );
            }
          }

          chunkVariables.info = chunkInfo;

          const chunkElement = listRenderFn(data, chunkVariables, ...args);
          const primaryLoadDelta = cachedChunkIndex - chunkIndex;

          const chunkContent = !R.isNil(chunkElement) && (
            <div
              data-async-chunk='true'
              onClick={() => {
                if (!rememberScrollPosition)
                  return;

                rememberScrollFlagAccessor.set(
                  {
                    __cache: {
                      scrollY: window.scrollY,
                      chunkIndex,
                      chunkSpaces: getContainerReservedSpace(),
                    },
                    visibleChunksCount: state.visibleChunksCount,
                    prevChunk,
                  },
                );
              }}
              {...cachedHeight && {
                style: {
                  minHeight: cachedHeight,
                },
              }}
            >
              {!ssr && cachedHeight && primaryLoadDelta !== 0
                ? (
                  <IdleRender>
                    {chunkElement}
                  </IdleRender>
                )
                : chunkElement}
              {!prevChunk && insertRenderFn && insertRenderFn(
                {
                  ...chunkVariables,
                  prevChunk: chunkInfo,
                },
              )}
            </div>
          );

          return (
            <>
              {header}
              {chunkContent}
              {toolbar}
            </>
          );
        }}
      </AsyncChunkComponent>
    );

    return (
      <React.Fragment key={chunkIndex}>
        {content}
        {prevChunk && insertRenderFn && insertRenderFn(chunkVariables)}
      </React.Fragment>
    );
  };

  const container = (
    <div
      ref={(node) => {
        containerRef.current = node;
        if (ref)
          ref(node);
      }}
      className={className}
    >
      {R.times(
        renderChunk,
        visibleChunksCount,
      )}
    </div>
  );

  if (allowIdleLoad && !cacheLoaded) {
    return (
      <IdleRender>
        {container}
      </IdleRender>
    );
  }

  return container;
});

ExpandableFunctionalChunksList.propTypes = {
  asyncChunkResolverComponent: REACT_COMPONENT_CLASS_SCHEMA.isRequired,
  asyncChunkPropsFn: PropTypes.func,
  pickChunkInfoFn: PropTypes.func.isRequired,
  listRenderFn: PropTypes.func.isRequired,
  toolbarRenderFn: PropTypes.func,
  headerRenderFn: PropTypes.func,
  insertRenderFn: PropTypes.func,
  rememberScrollPosition: PropTypes.bool,
  allowIdleLoad: PropTypes.bool,
};

ExpandableFunctionalChunksList.defaultProps = {
  rememberScrollPosition: false,
  toolbarRenderFn: textToolbarFn,
};

export default React.memo(ExpandableFunctionalChunksList);
