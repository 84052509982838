import * as R from 'ramda';

import stringLens from '@adretail/basic-helpers/src/base/stringLens';
import {
  OUTER_ITEM_MATCH_REGEX,
  INNER_ITEM_MATCH_REGEX,

  argsListToSource,
  isPathKey,
  defaultToEmpty,
} from '@adretail/basic-helpers/src/base/format';

const innerItemMatch = R.match(
  new RegExp(INNER_ITEM_MATCH_REGEX.source), // remove g flag
);

const reactFormat = (str, ...args) => {
  const tokens = R.split(OUTER_ITEM_MATCH_REGEX, str);
  if (!tokens.length)
    return null;

  const source = argsListToSource(args);
  let counter = 0;

  return R.map(
    (token) => {
      const match = innerItemMatch(token);
      if (match.length) {
        const key = match[1] || (counter++);

        if (isPathKey(key))
          return stringLens(key)(source);

        return defaultToEmpty(source[key]);
      }

      return token;
    },
    tokens,
  );
};

export default reactFormat;
