import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  PROPS_SCHEMA,
  LIST_ITEM_SCHEMA,
  REACT_COMPONENT_CLASS_SCHEMA,
} from '@adretail/schemas';

import applyIfFunction from '@adretail/basic-helpers/src/base/applyIfFunction';
import inputValue from '@adretail/basic-helpers/src/inputs/inputValue';
import suppressEvent from '@adretail/basic-helpers/src/inputs/suppressEvent';
import {findById} from '@adretail/basic-helpers/src/base/findIndexById';

import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';
import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import FadeAppear from '@adretail/basic-components/src/Anim/FadeAppear';
import RerenderWhen from '@adretail/basic-components/src/Functional/RerenderWhen';

import {RelativeContainer} from '@ding/core/src/components/Predefined';

import AutocompleteExpandIcon from './AutocompleteExpandIcon';
import AutocompleteDropdown from './AutocompleteDropdown';
import AutocompleteInput from './AutocompleteInput';
import AutocompleteItemsList, {createBlankValue} from './AutocompleteItemsList';

import * as Items from './Items';

export {
  createBlankValue,
};

export const isIdValue = value => R.is(Number, value) || R.is(String, value);

const matchStringValues = provideProps(
  ({value, list}) => {
    if (!isIdValue(value) || !list)
      return null;

    return {
      value: findById(value, list),
    };
  },
  true,
);

/**
 * @see
 * Do not default forwardOnChange value(false item),
 * resetOnSelect autocomplete should not update value every time.
 *
 * If resetOnSelect is selected
 *  do not provide value prop! It will force override input value
 */
export default
@linkedInputs(null, false)
@matchStringValues
class Autocomplete extends React.PureComponent {
  static propTypes = {
    // if true, onChange will emit only ID value from selected input
    // instead of {id, name}, use in callendar inputs, it should be
    // handled in matchStringValue
    returnIdOnly: PropTypes.bool,

    // AbstractComponentsList props
    // optional, list might be render also using children
    list: PropTypes.arrayOf(LIST_ITEM_SCHEMA),
    addEmptyOption: PropTypes.bool,
    alwaysShowEmptyOption: PropTypes.bool,

    listComponent: REACT_COMPONENT_CLASS_SCHEMA,
    itemComponent: REACT_COMPONENT_CLASS_SCHEMA,
    inputComponent: REACT_COMPONENT_CLASS_SCHEMA,
    dropdownComponent: REACT_COMPONENT_CLASS_SCHEMA,
    emptyItemComponent: REACT_COMPONENT_CLASS_SCHEMA,

    showPopupWhenEmptyPhrase: PropTypes.bool,

    // props forwarders
    listProps: PROPS_SCHEMA,
    dropdownProps: PROPS_SCHEMA,
    itemProps: PROPS_SCHEMA,
    inputProps: PropTypes.oneOfType([
      PropTypes.func,
      PROPS_SCHEMA,
    ]),

    // input props used by autocomplete
    inline: PropTypes.bool, // autocomplete is small, within text
    allowCloseInputClick: PropTypes.bool, // allow close autocomplete if user clicks

    maxListHeight: PropTypes.number,
    dropdownStyle: PropTypes.objectOf(PropTypes.any),
    dropdownContentMargin: PropTypes.number, // spacing between start list and input

    inputOverDropdown: PropTypes.bool,

    // if true - renders chevron down in right corner of component
    expandIcon: PropTypes.bool,
    allowInputChange: PropTypes.bool,

    // if true - components resets its content after user select value
    // onChange will be provided
    resetOnSelect: PropTypes.bool,

    // used when even user no selected any option
    // but is still typing and get value from actually typed input text
    // warn: it is slow, default disabled
    watchInputValueChange: PropTypes.bool,

    // if true - ENTER or etc. will enable null ID selecting
    // inactive when addEmptyOption is active
    allowReturnEmptyID: PropTypes.bool,
  };

  static defaultProps = {
    dropdownContentMargin: 0,
    maxListHeight: 350,

    itemComponent: Items.Plain,
    listComponent: AutocompleteItemsList,
    inputComponent: AutocompleteInput,
    dropdownComponent: AutocompleteDropdown,

    allowCloseInputClick: false,
    inputOverDropdown: true,
    returnIdOnly: false,
    resetOnSelect: false,
    showPopupWhenEmptyPhrase: false, // ignored when provided list
    watchInputValueChange: false,
    allowInputChange: true,
    allowReturnEmptyID: true,
  };

  inputRef = null; // not work with React.createRef();

  state = {
    active: false,
    visible: false, // active do not handle animation delay

    inputSize: null,
    prevValue: null,
  };

  static getDerivedStateFromProps({value}, {prevValue}) {
    // do not blur if user erased item during focus
    // (if input has not reset onSelect)
    if (prevValue?.id !== value?.id && !R.isNil(value?.id)) {
      return {
        active: false,
        visible: false,
        prevValue: value,
      };
    }

    return false;
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  onShowPopup = () => {
    const {inputRef} = this;
    const {allowCloseInputClick} = this.props;
    const {
      active,
      visible,
    } = this.state;

    if (!inputRef)
      return;

    if (active && visible) {
      if (!this.blurLock && allowCloseInputClick) {
        this.setState(
          {
            active: false,
            visible: false,
          },
        );
      }

      return;
    }

    this.blurLock = true;
    this.setState(
      {
        active: true,
        visible: true,
        inputSize: inputRef.getBoundingClientRect(),
      },
      () => {
        setTimeout(
          () => {
            this.blurLock = false;
          },
          250,
        );
      },
    );
  };

  onBlur = () => new Promise(
    (resolve) => {
      if (this.blurLock || !this.state.active)
        return resolve();

      this.setState(
        {
          active: false,
        },
      );

      setTimeout(
        () => {
          const {active} = this.state;

          !this.unmounted && !active && this.setState(
            {
              visible: false,
            },
            resolve,
          );
        },
        500,
      );

      return null;
    },
  );

  onFocus = () => {
    setTimeout(this.onShowPopup, 40);
  };

  onSelectItem = (item) => {
    const {
      l, resetOnSelect, allowReturnEmptyID,
      returnIdOnly, onChange,
    } = this.props;

    const newValue = (
      returnIdOnly
        ? R.propOr(null, 'id', item)
        : item
    );

    if (R.isNil(item.id) && !allowReturnEmptyID)
      return;

    // todo: make less rerenders?
    if (resetOnSelect) {
      l.onSilentUpdateValue(
        createBlankValue(null),
      );

      onChange && onChange(newValue);
    } else
      l.onInputEvent(newValue, true);

    this.onBlur();
  };

  onChangeValue = (phrase) => {
    const {active} = this.state;
    const {value, watchInputValueChange, l} = this.props;

    const newValue = (
      R.has('id', phrase)
        ? phrase
        : createBlankValue(inputValue(phrase))
    );

    // show popup if not visible
    if (!active)
      this.onShowPopup();

    // if user resets ID force trigger event
    const userDeselectValue = !R.isNil(value?.id) && R.isNil(newValue.id);
    if (watchInputValueChange || userDeselectValue)
      l.onInputEvent(newValue, true);
    else
      l.onSilentUpdateValue(newValue);
  }

  onOutsideClick = (e) => {
    const {inputRef} = this;
    if (e && e.target && inputRef && inputRef.contains(e.target))
      return;

    this.onBlur();
  }

  onKeyPress = (e) => {
    const {allowReturnEmptyID, list} = this.props;
    const {phrase} = this;

    // if enter select value
    // it should be suppressed in hightlight list
    if (e.which === 13) {
      const normalizeItemTitle = R.compose(R.toLower, R.trim);
      const trimPhrase = normalizeItemTitle(phrase);

      let item = list && R.find(
        ({name}) => normalizeItemTitle(name || '') === trimPhrase,
        list,
      );

      if (!item && allowReturnEmptyID)
        item = createBlankValue(phrase);

      if (item)
        this.onSelectItem(item);
    }
  };

  onReset = () => {
    const {l} = this.props;

    l.onResetInputs();
    this.onBlur();
  }

  get phrase() {
    return this.props.value?.name || '';
  }

  get childrenContentListeners() {
    return {
      onSelectItem: this.onSelectItem,
      onChangePhrase: this.onChangeValue,
      onFocus: this.onShowPopup,
      onBlur: this.onBlur,
      onReset: this.onReset,
    };
  }

  render() {
    const {phrase, childrenContentListeners, onSelectItem} = this;
    const {
      active,
      visible,
      inputSize,
    } = this.state;

    const {
      inputComponent: InputComponent,
      dropdownComponent: DropdownComponent,
      listComponent: ListComponent,
      itemComponent,
      listProps,
      inputProps,
      itemProps,
      dropdownProps,
      containerProps,
      placeholder,
      list,
      addEmptyOption,
      alwaysShowEmptyOption,
      className,
      style,
      dropdownStyle,
      dropdownContentMargin,
      value,
      children,
      maxListHeight,
      showPopupWhenEmptyPhrase,
      expandIcon,
      allowInputChange,
      inputOverDropdown,
      inline,
      emptyItemComponent,
      onKeyPress,
    } = this.props;

    const renderPopup = !!(active && (showPopupWhenEmptyPhrase || list || phrase));

    let childs = null;
    if (renderPopup) {
      childs = (children && children(value, childrenContentListeners)) || (
        <ListComponent
          {...listProps}
          {...{
            emptyItemComponent,
            addEmptyOption,
            alwaysShowEmptyOption,
            list,
            value,
            itemComponent,
            itemProps,
          }}
          onSelectItem={this.onSelectItem}
          onBlur={this.onBlur}
        />
      );
    }

    const content = !DropdownComponent
      ? childs
      : (
        <FadeAppear in={renderPopup}>
          {(animStyle) => {
            const layerOffset = (inputSize?.height || 0) + (dropdownContentMargin || 0);
            const dropdownMergedProps = {
              ...dropdownProps,
              style: {
                ...animStyle,
                ...dropdownStyle,
              },

              maxListHeight,
              layerOffset,
              onOutsideClick: this.onOutsideClick,
            };

            return childs && (
              <DropdownComponent {...dropdownMergedProps}>
                <RerenderWhen
                  rerenderKey={
                    `${value?.name}-${value?.id}`
                  }
                >
                  {() => childs}
                </RerenderWhen>
              </DropdownComponent>
            );
          }}
        </FadeAppear>
      );

    return (
      <RelativeContainer
        block
        innerRef={
          (ref) => {
            this.inputRef = ref;
          }
        }
        style={{
          ...style,
          display: inline ? 'inline-block' : undefined,
        }}
        {...{
          className,
          onKeyPress,
        }}
        {...containerProps}
        onClick={suppressEvent}
      >
        {content}

        <div
          style={{
            height: 'inherit',

            ...visible && inputOverDropdown && {
              position: 'relative',
              zIndex: 1001,
            },
          }}
          onClick={this.onShowPopup}
          onFocus={this.onFocus}
          {...allowInputChange && {
            onKeyPress: this.onKeyPress,
          }}
        >
          <InputComponent
            {...placeholder && {
              placeholder,
            }}
            {...applyIfFunction(
              [value, childrenContentListeners, {active, onSelectItem}],
              inputProps,
            )}
            {...!allowInputChange && {
              readOnly: true,
              style: {
                cursor: 'pointer',
              },
            }}
            active={renderPopup}
            inline={inline}
            value={phrase}
            onChange={this.onChangeValue}
          />

          {expandIcon && (
            <AutocompleteExpandIcon verticalFlip={active} />
          )}
        </div>
      </RelativeContainer>
    );
  }
}
