import PropTypes from 'prop-types';

import {
  ID_SCHEMA,
  LIST_ITEM_SCHEMA,
} from '@adretail/schemas';

import {RANGE_SCHEMA} from './basic';

export const ID_ROUTE_MATCH_SCHEMA = PropTypes.shape({
  params: PropTypes.shape({
    id: ID_SCHEMA.isRequired,
  }),
});

export const LOGO_ITEM_SCHEMA = PropTypes.shape(
  {
    id: ID_SCHEMA.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
  },
);

export const DEFAULT_GRID_FILTERS_SCHEMA = PropTypes.shape(
  {
    categoryId: ID_SCHEMA,
    contractorId: ID_SCHEMA,
  },
);

export const DEFAULT_SEARCH_VARIABLES_SCHEMA = PropTypes.shape(
  {
    page: PropTypes.number,
    filters: DEFAULT_GRID_FILTERS_SCHEMA,
    sortBy: PropTypes.string,
  },
);

export const PRODUCT_SEARCH_FILTERS_SCHEMA = PropTypes.shape(
  {
    name: PropTypes.string,
    contractors: PropTypes.objectOf(LIST_ITEM_SCHEMA),
    categories: PropTypes.objectOf(LIST_ITEM_SCHEMA),
    archivalOnly: PropTypes.bool,
    price: RANGE_SCHEMA,
  },
);
