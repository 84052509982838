import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {getHOCDisplayName} from '@adretail/basic-helpers/src/getters/getDisplayName';
import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';

import {
  Flex,
  RelativeContainer,
} from '@ding/core/src/components/Predefined';

import Input from '.';

const defaultToEmpty = R.defaultTo('');

/**
 * Adds support for left / right icon for component
 *
 * @param {Object} iconCss
 */
export const withIconDecoration = iconCss => (Component) => {
  const Wrapped = React.forwardRef(
    ({
      iconLeft, iconRight, classes,
      wrapperClassName, value, children,
      ...props
    }, ref) => (
      <RelativeContainer
        component='div'
        className={wrapperClassName}
      >
        <Component
          ref={ref}
          {...props}
          {...props.onChange && {
            value: defaultToEmpty(value),
          }}
          spaceLeft={!!iconLeft}
          spaceRight={!!iconRight}
        />
        {iconLeft && (
          <Flex.Centered className={classes.iconLeft}>
            {iconLeft}
          </Flex.Centered>
        )}
        {iconRight && (
          <Flex.Centered className={classes.iconRight}>
            {iconRight}
          </Flex.Centered>
        )}
      </RelativeContainer>
    ),
  );

  Wrapped.displayName = getHOCDisplayName('withIconDecoration', Component);

  Wrapped.propTypes = {
    iconLeft: PropTypes.element,
    iconRight: PropTypes.element,
  };

  return injectClassesSheet(iconCss)(Wrapped);
};

const css = {
  icon: {
    position: 'absolute',
    minWidth: 50,
    top: '50%',
    height: 22,
    marginTop: -11,

    '& > i': {
      height: 'inherit',
    },
  },

  iconLeft: {
    composes: '$icon',
    left: 0,
  },

  iconRight: {
    composes: '$icon',
    right: 0,
  },
};

export default withIconDecoration(css)(Input);
