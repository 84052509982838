import {useEffect} from 'react';
import useUUID from '@adretail/basic-hooks/src/base/useUUID';

/**
 * It is adding query to refetch store
 *
 * @see
 *  UUID is QUERY UUID
 */
const useQueryRefetchStore = ({
  query,
  variables,

  queryUUID, // UUID is generated based on variables and query, it can duplicate
  clientContext,

  loadData,
  refetchData,

  // addional parameters passed to attachQuery()
  ...attachParams
}) => {
  const invokerUUID = useUUID();

  useEffect(
    () => {
      clientContext.attachQuery(
        queryUUID,
        {
          invokerUUID,
          loadData,
          refetchData,
          ...attachParams,
        },
      );

      return () => {
        clientContext.detachQuery(queryUUID, invokerUUID);
      };
    },
    [
      queryUUID,
      clientContext?.visibleQueries,
      loadData,
      refetchData,
    ],
  );
};

const QueryRefetchStoreItem = ({children, refetchConfig}) => {
  useQueryRefetchStore(refetchConfig);

  return children || null;
};

QueryRefetchStoreItem.displayName = 'QueryRefetchStoreItem';

export {
  QueryRefetchStoreItem,
};

export default useQueryRefetchStore;
