
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeafletCard"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Leaflet"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"cover"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"size"},"value":{"kind":"StringValue","value":"C111","block":false}}],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"coverOnlyForAdults"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"archived"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"promoted"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"hit"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"contractor"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"logo"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"duration"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"begin"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"end"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"mainCategory"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":226}};
    doc.loc.source = {"body":"fragment LeafletCard on Leaflet {\n  id\n  name\n  cover(size: \"C111\")\n  coverOnlyForAdults\n  archived\n  promoted\n  hit\n  contractor {\n    id\n    name\n    logo\n  }\n  duration {\n    begin\n    end\n  }\n  mainCategory {\n    id\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
