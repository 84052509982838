import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';
import renderFunctionalChildren from '@adretail/basic-helpers/src/ui/renderFunctionalChildren';

import TitledCheckbox from './TitledCheckbox';

/**
 * Checkbox that does not contain only one bool value - it contains
 * also nested inputs etc and should return at least object
 *
 * @export
 */
const InnerTitleInputCheckbox = ({
  children, l, value, defaultInnerValue, externalChildren,
  ...props
}) => {
  const childs = renderFunctionalChildren(children, l);
  const inputProps = {
    value: !!value,
    onChange: flag => l.onInputEvent(
      flag
        ? R.defaultTo(defaultInnerValue, value)
        : false,
    ),
  };

  if (externalChildren) {
    return (
      <>
        <TitledCheckbox
          {...props}
          {...inputProps}
        >
          {childs}
        </TitledCheckbox>

        {externalChildren(l, value)}
      </>
    );
  }

  return (
    <TitledCheckbox
      {...props}
      {...inputProps}
    >
      {childs}
    </TitledCheckbox>
  );
};

InnerTitleInputCheckbox.displayName = 'InnerTitleInputCheckbox';

InnerTitleInputCheckbox.propTypes = {
  // value which is set when user clicks titled checkbox
  // but previously nothing has beend entered
  defaultInnerValue: PropTypes.any,

  // renders children below component if true
  externalChildren: PropTypes.func,
};

InnerTitleInputCheckbox.defaultProps = {
  defaultInnerValue: {},
};

export default linkedInputs(null, true)(InnerTitleInputCheckbox);
