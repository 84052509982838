import React from 'react';
import PropTypes from 'prop-types';
import styled from '@adretail/fast-stylesheet/src/react/styled';

import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';
import {
  LIGHT_CARD_GRAY,
  CARD_GRAY,
  WHITE,
} from '@ding/constants/src/colorSchema';
import {ENV_ALERT_HEIGHT, TRUNCATE_NAV_SIZE} from '@ding/layout/src/constants/navSize';

const SectionHolder = styled(
  React.forwardRef(
    ({wrapInnerContent, children, innerRef, sectionTag: Section, ...props}, ref) => (
      <Section
        {...props}
        ref={ref}
      >
        {(
          wrapInnerContent
            ? <div>{children}</div>
            : children
        )}
      </Section>
    ),
  ),
  {
    base: {
      position: 'relative', // for right corner buttons
      display: 'block',
      width: '100%',
      background: LIGHT_CARD_GRAY,
      scrollMarginTop: TRUNCATE_NAV_SIZE + ENV_ALERT_HEIGHT, // for hash scroll

      '& > div': {
        paddingTop: 30,
        paddingBottom: 30,

        '&:empty': {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },

    subsection: {
      '& > div': createBreakpointClasses(
        {
          xs: {
            paddingTop: 15,
            paddingBottom: 15,
          },

          md: {
            paddingTop: 20,
            paddingBottom: 20,
          },
        },
      ),
    },

    noBottomSpace: {
      '& > div': {
        paddingBottom: 0,
      },
    },

    noTopSpace: {
      '& > div': {
        paddingTop: 0,
      },
    },

    dark: {
      background: CARD_GRAY,
    },

    white: {
      background: WHITE,
    },
  },
  {
    omitProps: [
      'dark',
      'white',
      'noBottomSpace',
      'noTopSpace',
      'subsection',
    ],
    classSelector: (classes, {white, dark, noBottomSpace, noTopSpace, subsection}) => [
      dark && classes.dark,
      white && classes.white,
      noBottomSpace && classes.noBottomSpace,
      noTopSpace && classes.noTopSpace,
      subsection && classes.subsection,
    ],
  },
);

SectionHolder.displayName = 'SectionHolder';

SectionHolder.propTypes = {
  subsection: PropTypes.bool,
  dark: PropTypes.bool,
  white: PropTypes.bool,
  noBottomSpace: PropTypes.bool,
  noTopSpace: PropTypes.bool,
  wrapInnerContent: PropTypes.bool, // css trick to check if PageContainer has no childs
  sectionTag: PropTypes.string,
};

SectionHolder.defaultProps = {
  wrapInnerContent: true,
  sectionTag: 'section',
};

export default SectionHolder;
