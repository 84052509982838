import styled from '@adretail/fast-stylesheet/src/react/styled';
import ExpandIcon from '@ding/core/src/icons/Expand';

const AutocompleteExpandIcon = styled(
  ExpandIcon,
  {
    position: 'absolute',
    top: 'calc(50% - 7px)',
    right: 15,
  },
  {
    type: 'primary',
    size: 'tiny',
  },
);

AutocompleteExpandIcon.displayName = 'AutocompleteExpandIcon';

export default AutocompleteExpandIcon;
