export default {
  titles: {
    layer_title: 'Logowanie',
    login: 'Zaloguj się',
    logout: 'Wyloguj się',
    register: 'Zarejestruj się',
    register_form: 'Rejestracja',
    create_account: 'Załóż konto',
    remember_me: 'Zapamiętaj mnie',
    reset_password: 'Nie pamiętam hasła',
    set_new_password: 'Ustaw nowe hasło',
    set_password: 'Ustaw hasło',
    correct_password: 'Poprawne hasło',
    remind_password: 'Przypomnij hasło',
  },

  buttons: {
    open_auth_menu: 'Otwórz menu użytkownika',
    google_login: 'Kontynuuj przy użyciu Google',
    facebook_login: 'Kontynuuj przy użyciu Facebook',
  },

  modals: {
    header_cta: 'Otrzymuj powiadomienia o najnowszych promocjach!',
  },

  oauth_register: {
    accept_rules_to_register: 'Aby utworzyć konto, prosimy o udzielenie poniższych zgód:',
  },

  errors: {
    not_remember_password: 'Błędny adres email lub hasło.',
    email_taken: 'Zarejestrowałeś się już w naszym serwisie używając adresu %{}. %{} lub %{}, jeśli go nie pamiętasz.',
  },

  validation: {
    length: '5-32 znaków',
    letter: 'min. 1 litera',
    special: 'min. 1 cyfra lub znak specjalny',
  },

  labels: {
    your_birth_date: 'Twoja data urodzenia',
    minimum_age: 'Wymagany wiek to minimum %{} lat',
  },

  confirm: {
    agree_age: {
      ding_rules: 'regulaminu serwisu ding.pl',
      content: '* Oświadczam, że mam ukończone 16 lat, zapoznałem/-am się z treścią %{} i akceptuję jego postanowienia oraz wyrażam zgodę na rozpoczęcie świadczenia, co oznacza utratę przeze mnie ustawowego prawa do odstąpienia',
    },
    agree_rodo: 'Wyrażam zgodę na otrzymywanie na podany przeze mnie adres e-mail informacji handlowych wysyłanych przez INTERIA.PL sp. z o.o.. w imieniu własnym oraz na zlecenie innych osób. Przyjmuję do wiadomości, że mogę w dowolnym momencie wycofać tę zgodę. Wycofanie przeze mnie zgody nie ma wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie mojej zgody przed jej wycofaniem.',
    personalized_data_migration: 'Przenieś dotychczasowe ustawienia, ulubione sieci, i listę zakupową do utworzonego konta.',
  },

  registration: {
    confirm_email: 'Potwierdź adres e-mail',
    message_sent_to_email: 'Na adres e-mail %{} została wysłana wiadomość w temacie:',
    email_title: 'Rejestracja w serwisie %{}',
    finalize: 'Aby dokończyć proces rejestracji, kliknij link w przesłanej do Ciebie wiadomości.',
  },

  rodo_description: {
    administrator: 'Administrator Danych: INTERIA.PL sp. z o.o.',
    target: 'Cel przetwarzania danych: Świadczenie usługi elektronicznej',
    law: 'Podstawa prawna: Wykonanie umowy - więcej',
    receivers: 'Odbiorcy danych: Dane nie będą udostępniane innym odbiorcom, poza przypadkami wskazanymi w przepisach prawa',
    your_rights: 'Twoje prawa: Prawo dostępu, poprawiania lub żadania usunięcia danych, a także inne prawa których opis znajduje się',
    additional: 'Informacje dodatkowe: Więcej informacji o przetwarzaniu danych znajduje się w zakładce',
  },

  links: {
    withdrawal_contract: 'Odstąpienie od umowy świadczenia usług',
    website_regulations: 'Regulamin serwisu',
    portal_regulations: 'Regulamin portalu',
  },

  recaptcha: {
    flow_row_title: 'This site is protected by reCAPTCHA and the Google %{} and %{} apply.',
    privacy: 'Privacy Policy',
    terms: 'Terms of Service',
  },

  toast: {
    missing_email: 'Brak adresu e-mail przypisanego do konta!',
    email_already_taken: 'Konto z podanym adresem e-mail zostało już zarejestrowane!',
    reset_password: {
      success: 'Zmieniono hasło!',
      error: 'Problem podczas zmiany hasła!',
    },

    account_confirm: {
      success: 'Konto potwierdzone!',
      error: 'Ten link aktywacyjny nie jest już aktywny. Zarejestruj się jeszcze raz.',
    },

    logout: {
      success: 'Wylogowano',
      error: 'Nastąpił problem podczas wylogowywania',
    },

    login: {
      success: 'Zalogowano pomyślnie',
      error: 'Nastąpił problem podczas logowania',
    },
  },

  sections: {
    reset_password: {
      title: 'Hasło',
      header: 'Nie pamiętam hasła',
      email_reset_info: 'Na Twój adres e-mail wysłany zostanie link, dzięki któremu uzyskasz dostęp do strony umożliwiającej ustawienie nowego hasła.',
      type_login_email: 'Wpisz adres e-mail przy pomocy którego się logujesz:',

      sent_email_done: 'Gotowe!',
      link_sent_to_email: 'Na podany przez Ciebie adres e-mail został wysłany link do strony umożliwiającej ustawienie nowego hasła.',
      click_to_set_password: 'Aby ustawić nowe hasło, kliknij link w otrzymanym e-mailu. Jeżeli nie dostałeś e-maila, sprawdź w folderze SPAM',
    },
  },
};
