import {useRef} from 'react';

import useDebounce from '@adretail/basic-hooks/src/base/useDebounce';
import {useTrackers} from '@ding/interia';
import {isCorrectPlacementForGA4Tracking} from '../isCorrectPlacementForGA4Tracking';

const useTrackSidebarScrollDepth = () => {
  const {ga4, iwa} = useTrackers();
  const debounceTrack = useDebounce(
    {
      delay: 1000,
      initialInstant: false,
    },
    (depth, placement) => {
      iwa.sidebarScrollDepth(
        {
          depth,
        },
      );

      if (isCorrectPlacementForGA4Tracking(placement))
        ga4.sidebarScrollDepth(
          {
            depth,
          },
        );
    },
  );

  const prevItems = useRef(null);
  return ({items, placement}) => {
    if (items > prevItems.current) {
      debounceTrack(items, placement);
      prevItems.current = items;
    }
  };
};

export default useTrackSidebarScrollDepth;
