import React from 'react';
import PropTypes from 'prop-types';

import {ERRORS_LIST_SCHEMA} from '@ding/constants';
import translate from '@ding/i18n';

import {TitledDivider} from '@ding/core/src/components/Parts';
import showUserToast from '@ding/controls/src/ToastAlert/types/showUserToast';

import {OAuthButtonsRow} from '../Shared';
import LoginBufferedControlGroup from './LoginBufferedControlGroup';

export default
@translate
class LoginForm extends React.PureComponent {
  static propTypes = {
    errors: ERRORS_LIST_SCHEMA,
    loginUserUtils: PropTypes.shape(
      {
        setFreshLogin: PropTypes.func.isRequired,
        fetchUserToken: PropTypes.func.isRequired,
        setTokenAndRefetch: PropTypes.func.isRequired,
      },
    ).isRequired,

    onCloseModal: PropTypes.func,
    onChangeModalAuthTab: PropTypes.func,
  };

  /**
   * Collapses modal and then loads token
   */
  onReceiveLoginToken = async (token) => {
    const {t, loginUserUtils, onCloseModal} = this.props;

    await loginUserUtils.setFreshLogin(true);
    await onCloseModal();
    await loginUserUtils.setTokenAndRefetch(token);

    showUserToast(
      t('website.auth.toast.login.success'),
    );
  };

  /**
   * Fetch token based on user form value
   * and triggers onReceiveToken
   */
  onFormSubmit = async (formValue) => {
    const {loginUserUtils} = this.props;

    try {
      this.onReceiveLoginToken(
        await loginUserUtils.fetchUserToken(formValue),
      );
    } catch (e) {
      // todo add error handler
    }
  }

  render() {
    const {errors, onCloseModal, onChangeModalAuthTab} = this.props;

    return (
      <>
        <OAuthButtonsRow
          buttonProps={{
            onBeforeRegisterRedirect: onCloseModal,
            onBeforeLoadToken: onCloseModal,
          }}
        />

        <TitledDivider.OR space='medium' />
        <LoginBufferedControlGroup
          errors={errors}
          onSubmit={this.onFormSubmit}
          onChangeModalAuthTab={onChangeModalAuthTab}
        />
      </>
    );
  }
}
