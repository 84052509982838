import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import HeaderHolder from '../HeaderHolder';

/**
 * Red primary bar used in navigation and fullscreen popups
 *
 * @see
 *  used in:
 *    MobileFullscreenPopup
 *    MobileHeader
 *
 * @export
 */
const MobileHeaderHolder = provideProps(
  {
    truncated: true,
  },
  true,
)(HeaderHolder);

MobileHeaderHolder.displayName = 'MobileHeaderHolder';

export default MobileHeaderHolder;
