const toggleAllNotifications = 'Włącz powiadomienia o ulubionych produktach';

export default {
  product_card: {
    title: 'Karta produktu',
  },

  notification_section: {
    toggle_product_tags: toggleAllNotifications,
    toggle_product_tags_label: `${toggleAllNotifications}:`,
  },
};
