import React from 'react';
import * as R from 'ramda';

import {parameterize} from '@ding/core/src/helpers';
import {UndecoratedLink} from '@ding/core/src/components/Predefined';

import createLinkComponent from '../../utils/createLinkComponent';
import {genLeafletURL} from './LeafletLink';

export const PRODUCT_URL_SCHEMA = '/oferta/:name,:id(\\d+)';

export const genProductURL = ({item, product, layer, toLeafletPage}) => {
  const safeProduct = item || product;
  const {name, originPage} = safeProduct;

  if (toLeafletPage)
    return genLeafletURL(originPage.leaflet, originPage.index + 1);

  if (layer)
    return genLeafletURL(originPage.leaflet, originPage.index + 1, safeProduct);

  return `/oferta/${parameterize(name)},${R.defaultTo(safeProduct.id, safeProduct.productId)}`;
};

export const ProductExternalLink = ({product, ...props}) => (
  <UndecoratedLink
    to={product?.url}
    target='_blank'
    rel='noreferrer noopener nofollow'
    {...props}
  />
);

export default createLinkComponent(
  [
    'product',
    'layer', // loads product on layer ontop leaflet
    'toLeafletPage', // focuses on leaflets
  ],
  (props, params) => genProductURL(params),
);
