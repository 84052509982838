import React, {useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {ID_SCHEMA} from '@adretail/schemas';
import {LEAFLETS_SIDEBAR_WIDTH_BREAKPOINT} from '@ding/slide-viewer/src/Viewer/constants';

import {trackComponent} from '@ding/interia';

import SelectedForUserLeafletsQuery, {SUGGESTED_LEAFLETS_PLACEMENT} from '@ding/api/src/gql/geo/SelectedForUserLeafletsQuery';
import OffsetQueryChunksList from '@ding/control-groups/src/ExpandableFunctionalChunksList/OffsetQueryChunksList';
import LeafletCard from '@ding/cards/src/Leaflet/LeafletCard';

import ViewportIntersectionToolbar, {viewportIntersectionToolbarFn} from '@ding/control-groups/src/ExpandableFunctionalChunksList/toolbars/ViewportIntersectionToolbar';
import {getParentScrollableTag} from '@ding/controls/src/HoverableScrollbarContainer';

import CurrentlyVisibleLeafletCard from '@ding/cards/src/Leaflet/CurrentlyVisibleLeafletCard';
import SidebarLeafletsGrid from '@ding/slide-viewer/src/Viewer/components/SidebarLeafletsGrid';
import {useViewerState} from '@ding/slide-viewer/src/Viewer/context/components/ViewerState';

import SidebarInsertChunk from './SidebarInsert';
import useTrackSidebarScroll from './hooks/useTrackSidebarScroll';
import {isCorrectPlacementForGA4Tracking} from './isCorrectPlacementForGA4Tracking';

export const CURRENTLY_VISIBLE_LEAFLET_INDICES_SCHEMA = PropTypes.shape(
  {
    id: ID_SCHEMA,
    pageIndices: PropTypes.arrayOf(PropTypes.number),
  },
);

export const isCurrentlyVisibleCard = (currentlyVisibleLeaflet, item) => {
  if (!currentlyVisibleLeaflet)
    return false;

  const {creatorMeta, originPage} = item;
  const {pageIndices} = currentlyVisibleLeaflet;

  return (
    item.id === currentlyVisibleLeaflet.id
      || (creatorMeta && currentlyVisibleLeaflet.id === creatorMeta.id)
  ) && (
    !pageIndices
      || !originPage
      || R.contains(originPage.index, pageIndices)
  );
};

const SIDEBAR_CHUNK_SIZE = 12;

const SIDEBAR_INSERTS = {
  REST: {
    7: index => (
      <SidebarInsertChunk
        index={index}
        style={{marginBlock: 5, minHeight: 330}}
      />
    ),
  },
};

const TrackedSidebarLeafletCard = trackComponent(
  {
    onClick({ga4, iwa}, {item, placement}) {
      iwa.clickSelectedForUserCard(
        {
          leafletId: R.defaultTo(item.id, item.creatorMeta?.id),
          contractorId: item.contractor?.id || '',
          categoryId: item.mainCategory?.id || '',
        },
      );

      if (isCorrectPlacementForGA4Tracking(placement)) {
        ga4.selectedForUserMobileSliderClick({
          leafletId: R.defaultTo(item.id, item.creatorMeta?.id),
          contractorName: item.contractor?.name || '',
          placement,
        });
      }
    },
  },
)(
  ({currentlyVisible, onInViewport, ...props}) => {
    const Component = (
      currentlyVisible
        ? CurrentlyVisibleLeafletCard
        : LeafletCard
    );

    let element = <Component {...props} />;
    if (onInViewport) {
      element = (
        <>
          {element}
          <ViewportIntersectionToolbar onLoadNextChunk={onInViewport} />
        </>
      );
    }

    return element;
  },
);

const SelectedForUserSidebarChunksList = ({
  currentlyVisibleLeaflet,
  itemProps,
  insert,
  leafletsGrid: LeafletsGrid,
  placement,
}) => {
  /**
   * Measure how far user
   */
  const trackSidebarScroll = useTrackSidebarScroll();

  const {dimensions} = useViewerState(
    ({getUIState}) => getUIState(),
  );

  /**
   * Cache variables due to click leaflet issue,
   * if user clicks leaflet on for example 5 page
   * excludeLeafletId changes and makes burst of GQL calls
   */
  const scrollableAreaRef = useRef(null);
  const cachedVariables = useMemo(
    () => ({
      placement: SUGGESTED_LEAFLETS_PLACEMENT.VIEWER,
      filters: currentlyVisibleLeaflet && {
        currentLeafletId: currentlyVisibleLeaflet.id,
        excludeIds: [
          currentlyVisibleLeaflet.id,
        ],
      },
    }),
    [],
  );

  // do not place here any props that might cause state update
  const renderChunk = ({items: leaflets}, chunkInfo) => (
    <LeafletsGrid
      // TODO: reverted skeleton loading (commit: d2845bb8546f89e3cb32a3f60af33128010f3342), there was possible memory leak and this need to have separate tests to check if this work correctly
      list={leaflets}
      // NOTE: because on load page dimensions state value equal 0, as default
      // loaded is small version to prevent insert with ads to be removed from dom
      {...dimensions?.width >= LEAFLETS_SIDEBAR_WIDTH_BREAKPOINT
        ? {insert: insert || SIDEBAR_INSERTS.REST} // don't show inserts in small sidebar
        : {gridProps: {gridConfig: {xs: 12}}}
      }
        // NOTE: prevent one variant from rendering before hydration, causing cls because dimensions are unknown on server and mix grid and inset cannot be replicated in pure css, to prevent this hide component when dimension is 0 or undefined
      style={{visibility: dimensions?.width ? 'visible' : 'hidden'}}
      itemComponent={TrackedSidebarLeafletCard}
      itemProps={itemProps}
      itemPropsFn={
        (item, index) => ({
          placement,
          currentlyVisible: isCurrentlyVisibleCard(currentlyVisibleLeaflet, item),
          onInViewport: () => {
            trackSidebarScroll(
              {
                items: Math.ceil((1 + index + chunkInfo.chunkIndex * SIDEBAR_CHUNK_SIZE) / 2) * 2,
                placement,
              },
            );
          },
        })
      }
    />
  );

  return (
    <OffsetQueryChunksList
      ref={(elementNode) => {
        if (!elementNode)
          return;

        scrollableAreaRef.current = getParentScrollableTag(elementNode) || elementNode.parentNode;
      }}
      asyncChunkResolverComponent={SelectedForUserLeafletsQuery}
      listRenderFn={renderChunk}
      toolbarRenderFn={
        toolbarParams => viewportIntersectionToolbarFn(
          {
            ...toolbarParams,
            intersectionParams: {
              rootRef: scrollableAreaRef,
            },
          },
        )
      }
      initialExpand={SIDEBAR_CHUNK_SIZE}
      expandBy={SIDEBAR_CHUNK_SIZE}
      variables={cachedVariables}
    />
  );
};

SelectedForUserSidebarChunksList.displayName = 'SelectedForUserSidebarChunksList';

SelectedForUserSidebarChunksList.propTypes = {
  leafletsGrid: PropTypes.elementType,
};

SelectedForUserSidebarChunksList.defaultProps = {
  leafletsGrid: SidebarLeafletsGrid,
};

export default SelectedForUserSidebarChunksList;
