import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  ID_SCHEMA,
  DATE_SCHEMA,
} from '@adretail/schemas';

export const OAUTH_PROVIDERS = {
  FACEBOOK: 'FACEBOOK',
  GOOGLE: 'GOOGLE',
};

export const OAUTH_PROVIDER_SCHEMA = PropTypes.oneOf(
  R.values(OAUTH_PROVIDERS),
);

export const OAUTH_REGISTER_ROUTE_STATE = PropTypes.shape(
  {
    provider: OAUTH_PROVIDER_SCHEMA,
    keys: PropTypes.shape(
      {
        expiresIn: PropTypes.number,
        accessToken: PropTypes.string,
      },
    ),
    user: PropTypes.shape(
      {
        id: ID_SCHEMA,
        birthday: DATE_SCHEMA,
        email: PropTypes.string.isRequired,
      },
    ),
  },
);
