import PropTypes from 'prop-types';
import styled from '@adretail/fast-stylesheet/src/react/styled';

import {LIGHT_BORDER} from '@ding/constants/src/colorSchema';
import grayscaleStyle from '../../styles/grayscale';

const BorderedImage = styled.img(
  {
    base: {
      display: 'inline-block',
      border: `1px solid ${LIGHT_BORDER}`,
      objectFit: 'contain',
    },

    // used in grids
    expanded: {
      width: '100%',
      height: '100%',
    },

    'filter-grayscale': {
      extend: grayscaleStyle,
    },
  },
  {
    omitProps: ['expanded', 'children', 'filter'],
    classSelector: (classes, {expanded, filter}) => [
      expanded && classes.expanded,
      filter && classes[`filter-${filter}`],
    ],
  },
);

BorderedImage.displayName = 'BorderedImage';

BorderedImage.propTypes = {
  expanded: PropTypes.bool,
  filter: PropTypes.oneOf(
    [
      null,
      'grayscale',
    ],
  ),
};

export default BorderedImage;
