import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'Alert',
  {
    viewBox: '0 0 10 20',
  },
  <>
    <path d='M7,10,10,0H0L3,10Zm.31-8L5.51,8h-1L2.69,2Z' />
    <path d='M5,12a4,4,0,1,0,4,4A4,4,0,0,0,5,12Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,5,18Z' />
  </>,
);
