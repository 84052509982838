import {useState} from 'react';
import * as R from 'ramda';

import clamp from '@adretail/basic-helpers/src/base/clamp';
import useHotKeys from '@adretail/basic-hooks/src/base/useHotKeys';

/**
 * Add support for key up / key down higlight in list
 *
 * @param {Object} config
 *
 * @returns {Number}  highlight index
 */
const useHighlightedIndex = ({phrase, listLength, onSelectItem}) => {
  const [highlightIndex, setHighlightIndex] = useState(null);
  const [prevPhrase, setPrevPhrase] = useState(phrase);

  useHotKeys(
    {
      /** ARROW UP */ 38: () => {
        setHighlightIndex(
          highlightIndex > 0
            ? highlightIndex - 1
            : null,
        );
      },

      /** ARROW DOWN */ 40: () => {
        setHighlightIndex(
          // handle null value
          clamp(
            0,
            Math.max(listLength - 1, 0),
            R.defaultTo(-1, highlightIndex) + 1,
          ),
        );
      },

      /** ENTER */ 13: () => {
        // eslint-disable-next-line no-unused-expressions
        onSelectItem?.(
          highlightIndex || 0,
          highlightIndex === null,
        );
      },
    },
    {
      suppress: true,
    },
  );

  if (highlightIndex !== null) {
    if (listLength <= highlightIndex) {
      setHighlightIndex(
        listLength
          ? listLength - 1
          : null,
      );
    } else if (prevPhrase !== phrase) {
      // reset index on phrase change
      setHighlightIndex(null);
      setPrevPhrase(null);
    }
  }

  return highlightIndex;
};

export default useHighlightedIndex;
