export class GraphQLFragment {
  constructor(operationName, query) {
    this.operationName = operationName;
    this.query = query;
  }
}

const gql = (strings, ...values) => {
  let str = '';

  for (let i = 0; i < strings.length; ++i) {
    const fragment = values[i];
    str += strings[i] + (fragment?.loc?.source?.body || fragment || '');
  }

  return str;
};

export default gql;
