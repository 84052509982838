import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  DURATION_SCHEMA,
  ID_SCHEMA,
} from '@adretail/schemas';

import * as NOTIFICATION_TYPES from './notificationTypes';
import * as NOTIFICATION_CALENDAR_TYPES from './notificationCalendarTypes';
import * as NOTIFICATION_ACTIONS from './notificationActions';
import * as FAVOURITE_TYPES from './favouriteTypes';

const CALENADAR_TYPE_SCHEMA = PropTypes.oneOf(
  R.values(NOTIFICATION_CALENDAR_TYPES),
);

const CONTRACTOR_SCHEMA = PropTypes.shape({
  name: PropTypes.string,
});

export const NOTIFABLE_SCHEMA = PropTypes.shape({
  id: ID_SCHEMA,
  type: CALENADAR_TYPE_SCHEMA,
  contractor: CONTRACTOR_SCHEMA,
  name: PropTypes.string,
  image: PropTypes.string,
  duration: DURATION_SCHEMA,
});

const FAVOURITE_KIND_SCHEMA = PropTypes.oneOf(
  R.values(FAVOURITE_TYPES),
);

export const NOTIFICATIONS_LIST_ITEM_SCHEMA = PropTypes.shape(
  {
    id: ID_SCHEMA.isRequired,
    kind: PropTypes.oneOf(
      R.values(NOTIFICATION_TYPES),
    ).isRequired,

    action: PropTypes.oneOf(
      R.values(NOTIFICATION_ACTIONS),
    ),

    message: PropTypes.string,
    readedAt: PropTypes.string,
    notifable: NOTIFABLE_SCHEMA,
  },
);

export const NOTIFICATIONS_LIST_SCHEMA = PropTypes.arrayOf(
  NOTIFICATIONS_LIST_ITEM_SCHEMA,
);

export const CREATE_FAVOURITE_INPUT_SCHEMA = PropTypes.shape(
  {
    kind: FAVOURITE_KIND_SCHEMA,
    name: PropTypes.string,

    source: PropTypes.any, // front only loopback ref(tracking)
  },
);
