import React, {useMemo, useState, useEffect} from 'react';

import {
  WHITE,
  MUTED_GRAY,
  DARK_CARD_GRAY,
  PRIMARY_FILL,
} from '@ding/constants';

import fullLayer from '@ding/core/src/styles/fullLayer';

import {createCacheInterface} from '@adretail/basic-helpers/src/getters/localStorage';
import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import {fadeInKeyframes} from '@ding/core/src/styles/keyframes';

import {useTranslate} from '@ding/i18n';
import {stopPropagation} from '@adretail/basic-helpers/src/inputs/suppressEvent';

import logoRasterUrl from '@ding/server/resources/logo/logo.png';
import iosShareUrl from '@ding/server/resources/ios/share.jpg';

import TimesIcon from '@ding/core/src/icons/Times';
import {
  Margin,
  Header,
} from '@ding/core/src/components/Predefined';

import {reactFormat} from '@ding/core/src/helpers';
import {IconButton} from '@ding/controls';

import {useHideBadgeOnScroll} from '@ding/eu/src/components/EUFloatingImage';
import useIsPWAInstalled from '../../../hooks/useIsPWAInstalled';
import useLayoutVisitsCounter from '../../../hooks/useLayoutVisitsCounter';

const css = {
  ...fadeInKeyframes,

  backdrop: {
    extend: [fullLayer],

    position: 'fixed',
    background: 'rgba(0, 0, 0, 0.8)',
    zIndex: 1000,
  },

  base: {
    position: 'fixed',
    bottom: 15,
    width: '100%',
    textAlign: 'center',
    animation: 'fade-in 300ms ease-in-out 1 forwards',
  },

  wrapper: {
    display: 'inline-block',
    position: 'relative',
    maxWidth: 'calc(100vw - 20px)',
    padding: '40px 10px 20px',
    background: WHITE,
    border: `1px solid ${MUTED_GRAY}`,
    color: DARK_CARD_GRAY,
    textAlign: 'center',
    boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.2)',

    '&, & *': {
      fontFamily: 'sans-serif',
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      left: 'calc(50% - 10px)',
      bottom: -11,
      width: 20,
      height: 20,
      transform: 'rotate(45deg)',
      borderBottom: `1px solid ${MUTED_GRAY}`,
      borderRight: `1px solid ${MUTED_GRAY}`,
      backgroundColor: WHITE,
    },
  },

  header: {
    marginTop: 0,
    marginBottom: 10,
    fontSize: '21px',
    fontWeight: 600,
  },

  content: {
    fontSize: 15,
    lineHeight: '20px',
  },

  logo: {
    position: 'absolute',
    left: '50%',
    bottom: 'calc(100% - 25px)',
    transform: 'translateX(-50%)',
    width: '25%',
    padding: 10,
    background: PRIMARY_FILL,

    '& div': {
      paddingBottom: '100%',
    },

    '& img': {
      position: 'absolute',
      left: 10,
      top: '50%',
      width: 'calc(100% - 20px)',
      transform: 'translateY(-50%)',
    },
  },

  shareIcon: {
    width: 16,
    verticalAlign: 'middle',
  },

  iconTitle: {
    marginTop: 20,
    fontSize: 13,
  },

  close: {
    position: 'absolute !important',
    right: 10,
    top: 10,
    color: DARK_CARD_GRAY,
  },
};

let IOSPinApp = ({classes, onToggle}) => {
  const t = useTranslate('website.hints.pin_ios_app');
  const [toggled, setToggled] = useHideBadgeOnScroll(false, onToggle);
  const pwaInstalled = useIsPWAInstalled();

  if (pwaInstalled || !toggled)
    return null;

  const onClose = () => setToggled(false);

  return (
    <div
      className={classes.backdrop}
      onClick={onClose}
    >
      <div
        className={classes.base}
        onClick={stopPropagation}
      >
        <div className={classes.wrapper}>
          <IconButton
            className={classes.close}
            icon={TimesIcon}
            iconSize='tiny'
            onClick={onClose}
          />

          <div className={classes.logo}>
            <div>
              <img
                src={logoRasterUrl}
                alt='Logo'
              />
            </div>
          </div>

          <Header.H2 className={classes.header}>
            {t('title')}
          </Header.H2>

          <Margin
            className={classes.content}
            bottom={2}
            block
          >
            {t('subtitle')}
          </Margin>

          <div className={classes.iconTitle}>
            {reactFormat(
              t('icon_title'),
              [
                <img
                  key='share'
                  src={iosShareUrl}
                  alt='Share'
                  className={classes.shareIcon}
                />,
              ],
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

IOSPinApp.displayName = 'IOSPinApp';
IOSPinApp = injectClassesSheet(css, {index: 3})(IOSPinApp);

const IOSPinAppDebounced = () => {
  const {getVisitsCount} = useLayoutVisitsCounter();
  const [popupVisible, setPopupVisible] = useState(false);

  const storageFlag = useMemo(
    () => createCacheInterface('ios-install-prompt', 432000), // 5 days
    [],
  );

  useEffect(
    () => {
      if (getVisitsCount() >= 6 && !storageFlag.value)
        setPopupVisible(true);
    },
    [],
  );

  if (!popupVisible)
    return null;

  return (
    <IOSPinApp
      onToggle={() => {
        storageFlag.update(1);
      }}
    />
  );
};

IOSPinAppDebounced.displayName = 'IOSPinAppDebounced';

export default React.memo(IOSPinAppDebounced);
