import React from 'react';

import {useGuestPersonalizedDataQuery} from '@ding/api/src/gql/guestPersonalizedDataMigrationQuery';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';
import styled from '@adretail/fast-stylesheet/src/react/styled';

import {TitledCheckbox} from '@ding/controls/src';
import {BorderedRoundedBox} from '@ding/core/src/components/Parts';
import FormRow from '@ding/control-groups/src/Form/FormRow';

const TransferAlertRoundedBox = styled(
  BorderedRoundedBox,
  {
    marginBottom: 25,

    ...createBreakpointClasses(
      {
        md: {
          width: 'calc(100% + 30px)',
          marginLeft: -15,
        },
      },
    ),
  },
  {
    mergeProps: () => ({
      type: 'warn',
    }),
  },
);

const TransferDataCheckbox = (props) => {
  const {data} = useGuestPersonalizedDataQuery();
  const t = useTranslate('website.auth.confirm');

  if (!data)
    return null;

  return (
    <TransferAlertRoundedBox>
      <FormRow>
        <TitledCheckbox
          {...props}
          flexAlignToggle='flex-start'
        >
          {t('personalized_data_migration')}
        </TitledCheckbox>
      </FormRow>
    </TransferAlertRoundedBox>
  );
};

TransferDataCheckbox.displayName = 'TransferDataCheckbox';

export default TransferDataCheckbox;
