import React from 'react';

import env from '@cdd/internal-server/src/constants/env';

import {PRIMARY_FILL} from '@ding/constants/src/colorSchema';
import CacheStoreReactMetatags from '@adretail/fast-stylesheet/src/react/server/CacheStoreReactMetatags';

import faviconUrl from '@ding/server/resources/favicons/24x24.png';
import splashScreenUrl from '@ding/server/resources/splash-screen.png';

import prefixWithRootAddress from '@ding/layout/src/components/Links/utils/prefixWithRootAddress';
import {prefixWithLanguage} from '@ding/i18n/src/languages';

import {PreHydrateWindowAPIHeader} from '@ding/window-api';
import InteriaScriptPlacement from '@ding/interia/src/components/InteriaScriptPlacement';

import SSRMetaTagsContainer from './HTML/SSRMetaTagsContainer';
import SSRLoadedChunksContainer from './HTML/SSRLoadedChunksContainer';
import SSRLoadableChunksContainer from './HTML/SSRLoadableChunksContainer';
import SSRAlternateLinksContainer from './HTML/SSRAlternateLinksContainer';

const HeadTags = ({children}) => (
  <>
    {/* META TAGS */}
    <SSRAlternateLinksContainer />
    <meta name='keywords' content='gazetki promocyjne, promocje, tanie zakupy, sieci handlowe, rabaty, okazje, lista zakupowa, przeceny, oferty promocyjne, przeceny, gazetki reklamowe, wyprzedaże' />

    <meta name='dc.language' content={APP_LANGUAGE.toUpperCase()} />
    <meta name='dc.source' content='https://ding.pl/' />
    <meta name='dc.publisher' content='https://ding.pl' />
    <meta name='dc.rights' content='https://ding.pl, wszelkie prawa zastrzeżone' />

    <meta name='geo.region' content='PL-MZ' />
    <meta name='geo.placename' content='Warszawa' />
    <meta name='geo.position' content='52.229676;21.012229' />

    <meta property='og:site_name' content='Ding.pl' />

    <meta name='theme-color' content={PRIMARY_FILL} />

    <link rel='preconnect' href='https://fonts.gstatic.com/' />
    <link rel='preconnect' href='https://prywatnosc.interia.pl/' />
    <link rel='preconnect' href='https://js.iplsc.com/' />
    <link rel='preconnect' href='https://i.iplsc.com/' />
    <link rel='preconnect' href={env.client.graphApiUrl} />
    <link rel='preconnect' href={env.client.publishersGraphApiUrl} />

    {/* Safari PWA stuff */}
    <link rel='icon' sizes='192x192' href={prefixWithRootAddress('/app-icons/app-icon-192x192.png')} />
    <link rel='apple-touch-icon' sizes='512x512' href={prefixWithRootAddress('/app-icons/app-ios-icon.png')} />
    <link rel='apple-touch-startup-image' href={splashScreenUrl} />

    {/* RSS feeds */}
    <link
      rel='alternate'
      type='application/rss+xml'
      title='RSS Feed dla Ding.pl'
      href={prefixWithLanguage('/feed/rss')}
    />
    <link
      rel='alternate'
      type='application/rss+xml'
      title='Gazetki promocyjne Ding.pl - kanał RSS'
      href={prefixWithLanguage('/feed/leaflet/rss')}
    />

    <meta name='apple-mobile-web-app-capable' content='yes' />
    <meta name='mobile-web-app-capable' content='yes' />

    {/*
      ... there is more meta tags
      canonical, og:url, og:type is in RootLayoutMetaTags
      og:title, og:image, title, dscription in routes
    */}

    {/* React helmet stuff */}
    <meta property='fb:app_id' content={env.client.facebook?.appId} />
    <SSRMetaTagsContainer />

    {/* LINKS */}
    <link rel='manifest' href='/manifest.json' />
    <link rel='sitemap' href='/sitemap.xml' />
    <link rel='icon' type='image/png' sizes='24x24' href={faviconUrl} />

    {/* NOTE:  Here server will place in <script> tags needed JS chunks to render current page.
      main.js is placed by @cdd/internal-server and have to go after all chunks */}
    <SSRLoadedChunksContainer />

    <SSRLoadableChunksContainer />

    <style key='clear-styles'>
      {`
        #react-hydrate-root {
          width: 100%;
          min-height: 100%;
        }
      `}
    </style>

    {/* GTM */}
    <script
      type='text/javascript'
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${env.client.google.gtm.id}');`,
      }}
    />

    {/* facebook Pixel */}
    <script
      type='text/javascript'
      dangerouslySetInnerHTML={{
        __html: `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window,document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${env.client.facebook?.pixel}'); 
        fbq('track', 'PageView');`,
      }}
    />

    {/* Used in PPG, exposes window API variable */}
    <PreHydrateWindowAPIHeader />

    {/* Fast stylesheet styles container */}
    <CacheStoreReactMetatags />

    {/* INTERIA STUFF */}
    <InteriaScriptPlacement />

    {/* Third party content */}
    {children}
  </>
);

HeadTags.displayName = 'HeadTags';

export default HeadTags;
