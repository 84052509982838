import React from 'react';
import createIcon from '../utils/createIcon';

export default createIcon(
  'ShoppingList',
  {
    viewBox: '0 0 24 24',
  },
  <>
    <path
      fill='currentColor'
      d='M17.28,3H6.72A2.282,2.282,0,0,0,4.44,5.28V18.72A2.282,2.282,0,0,0,6.72,21H17.28a2.282,2.282,0,0,0,2.28-2.28V5.28A2.282,2.282,0,0,0,17.28,3m1.08,15.72a1.082,1.082,0,0,1-1.08,1.08H6.72a1.082,1.082,0,0,1-1.08-1.08V5.28A1.082,1.082,0,0,1,6.72,4.2H17.28a1.082,1.082,0,0,1,1.08,1.08Z'
      transform='translate(0 -0.004)'
    />
    <rect
      fill='currentColor'
      x='7.56'
      y='7.076'
      width='1.2'
      height='1.2'
    />

    <rect
      fill='currentColor'
      x='7.56'
      y='9.956'
      width='1.2'
      height='1.2'
    />

    <rect
      fill='currentColor'
      x='7.56'
      y='12.836'
      width='1.2'
      height='1.2'
    />

    <rect
      fill='currentColor'
      x='7.56'
      y='15.716'
      width='1.2'
      height='1.2'
    />

    <rect
      fill='currentColor'
      x='9.48'
      y='7.076'
      width='6.96'
      height='1.2'
    />

    <rect
      fill='currentColor'
      x='9.48'
      y='9.956'
      width='6.96'
      height='1.2'
    />

    <rect
      fill='currentColor'
      x='9.48'
      y='12.836'
      width='6.96'
      height='1.2'
    />

    <rect
      fill='currentColor'
      x='9.48'
      y='15.716'
      width='6.96'
      height='1.2'
    />

    <rect
      fill='none'
      width='24'
      height='24'
    />
  </>,
);
