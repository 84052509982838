import {gql, GraphQLFragment} from '@ding/tiny-gql';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import DefaultTinyQuery from '@ding/control-groups/src/DefaultTinyQuery';

import ContractorFragment from './fragments/Contractor.graphql';
import CustomerFlagsFragment from './fragments/CustomerFlags.graphql';

const PRIORITIZED_CONTRACTORS_QUERY = new GraphQLFragment(
  'prioritizedContractorsQuery',
  gql`
    ${ContractorFragment}
    ${CustomerFlagsFragment}
    query prioritizedContractorsQuery(
      $limit: Int = 12,
      $filters: PrioritizedContractorListFilters,
    ) @locale(lang: ${APP_LANGUAGE}) {
      prioritizedContractors(
        limit: $limit,
        filters: $filters,
      ) {
        ...Contractor
        customerFlags {
          ...CustomerFlags
        }
      }
    }
  `,
);

export const PrioritizedContractorsQuery = provideProps(
  {
    query: PRIORITIZED_CONTRACTORS_QUERY,
    responseSelector: 'prioritizedContractors',
    variables: {
      limit: 12,
    },
    cacheKey: {
      expire: 86400, // 24h
    },
  },
)(DefaultTinyQuery);

export default PRIORITIZED_CONTRACTORS_QUERY;
