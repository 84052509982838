import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {AsyncSpinnerButton} from '@ding/controls/src/';

const SendEmailButton = (props) => {
  const t = useTranslate(props);

  return (
    <AsyncSpinnerButton
      expanded
      type='primary'
      {...props}
    >
      {t('website.shared.titles.next')}
    </AsyncSpinnerButton>
  );
};

SendEmailButton.displayName = 'SendEmailButton';

export default SendEmailButton;
