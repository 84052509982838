import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'Plus',
  {
    // viewBox: '0 0 16 16', // looks smooth
    viewBox: null,
  },
  <polygon points='16 7 9 7 9 0 7 0 7 7 0 7 0 9 7 9 7 16 9 16 9 9 16 9 16 7' />,
);
