import React from 'react';
import PropTypes from 'prop-types';

import {PROPS_SCHEMA} from '@adretail/schemas';
import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/typeSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {Label} from '@ding/core/src/components/Predefined';

const FormRowHolder = styled.div(
  {
    base: {
      '&:last-child': {
        marginBottom: 0,
      },
    },

    centered: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },

    small: {
      marginBottom: 10,
    },

    'medium-small': {
      marginBottom: 15,
    },

    big: {
      marginBottom: 20,
    },
  },
  {
    omitProps: ['centered', 'spacing'],
    classSelector: (classes, {centered, spacing}) => [
      spacing && classes[spacing],
      centered && classes.centered,
    ],
  },
);

const FormRow = ({title, titleProps, children, centered, ...props}) => (
  <FormRowHolder
    {...props}
    centered={centered}
  >
    {title
      ? (
        <>
          <Label
            row
            {...titleProps}
          >
            {title}
          </Label>
          <div>
            {children}
          </div>
        </>
      )
      : children}
  </FormRowHolder>
);

FormRow.displayName = 'FormRow';

FormRow.propTypes = {
  spacing: SIZE_ARRAY_SCHEMA,
  centered: PropTypes.bool,
  titleProps: PROPS_SCHEMA,
  title: PropTypes.node,
};

FormRow.defaultProps = {
  spacing: 'small',
};

export default FormRow;
