export default {
  titles: {
    votes: {
      denominator: 'głos',
      genitive: 'głosów',
      accusative: 'głosy',
    },

    stars_title: 'Ocena: %{} - %{} %{}',
    stars_empty_title: 'Brak ocen!',
    stars_fetch_error: 'Błąd podczas pobierania ocen!',
    customer_rating: 'Twoja ocena: %{}',
  },

  toast: {
    vote_error: 'Błąd podczas oddawania głosu :(',
    vote_success: 'Dziękujemy za oddanie głosu!',
  },
};
