import React from 'react';
import * as R from 'ramda';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import isOldBrowser from '@ding/core/src/helpers/isOldBrowser';

import ExpandedAlert from '@ding/core/src/components/Parts/ExpandedAlert';

const HeaderAlert = () => {
  const t = useTranslate();
  let message = null;

  if (process.env.APP_ENV !== 'production') {
    message = t('website.header.env_alert', R.toUpper(process.env.APP_ENV));
  } else if (isOldBrowser())
    message = t('website.header.old_browser');

  if (!message)
    return null;

  return (
    <ExpandedAlert>
      <strong>
        {message}
      </strong>
    </ExpandedAlert>
  );
};

HeaderAlert.displayName = 'HeaderAlert';

export default React.memo(HeaderAlert);
