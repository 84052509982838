import {
  parameterize,
  parameterizeAddress,
} from '@ding/core/src/helpers';

import createLinkComponent from '../../utils/createLinkComponent';

export const STORE_URL_SCHEMA = '/:contractorName,:address,:id';

export const genStoreURL = ({id, contractor, address}) => (
  `/${parameterize(contractor.name)},${parameterizeAddress(address)},${id}`
);

export default createLinkComponent('store', genStoreURL);
