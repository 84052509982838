import {gql, GraphQLFragment} from '@ding/tiny-gql';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import DefaultTinyQuery from '@ding/control-groups/src/DefaultTinyQuery';
import LeafletCardFragment from './fragments/LeafletCard.graphql';

export const LEAFLETS_QUERY_CACHE_PREFIX = 'leaflets-';

export const LEAFLETS_QUERY = new GraphQLFragment(
  'leafletsQuery',
  gql`
    ${LeafletCardFragment}
    query leafletsQuery(
      $offset: Int,
      $page: Int = 1,
      $limit: Int = 24,
      $orderBy: LeafletListSort = PUB_DATE,
      $withSummaryContent: Boolean = false,
      $withPdfIdentifier: Boolean = false,
      $filters: LeafletListFilters
    ) @locale(lang: ${APP_LANGUAGE}) {
      leaflets(
        offset: $offset,
        page: $page,
        limit: $limit,
        orderBy: $orderBy,
        filters: $filters
      ) {
        totalPages
        totalItems
        items {
          ...LeafletCard

          summaryContent @include(if: $withSummaryContent)
          pdfIdentifier @include(if: $withPdfIdentifier)
        }
      }
    }
  `,
);

export const LeafletsQuery = provideProps(
  {
    query: LEAFLETS_QUERY,
    responseSelector: 'leaflets',
    cacheKey: {
      prefix: LEAFLETS_QUERY_CACHE_PREFIX,
      expire: 500,
    },
  },
)(DefaultTinyQuery);

export default LEAFLETS_QUERY;
