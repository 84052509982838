import * as R from 'ramda';

import {ROOT_DOMAIN} from '@ding/layout/src/components/Links/utils/prefixWithRootAddress';
import suppressEvent from '@adretail/basic-helpers/src/inputs/suppressEvent';

export const dropProtocol = (url) => {
  const match = R.match(/^https?:\/\/(.+)/, url);
  if (match.length)
    return match[1];

  return url;
};

export const isRootDomainURL = nonProtocolURL => (
  R.indexOf(ROOT_DOMAIN, nonProtocolURL) === 0
);

export const dropRootDomain = nonProtocolURL => R.compose(
  R.unless(
    R.startsWith('/'),
    str => `/${str}`,
  ),
  s => s.substr(
    ROOT_DOMAIN.length,
    nonProtocolURL.length,
    s,
  ),
)(nonProtocolURL);

export const interceptRelativeReactRedirect = history => (e) => {
  const {target} = e;
  const anchor = target.closest('a');

  if (!anchor)
    return;

  const link = anchor.getAttribute('href');
  if (!link || link[0] !== '/')
    return;

  suppressEvent(e);
  history.push(link);
};
