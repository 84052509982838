import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {AsyncSpinnerButton} from '@ding/controls/src/';

const LoginButton = (props) => {
  const t = useTranslate();

  return (
    <AsyncSpinnerButton
      expanded
      type='primary'
      {...props}
    >
      {t('website.auth.titles.login')}
    </AsyncSpinnerButton>
  );
};

LoginButton.displayName = 'LoginButton';

export default LoginButton;
