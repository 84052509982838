import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {DURATION_SCHEMA, REACT_ELEMENT_SCHEMA} from '@ding/constants/src/typeSchema';

import {formatDateRange, PL_DATE_SEPARATOR_SYMBOL} from '../../helpers/date';

/**
 * @example
 *  numeric: 12.01-05.12
 *  short:  7 Jan–12 Feb, 7–12 Feb
 *  long: 7 January – 12 February, 7–12 February
 *  verbose: 7 January – 12 February 2022, 7–12 February 2022
 */
const DATE_FORMAT_OPTIONS = {
  numeric: {
    day: '2-digit',
    month: '2-digit',
  },
  short: {
    day: '2-digit',
    month: 'short',
  },
  long: {
    day: '2-digit',
    month: 'long',
  },
  verbose: {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  },
};

/**
 * @param duration object with 'begin' and 'end' date in string format
 * @param format format date in certain way ['numeric', 'short', 'long', 'verbose']
 * @param withYear display year in date range
 * @param forceSeparator because PL-pl date format using '–' (U+2013) with no
 *                       spaces replace this with given text
 */
const DateDurationText = ({
  component: Component,
  duration, format, withYear,
  forceSeparator, ...props
}) => {
  if (!duration || R.isEmpty(duration)) {
    duration = {
      begin: null,
      end: null,
    };
  }

  const formatterOptions = withYear
    ? R.assoc('year', 'numeric', DATE_FORMAT_OPTIONS[format])
    : DATE_FORMAT_OPTIONS[format];

  const formattedDate = formatDateRange(duration, formatterOptions, APP_LANGUAGE);

  let content = '';

  if (formattedDate) {
    const [formattedBegin, formattedEnd] = R.split(PL_DATE_SEPARATOR_SYMBOL, formattedDate);

    content = (
      <>
        {duration.begin
          ? <time dateTime={duration.begin}>{formattedBegin}</time>
          : <span>{formattedBegin}</span>}

        <span>{forceSeparator || PL_DATE_SEPARATOR_SYMBOL}</span>

        {duration.end
          ? <time dateTime={duration.end}>{formattedEnd}</time>
          : <span>{formattedEnd}</span>}
      </>
    );
  }

  return (
    <Component {...props}>
      {content}
    </Component>
  );
};

DateDurationText.displayName = 'DateDurationText';

DateDurationText.propTypes = {
  component: REACT_ELEMENT_SCHEMA,
  duration: DURATION_SCHEMA.isRequired,
  format: PropTypes.oneOf(['numeric', 'short', 'long', 'verbose']),
  withYear: PropTypes.bool,
  forceSeparator: PropTypes.string,
};

DateDurationText.defaultProps = {
  component: 'span',
  format: 'short',
  withYear: false,
  forceSeparator: '',
};

export default DateDurationText;
