import React from 'react';
import PropTypes from 'prop-types';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {reactFormat} from '@ding/core/src/helpers';

import {
  Margin,
  Header,
  Text,
} from '@ding/core/src/components/Predefined';

const RegisterConfirmStep = ({email}) => {
  const t = useTranslate();

  return (
    <>
      <Header.H1>
        {t('website.auth.registration.confirm_email')}
      </Header.H1>

      {reactFormat(
        t('website.auth.registration.message_sent_to_email'),
        [
          <Text.Primary
            key='email'
            weight={600}
          >
            {email}
          </Text.Primary>,
        ],
      )}

      <Margin
        top={5}
        bottom={5}
        block
      >
        <Text weight={600}>
          {t(
            'website.auth.registration.email_title',
            t('website.address'),
          )}
        </Text>
      </Margin>

      <Text.Primary weight={600}>
        {t('website.auth.registration.finalize')}
      </Text.Primary>
    </>
  );
};

RegisterConfirmStep.displayName = 'RegisterConfirmStep';

RegisterConfirmStep.propTypes = {
  email: PropTypes.string.isRequired,
};

export default RegisterConfirmStep;
