import React from 'react';
import PropTypes from 'prop-types';

import {ERRORS_LIST_SCHEMA} from '@ding/constants';

import {Margin} from '@ding/core/src/components/Predefined';
import {
  SubmitFormRow,
  FormRow,
} from '@ding/control-groups/src/Form';

import {
  LoginPasswordGroup,
  AuthSubmitErrors,
} from '../Shared';

import {RecaptchaForm} from '../../Recaptcha';

import {
  LoginToolbar,
  LoginButton,
} from './Controls';

const LoginBufferedControlGroup = ({errors, onChangeModalAuthTab, ...props}) => (
  <RecaptchaForm
    {...props}
    initialData={{
      rememberMe: true,
    }}
  >
    {loading => (l, value) => (
      <>
        <LoginPasswordGroup {...l.input('auth')} />

        <Margin
          top={5}
          block
        >
          <AuthSubmitErrors
            email={value?.auth?.email}
            errors={errors}
            onChangeModalAuthTab={onChangeModalAuthTab}
          />
          <SubmitFormRow
            buttonComponent={LoginButton}
            loading={loading}
          />
        </Margin>

        <FormRow>
          <LoginToolbar l={l} />
        </FormRow>
      </>
    )}
  </RecaptchaForm>
);

LoginBufferedControlGroup.displayName = 'LoginBufferedControlGroup';

LoginBufferedControlGroup.propTypes = {
  errors: ERRORS_LIST_SCHEMA,
  onChangeModalAuthTab: PropTypes.func,
};

export default React.memo(LoginBufferedControlGroup);
