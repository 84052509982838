import React from 'react';
import * as R from 'ramda';
import {gql, GraphQLFragment} from '@ding/tiny-gql';

import createAuthorizationHeaders from '@ding/auth/src/helpers/createAuthorizationHeaders';

import TinyGqlMutation from '@ding/tiny-gql/src/react/TinyGqlMutation';
import {useAuthState} from '../../client/AuthContext';

const LOGIN_USER_MUTATION = new GraphQLFragment(
  'loginCustomerMutation',
  gql`
    mutation loginCustomerMutation(
      $recaptcha: Recaptcha!,
      $rememberMe: Boolean,
    ) {
      loginCustomer(input: {
        recaptcha: $recaptcha,
        rememberMe: $rememberMe,
      }) {
        token
        errors
      }
    }
  `,
);

const LoginMutation = ({children, ...props}) => {
  const authState = useAuthState();

  return (
    <TinyGqlMutation
      mutation={LOGIN_USER_MUTATION}
      {...props}
    >
      {(mutate, utils) => {
        const setTokenAndRefetch = (token) => {
          authState.setToken(token);
          return utils.refetchQuery('*');
        };

        const setFreshLogin = (value) => {
          authState.setFreshLogin(value);
        };

        const fetchUserToken = async (formValue) => {
          const {token} = await mutate(
            R.pick(
              ['recaptcha', 'rememberMe'],
              formValue,
            ),
            createAuthorizationHeaders(
              authState.getToken(),
              formValue.auth, // {login, password}
            ),
          );

          return token;
        };

        return children(
          {
            ...utils,
            fetchUserToken,
            setTokenAndRefetch,
            setFreshLogin,
          },
        );
      }}
    </TinyGqlMutation>
  );
};

LoginMutation.displayName = 'LoginMutation';

export default LoginMutation;
