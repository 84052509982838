import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  PROPS_SCHEMA,
  REACT_COMPONENT_CLASS_SCHEMA,
} from '@adretail/schemas';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import selectResponseData from '@adretail/basic-helpers/src/getters/selectResponseData';
import renderFunctionalChildren from '@adretail/basic-helpers/src/ui/renderFunctionalChildren';

import LoadingSpinner from '@ding/core/src/components/Parts/LoadingSpinner';
import TinyGqlQuery from '@ding/tiny-gql/src/react/TinyGqlQuery';

import {outlineButtonToolbarFn} from '@ding/control-groups/src/ExpandableFunctionalChunksList/toolbars/OutlineExpandToolbarRow';
import OffsetQueryChunksList from '../../ExpandableFunctionalChunksList/OffsetQueryChunksList';
import Section from '..';
import {viewportIntersectionToolbarFn} from '../../ExpandableFunctionalChunksList/toolbars/ViewportIntersectionToolbar';

const SectionLoadingSpinner = React.memo(() => {
  const t = useTranslate();

  return (
    <LoadingSpinner
      style={{height: 300}}
      title={t('website.placeholders.loading_section')}
    />
  );
});

/**
 * @typedef {object} OptionalGQLSectionParams - params for OptionalGQLSection component
 *
 * @property {{items: Array<{}>, totalPages: number, totalItems: number }[]} [list] - pass list of component to skip query
 * @property {string} [listSelector] - name of key that has data needed to render grid
 *
 * @property {boolean} [showLoadingPlaceholder] - show loading component when initial query is running
 * @property {boolean} [skeletonLoading] - render skeleton loading instead of loading spinner
 *
 * @property {function} queryComponent - predefined gql query function
 * @property {object} queryProps - props for gql query
 *
 * @property {function} gridComponent - predefined createComponentsGrid function with all necessary props
 * @property {object} gridProps - props passed to gridComponent
 *
 * @property {object} sectionProps - props for section component
 * @property {function} [expandMoreAnchor] - render component below grid in section component
 * @property {function} emptyListRenderFn - callback render function that run if query return empty list
 * @property {boolean} [renderEmptyPlaceholderWithoutSection] - when query return empty element do not render section component
 * @property {React.ReactNode} children -TODO:
*
* @property {boolean} [lazyExpand] - render infinite
* @property {function} [asyncChunkPropsFn] - TODO:
* @property {boolean} [lazyLoadOnScroll] - infinite scroll component
* @property {function} [toolbarRenderFn=viewportIntersectionToolbarFn] - TODO:
* @property {number} [expandBy] - number of item grid will expand every trigger
*/

/**
 * create section with requested data from gql in grid format (or any using renderFunction)
 * @param {OptionalGQLSectionParams} props - react component props
 */
const OptionalGQLSection = ({
  sectionProps,
  list,
  queryProps,
  gridProps,
  renderEmptyPlaceholderWithoutSection,
  emptyListRenderFn,
  queryComponent: QueryComponent,
  gridComponent: GridComponent,
  listSelector,
  expandBy,
  children,
  expandMoreAnchor,
  showLoadingPlaceholder,
  skeletonLoading,
  lazyExpand,
  lazyLoadOnScroll,
  toolbarRenderFn,
  asyncChunkPropsFn,
  ...props
}) => {
  if (lazyLoadOnScroll) {
    expandMoreAnchor = null;
    toolbarRenderFn = viewportIntersectionToolbarFn;
    lazyExpand = true;
  }

  const renderSection = (itemsList, data, loading) => {
    const emptyList = !itemsList || R.isEmpty(itemsList);

    if (!showLoadingPlaceholder && emptyList && !emptyListRenderFn)
      return null;

    let grid = null;
    if (GridComponent) {
      // render skeleton loading on first load (skeletonLoading && showLoadingPlaceholder)
      if (loading && emptyList) {
        grid = (
          <GridComponent
            placeholder={
              // NOTE: because false is phrased to 0 and i need undefined
              // eslint-disable-next-line no-nested-ternary
              (skeletonLoading && showLoadingPlaceholder)
                ? data?.totalItems < queryProps?.variables?.limit
                  ? data.totalItems
                  : queryProps.variables.limit
                : undefined
            }
            list={itemsList}
            {...gridProps}
          />
        );
      } else if (lazyExpand) {
        grid = (
          <OffsetQueryChunksList
            asyncChunkResolverComponent={QueryComponent}
            asyncChunkPropsFn={asyncChunkPropsFn}
            variables={queryProps?.variables}
            initialExpand={itemsList.length}
            expandBy={expandBy ?? itemsList.length}
            // NOTE: OffsetQueryChunksList do not support initialChunk, so to skip first request when list already provided we need to overwrite prop `queryComponent` with `SkipCachedChunksQuery` and using asyncChunkPropsFn to skip first query by returning as children object with initial data in shape of chunk
            optimisticResponse={
              (showLoadingPlaceholder && skeletonLoading)
                ? {items: itemsList || []}
                : undefined
            }
            // NOTE: to correctly estimate how many placeholder render in next chunk (how many items left) i need to know preview chunk and this is not supported by loadingComponent
            listRenderFn={
              ({items}, {info}, status) => {
                let placeholder;
                if (skeletonLoading && showLoadingPlaceholder && status?.loading) {
                  placeholder = info?.remainItems < queryProps?.variables?.limit
                    ? info.remainItems
                    : queryProps.variables.limit;
                }

                return (
                  <GridComponent
                    placeholder={placeholder}
                    list={items}
                    {...gridProps}
                  />
                );
              }
            }
            toolbarRenderFn={
              toolbarRenderFn || (
                expandMoreAnchor
                  ? toolbarParams => (
                    renderFunctionalChildren(expandMoreAnchor, toolbarParams) || null
                  )
                  : outlineButtonToolbarFn
              )
            }
          />
        );
      } else {
        grid = (
          <GridComponent
            list={itemsList}
            {...gridProps}
          />
        );
      }
    }

    const renderFallback = !loading && emptyList && emptyListRenderFn;
    if (renderFallback && renderEmptyPlaceholderWithoutSection)
      return emptyListRenderFn(data, props);

    return (
      <Section
        {...props}
        // TODO: BUG: fix missing button in skeletonLoading. Because i don't render OffsetQueryChunksList when loading render it here
        // TODO: BUG: using toolbar in OffsetQueryChunksList will not help because only after request finalize i know if i can render "more button"
        // TODO: maybe add if when loading and render just skeleton of button size, or pass disabled button when list is empty but this mean that i need to rewrite all buttons
        expandMoreAnchor={
          (!emptyList
            && !lazyExpand
            && renderFunctionalChildren(expandMoreAnchor, itemsList, data)) || null
        }
      >
        {/* if query return empty list and you want to render custom fallback */}
        {renderFallback && emptyListRenderFn(data, props)}
        {grid}
        {/* when as children passed is render function run this as extra */}

        {/* DEADCODE: (possible) allow to render children */}
        {!emptyList && renderFunctionalChildren(
          children,
          itemsList,
          data,
        )}
        {/* main full section loading spinner */}
        {loading && showLoadingPlaceholder && !skeletonLoading && (
          <SectionLoadingSpinner />
        )}
      </Section>
    );
  };

  if (list || !QueryComponent)
    return renderSection(list || []);

  return (
    <QueryComponent
      loadingComponent={null}
      alwaysRenderComponent={showLoadingPlaceholder}
      {...queryProps}
      {...lazyExpand && {
        // prevent twice fetch
        variables: {
          ...queryProps?.variables,
          page: 1,
        },
      }}
    >
      {(data, asyncParams) => (
        renderSection(
          selectResponseData(listSelector, data),
          data,
          asyncParams?.loading,
        )
      )}
    </QueryComponent>
  );
};

OptionalGQLSection.displayName = 'OptionalGQLSection';

OptionalGQLSection.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any),
  listSelector: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.any),
  ]),

  showLoadingPlaceholder: PropTypes.bool,
  skeletonLoading: PropTypes.bool,

  emptyListRenderFn: PropTypes.func,
  lazyExpand: PropTypes.bool,
  lazyLoadOnScroll: PropTypes.bool,
  expandBy: PropTypes.number,

  queryComponent: REACT_COMPONENT_CLASS_SCHEMA,
  queryProps: PROPS_SCHEMA,

  gridComponent: REACT_COMPONENT_CLASS_SCHEMA,
  gridProps: PROPS_SCHEMA,
};

OptionalGQLSection.defaultProps = {
  queryComponent: TinyGqlQuery,
  listSelector: 'items',
};

export default React.memo(OptionalGQLSection);
