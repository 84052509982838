import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import ssr from '@adretail/basic-helpers/src/base/ssr';

import memoizeMethod from '@adretail/basic-helpers/src/methods/memoizeMethod';
import {shallowNotEq} from '@adretail/basic-helpers/src/base/memoizeOne';

/**
 * It is tiny clone of
 *  https://github.com/diegohaz/constate
 *
 * but react constate has great API but it is slooow and
 * selectors are not cached. It is just smaller and faster
 * clone of this lib with caching, suited for tracking
 *
 * @todo
 *  Add hooks support
 *
 * @export
 */
export default class TinyContextProvider extends React.Component {
  static propTypes = {
    contextComponent: PropTypes.any.isRequired,

    initialState: PropTypes.objectOf(PropTypes.any),
    actions: PropTypes.objectOf(PropTypes.func),
    effects: PropTypes.objectOf(PropTypes.func),
    selectors: PropTypes.objectOf(PropTypes.func),

    // function that updates state using props
    // used e.g. in router that changes viewer page
    getStateFromProps: PropTypes.shape({
      keys: PropTypes.arrayOf(PropTypes.any).isRequired,
      fn: PropTypes.func.isRequired,
    }),

    onBroadcastedState: PropTypes.func,
  };

  static defaultProps = {
    initialState: {},
    actions: {},
    effects: {},
    selectors: {},
  };

  subscribers = [];

  state = {
    appState: null,
    prevUpdaterKeys: null, // used by getSteteFromProps
  };

  static getDerivedStateFromProps(
    {initialState, resetStateKey, getStateFromProps},
    {appState, prevUpdaterKeys, prevResetStateKey},
  ) {
    let newAppState;

    if (!R.isNil(prevResetStateKey)
        && prevResetStateKey !== resetStateKey
        && resetStateKey !== undefined) {
      newAppState = initialState;
    } else {
      newAppState = appState || initialState;
    }

    if (getStateFromProps) {
      const {keys, fn} = getStateFromProps;

      const keysUpdated = (fn && shallowNotEq(keys, prevUpdaterKeys || []));
      const newState = (
        keysUpdated
          ? fn(newAppState, prevUpdaterKeys, keys)
          : newAppState
      );

      return {
        prevResetStateKey: resetStateKey,
        prevUpdaterKeys: keys,
        appState: newState,
      };
    }

    return {
      prevResetStateKey: resetStateKey,
      appState: newAppState,
    };
  }

  getComputedContextState = (() => {
    const fastCache = {};

    return (appState) => {
      if (fastCache.appState !== appState) {
        const {
          resetStateKey, actions,
          effects, selectors,
        } = this.props;

        // resetStateKey is optional arg, cache killer used in fast state swap
        const linkedSelectors = this.createSelectors(selectors, resetStateKey);

        fastCache.appState = appState;
        fastCache.computedState = {
          state: appState,
          ...linkedSelectors,
          ...this.linkEffectsToState(effects),
          ...this.linkActionsToState(actions, linkedSelectors),
        };
      }

      return fastCache.computedState;
    };
  })();

  componentDidUpdate(prevProps, prevState) {
    if (prevState.appState !== this.state.appState)
      this.broadcastState();
  }

  setBroadcastedState = (updater) => {
    const {onBroadcastedState} = this.props;
    const {appState} = this.state;
    const newState = updater(appState);

    const differs = newState !== appState;
    if (differs) {
      this.state.appState = newState;
      this.broadcastState();
    }

    // eslint-disable-next-line no-unused-expressions
    onBroadcastedState?.(newState, appState, differs);
  };

  @memoizeMethod
  getContextValue() {
    return {
      getState: () => this.getComputedContextState(this.state.appState),
      subscribe: (fn) => {
        this.subscribers.push(fn);
      },
      unsubscribe: (fn) => {
        const {subscribers} = this;

        const index = subscribers.indexOf(fn);
        if (index === -1)
          return false;

        subscribers.splice(index, 1);
        return true;
      },
    };
  }

  broadcastState = () => {
    const computedAppState = this.getComputedContextState(this.state.appState);

    R.forEach(
      subscriberFn => subscriberFn && subscriberFn(computedAppState),
      this.subscribers,
    );
  };

  /**
   * Creates selectors
   *
   * @param {Object} selectors
   * @returns {Object}
   */
  /* eslint-disable class-methods-use-this */
  @memoizeMethod
  createSelectors(selectors) {
    return R.mapObjIndexed(
      R.compose(
        fn => (...args) => {
          const {state: {appState}} = this;
          return fn(appState, ...args);
        },
        R.applyTo(null),
      ),
      selectors,
    );
  }
  /* eslint-enalbe class-methods-use-this */

  /**
   * Effects are async actions(similiar to redux-thunk).
   * Maps them to return state and provide them "fake" setState function,
   * that modifies only appState
   *
   * @param {Object}  effects
   * @returns {Object}
   */
  @memoizeMethod
  linkEffectsToState(effects) {
    const mapper = effectFn => (...args) => {
      const {appState} = this.state;

      return effectFn(...args)({
        state: appState,
        setState: this.setBroadcastedState,
      });
    };

    return R.mapObjIndexed(
      mapper,
      effects,
    );
  }

  /**
   * Wraps each function to return its value into appState
   *
   * @param {Object}  actions
   * @param {Object}  linkedSelectors
   * d
   * @returns {Object}
   */
  @memoizeMethod
  linkActionsToState(actions, linkedSelectors) {
    const mapper = actionFn => (...args) => {
      if (ssr)
        actionFn(...args)(this.state.appState, linkedSelectors);
      else {
        this.setBroadcastedState(
          appState => actionFn(...args)(appState, linkedSelectors),
        );
      }
    };

    return R.mapObjIndexed(
      mapper,
      actions,
    );
  }

  render() {
    const {
      children,
      contextComponent: StateContext,
    } = this.props;

    return (
      <StateContext.Provider value={this.getContextValue()}>
        {children}
      </StateContext.Provider>
    );
  }
}
