import React from 'react';
import PropTypes from 'prop-types';
import {gql, GraphQLFragment} from '@ding/tiny-gql';

import {FETCH_POLICY} from '@ding/tiny-gql/src/react/TinyGqlQuery';

import {useGeolocationAddressInput} from '@ding/geolocation/src/client/components/GeolocationContext';

import DefaultTinyQuery from '@ding/control-groups/src/DefaultTinyQuery';
import LeafletCardFragment from '../fragments/LeafletCard.graphql';
import assignLocationToFilters from './helpers/assignLocationToFilters';

export const SUGGESTED_LEAFLETS_PLACEMENT = {
  HOME: 'HOME',
  VIEWER: 'VIEWER',
};

export const SELECTED_FOR_USERS_LEAFLETS_QUERY = new GraphQLFragment(
  'customerLeafletSuggestions',
  gql`
    ${LeafletCardFragment}

    query customerLeafletSuggestions(
      $limit: Int = 12,
      $page: Int = 1,
      $filters: CustomerLeafletSuggestionsFilters,
      $offset: Int
    ) @locale(lang: ${APP_LANGUAGE}) {
      leaflets: customerLeafletSuggestions(
        limit: $limit,
        page: $page,
        filters: $filters,
        offset: $offset,
      ) {
        items {
          ...LeafletCard
        }
        totalPages
        totalItems
      }
    }
  `,
);

export const SEGMENTED_SELECTED_FOR_USERS_LEAFLETS_QUERY = new GraphQLFragment(
  'customerLeafletSegmentedSuggestions',
  gql`
    ${LeafletCardFragment}

    query customerLeafletSegmentedSuggestions(
      $limit: Int = 12,
      $page: Int = 1,
      $filters: SuggestedLeafletsFilters,
      $offset: Int,
      $placement: SuggestedLeafletsPlacements,
    ) @locale(lang: ${APP_LANGUAGE}) {
      leaflets: suggestedLeaflets(
        limit: $limit,
        page: $page,
        filters: $filters,
        offset: $offset,
        placement: $placement,
      ) {
        items {
          ...LeafletCard
        }
        totalPages
        totalItems
      }
    }
  `,
);

const SelectedForUserLeafletsQuery = ({variables, filters, placement, ...props}) => {
  const addressInput = useGeolocationAddressInput();
  return (
    <DefaultTinyQuery
      query={(
        placement || variables?.placement
          ? SEGMENTED_SELECTED_FOR_USERS_LEAFLETS_QUERY
          : SELECTED_FOR_USERS_LEAFLETS_QUERY
      )}
      clientFetchPolicy={FETCH_POLICY.NETWORK_ONLY}
      responseSelector='leaflets'
      variables={
        assignLocationToFilters(
          addressInput,
          {
            limit: 12,
            placement: placement || SUGGESTED_LEAFLETS_PLACEMENT.HOME,
            filters,
            ...variables,
          },
        )
      }
      {...props}
    />
  );
};

SelectedForUserLeafletsQuery.displayName = 'SelectedForUserLeafletsQuery';

SelectedForUserLeafletsQuery.propTypes = {
  placement: PropTypes.string,
};

export default SelectedForUserLeafletsQuery;
