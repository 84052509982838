import React from 'react';

import {
  LIGHT_CARD_GRAY,
  LIGHT_MUTED_RED,
  LIGHT_RED,
} from '@ding/constants/src/colorSchema';

import {injectClassesSheet} from '@adretail/fast-stylesheet/src/react';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';

const SCROLLBAR_SELECTOR = 'custom-scrollbar';

const css = {
  '@global': {
    body: createBreakpointClasses({
      md: {
        overflowY: 'scroll',

        [`& ${SCROLLBAR_SELECTOR} ::-webkit-scrollbar`]: {
          width: 4,
          height: 4,
        },

        [`& ${SCROLLBAR_SELECTOR} ::-webkit-scrollbar-track`]: {
          background: LIGHT_CARD_GRAY,
        },

        [`& ${SCROLLBAR_SELECTOR} ::-webkit-scrollbar-thumb`]: {
          background: LIGHT_RED,
          cursor: 'pointer',
          '&:hover': {
            background: LIGHT_MUTED_RED,
          },
        },

      },
    }),
  },
  hide: {
    '& ::-webkit-scrollbar': {
      display: 'none',
    },
  },
};

const CustomScrollbars = ({children, classes, hide, ...props}) => {
  const Component = SCROLLBAR_SELECTOR;
  return (
    <Component class={hide && classes.hide} {...props}>
      {children}
    </Component>
  );
};

CustomScrollbars.displayName = 'CustomScrollbars';

export default injectClassesSheet(css)(CustomScrollbars);
