import * as R from 'ramda';

/**
 * Generates object from GET pairs
 *
 * @example
 *  ['12-bb'] => {12: {id: 12, name: 'bb'}}
 */
const decodeCheckboxGETPairs = R.compose(
  R.fromPairs,
  R.reject(R.isNil),
  R.map(
    (pair) => {
      const match = R.match(/^(\d+)-(.*)$/, pair);
      if (!match || !match.length)
        return null;

      return [
        match[1],
        {
          id: match[1],
          name: match[2],
        },
      ];
    },
  ),
);

export default decodeCheckboxGETPairs;
