import {useMemo} from 'react';
import * as R from 'ramda';

import ssr from '@adretail/basic-helpers/src/base/ssr';

export const JSON_ACCESSOR = {
  serializer: JSON.stringify,
  deserializer: R.unless(
    R.isNil,
    JSON.parse,
  ),
};

/**
 * Allows to single abstract item throuh localstorage
 *
 * @param {String} flagName
 * @param {Object} config
 */
const useLocalStorageFlagAccessor = (
  flagName,
  {
    serializer = JSON_ACCESSOR.serializer,
    deserializer = JSON_ACCESSOR.deserializer,
    storage = 'localStorage',
  } = JSON_ACCESSOR,
) => useMemo(
  () => {
    if (ssr) {
      return {
        get: R.always(null),
        set: R.F,
      };
    }

    const storageDB = window[storage];
    return {
      get: () => {
        try {
          return deserializer(storageDB[flagName]);
        } catch (e) {
          console.error(e);
          return undefined;
        }
      },
      set: (value) => {
        storageDB[flagName] = serializer(value);
      },
    };
  },
  [],
);

/**
 * Allows to access list throuh localstorage
 *
 * @param {String} flagName
 * @param {Object} config
 */
export const useLocalStorageListAccessor = (
  flagName,
  {
    limit = 40,
    removeDuplicates = true,
  } = {},
) => {
  const {
    get,
    set,
  } = useLocalStorageFlagAccessor(flagName, JSON_ACCESSOR);

  return {
    append: (item) => {
      const list = get() || [];

      let truncatedList = (
        limit
          ? R.takeLast(limit - 1, list)
          : list
      );

      if (removeDuplicates)
        truncatedList = R.without([item], truncatedList);

      set(
        [
          ...truncatedList,
          item,
        ],
      );
    },
    get,
    set,
  };
};

export default useLocalStorageFlagAccessor;
