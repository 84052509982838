import React from 'react';

import TitledCheckbox from '@ding/controls/src/CheckBox/TitledCheckbox';
import AuthResponsiveModalToggle from './AuthResponsiveModalToggle';

/**
 * Renders component for not authorized users, it should
 * trigger auth modal, it is locked to false value
 */
const AuthorizationToggleSwitch = ({children, triggerComponent, ...props}) => (
  <AuthResponsiveModalToggle
    triggerComponent={triggerComponent || TitledCheckbox}
    type='switch'
    watchEvent='onChange'
    value={false}
    allowTitleToggle={false}
    {...props}
  >
    {children}
  </AuthResponsiveModalToggle>
);

AuthorizationToggleSwitch.displayName = 'AuthorizationToggleSwitch';

export default AuthorizationToggleSwitch;
