import {CONTRACTOR_SCHEMA} from '@ding/constants/src/typeSchema';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import createComponentsGrid from '@ding/core/src/components/Utils/createComponentsGrid';

import EmptyTitlePlaceholder from '@ding/core/src/components/Parts/EmptyTitlePlaceholder';
import ContractorCard from '../ContractorCard';
import ContractorCardSkeleton from '../ContractorCardSkeleton';

export const NoContractors = provideProps(
  {
    translatePath: 'website.placeholders.empty_contractors',
  },
)(EmptyTitlePlaceholder);

export default createComponentsGrid(
  {
    itemSchema: CONTRACTOR_SCHEMA,
    itemComponent: ContractorCard,
    skeletonComponent: ContractorCardSkeleton,
    emptyPlaceholderComponent: NoContractors,
    defaultGrid: {
      xs: 6,
      md: 3,
      lg: 2,
    },
  },
);
