import {useEffect} from 'react';

import mountElementEvents from '@adretail/basic-helpers/src/dom/mountElementEvents';
import {useDingFeatures} from '@ding/features';
import useReactRouter from '@ding/core/src/hooks/useReactRouter';

const useSitebrandingWatcher = () => {
  const features = useDingFeatures();
  const {location: {pathname}} = useReactRouter();

  // sitebranding
  useEffect(
    () => mountElementEvents(
      null,
      {
        adSponsStateChange: ({detail}) => {
          features.toggleFeature('sitebranding', detail);
        },
      },
      window,
    ),
    [],
  );

  useEffect(
    () => {
      window.advancedAds?.destroyAll?.();
    },
    [pathname],
  );
};

export default useSitebrandingWatcher;
