import {gql, GraphQLFragment} from '@ding/tiny-gql';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import AddressFragment from '@ding/api/src/gql/fragments/Address.graphql';
import DefaultTinyQuery from '@ding/control-groups/src/DefaultTinyQuery';

export const GEOLOCATION_QUERY = new GraphQLFragment(
  'geolocationQuery',
  gql`
    ${AddressFragment}
    query geolocationQuery(
      $coords: GeoCoordinates,
      $distance: Int = 5,
      $withProvince: Boolean = true,
      $withRegion: Boolean = false,
      $withPostcode: Boolean = true
    ) @locale(lang: ${APP_LANGUAGE}) {
      geoLocation(
        coords: $coords,
        distance: $distance,
      ) {
        ...Address
      }
    }
  `,
);

export const GeolocationQuery = provideProps(
  {
    query: GEOLOCATION_QUERY,
    responseSelector: 'geoLocation',
  },
)(DefaultTinyQuery);

export default GEOLOCATION_QUERY;
