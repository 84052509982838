import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {Text} from '@ding/core/src/components/Predefined';
import FormRow from '../FormRow';

export const mapObjectToDivs = R.compose(
  R.map(
    title => (
      <Text
        key={title}
        size='small'
        block
        style={{
          lineHeight: '20px',
        }}
      >
        {title}
      </Text>
    ),
  ),
  description => (
    !R.is(String, description) && R.is(Object, description) && !React.isValidElement(description)
      ? R.values(description)
      : [description]
  ),
);

const DescriptionFormRow = ({title, description}) => (
  <FormRow
    title={title}
    titleProps={{
      size: 'medium',
    }}
  >
    {mapObjectToDivs(description)}
  </FormRow>
);

DescriptionFormRow.displayName = 'DescriptionFormRow';

DescriptionFormRow.propTypes = {
  title: PropTypes.node,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
  ]),
};

export default DescriptionFormRow;
