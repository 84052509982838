import React from 'react';
import PropTypes from 'prop-types';
import {gql, GraphQLFragment} from '@ding/tiny-gql';

import {ID_SCHEMA} from '@adretail/schemas';

import useQuery from '@ding/tiny-gql/src/react/hooks/useQuery';

import TinyGqlQuery from '@ding/tiny-gql/src/react/TinyGqlQuery';
import SomethingGoneWrong from '@ding/core/src/components/Parts/SomethingGoneWrong';

import NotifyTagComposedFragment from '@ding/api/src/gql/fragments/composed/NotifyTagComposed';
import ViewerInsertFragment from '@ding/slide-viewer/src/Viewer/gql/fragments/ViewerInsert.graphql';

import ViewerModuleFragment from './fragments/ViewerModule.graphql';
import ViewerPageFragment from './fragments/ViewerPage.graphql';
import ViewerInfoFragment from './fragments/ViewerInfo.graphql';

export const LEAFLET_QUERY_NAME = 'viewerLeafletQuery';

export const LEAFLET_QUERY_CACHE_PREFIX = 'leaflet-';

export const getLeafletQueryCachePrefix = id => `${LEAFLET_QUERY_CACHE_PREFIX}${id}-`;

export const LEAFLET_QUERY = new GraphQLFragment(
  LEAFLET_QUERY_NAME,
  gql`
    ${ViewerPageFragment}
    ${ViewerModuleFragment}
    ${ViewerInsertFragment}
    ${ViewerInfoFragment}
    ${NotifyTagComposedFragment}
    query viewerLeafletQuery(
      $id: ID!,
      $withTags: Boolean = false,
      $withLeafletContent: Boolean = true,
      $withProducts: Boolean = true,
      $imagesSize: String = "C127"
    ) @locale(lang: ${APP_LANGUAGE}) {
      viewerLeaflet(id: $id, withArchival: true) {
        ... @include(if: $withTags) {
          info {
            tags {
              ...NotifyTag
            }
          }
        }
        ... @include(if: $withLeafletContent) {
          info {
            ...ViewerInfo
          }
          pages {
            ...ViewerPage
          }
        }
      }
    }
  `,
);

/**
 * !!!!!
 * Watch variables order due to cache! Cache key is based
 * on ORDERED variables so sometimes useLeafletQuery vs LeafletQuery
 * might return different variables
 */
const DEFAULT_VARIABLES = {
  withLeafletContent: true,
  withTags: false,
  withProducts: true,
};

const LeafletQuery = ({id, withTags, withLeafletContent, withProducts, children, ...props}) => (
  <TinyGqlQuery
    errorComponent={SomethingGoneWrong}
    responseSelector='viewerLeaflet'
    query={LEAFLET_QUERY}
    variables={{
      withLeafletContent,
      withTags,
      withProducts,
      id,
    }}
    cacheKey={{
      expire: 0,
    }}
    {...props}
  >
    {children}
  </TinyGqlQuery>
);

LeafletQuery.propTypes = {
  id: ID_SCHEMA.isRequired,
  withLeafletContent: PropTypes.bool,
  withTags: PropTypes.bool,
  withProducts: PropTypes.bool,
};

LeafletQuery.defaultProps = DEFAULT_VARIABLES;

export const useLeafletQuery = ({variables, ...params} = {}) => useQuery(
  LEAFLET_QUERY,
  {
    responseSelector: 'viewerLeaflet',
    cacheKey: {
      expire: 0,
    },
    ...params,
    variables: {
      ...DEFAULT_VARIABLES,
      ...variables,
    },
  },
);

export const preloadLeaflet = ({client, leafletId}) => {
  const variables = {
    withLeafletContent: true,
    withTags: false,
    withProducts: true,
    id: leafletId,
  };

  return client.query(
    LEAFLET_QUERY,
    variables,
    null,
    {
      expire: 0,
    },
  );
};

export default LeafletQuery;
