/**
 * Rotate down to up
 */
export const verticalFlip = {
  transform: 'rotateZ(-180deg)',
};

/**
 * Rotate left to right
 *
 * @todo
 *  Check it
 */
export const horizontalFlip = {
  transform: 'rotate3D(-90deg)',
};
