import React from 'react';
import PropTypes from 'prop-types';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/schemas/basic';
import {
  LIGHT_BORDER,
  PRIMARY_FILL,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import textEllipsisOverflow from '../../../styles/textEllipsisOverflow';
import {createBreakpointClasses} from '../../../helpers/createBreakpoints';

import PlainUnorderedList from '../../Predefined/PlainUnorderedList';

const ITEM_SIZE_CSS = {
  tiny: {
    '& > *': {
      padding: '4px 5px !important',
    },
  },

  small: {
    '& > *': {
      padding: '8px 10px !important',
    },
  },

  medium: {
    '& > *': {
      padding: '15px 10px !important',
      minHeight: 46,
    },
  },

  big: {
    '& > *': {
      padding: '20px 10px !important',
    },
  },
};

const LIST_ITEM_CSS = {
  base: {
    extend: textEllipsisOverflow,

    fontWeight: 'bold',
    fontSize: 13,
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',
    transition: 'color 250ms ease-in-out',

    '& > *': {
      display: 'inline-block',
      width: '100%',
    },

    '&:not(:last-child)': {
      borderBottom: `1px solid ${LIGHT_BORDER}`,
    },

    '&:hover': createBreakpointClasses({
      md: {
        color: PRIMARY_FILL,
      },
    }),
  },

  active: {
    color: PRIMARY_FILL,
    fontWeight: '700 !important',
  },

  highlight: {
    background: 'rgba(0, 0, 0, 0.03)',
  },

  alwaysBordered: {
    borderBottom: `1px solid ${LIGHT_BORDER}`,
  },

  wrap: {
    whiteSpace: 'normal',
  },

  ...ITEM_SIZE_CSS,
};

export const SeparatedListItem = styled(
  ({children, wrapContent, ...props}) => (
    <li {...props}>
      {(
        wrapContent
          ? <span>{children}</span>
          : children
      )}
    </li>
  ),
  LIST_ITEM_CSS,
  {
    omitProps: [
      'alwaysBordered', 'size', 'active', 'highlight', 'wrap',
    ],
    classSelector: (classes, {alwaysBordered, active, highlight, wrap, size}) => [
      classes[size],
      active && classes.active,
      highlight && classes.highlight,
      alwaysBordered && classes.alwaysBordered,
      wrap && classes.wrap,
    ],
    index: 4,
  },
);

SeparatedListItem.propTypes = {
  size: SIZE_ARRAY_SCHEMA,

  // used in autocomplete
  active: PropTypes.bool,
  highlight: PropTypes.bool,

  wrapContent: PropTypes.bool, // list item might be also anchor or button
  alwaysBordered: PropTypes.bool, // :not(:last-child) border is ignored if true
};

SeparatedListItem.defaultProps = {
  size: 'big',
  wrapContent: true,
};

/**
 * Used in popups to split buttons in list with dividers
 *
 * @export
 */
export default PlainUnorderedList;
