import React from 'react';
import PropTypes from 'prop-types';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import styled from '@adretail/fast-stylesheet/src/react/styled';

import PageSearch from '@ding/search';

import HeaderToolbar, {HeaderItem} from '../HeaderToolbar';
import * as Items from './Items';

const DesktopHeaderHolder = styled(
  HeaderToolbar,
  {
    base: {
      flex: 1,
      paddingLeft: 30,
    },
  },
);

const DesktopHeaderToolbar = ({prependItems, appendItems}) => {
  const t = useTranslate('website.bottom_bar');

  const defaultMenuItems = (
    <>
      <Items.Products
        margin='auto'
      />

      <Items.Notifications
        margin='auto'
      />

      <Items.Geolocation
        margin='auto'
      />

      <Items.Auth
        margin='auto'
      />

      <Items.Info
        margin='auto'
      />
    </>
  );

  const sharedLeftToolbarProps = {
    margin: 'none',
  };

  const leftToolbar = (
    <nav aria-label='Site-navigation'>
      <HeaderToolbar itemSpace='large'>
        <Items.Leaflets
          subtitle={t('leaflets')}
          {...sharedLeftToolbarProps}
        />
        <Items.Offers
          subtitle={t('offers')}
          {...sharedLeftToolbarProps}
          iconProps={{
            style: {
              transform: 'scale(0.8)',
            },
          }}
        />
        <Items.Stores
          subtitle={t('contractors')}
          {...sharedLeftToolbarProps}
        />
        <Items.Blog
          subtitle={t('blog')}
          {...sharedLeftToolbarProps}
        />
        <Items.Calendar
          subtitle={t('shopping_sundays')}
          {...sharedLeftToolbarProps}
        />
      </HeaderToolbar>
    </nav>
  );

  return (
    <DesktopHeaderHolder role='presentation'>
      {leftToolbar}
      {prependItems}

      <HeaderItem
        style={{marginLeft: 'auto'}}
        overrideIconSize={false}
      >
        <PageSearch
          inputProps={{
            size: 'medium',
          }}
        />
      </HeaderItem>

      {defaultMenuItems}
      {appendItems}
    </DesktopHeaderHolder>
  );
};

DesktopHeaderToolbar.displayName = 'DesktopHeaderToolbar';

DesktopHeaderToolbar.propTypes = {
  truncated: PropTypes.bool, // when nav in fixed mode
  prependItems: PropTypes.node,
  appendItems: PropTypes.node,
};

export default React.memo(DesktopHeaderToolbar);
