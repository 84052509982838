import React, {useMemo} from 'react';
import * as R from 'ramda';

import ssr from '@adretail/basic-helpers/src/base/ssr';

import TrackersContext from './TrackersContext';

import iwaTracking from '../trackers/iwa';
import ga4Tracking from '../trackers/ga4';

import getLazyLoadGlobalFunction from '../helpers/lazyLoadGlobalFunction';
import {createFormattedCallers} from '../helpers/formattedCallers';

const getSafeGemiusGlobalObject = () => {
  if (ssr)
    return null;

  if (!window.gemius_hit) {
    const gemiusPending = (event) => {
      window[event] = window[event] || ((...args) => {
        const windowHandler = `${event}_pdata`;
        window[windowHandler] = window[windowHandler] || [];

        const x = window[windowHandler];
        x[x.length] = args;
      });
    };

    gemiusPending('gemius_hit');
    gemiusPending('gemius_event');
    gemiusPending('pp_gemius_hit');
    gemiusPending('pp_gemius_event');
  }

  if (!window.gemius) {
    window.gemius = {
      hit: (...args) => window.gemius_hit(...args),
      event: (...args) => window.gemius_event(...args),
    };
  }

  return window.gemius;
};

const TrackersProvider = ({children}) => {
  const trackers = useMemo(
    () => ({
      // GEMIUS
      gemius: getSafeGemiusGlobalObject(),

      // GA4
      ga4: createFormattedCallers(
        (...args) => {
          if (!window.dataLayer) {
            console.warn('GA4::dataLayer: GA4 call aborted, GA4 is missing or dataLayer is not registered');
            return;
          }

          const params = {};
          for (let i = 0; i < args.length; i++) {
            if (i === 0) {
              params.event = args[i];
              // eslint-disable-next-line no-continue
              continue;
            }
            params[`param${i}`] = args[i];
          }

          window.dataLayer.push(
            params,
            /**
             * NOTE: dataLayer in GTM will be remember old params.
             * If next event will has a less parameters than current
             * then dataLayer will add extra parameters from current event to next event.
             * Push reset function to reset dataLayer every call and prevent this behavior.
             * https://developers.google.com/tag-platform/devguides/datalayer#reset
             */
            function reset() {
              // eslint-disable-next-line react/no-this-in-sfc
              this.reset();
            },
          );
        },
        ga4Tracking.events,
      ),

      // IWA
      iwa: (() => {
        const pickIWAVariablesArg = R.compose(
          R.unless(
            R.both(R.is(Object), R.complement(Array.isArray)),
            R.always(null),
          ),
          R.last,
        );

        const iwa3 = getLazyLoadGlobalFunction('iwa3');

        /**
         * Due to IWA1/2 issues customVariables
         * are not passed to every trackEvent,
         * try to polyfill using trackEventGlobalVariables
         */
        const trackEventGlobalVariables = {};
        const iwa = createFormattedCallers(
          (...args) => {
            // add logged flag to custom variables
            if (args[0] === 'trackEvent' && !R.isEmpty(trackEventGlobalVariables)) {
              const customVariables = pickIWAVariablesArg(args);
              const patchArgs = (
                customVariables
                  ? R.init(args)
                  : args
              );

              patchArgs.push(
                {
                  ...customVariables,
                  ...trackEventGlobalVariables,
                },
              );

              iwa3(...patchArgs);
            } else {
              iwa3(...args);
            }
          },
          iwaTracking.events,
        );

        iwa.setEventGlobalVar = (key, value) => {
          trackEventGlobalVariables[key] = value;
          iwa('setCustomVar', key, value, 'page');
        };

        iwa.delEventGlobalVar = (key) => {
          delete trackEventGlobalVariables[key];
          iwa('delCustomVar', key, 'page');
        };

        return iwa;
      })(),
    }),
    [],
  );

  return (
    <TrackersContext.Provider value={trackers}>
      {children}
    </TrackersContext.Provider>
  );
};

export default TrackersProvider;
