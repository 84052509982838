import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {AsyncSpinnerButton} from '@ding/controls/src/';

const SetPasswordButton = (props) => {
  const t = useTranslate();

  return (
    <AsyncSpinnerButton
      expanded
      type='primary'
      {...props}
    >
      {t('website.auth.titles.set_password')}
    </AsyncSpinnerButton>
  );
};

SetPasswordButton.displayName = 'SetPasswordButton';

export default SetPasswordButton;
