import {
  parameterize,
  parameterizeAddress,
} from '@ding/core/src/helpers';
import {languages} from '@ding/i18n/src/languages';

import createLinkComponent from '../../utils/createLinkComponent';

export const STORE_URL_SCHEMA = `${languages.uk.prefix}/:contractorName,:address,:id`;

export const genStoreURL = ({id, contractor, address}) => (
  `${languages.uk.prefix}/${parameterize(contractor.name)},${parameterizeAddress(address)},${id}`
);

export default createLinkComponent('store', genStoreURL);
