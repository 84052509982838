import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import IconText from '../Predefined/IconText';
import LoadingSpinner from './LoadingSpinner';

const TitledLoadingSpinner = (props) => {
  const t = useTranslate();

  return (
    <IconText
      icon={
        <LoadingSpinner size='small' />
      }
      title={
        t('website.placeholders.loading')
      }
      {...props}
    />
  );
};

TitledLoadingSpinner.displayName = 'TitledLoadingSpinner';

export default TitledLoadingSpinner;
