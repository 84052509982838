import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';

import {MAGIC_HYDRATION_ROOT_ID} from '@cdd/internal-server/src/constants/hydrationKeys';

import showToast from '@ding/controls/src/ToastAlert/showToast';

import wrapWithAsyncHydrationData from '@adretail/async-cache-utils/src/components/AsyncSSRComponent/wrapWithAsyncHydrationData';
import HTMLTemplate from '@ding/layout/src/HTML/HTMLTemplate';

if (navigator.cookieEnabled) {
  Loadable.preloadReady().then(() => {
    const HydrateComponent = wrapWithAsyncHydrationData(HTMLTemplate);

    ReactDOM.hydrate(
      <HydrateComponent />,
      document.getElementById(MAGIC_HYDRATION_ROOT_ID),
      () => {
        window.__hydrated = true;
      },
    );
  });
} else
  showToast('Cookies are disabled, cancel page hydration!');

if (('serviceWorker' in navigator) && process.env.NODE_ENV === 'production') {
  const registerPromise = navigator.serviceWorker.register('/sw.js');
  registerPromise
    .then((registration) => {
      console.info('Successfully registered service worker', registration);
    })
    .catch((err) => {
      console.error(err);
    });
}
