import React from 'react';
import * as R from 'ramda';

import env from '@cdd/internal-server/src/constants/env';
import rejectNil from '@adretail/basic-helpers/src/functors/rejectNil';

import DefaultTinyQuery from '@ding/control-groups/src/DefaultTinyQuery';

import publishersApiGqlClient from '../../clients/publishersApiGqlClient';
import {
  PUBLISHERS_WIDGET_BY_PLACEMENT_LEAFLETS_QUERY,
  PUBLISHERS_WIDGET_LEAFLETS_QUERY,
} from './publisherWidgetLeafletsQuery';

const DEFAULT_LIST_CONFIG = env.client?.publishers?.worthToSee;

export const mapResponseToInternalList = R.compose(
  rejectNil,
  R.map(
    (item) => {
      if (!('leaflet' in item))
        return item;

      const {leaflet, contractor, coverOnlyForAdults} = item;

      if (!leaflet)
        return {
          // custom fields
          contractor,
          coverOnlyForAdults,

          // shared fields for custom
          cover: item.image,
          duration: item.promotion,
          ...R.pick(
            [
              'id', 'name',
              'logo', 'link',
            ],
            item,
          ),
        };

      return {
        // custom fields
        contractor,
        coverOnlyForAdults,

        // shared fields
        ...R.pick(
          [
            'id', 'name',
            'cover', 'duration',
          ],
          leaflet,
        ),
      };
    },
  ),
  R.pathOr([], ['widget', 'slots']),
);

const WorthToSeeLeafletsQuery = ({variables, ...props}) => (
  <DefaultTinyQuery
    query={(
      variables && ('id' in variables)
        ? PUBLISHERS_WIDGET_LEAFLETS_QUERY
        : PUBLISHERS_WIDGET_BY_PLACEMENT_LEAFLETS_QUERY
    )}
    responseSelector={mapResponseToInternalList}
    client={publishersApiGqlClient}
    {...props}

    variables={{
      ...DEFAULT_LIST_CONFIG,
      ...variables,
    }}
  />
);

WorthToSeeLeafletsQuery.displayName = 'WorthToSeeLeafletsQuery';

export default WorthToSeeLeafletsQuery;
