import {
  AGGREGATOR_TYPES,
  GEOLOCATION_METHODS,
} from '../../constants';

import createAggregatorHookPartial from '../hooks/createAggregatorHookPartial';

/**
 * @param {String}  method
 * @param {Coords}  coords
 */
const useGeolocationAggregate = createAggregatorHookPartial(
  AGGREGATOR_TYPES.LOCATION_CHANGE,
  ({
    coords,
    method = GEOLOCATION_METHODS.MANUAL,
  }) => ({
    geolocationMethod: method,
    coords,
  }),
);

export default useGeolocationAggregate;
