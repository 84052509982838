import * as R from 'ramda';
import {gql, GraphQLFragment} from '@ding/tiny-gql';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import TinyGqlQuery, {FETCH_POLICY} from '@ding/tiny-gql/src/react/TinyGqlQuery';

export const GET_SHOPPING_LIST_COUNT_QUERY = new GraphQLFragment(
  'getShoppingListCount',
  gql`
    query getShoppingListCount @locale(lang: ${APP_LANGUAGE}) {
      shoppingList {
        count
      }
    }
  `,
);

const ShoppingListCountQuery = provideProps(
  {
    query: GET_SHOPPING_LIST_COUNT_QUERY,
    clientFetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    allowSSR: false,
    responseSelector: R.pathOr(0, ['shoppingList', 'count']),
  },
)(TinyGqlQuery);

ShoppingListCountQuery.displayName = 'ShoppingListCountQuery';

export default ShoppingListCountQuery;
