import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import {
  REACT_COMPONENT_CLASS_SCHEMA,
  PROPS_SCHEMA,
} from '@adretail/schemas';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/typeSchema';

import * as Colors from '@ding/constants/src/colorSchema';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import Button from '.';

const css = {
  base: {
    display: 'inline-block',
    position: 'relative',
    transition: 'color 250ms ease-in-out',
    padding: 0,
    lineHeight: '100%',
    maxHeight: '100%',
    border: 0,

    // okazjum mobile browser fix
    '&:hover': {
      background: 'inherit',
    },
  },

  titled: {
    display: 'flex',
    textAlign: 'left',

    '& i:not(:only-child)': {
      marginRight: 10,
    },
  },

  outline: {
    backgroundColor: 'transparent',
    border: '1.5px solid currentColor',
  },

  dark: {
    color: Colors.DARK,

    '&:hover': {
      color: 'rgba(0, 0, 0, 0.5)',
    },
  },

  gray: {
    color: Colors.MUTED_GRAY,

    '&:hover': {
      color: Colors.DARK_MUTED_GRAY,
    },
  },

  'dark-gray': {
    color: Colors.DARK_GRAY,

    '&:hover': {
      color: Colors.DARK_MUTED_GRAY,
    },
  },

  'light-gray': {
    color: Colors.LIGHT_CARD_GRAY,

    '&:hover': {
      color: Colors.LIGHT_BORDER,
    },
  },

  light: {
    color: Colors.WHITE,

    '&:hover': {
      color: 'rgba(255, 255, 255, 0.5)',
    },
  },

  green: {
    color: Colors.GRASS_GREEN,

    '&:hover': {
      color: 'rgba(19, 196, 111, 0.5)',
    },
  },

  primary: {
    color: Colors.PRIMARY_FILL,

    // '&:hover': {
    //   color: Colors.PRIMARY_FILL_SHADOW,
    // },
  },

  muted: {
    color: Colors.MUTED_GRAY,
    '&:hover': {
      color: Colors.DARK,
    },
  },

  'dark-inverted': {
    color: [Colors.WHITE, '!important'],
  },

  'light-inverted': {
    color: [Colors.DARK, '!important'],
  },

  'primary-inverted': {
    background: `${Colors.WHITE} !important`,
    color: Colors.PRIMARY_FILL,
    border: `1.5px solid ${Colors.PRIMARY_FILL}`,

    '&:hover': {
      background: `${Colors.PRIMARY_FILL} !important`,
      color: Colors.WHITE,
      border: `1.5px solid ${Colors.PRIMARY_FILL}`,
    },
  },

  filled: {
    borderRadius: '100%',
  },

  disabled: {
    color: [Colors.MUTED_GRAY, '!important'],
  },

  auto: {},

  micro: {
    width: 13,
    height: 13,
  },

  tiny: {
    width: 16,
    height: 16,
  },

  small: {
    width: 32,
    height: 32,
  },

  'medium-small': {
    width: 38,
    height: 38,
  },

  medium: {
    width: 48,
    height: 48,
  },

  big: {
    width: 64,
    height: 64,
  },

  'primary-filled': {
    background: `${Colors.PRIMARY_FILL} !important`,
    color: Colors.WHITE,

    '&:hover': {
      background: `${Colors.WHITE} !important`,
      color: Colors.PRIMARY_FILL,
    },
  },

  'primary-green-filled': {
    background: `${Colors.PRIMARY_FILL} !important`,
    color: Colors.WHITE,

    '&:hover': {
      background: `${Colors.GRASS_GREEN} !important`,
      color: Colors.WHITE,
    },
  },

  'secondary-light-filled': {
    background: `${Colors.DEFAULT_BUTTON_BACKGROUND} !important`,
    color: Colors.DARK,

    '&:hover': {
      background: `${Colors.PRIMARY_FILL} !important`,
      color: Colors.WHITE,
    },
  },

  'light-gray-filled': {
    background: `${Colors.LIGHT_CARD_GRAY}`,
    color: Colors.DARK,

    '&:hover': {
      background: `${Colors.LIGHT_BORDER}`,
    },
  },
};

/**
 * Small button that looks like single icon
 *
 * @export
 */
const IconButton = React.forwardRef(({
  // CornerIconHolder props might appear here
  link: LinkComponent,
  icon: IconComponent,
  iconSpacing, // just prevent DOM warnings
  iconOnRight,
  iconProps,

  className,
  classes,
  type,
  buttonType,
  size,
  iconSize,
  filled,
  inverted,
  disabled,
  radius,
  iconStyle,
  titled,
  children,
  ...props
}, ref) => {
  const Component = LinkComponent || Button;

  return (
    <Component
      {...props}
      ref={ref}
      className={c(
        className,
        classes.base,
        classes[type],
        size && classes[size],
        titled && classes.titled,
        inverted && classes[`${type}-inverted`],
        props?.outline && classes.outline,
        filled && [
          classes.filled,
          classes[`${type}-filled`],
        ],
        disabled && classes.disabled,
      )}
      {...!LinkComponent && {
        type: Button.Type.NO_DECORATION,
        expandedPadding: false,
        radius,
        disabled,
      }}
    >
      {(
        React.isValidElement(IconComponent)
          ? IconComponent
          : (
            <IconComponent
              type={null}
              size={
                iconSize || 'medium'
              }
              style={iconStyle}
              {...iconProps}
            />
          )
      )}
      {children}
    </Component>
  );
});

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
  type: PropTypes.oneOf([
    'none',
    'dark-gray', 'light-gray', 'gray',
    'light-gray-filled',
    'dark', 'light', 'muted', 'green',
    // buttons clone
    'secondary-light',
    'primary', 'primary-green',
  ]),
  size: SIZE_ARRAY_SCHEMA,

  iconProps: PROPS_SCHEMA,
  iconSize: PropTypes.string,
  icon: PropTypes.oneOfType([
    REACT_COMPONENT_CLASS_SCHEMA,
    PropTypes.node,
  ]).isRequired,

  filled: PropTypes.bool,
  titled: PropTypes.bool,
  inverted: PropTypes.bool,
  radius: PropTypes.string,
  iconStyle: PropTypes.objectOf(PropTypes.any),
};

IconButton.defaultProps = {
  type: 'dark',
  size: 'auto',
  iconSize: 'medium',
  filled: false,
  radius: 'none',
};

export default injectClassesSheet(css, {index: 2})(IconButton);
