import * as R from 'ramda';

import geolocationEnv from '@ding/geolocation/src/constants/geolocationEnv';
import areBlankCoords from './areBlankCoords';

const parseCoordsKeys = ({
  latitude, lat = latitude,
  longitude, lon = longitude,
}) => ({
  lat: lat ? Number.parseFloat(lat) : null,
  lon: lon ? Number.parseFloat(lon) : null,
});

export const safeParseCoords = R.compose(
  coords => (
    areBlankCoords(coords)
      ? geolocationEnv.defaultCoords
      : coords
  ),

  R.unless(R.isNil, parseCoordsKeys),
);

export default parseCoordsKeys;
