import React from 'react';
import * as R from 'ramda';

import translate from '@ding/i18n';

import {Text} from '@ding/core/src/components/Predefined';
import SetNewPasswordSection from '../../SetNewPasswordSection';

const EmailSentConfirm = ({t}) => (
  <SetNewPasswordSection
    formTitle={
      t('website.auth.sections.reset_password.sent_email_done')
    }
  >
    <Text
      weight={700}
      block
    >
      {t('website.auth.sections.reset_password.link_sent_to_email')}
    </Text>
    {t('website.auth.sections.reset_password.click_to_set_password')}
  </SetNewPasswordSection>
);

EmailSentConfirm.displayName = 'EmailSentConfirm';

export default R.compose(
  React.memo,
  translate,
)(EmailSentConfirm);
