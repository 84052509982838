import React from 'react';
import {useRouteMatch} from 'react-router-dom';

import StartIcon from '@ding/core/src/icons/bottom-bar/Start';
import {HomeLink} from '@ding/layout/src/components/Links';
import BottomBarButton from '../BottomBarButton';

const StartItem = ({subtitle, onClick}) => {
  const match = useRouteMatch('/');

  return (
    <BottomBarButton
      active={!!match?.isExact}
      component={HomeLink}
      subtitle={subtitle}
      title={subtitle}
      onClick={onClick}
    >
      <StartIcon size='medium' />
    </BottomBarButton>
  );
};

StartItem.displayName = 'StartItem';

export default StartItem;
