import React from 'react';

import {showPromiseToast} from '@ding/controls/src/ToastAlert/showToast';
import renderFunctionalChildren from '@adretail/basic-helpers/src/ui/renderFunctionalChildren';

import {IconText} from '@ding/core/src/components/Predefined';
import BellOutlineIcon from '@ding/core/src/icons/BellOutline';

const showNotificationsUserToast = (title, props, promiseFn) => showPromiseToast(
  error => (
    <IconText
      title={
        renderFunctionalChildren(title, {error})
      }
      icon={(
        <BellOutlineIcon size='medium' />
      )}
    />
  ),
  props,
  promiseFn,
);

export default showNotificationsUserToast;
