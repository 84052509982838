import * as R from 'ramda';
import {GraphQLFragment} from './gql';

export const pickDataAttribute = R.when(
  R.allPass([
    R.is(Object),
    R.has('data'),
    R.propSatisfies(
      R.either(
        R.isEmpty,
        R.isNil,
      ),
      'errors',
    ),
  ]),
  R.prop('data'),
);

export const findOperationName = (() => {
  const pickASTOperationName = operationType => R.compose(
    R.find(
      R.propEq('operation', operationType),
    ),
    R.propOr([], 'definitions'),
  );

  return operationType => (document) => {
    if (document instanceof GraphQLFragment)
      return document.operationName;

    return pickASTOperationName(operationType)(document)?.name?.value || null;
  };
})();

/**
 * Picks name and operation name from query
 *
 * @param {String}    operationType
 * @param {Document}  document Document parsed from graphql-tag
 */
export const pickDocumentRequestData = (operationType, document) => {
  if (!document)
    return null;

  if (document instanceof GraphQLFragment)
    return document;

  const currentOperation = findOperationName(operationType)(document);
  if (!currentOperation)
    return null;

  return {
    query: document.loc.source.body,
    operationName: currentOperation,
  };
};
