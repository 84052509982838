/**
 * Compares two rects, faster than ramda. Used
 * in scrolll events
 *
 * @param {Rect} a
 * @param {Rect} b
 */
const compareDimensions = (a, b) => (
  a.width === b.width && a.height === b.height
);

export default compareDimensions;
