import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import BellOutlineIcon from '@ding/core/src/icons/BellOutline';
import {IconText} from '@ding/core/src/components/Predefined';
import {PrimaryHeaderHolder} from '@ding/controls/src/ResponsiveModalToggle/Modals/DesktopPopup';

const AuthModalPrimaryHeader = ({small}) => {
  const t = useTranslate();

  return (
    <PrimaryHeaderHolder padding={small ? 'small' : 'big'}>
      <IconText
        icon={
          <BellOutlineIcon size='large' />
        }
        title={
          t('website.auth.modals.header_cta')
        }
      />
    </PrimaryHeaderHolder>
  );
};

AuthModalPrimaryHeader.displayName = 'AuthModalPrimaryHeader';

export default React.memo(AuthModalPrimaryHeader);
