import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'Settings',
  {
    viewBox: '0 0 20 20',
  },
  <path
    style={{
      fillRule: 'evenodd',
    }}
    d='M19.29,7.14H18.08a8.58,8.58,0,0,0-.35-.83l.86-.86a.71.71,0,0,0,0-1h0l-3-3a.71.71,0,0,0-1,0h0l-.86.86a8.58,8.58,0,0,0-.83-.35V.71A.72.72,0,0,0,12.14,0H7.86a.71.71,0,0,0-.72.71V1.92a8.58,8.58,0,0,0-.83.35l-.86-.86a.71.71,0,0,0-1,0h0l-3,3a.71.71,0,0,0,0,1h0l.86.86a8.58,8.58,0,0,0-.35.83H.71A.71.71,0,0,0,0,7.86v4.28a.72.72,0,0,0,.71.72H1.92a8.58,8.58,0,0,0,.35.83l-.86.86a.71.71,0,0,0,0,1h0l3,3a.71.71,0,0,0,1,0h0l.86-.86a8.58,8.58,0,0,0,.83.35v1.21a.71.71,0,0,0,.72.71h4.28a.72.72,0,0,0,.72-.71V18.08a8.58,8.58,0,0,0,.83-.35l.86.86a.71.71,0,0,0,1,0h0l3-3a.71.71,0,0,0,0-1h0l-.86-.86a8.58,8.58,0,0,0,.35-.83h1.21a.72.72,0,0,0,.71-.72V7.86A.71.71,0,0,0,19.29,7.14Zm-.72,4.29h-1a.7.7,0,0,0-.68.52,7.08,7.08,0,0,1-.64,1.53.74.74,0,0,0,.12.86l.72.71-2,2-.72-.71a.7.7,0,0,0-.85-.12,7.12,7.12,0,0,1-1.54.63.72.72,0,0,0-.52.69v1H8.57v-1a.7.7,0,0,0-.52-.68,7.08,7.08,0,0,1-1.53-.64.74.74,0,0,0-.86.12L5,17.07l-2-2,.71-.72a.7.7,0,0,0,.12-.85,7.12,7.12,0,0,1-.63-1.54.72.72,0,0,0-.68-.51h-1V8.57h1a.7.7,0,0,0,.68-.52,7.26,7.26,0,0,1,.63-1.53.73.73,0,0,0-.11-.86L2.93,5l2-2,.71.71a.71.71,0,0,0,.86.12,7,7,0,0,1,1.53-.63.72.72,0,0,0,.52-.68v-1h2.86v1a.7.7,0,0,0,.52.68,7.26,7.26,0,0,1,1.53.63.73.73,0,0,0,.86-.11l.71-.72,2,2-.71.71a.71.71,0,0,0-.12.86,7,7,0,0,1,.63,1.53.72.72,0,0,0,.69.52h1v2.86ZM10,5a5,5,0,1,0,5,5,5,5,0,0,0-5-5Zm0,8.57A3.57,3.57,0,1,1,13.57,10h0A3.57,3.57,0,0,1,10,13.57Z'
  />,
);
