import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  DURATION_SCHEMA,
  ID_SCHEMA,
} from '@adretail/schemas';

import * as CALENDAR_TYPES from '../calendarTypes';

export const CALENDAR_TYPES_SCHEMA = PropTypes.oneOf(
  R.values(CALENDAR_TYPES),
);

export const CALENDAR_DAY_SCHEMA = PropTypes.shape(
  {
    id: ID_SCHEMA.isRequired,
    kind: CALENDAR_TYPES_SCHEMA.isRequired,
    duration: DURATION_SCHEMA,
    description: PropTypes.string,
  },
);

export const CALENDAR_WORKDAY_SCHEMA = PropTypes.shape(
  {
    date: PropTypes.string,
    dayIndex: PropTypes.number,
    closed: PropTypes.bool,
    begin: PropTypes.string,
    end: PropTypes.string,
    calendar: CALENDAR_DAY_SCHEMA,
  },
);

export const CALENDAR_WORKDAYS_SCHEMA = PropTypes.arrayOf(CALENDAR_WORKDAY_SCHEMA);
