import ssr from '@adretail/basic-helpers/src/base/ssr';

import {DEFAULT_DING_API_VARIABLE} from '../constants/globals';

const useWindowAPI = (variable = DEFAULT_DING_API_VARIABLE) => (
  ssr
    ? null
    : window[variable]
);

export default useWindowAPI;
