import PropTypes from 'prop-types';
import styled from '@adretail/fast-stylesheet/src/react/styled';

const CornerItemHolder = styled.div(
  {
    base: {
      position: ['absolute', '!important'],
      zIndex: 9,
    },

    'tiny-spacing': {
      left: 10,
      right: 10,
      top: 10,
    },

    'small-spacing': {
      left: 15,
      right: 15,
      top: 15,
    },

    'big-spacing': {
      left: 28,
      right: 28,
      top: 28,
    },

    rightSpacing: {
      left: 'initial',
    },

    leftSpacing: {
      right: 'initial',
    },
  },
  {
    index: 4,
    omitProps: ['spacing', 'left'],
    classSelector: (classes, {spacing, left}) => [
      classes[`${spacing}-spacing`],
      classes[left ? 'leftSpacing' : 'rightSpacing'],
    ],
  },
);

CornerItemHolder.propTypes = {
  left: PropTypes.bool,
  spacing: PropTypes.oneOf([
    'tiny',
    'small',
    'big',
  ]),
};

CornerItemHolder.defaultProps = {
  spacing: 'small',
};

export default CornerItemHolder;
