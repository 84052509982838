export default {
  navbar: {
    page: 'Strona',
    one_of: '%{} / %{}',
  },

  embed: {
    archived: 'Gazetka wygasła. Kliknij, aby zobaczyć aktualne gazetki',
  },

  toasts: {
    redirect_inactive_contractor: 'Gazetka nie istnieje lub skończył się jej termin ważności.',
    link_copied: 'Link do gazetki został skopiowany',
  },

  pages: {
    pages_list: 'Lista stron w gazetce',
  },

  info: {
    worth_to_see: 'Polecane',
    selected_for_you: 'Wybrane dla Ciebie',
    see_worth_to_see_leaflets: 'Zobacz polecane gazetki',
    recommended_leaflets: 'Polecane gazetki',
    about_product: 'O produkcie',
  },

  modals: {
    interactive_leaflets: {
      header: 'Nasze gazetki są interaktywne',
      go_to_shop: 'Przejdź do sklepu',
      product_info: 'Informacje o produkcie',
      add_to_shopping_list: 'Dodaj produkt do listy zakupowej',
      remove_from_shopping_list: 'Usuń produkt z listy zakupowej',

      desktop: {
        click_on_area: '<strong>Kliknij</strong> w dowolny obszar gazetki, aby zobaczyć <strong>dostępne opcje:</strong>',
        press_mouse: '<strong>Przytrzymaj</strong> lewy przycisk myszy na produkcie, aby od razu dodać go do <strong>listy zakupowej.</strong>',
      },

      mobile: {
        click_on_area: '<strong>Tapnij</strong> w dowolny obszar gazetki, aby zobaczyć <strong>dostępne opcje:</strong>',
        press_mouse: '<strong>Przytrzymaj</strong> dłużej produkt, aby od razu dodać go do <strong>listy zakupowej.</strong>',
      },
    },
  },

  section: {
    header: 'Gazetka %{}',
    description: 'W aktualnej gazetce sieci %{} w promocji znajdują się m.in. takie produkty jak:',
    rotated_header: [
      'Nowa gazetka %{contractorName} obowiązująca od %{begin} do %{end} prezentuje promocje na takie produkty:',
      'Gazetka promocyjna %{contractorName} obowiązuje od %{begin} i do %{end}. Nie przegap najlepszych promocji w sklepach %{contractorName} na:',
      'Promocje w sklepach %{contractorName} obowiązują od %{begin} do %{end}. Sprawdź gazetkę promocyjną %{contractorName} i upoluj najlepsze promocje na:',
      'Nowe oferty w %{contractorName}. Sprawdź atrakcyjne ceny w gazetce promocyjnej, która obowiązuje od %{begin} do %{end}. Zobacz promocje na:',
      'Zobacz najnowsze oferty w gazetce promocyjnej %{contractorName}. Gazetka ważna od %{begin} do %{end}. Nie przegap najlepszych promocji:',
      'Szukasz produktu w atrakcyjnej cenie? Sprawdź najnowszą gazetkę sieci %{contractorName}, która jest ważna od %{begin} do %{end}. Znajdziesz promocje na takie produkty jak:',
      'Sprawdź gazetkę %{contractorName}! Promocje w sklepach sieci są ważne od %{begin} do %{end}. W atrakcyjnej cenie dostaniesz takie produkty:',
      'Sprawdź jakie promocyjne produkty kupisz w sklepie %{contractorName}! Skorzystaj z rabatów od %{begin} do %{end} na produkty:',
      'Zobacz gazetkę %{contractorName}, promocje obowiązują od %{begin} do %{end}. W dobrej cenie kupisz:',
      'Nie przegap promocji w %{contractorName}. Gazetka %{contractorName} obowiązuje od %{begin} do %{end}, sprawdź produkty w promocji:',
      'Skorzystaj z promocji w %{contractorName} od %{begin} do %{end}. W atrakcyjnych cenach znajdziesz takie produkty, jak:',
      'Sprawdź ofertę handlową %{contractorName}, która obowiązuje od %{begin} do %{end}. W gazetce promocyjnej znajdziesz promocje na:',
      'Rabaty w gazetce promocyjnej %{contractorName}. Promocja obowiązuje od %{begin} do %{end}. W atrakcyjnej cenie dostaniesz:',
      'Sprawdź rabaty %{contractorName} w gazetce promocyjnej. Oferta obowiązuje od %{begin} do %{end}. Promocja obejmuje:',
      'Nie przegap rabatów w %{contractorName}. Całą ofertę zobaczysz w gazetce promocyjnej od %{begin} do %{end}. Warto zwrócić uwagę na:',
      'Nie przegap promocji w %{contractorName}. Wspaniałe rabaty obejrzysz w gazetce promocyjnej od %{begin} do %{end}. Warto przyjrzeć się tym produktom:',
      'Skorzystaj z rabatów sieci %{contractorName}. Gazetka promocyjna jest ważna od %{begin} do %{end}. Nie przegap tych atrakcyjnych produktów:',
      'Atrakcyjne ceny w %{contractorName}. Wszystkie rabaty obejrzysz w gazetce promocyjnej od %{begin} do %{end}. Warto sprawdzić te tanie produkty:',
      'Wiesz jakie promocje znajdziesz w %{contractorName}? Zobacz gazetkę promocyjną od %{begin} do %{end}. W niskich cenach dostaniesz:',
      'Wiesz jakie rabaty są teraz w %{contractorName}? Sprawdź gazetkę promocyjną od %{begin} do %{end}. Nie przegap promocji na:',
      'Wiesz, jaka jest oferta w %{contractorName}? Obejrzyj gazetkę promocyjną od %{begin} do %{end}. Nie przegap rabatów na:',
      'Nie strać okazji w %{contractorName}! Niezwykłe rabaty w gazetce promocyjnej od %{begin} do %{end}. Promocje na takie produkt jak:',
      'Niezwykłe promocje w gazetce promocyjnej %{contractorName}. Oferta ważna od %{begin} do %{end}. Znajdziesz rabaty na produkty takie jak:',
      'Zyskuj z gazetką promocyjną sklepów %{contractorName}. Oferta ważna od %{begin} do %{end}. Nie przegap promocji na:',
      'Oszczędzaj w sklepach %{contractorName}. Zobacz gazetkę promocyjną od %{begin} do %{end}. Tanio dostaniesz:',
      'Przejrzyj gazetkę promocyjną %{contractorName}. Atrakcyjne oferty dostaniesz od %{begin} do %{end}. Zaoszczędzisz na:',
      'Szukasz promocji w %{contractorName}? Zobacz gazetkę promocyjną od %{begin} do %{end}. Skorzystaj z rabatów na:',
      'Kupując w %{contractorName} oszczędzasz! Zobacz aktualną ofertę w gazetce promocyjnej od %{begin} do %{end}. W niskich cenach kupisz:',
      'Zobacz promocję w %{contractorName}. W gazetce promocyjnej od %{begin} do %{end} są świetne rabaty! Sprawdź jak tanio dostaniesz:',
      '%{contractorName} ma super okazje! Zobacz gazetkę promocyjną od %{begin} do %{end}. Upoluj rabaty na:',
      '%{contractorName} ma wspaniałe rabaty! Sprawdź gazetkę promocyjną od %{begin} do %{end}. Taniej kupisz:',
      'Świeże promocje w sieci %{contractorName}. Zobacz gazetkę promocyjną od %{begin} do %{end}. Zaoszczędź na:',
      'Zaoszczędź kupując w %{contractorName}. Obejrzyj gazetkę promocyjną od %{begin} do %{end}. W niskich cenach dostaniesz:',
      'Zrób zakupy w %{contractorName} taniej! Sprawdź promocji w gazetce na takie produkty:',
      'Oszczędzaj na zakupach w %{contractorName} z gazetką promocyjną i kup te produkty taniej:',
      'W gazetce %{contractorName} obowiązują promocje od %{begin} do %{end} na taki produkty:',
      'Nie przegap super promocje w %{contractorName}! Tylko od %{begin} do %{end} kupisz te produkty w super cenie:',
    ],
    page_info: {
      info: 'informacje dla strony %{page} %{nextPage}',
      additional_page: ' i %{}',
    },
  },

  titles: {
    skip_ad: 'Pomiń reklamę',
    interactive_module_layer: 'Warstwa produktowa',
    contractor_rating: 'Ocena sieci:',
    rating_suffix: '%{} (%{} głosów)',
    loading_leaflet: 'Wczytywanie gazetki...',
    next_leaflet: 'Następna gazetka',

    active: 'Aktualna',
    inactive: 'Nieaktualna',

    enter_product_page: 'Odwiedź witrynę produktu',
  },

  placeholder: {
    search_box: 'Szukaj sieci handlowych, gazetek',
    loading_next_paper: 'Wczytuję gazetkę sieci %{}...',
  },

  toolbar: {
    duration: {
      label: 'Oferta ważna',
      begin: ' od ',
      end: ' do ',
    },

    module: {
      redirect: 'Przejdź do sklepu',
      product: 'Więcej',
      video: 'Odtwórz',
      shopping_list: {
        add_short: 'Dodaj do listy',
        add: 'Dodaj do listy zakupowej',
        remove: 'Usuń z listy zakupowej',
      },
      copy: 'Kopiuj',
    },

    zoom: {
      increase: 'przybliż',
      decrease: 'oddal',
    },

    menu: {
      download_pdf: 'Pobierz PDF',
      pages_list: 'Lista stron w gazetce',
      enable_notifications: 'Włącz powiadomienia',
      disable_notifications: 'Wyłącz powiadomienia',
      nearest_stores: 'Najbliższe sklepy',
      more_leaflets: 'Więcej gazetek %{}',
      fullscreen: 'Tryb pełnoekranowy',
      share: 'Udostępnij',
      page_info: 'Informacje o stronie',
      exit_to_grid: 'Wróć',
    },
  },

  last_page: {
    header: 'To już ostatnia strona tej gazetki',
    info: {
      desktop: 'Za chwilę przeniesiemy Cię na następną gazetkę',
      mobile: 'Przejdź do następnej gazetki przesuwając ekran w lewo',
    },
    cancel: 'Zostań na tej gazetce',
    duration_prefix: 'Ważna',
    more_leaflets: 'Inne gazetki z tej kategorii',
    next_leaflet: 'Następna gazetka',
    notifications: 'Otrzymuj powiadomienia o nowych gazetkach %{}',
  },
};
