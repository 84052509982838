import React from 'react';
import PropTypes from 'prop-types';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {Header} from '@ding/core/src/components/Predefined';

const SectionCenteredContent = styled(
  ({title, children, ...props}) => (
    <div {...props}>
      {title && (
        <Header.H2>
          {title}
        </Header.H2>
      )}
      {children}
    </div>
  ),
  {
    margin: '0 auto',
    padding: [0, 10],
    maxWidth: 850,
  },
);

SectionCenteredContent.displayName = 'SectionCenteredContent';

SectionCenteredContent.propTypes = {
  title: PropTypes.node,
};

export default SectionCenteredContent;
