import * as R from 'ramda';

const safePickEnvFields = R.pick(
  [
    'client',
    'shared',
  ],
);

export default safePickEnvFields;
