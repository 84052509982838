import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'FlagUkrainian',
  {
    viewBox: '0 0 14 14',
  },
  <>
    <path fill='#338AF3' d='M0 0h14v7H0z' />
    <path fill='#FFDA44' d='M0 7h14v7H0z' />
  </>,
);
