import React from 'react';

import {ID_SCHEMA} from '@adretail/schemas';
import renderFunctionalChildren from '@adretail/basic-helpers/src/ui/renderFunctionalChildren';

import {useLatestLeafletQuery} from '@ding/api/src/gql/predefined/LatestLeafletQuery';
import LeafletLink from '../LeafletLink';

const LatestLeafletLink = ({children, contractorId, ...props}) => {
  const {data: latestLeaflet} = useLatestLeafletQuery(
    {
      variables: contractorId && {
        filters: {
          contractorIds: [contractorId],
        },
      },
    },
  );

  if (!latestLeaflet)
    return null;

  return (
    <LeafletLink
      {...props}
      leaflet={latestLeaflet}
    >
      {renderFunctionalChildren(children, [latestLeaflet])}
    </LeafletLink>
  );
};

LatestLeafletLink.displayName = 'LatestLeafletLink';

LatestLeafletLink.propTypes = {
  contractorId: ID_SCHEMA,
};

export default LatestLeafletLink;
