import React from 'react';
import * as R from 'ramda';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {ssr} from '@adretail/fast-stylesheet/src/utils';

import cookieEnv from '@ding/constants/src/envCookieConfig';

import {CookieToggleableExpandedAlert} from '@ding/core/src/components/Parts/ExpandedAlert';
import {Margin, Text} from '@ding/core/src/components/Predefined';
import {LANGUAGES, isDefaultLanguage} from '@ding/i18n/src/languages';

import {LanguageSwitchLink} from '../../Links';
import {useRouterSession} from '../../SSRRouter';

/**
 * @description Get user preferred language which server can handle. Works like express req.languages() method but client-side
 * @param {string[]} languages user preferred languages
 * @returns {string | null} supported language
 */
const getPreferredLanguage = (languages) => {
  const supportedLanguages = R.values(LANGUAGES);
  for (let i = 0; i < languages.length; i++) {
    const lang = languages[i];
    // TODO: This will broke if languages are in format with _ (ie. "en_US" instead "en"), requires fix also in server-side req.languages call
    if (supportedLanguages.includes(lang))
      return lang;
  }

  return null;
};

const LanguageHeaderAlert = () => {
  const t = useTranslate();
  const {value: {preferredLanguage: preferredLanguageSsr}} = useRouterSession();

  const preferredLanguage = ssr ? preferredLanguageSsr : getPreferredLanguage(navigator.languages);
  if (!preferredLanguage || APP_LANGUAGE === preferredLanguage
    || isDefaultLanguage(preferredLanguage))
    return null;

  return (
    <CookieToggleableExpandedAlert
      toggleableCookieConfig={cookieEnv.dismissedLanguageAlert}
    >
      {onToggle => (
        <>
          <Text>
            {t('website.header.change_language.text')}
          </Text>
          <LanguageSwitchLink onClick={onToggle}>
            <Margin left={1}>
              <Text underline weight={900}>
                {t('website.header.change_language.link')}
              </Text>
            </Margin>

          </LanguageSwitchLink>
        </>
      )}
    </CookieToggleableExpandedAlert>
  );
};

LanguageHeaderAlert.displayName = 'LanguageHeaderAlert';

export default React.memo(LanguageHeaderAlert);
