import styled from '@adretail/fast-stylesheet/src/react/styled';

import {WHITE} from '@ding/constants/src/colorSchema';

import flexCenter from '../../styles/flexCenter';
import fullLayer from '../../styles/fullLayer';

const Layer = styled.div(
  {
    extend: [flexCenter, fullLayer],
  },
);

Layer.displayName = 'Layer';

export const DarkLayer = styled(
  Layer,
  {
    extend: flexCenter,

    background: 'rgba(0, 0, 0, 0.8)',
    color: WHITE,
    fontWeight: 700,
    lineHeight: '22px',
    textAlign: 'center',
    borderRadius: 4,
  },
);

export const GrayScaleLayer = styled(
  Layer,
  {
    extend: flexCenter,

    background: 'black',
    mixBlendMode: 'saturation',
    pointerEvents: 'none',
    borderRadius: 4,
  },
);

Layer.GrayScale = GrayScaleLayer;
Layer.Dark = DarkLayer;

export default Layer;
