import createLinkComponent from '../../utils/createLinkComponent';
import {optionalLinkBlock, optionalLinkSegment} from '../../utils/optionalSegment';

const optionalHashSegment = optionalLinkBlock('#');
export const createAccountSettingsPath = (path, hash) => `${ACCOUNT_SETTINGS_SCHEMA}${optionalLinkSegment(path)}${optionalHashSegment(hash)}`;

export const ACCOUNT_SETTINGS_SCHEMA = '/ustawienia-konta';
export const UNAUTHORIZED_DELETE_ACCOUNT_HASH = 'usun-konto';

export const ACCOUNT_SETTINGS_DELETE_ACCOUNT_SCHEMA = createAccountSettingsPath(
  null,
  UNAUTHORIZED_DELETE_ACCOUNT_HASH,
);

export const UNAUTHORIZED_DELETE_ACCOUNT_SCHEMA = createAccountSettingsPath(
  UNAUTHORIZED_DELETE_ACCOUNT_HASH,
  null,
);

export default createLinkComponent(
  null,
  ACCOUNT_SETTINGS_SCHEMA,
  'website.account_settings.titles.settings',
);
