import React from 'react';
import PropTypes from 'prop-types';

import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';
import styled from '@adretail/fast-stylesheet/src/react/styled';

import MobileFullscreenPopup from '@ding/controls/src/ResponsiveModalToggle/Modals/MobileFullscreenPopup';
import {
  PageSearchGroupedLists,
  ExpandedSearchInput,
} from '@ding/search/src/components';

import LoadingSpinner from '@ding/core/src/components/Parts/LoadingSpinner';
import {Debounce} from '@adretail/basic-decorators/src/base/balancer/debounce';
import TitledSidebarHeader from '@ding/controls/src/TitledSidebar/TitledSidebarHeader';

import {PageSearchQuery} from '../gql/pageSearchQuery';
import WorthToSeeSearchGrid from './WorthToSeeSearchGrid';

const MobileExpandedSearch = styled(
  ExpandedSearchInput,
  {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    height: '100%',
    minHeight: '100%',
  },
  {
    classPropName: 'wrapperClassName',
  },
);

const ModalAutocompleteLoadingSpinner = styled(
  LoadingSpinner,
  {
    maxHeight: 250,
  },
);

const SearchModal = ({
  value, l, onUnmount,
  noSearchParamsRenderFn, headerRenderFn,
}) => {
  const renderContent = () => {
    if (value) {
      return (
        <Debounce
          updateKey={value}
          delay={200}
          loadingComponent={ModalAutocompleteLoadingSpinner}
        >
          {() => (
            <PageSearchQuery
              variables={{phrase: value}}
              loadingComponent={ModalAutocompleteLoadingSpinner}
            >
              {searchData => (
                <PageSearchGroupedLists
                  phrase={value}
                  searchData={searchData}
                  onSelectItem={l.onResetInputs}
                />
              )}
            </PageSearchQuery>
          )}
        </Debounce>
      );
    }

    return noSearchParamsRenderFn();
  };

  return (
    <MobileFullscreenPopup
      noTitleStyles
      contentProps={{
        styleChildrenContainer: {
          padding: '0px 35px 0px 25px',
        },
        header: (
          <>
            <TitledSidebarHeader
              type='light'
              noTitleStyles
              onClose={(data, status) => onUnmount(
                {
                // used for tracking
                  ...data,
                  source: 'button',
                },
                status,
              )}
            >
              <MobileExpandedSearch
                {...l.input()}
                flat
              />
            </TitledSidebarHeader>
            {headerRenderFn?.(value)}
          </>
        ),
      }}
      onUnmount={onUnmount}
    >
      {renderContent}
    </MobileFullscreenPopup>
  );
};

SearchModal.displayName = 'SearchModal';

SearchModal.propTypes = {
  noSearchParamsRenderFn: PropTypes.func,
  headerRenderFn: PropTypes.func,
};

SearchModal.defaultProps = {
  noSearchParamsRenderFn: () => (
    <WorthToSeeSearchGrid />
  ),
};

export default linkedInputs(null, true)(SearchModal);
