import React from 'react';
import PropTypes from 'prop-types';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {
  Text,
  Margin,
} from '../Predefined';

const EmptyTitlePlaceholder = ({translatePath}) => {
  const t = useTranslate();

  return (
    <Margin
      top={5}
      bottom={5}
      block
    >
      <Text.Center block>
        {t(translatePath)}
      </Text.Center>
    </Margin>
  );
};

EmptyTitlePlaceholder.displayName = 'EmptyTitlePlaceholder';

EmptyTitlePlaceholder.propTypes = {
  translatePath: PropTypes.string.isRequired,
};

export default EmptyTitlePlaceholder;
