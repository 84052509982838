import React from 'react';
import * as R from 'ramda';

import isBetween from '@adretail/basic-helpers/src/base/isBetween';
import safeArray from '@adretail/basic-helpers/src/base/safeArray';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import validateToFlags, {allFlagsPass} from '@ding/core/src/helpers/validateToFlags';

import {createMessageValidator} from '../ValidatedInput';
import PasswordInput from './PasswordInput';

export const validatePasswordToFlags = validateToFlags(
  {
    special: R.test(/[\d!@#$%^&*()+-]+/),
    letter: R.test(/[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]+/),
    length: R.compose(
      isBetween({
        min: 5,
        max: 32,
      }),
      R.length,
    ),
  },
);

export const defaultPasswordTester = R.compose(
  allFlagsPass,
  validatePasswordToFlags,
);

const ValidatedPasswordInput = ({validatorFn, ...props}) => {
  const t = useTranslate();

  return (
    <PasswordInput
      validatorFn={[
        createMessageValidator(
          defaultPasswordTester,
          t('website.errors.validators.password'),
        ),
        ...safeArray(validatorFn),
      ]}
      {...props}
    />
  );
};

ValidatedPasswordInput.displayName = 'ValidatedPasswordInput';

export default ValidatedPasswordInput;
