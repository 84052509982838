import React from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';
import * as R from 'ramda';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';

import renderFunctionalChildren from '@adretail/basic-helpers/src/ui/renderFunctionalChildren';
import appendSearchParams from '@adretail/basic-helpers/src/url/appendSearchParams';
import decodeUrl from '@adretail/basic-helpers/src/url/decodeUrl';

import useMountedRef from '@adretail/basic-hooks/src/base/useMountedRef';
import useReactRouter from '@ding/core/src/hooks/useReactRouter';

import {getRouterLocationPath} from '@ding/core/src/helpers';
import {dropPageInURL} from '@ding/control-groups/src/Pagination/utils';

import {UndecoratedLink} from '@ding/core/src/components/Predefined';
import SortSelectButton from '../SortSelectButton';

const GETLinkItem = ({item, searchParamName, children}) => (
  <Route
    render={
      ({location}) => (
        <UndecoratedLink
          to={
            appendSearchParams(
              // reset pagination if present
              dropPageInURL(
                getRouterLocationPath(location),
              ),
              {
                [searchParamName]: item.id,
              },
            )
          }
        >
          {children}
        </UndecoratedLink>
      )
    }
  />
);

/**
 * Loads GET param from url and assignts it as value to input
 *
 * @export
 */
const GETSelectButton = ({
  children,
  itemProps,
  searchParamName,
  title,
  initialData,
  selectComponent: SelectComponent,
  ...props
}) => {
  const mounted = useMountedRef();
  const {location: {search}} = useReactRouter();
  let urlValue = null;
  try {
    urlValue = decodeUrl(search)[searchParamName];
  } catch (e) {
    if (!(e instanceof URIError)) {
      console.error(e);
    }
  }
  if (initialData && !urlValue && !mounted.current)
    urlValue = initialData;

  const renderContent = value => (
    <SelectComponent
      {...props}
      value={value}
      rootTitle={title}
      title={
        renderFunctionalChildren(title, [urlValue])
      }
      itemProps={{
        ...itemProps,
        linkComponent: GETLinkItem,
        linkProps: {
          searchParamName,
        },
      }}
    />
  );

  if (!R.is(Function, children))
    return renderContent(urlValue);

  if (!urlValue)
    return renderContent(null);

  return children(urlValue, renderContent);
};

GETSelectButton.displayName = 'GETSelectButton';

GETSelectButton.propTypes = {
  searchParamName: PropTypes.string.isRequired,
  selectComponent: REACT_COMPONENT_CLASS_SCHEMA,
};

GETSelectButton.defaultProps = {
  selectComponent: SortSelectButton,
};

export default GETSelectButton;
