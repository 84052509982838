import createLinkComponent from '../../utils/createLinkComponent';
import {
  optionalLinkSegment,
  optionalLinkSearch,
} from '../../utils/optionalSegment';

export const FRIENDLY_TABS_NAMES = {
  ALL: 'wszystkie',
  CONTRACTORS: 'sklepy',
  LEAFLETS: 'gazetki',
  PRODUCTS: 'produkty',
  LEAFLETS_PAGES: 'produkty-w-gazetce',
  ARTICLES: 'newsy',
};

export const SEARCH_URL_SCHEMA = '/szukaj/:tab?/:page?';

//DEV: After developing ContractorsChips, it has been decided that they look ugly.
// For now, I am leaving the source code.
// When they will make a new design for selecting contractors in the search, a lot of it is done yet
// Here is function to serialize contractors to url
export const serializeTabFilters = {
  toGETParams: contractors => (
    contractors && contractors.length > 0
      ? {contractors}
      : {}
  ),

  fromGETParams: contractors => contractors,
};

export const genSearchUrl = ({tab, page, phrase, filters} = {}) => `/szukaj${optionalLinkSegment(tab)}${optionalLinkSegment(page)}${optionalLinkSearch({q: phrase, ...serializeTabFilters.toGETParams(filters)})}`;

export default createLinkComponent(
  [
    'tab',
    'page',
    'phrase',
    'filters',
  ],
  (_, props) => genSearchUrl(props),
);
