const FORCE_REDIRECT_URLS = {
  '/katalog': '/gazetki-promocyjne',
  '/oferta': '/oferty',
  '/oferta/czekolada-milka,95163': '/oferta/czekolada-milka,108998',
  '/oferta/czekolada-milka,97584': '/oferta/czekolada-milka,108998',
  '/oferta/czekolada-milka,98898': '/oferta/czekolada-milka,108998',
  '/oferta/czekolada-milka,98994': '/oferta/czekolada-milka,108998',
  '/promocje/czekolaa,49739': '/promocje/czekolada,31', // SUP-1229
  '/promocje/pomidor,701': '/promocje/pomidory,301',
  '/promocje/pomoc-ukrainie,155929': '/promocje/pomoc-ukrainie,155911',
  '/blog/artykuly/koronawirus-a-bezpieczenstwo-najnowsze-informacje-i-zmiany-w-zyciu-obywateli,620': '/blog/artykuly/koronawirus-a-bezpieczenstwo-najnowsze-informacje-i-zmiany-w-zyciu-obywateli,652',

  // Interia taniomam
  '/blog/artykuly/biedronka-oferuje-jednodniowe-promocje-niesamowita-oferta,6679': 'https://taniomam.interia.pl/nowinki-handlowe/aktualnosci/news-biedronka-oferuje-jednodniowe-promocje-niesamowita-oferta,nId,7106612',
  '/blog/artykuly/totalnie-niskie-ceny-na-zabawki-dla-kotow-w-tedi,4873': 'https://taniomam.interia.pl/hitowe-promocje/news-totalnie-niskie-ceny-na-zabawki-dla-kotow-w-tedi,nId,7106578',
  '/blog/artykuly/lidl-wprowadza-jesienne-hity-cenowe-do-sklepow-nowa-gazetka,6678': 'https://taniomam.interia.pl/hitowe-promocje/news-lidl-wprowadza-jesienne-hity-cenowe-do-sklepow-nowa-gazetka,nId,7106569',
  '/blog/artykuly/biedronka-nie-zwalnia-tempa-i-znowu-rozdaje-gratisowe-produkty-co-za-wybor,6613': 'https://taniomam.interia.pl/gratisowe-produkty/biedronka/news-biedronka-nie-zwalnia-tempa-i-znowu-rozdaje-gratisowe-produk,nId,7099166',
  '/blog/artykuly/kurtki-i-plaszcze-w-promocji-od-lidla-rabat-jest-ogromny,6671': 'https://taniomam.interia.pl/hitowe-promocje/news-kurtki-i-plaszcze-w-promocji-od-lidla-rabat-jest-ogromny,nId,7099243',
  '/blog/artykuly/lidl-oferuje-produkty-z-kolekcji-netflix-dostaniesz-tez-duzy-rabat,5605': 'https://taniomam.interia.pl/hitowe-promocje/news-lidl-obniza-cene-hitowego-odkurzacza-rabat-szokuje-nowa-gaze,nId,7099231',
  '/blog/artykuly/lidl-oferuje-produkty-za-polowe-ceny-na-czym-zaoszczedzisz,6662': 'https://taniomam.interia.pl/hitowe-promocje/news-lidl-oferuje-produkty-za-polowe-ceny-na-czym-zaoszczedzisz,nId,7099219',
  '/blog/artykuly/kik-stylowa-sukienka-za-cene-kubka-kawy,6527': 'https://taniomam.interia.pl/hitowe-promocje/news-kik-stylowa-sukienka-za-cene-kubka-kawy,nId,7099213',
  '/blog/artykuly/zabawny-i-cieply-sweter-z-kik-idealny-na-jesienne-wieczory,6616': 'https://taniomam.interia.pl/hitowe-promocje/news-zabawny-i-cieply-sweter-z-kik-idealny-na-jesienne-wieczory,nId,7099206',
  '/blog/artykuly/weekendowe-szalenstwo-z-rossmannem-darmowa-dostawa-kosmetykow-nyx,6676': 'https://taniomam.interia.pl/hitowe-promocje/news-weekendowe-szalenstwo-z-rossmannem-darmowa-dostawa-kosmetyko,nId,7099179',
  '/blog/artykuly/piwo-w-biedronce-10-10-gratis,6606': 'https://taniomam.interia.pl/gratisowe-produkty/biedronka/news-piwo-w-biedronce-10-10-gratis,nId,7099172',
  '/blog/artykuly/biedronka-home-oferuje-nowe-promocje-jaki-okazje-cenowe-czekaja,6674': 'https://taniomam.interia.pl/hitowe-promocje/biedronka/news-biedronka-home-oferuje-nowe-promocje-jaki-okazje-cenowe-czek,nId,7099051',
  '/blog/artykuly/dynia-przepisy-na-sprawdzone-dania-i-przetwory-co-zrobic-z-dyni,2693': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-dynia-przepisy-na-sprawdzone-dania-i-przetwory-co-zrobic-z-d,nId,7096904',
  '/blog/artykuly/filmy-swiateczne-romantyczne-bajki-obyczajowe-co-ogladac-w-swieta,2934': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-filmy-swiateczne-romantyczne-bajki-obyczajowe-co-ogladac-w-s,nId,7096899',
  '/blog/artykuly/gdzie-kupic-poduszke-ciazowa-poradnik-zakupowy,3977': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-dlaczego-potrzebujesz-poduszki-ciazowej-z-biedronki,nId,7096890',
  '/blog/artykuly/glog-owoc-kwia-wlasciwosci-i-przeciwwskazania,2610': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-glog-owoc-kwia-wlasciwosci-i-przeciwwskazania,nId,7096869',
  '/blog/artykuly/grzane-wino-i-piwo-czyli-grzaniec-nie-tylko-galicyjski,2921': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-grzaniec-krol-drinkow-na-jesienne-i-zimowe-wieczory,nId,7096855',
  '/blog/artykuly/grzybobranie-od-a-do-z-jak-sie-przygotowac-gdzie-pojechac-jakie-grzyby-zbierac,6472': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-grzybobranie-od-a-do-z-jak-sie-przygotowac-gdzie-pojechac-ja,nId,7096846',
  '/blog/artykuly/guacamole-smaczna-pasta-nie-tylko-do-pieczywa-przepis-na-quacamole,2860': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-guacamole-smaczna-pasta-nie-tylko-do-pieczywa-przepis-na-qua,nId,7096840',
  '/blog/artykuly/halloween-skad-wzielo-sie-to-swieto-data-tradycja-przepisy-na-udane-halloween,2435': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-halloween-skad-wzielo-sie-to-swieto-data-tradycja-przepisy-n,nId,7095019',
  '/blog/artykuly/hybryda-na-paznokciach-jak-sie-robi-paznokcie-hybrydowe,2603': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-hybryda-na-paznokciach-jak-sie-robi-paznokcie-hybrydowe,nId,7095016',
  '/blog/artykuly/jager-z-czym-go-pic-podroz-po-swiecie-wyrafinowanych-smakow,6067': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-jager-z-czym-go-pic-podroz-po-swiecie-wyrafinowanych-smakow,nId,7095012',
  '/blog/artykuly/jak-i-z-czym-jesc-awokado-przepisy-na-pyszne-dania,2830': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-awokado-pyszne-przepisy-wartosci-odzywcze,nId,7094998',
  '/blog/artykuly/jak-odkamienic-czajnik-odkamienianie-czajnika-krok-po-kroku,2452': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-jak-odkamienic-czajnik-odkamienianie-czajnika-krok-po-kroku,nId,7094991',
  '/blog/artykuly/lidl-obniza-cene-hitowego-odkurzacza-rabat-szokuje-nowa-gazetka,6666': 'https://taniomam.interia.pl/hitowe-promocje/news-lidl-obniza-cene-hitowego-odkurzacza-rabat-szokuje-nowa-gaze,nId,7099231',
  '/blog/artykuly/tort-dla-dziecka-zrob-to-sam-przepis-na-tort-dla-dziecka,2701': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-tort-dla-dziecka-zrob-to-sam-przepis-na-tort-dla-dziecka,nId,7094983',
  '/blog/artykuly/topinambur-wlasciwosci-smak-przepisy,2846': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-topinambur-wlasciwosci-smak-przepisy,nId,7094976',
  '/blog/artykuly/thermomix-praktyczny-gadzet-czy-chwilowa-moda-polacy-pokochali-przepisy-z-thermomixa,2429': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-czy-thermomix-to-praktyczne-narzedzie-czy-fanaberia-przepisy,nId,7094973',
  '/blog/artykuly/sprawdzony-przepis-na-chleb-jak-upiec-chleb-odpowiadamy,2488': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-sprawdzony-przepis-na-chleb-jak-upiec-chleb-odpowiadamy,nId,7094964',
  '/blog/artykuly/smoothie-top-5-przepisow-ktore-przypadna-ci-do-gustu,2658': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-smoothie-top-5-przepisow-ktore-przypadna-ci-do-gustu,nId,7094960',
  '/blog/artykuly/sezon-na-kurki-jak-odroznic-kurki-od-lisowki,4182': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-sezon-na-kurki-jak-odroznic-kurki-od-lisowki,nId,7094952',
  '/blog/artykuly/przybory-szkolne-zestaw-rzeczy-ktore-przydadza-sie-w-szkole,2522': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-wyprawka-szkolna-nie-zapomnij-tych-rzeczy,nId,7094926',
  '/blog/artykuly/przekaski-na-sylwestra-zorganizuj-niezapomniana-domowke,3241': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-przekaski-na-sylwestra-zorganizuj-niezapomniana-domowke,nId,7094916',
  '/blog/artykuly/potrawy-wielkanocne-pamietaj-o-wszystkim-co-wazne,673': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-oto-najwazniejsze-potrawy-na-wielkanoc-nie-zapomnij-o-nich,nId,7094911',
  '/blog/artykuly/porownanie-systemow-multisport-fitprofit-ok-system,448': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-multisport-fitprofit-ok-system-co-wybrac,nId,7094908',
  '/blog/artykuly/pokrzywa-zwyczajna-wlasciwosci-zastosowanie-i-przeciwwskazania,2720': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-pokrzywa-zwyczajna-wlasciwosci-zastosowanie-i-przeciwwskazan,nId,7094902',
  '/blog/artykuly/plecak-czy-tornister-dla-pierwszoklasisty-co-wybrac,2375': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-plecak-czy-tornister-dla-pierwszoklasisty-co-wybrac,nId,7094897',
  '/blog/artykuly/kapusta-z-grzybami-na-wigilie-tradycja-przepi,2433': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-kapusta-z-grzybami-na-wigilie-tradycja-przepis,nId,7094890',
  '/blog/artykuly/kamelia-orientalne-klimaty-w-naszym-ogrodzie,5527': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-kamelia-orientalne-klimaty-w-naszym-ogrodzie,nId,7094885',
  '/blog/artykuly/jaka-maka-do-pizzy-pierogow-i-do-nalesnikow-rozwiewamy-watpliwosci,2743': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-jaka-maka-do-pizzy-pierogow-i-do-nalesnikow-rozwiewamy-watpl,nId,7094879',
  '/blog/artykuly/orzezwiajaca-lemoniada-na-upal-produkty-przepisy-propozycje-podania,2408': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-orzezwiajaca-lemoniada-na-upal-produkty-przepisy-propozycje-,nId,7094874',
  '/blog/artykuly/noc-kupaly-pierwszy-dzien-lata-i-wielkie-przesilenie-na-ten-termin-trzeba-byc-przygotowanym,3953': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-noc-kupaly-pierwszy-dzien-lata-i-wielkie-przesilenie-na-ten-,nId,7094866',
  '/blog/artykuly/nawilzacz-powietrza-ewaporacyjny-czy-ultradzwiekowy-jaki-nawilzacz-powietrza-wybrac-ranking,2399': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-nawilzacz-powietrza-ewaporacyjny-czy-ultradzwiekowy-jaki-naw,nId,7094860',
  '/blog/artykuly/najlepszy-proszek-do-prania-poznaj-wyniki-rankingu,1223': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-najlepszy-proszek-do-prania-poznaj-wyniki-rankingu,nId,7094856',
  '/blog/artykuly/letnie-sukienki-material-fason-wzor-3-modne-sukienki-na-lato,2403': 'https://taniomam.interia.pl/ciekawostki-i-porady/news-letnie-sukienki-material-fason-wzor-3-modne-sukienki-na-lato,nId,7094823',
  '/blog/artykuly/jak-wywolac-zdjecia-w-rossmanie,547': 'https://taniomam.interia.pl/nowinki-handlowe/aktualnosci/news-jak-wywolac-zdjecia-w-rossmannie-jest-to-bardzo-proste,nId,7094745',
  '/blog/artykuly/jak-zalozyc-karte-moja-biedronka-przez-internet,4166': 'https://taniomam.interia.pl/nowinki-handlowe/aktualnosci/news-jak-zalozyc-karte-moja-biedronka-przez-internet,nId,7094733',
  '/blog/artykuly/pepco-gazetka-nowa-oferta-promocyjna-zaskakuje-co-za-wybor-produktow,6663': 'https://taniomam.interia.pl/hitowe-promocje/news-pepco-gazetka-nowa-oferta-promocyjna-zaskakuje-co-za-wybor-p,nId,7106574',
  '/blog/artykuly/karta-moja-biedronka-jak-zarejestrowac,579': 'https://taniomam.interia.pl/nowinki-handlowe/aktualnosci/news-karta-moja-biedronka-jak-zarejestrowac,nId,7094718',
  '/blog/artykuly/paczka-z-aliexpress-nie-doszla-sprawdz-co-zrobic,1202': 'https://taniomam.interia.pl/nowinki-handlowe/aktualnosci/news-nie-doszla-paczka-z-aliexpress-ten-sposob-rozwiaze-twoj-prob,nId,7094713',
  '/blog/artykuly/jesli-zgubimy-karte-moja-biedronka,5371': 'https://taniomam.interia.pl/nowinki-handlowe/aktualnosci/news-jesli-zgubimy-karte-moja-biedronka,nId,7094730',

};

export default FORCE_REDIRECT_URLS;
