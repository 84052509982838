import React from 'react';
import PropTypes from 'prop-types';

import {
  REACT_COMPONENT_CLASS_SCHEMA,
  PROPS_SCHEMA,
} from '@adretail/schemas';

import PlainUnorderedList from '../../Predefined/PlainUnorderedList';

/**
 * Generates list of components
 *
 * @see
 *  used in:
 *    Autocomplete
 *
 * @export
 */
const AbstractComponentsList = ({
  className,
  list,
  itemRenderFn,
  itemValuePropName,
  itemPropsMap,
  itemProps,
  itemComponent: Item,
  emptyListComponent: Empty,
  children,
}) => {
  if (!list || !list.length)
    return (
      Empty
        ? <Empty />
        : null
    );

  return (
    <PlainUnorderedList className={className}>
      {list.map(
        (item, index) => {
          const mergedItemProps = {
            key: item.key || item.id || item,
            ...itemProps,
            ...itemPropsMap && itemPropsMap(item, index),
          };

          if (itemRenderFn)
            return itemRenderFn(mergedItemProps, item, index);

          return (
            <Item
              {...{
                [itemValuePropName]: item,
              }}
              {...mergedItemProps}
            />
          );
        },
      )}
      {children}
    </PlainUnorderedList>
  );
};

AbstractComponentsList.displayName = 'AbstractComponentsList';

AbstractComponentsList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.any),

  itemPropsMap: PropTypes.func,
  itemProps: PROPS_SCHEMA,
  itemComponent: REACT_COMPONENT_CLASS_SCHEMA,
  itemRenderFn: PropTypes.func,
  itemValuePropName: PropTypes.string,

  emptyListComponent: REACT_COMPONENT_CLASS_SCHEMA,
};

AbstractComponentsList.defaultProps = {
  itemValuePropName: 'item',
};

export default AbstractComponentsList;
