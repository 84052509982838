import * as R from 'ramda';

/**
 * Generates pairs of [id-name] from checked value
 *
 * @example
 *  {12: {name: 'bb'}} => ['12-bb']
 */
const encodeCheckboxGETPairs = R.ifElse(
  R.is(Array),

  // [{id: '', name: ''}, ...]
  R.map(
    ({id, name}) => `${id}-${name}`,
  ),

  // {id: {name, id}, id2: {name, id}}
  R.compose(
    R.map(
      R.join('-'),
    ),
    R.toPairs,
    R.mapObjIndexed(
      R.prop('name'),
    ),
  ),
);

export default encodeCheckboxGETPairs;
