import crypto from 'crypto';
import React from 'react';
import sum from 'hash-sum';

import {getHOCDisplayName} from '@adretail/basic-helpers/src/getters/getDisplayName';
import {ssr} from '@adretail/fast-stylesheet/src/utils';

import {AsyncPromiseProvider} from '@adretail/async-cache-utils/src/components/AsyncSSRComponent/AsyncPromiseContext';
import SSRCookiesProvider from '@adretail/basic-components/src/Context/SSRCookiesProvider';
import UAProvider from '@adretail/basic-components/src/Context/UAProvider';

import providePageI18n from '@ding/i18n/src/providePageI18n';

import SSRRouter, {useRouterSession} from '@ding/layout/src/components/SSRRouter';
import PageProviders from '@ding/layout/src/PageProviders';
import {prefixWithLanguage} from '@ding/i18n/src/languages';

export const allESIFragments = {};

/**
 * @description Mark Component as ESI Fragment. Server after collection all ESI framgnets, creates ESI routes /esi/* which renders wrapped component as HTML
 * @param {*} Component Component rendered as ESI Fragment
 * @returns {React.ReactElement} Component during CSR, esi:include element during SSR (changed by varnish into component html)
 * @see https://varnish-cache.org/docs/trunk/users-guide/esi.html
 */
const withESI = (Component) => {
  const key = Component.displayName || crypto.randomBytes(16).toString('hex');
  const esiPath = prefixWithLanguage(`/esi/${key}`);

  if (ssr) {
    const ComponentWithProviders = ({req, res, devicesUA, routerProps, ...props}) => {
      const ComponentI18n = providePageI18n(Component);
      return (
        <UAProvider value={devicesUA}>
          <SSRCookiesProvider {...{req, res}}>
            <PageProviders>
              <SSRRouter {...routerProps}>
                <ComponentI18n {...props} />
              </SSRRouter>
            </PageProviders>
          </SSRCookiesProvider>
        </UAProvider>
      );
    };

    allESIFragments[key] = {
      component: ComponentWithProviders,
      props: {},
    };
  }

  const Wrapped = (props) => {
    const {value: routerSession} = useRouterSession();
    if (routerSession?.disabledCaching)
      return <Component {...props} />;

    if (ssr) {
      const propsPackId = sum(props);
      allESIFragments[key].props[propsPackId] = props;

      return React.createElement('esi:include', {src: `${esiPath}?props=${propsPackId}`});
    }

    if (window.__asyncESIContextCache?.[esiPath])
      return (
        <AsyncPromiseProvider value={window.__asyncESIContextCache[esiPath]}>
          <Component
            {...props}
          />
        </AsyncPromiseProvider>
      );

    return <Component {...props} />;
  };

  Wrapped.displayName = getHOCDisplayName('withESI', Component);

  return Wrapped;
};

export default withESI;
