import React from 'react';
import * as R from 'ramda';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import TitledCheckbox from '@ding/controls/src/CheckBox/TitledCheckbox';
import BorderedRoundedBox from '@ding/core/src/components/Parts/BorderedRoundedBox';

import useSectionTranslate from '../Section/hooks/useSectionTranslate';

export const RoundedLegendBox = styled(
  BorderedRoundedBox,
  {
    '& *': {
      cursor: 'default !important',
      pointerEvents: 'none',
    },
  },
);

/**
 * It is fake component that describes user titled checkbox
 *
 * @param {Object} props
 */
const ContractorNotifySectionLegend = (props) => {
  const t = useSectionTranslate();

  return (
    <RoundedLegendBox>
      <TitledCheckbox
        type='bell'
        settingName='newContractorLeaflet'
        value={false}
        onChange={R.F}
        {...props}
      >
        {t('notification_section.toggle_all_notifications')}
      </TitledCheckbox>
    </RoundedLegendBox>
  );
};

ContractorNotifySectionLegend.displayName = 'ContractorNotifySectionLegend';

export default ContractorNotifySectionLegend;
