import ssr from '@adretail/basic-helpers/src/base/ssr';

const getPageHeaderSize = () => {
  if (ssr)
    return {};

  const header = document.querySelector('header[role="banner"]');
  if (!header)
    return {};

  const {
    offsetWidth,
    offsetHeight,
  } = header;

  return {
    height: offsetHeight + (document.querySelector('[role="alert"]')?.offsetHeight || 0),
    width: offsetWidth,
  };
};

export default getPageHeaderSize;
