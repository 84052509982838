import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {PROPS_SCHEMA} from '@adretail/schemas';

import {useUA} from '@adretail/basic-components/src/Context/UAProvider';

import ModalPortal from '@adretail/basic-components/src/BasicModal/ModalPortal';
import {FixedFullscreenLayer} from '@ding/core/src/components/Predefined';

import CustomScrollbars from '../../CustomScrollbars';
import TitledSidebar from '../../TitledSidebar';

/**
 * https://bugs.webkit.org/show_bug.cgi?id=153852
 */
const useFullscreenLayerPopupScrollLock = () => {
  const ua = useUA();

  useEffect(
    () => {
      // TODO: DEV: browser.safari don't work because of varnish cache'
      if (!ua.browser?.safari) {
        document.body.style.cssText = 'overflow-y: hidden;';

        return () => {
          document.body.style.cssText = '';
        };
      }

      const scrollPosition = window.pageYOffset;

      const {body} = document;
      const styles = {
        overflow: 'hidden',
        position: 'fixed',
        top: `-${scrollPosition}px`,
        width: '100%',
      };

      Object.assign(body.style, styles);

      return () => {
        Object.assign(
          body.style,
          R.mapObjIndexed(R.always(null), styles),
        );

        void body.offsetHeight; // eslint-disable-line
        window.scrollTo(0, scrollPosition);
      };
    },
    [],
  );
};

/**
 * It is layer used for modals in mobile mode. Loads to fullscreen
 * and contains top header with close button and some titles. Generally
 * it should not be rendered on server, for now
 *
 * @todo
 *  change children(onUnmount) to children(onClose) if there will be any fancy animation
 *
 * @export
 */
const MobileFullscreenPopup = ({
  className, title, children, headerToolbar,
  type, fill, noTitleStyles, leftTopHeaderButtons,
  relativeContent, contentProps, closeButtonProps,
  rightTitlebarToolbar, mounted, wrapWithPortal, padding,
  onUnmount,
}) => {
  useFullscreenLayerPopupScrollLock();

  const content = (
    <FixedFullscreenLayer
      className={className}
      style={{
        zIndex: wrapWithPortal ? 9999 : 10000,
        display: mounted === false ? 'none' : 'initial',
      }}
    >
      <CustomScrollbars>
        <TitledSidebar
          {...padding !== undefined && {
            padding,
          }}
          {...{
            relativeContent,
            type,
            fill,
            title,
            leftTopHeaderButtons,
            headerToolbar,
            noTitleStyles,
            closeButtonProps,
          }}
          rightTitlebarToolbar={rightTitlebarToolbar}
          {...contentProps}
          onClose={
            onUnmount && ((data, status) => onUnmount(
              {
                // used for tracking
                ...data,
                source: 'button',
              },
              status,
            ))
          }
        >
          {children}
        </TitledSidebar>
      </CustomScrollbars>
    </FixedFullscreenLayer>
  );

  if (!wrapWithPortal)
    return content;

  return (
    <ModalPortal>
      {content}
    </ModalPortal>
  );
};

MobileFullscreenPopup.displayName = 'MobileFullscreenPopup';

MobileFullscreenPopup.propTypes = {
  wrapWithPortal: PropTypes.bool,
  contentProps: PROPS_SCHEMA,
  headerToolbar: PropTypes.element,
  leftTopHeaderButtons: PropTypes.element,
  title: PropTypes.node,
  mounted: PropTypes.bool,
  onUnmount: PropTypes.func,
};

MobileFullscreenPopup.defaultProps = {
  mounted: true,
  wrapWithPortal: true,
};

export default MobileFullscreenPopup;
