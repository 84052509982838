export default {
  meta: {
    title: '%{contractorName} Gazetka Promocyjna - oferty, rabaty, kupony [%{beginDate} do %{endDate}]-%{currentMonthName} str. %{page}|%{domain}',
    description: {
      no_tags: 'Aktualna gazetka promocyjna %{contractorName} zobacz na czym zaoszczędzisz. Najnowsze gazetki i najlepsze promocje. Sprawdź aktualną ofertę!',
      tags: 'Aktualna gazetka promocyjna %{contractorName} zobacz na czym zaoszczędzisz. Najnowsze gazetki i najlepsze promocje. Sprawdź promocje na %{firstTag}!',
    },
    og: {
      title: '%{contractorName} Gazetka Promocyjna [%{beginDate} do %{endDate}]-%{currentMonthName} str. %{page}|%{domain}',
    },
  },

  fourOFour: {
    meta: {
      title: '404 Gazetka Promocyjna - Nie znleziono gzetki o podanej nazwie na stronie Ding.pl',

      og: {
        title: '404 Gazetka Promocyjna - nie znaleziono gazetki o wskazanej nazwie na Ding.pl',
      },
    },

    section: {
      header: 'Gazetka nie istnieje lub została przeniesiona!',
      caption: 'Nie udało nam się znaleźć wskazanej gazetki w naszych zasobach, przejdź do galerii gazetek aby znaleźć interesującą Cie gazetkę.',
      image_alt: 'Błąd 404 - gazetki nie znaleziono.',
    },
  },
};
