import React from 'react';

import CornerItemHolder from '@ding/core/src/components/Parts/CornerItemHolder';

import CloseButton from './CloseButton.jsx';

const CloseCornerButton = ({spacing, style, ...props}) => (
  <CornerItemHolder
    spacing={spacing}
    style={style}
  >
    <CloseButton {...props} />
  </CornerItemHolder>
);

CloseCornerButton.displayName = 'CloseCornerButton';

export default CloseCornerButton;
