import {useEffect} from 'react';

import {useUA} from '@adretail/basic-components/src/Context/UAProvider';
import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import useMutation from '@ding/tiny-gql/src/react/hooks/useMutation';
import {useAuthState} from '@ding/auth/src/client/AuthContext';

import TinyGqlMutation from '@ding/tiny-gql/src/react/TinyGqlMutation';

import {CREATE_GUEST_MUTATION} from '../createGuestUser';
import {USER_TYPES} from '../../constants';

export const useCreateGuestUserMutation = (...args) => useMutation(
  CREATE_GUEST_MUTATION,
  ...args,
);

export const CreateDefaultUserToken = () => {
  const authState = useAuthState();
  const ua = useUA();
  const [createGuestUser] = useCreateGuestUserMutation();
  const haveToken = authState.getToken();

  useEffect(() => {
    (async () => {
      if (!haveToken) {
        authState.setToken(
          (await createGuestUser({
            kind: ua.bot ? USER_TYPES.BOT : USER_TYPES.GUEST,
          })).token,
        );
      }
    })();
  }, [haveToken]);

  return null;
};

export default provideProps(
  {
    mutation: CREATE_GUEST_MUTATION,
  },
)(TinyGqlMutation);
