import React from 'react';
import styled from '@adretail/fast-stylesheet/src/react/styled';

import Flex from '@ding/core/src/components/Predefined/Flex';
import Pagination from '.';

const PaginationBarHolder = styled(
  Flex.Centered,
  {
    width: '100%',

    '&:not(:empty)': {
      margin: '32px 0 0',
    },
  },
);

const PaginationBar = ({className, ...props}) => (
  <PaginationBarHolder className={className}>
    <Pagination {...props} />
  </PaginationBarHolder>
);

PaginationBar.displayName = 'PaginationBar';

export default PaginationBar;
