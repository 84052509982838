import {useEffect, useRef, useMemo} from 'react';
import * as R from 'ramda';

import {FETCH_POLICY} from '@ding/tiny-gql/src/react/TinyGqlQuery';
import {ITEM_TYPES} from '@ding/constants';

import {gql, GraphQLFragment} from '@ding/tiny-gql';
import safeArray from '@adretail/basic-helpers/src/base/safeArray';
import {decodeURLSearchParams} from '@ding/search/src/helpers';
import decodeUrl from '@adretail/basic-helpers/src/url/decodeUrl';

import useReactRouter from '@ding/core/src/hooks/useReactRouter';
import useGqlContext from '@ding/tiny-gql/src/react/hooks/useGqlContext';
import {useAuthState} from '@ding/auth/src/client/AuthContext';
import {useGeolocationAddress} from '@ding/geolocation/src/client/components/GeolocationContext';
import {useCookies} from '@adretail/basic-components/src/Context/SSRCookiesProvider';

import cookieEnv from '@ding/constants/src/envCookieConfig';

const MI_INSTITUTE_USER_INFO_QUERY = new GraphQLFragment(
  'customerInfo',
  gql`
    query customerInfo @locale(lang: ${APP_LANGUAGE}) {
      favouriteContractors: customerFavourites(limit: 20, filters: {kind: CONTRACTOR}) {
        items {
          id
          name
        }
      }

      favouriteTags: customerFavourites(limit: 20, filters: {kind: CUSTOM}) {
        items {
          id
          name
        }
      }
    }
  `,
);

const useMiInstituteWatchers = (apiRef) => {
  const {client: gqlClient} = useGqlContext();
  const {isLogged} = useAuthState();
  const {location: {pathname}} = useReactRouter();
  const urls = useRef([]);
  const address = useGeolocationAddress();
  const auth = useAuthState();
  const cookies = useCookies();
  const isReturningUser = useMemo(
    () => cookies.get(cookieEnv.returningUser.name) === '1',
    [],
  );

  useEffect(
    () => {
      if (isReturningUser)
        return;

      cookies.set(
        cookieEnv.returningUser.name,
        '1',
        cookieEnv.returningUser,
      );
    },
    [],
  );

  useEffect(
    () => {
      urls.current = R.takeLast(2, [
        ...urls.current,
        document.location.href,
      ]);
    },
    [pathname],
  );

  if (apiRef) {
    Object.assign(
      apiRef,
      {
        institute: {
          async fetchTrackUserInfo() {
            const {search} = document.location;

            let searchPhraseItem = R.find(
              R.propEq('kind', ITEM_TYPES.PHRASE),
              safeArray(
                decodeURLSearchParams(search),
              ).filter(Boolean),
            )?.name;

            if (!searchPhraseItem)
              searchPhraseItem = decodeUrl(search)?.q;

            const {
              favouriteContractors,
              favouriteTags,
            } = await gqlClient.query(
              MI_INSTITUTE_USER_INFO_QUERY,
              null,
              null,
              {
                allowBatching: false,
                type: FETCH_POLICY.NETWORK_ONLY,
              },
            );

            return R.mapObjIndexed(R.unless(R.anyPass([R.is(String), R.isNil]), R.toString))({
              url: R.last(urls.current),
              prevUrl: urls.current[urls.current.length - 2] || null,
              searchTerm: searchPhraseItem || null,
              variant: 'B',
              favContractors: favouriteContractors?.items,
              favTags: favouriteTags?.items,
              logged: isLogged(),
              location: address?.coords,
              listId: auth.getUser().shoppingListId,
              returningUser: isReturningUser,
            });
          },
        },
      },
    );
  }
};

export default useMiInstituteWatchers;
