import createLinkComponent from '../../utils/createLinkComponent';

export const MOBILE_APP_DIRECT_URL = 'https://play.google.com/store/apps/details?id=pl.mobiem.android.gazetki.interia&referrer=utm_source%3Dlandingpage%26utm_medium%3Dbutton%26utm_campaign%3DLP_button';

export const MOBILE_APP_URL_SCHEMA = '/aplikacja-mobilna';

export const genMobileAppURL = (_, {direct}) => (
  direct
    ? MOBILE_APP_DIRECT_URL
    : MOBILE_APP_URL_SCHEMA
);

export default createLinkComponent(['direct'], genMobileAppURL);
