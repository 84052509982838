import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {Pull} from '@ding/core/src/components/Predefined';
import CheckBoxList from '.';

const CheckBoxCountedList = ({itemsCountSelector, itemTitleRenderFn, ...props}) => (
  <CheckBoxList
    {...props}
    itemTitleRenderFn={
      item => (
        <>
          {itemTitleRenderFn(item)}
          {itemsCountSelector && (
            <Pull.Right>
              {itemsCountSelector(item)}
            </Pull.Right>
          )}
        </>
      )
    }
  />
);

CheckBoxCountedList.displayName = 'CheckBoxCountedList';

CheckBoxCountedList.propTypes = {
  itemsCountSelector: PropTypes.func,
  itemTitleRenderFn: PropTypes.func,
};

CheckBoxCountedList.defaultProps = {
  itemTitleRenderFn: R.prop('name'),
};

export default React.memo(CheckBoxCountedList);
