import React from 'react';
import {Redirect} from 'react-router-dom';

import {Layout} from '@ding/layout/src/components/LayoutParts';
import OAuthRegisterSection from './OAuthRegisterSection';

const OAuthRegisterRoute = ({location}) => {
  const prevStepAuth = location.state?.prevStepAuth;
  if (!prevStepAuth) {
    return (
      <Redirect to='/' />
    );
  }

  return (
    <Layout>
      <OAuthRegisterSection prevStepAuth={prevStepAuth} />
    </Layout>
  );
};

OAuthRegisterRoute.displayName = 'OAuthRegisterRoute';

export default React.memo(OAuthRegisterRoute);
