import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import useAggregatedLeafletsStorage from '@ding/aggregator/src/client/storage/useAggregatedLeafletsStorage';

import WorthToSeeLeafletsQuery from '@ding/api/src/gql/external/WorthToSeeLeafletsQuery';

import LeafletsGrid from '../LeafletsGrid';

const DEFAULT_WORTH_TO_SEE_LIMIT = 12;

const GQLWorthToSeeGrid = ({rejectAlreadySeen, queryProps, skeletonLoading, ...props}) => {
  const {get: getAggregatedLeaflets} = useAggregatedLeafletsStorage();

  const mergeQueryProps = R.mergeDeepRight(
    {
      variables: {
        limit: DEFAULT_WORTH_TO_SEE_LIMIT,
        rejectWithLeafletIds: rejectAlreadySeen ? getAggregatedLeaflets() : undefined,
      },
    },
    (queryProps || {}),
  );

  return (
    <WorthToSeeLeafletsQuery
      {...mergeQueryProps}
      {...skeletonLoading && {
        alwaysRenderComponent: true,
        loadingComponent: null,
      }}
    >
      {(items, asyncParams) => (
        <LeafletsGrid
          {...props}
          placeholder={
            (skeletonLoading && asyncParams.loading)
              ? mergeQueryProps.variables.limit
              : undefined
          }
          list={items}
        />
      )}
    </WorthToSeeLeafletsQuery>
  );
};

GQLWorthToSeeGrid.displayName = 'GQLWorthToSeeGrid';

GQLWorthToSeeGrid.propTypes = {
  rejectWithLeafletIds: PropTypes.bool,
  skeletonLoading: PropTypes.bool,
};

export default GQLWorthToSeeGrid;
