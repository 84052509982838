import React from 'react';
import createIcon from '../utils/createIcon';

export default createIcon(
  'Blog',
  {
    viewBox: '0 0 26.828 23.938',
  },
  <>
    <path d='M280.712,333.674a1.064,1.064,0,1,1-1.064,1.064h0a1.062,1.062,0,0,1,1.062-1.062h0m3.718,1.064a1.065,1.065,0,1,0,1.066-1.064h0a1.061,1.061,0,0,0-1.062,1.061h0m-9.574,0a1.065,1.065,0,1,0,1.065-1.069h0a1.061,1.061,0,0,0-1.062,1.061h0m19.178,9.983a3.191,3.191,0,1,1-4.512,4.512l-5.838-5.825a1.1,1.1,0,0,1-.27-.444l-1.265-4.187a1.065,1.065,0,0,1,1.3-1.333l4.3,1.189a1.065,1.065,0,0,1,.468.273Zm-8.657-2.633,3.934,3.934,1.5-1.5-3.916-3.9-2.16-.6Zm7.158,4.137-.207-.205-1.506,1.506.205.2a1.065,1.065,0,0,0,1.506-1.506h0' transform='translate(-268.146 -326.22)' />
    <path d='M107.74,151.058h-3.635l-.762,1.5-.76-1.5H96.823a1.274,1.274,0,0,1-1.274-1.273V135.8a1.277,1.277,0,0,1,1.274-1.265h18.934a1.279,1.279,0,0,1,1.276,1.274v10.073l1.827,1.818V135.8a3.091,3.091,0,0,0-3.091-3.091H96.821A3.1,3.1,0,0,0,93.73,135.8V149.78h0a3.091,3.091,0,0,0,3.091,3.091h4.637l1.884,3.724h0l1.124-2.234.752-1.484h4.339Z' transform='translate(-93.73 -132.708)' />
  </>,
);
