import React from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  Route,
} from 'react-router-dom';

const RelativeLink = ({to, ...props}) => (
  <Route
    render={
      ({location}) => (
        <Link
          {...props}
          to={`${location.pathname}${to}`}
        />
      )
    }
  />
);

RelativeLink.displayName = 'RelativeLink';

RelativeLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default RelativeLink;
