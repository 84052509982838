import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'FacebookF',
  {
    viewBox: '0 0 24 24',
  },
  <path d='M23.5 12a11.5 11.5 0 1 0-13.3 11.4v-8h-3V12h3V9.5c0-2.8 1.7-4.4 4.3-4.4l2.6.2V8h-1.4c-1.5 0-1.9.9-1.9 1.8v2.2H17l-.5 3.3h-2.7v8c5.5-.8 9.7-5.6 9.7-11.3Z' fill='#fff' />,
);
