import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import FormRow from '@ding/control-groups/src/Form/FormRow';
import {MoreDescriptionLink} from '@ding/core/src/components/Parts';
import {PrivacyLink} from '@ding/layout/src/components/Links';

const DescriptionRow = props => (
  <FormRow spacing='medium-small'>
    <MoreDescriptionLink {...props} />
  </FormRow>
);

const LawDescriptionLinks = () => {
  const t = useTranslate('website.auth.rodo_description');

  return (
    <>
      <DescriptionRow linkComponent={PrivacyLink}>
        {t('administrator')}
      </DescriptionRow>

      <DescriptionRow linkComponent={PrivacyLink}>
        {t('target')}
      </DescriptionRow>

      <DescriptionRow linkComponent={PrivacyLink}>
        {t('law')}
      </DescriptionRow>

      <DescriptionRow linkComponent={PrivacyLink}>
        {t('receivers')}
      </DescriptionRow>

      <DescriptionRow
        separator=' '
        linkComponent={PrivacyLink}
        linkTitle={
          t('website.shared.titles.here')
        }
      >
        {t('your_rights')}
      </DescriptionRow>

      <DescriptionRow
        separator=' '
        linkComponent={PrivacyLink}
        linkTitle={
          t('website.shared.titles.privacy')
        }
      >
        {t('additional')}
      </DescriptionRow>
    </>
  );
};

LawDescriptionLinks.displayName = 'LawDescriptionLinks';

export default React.memo(LawDescriptionLinks);
