export const fadeInKeyframes = {
  '@keyframes fade-in': {
    '0%': {opacity: 0},
    '100%': {opacity: 1},
  },
};

export const fadeOutKeyframes = {
  '@keyframes fade-out': {
    '0%': {opacity: 1},
    '100%': {opacity: 0},
  },
};
