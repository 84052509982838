import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'Pen',
  {
    viewBox: '0 0 18 18',
  },
  <path d='M17.33.67a2.3,2.3,0,0,1,0,3.25L16.24,5,13,1.76,14.08.67a2.31,2.31,0,0,1,3.25,0Zm-16.25,13L0,18l4.33-1.08L15.16,6.09,11.91,2.84Z' />,
);
