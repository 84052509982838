import React from 'react';
import PropTypes from 'prop-types';

import {CONTRACTOR_SCHEMA, LIST_ITEM_SCHEMA} from '@ding/constants/src/typeSchema';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import ContractorsLink from '@ding/layout/src/components/Links/ContractorsLink';
import {PrioritizedContractorsQuery} from '@ding/api/src/gql/prioritizedContractors';

import OptionalContractorsGridSection from './OptionalContractorsGridSection';
import MoreContractorsButton from '../Buttons/MoreContractorsButton';
import ContractorNotifySectionLegend from '../Legends/ContractorNotifySectionLegend';

const DefaultContractorsGridSection = ({category, prioritized, variables, ...props}) => {
  const t = useTranslate();
  const {title, header} = t('website.shared.sections.contractors');

  return (
    <OptionalContractorsGridSection
      listSelector={null}
      title={
        !props.subsection && (
          <ContractorsLink category={category}>
            {title}
          </ContractorsLink>
        )
      }
      header={
        header && (
          <ContractorsLink category={category}>
            {category ? `${header}: ${category.name}` : header}
          </ContractorsLink>
        )
      }
      aria-label={header}
      headerToolbar={(
        <ContractorNotifySectionLegend />
      )}
      expandMoreAnchor={(
        <MoreContractorsButton />
      )}
      {...!category && prioritized && {
        queryComponent: PrioritizedContractorsQuery,
        queryProps: {
          variables: {
            limit: 12,
            ...variables,
          },
        },
      }}
      {...category && {
        listSelector: 'items',
        queryProps: {
          variables: {
            limit: 24,
            filters: {
              categoryIds: [category.id],
            },
          },
        },
      }}
      showLoadingPlaceholder
      skeletonLoading
      {...props}
    />
  );
};

DefaultContractorsGridSection.displayName = 'Contractors';

DefaultContractorsGridSection.propTypes = {
  category: LIST_ITEM_SCHEMA,
  contractors: PropTypes.arrayOf(CONTRACTOR_SCHEMA),
  prioritized: PropTypes.bool,
};

DefaultContractorsGridSection.defaultProps = {
  prioritized: true,
};

export default React.memo(DefaultContractorsGridSection);
