import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'BellOutline',
  {
    viewBox: '0 0 24 24',
    fill: 'none',
    stroke: 'currentcolor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  },
  <>
    <path d='M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9' />
    <path d='M13.73 21a2 2 0 0 1-3.46 0' />
  </>,
);
