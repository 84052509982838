import PropTypes from 'prop-types';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import provideProps from '@adretail/basic-decorators/src/base/provideProps';

const Float = styled.span(
  {
    right: {
      float: 'right',
    },
    left: {
      float: 'left',
    },
  },
  {
    omitProps: ['right'],
    classSelector: (classes, {right}) => (
      classes[right ? 'right' : 'left']
    ),
  },
);

Float.displayName = 'Float';

Float.propTypes = {
  right: PropTypes.bool,
};

Float.Left = provideProps({right: false})(Float);
Float.Right = provideProps({right: true})(Float);

export default Float;
