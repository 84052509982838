import React from 'react';
import PropTypes from 'prop-types';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import {Header} from '@ding/core/src/components/Predefined';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';

const SectionHeader = styled(
  ({subsection, headerTag, ...props}) => {
    const HeaderComponent = Header[headerTag];

    return (
      <HeaderComponent {...props} />
    );
  },
  {
    base: {
      fontWeight: 800,

      ...createBreakpointClasses(
        {
          xs: {
            margin: '8px 0 20px',
            paddingLeft: 40,
          },

          md: {
            paddingLeft: 100,
            marginBottom: 32,
          },
        },
      ),

      // when no title in section
      '&:first-child': {
        marginTop: 0,
      },
    },

    'padding-small': {
      paddingLeft: 40,
    },

    'padding-big': {
      paddingLeft: 100,
    },

    section: createBreakpointClasses(
      {
        xs: {
          fontSize: 21,
          lineHeight: '30px',
          marginBottom: 22,
        },

        md: {
          fontSize: 23,
          lineHeight: '30px',
          marginBottom: 25,
        },
      },
    ),

    subsection: createBreakpointClasses(
      {
        xs: {
          fontSize: 19,
          marginBottom: 15,
        },

        md: {
          fontSize: 23,
          marginBottom: 16,
        },
      },
    ),

    noLeftPadding: {
      paddingLeft: 0,
    },

    noBottomSpace: {
      marginBottom: 0,
    },
  },
  {
    index: 1,
    omitProps: [
      'noLeftPadding',
      'noBottomSpace',
      'padding',
    ],
    classSelector: (classes, {padding, noBottomSpace, subsection, noLeftPadding, headerTag}) => [
      padding && classes[`padding-${padding}`],
      noLeftPadding && classes.noLeftPadding,
      noBottomSpace && classes.noBottomSpace,
      headerTag && classes[headerTag],
      subsection
        ? classes.subsection
        : classes.section,
    ],
  },
);

SectionHeader.displayName = 'SectionHeader';

SectionHeader.propTypes = {
  noLeftPadding: PropTypes.bool,
  subsection: PropTypes.bool,
  noBottomSpace: PropTypes.bool,
  headerTag: PropTypes.string,
};

SectionHeader.defaultProps = {
  headerTag: 'H2',
};

export default SectionHeader;
