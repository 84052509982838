import env from '@cdd/internal-server/src/constants/env';
import cookieEnv from '@ding/constants/src/envCookieConfig';

const {geolocation: GEO_COOKIE} = cookieEnv;

export {
  GEO_COOKIE,
};

export default env.shared.geolocation;
