import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {reactFormat} from '@ding/core/src/helpers';

import {
  Text,
  UndecoratedLink,
} from '@ding/core/src/components/Predefined';

/**
 * Hide global grecaptcha badge and show flow title
 *
 * @export
 * https://stackoverflow.com/a/44543771
 */
const RecaptchaLinkRow = () => {
  const t = useTranslate();

  return (
    <Text.Muted size='small'>
      {reactFormat(
        t('website.auth.recaptcha.flow_row_title'),
        [
          <UndecoratedLink
            key='privacy'
            to='https://policies.google.com/privacy'
            type='primary'
            target='_blank'
          >
            {t('website.auth.recaptcha.privacy')}
          </UndecoratedLink>,

          <UndecoratedLink
            key='terms'
            to='https://policies.google.com/terms'
            type='primary'
            target='_blank'
          >
            {t('website.auth.recaptcha.terms')}
          </UndecoratedLink>,
        ],
      )}
    </Text.Muted>
  );
};

RecaptchaLinkRow.displayName = 'RecaptchaLinkRow';

export default React.memo(RecaptchaLinkRow);
