import Loadable from 'react-loadable';
import LoadingSpinnerSectionErrorHandling from '@ding/core/src/components/Parts/LoadingSpinnerSectionErrorHandling';

import {DEFAULT_CHUNK_TIMEOUT} from '../constants';

export const FourOFourContainer = Loadable(
  {
    loader: () => import(/* webpackChunkName: "404" */ './FourOFourContainer'),
    modules: ['./FourOFourContainer'],
    webpack: () => [require.resolveWeak('./FourOFourContainer')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

const FourOFour = Loadable(
  {
    loader: () => import(/* webpackChunkName: "404" */ './FourOFour'),
    modules: ['./FourOFour'],
    webpack: () => [require.resolveWeak('./FourOFour')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export default FourOFour;
