import React from 'react';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import Text from '@ding/core/src/components/Predefined/Text';

const AdTitleHolder = styled(
  Text,
  {
    marginBottom: 8,
    textAlign: 'center',
    fontSize: '11px !important',
    letterSpacing: 2,
  },
  {
    type: 'muted',
    block: true,
    expanded: true,
    uppercase: true,
  },
);

const AdTitle = (props) => {
  const t = useTranslate('website.advertisement');

  return (
    <AdTitleHolder {...props}>
      {t('title')}
    </AdTitleHolder>
  );
};

AdTitle.displayName = 'AdTitle';

export default AdTitle;
