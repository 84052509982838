import React from 'react';

import translate, {useTranslate} from '@ding/i18n';

import {AutocompleteInput} from '@ding/controls/src/Autocomplete';
import SearchIcon from '@ding/core/src/icons/Search';
import PageSearchGroupedLists from './PageSearchGroupedLists';
import PageSearchEnterListener from './PageSearchEnterListener';
import WorthToSeeSearchGrid from '../containers/WorthToSeeSearchGrid';

import GQLPageSearchAutocomplete from './GQLPageSearchAutocomplete';

const PageSearchInput = translate(
  ({active, t, ...props}) => (
    <AutocompleteInput
      active={active}
      type='text'
      color='light'
      placeholder={
        t('website.header.search.placeholder')
      }
      iconLeft={(
        <SearchIcon
          type={
            active
              ? 'primary'
              : 'dark'
          }
        />
      )}
      {...props}
    />
  ),
);

/**
 * Desktop page search in navbar, it is rounded
 *
 * @export
 */
const PageSearch = ({inputProps, ...props}) => {
  const t = useTranslate('website.header.search');

  return (
    <PageSearchEnterListener>
      {listeners => (
        <GQLPageSearchAutocomplete
          containerProps={{
            role: 'search',
            title: t('label'),
            'aria-label': t('label'),
          }}
          inputComponent={PageSearchInput}
          inputProps={inputProps}
          maxListHeight={520}
          style={{
            width: 350,
          }}
          showPopupWhenEmptyPhrase
          watchInputValueChange
          {...props}
          {...listeners}
        >
          {(searchData, value) => (
            value?.name
              ? (
                <PageSearchGroupedLists
                  phrase={value?.name}
                  searchData={searchData}
                />
              )
              : (
                <WorthToSeeSearchGrid
                  gridConfig={{xs: 4}}
                  padding='small'
                  itemProps={{
                    spaced: false,
                    showTitles: false,
                    showDate: true,
                    dateFormat: 'numeric',
                    size: 'tiny',
                    imageFit: 'cover',
                  }}
                />
              )
          )}
        </GQLPageSearchAutocomplete>
      )}
    </PageSearchEnterListener>
  );
};

PageSearch.displayName = 'PageSearch';

export default PageSearch;
