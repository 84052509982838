import React from 'react';
import createIcon from '../utils/createIcon';

export default createIcon(
  'Products',
  {
    viewBox: '0 0 20.334 24',
  },
  <>
    <path d='M200.388,219.725h0a.993.993,0,0,0,.986,1,1.081,1.081,0,0,0,.705-.3l18.171-18.15a.987.987,0,0,0,0-1.4h0a.994.994,0,0,0-.711-.3.973.973,0,0,0-.7.288L200.679,219.02a.971.971,0,0,0-.291.7' transform='translate(-200.294 -198.644)' />
    <path d='M202.666,151.667a4.664,4.664,0,1,0,4.662,4.665h0a4.67,4.67,0,0,0-4.662-4.665m0,7.337a2.673,2.673,0,1,1,2.671-2.673h0A2.677,2.677,0,0,1,202.666,159' transform='translate(-198.001 -151.667)' />
    <path d='M480.9,522.567a4.663,4.663,0,1,0,4.664,4.664,4.664,4.664,0,0,0-4.664-4.664m0,7.336a2.673,2.673,0,1,1,2.672-2.672h0A2.675,2.675,0,0,1,480.9,529.9' transform='translate(-465.226 -507.894)' />
  </>,
);
