import React from 'react';

import {
  PRIMARY_FILL,
  WHITE,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {useUA} from '@adretail/basic-components/src/Context/UAProvider';

import {WebsiteRegulationsLink} from '@ding/auth/src/client/Layout/Links/Law';
import {
  Margin,
  PageContainer,
} from '@ding/core/src/components/Predefined';

import {PrivacyLink} from '../../Links';
import HeaderLogo from '../Header/Desktop/HeaderLogo';

const BarHolder = styled(
  'div',
  {
    width: '100%',
    minHeight: 100,
    background: PRIMARY_FILL,
    fontSize: 13,
    color: WHITE,
  },
);

const CopyrightBarHolder = styled(
  PageContainer,
  {
    base: {
      display: 'flex',
      minHeight: 'inherit',
      padding: 10,
    },

    big: {
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },

    small: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
  },
  {
    index: 4,
    omitProps: ['small'],
    classSelector: (classes, {small}) => classes[small ? 'small' : 'big'],
  },
);

const CopyrightBar = () => {
  const t = useTranslate();
  const ua = useUA();
  const now = new Date();

  return (
    <BarHolder aria-label='copyright-info'>
      <CopyrightBarHolder small={ua.mobile}>
        {(
          ua.mobile
            ? <Margin bottom={2} block><HeaderLogo /></Margin>
            : <HeaderLogo />
        )}

        <Margin left='auto'>
          <span>
            {t('website.footer.copyrights.accept_regulations')}

            <WebsiteRegulationsLink
              rel='nofollow'
              weight={700}
            >
              {t('website.footer.copyrights.regulations')}
            </WebsiteRegulationsLink>
            .
          </span>

          &nbsp;

          <span>
            <PrivacyLink weight={700} />
            .
          </span>

          &nbsp;

          {t('website.footer.copyrights.copyright', now.getFullYear())}
        </Margin>
      </CopyrightBarHolder>
    </BarHolder>
  );
};

CopyrightBar.displayName = 'CopyrightBar';

export default CopyrightBar;
