import PropTypes from 'prop-types';
import styled from '@adretail/fast-stylesheet/src/react/styled';

const RelativeContainer = styled.span(
  {
    base: {
      position: 'relative',
    },

    block: {
      display: 'block',
    },
  },
  {
    omitProps: ['block'],
    classSelector: (classes, {block}) => block && classes.block,
  },
);

RelativeContainer.displayName = 'RelativeContainer';

RelativeContainer.propTypes = {
  block: PropTypes.bool,
};

export default RelativeContainer;
