import * as R from 'ramda';
import * as ITEM_TYPES from '@ding/constants/src/itemTypes';

const decodeSearchItem = R.compose(
  (searchItem) => {
    if (searchItem) {
      const {kind, id} = searchItem;
      if (kind === ITEM_TYPES.PHRASE) {
        return {
          id: null,
          kind,
          name: id,
        };
      }
    }

    return searchItem;
  },
  R.ifElse(
    R.either(
      R.isEmpty,
      R.isNil,
    ),
    R.always(null),
    R.compose(
      R.zipObj(['kind', 'id', 'name']), // convert ['PRODUCT', 1] => {kind: 'PRODUCT', id: 1}
      R.split(','), // convert PRODUCT,1 => ['PRODUCT', 1]
    ),
    decodeURIComponent,
  ),
);

export default decodeSearchItem;
