import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {FETCH_POLICY} from '@ding/tiny-gql/src/react/TinyGqlQuery';

import {useUA} from '@adretail/basic-components/src/Context/UAProvider';

import renderFunctionalChildren from '@adretail/basic-helpers/src/ui/renderFunctionalChildren';
import styled from '@adretail/fast-stylesheet/src/react/styled';

import ExpandIcon from '@ding/core/src/icons/Expand';
import {CornerIconHolder, Text} from '@ding/core/src/components/Predefined';

import MobileSelectBottomList from './MobileSelectBottomList';
import Button from '../Button';
import Autocomplete, {GQLAutocomplete} from '../Autocomplete';

const SelectCornerIconHolder = ({active, children}) => (
  <CornerIconHolder
    iconSpacing='auto'
    iconOnRight
    icon={(
      <ExpandIcon
        type='light'
        size='tiny'
        verticalFlip={active}
        style={{
          marginLeft: 10,
        }}
      />
    )}
  >
    {children}
  </CornerIconHolder>
);

export const SelectButtonHolder = styled(
  ({
    title,
    active,
    value,
    emptyItemComponent: EmptyItemComponent,
    ...props
  }) => {
    const ua = useUA();

    return (
      <Button
        type='thin-gray'
        outline
        truncateHeight

        {...props}
        textAlign='left'
        noHover

        {...active && !ua.mobile && {
          style: {
            ...props.style,
            borderColor: 'transparent',
          },
        }}
      >
        <SelectCornerIconHolder active={active}>
          {renderFunctionalChildren(
            title,
            value || (EmptyItemComponent && <EmptyItemComponent />) || null,
            ua,
          )}
        </SelectCornerIconHolder>
      </Button>
    );
  },
  {
    margin: '0 auto 8px auto',
  },
  {
    expandedPadding: false,
    index: 3,
  },
);

const SelectButton = ({
  list, inputProps, expanded,
  title, listTitle, rootTitle,
  ...props
}) => {
  const ua = useUA();
  const Component = list ? Autocomplete : GQLAutocomplete;

  return (
    <Component
      inputOverDropdown={!ua.mobile}
      inputComponent={SelectButtonHolder}
      inputProps={
        (value, childrenContentListeners, {active}) => ({
          ...inputProps,
          emptyItemComponent: props.emptyItemComponent,
          expanded,
          title,
          style: {
            ...inputProps?.style,
            ...!ua.mobile && {
              minWidth: active ? 260 : 120,
            },
          },
        })
      }

      listProps={{
        title: (
          <Text weight={800} uppercase>
            {listTitle
            || rootTitle
            || (title && renderFunctionalChildren(title, null, ua))
            || null}
          </Text>
        ),
        fullPage: false,
        headerSeparator: true,
        containerProps: {noPadding: true},
      }}

      list={list}
      clientFetchPolicy={FETCH_POLICY.CACHE_AND_NETWORK}
      providePhraseVariable={false}
      showPopupWhenEmptyPhrase
      addEmptyOption
      alwaysShowEmptyOption
      allowCloseInputClick
      preloadList={
        !R.isNil(props.value)
      }

      {...props}
      {...ua.mobile && {
        dropdownComponent: null,
        listComponent: MobileSelectBottomList,
      }}
    />
  );
};

SelectButton.propTypes = {
  // it is value provided when user does not select any option
  // it is visible only in input
  defaultEmptyInputValue: PropTypes.string,

  // title is function that formats rootTitle
  rootTitle: PropTypes.string,
  listTitle: PropTypes.node,
  title: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
};

export default React.memo(SelectButton);
