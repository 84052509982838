import {gql, GraphQLFragment} from '@ding/tiny-gql';

import rootApiGqlClient from '@ding/api/src/clients/rootApiGqlClient';
import {decodeResponseToken} from './createGuestUser';

export const REFRESH_USER_TOKEN_MUTATION = new GraphQLFragment(
  'refreshTokenMutation',
  gql`
    mutation refreshTokenMutation($token: String!) {
      refreshToken(input: {
        token: $token
      }) {
        token
      }
    }
  `,
);

const refreshUserToken = oldToken => decodeResponseToken(
  () => rootApiGqlClient.mutate(
    REFRESH_USER_TOKEN_MUTATION,
    {
      token: oldToken,
    },
  ),
);

export default refreshUserToken;
