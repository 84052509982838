import React from 'react';
import {
  Margin,
  Flex,
} from '@ding/core/src/components/Predefined';

import RememberMeCheckbox from './RememberMeCheckbox';
import ResetPasswordTitledLink from './ResetPasswordTitledLink';

const LoginToolbar = ({l}) => (
  <Margin
    top={4}
    block
  >
    <Flex.Row>
      <RememberMeCheckbox {...l.input('rememberMe')} />

      <Margin left='auto'>
        <ResetPasswordTitledLink />
      </Margin>
    </Flex.Row>
  </Margin>
);

LoginToolbar.displayName = 'LoginToolbar';

export default LoginToolbar;
