import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {
  Route,
  Switch,
} from 'react-router-dom';

import {REACT_COMPONENT_CLASS_SCHEMA} from 'adretail-schemas';
import memoizeOne from '@adretail/basic-helpers/src/base/memoizeOne';

const mapRoutes = (routeProps, routes) => R.map(
  ({
    component: Component,
    routeComponent: RouteComponent = Route,
    ...props
  }) => (
    <RouteComponent
      key={props.path}
      {...props}
      render={
        renderRouteProps => (
          <Component
            {...routeProps}
            {...renderRouteProps}
          />
        )
      }
    />
  ),
  routes,
);

export default class RouteMapper extends React.PureComponent {
  static propTypes = {
    routeProps: PropTypes.any,
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.string),
        ]),
        component: REACT_COMPONENT_CLASS_SCHEMA,
        routingModule: PropTypes.string,
      }),
    ),
  };

  cachedMapRoutes = memoizeOne(mapRoutes);

  render() {
    const {
      children,
      routes,
      routeProps,
    } = this.props;

    const renderedRoutes = this.cachedMapRoutes(routeProps, routes);

    return (
      <Switch>
        {(
          R.is(Function, children)
            ? children(renderedRoutes)
            : renderedRoutes
        )}
      </Switch>
    );
  }
}
