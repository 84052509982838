import React from 'react';

import {getHOCDisplayName} from '@adretail/basic-helpers/src/getters/getDisplayName';
import {createClientContext} from '../TinyGqlProvider';

import useGqlContext from '../hooks/useGqlContext';

/**
 * Provide Client Context, not Client! Client Context contains also
 * queries store used to refetch
 *
 * @param {*} Component
 */
const provideClientContext = (Component) => {
  const Wrapped = ({client, ...props}) => {
    const contextClient = useGqlContext();

    return (
      <Component
        {...props}
        clientContext={(
          client
            ? createClientContext({}, () => client)
            : contextClient
        )}
      />
    );
  };

  Wrapped.displayName = getHOCDisplayName('provideGqlClientContext', Component);

  return Wrapped;
};

export default provideClientContext;
