import PropTypes from 'prop-types';

import {
  ID_SCHEMA,
  LIST_ITEM_SCHEMA,
} from '@adretail/schemas';

import {TAG_SCHEMA} from './typed';

export const AUTHOR_CARD_SCHEMA = PropTypes.shape(
  {
    id: ID_SCHEMA.isRequired,
    name: PropTypes.string,
    bio: PropTypes.string,
    photo: PropTypes.string,
    website: PropTypes.string,
  },
);

export const AUTHOR_SCHEMA = PropTypes.shape(
  {
    id: ID_SCHEMA.isRequired,
    name: PropTypes.string,
    photo: PropTypes.string,
    bio: PropTypes.string,
    website: PropTypes.string,
  },
);

export const ARTICLE_CARD_SCHEMA = PropTypes.shape(
  {
    id: ID_SCHEMA.isRequired,
    slug: PropTypes.string,
    title: PropTypes.string,
    cover: PropTypes.string,
    category: LIST_ITEM_SCHEMA,
  },
);

export const ARTICLE_SCHEMA = PropTypes.shape(
  {
    id: ID_SCHEMA.isRequired,
    publishDate: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    lead: PropTypes.string,
    cover: PropTypes.string,
    fullCover: PropTypes.string,
    coverCaption: PropTypes.string,
    content: PropTypes.string,
    tags: PropTypes.arrayOf(TAG_SCHEMA),
    category: LIST_ITEM_SCHEMA,
    author: AUTHOR_SCHEMA,
  },
);
