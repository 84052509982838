import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {ADDRESS_SCHEMA} from '@ding/constants';
import Text from '../../Predefined/Text';

const getStreetTranslationSuffix = (t, streetName) => (
  R.test(
    t('website.shared.titles.address.blacklisted_street_prefixes'),
    streetName,
  )
    ? '_without_prefix'
    : ''
);

export const Street = ({street, streetNo, ...props}) => {
  const t = useTranslate();

  return (
    <Text {...props}>
      {t(
        `website.shared.titles.address.street${getStreetTranslationSuffix(t, street.name)}`,
        [
          street.name,
          streetNo,
        ],
      )}
    </Text>
  );
};

export const PostalAddress = ({address, ...props}) => {
  if (!address) return null;

  return (
    <Text tagName='address' {...props}>
      <Street street={address?.street} streetNo={address?.streetNo} block />
      <Text block>
        {[address.city?.name, address.postcode?.name].filter(Boolean).join(', ')}
      </Text>

    </Text>
  );
};

export const translateAddress = (t, address) => {
  const cityName = address.city?.name || '';

  if (!address.street)
    return cityName;

  const streetName = address.street.name || '';
  return t(
    `website.shared.titles.address.with_street${getStreetTranslationSuffix(t, streetName)}`,
    [
      cityName,
      streetName,
      R.defaultTo('', address.streetNo),
    ],
  );
};

const Address = ({address, textWrap, ...props}) => {
  const t = useTranslate();
  const content = translateAddress(t, address);

  if (!textWrap)
    return content;

  return (
    <Text {...props}>
      {content}
    </Text>
  );
};

Address.propTypes = {
  textWrap: PropTypes.bool,
  address: ADDRESS_SCHEMA.isRequired,
};

Address.defaultProps = {
  textWrap: true,
};

export default React.memo(Address);
