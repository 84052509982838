import {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import env from '@cdd/internal-server/src/constants/env';
import ssr from '@adretail/basic-helpers/src/base/ssr';

import {useAuthState} from '@ding/auth/src/client/AuthContext';
import useReactRouter from '@ding/core/src/hooks/useReactRouter';
import useTrackers from '../helpers/useTrackers';

const GEMIUS_KEYS = (() => {
  const gemiusConfig = !ssr && window.Inpl?.WebTr?.gemius;
  if (gemiusConfig) {
    return {
      primaryPVKey: gemiusConfig.identifier,
      partialPVKey: gemiusConfig.partial_identifier,
    };
  }

  return env.client.gemius;
})();

let FIRST_PV_MOUNT = true;

const PVTracker = ({iwa: iwaParams, abortFirstIWAPageView}) => {
  const callbackRef = useRef(null);
  const {
    authSupported,
    stateNotInitialized,
    logged,
    user,
  } = useAuthState(
    ({isAuthSupported, isLogged, getUser}) => ({
      authSupported: isAuthSupported(),
      logged: isLogged(),
      user: getUser(),
    }),
  );

  callbackRef.current = {
    iwa: iwaParams,
  };

  const router = useReactRouter();
  const {gemius, iwa, ga4} = useTrackers();

  if (authSupported && !ssr && !stateNotInitialized) {
    const userId = user?.customer?.id;
    if (logged && !R.isNil(userId))
      iwa.setEventGlobalVar('USER_ID', userId);
    else
      iwa.delEventGlobalVar('USER_ID');

    iwa.setEventGlobalVar('zalogowany', logged ? 'tak' : 'nie', 'page');
  }

  const {pathname} = router.location;

  useEffect(
    () => {
      const {iwa: iwaHandlers} = callbackRef.current;

      if (!FIRST_PV_MOUNT || !abortFirstIWAPageView) {
        iwa('send', 'ppv');
        iwaHandlers?.beforePV?.(iwa); // eslint-disable-line no-unused-expressions

        // add flag for iwa3 that app is spa
        if (!window.iwaAppType) window.iwaAppType = 'SPA';

        if (GEMIUS_KEYS?.partialPVKey && !FIRST_PV_MOUNT)
          gemius.hit(GEMIUS_KEYS.partialPVKey);
      }

      // NOTE: Always sends GA4 page_view event, GTM has disabled automatic page_view sending due to problems with reporting pv event in SPA app
      // https://developers.google.com/analytics/devguides/collection/ga4/views?client_type=gtm#manual_pageviews
      ga4.pageView({
        pageTitle: document.title,
        pageLocation: document.location.href,
        pagePath: pathname,
      });

      FIRST_PV_MOUNT = false;

      return () => {
        iwaHandlers?.beforeUnmount?.(iwa); // eslint-disable-line no-unused-expressions
      };
    },
    [pathname],
  );

  return null;
};

PVTracker.propTypes = {
  iwa: PropTypes.shape(
    {
      beforePV: PropTypes.func,
    },
  ),
  abortFirstIWAPageView: PropTypes.bool,
};

PVTracker.defaultProps = {
  abortFirstIWAPageView: true,
};

export default PVTracker;
