import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {
  Grid,
  Text,
} from '@ding/core/src/components/Predefined';

import {RecaptchaForm} from '@ding/auth/src/client/Recaptcha';
import EmailResetPasswordGroup from './EmailResetPasswordGroup';

const EmailResetPasswordForm = (props) => {
  const t = useTranslate();

  return (
    <RecaptchaForm {...props}>
      {loading => l => (
        <Grid>
          <Grid.DividedColumn>
            <EmailResetPasswordGroup
              {...{
                loading,
                l,
              }}
            />
          </Grid.DividedColumn>

          <Grid.DividedColumn>
            <Text>
              {t('website.auth.sections.reset_password.email_reset_info')}
            </Text>
          </Grid.DividedColumn>
        </Grid>
      )}
    </RecaptchaForm>
  );
};

EmailResetPasswordForm.displayName = 'EmailResetPasswordForm';

export default EmailResetPasswordForm;
