import {useMemo, useCallback} from 'react';
import * as R from 'ramda';

import selectResponseData from '@adretail/basic-helpers/src/getters/selectResponseData';

import useAsyncSSRPromise from '@adretail/async-cache-utils/src/hooks/useAsyncSSRPromise';
import * as idleCallback from '@adretail/basic-helpers/src/async/idleCallback';

import * as FETCH_POLICY from '../../constants/fetchPolicy';

import useQueryRefetchStore from './useQueryRefetchStore';
import useGqlContext from './useGqlContext';

import {
  pickResponseErrors,
  displayErrorsList,
} from '../../helpers';

import {
  defaultQueryCacheKey,
  getHydrationLoggerLabels,
} from '../helpers';

export const postHydrateExecutor = ({
  clientFetchPolicy,
  queryCacheKey,
  query,
  data,
  variables,
  client,
}) => {
  idleCallback.create(
    () => {
      const errors = pickResponseErrors(data);
      if (errors) {
        console.error(...getHydrationLoggerLabels(queryCacheKey, variables, data, query));
        displayErrorsList(errors);

        // hydration success
      } else
      if (process.env.APP_ENV !== 'production')
        console.warn(...getHydrationLoggerLabels(queryCacheKey, variables, data, query));
    },
  );

  if (clientFetchPolicy === FETCH_POLICY.CACHE_AND_NETWORK) {
    client.cacheStore.setex(
      queryCacheKey.name,
      Promise.resolve(data),
    );
  }
};

const useQuery = (
  query,
  {
    skip,
    allowSSR,
    preserveDataOnRefetch,
    variables,
    cacheKey,
    headers,
    allowBatching = true,
    clientFetchPolicy = FETCH_POLICY.CACHE_AND_NETWORK,
    responsePostFetchScelector,
    parallelPromiseFn,
    responseSelector = R.identity,
  } = {},
) => {
  const clientContext = useGqlContext();
  const queryCacheKey = defaultQueryCacheKey(query, cacheKey, variables);

  const {
    refetchQuery,
    client,
  } = clientContext;

  const promiseFn = async ({cachePolicy} = {}) => {
    const result = await Promise.all(
      [
        client.query(
          query,
          variables,
          headers,
          {
            allowBatching,
            cachePolicy,
            type: clientFetchPolicy,
            key: queryCacheKey.name,
          },
        ),
        parallelPromiseFn
          ? parallelPromiseFn(
            {
              client,
            },
          )
          : Promise.resolve(null),
      ],
    );

    if (responsePostFetchScelector)
      result[0] = responsePostFetchScelector(...result);

    return result[0];
  };

  const cacheHydrationData = useCallback(
    (data) => {
      postHydrateExecutor(
        {
          data,
          query,
          queryCacheKey,
          clientFetchPolicy,
          variables,
          client,
        },
      );
    },
  );

  const result = useAsyncSSRPromise(
    {
      onHydrated: cacheHydrationData,
      cacheKey: queryCacheKey,
      preserveDataOnRefetch,
      skip,
      allowSSR,
      promiseFn,
    },
  );

  useQueryRefetchStore(
    {
      queryUUID: result.uuid,
      variables,
      query,
      refetchData: result.refetchData,
      loadData: result.loadData,
      clientContext,
    },
  );

  const refetchCallbacks = useMemo(
    () => ({
      refetchCurrentQuery: async (flags) => {
        const responseData = await refetchQuery(
          query,
          variables,
          {
            withoutLoadingSpinner: true,
            ...flags,
          },
        );

        return responseData && selectResponseData(responseSelector, responseData);
      },

      refetchQuery: (...args) => {
        // search store for query and refetches it
        if (args.length)
          return refetchQuery(...args);

        // refetch current query
        return refetchQuery(query, variables);
      },
    }),
    [refetchQuery],
  );

  return {
    ...refetchCallbacks,
    ...result,
    ...result.data && {
      data: selectResponseData(responseSelector, result.data),
    },
  };
};

export default useQuery;
