import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  REACT_COMPONENT_CLASS_SCHEMA,
  PROPS_SCHEMA,
} from '@adretail/schemas';

import {
  DIRECTION_SCHEMA,
  DIRECTION,
} from '@ding/constants/src/typeSchema';

import {stopPropagation} from '@adretail/basic-helpers/src/inputs/suppressEvent';

import toggleable from '@adretail/basic-decorators/src/base/toggleable';
import getFixedElementPos from '@adretail/basic-helpers/src/dom/getFixedElementPos';
import getElementSize from '@ding/core/src/helpers/ui/getElementSize';
import mountElementEvents from '@adretail/basic-helpers/src/dom/mountElementEvents';

import {
  isModalPortalVisible,
  isInsidePortal,
} from '@adretail/basic-components/src/BasicModal/ModalPortal';

import FadeAppear from '@adretail/basic-components/src/Anim/FadeAppear';
import RelativeContainer from '@ding/core/src/components/Predefined/RelativeContainer';
import PopoverBlurHolder from './PopoverBlurHolder';
import {getDirectionMargins, ARROW_SIZE} from './PopoverHolder';

const applyMarginOverflowCorrection = overflow => (style) => {
  if (!overflow)
    return style;

  if (overflow.left)
    style.marginRight = (Number.parseInt(style.marginRight, 10) || 0) - overflow.left;

  return style;
};

export default
@toggleable(false, true)
class PopoverPopupToggle extends React.PureComponent {
  static propTypes = {
    // for hints
    closeOnly: PropTypes.bool,

    toggleComponent: REACT_COMPONENT_CLASS_SCHEMA,
    toggleRenderFn: PropTypes.func,
    direction: DIRECTION_SCHEMA,

    innerClassName: PropTypes.string,
    popoverStyle: PropTypes.objectOf(PropTypes.any), // provide width to overflow check
    popoverProps: PROPS_SCHEMA,
    provideActiveFlagAs: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    watchEvent: PropTypes.string,
    blurOutsideClick: PropTypes.bool,
    relativeWrap: PropTypes.bool,
  }

  static defaultProps = {
    provideActiveFlagAs: 'toggle',
    watchEvent: 'onClick',
    blurOutsideClick: true,
    relativeWrap: true,
    direction: DIRECTION.TOP,
  };

  state = {
    resizeKey: 'resize-key',
    overflowMarginCorrection: null,
  };

  componentDidMount() {
    const {toggled} = this.props;

    if (toggled) {
      this.updateSize();
      this.forceUpdate();
      this.reassignResizeListeners();
    }
  }

  componentDidUpdate(prevProps) {
    const {toggled} = this.props;

    if (prevProps.toggled !== toggled) {
      this.reassignResizeListeners();

      if (!toggled)
        this.state.overflowMarginCorrection = null;
      else {
        this.updateSize();
        this.forceUpdate();
      }
    }
  }

  componentWillUnmount() {
    this.resizeListeners?.(); // eslint-disable-line no-unused-expressions
  }

  reassignResizeListeners() {
    const {toggled} = this.props;

    this.resizeListeners?.(); // eslint-disable-line no-unused-expressions
    if (toggled) {
      this.resizeListeners = mountElementEvents(
        null,
        {
          resize: this.rerenderPopup,
          orientationchange: this.rerenderPopup,
        },
        window,
      );
    }
  }

  ref = (element) => {
    this.element = element;
  }

  onToggle = (status) => {
    const {
      closeOnly,
      onClearToggle,
      onToggle,
    } = this.props;

    const toggleFn = (
      closeOnly
        ? onClearToggle
        : onToggle
    );

    this.updateSize();
    toggleFn(status);
  };

  onOutsideClick = (e) => {
    const {element} = this;
    const {onClearToggle} = this.props;
    const {target} = e;

    if (target === element || element.contains(target))
      return;

    // todo: fix it
    // react 16 not support stopPropagation() inside portal, for now
    // we have to detect it manually
    if (!isInsidePortal(target))
      onClearToggle();
    else {
      setTimeout(
        () => {
          if (!isModalPortalVisible())
            onClearToggle();
        },
        100,
      );
    }
  };

  rerenderPopup = () => {
    this.setState(
      {
        resizeKey: `resize-key-${Date.now()}`,
        overflowMarginCorrection: null,
      },
    );
  }

  updateSize() {
    this.toggleSize = getElementSize(
      false,
      ReactDOM.findDOMNode(this.element),
    );
  }

  popupOverflowFix = (popupRef) => {
    this.popupRef = popupRef;
    if (!popupRef)
      return;

    const node = ReactDOM.findDOMNode(popupRef);
    const {left} = getFixedElementPos(node);

    if (left < 0) {
      this.setState(
        {
          overflowMarginCorrection: {
            top: 0,
            left: Math.abs(left) + ARROW_SIZE,
          },
        },
      );
    } else {
      this.setState(
        {
          overflowMarginCorrection: false,
        },
      );
    }
  }

  render() {
    const {ref, toggleSize} = this;
    const {overflowMarginCorrection, resizeKey} = this.state;
    const {
      provideActiveFlagAs,
      children,
      toggled,
      className,
      popoverStyle,
      innerClassName,
      watchEvent,
      direction,
      dark,
      blurOutsideClick,
      relativeWrap,
      popoverProps,
      closeOnly,
      toggleRenderFn,
      toggleComponent: ToggleComponent,

      // decorators stuff
      onClearToggle,
      onToggle,
      ...props
    } = this.props;

    const visiblePopover = toggled && !!toggleSize;
    const popover = (
      <FadeAppear
        key={resizeKey}
        {...visiblePopover && overflowMarginCorrection === null && {
          appear: false,
          mountOnEnter: true,
        }}
        {...!visiblePopover && {
          in: false,
        }}
        {...overflowMarginCorrection !== null && visiblePopover && {
          in: true,
        }}
      >
        {style => (
          <PopoverBlurHolder
            {...popoverProps}
            {...{
              direction,
              dark,
            }}
            ref={this.popupOverflowFix}
            arrowStyle={(
              overflowMarginCorrection
                ? {
                  marginRight: (
                    overflowMarginCorrection.left
                      ? overflowMarginCorrection.left
                      : 0
                  ),
                  marginBottom: overflowMarginCorrection.bottom || 0,
                }
                : null
            )}
            style={
              applyMarginOverflowCorrection(overflowMarginCorrection)({
                ...overflowMarginCorrection === null && visiblePopover && {
                  visibility: 'hidden',
                },
                ...style,
                ...popoverProps?.style,
                ...toggleSize && getDirectionMargins(direction, toggleSize),
                ...popoverStyle,
              })
            }
            onOutsideClick={(
              blurOutsideClick
                ? this.onOutsideClick
                : R.T
            )}
            onClick={stopPropagation}
          >
            {children && children(onClearToggle)}
          </PopoverBlurHolder>
        )}
      </FadeAppear>
    );

    const toggle = (
      toggleRenderFn
        ? toggleRenderFn(
          props,
          {
            ref,
            popover,
            toggled,
            onToggle: () => this.onToggle(!visiblePopover),
          },
        )
        : (
          <ToggleComponent
            {...props}
            {...provideActiveFlagAs && {
              [provideActiveFlagAs]: toggled,
            }}
            {...!relativeWrap && {
              popover,
            }}
            {...{
              [watchEvent]: () => this.onToggle(!visiblePopover),
            }}
            ref={ref}
            className={innerClassName}
          />
        )
    );

    if (!relativeWrap)
      return toggle;

    return (
      <RelativeContainer
        className={className}
        style={{
          display: 'inline-flex',
        }}
      >
        {toggle}
        {popover}
      </RelativeContainer>
    );
  }
}
