export default {
  title: 'Blog',

  section: {
    title: 'Artykuły',
    header: 'Blog',
  },

  meta: {
    title: 'Najnowsze informacje o sklepach, promocjach i zakupach %{domain}',
    description: 'Aktualne informacje o promocjach, wyprzedażach i przecenach. Porady jak kupować i jak oszczędzać na zakupach. Znajdź okazje!',

    og_description: 'Wiadomości handlowe %{domain} - poznaj najnowsze wiadomości ze świata zakupów i promocji',

    category_title: '%{categoryName} - Poradnik zakupowy%{domainSuffix}',
    category_description: 'Promocje i porady zakupowe. Artykuły w kategorii: %{categoryName}. Sprawdź najnowsze informacje.',
  },

  filters: {
    article_name: {
      placeholder: 'Wyszukaj artykuł...',
    },
  },
};
