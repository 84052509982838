
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FavouriteListItem"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Favourite"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"kind"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"favouritable"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Contractor"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Contractor"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"customerFlags"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CustomerFlags"},"directives":[]}]}}]}}]}}]}}],"loc":{"start":0,"end":189}};
    doc.loc.source = {"body":"fragment FavouriteListItem on Favourite {\n  id\n  name\n  kind\n  favouritable {\n    ... on Contractor {\n      ...Contractor\n      customerFlags {\n        ...CustomerFlags\n      }\n    }\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
