import React from 'react';
import PropTypes from 'prop-types';

import selectResponseData from '@adretail/basic-helpers/src/getters/selectResponseData';
import {hasErrors} from '../helpers/pickResponseErrors';

import {TinyGqlContext} from './TinyGqlProvider';
import TinyGqlClient from '../client';

export default class TinyGqlMutation extends React.Component {
  static propTypes = {
    mutation: PropTypes.objectOf(PropTypes.any).isRequired,

    // selects data from response
    responseSelector: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
    ]),
  };

  state = {
    performsMutation: false,
    data: null,
    errors: null,
  };

  mounted = true;

  componentWillUnmount() {
    this.mounted = false;
  }

  renderMutationUtils = (client, utils) => {
    if (!client)
      throw new Error('Not provided client context!');

    const {
      performsMutation,
      data,
      errors,
    } = this.state;

    const {
      mutation,
      children,
      responseSelector,
    } = this.props;

    if (!children)
      return null;

    const mutate = async (content, headers = {}, silent = false) => {
      const updateState = this.mounted || silent;

      if (updateState) {
        this.setState(
          {
            performsMutation: true,
            data: null,
            errors: null,
          },
        );
      }

      try {
        const response = await client.mutate(
          mutation,
          content,
          headers,
        );

        const selectedData = (
          hasErrors(response)
            ? response
            : selectResponseData(responseSelector, response)
        );

        if (this.mounted && updateState) {
          this.setState(
            {
              performsMutation: false,
              data: selectedData,
            },
          );
        }

        return selectedData;
      } catch (e) {
        this.mounted && this.setState(
          {
            performsMutation: false,
            data: null,
            errors: e.gqlErrors || true,
          },
        );

        // rethrow for mutation() try catch
        throw e;
      }
    };

    return children(
      mutate,
      {
        performsMutation,
        data,
        errors,
        ...utils,
      },
    );
  }

  /**
   * Renders mutation with custom client
   *
   * @export
   */
  providedContextRender = customContext => this.renderMutationUtils(
    TinyGqlClient.from(customContext),
  );

  render() {
    const {clientContext} = this.props;
    if (clientContext)
      return this.providedContextRender(clientContext);

    return (
      <TinyGqlContext.Consumer>
        {({client, refetchQuery}) => this.renderMutationUtils(client, {refetchQuery})}
      </TinyGqlContext.Consumer>
    );
  }
}
