import React from 'react';
import * as R from 'ramda';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';

import {stopPropagation} from '@adretail/basic-helpers/src/inputs/suppressEvent';
import {reactFormat} from '@ding/core/src/helpers';

import FormRow from '@ding/control-groups/src/Form/FormRow';
import {TitledCheckbox} from '@ding/controls/src/';
import {WebsiteRegulationsLink} from '@ding/auth/src/client/Layout/Links/Law';

const RegisterAgreementGroup = ({l}) => {
  const t = useTranslate('website.auth.confirm');

  return (
    <>
      <FormRow>
        <TitledCheckbox
          {...l.input('age')}
          flexAlignToggle='flex-start'
          required
        >
          <div>
            {reactFormat(
              t('agree_age.content'),
              [
                <WebsiteRegulationsLink
                  key='privacy-link'
                  type='primary'
                  weight={600}
                  onClick={stopPropagation}
                >
                  {t('agree_age.ding_rules')}
                </WebsiteRegulationsLink>,
              ],
            )}
          </div>
        </TitledCheckbox>
      </FormRow>

      <FormRow>
        <TitledCheckbox
          {...l.input('rodo')}
          flexAlignToggle='flex-start'
        >
          {t('agree_rodo')}
        </TitledCheckbox>
      </FormRow>
    </>
  );
};

RegisterAgreementGroup.displayName = 'RegisterAgreementGroup';

export default R.compose(
  React.memo,
  linkedInputs(
    {
      groupName: 'value',
      singleValueProp: null,
    },
  ),
)(RegisterAgreementGroup);
