import React from 'react';
import PropTypes from 'prop-types';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {
  Flex,
  TextButton,
} from '@ding/core/src/components/Predefined';

const ExpandTextToolbarRow = ({
  remainItems, visibleChunksCount,
  buttonComponent: ButtonComponent,
  onLoadNextChunk, onResetChunks,
}) => {
  const t = useTranslate();

  return (
    <Flex.Row>
      {remainItems > 0 && (
        <ButtonComponent
          style={{
            margin: '0 auto',
          }}
          onClick={onLoadNextChunk}
        >
          {`${t('website.shared.titles.more')} (${remainItems})`}
        </ButtonComponent>
      )}

      {visibleChunksCount > 1 && (
        <TextButton onClick={onResetChunks}>
          {t('website.shared.titles.collapse')}
        </TextButton>
      )}
    </Flex.Row>
  );
};

ExpandTextToolbarRow.displayName = 'ExpandTextToolbarRow';

ExpandTextToolbarRow.propTypes = {
  buttonComponent: REACT_COMPONENT_CLASS_SCHEMA,

  visibleChunksCount: PropTypes.number,
  remainItems: PropTypes.number,

  onLoadNextChunk: PropTypes.func,
  onResetChunks: PropTypes.func,
};

ExpandTextToolbarRow.defaultProps = {
  buttonComponent: TextButton.Primary,
};

export const textToolbarFn = ({
  chunkInfo,
  ...props
}) => (
  <ExpandTextToolbarRow
    remainItems={chunkInfo.remainItems}
    {...props}
  />
);

export default ExpandTextToolbarRow;
