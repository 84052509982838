export default {
  meta: {
    discount_suffix: ' - %{}% TANIEJ!',
    title: '%{name}%{discountSuffix} - promocja %{contractorName}%{domainSuffix}',
    description: 'Promocja na: %{name} w sklepach sieci %{contractorName}. %{name} w super cenie - zobacz promocję i najbliższe sklepy! ',

    og: {
      title: '%{name}%{discountSuffix} - promocja %{contractorName}%{domainSuffix}',
      description: 'Kliknij i zobacz promocje!',
    },
  },
};
