export default {
  title: 'Strona główna',

  meta: {
    title: 'Lubisz zakupy? Nie chcesz przepłacać? Gazetki promocyjne zobacz !%{domainSuffix}',
    description: 'Gazetki promocyjne oglądaj i oszczędzaj. Aktualne gazetki, a w nich promocje z: Biedronka, Lidl, Netto, Kaufland, Tesco, Pepco, Auchan. Promocje w Twojej okolicy.',

    og: {
      title: '🔔 Lubisz zakupy? Szukasz promocji? Zobacz najnowsze oferty i gazetki promocyjne i listę zakupową DING.PL!',
      description: 'Kliknij po więcej!',
    },
  },
};
