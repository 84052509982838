import React from 'react';

import {URLModalTrigger} from '@ding/controls/src/ResponsiveModalToggle';
import SearchIcon from '@ding/core/src/icons/bottom-bar/Search';
import SearchModal from '@ding/search/src/containers/SearchModal';
import BottomBarButton from '../BottomBarButton';

const SearchItem = ({subtitle, onClick}) => (
  <URLModalTrigger
    provideToggleStateProps
    modalComponent={SearchModal}
    triggerRenderFn={({toggled, onToggle}) => (
      <BottomBarButton
        active={toggled}
        subtitle={subtitle.name}
        title={subtitle.title}
        onClick={() => {
          onToggle();
          onClick?.();
        }}
      >
        <SearchIcon size='medium' />
      </BottomBarButton>
    )}
  />
);

SearchItem.displayName = 'SearchItem';

export default SearchItem;
