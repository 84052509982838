import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Text from './Text';
import CornerIconHolder from './CornerIconHolder';

const IconText = ({
  icon, iconSpacing, iconOnRight,
  title, children,
  ...props
}) => {
  const defaultIconSpacing = R.defaultTo(
    props.size === 'small' ? 1 : 3,
    iconSpacing,
  );

  return (
    <Text {...props}>
      <CornerIconHolder
        iconSpacing={defaultIconSpacing}
        {...{
          icon,
          iconOnRight,
        }}
      >
        {title}
        {children}
      </CornerIconHolder>
    </Text>
  );
};

IconText.displayName = 'IconText';

IconText.propTypes = {
  title: PropTypes.node,
  icon: PropTypes.node,
  iconOnRight: PropTypes.bool,
  iconSpacing: PropTypes.number,
};

IconText.defaultProps = {
  iconOnRight: false,
};

export default IconText;
