import PropTypes from 'prop-types';
import styled from '@adretail/fast-stylesheet/src/react/styled';

const IFrame = styled(
  'iframe',
  {
    base: {
      margin: 0,
      padding: 0,
      border: 0,
      outline: 0,
    },

    expanded: {
      display: 'block',
      width: '100%',
      height: '100%',
    },
  },
  {
    omitProps: ['expanded'],
    classSelector: (classes, {expanded}) => expanded && classes.expanded,
  },
);

IFrame.displayName = 'IFrame';

IFrame.propTypes = {
  expanded: PropTypes.bool,
};

export default IFrame;
