import PropTypes from 'prop-types';

import {
  TOGGLE_BACKGROUND,
  TOGGLE_LIGHT_BACKGROUND,
  TOGGLE_NORMAL_BACKGROUND,
  TOGGLE_HOVER_BACKGROUND,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';

const CheckboxContainer = styled.span(
  {
    base: {
      position: 'relative',
      display: 'inline-block',
      flexShrink: 0,
      transition: 'all 400ms ease-in-out',
      cursor: 'pointer',

      '&:hover': {
        background: TOGGLE_HOVER_BACKGROUND,
      },
    },

    'fill-light': {
      background: TOGGLE_LIGHT_BACKGROUND,
    },

    'fill-normal': {
      background: TOGGLE_NORMAL_BACKGROUND,
    },

    'fill-dark': {
      background: TOGGLE_BACKGROUND,
    },
  },
  {
    index: 1,
    omitProps: ['light', 'parentProps'],
    classSelector: (classes, {fill}) => classes[`fill-${fill || 'dark'}`],
  },
);

CheckboxContainer.displayName = 'CheckboxContainer';

CheckboxContainer.propTypes = {
  fill: PropTypes.string,
};

export default CheckboxContainer;
