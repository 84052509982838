import React from 'react';
import {gql, GraphQLFragment} from '@ding/tiny-gql';

import DefaultTinyQuery from '@ding/control-groups/src/DefaultTinyQuery';

import {Grid, Text} from '@ding/core/src/components/Predefined';

import {ContractorLink} from '../../../Links';
import FooterColumn from '../FooterColumn';

const TOP_CONTRACTORS_QUERY = new GraphQLFragment(
  'topContractorsQuery',
  gql`
    query topContractorsQuery($limit: Int = 20) @locale(lang: ${APP_LANGUAGE}) {
      topContractors(limit: $limit) {
        id
        name
      }
    }
  `,
);

const ContractorsColumn = ({t, children}) => (
  <FooterColumn
    title={
      t('website.footer.headers.top_contractors')
    }
    aria-label={
      t('website.footer.headers.top_contractors')
    }
    role='group'
    md={6}
    lg={8}
  >
    {children}
    <DefaultTinyQuery
      query={TOP_CONTRACTORS_QUERY}
      responseSelector='topContractors'
      cacheKey={{
        expire: 86400, // 24h
      }}
    >
      {items => (
        <Grid>
          {items.map(
            contractor => (
              <Grid.TextColumn
                key={contractor.id}
                xs={6}
                lg={3}
                role='presentation'
              >
                <ContractorLink contractor={contractor}>
                  <Text block weight={700} truncate>
                    {contractor.name}
                  </Text>
                </ContractorLink>
              </Grid.TextColumn>
            ),
          )}
        </Grid>
      )}
    </DefaultTinyQuery>
  </FooterColumn>
);

ContractorsColumn.displayName = 'ContractorsColumn';

export default ContractorsColumn;
