import React from 'react';

import decodeUrl from '@adretail/basic-helpers/src/url/decodeUrl';
import {getHOCDisplayName} from '@adretail/basic-helpers/src/getters/getDisplayName';

const withDecodedLocationSearch = (Component) => {
  const Wrapped = (props) => {
    const {location} = props;
    const searchParams = location?.search ? decodeUrl(location.search) : {};

    return (
      <Component
        {...props}
        searchParams={searchParams}
      />
    );
  };

  Wrapped.displayName = getHOCDisplayName('withDecodedLocationSearch', Component);

  return Wrapped;
};

export default withDecodedLocationSearch;
