import React from 'react';
import * as R from 'ramda';

import {ADDRESS_SCHEMA} from '@ding/constants/src/typeSchema';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import {Street} from '@ding/core/src/components/Parts/Titles/Address';

const css = {
  base: {
    lineHeight: '15px',
    fontSize: 13,
  },
};

const GeolocationTitle = ({
  classes,
  address: {
    street,
    city,
    streetNo,
  },
}) => (
  <div className={classes.base}>
    <strong>
      {city?.name}
    </strong>

    {street && (
      <Street
        weight={400}
        block
        {...{
          street,
          streetNo,
        }}
      />
    )}
  </div>
);

GeolocationTitle.displayName = 'GeolocationTitle';

GeolocationTitle.propTypes = {
  address: ADDRESS_SCHEMA.isRequired,
};

export default R.compose(
  React.memo,
  injectClassesSheet(css),
)(GeolocationTitle);
