import * as R from 'ramda';

import {NOTIFICATIONS_FREQUENCY} from '@ding/account-settings/src/constants';
import removeNullValues from '@adretail/basic-helpers/src/base/removeNullValues';

const FREQUENCY_FIELDS_MAP = {
  [NOTIFICATIONS_FREQUENCY.NEVER]: R.always({
    never: true,
    emailDelivery: null,
  }),
};

/**
 * Transforms account query response settings to form value
 *
 * @param {Object} settings
 *
 * @returns {Object}
 */
const settingsToFormValue = (settings) => {
  if (!settings)
    return {};

  const {
    notificationsInterval,
    favouriteProductExpires,
    emailDelivery,
    ...triggers
  } = removeNullValues(settings);

  const frequencyMapper = notificationsInterval && FREQUENCY_FIELDS_MAP[notificationsInterval];

  // backend bugfix
  triggers.favouriteProductExpires = favouriteProductExpires || null;

  return {
    // interval
    frequency: {
      emailDelivery,
      ...frequencyMapper?.(settings),
    },

    // triggers
    triggers,
  };
};

export default settingsToFormValue;
