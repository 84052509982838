import React from 'react';

import {Margin} from '@ding/core/src/components/Predefined';
import {RecaptchaLinkRow} from '@ding/auth/src/client/Recaptcha';
import {
  SubmitFormRow,
  FormSubmitErrors,
} from '@ding/control-groups/src/Form';

import EnterPasswordsGroup from '@ding/auth/src/client/Inputs/EnterPasswordsGroup';
import SetPasswordButton from './SetPasswordButton';

const SetPasswordInputGroup = ({l, loading, errors}) => (
  <>
    <EnterPasswordsGroup {...l.input('passwords')} />

    <RecaptchaLinkRow />

    <FormSubmitErrors errors={errors} />

    <Margin
      top={5}
      block
    >
      <SubmitFormRow
        buttonComponent={SetPasswordButton}
        loading={loading}
      />
    </Margin>
  </>
);

SetPasswordInputGroup.displayName = 'SetPasswordInputGroup';

export default SetPasswordInputGroup;
