import React from 'react';

import {useTrackers} from '@ding/interia';

import TitledDivider from '@ding/core/src/components/Parts/TitledDivider';
import LocalizeMeButton from './LocalizeMeButton';
import LocalizeAutocomplete from './LocalizeAutocomplete';
import GeolocationInfo from './GeolocationInfo';

const GeolocationChangeModal = ({ua, onClose}) => {
  const {ga4} = useTrackers();

  return (
    <div
      {...!ua.mobile && {
        style: {
          minWidth: 320,
        },
      }}
    >
      <GeolocationInfo />
      <LocalizeMeButton
        expanded
        onLocalized={() => {
          ga4.localizeModalButton();
          onClose();
        }}
      />
      <TitledDivider.OR
        space='medium'
        style={{
          opacity: 0.5,
        }}
      />
      <LocalizeAutocomplete
        onLocalized={() => {
          ga4.localizeModalInput();
          onClose();
        }}
      />
    </div>
  );
};

GeolocationChangeModal.displayName = 'GeolocationChangeModal';

export default React.memo(GeolocationChangeModal);
