import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'Times',
  {
    viewBox: '0 0 20 20',
  },
  <polygon points='20 1.48 18.52 0 10 8.52 1.48 0 0 1.48 8.52 10 0 18.52 1.48 20 10 11.48 18.52 20 20 18.52 11.48 10 20 1.48' />,
);
