export const LEAFLETS_SIDEBAR_WIDTH = 320;
export const LEAFLETS_SIDEBAR_WIDTH_SMALL = 125;
export const LEAFLETS_SIDEBAR_WIDTH_BREAKPOINT = 1200;

export const CONTENT_TOOLBAR_HEIGHT = 60;

export const SLIDE_NAV_WIDTH = 75;

export const AUTOPLAY_BOX_HEIGHT = 100;

export const SKY_PLACEHOLDER_BOX = {
  width: 300,
  height: 632,
};

export const LABELED_BUTTON_SIZES = {
  SMALL: 0.030,
  MEDIUM: 0.055,
  LARGE: 0.070,
};

export const LABELED_BUTTON_RATIO = 1.38;
