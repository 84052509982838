import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import * as R from 'ramda';

import {buildUrl} from '@adretail/basic-helpers/src/url/encodeUrl';
import decodeUrl from '@adretail/basic-helpers/src/url/decodeUrl';
import renderFunctionalChildren from '@adretail/basic-helpers/src/ui/renderFunctionalChildren';

import createFunctionalWrapper from '../../../helpers/createFunctionalWrapper';

@withRouter
class GETParamsStateProvider extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    routeState: PropTypes.any,
    whiteListGETKeys: PropTypes.arrayOf(PropTypes.string),
    pathnameParse: PropTypes.func, // remove from page pagination number etc
    value: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.string),
      ]),
    ),
  };

  static defaultProps = {
    pathnameParse: R.identity,
    whiteListGETKeys: [],
  };

  componentDidUpdate({value: prevValue, disabled: prevDisabled}) {
    const {disabled, value} = this.props;

    if ((prevDisabled && !disabled)
        || (!disabled && !R.equals(value, prevValue)))
      this.updateURLState(value, prevValue);
  }

  updateURLState = (newValue) => {
    const {
      pathnameParse,
      history,
      routeState,
      whiteListGETKeys,
      location: {
        pathname,
        search,
      },
    } = this.props;

    const newPath = buildUrl(
      pathnameParse(pathname),
      R.compose(
        R.merge(R.__, newValue),
        R.pick(whiteListGETKeys),
        decodeUrl,
      )(search),
    );

    history.replace(newPath, routeState);
  }

  render() {
    const {children} = this.props;

    return renderFunctionalChildren(children, this.updateURLState);
  }
}

@withRouter
class GETParamsStateConsumer extends React.Component {
  state = {
    parameters: {},
    prevSearch: null,
  };

  static getDerivedStateFromProps({location}, {prevSearch}) {
    const {search} = location;
    try {
      if (search !== prevSearch) {
        return {
          prevSearch: search,
          parameters: decodeUrl(search),
        };
      }
    } catch (e) {
      if (!(e instanceof URIError)) {
        console.error(e);
      }
    }

    return null;
  }

  render() {
    const {children} = this.props;
    const {parameters} = this.state;

    return children(parameters);
  }
}

export const withGETParamsStateConsumer = createFunctionalWrapper(GETParamsStateConsumer, 'urlValue');

export default {
  Provider: GETParamsStateProvider,
  Consumer: GETParamsStateConsumer,
};
