import prefixWithRootAddress from '@ding/layout/src/components/Links/utils/prefixWithRootAddress';
import {genContractorsURL} from '@ding/layout/src/components/Links/ContractorsLink';
import {genArticlesURL} from '@ding/layout/src/components/Links/ArticlesLink';
import {genShoppingSundaysURL} from '@ding/layout/src/components/Links/ShoppingSundaysLink';
import {genProductsURL} from '@ding/layout/src/components/Links/ProductsLink';
import {genLeafletsURL} from '@ding/layout/src/components/Links/LeafletsLink';
import {genContactURL} from '@ding/layout/src/components/Links/ContactLink';

import {MOBILE_APP_DIRECT_URL} from '@ding/layout/src/components/Links/MobileAppLink';
import {PLACEMENT_BOTTOM_BAR_SELECTED_LEAFLETS} from '@ding/slide-viewer/src/Viewer/containers/Desktop/Sidebars/ViewerLeafletsSidebarContainer/isCorrectPlacementForGA4Tracking';

const ga4Tracking = {
  events: {
    pageView: ['page_view', '%{pageTitle}', '%{pageLocation}', '%{pagePath}'],
    menuClickLogo: ['menu_klik', 'logotyp_ding', prefixWithRootAddress('/')],
    menuClickLeaflets: ['menu_klik', 'gazetki', prefixWithRootAddress(genLeafletsURL())],
    menuClickProducts: ['menu_klik', 'produkty', prefixWithRootAddress(genProductsURL())],
    menuClickShops: ['menu_klik', 'sklepy', prefixWithRootAddress(genContractorsURL())],
    menuClickBlogs: ['menu_klik', 'blog', prefixWithRootAddress(genArticlesURL())],
    menuClickSundays: ['menu_klik', 'dni_handlu', prefixWithRootAddress(genShoppingSundaysURL())],

    // NOTE: In slide-viewer, menuClickShoppingList is reported as
    // event "viewer" with param1=lista_zakupowa_klik. GTM has mapping for this case
    menuClickShoppingList: ['menu_klik', 'lista_zakupowa'],
    menuClickNotifications: ['menu_klik', 'powiadomienia'],
    menuClickLocalization: ['menu_klik', 'lokalizacja'],
    menuClickPopover: ['menu_klik', 'menu'],
    menuClickContact: ['menu_klik', 'napisz_do_nas', prefixWithRootAddress(genContactURL())],
    menuClickDownloadApp: ['menu_klik', 'pobierz_apkę', MOBILE_APP_DIRECT_URL],

    menuClickDotModal: ['menu_klik', 'Wiecej'],
    menuClickDotModalLeaflets: ['menu_klik', 'Wiecej', 'gazetki'],
    menuClickDotModalProducts: ['menu_klik', 'Wiecej', 'oferty'],
    menuClickDotModalShops: ['menu_klik', 'Wiecej', 'sklepy'],
    menuClickDotModalBlogs: ['menu_klik', 'Wiecej', 'blog'],
    menuClickDotModalSundays: ['menu_klik', 'Wiecej', 'niedziele_handlowe'],
    menuClickDotModalContact: ['menu_klik', 'Wiecej', 'kontakt'],
    menuClickDotModalDownloadApp: ['menu_klik', 'Wiecej', 'aplikacja_mobilna'],

    localizeModalButton: ['menu_klik', 'lokalizacja', 'zlokalizuj'],
    localizeModalInput: ['menu_klik', 'lokalizacja', 'zmien_lokalizacje'],

    // NOTE: In IWA is one event viewerMenuItem for all items
    // but in GA4 number of arguments change beetwen items
    viewerMenuItemPagesClick: ['viewer_menu', 'lista_stron_klik', '%{contractorName}', '%{leafletId}'],
    viewerMenuItemStoresClick: ['viewer_menu', 'sklepy_najblizsze', '%{contractorName}'],
    viewerMenuItemLeafletsClick: ['viewer_menu', 'wiecej_gazetek', '%{contractorName}'],
    viewerMenuItemFullscreenClick: ['viewer_menu', 'fullscreen_klik', '%{contractorName}', '%{leafletId}'],
    viewerMenuItemShareClick: ['viewer_menu', 'udostepnij', '%{contractorName}', '%{leafletId}'],
    viewerMenuItemInfoClick: ['viewer_menu', 'info'],

    appendShoppingListViewerCutItem: ['viewer', 'spider_lista_zakupowa', '%{contractorName}', '%{leafletId}', '', 'wycinek'],
    appendShoppingListViewerItem: ['viewer', 'spider_lista_zakupowa', '%{contractorName}', '%{leafletId}', '', 'produkt'],
    sidebarScrollDepth: ['viewer', 'szpalta_scroll', '%{depth}'],
    clickSelectedForUserCard: ['viewer', 'szpalta_gazetka_klik', '%{contractorName}', '%{leafletId}', '%{mobile}'],
    realLeafletPV: ['viewer', 'rpv', '%{contractorName}', '%{id}'],
    partialLeafletPV: ['viewer', 'ppv', 'PV_czesciowe', '%{contractorName}', '%{id}'],
    toggleContractorNotifications: ga4 => ({contractorName, toggled}) => {
      ga4('viewer', 'obserwuj_klik', contractorName, toggled ? 'obserwuj' : 'odobserwuj');
    },

    openLeafletSpider: ['viewer', 'active_spider', '%{contractorName}', '%{leafletId}'],
    closeSpiderButton: ['viewer', 'spider_x', '%{contractorName}', '%{leafletId}'],
    clickInsertProductModule: ['viewer', 'spider_lista_zakupowa', '%{contractorName}', '%{leafletId}'],
    clickLeafletModule: ['viewer', 'spider_oferta', '%{contractorName}', '%{leafletId}', '%{url}'],
    clickLeafletProduct: ga4 => ({contractorName, leafletId, url}) => {
      ga4('viewer', 'spider_produkt_klik', contractorName, leafletId, '', url ? 'produkt' : 'wycinek');
    },

    selectedForUserMobileSliderShow: ['viewer', 'wiecej_gazetek_mobile'],
    longClickCutItem: ['viewer', 'do_listy_LPM_klik', '%{contractorName}', '%{leafletId}', '-', 'wycinek'],
    longClickItem: ['viewer', 'do_listy_LPM_klik', '%{contractorName}', '%{leafletId}', '%{productId}', 'produkt'],
    acceptNSFWModalProduct: ['viewer', 'odblokuj_klik', 'produkt', '%{productId}'],
    acceptNSFWModalLeaflet: ['viewer', 'odblokuj_klik', 'gazetka', '%{leafletId}'],

    // NOTE: Ding have also event "viewer_lista_klik", copy of "lista_klik" event
    // "viewer_lista_klik" was created to distinguish "lista_klik" events occurence in slide-viewer
    // Mapping from event name "lista_klik" to "viewer_lista_klik" is defined in GTM
    // if URL Path contains \/gazetka\/.+ then event name is mapped to viewer_lista_klik
    appendShoppingListBarItem: ['lista_klik', 'dodanie_tagu', '%{input}'],
    appendShoppingListProduct: ['lista_klik', 'do_listy_oferta_klik', '%{productId}', '%{productName}'],
    openShoppingListEdit: ['lista_klik', 'edycja', 'kredka'],
    saveShoppingListEdit: ['lista_klik', 'edycja', 'zapisz'],
    checkShoppingListBarItem: ['lista_klik', 'kupione', '%{productId}', '%{productName}'],
    removeAllDoneShoppingListBarItems: ['lista_klik', 'kupione', 'usun_wszystkie'],
    redirectShoppingListBarItem: ['lista_klik', 'oferta', '%{contractorName}', '%{leafletId}'],
    triggerShareShoppingList: ['lista_klik', 'share', 'e-mail'],
    openShoppingListTagMatch: ['lista_klik', 'podpowiedzi'],
    matchShoppingListProduct: ['lista_klik', 'do_listy_podpowiedzi_klik', '%{productId}', '%{productName}'],

    notificationsClickLeaflet: ['dzwonek', 'gazetka_klik', '%{contractorName}', '%{leafletId}'],
    notificationsClickProduct: ['dzwonek', 'produkt_klik', '%{productId}', '%{productName}'],
    notificationsChangeSection: ['dzwonek', 'przejscie', '%{from}', '%{to}'],
    notificationsGoToSettings: ['dzwonek', 'klik', 'ustawienia'],

    sortLeaflets: ['filtr_klik', 'gazetki', '%{name}'],
    sortContractors: ['filtr_klik', 'sieci', '%{name}'],
    sortProducts: ['produkty_klik', 'najnowsze', '%{name}'],
    openProductsFilter: ['filtr_klik', 'produkty', 'filtruj'],
    searchProducts: ['produkty_klik', 'szukaj', '%{phrase}'],
    openSearch: ['viewer', 'wyszukiwarka_klik'],
    openShoppingList: ['viewer', 'lista_zakupowa_klik'],

    viewerMenuShowClick: ['viewer_menu'],
    viewerMenuItemNotificationsClick: ga4 => ({contractorName, active}) => {
      ga4('viewer_menu', 'obserwuj_klik', contractorName, active ? 'obserwuj' : 'odobserwuj');
    },

    //leaflet last page
    redirectToNextLeaflet: ['viewer', 'licznik_redirect', '%{contractorName}', '%{leafletId}'],
    goToNextLeaflet: ['viewer', 'licznik_klik', '%{contractorName}', '%{leafletId}'],
    stayOnCurrentLeaflet: ['viewer', 'licznik_close'],
    clickLeafletInSlider: ['viewer', 'ostatnia_inne_klik', '%{contractorName}', '%{leafletId}'],
    selectedForUserMobileSliderClick: ga4 => ({contractorName, leafletId, placement}) => {
      ga4('viewer', 'szpalta_gazetka_klik', contractorName, leafletId, placement === PLACEMENT_BOTTOM_BAR_SELECTED_LEAFLETS ? 'okładka' : undefined);
    },

    articleLoaded: ['wyswietlenie_artykulu', '%{author}'],
  },
};

export default ga4Tracking;
