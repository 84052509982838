import * as R from 'ramda';
import removeNullValues from '@adretail/basic-helpers/src/base/removeNullValues';

export const cityToFiltersInput = R.unless(
  R.isNil,
  cityId => ({
    address: {
      cityId,
    },
  }),
);

const addressToFiltersInput = R.unless(
  R.isNil,
  ({city, street, streetNo, coords}) => removeNullValues(
    {
      geo: coords,
      address: {
        cityId: city?.id,
        streetId: street?.id,
        streetNo,
      },
    },
  ),
);

export default addressToFiltersInput;
