import * as R from 'ramda';
import PropTypes from 'prop-types';

import * as ITEM_TYPES from '../itemTypes';

export const ITEM_TYPE_SCHEMA = PropTypes.oneOf(
  R.values(ITEM_TYPES),
);

export const RATING_SCHEMA = PropTypes.shape(
  {
    avg: PropTypes.number,
    count: PropTypes.number,
  },
);

export const DIRECTION = {
  TOP: 'TOP',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',

  BOTTOM: 'BOTTOM',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',

  TOP_CENTER: 'TOP_CENTER',
  TOP_LEFT: 'TOP_LEFT',
};

export const DIRECTION_SCHEMA = PropTypes.oneOf(
  R.values(DIRECTION),
);

export const STYLE_SCHEMA = PropTypes.objectOf(PropTypes.any);

export const SIZE_ARRAY_SCHEMA = PropTypes.oneOf(
  [
    null,
    'parent',
    'none',
    'micro',
    'xs',
    'tiny-xs',
    'tiny',
    'tiny-small',
    'small',
    'medium-small',
    'medium',
    'big',
    'large',
    'huge',
    'auto',
  ],
);

export const ERRORS_LIST_SCHEMA = PropTypes.oneOfType(
  [
    PropTypes.arrayOf(
      PropTypes.shape(
        {
          message: PropTypes.any,
          code: PropTypes.any,
        },
      ),
    ), // messages from server
    PropTypes.bool, // crash without message
  ],
);

export const IMAGE_FIT_SCHEMA = PropTypes.oneOf(
  [
    'contain',
    'cover',
  ],
);

export const IMAGE_RESPONSIVE_DATA = PropTypes.arrayOf(
  PropTypes.shape(
    {
      breakpoint: PropTypes.number,
      frameClass: PropTypes.string,
    },
  ),
);

export const IMAGE_COMPONENT_PROPS = PropTypes.shape(
  {
    reload: PropTypes.bool,
    responsiveData: IMAGE_RESPONSIVE_DATA,
    imageClass: PropTypes.string,
  },
);

export const RANGE_SCHEMA = PropTypes.shape(
  {
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  },
);
