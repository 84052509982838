import * as R from 'ramda';

const pickFirstOperationResponseKey = (obj) => {
  const keys = R.keys(obj);

  return (
    keys.length === 1 && keys[0] !== 'errors'
      ? obj[keys[0]]
      : obj
  );
};

export default pickFirstOperationResponseKey;
