import SquareHolder from './SquareHolder';
import SwitchHolder from './SwitchHolder';
import BellHolder from './BellHolder';
import SquareOutlineHolder from './SquareOutlineHolder';
import DotOutlineCircleHolder from './DotOutlineCircleHolder';

export default {
  'dot-outline-circle': DotOutlineCircleHolder,
  square: SquareHolder,
  'square-outline': SquareOutlineHolder,
  switch: SwitchHolder,
  bell: BellHolder,
};
