import React from 'react';
import * as R from 'ramda';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import MarkerOutlineIcon from '@ding/core/src/icons/MarkerOutline';
import {Street} from '@ding/core/src/components/Parts/Titles/Address';
import {
  Divider,
  Text,
  Margin,
  IconText,
} from '@ding/core/src/components/Predefined';

import {useGeolocationAddress} from '../GeolocationContext';

const GeolocationInfo = () => {
  const t = useTranslate();
  const address = useGeolocationAddress();
  if (!address)
    return null;

  const addressTitle = (
    <div>
      {address.city?.name}
      &nbsp;
      <Text.Muted size='small'>
        {`(${address.province?.name})`}
      </Text.Muted>

      {address.street && (
        <Street
          type='muted'
          size='small'
          style={{
            marginTop: 3,
          }}
          block
          {...R.pick(['street', 'streetNo'], address)}
        />
      )}
    </div>
  );

  return (
    <>
      <Text
        size='tiny-small'
        weight={600}
        uppercase
        block
      >
        {t('website.geolocation.titles.browsing_place_offers')}
      </Text>

      <Margin top={4}>
        <IconText
          weight={600}
          size='medium'
          iconSpacing={2}
          icon={(
            <MarkerOutlineIcon />
          )}
        >
          {addressTitle}
        </IconText>
      </Margin>

      <Divider
        style={{
          margin: '20px 0 35px',
        }}
      />
    </>
  );
};

GeolocationInfo.displayName = 'GeolocationInfo';

export default React.memo(GeolocationInfo);
