import React, {useEffect} from 'react';
import * as R from 'ramda';

import geolocationEnv, {GEO_COOKIE} from '@ding/geolocation/src/constants/geolocationEnv';
import {ADDRESS_SCHEMA} from '@ding/constants/src/schemas/geo';

import createContextPack from '@ding/tiny-context-state';
import memoizeOne from '@adretail/basic-helpers/src/base/memoizeOne';

import {useCookies} from '@adretail/basic-components/src/Context/SSRCookiesProvider';
import {addressToFiltersInput} from '@ding/api/src/gql/geo/helpers';

import useBroadcastRouteMetadata from '@ding/window-api/src/hooks/useBroadcastRouteMetadata';

import {dropBlankAddressCoords} from '../../helpers';
import {
  encodeAddressCookie,
  decodeAddressCookie,
} from '../../helpers/cookieSerializer';

const getAddress = R.prop('address');

/**
 * @todo
 *  Set default localization to Warsaw?
 *
 * @see
 *  ADDRESS_SCHEMA
 */
const {
  Consumer,
  Provider,
  useStateContext,
} = createContextPack(
  {
    selectors: {
      getAddress: R.always(getAddress),

      // query filter input used by some queries
      getFilterAddressInput: () => memoizeOne(
        R.compose(
          addressToFiltersInput,
          getAddress,
        ),
      ),
    },
  },
);

/**
 * Hooks
 */
export const useGeolocationState = useStateContext;

export const useGeolocationAddress = () => useGeolocationState().getAddress();

export const useGeolocationAddressInput = () => useGeolocationState().getFilterAddressInput();

/**
 * Components
 */
const GeolocationRouteBroadcast = React.memo(() => {
  const broadcastGeolocation = useBroadcastRouteMetadata(null, 'Geolocation:Refresh');
  const address = useGeolocationAddress();

  useEffect(
    () => {
      broadcastGeolocation(
        {
          address,
        },
      );
    },
    [address],
  );

  return null;
});

export const GeolocationStateConsumer = Consumer;

export const GeolocationStateProvider = ({children, initialAddress}) => {
  const cookies = useCookies();
  const geolocalization = cookies.get(GEO_COOKIE.name)
    || encodeAddressCookie(geolocationEnv.defaultGeolocalization);

  return (
    <Provider
      initialState={{
        address: initialAddress || (
          geolocalization && decodeAddressCookie(geolocalization)
        ),
      }}
      actions={{
        setAddress: R.compose(
          address => (state) => {
            cookies.set(
              GEO_COOKIE.name,
              encodeAddressCookie(address),
              GEO_COOKIE,
            );

            return {
              ...state,
              address,
            };
          },
          dropBlankAddressCoords,
        ),
      }}
    >
      <GeolocationRouteBroadcast />
      {children}
    </Provider>
  );
};

GeolocationStateProvider.propTypes = {
  initialAddress: ADDRESS_SCHEMA,
};
