import React from 'react';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';

import {
  Divider,
  Grid,
} from '@ding/core/src/components/Predefined';

import {AsyncSpinnerForm} from '@ding/control-groups/src/Form';
import {BasicAgreementAuthGroup} from '@ding/auth/src/client/AuthModal/RegisterForm/RegisterBufferedControlGroup';

import * as RegisterControls from '@ding/auth/src/client/AuthModal/RegisterForm//Controls';

const RegisterFormDivider = styled(
  Divider,
  createBreakpointClasses({
    xs: {
      margin: '32px 0 20px',
    },

    md: {
      margin: '64px 0 25px',
    },
  }),
  {
    index: 3,
    voidTag: true,
  },
);

const OAuthRegisterFooter = React.memo(() => (
  <Grid>
    <Grid.Column xs={12} md={6}>
      <RegisterControls.ContactGroup />
    </Grid.Column>

    <Grid.Column xs={12} md={6}>
      <RegisterControls.LawUsefulLinks />
      <RegisterControls.CompanyIdentifiersRow />
    </Grid.Column>
  </Grid>
));

const OAuthRegisterForm = props => (
  <>
    <AsyncSpinnerForm {...props}>
      {loading => l => (
        <BasicAgreementAuthGroup
          {...{
            loading,
            l,
          }}
        />
      )}
    </AsyncSpinnerForm>

    <RegisterFormDivider />
    <OAuthRegisterFooter />
  </>
);

OAuthRegisterForm.displayName = 'OAuthRegisterForm';

export default React.memo(OAuthRegisterForm);
