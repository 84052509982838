import {createFunctionalWrapper} from '@ding/core/src/helpers';

const QUERY_PROPS = [
  'cacheKey', 'responseSelector',
  'variables', 'allowSSR', 'query',
];

const createGqlFunctionalWrapper = (FunctionalComponent, propName) => createFunctionalWrapper(
  FunctionalComponent,
  propName,
  QUERY_PROPS,
);

export default createGqlFunctionalWrapper;
