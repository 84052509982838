import styled from '@adretail/fast-stylesheet/src/react/styled';
import {PlainUnorderedList} from '../../Predefined';

const RedDotList = styled(
  PlainUnorderedList,
  {
    '& > li': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',

      '&::before': {
        content: '"•"',
        display: 'inline-block',
        position: 'relative',
        top: -3,
        width: '1em',
        flexShrink: 0,
        color: 'red',
        fontWeight: 'bold',
        fontSize: '20px',
      },
    },
  },
);

export default RedDotList;
