import React from 'react';

import {trackComponent} from '@ding/interia';
import {useTranslate} from '@ding/i18n';

import BellIcon from '@ding/core/src/icons/navbar/Alerts';
import {CounterIconButton} from '@ding/controls/src/';
import CustomerNotificationsCountQuery from '@ding/notifications/src/gql/CustomerNotificationsCountQuery';

import {HeaderItem} from '../../../HeaderToolbar';

const NotificationsItem = (
  {
    type, children, subtitle, innerRef,
    toggled, iconProps, margin,
    onClick,
  }) => {
  const t = useTranslate();
  const title = t('website.notifications_list.buttons.open_notifications_list');

  return (
    <CustomerNotificationsCountQuery alwaysRenderComponent>
      {count => (
        <HeaderItem
          margin={margin}
          innerRef={innerRef}
          subtitle={subtitle}
          onClick={onClick}
        >
          <CounterIconButton
            type={type || 'light'}
            inverted={toggled}
            icon={BellIcon}
            iconProps={{
              ...iconProps,
              size: 'big',
            }}
            count={count}
            title={title}
            aria-label={title}
            aria-haspopup
          />
          {children}
        </HeaderItem>
      )}
    </CustomerNotificationsCountQuery>
  );
};

/** TRACKING */
export default trackComponent(
  {
    onClick({iwa, ga4}) {
      iwa.triggerNotificationsList();
      ga4.menuClickNotifications();
    },
  },
)(NotificationsItem);
