import * as R from 'ramda';

import removeNullValues from '@adretail/basic-helpers/src/base/removeNullValues';
import {removeEmptySearchParams} from '@ding/core/src/helpers';

import {
  encodeCheckboxGETPairs,
  decodeCheckboxGETPairs,
} from '../../utils';

import createLinkComponent, {
  optionalLinkSegment,
  optionalLinkSearch,
} from '../../utils/createLinkComponent';

export const safeNumberSelector = R.compose(
  R.defaultTo(null),
  Number.parseFloat,
);

export const serializeProductsFilters = {
  toGETParams: R.unless(
    R.isNil,
    R.compose(
      removeEmptySearchParams,
      ({
        name,
        archivalOnly,
        contractors,
        categories,
        price,
      }) => ({
        name,
        archivalOnly: !!archivalOnly || null,

        priceMin: safeNumberSelector(price?.min),
        priceMax: safeNumberSelector(price?.max),
        contractors: contractors && encodeCheckboxGETPairs(contractors),
        categories: categories && encodeCheckboxGETPairs(categories),
      }),
    ),
  ),

  fromGETParams: R.unless(
    R.isNil,
    R.compose(
      removeEmptySearchParams,
      ({
        name,
        archivalOnly,
        contractors,
        categories,
        priceMin,
        priceMax,
      }) => ({
        name,

        ...archivalOnly && {
          archivalOnly: !!archivalOnly,
        },

        ...(!R.isNil(priceMin) || !R.isNil(priceMax)) && {
          price: removeNullValues({
            min: safeNumberSelector(priceMin),
            max: safeNumberSelector(priceMax),
          }),
        },

        contractors: contractors && decodeCheckboxGETPairs(contractors),
        categories: categories && decodeCheckboxGETPairs(categories),
      }),
    ),
  ),
};

export const PRODUCTS_URL_SCHEMA = '/oferty/:page?';

export const genProductsURL = ({filters, page} = {}) => (
  `/oferty${optionalLinkSegment(page)}${optionalLinkSearch(filters && serializeProductsFilters.toGETParams(filters))}`
);

export default createLinkComponent(
  [
    'page',
    'filters',
  ],
  (props, params) => genProductsURL(params),
);
