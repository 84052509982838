import * as R from 'ramda';

import {createObservable} from '@adretail/basic-helpers/src/async/observable';
import getRandomArrayItem from '@adretail/basic-helpers/src/getters/getRandomArrayItem';

const useRandomInsertVariant = (pageInsert) => {
  const variants = [
    R.omit(['variants'], pageInsert),
    ...(pageInsert.variants || []),
  ];

  return {
    ...getRandomArrayItem(variants),
    variants,
  };
};

const preprocessLeaflet = R.evolve(
  {
    pages: R.map(
      R.evolve(
        {
          inserts: R.when(R.is(Array), R.map(useRandomInsertVariant)),
        },
      ),
    ),
  },
);

/**
 * Creates blank state for browser
 *
 * @param {Object}  fields
 */
const createInitialState = ({
  ads,
  leaflet = null,
  dimensions = null,
  currentSlide = 0,
  flags,
  maxPagesPerSlide = 2,
}) => ({
  ads: ads || {},

  resources: {
    leaflet: preprocessLeaflet(leaflet),
  },

  ui: {
    $events: createObservable(
      {
        comparator: R.F,
      },
    ),
    changedPagesCount: 0,
    dimensions,

    flags: {
      withAutoplay: true,
      withProducts: true,
      unlockAutoplayAfterPageChange: false,
      allowCollapseButtons: true, // for embed
      embed: false,
      allowShoppingListSelecting: true,
      onFixedLayer: true,
      notifications: {
        controls: true,
      },
      ...flags,

      mobile: {
        pageMenu: true,
        viewerMenu: true,
        contractorNotification: true,
        pageIndex: true,
        selectedForUser: true,
        recommendedPage: true,
        ...flags?.mobile,
      },

      desktop: {
        sidebar: true,
        ...flags?.desktop,
      },

      menu: {
        dingoLogo: true,
        cart: true,
        search: true,
        contractor: true,
        moreLeaflets: true,
        share: true,
        pageIndex: true,
        geo: true,
        pages: true,
        info: true,
        ...flags?.menu,
      },
    },

    // used to scroll to section feature
    focusedSections: {
      browser: true,
      info: false,
    },

    zoom: null,
    dragging: false, // zoom is always null if dragging state changes
    modulesEnabled: true,

    zoomConfig: {
      min: 1,
      max: 4,
      step: 0.25,
      clickZoomMaxValue: 2.5,
      zoomToCenterOnClick: false,
      doubleClickZoom: true,
      showZoomCursor: false,
    },

    currentSlide: currentSlide || 0,
    pagesPerSlide: maxPagesPerSlide,
    maxPagesPerSlide,
  },
});

export default createInitialState;
