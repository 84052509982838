import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {RANGE_SCHEMA} from '@ding/constants/src/typeSchema';

import isBetween from '@adretail/basic-helpers/src/base/isBetween';
import inputValue from '@adretail/basic-helpers/src/inputs/inputValue';
import {isFloatString} from '@ding/core/src/helpers/number';

import ValidatedInput from '../ValidatedInput';

const fixCommaNotation = R.replace(',', '.');

const toFloatIfNumber = R.unless(
  R.anyPass(
    [
      R.complement(R.is)(String),
      R.test(/^(\d+)\.0?$/), // ends with . or .0
      R.equals('-'),
    ],
  ),
  Number.parseFloat,
);

const truncateToPrecision = precision => R.when(
  R.is(String),
  R.replace(
    new RegExp(`(\\.\\d{${precision}})\\d+`, 'g'),
    '$1',
  ),
);

const rangeValidator = range => R.both(
  isFloatString,
  isBetween(range),
);

const MinMaxValidatedInput = ({precision, range, onChange, ...props}) => (
  <ValidatedInput
    {...props}
    validatorFn={
      rangeValidator(range)
    }
    {...onChange && {
      onChange: R.compose(
        onChange,
        toFloatIfNumber,
        truncateToPrecision(precision),
        fixCommaNotation,
        inputValue,
      ),
    }}
  />
);

MinMaxValidatedInput.displayName = 'MinMaxValidatedInput';

MinMaxValidatedInput.propTypes = {
  range: RANGE_SCHEMA,
  precision: PropTypes.number,
};

MinMaxValidatedInput.defaultProps = {
  precision: 2, // 0.XX
  range: {
    min: 1,
    max: Infinity,
  },
};

export default MinMaxValidatedInput;
