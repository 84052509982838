import * as R from 'ramda';
import {MATCH_PAGE_REGEX} from './defaultLinkBuilder';

export const getPageIndexFromURL = R.compose(
  R.ifElse(
    R.both(
      R.is(Array),
      array => array.length > 0,
    ),
    R.pipe(R.nth(1), ::Number.parseInt),
    R.always(1),
  ),
  R.match(MATCH_PAGE_REGEX),
);

const defaultLinkDecoder = params => (
  +params.page || 1
);

export default defaultLinkDecoder;
