import React from 'react';
import PropTypes from 'prop-types';

import {ID_SCHEMA} from '@adretail/schemas';
import {
  LIGHT_CARD_GRAY,
  PRIMARY_FILL,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import flexCenter from '@ding/core/src/styles/flexCenter';
import textEllipsisOverflow from '@ding/core/src/styles/textEllipsisOverflow';

const Tab = styled(
  ({title, className, onClick}) => (
    <li
      {...{
        className,
        onClick,
      }}
    >
      {title}
    </li>
  ),
  {
    base: {
      extend: [flexCenter, textEllipsisOverflow],

      flex: 1,
      height: 55,
      padding: [[0, 25], '!important'],

      fontWeight: 700,
      fontSize: 17,
      textAlign: 'center',

      borderBottom: [3, 'solid', LIGHT_CARD_GRAY],
      cursor: 'pointer',

      transition: '250ms ease-in-out',
      transitionProperty: 'color, border-color',
    },

    active: {
      color: PRIMARY_FILL,
      borderBottomColor: PRIMARY_FILL,
    },

    small: {
      height: 45,
      fontSize: 15,
      flex: 'initial',
      justifyContent: 'flex-start',
    },
  },
  {
    omitProps: ['small'],
    classSelector: (classes, {active, small}) => [
      active && classes.active,
      small && classes.small,
    ],
  },
);

Tab.displayName = 'Tab';

Tab.propTypes = {
  id: ID_SCHEMA.isRequired,
  active: PropTypes.bool,
  small: PropTypes.bool,
};

export default Tab;
