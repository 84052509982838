import createLinkComponent from '../../utils/createLinkComponent';
import {
  optionalNamePairSegment,
  optionalLinkSegment,
} from '../../utils/optionalSegment';

// todo: Check why optional matching for categoryName group is not working
export const LEAFLETS_URL_SCHEMA = [
  '/gazetki-promocyjne/:categoryName,:categoryId(\\d+)/:page(\\d+)?',
  '/gazetki-promocyjne/:page(\\d+)?',
];

export const genLeafletsURL = ({page, category} = {}) => (
  `/gazetki-promocyjne${optionalNamePairSegment(category)}${optionalLinkSegment(page)}`
);

export default createLinkComponent(
  [
    'page',
    'category',
  ],
  (_, props) => genLeafletsURL(props),
);
