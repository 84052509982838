import React from 'react';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/schemas/basic';
import {
  REACT_COMPONENT_CLASS_SCHEMA,
  PROPS_SCHEMA,
} from '@adretail/schemas';

import {SeparatedListItem} from '@ding/core/src/components/Parts/Lists/SeparatedItemsList';

const PlainAutocompleteItem = ({
  size, item,
  active, children,
  highlight,
  linkProps,
  linkComponent: LinkComponent,
  onClick,
}) => {
  let content = children || item?.name;

  if (LinkComponent) {
    content = (
      <LinkComponent
        item={item}
        {...linkProps}
      >
        {content}
      </LinkComponent>
    );
  }

  return (
    <SeparatedListItem
      wrapContent={!LinkComponent}
      {...{
        size,
        active,
        highlight,
        onClick,
        disabled: item?.disabled,
      }}
    >
      {content}
    </SeparatedListItem>
  );
};

PlainAutocompleteItem.displayName = 'PlainAutocompleteItem';

PlainAutocompleteItem.propTypes = {
  linkComponent: REACT_COMPONENT_CLASS_SCHEMA,
  linkProps: PROPS_SCHEMA,
  size: SIZE_ARRAY_SCHEMA,
};

PlainAutocompleteItem.defaultProps = {
  size: 'medium',
};

export default PlainAutocompleteItem;
