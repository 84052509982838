import React from 'react';
import PropTypes from 'prop-types';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {
  Margin,
  Text,
} from '@ding/core/src/components/Predefined';

const DefaultErrorMessage = ({errorLog}) => {
  const t = useTranslate();

  return (
    <Margin left={4}>
      <Text.Primary size='small'>
        {errorLog.message || t('shared.errors.incorrect_field_value')}
      </Text.Primary>
    </Margin>
  );
};

DefaultErrorMessage.displayName = 'DefaultErrorMessage';

DefaultErrorMessage.propTypes = {
  errorLog: PropTypes.shape(
    {
      status: PropTypes.bool,
      message: PropTypes.string,
    },
  ).isRequired,
};

export default DefaultErrorMessage;
