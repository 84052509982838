import React from 'react';
import * as R from 'ramda';

const insertReactLineBreaks = R.compose(
  list => list.map(
    (text, index) => (
      index === list.length - 1
        ? text
        : (
          <React.Fragment key={text}>
            {text}
            <br />
          </React.Fragment>
        )
    ),
    list,
  ),
  R.split('\n'),
);

export default insertReactLineBreaks;
