import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

import {PROPS_SCHEMA} from '@ding/constants';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import useReactRouter from '@ding/core/src/hooks/useReactRouter';
import {useUA} from '@adretail/basic-components/src/Context/UAProvider';

import IdleRender from '@adretail/basic-components/src/IdleRender';
import {AdProvider} from '@ding/gpt';

import {PVTracker} from '@ding/interia';
import CategoryProductsStoriesSection from '@ding/story';

import Header from './Header';
import Footer from './Footer';
import LayoutHolder from './LayoutHolder';
import RootLayoutMetaTags from './RootLayoutMetaTags';
import IOSPinApp from './Popups/IOSPinApp';

import useLayoutSessionHandler from '../../hooks/useLayoutSessionHandler';
import useLayoutVisitsCounter from '../../hooks/useLayoutVisitsCounter';

import RedirectToI18nPathAfterLogin from '../Links/dynamic/RedirectToI18nPathAfterLogin';

const ContentHolder = styled.div(
  {
    minHeight: 'calc(100vh - 732px)',
  },
);

const Layout = ({
  savePathToSession, breadcrumbs,
  trackPV, children, className,
  adProviderProps, hideStories,
}) => {
  const ua = useUA();
  const {
    location,
    history,
  } = useReactRouter();

  const {pathname} = location;
  const resetScrollFlag = location?.state?.resetScroll;
  const {incrementVisits} = useLayoutVisitsCounter();

  const resetScroll = () => {
    if (history.action !== 'POP' && !document.location.hash)
      window.scrollTo(0, 0);
  };

  useEffect(
    () => {
      // used in back action on some grid views with Android like history
      // see mobile leaflets browser
      if (history.action === 'POP' || resetScrollFlag !== false)
        resetScroll();
    },
    [],
  );

  useEffect(
    () => {
      if (resetScrollFlag !== false)
        resetScroll();

      incrementVisits();
    },
    [
      !!resetScroll,
      pathname,
    ],
  );

  useLayoutSessionHandler(
    {
      savePathToSession,
    },
  );

  return (
    <AdProvider {...adProviderProps}>
      <Helmet>
        <meta name='robots' content='index, follow' />
      </Helmet>
      {trackPV && (
        <PVTracker />
      )}

      <RedirectToI18nPathAfterLogin />
      <RootLayoutMetaTags />

      <Header />

      <LayoutHolder
        // NOTE: id in layout holder is for easier analyzing DOM selectors in kibana dashboard
        id={location.pathname}
        className={className}
      >
        <ContentHolder>
          {!hideStories && (
            <CategoryProductsStoriesSection />
          )}
          {children}
        </ContentHolder>
      </LayoutHolder>

      <IdleRender>
        <Footer breadcrumbs={breadcrumbs} />
      </IdleRender>

      {/* // TODO: DEV: browser.safari don't work because of varnish cache' */}
      {ua.mobile && ua.browser?.safari && (
        <IOSPinApp />
      )}
    </AdProvider>
  );
};

Layout.displayName = 'Layout';

Layout.propTypes = {
  adProviderProps: PROPS_SCHEMA,
  breadcrumbs: PropTypes.node,
  trackPV: PropTypes.bool,
  savePathToSession: PropTypes.bool, // it is used in android style back buttons
};

Layout.defaultProps = {
  savePathToSession: true,
  trackPV: true,
};

export default Layout;
