import * as R from 'ramda';
import createContextPack from '@ding/tiny-context-state';

const createFavouriteItemKey = ({kind, name}) => `${kind}-${name}`;

/**
 * @see
 *  TinyGQLClient is not supporting Rails style subscription
 *  and splitting queries into single items types, it also caches
 *  in front(and server) side items, use context to update all components
 *  without sockets and refetches
 */
const {
  Consumer,
  Provider,
  useStateContext,
} = createContextPack(
  {
    initialState: {},
    selectors: {
      isFavouriteItem: () => (state, item) => R.propOr(null, createFavouriteItemKey(item), state),
    },
    actions: {
      setFavouriteItemState: (item, value) => state => ({
        ...state,
        [createFavouriteItemKey(item)]: value,
      }),
    },
  },
);

export const useTempFavouritesState = useStateContext;

export const TempFavouritesStateConsumer = Consumer;

export const TempFavouritesStateProvider = Provider;
