import React from 'react';
import PropTypes from 'prop-types';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import GeolocalizeIcon from '@ding/core/src/icons/Geolocalize';
import {AsyncSpinnerButton} from '@ding/controls/src/';

import useLocalizeCallback from './hooks/useLocalizeCallback';

/**
 * @export
 */
const LocalizeMeButton = ({onLocalized, ...props}) => {
  const t = useTranslate();
  const [requestLocalize, {loading}] = useLocalizeCallback();

  return (
    <AsyncSpinnerButton
      type='primary'
      style={{
        padding: '0 32px',
      }}
      {...props}
      loading={loading}
      onClick={
        async () => {
          await requestLocalize(); // automatic
          onLocalized?.(); // eslint-disable-line
        }
      }
    >
      <GeolocalizeIcon
        size='medium-small'
        type={null}
        style={{
          position: 'absolute',
          left: 10,
          top: 12,
        }}
      />
      {t('website.geolocation.titles.localize_me')}
    </AsyncSpinnerButton>
  );
};

LocalizeMeButton.displayName = 'LocalizeMeButton';

LocalizeMeButton.propTypes = {
  onLocalized: PropTypes.func,
};

export default React.memo(LocalizeMeButton);
