import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import SetNewPasswordSection from '../SetNewPasswordSection';
import SetPasswordForm from './Form';

const SetPasswordSection = (props) => {
  const t = useTranslate();

  return (
    <SetNewPasswordSection
      formTitle={
        t('website.auth.titles.set_password')
      }
    >
      <SetPasswordForm {...props} />
    </SetNewPasswordSection>
  );
};

SetPasswordSection.displayName = 'SetPasswordSection';

export default SetPasswordSection;
