import React from 'react';
import PropTypes from 'prop-types';

import TinyGqlQuery from '../TinyGqlQuery';

const withGqlQuery = (
  defaultQuery,
  {
    defaultResponseSelector,
    as = 'queryData',
    renderPlaceholdersToChildren = false,
    ...params
  } = {},
) => Component => (
  class Wrapped extends React.Component {
    static renderChildren(props, data, loaderParams) {
      return (
        <Component
          {...props}
          loaderParams={loaderParams}
          {...{
            [as]: data,
          }}
        >
          {props.children}
          {loaderParams?.placeholder || null}
        </Component>
      );
    }

    static propTypes = {
      responseSelector: PropTypes.func,
      query: PropTypes.any,
    };

    render() {
      const {
        query, responseSelector, allowSSR,
        variables, cacheKey, ...props
      } = this.props;

      return (
        <TinyGqlQuery
          query={
            query || defaultQuery
          }
          responseSelector={
            responseSelector || defaultResponseSelector
          }
          alwaysRenderComponent={
            renderPlaceholdersToChildren
          }
          {...{
            allowSSR,
            variables,
            cacheKey,
          }}
          {...params}
        >
          {(...args) => Wrapped.renderChildren(props, ...args)}
        </TinyGqlQuery>
      );
    }
  }
);

export default withGqlQuery;
