import {useEffect, useState} from 'react';
import * as R from 'ramda';

import ssr from '@adretail/basic-helpers/src/base/ssr';
import * as idleCallback from '@adretail/basic-helpers/src/async/idleCallback';

import {useCookies} from '@adretail/basic-components/src/Context/SSRCookiesProvider';
import cookieEnv from '@ding/constants/src/envCookieConfig';

import createAdblockBait from '../helpers/createAdblockBait';

/**
 * @see
 *  Resolves queries in reverse mode!
 *  useEffect is called from child to parent!
 *
 * - In SSR adblock status is always null
 * - If promise resolves function returns cached status value
 *   instead of Promise
 */
export const getAdblockStatus = R.memoizeWith(
  JSON.stringify,
  (allowedAdblockers) => {
    if (ssr)
      return R.always(null);

    const adblockBait = createAdblockBait(allowedAdblockers);
    const data = {
      status: null,
      queue: [],
    };

    adblockBait.then(
      (newStatus) => {
        const {queue} = data;

        data.status = newStatus;
        data.queue = null;
        for (let i = queue.length - 1; i >= 0; --i)
          queue[i](newStatus);
      },
    );

    return (_fn) => {
      const queueFn = (fn) => {
        if (!data.queue)
          fn(data.status);
        else
          data.queue.push(fn);
      };

      if (_fn)
        return queueFn(_fn);

      if (data.status !== null || !data.queue)
        return data.status;

      return queueFn;
    };
  },
);

// initial adblock check for non adblock plus
if (!ssr)
  idleCallback.create(() => getAdblockStatus());

/**
 * @param {Object}  whitelist Adblockers ignored during check,
 *                            if detect one of them, returns
 *                            false in detect
 *
 * @export
 */
const useAdblockCheck = (whitelist = {adblockPlus: true}) => {
  const baitResult = getAdblockStatus(whitelist)();
  const [status, setStatus] = useState(
    // if returned promise returns null
    // otherwise, rturns false / true
    R.is(Function, baitResult)
      ? null
      : baitResult,
  );

  useEffect(
    () => {
      if (status !== null)
        return;

      baitResult(setStatus);
    },
    [],
  );

  return status;
};

export const useLazyAdblockCheck = () => {
  const cookies = useCookies();
  const [result] = useState(
    () => {
      const cookie = cookies.get(cookieEnv.adblockDetect.name);
      if (R.isNil(cookie))
        return null;

      return cookie === '1';
    },
  );

  useEffect(
    () => {
      try {
        getAdblockStatus()((status) => {
          cookies.set(
            cookieEnv.adblockDetect.name,
            +(status?.detected || false),
            cookieEnv.adblockDetect,
          );
        });
      } catch (e) {
        console.error(e);
      }
    },
    [],
  );

  return !!result;
};

export default useAdblockCheck;
