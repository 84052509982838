import React from 'react';
import PropTypes from 'prop-types';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

/**
 * SEE: adretail/cdd-stores/app/models/concerns/working_hours.rb#L28
 */
const formatTime = (t, [from, to]) => {
  if (
    from === '00:00'
    && to === '00:00'
  )
    return t('website.shared.date.closed');

  if (from === to)
    return t('website.shared.date.no_data');

  if (
    from === '00:00'
    && to === '23:59'
  )
    return t('website.shared.date.twenty_four');

  if (
    from !== '00:00'
    && to === '00:00'
  )
    return `${from} - 24:00`;

  return `${from} - ${to}`;
};

const toTimeArray = time => (
  Array.isArray(time)
    ? time
    : [
      time.from,
      time.to,
    ]
);

const TimeTitle = ({className, time}) => {
  const t = useTranslate();

  return (
    <div
      {...{
        className,
      }}
    >
      {
        formatTime(
          t,
          toTimeArray(time),
        )
      }
    </div>
  );
};

TimeTitle.propTypes = {
  className: PropTypes.string,
  time: PropTypes.oneOfType([ // [from ,to] || {from, to}
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    }),
  ]),
};

export default TimeTitle;
