import PropTypes from 'prop-types';
import c from 'classnames';
import styled from '@adretail/fast-stylesheet/src/react/styled';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/typeSchema';
import * as Colors from '@ding/constants/src/colorSchema';

const Badge = styled.div(
  {
    base: {
      display: 'inline-block',
      padding: [10, 15],
      borderRadius: 20,
      fontWeight: 600,
      textTransform: 'uppercase',
    },

    small: {
      fontSize: 10,
    },

    medium: {
      fontSize: 12,
    },

    dark: {
      color: Colors.WHITE,
      backgroundColor: Colors.DARK,
    },

    gray: {
      color: Colors.WHITE,
      backgroundColor: Colors.MUTED_GRAY,
    },
  },
  {
    omitProps: [
      'size',
      'type',
    ],
    classSelector: (classes, {size, type}) => c(
      size && classes[size],
      type && classes[type],
    ),
  },
);

Badge.displayName = 'Ribbon';

Badge.propTypes = {
  size: SIZE_ARRAY_SCHEMA,
  type: PropTypes.oneOf([
    'dark',
    'gray',
  ]),
};

Badge.defaultProps = {
  size: 'small',
  type: 'dark',
};

export default Badge;
