import React from 'react';
import {gql, GraphQLFragment} from '@ding/tiny-gql';

import createAuthorizationHeaders from '@ding/auth/src/helpers/createAuthorizationHeaders';

import TinyGqlMutation from '@ding/tiny-gql/src/react/TinyGqlMutation';
import {useAuthState} from '../../client/AuthContext';

const RESET_ACCOUNT_PASSWORD_MUTATION = new GraphQLFragment(
  'resetPasswordCustomerMutation',
  gql`
    mutation resetPasswordCustomerMutation(
      $token: String!,
      $recaptcha: Recaptcha!,
    ) {
      resetPasswordCustomer(input: {
        resetPasswordToken: $token,
        recaptcha: $recaptcha,
      }) {
        errors
      }
    }
  `,
);

const ConfirmResetPasswordMutation = ({children}) => {
  const authState = useAuthState();

  return (
    <TinyGqlMutation mutation={RESET_ACCOUNT_PASSWORD_MUTATION}>
      {(mutate, utils) => {
        const confirm = ({recaptcha, resetPasswordToken, password}) => (
          mutate(
            {
              token: resetPasswordToken,
              recaptcha,
            },
            createAuthorizationHeaders(
              authState.getToken(),
              {
                password,
              },
            ),
          )
        );

        return children(
          {
            ...utils,
            confirm,
          },
        );
      }}
    </TinyGqlMutation>
  );
};

ConfirmResetPasswordMutation.displayName = 'ConfirmResetPasswordMutation';

export default ConfirmResetPasswordMutation;
