import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';
import NextGenPicture from '@ding/core/src/components/Parts/Images/NextGenPicture';

const sizeStyles = {
  'size-big': {
    paddingBottom: '141.42%',
  },

  'size-medium': {
    paddingBottom: '125.42%',
  },

  'size-small': {
    paddingBottom: '119.42%',
  },

  'size-tiny': {
    paddingBottom: '115.47%',
  },

  'size-square-small': {
    paddingBottom: '111.43%',
  },
};

const css = {
  base: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    border: '1px solid rgba(255, 255, 255, 0.16)',
    verticalAlign: 'text-bottom',

    '& > picture img': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'top',
    },
  },

  ...sizeStyles,

  'size-auto': createBreakpointClasses(
    {
      xs: {
        extend: sizeStyles['size-small'],
      },

      md: {
        extend: sizeStyles['size-big'],
      },
    },
  ),
};

const LeafletCover = ({
  classes, className, children,
  size, src, alt, ...props
}) => (
  <span
    className={c(
      className,
      classes.base,
      classes[`size-${size}`],
    )}
  >
    <NextGenPicture
      src={src || undefined}
      alt={alt}
      loading='lazy'
      {...props}
    />
    {children}
  </span>
);

LeafletCover.displayName = 'LeafletCover';

LeafletCover.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

export default injectClassesSheet(css, {index: 1})(LeafletCover);
