const getElementSize = (outer, element) => {
  if (!element)
    return null;

  const rect = element.getBoundingClientRect();
  const size = {
    width: rect.width,
    height: rect.height,
  };

  if (outer) {
    const computed = window.getComputedStyle(element);

    size.width += Number.parseFloat(computed.marginLeft) + Number.parseFloat(computed.marginRight);
    size.height += Number.parseFloat(computed.marginTop) + Number.parseFloat(computed.marginBottom);
  }

  return size;
};

export const getElementPadding = (element) => {
  const computedParentStyle = window.getComputedStyle(element);

  const top = Number.parseInt(computedParentStyle.paddingTop, 10);
  const bottom = Number.parseInt(computedParentStyle.paddingBottom, 10);

  const left = Number.parseInt(computedParentStyle.paddingLeft, 10);
  const right = Number.parseInt(computedParentStyle.paddingRight, 10);

  return {
    left,
    right,
    top,
    bottom,
    x: left + right,
    y: top + bottom,
  };
};

export default getElementSize;
