import PropTypes from 'prop-types';
import styled from '@adretail/fast-stylesheet/src/react/styled';

import {
  PRIMARY_FILL,
  GRASS_GREEN,
  CARD_GRAY,
  WHITE,
} from '@ding/constants/src/colorSchema';

import {Flex} from '../Predefined';

const RoundedBox = styled(
  Flex.Centered,
  {
    base: {
      overflow: 'hidden',
      borderRadius: 7,
      flexShrink: 0,
    },

    'size-small': {
      width: 30,
      height: 30,
    },

    'size-medium': {
      width: 40,
      height: 40,
    },

    'size-medium-big': {
      width: 50,
      height: 50,
    },

    'size-big': {
      width: 50,
      height: 50,
    },

    'size-huge': {
      width: 60,
      height: 60,
    },

    white: {
      backgroundColor: WHITE,
    },

    gray: {
      backgroundColor: CARD_GRAY,
    },

    green: {
      backgroundColor: GRASS_GREEN,
    },

    red: {
      backgroundColor: PRIMARY_FILL,
      color: WHITE,
    },
  },
  {
    omitProps: ['color', 'size'],
    classSelector: (classes, {color, size}) => [
      color && classes[color],
      classes[`size-${size || 'medium'}`],
    ],
  },
);

RoundedBox.displayName = 'RoundedBox';

RoundedBox.propTypes = {
  size: PropTypes.string,
  color: PropTypes.oneOf([
    'white',
    'gray',
    'green',
    'red',
  ]),
};

RoundedBox.defaultProps = {
  color: 'gray',
};

export default RoundedBox;
