import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/schemas/basic';
import * as Colors from '@ding/constants/src/colorSchema';

import {textShadow} from '../../styles/zDepth';
import * as Flips from '../../styles/flip';

export const ICON_SIZE_ARRAY = {
  xs: {
    width: 10,
    height: 10,
  },

  'tiny-xs': {
    width: 12.5,
    height: 12.5,
  },

  tiny: {
    width: 15,
    height: 15,
  },

  small: {
    width: 18,
    height: 18,
  },

  'medium-small': {
    width: 22,
    height: 22,
  },

  medium: {
    width: 26,
    height: 26,
  },

  big: {
    width: 32,
    height: 32,
  },

  large: {
    width: 48,
    height: 48,
  },

  huge: {
    width: 54,
    height: 54,
  },
};

const css = {
  ...Flips,

  base: {
    display: 'inline-block',
    userSelect: 'none',
    fontSize: '1em',
    overflow: 'hidden',
    flexShrink: 0,
    color: 'inherit',
    transition: 'transform 250ms ease-in-out',
    textAlign: 'center',
    maxHeight: '100%',

    '& > svg': {
      width: 'auto',
      maxWidth: '100%',
      height: 'inherit',
    },
  },

  parent: {
    width: 'inherit',
    height: 'inherit',
  },

  shadow: {
    extend: textShadow,
    overflow: 'visible',
  },

  ...ICON_SIZE_ARRAY,

  inherit: {
    color: 'inherit',
  },

  primary: {
    color: Colors.PRIMARY_FILL,
  },

  dark: {
    color: Colors.DARK,
  },

  muted: {
    color: Colors.MUTED_GRAY,
  },

  light: {
    color: Colors.WHITE,
  },
};

/**
 * Abstract icon wrapper
 *
 * @export
 */
const Icon = styled(
  'i',
  css,
  {
    omitProps: [
      'size',
      'type',
      'verticalFlip',
      'horizontalFlip',
      'shadow',
    ],
    classSelector: (classes, {shadow, verticalFlip, horizontalFlip, size, type}) => c(
      classes[size || 'small'],
      verticalFlip && classes.verticalFlip,
      horizontalFlip && classes.horizontalFlip,
      type && classes[type],
      shadow && classes.shadow,
    ),
  },
);

Icon.displayName = 'Icon';

Icon.propTypes = {
  shadow: PropTypes.bool,
  size: SIZE_ARRAY_SCHEMA,
  type: PropTypes.oneOf([
    null,
    'primary',
    'dark',
    'light',
    'muted',
    'inherit',
  ]),
};

Icon.defaultProps = {
  type: 'dark',
};

/**
 * Icon that displays wrapped path inside svg tag
 *
 * @export
 */
export const SvgIcon = ({
  children, viewBox, fill, scale, stroke,
  strokeWidth, strokeLinecap, strokeLinejoin,
  ...props
}) => (
  <Icon {...props}>
    <svg
      {...scale && {
        style: {
          transform: `scale(${scale}, ${scale})`,
        },
      }}
      {...{
        fill,
        stroke,
        viewBox,
        strokeWidth,
        strokeLinecap,
        strokeLinejoin,
      }}
    >
      {children}
    </svg>
  </Icon>
);

SvgIcon.displayName = 'SvgIcon';

SvgIcon.propTypes = {
  scale: PropTypes.number,
  viewBox: PropTypes.string,
  fill: PropTypes.string,
};

SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  fill: 'currentcolor',
};

export default React.memo(Icon);
