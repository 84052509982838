import React from 'react';

import ShoppingListIcon from '@ding/core/src/icons/bottom-bar/ShoppingList';
import BottomBarButton from '../BottomBarButton';
import {withShoppingListBarTrigger} from '../../../Desktop/Items/Products';

const ShoppingListItem = ({subtitle, toggled, onClick}) => (
  <BottomBarButton
    active={toggled}
    subtitle={subtitle}
    title={subtitle}
    onClick={() => onClick()}
  >
    <ShoppingListIcon size='medium' />
  </BottomBarButton>
);

ShoppingListItem.displayName = 'ShoppingListItem';

export default withShoppingListBarTrigger(
  {
    toggleProps: {},
  },
)(ShoppingListItem);
