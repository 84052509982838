import Loadable from 'react-loadable';
import LoadingSpinnerErrorHandling from '@ding/core/src/components/Parts/LoadingSpinnerErrorHandling';

const ShoppingListSidebar = Loadable(
  {
    loader: () => import(/* webpackChunkName: "shopping-list" */ './containers/ShoppingListSidebar'),
    loading: LoadingSpinnerErrorHandling,
  },
);

export default ShoppingListSidebar;
