import styled from '@adretail/fast-stylesheet/src/react/styled';

export default {
  H1: styled(
    'h1',
    {
      fontSize: 27,
      margin: '15px 0 35px',
    },
  ),
  H2: styled(
    'h2',
    {
      fontSize: 23,
      lineHeight: '32px',
    },
  ),
  H3: styled('h3', {fontSize: 20}),
  H4: styled(
    'h4',
    {
      fontSize: 18,
      margin: '5px 0',
    },
  ),
  H5: styled(
    'h5',
    {
      fontSize: 15,
      fontWeight: 500,
      margin: 0,
    },
  ),
};
