import React from 'react';

import {
  WHITE,
  PRIMARY_FILL,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {useAuthState} from '@ding/auth/src/client/AuthContext';
import {useTranslate} from '@ding/i18n';

import UserIcon from '@ding/core/src/icons/navbar/Profile';
import {IconButton} from '@ding/controls/src/';
import {SeparatedListItem} from '@ding/core/src/components/Parts/Lists/SeparatedItemsList';

import {AuthConditionalRender} from '@ding/auth/src/client';
import {PrivacyLink, AccountSettingsLink, FavouritesLink} from '@ding/layout/src/components/Links';
import * as AuthButtons from '@ding/auth/src/client/Buttons';

import {HeaderItem} from '../../HeaderToolbar';
import HeaderListPopover from './HeaderListPopover';

const AuthPopoverList = React.memo(() => {
  const privacyButton = (
    <SeparatedListItem wrapContent={false} role='menuitem'>
      <PrivacyLink />
    </SeparatedListItem>
  );

  const favouritesButton = (
    <SeparatedListItem wrapContent={false} role='menuitem'>
      <FavouritesLink />
    </SeparatedListItem>
  );

  return (
    <AuthConditionalRender
      key='logged-menu'
      loggedOnly
      authorizedRenderFn={
        () => (
          <>
            <SeparatedListItem wrapContent={false} role='menuitem'>
              <AccountSettingsLink />
            </SeparatedListItem>

            {favouritesButton}
            {privacyButton}

            <SeparatedListItem wrapContent={false} role='menuitem'>
              <AuthButtons.Logout />
            </SeparatedListItem>
          </>
        )
      }
      unauthorizedRenderFn={
        () => (
          <>
            {favouritesButton}
            <AuthButtons.Login triggerComponent={SeparatedListItem} role='menuitem' />
            <AuthButtons.Register triggerComponent={SeparatedListItem} role='menuitem' />
            {privacyButton}
          </>
        )
      }
    />
  );
});

const CircleFilledIconButton = styled(
  IconButton,
  {
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 36,
      height: 36,
      background: WHITE,
      borderRadius: '100%',
      zIndex: 0,
    },

    '& > i': {
      position: 'relative',
      color: PRIMARY_FILL,
      zIndex: 1,
    },
  },
  {
    index: 5,
  },
);

const AuthItem = ({margin, subtitle, iconProps, popoverDirection}) => {
  const {isLogged} = useAuthState();
  const t = useTranslate();
  const title = t('website.auth.buttons.open_auth_menu');

  return (
    <HeaderItem
      margin={margin}
      subtitle={subtitle}
    >
      <HeaderListPopover
        toggleComponent={(
          isLogged()
            ? CircleFilledIconButton
            : IconButton
        )}
        type='light'
        icon={UserIcon}
        iconProps={{
          size: 'big',
          ...iconProps,
        }}
        direction={popoverDirection}
        listProps={{role: 'presentation'}}
        popoverProps={{
          aria: {role: 'menu', 'aria-labelledby': 'AccountOptionsMenuButton'},
        }}
        mapListItems={false}
        id='AccountOptionsMenuButton'
        aria-label={title}
        title={title}
        aria-haspopup='menu'
      >
        {() => <AuthPopoverList />}
      </HeaderListPopover>
    </HeaderItem>
  );
};

AuthItem.displayName = 'AuthItem';

export default AuthItem;
