import PropTypes from 'prop-types';

import {ITEM_TYPE_SCHEMA} from './basic';

export const CUSTOMER_FLAGS_SCHEMA = PropTypes.shape(
  {
    favourite: PropTypes.bool,
  },
);

export const CUSTOMER_FAVOURITABLE_ITEM_SCHEMA = PropTypes.shape(
  {
    kind: ITEM_TYPE_SCHEMA,
    customerFlags: CUSTOMER_FLAGS_SCHEMA,
  },
);
