import React from 'react';

import {Grid, UndecoratedLink} from '@ding/core/src/components/Predefined';
import ChangeLanguageWithCheckboxPopup, {CurrentLanguageLabel} from '@ding/layout/src/components/LayoutParts/Popups/ChangeLanguageWithCheckboxPopup';

import * as Links from '../../../Links';
import FooterColumn from '../FooterColumn';

const NavigationColumn = ({t}) => {
  const footerSections = [
    {
      id: 1,
      link: Links.ContractorsLink,
      name: t('website.footer.headers.stores'),
    },

    {
      id: 2,
      link: Links.LeafletsLink,
      name: t('website.shared.sections.leaflets.header'),
    },

    {
      id: 3,
      link: Links.ArticlesLink,
      name: t('website.shared.sections.articles.header'),
    },

    {
      id: 4,
      link: Links.ProductsLink,
      name: t('website.footer.headers.leaflets_offers'),
    },

    {
      id: 5,
      link: Links.MobileAppLink,
      name: t('website.header.menu_items.mobile_app'),
    },
  ];

  return (
    <FooterColumn title={t('website.name')} component='nav' aria-label='footer-site-navigation'>
      <Grid>
        {footerSections.map(
          ({link: LinkComponent, id, name}) => (
            <Grid.TextColumn
              key={id}
              xs={12}
              noLeftPadding
              role='presentation'
            >
              <LinkComponent>
                <strong>
                  {name}
                </strong>
              </LinkComponent>
            </Grid.TextColumn>
          ),
        )}

        <Grid.TextColumn
          key='language'
          xs={12}
          noLeftPadding
          role='presentation'
        >
          <ChangeLanguageWithCheckboxPopup triggerRenderFn={({onClick}) => (
            <UndecoratedLink to='#' onClick={onClick}>
              <strong>
                <CurrentLanguageLabel />
              </strong>
            </UndecoratedLink>
          )}
          />
        </Grid.TextColumn>
      </Grid>
    </FooterColumn>
  );
};

NavigationColumn.displayName = 'NavigationColumn';

export default NavigationColumn;
