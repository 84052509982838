export const PLACEMENT_BOTTOM_BAR_SELECTED_LEAFLETS = 'BOTTOM_BAR_SELECTED_LEAFLETS'; //BottomBar component (e.g. used in "Więcej gazetek" on mobile)
export const PLACEMENT_VIEWER_SELECTED_LEAFLETS = 'VIEWER_SELECTED_LEAFLETS'; //ViewerSelectedForUserLeaflets component  (e.g. used in "Więcej gazetek" on desktop)

/**
 * Checks if in given placement of SelectedForUserSidebarChunksList,
 * GA4 should report szpalta_scroll and szpalta_gazetka_klik events.
 * Exists because SelectedForUserSidebarChunksList is used in a few places in Ding,
 * but DBIA want GA4 events only in two places, what creates extra logic
 * @param {*} placement
 * @returns
 */
export const isCorrectPlacementForGA4Tracking = placement => (
  placement === PLACEMENT_BOTTOM_BAR_SELECTED_LEAFLETS
  || placement === PLACEMENT_VIEWER_SELECTED_LEAFLETS
);
