import React from 'react';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import {PROPS_SCHEMA} from '@adretail/schemas';
import IdleRender from '@adretail/basic-components/src/IdleRender';

import {Flex} from '@ding/core/src/components/Predefined';
import * as OAuth from '@ding/auth/src/client/OAuth';

const css = {
  base: {
    borderRadius: 0,
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.168)',
    textTransform: 'initial',

    '&:first-child': {
      marginBottom: 12,
    },
  },

  container: {
    maxWidth: 320,
  },
};

const OAuthButtonsRow = ({classes, buttonProps}) => (
  <Flex.Column
    justify='center'
    align='center'
  >
    <div className={classes.container}>
      <IdleRender>
        <OAuth.GoogleButton
          className={classes.base}
          {...buttonProps}
        />

        <OAuth.FBButton
          className={classes.base}
          {...buttonProps}
        />
      </IdleRender>
    </div>
  </Flex.Column>
);

OAuthButtonsRow.displayName = 'OAuthButtonsRow';

OAuthButtonsRow.propTypes = {
  buttonProps: PROPS_SCHEMA,
};

export default injectClassesSheet(css)(React.memo(OAuthButtonsRow));
