import * as R from 'ramda';

import decodeUrl from '@adretail/basic-helpers/src/url/decodeUrl';
import decodeSearchItem from './decodeSearchItem';

const decodeURLSearchParams = (urlSearch) => {
  const {searchItem} = decodeUrl(urlSearch);
  if (R.is(Array, searchItem)) {
    return R.map(
      item => decodeSearchItem(item) || null,
      searchItem,
    );
  }

  return decodeSearchItem(searchItem) || null;
};

export default decodeURLSearchParams;
