import env from '@cdd/internal-server/src/constants/env';
import React from 'react';

const NoscriptTags = () => (
  <>
    <noscript>
      <iframe
        title='gtm'
        src={`https://www.googletagmanager.com/ns.html?id=${env.client.google.gtm.id}`}
        height='0'
        width='0'
        style={{
          display: 'none',
          visibility: 'hidden',
        }}
      />
    </noscript>
  </>
);

NoscriptTags.displayName = 'NoscriptTags';

export default NoscriptTags;
