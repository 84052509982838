export default {
  notification_section: {
    toggle: 'Dodaj frazę "%{}" do ulubionych, a otrzymasz powiadomienia o nowych ofertach',
  },

  popular: {
    header: 'Popularne produkty',
  },

  similar: {
    header: 'Obecnie nie posiadamy produktów i gazetek z tagiem "%{}". Sprawdź podobne oferty:',
  },

  articles: {
    header: '%{} - artykuły',
  },

  products: {
    header: '%{} - oferty z gazetek promocyjnych',
  },

  leaflets: {
    header: '%{} - gazetki promocyjne',
  },

  no_results: {
    note: 'Brak wyników',
  },
};
