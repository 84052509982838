import React from 'react';

import GeolocalizeIcon from '@ding/core/src/icons/Geolocalize';
import {GeolocationModalTrigger} from '@ding/geolocation/src/client/components';

import PrimaryMenuList from './PrimaryMenuList';
import PrimaryMenuItem from './PrimaryMenuItem';
import AuthMenuItem from './AuthItem';

const MobileMenuList = () => (
  <PrimaryMenuList>
    <AuthMenuItem />

    <PrimaryMenuItem
      icon={GeolocalizeIcon}
      title={(
        <GeolocationModalTrigger withIcon={false} />
      )}
    />
  </PrimaryMenuList>
);

MobileMenuList.displayName = 'MobileMenuList';

export default MobileMenuList;
