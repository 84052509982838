import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  PROPS_SCHEMA,
  REACT_COMPONENT_CLASS_SCHEMA,
} from '@adretail/schemas';

import {fastCompareIds} from '@adretail/basic-helpers/src/base/compareIds';
import useReactRouter from '@ding/core/src/hooks/useReactRouter';

import {AbstractComponentsList} from '@ding/core/src/components/Parts/Lists';
import PlainUnorderedList from '@ding/core/src/components/Predefined/PlainUnorderedList';

import PlainAutocompleteItem from './Items/Plain';
import EmptyItem, {
  addEmptySelectItem,
  isEmptyItem,
} from './Items/EmptyItem';

import useHighlighedIndex from './hooks/useHighlightedIndex';

export const isBlankItem = R.propSatisfies(R.isNil, 'id');

export const createBlankValue = name => ({
  id: null,
  name,
});

/**
 * Renders array of items for autocomplete, add option
 * onSelectItem that blurs autocomplete and forwards onChange
 *
 * @export
 */
const AutocompleteItemsList = ({
  itemProps, value,
  list, addEmptyOption,
  alwaysShowEmptyOption,
  emitPhraseItemOnEnter,
  itemComponent: ItemComponent,
  emptyItemComponent,
  blankListTitle,
  onSelectItem,
  ...props
}) => {
  const EmptyItemComponent = emptyItemComponent || EmptyItem;
  const routerContext = useReactRouter();

  // assume that if AutocompleteItemsList is visible so input is focused
  const phrase = value?.name;
  const highlightedIndex = useHighlighedIndex(
    {
      phrase,
      listLength: list.length,
      onSelectItem: (index, enter) => {
        if (enter && emitPhraseItemOnEnter) {
          onSelectItem(
            createBlankValue(phrase),
            null,
            null,
          );
          return;
        }

        const selectedItem = list[index] || createBlankValue(phrase);
        if (!selectedItem)
          return;

        // if anchor, redirect
        const link = selectedItem.to || selectedItem.link;
        if (link && itemProps?.linkComponent) {
          routerContext.history.push(link);
          return;
        }

        // if normal item - set value
        onSelectItem(selectedItem, index, null);
      },
    },
  );

  // render placeholder if list is empty
  if (R.isEmpty(list) && !R.isNil(blankListTitle)) {
    return (
      <PlainUnorderedList className={props.className}>
        <ItemComponent>
          {blankListTitle}
        </ItemComponent>
      </PlainUnorderedList>
    );
  }

  return (
    <AbstractComponentsList
      {...props}
      itemComponent={ItemComponent}
      list={
        (alwaysShowEmptyOption || (value && !isBlankItem(value))) && addEmptyOption
          ? addEmptySelectItem(list)
          : list
      }
      itemPropsMap={
        (item, index) => ({
          ...itemProps,
          ...isEmptyItem(item) && {
            item: {
              ...item,
              name: item.name || <EmptyItemComponent item={item} />,
            },
          },

          onClick: e => onSelectItem(item, index, e),
          active: fastCompareIds(
            R.defaultTo(null, value?.id),
            item.id,
          ),
          highlight: highlightedIndex === index,
        })
      }
    />
  );
};

AutocompleteItemsList.displayName = 'AutocompleteItemsList';

AutocompleteItemsList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any),
  blankListTitle: PropTypes.node,
  itemProps: PROPS_SCHEMA,
  itemComponent: REACT_COMPONENT_CLASS_SCHEMA,
  emptyItemComponent: REACT_COMPONENT_CLASS_SCHEMA,
  addEmptyOption: PropTypes.bool,
  alwaysShowEmptyOption: PropTypes.bool,
  onSelectItem: PropTypes.func.isRequired,
};

AutocompleteItemsList.defaultProps = {
  itemComponent: PlainAutocompleteItem,
};

export default React.memo(AutocompleteItemsList);
