import React from 'react';
import * as R from 'ramda';

import {NOTIFICATIONS_MODAL_UUID} from '@ding/search/src/constants';

import {withSidebarModal} from '@ding/controls/src/ResponsiveModalToggle';
import {trackComponent} from '@ding/interia';

import AccountSettingsLink from '@ding/layout/src/components/Links/AccountSettingsLink';
import AuthConditionalRender from '@ding/auth/src/client/AuthConditionalRender';
import SettingsIcon from '@ding/core/src/icons/Settings';
import NotificationsSidebar from '@ding/notifications';

import NotificationsItem from './NotificationsItem';

// handle hashes such as notifications-product, notifications-leaflet
const NOTIFICATIONS_URL_MODE = () => ({
  encode: ({pathname, search}, uuid) => `${pathname}${search}#${uuid}`,
  reset: ({pathname, search}) => `${pathname}${search}`,
  match: ({hash}, uuid) => {
    const hashID = `#${uuid}`;

    return R.contains(
      hashID,
      [hash, hash.split('-')[0]],
    );
  },
});

const TrackedAccountSettingsLink = trackComponent({
  onClick({ga4}) {
    ga4.notificationsGoToSettings();
  },
})(AccountSettingsLink);

const NotificationsModalTrigger = withSidebarModal(
  {
    modalContentComponent: NotificationsSidebar,
    toggleProps: ({t}) => ({
      urlMode: NOTIFICATIONS_URL_MODE,
      uuid: NOTIFICATIONS_MODAL_UUID,
      modalProps: {
        title: t('website.shared.titles.notifications'),
        rightTitlebarToolbar: (
          <AuthConditionalRender
            authorizedRenderFn={
              () => (
                <TrackedAccountSettingsLink style={{display: 'flex'}}>
                  <SettingsIcon size='medium-small' />
                </TrackedAccountSettingsLink>
              )
            }
          />
        ),
        contentProps: {
          noVerticalPadding: true,
          padding: 'medium',
        },
      },
    }),
  },
)(NotificationsItem);

export default NotificationsModalTrigger;
