import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {
  WHITE,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import GLetterIcon from '@ding/core/src/icons/GLetter';
import {RectangleIconButton} from '@ding/controls/src/';

const GoogleButton = styled(
  RectangleIconButton,
  {
    background: WHITE,
    color: 'rgba(0, 0, 0, 0.54)',
    borderRadius: 0,
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.168)',
    textTransform: 'initial',
    transition: 'background-color .218s',
    height: 35,

    '&:hover': {
      backgroundColor: 'rgba(66,133,244,.04)',
    },
  },
  {
    icon: GLetterIcon,
    expanded: true,
    mergeProps: ({iconOnly}) => iconOnly ?? true,
  },
);

GoogleButton.displayName = 'GoogleButton';

export const TitledGoogleButton = (props) => {
  const title = useTranslate('website.auth.buttons')('google_login');

  return (
    <GoogleButton
      {...props}
      iconOnRight={false}
      iconOnly={false}
      iconSpacing={3}
    >
      {title}
    </GoogleButton>
  );
};

export default GoogleButton;
