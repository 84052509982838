import * as R from 'ramda';
import {
  useCallback,
  useMemo,
} from 'react';

import useMutation from '@ding/tiny-gql/src/react/hooks/useMutation';
import {CHANGE_ACCOUNT_SETTINGS_MUTATION} from '../ChangeAccountSettingsMutation';

const useSingleAccountSettingMutation = (settingName, silent) => {
  const settingSelector = useMemo(
    () => R.pathOr(false, ['settings', settingName]),
    [
      settingName,
    ],
  );

  const [mutateAccountSettings, promiseFlags] = useMutation(
    CHANGE_ACCOUNT_SETTINGS_MUTATION,
    {
      selectorFn: settingSelector,
      silent,
    },
  );

  const mutateSetting = useCallback(
    newValue => mutateAccountSettings(
      {
        removeEmpty: false,
        settings: {
          [settingName]: newValue || null, // due to API issues
        },
      },
    ),
    [
      mutateAccountSettings,
      settingName,
    ],
  );

  return [
    mutateSetting,
    promiseFlags,
  ];
};

export default useSingleAccountSettingMutation;
