import styled from '@adretail/fast-stylesheet/src/react/styled';

const SmallLogo = styled.img(
  {
    display: 'inline-block',
    width: 60,
    height: 40,
    objectFit: 'contain',
  },
);

SmallLogo.displayName = 'SmallLogo';

export default SmallLogo;
