import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import c from 'classnames';
import {useLocation} from 'react-router';

import {LIGHT_CARD_GRAY} from '@ding/constants/src/colorSchema';

import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';
import renderFunctionalChildren from '@adretail/basic-helpers/src/ui/renderFunctionalChildren';
import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';

import useUpdateEffect from '@adretail/basic-hooks/src/base/useUpdateEffect';

import FadeAppear from '@adretail/basic-components/src/Anim/FadeAppear';
import {
  Margin,
  PlainUnorderedList,
} from '@ding/core/src/components/Predefined';

import Tab from './Tab';

const findActiveTabChildren = (tabId, children, defaultToFirst = true) => R.when(
  R.is(Array),
  R.compose(
    R.defaultTo(
      defaultToFirst
        ? children[0]
        : null,
    ),
    R.ifElse(
      R.isNil,
      R.always(null),
      R.find(
        ({props}) => props.id === tabId,
      ),
    ),
  ),
)(children);

const pickValueTabId = R.when(
  R.is(Object),
  R.prop('id'),
);

const css = {
  base: {},
  bottomBordered: {
    borderBottom: [3, 'solid', LIGHT_CARD_GRAY],

    '& > li': {
      position: 'relative',
      bottom: -3,
    },
  },
};

const Tabs = ({
  l, value, headless, contentTopMargin,
  classes, bottomBordered, children,
  className, style,
  hashSerializer, storeInUrlHash, onChangeTab,
}) => {
  const location = useLocation();
  const currentTabId = pickValueTabId(value);
  const activeTab = findActiveTabChildren(currentTabId, children);
  const activeChildren = activeTab?.props.children;

  // dump initial state for tabs
  // useEffect is fine because hash is not
  // passed to server in SSR mode
  useEffect(
    () => {
      const {hash} = location;
      if (!storeInUrlHash || !hash)
        return;

      const decodedID = (hashSerializer.decode || R.identity)(R.tail(location.hash));
      if (findActiveTabChildren(decodedID, children, false))
        l.onInputEvent(decodedID);
    },
    [storeInUrlHash],
  );

  useUpdateEffect(
    () => {
      if (!storeInUrlHash)
        return;

      const encodedID = (hashSerializer.encode || R.identity)(pickValueTabId(value));
      document.location.hash = `#${encodedID}`;
    },
    [storeInUrlHash, value],
  );

  return (
    <>
      {!headless && (
        <PlainUnorderedList
          inline
          style={style}
          className={c(
            bottomBordered && classes?.bottomBordered,
            className,
          )}
        >
          {React.Children.map(
            children,
            child => React.cloneElement(
              child,
              {
                ...child.props,
                active: activeTab === child,
                onClick: () => {
                  onChangeTab?.(child.props.id);
                  l.onInputEvent(child.props.id);
                },
              },
            ),
          )}
        </PlainUnorderedList>
      )}

      {!!activeTab && (
        <FadeAppear
          key={activeTab.props.id}
          in
        >
          <Margin
            top={
              contentTopMargin ?? (headless ? 0 : 4)
            }
            block
          >
            {renderFunctionalChildren(
              activeChildren,
              {
                tab: value,
                onChangeTab: (tabId, transferredState) => {
                  l.onInputEvent({
                    id: tabId,
                    transferredState,
                  });
                },
              },
            )}
          </Margin>
        </FadeAppear>
      )}
    </>
  );
};

Tabs.displayName = 'Tabs';

Tabs.propTypes = {
  headless: PropTypes.bool,
  storeInUrlHash: PropTypes.bool,
  hashSerializer: PropTypes.shape(
    {
      encode: PropTypes.func,
      decode: PropTypes.func,
    },
  ),
};

// provide value based on tab id
const TabsContainer = injectClassesSheet(css)(linkedInputs(null, true)(Tabs));

TabsContainer.Item = Tab;

export default TabsContainer;
