import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import * as R from 'ramda';

import {PROPS_SCHEMA} from '@adretail/schemas';
import {WHITE, DARK, SEE_THROUGH_WHITE, SEE_THROUGH_DARK, LIGHT_BORDER} from '@ding/constants/src/colorSchema';

import {popupZDepth} from '@ding/core/src/styles/zDepth';
import {STOP_MODAL_PROPAGATION_LISTENERS} from '@adretail/basic-helpers/src/inputs/suppressEvent';

import {styled, injectClassesSheet} from '@adretail/fast-stylesheet/src/react';
import {withDurationDelayedUnmount} from '@adretail/basic-components/src/BasicModal';

import useHotKeys from '@adretail/basic-hooks/src/base/useHotKeys';

import SlideToUpAppear from '@adretail/basic-components/src/Anim/SlideToUpAppear';
import ModalHolder from '@adretail/basic-components/src/BasicModal/ModalHolder';
import ModalPortal from '@adretail/basic-components/src/BasicModal/ModalPortal';

import {PageContainer, Flex} from '@ding/core/src/components/Predefined';
import CloseButton from '../../Button/Predefined/CloseButton';
import CustomScrollbars from '../../CustomScrollbars';

const WideModalHeader = styled.div(
  {
    base: {
      position: 'sticky',
      top: 0,
      zIndex: 99,
      width: '100%',
      background: 'inherit',

      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 60,
        maxWidth: 1280,
        margin: '0 auto',
        padding: [0, 20],
      },
    },

    'type-light': {
      color: DARK,
    },
    'type-dark': {
      color: WHITE,
    },

    'padding-medium': {
      marginTop: 20,
    },

    'padding-big': {
      marginTop: 50,
    },

    'padding-none': {
      marginTop: 0,
    },

    fullPage: {
      '& > div': {
        maxWidth: '100%',
      },
    },

    headerSeparator: {
      borderBottom: `1px solid ${LIGHT_BORDER}`,
    },

    centredHeader: {
      '& > div > div': {
        width: '100%',
      },
      '& > div > button': {
        position: 'absolute',
        right: 20,
      },
    },
  },
  {
    omitProps: ['paddingSize', 'fullPage', 'centredHeader', 'type', 'headerSeparator'],
    classSelector: (classes, {fullPage, paddingSize, centredHeader, type, headerSeparator}) => [
      classes[`padding-${paddingSize || 'medium'}`],
      classes[`type-${type}`],
      fullPage && classes.fullPage,
      centredHeader && classes.centredHeader,
      headerSeparator && classes.headerSeparator,
    ],
  },
);

const WideModalContent = styled.div(
  {
    base: {
      display: 'flex',
      flexDirection: 'column',
      background: 'inherit',
    },

    fixed: {
      maxHeight: 'calc(100vh - 80px)',
      overflowY: 'auto',
    },

    fullPage: {
      height: '100vh',
      maxHeight: '100vh',
      '& > div:last-child': {
        height: '100%',
      },
    },

    expandedContent: {
      padding: 0,
      width: '100%',
      height: '100%',
      maxHeight: 'initial',
    },

    allowOverflow: {
      overflowY: 'initial',
    },
  },
  {
    omitProps: ['expandedContent', 'allowOverflow', 'fixed', 'paddingSize', 'fullPage'],
    classSelector: (classes, {expandedContent, allowOverflow, fixed, fullPage}) => [
      expandedContent && classes.expandedContent,
      allowOverflow && classes.allowOverflow,
      fixed && classes.fixed,
      fullPage && classes.fullPage,
    ],
  },
);

const WidePopupHolder = React.memo(
  ({
    children, paddingSize, absolutePosition, fullPage,
    wrapWithContainer, containerProps, expandedContent, allowOverflow,
    noCloseButton, leftTopHeaderButtons, title,
    headerSeparator, type, centredHeader, onClose,
    closeButtonProps, style,
  }) => {
    const childs = children && children(onClose);

    return (
      <>
        <WideModalContent
          fixed={!absolutePosition}
          {...{
            expandedContent,
            allowOverflow,
            fullPage,
            style,
          }}
        >
          <WideModalHeader
            {...{
              paddingSize,
              fullPage,
              headerSeparator,
              centredHeader,
              type,
            }}
          >
            <div>
              <Flex row gap='big'>
                {leftTopHeaderButtons && leftTopHeaderButtons}
                {title}
              </Flex>

              {!noCloseButton && (
              <CloseButton
                type={type === 'light' ? 'dark' : 'light'}
                iconSize='medium-small'
                size='medium'
                onClick={
                  onClose && (() => onClose(
                    {
                      // used for tracking
                      source: 'button',
                    },
                  ))
                }
                {...closeButtonProps}
              />
              )}
            </div>
          </WideModalHeader>

          {(
            wrapWithContainer
              ? (
                <PageContainer
                  padding='medium'
                  topPadding='medium'
                  noPadding={fullPage}
                  expandToViewport={!absolutePosition && !fullPage}
                  {...containerProps}
                >
                  {childs}
                </PageContainer>
              )
              : childs
          )}
        </WideModalContent>
      </>
    );
  },
);

WidePopupHolder.displayName = 'WidePopupHolder';

WidePopupHolder.propTypes = {
  expandedContent: PropTypes.bool,
  wrapWithContainer: PropTypes.bool, // if true - content is centered
  onClose: PropTypes.func.isRequired,
};

/**
 * Animated modal holder
 *
 * @export
 */

const css = {
  wideModal: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',

    zIndex: 9999,
    background: WHITE,
  },

  'type-light': {
    backgroundColor: WHITE,
  },
  'type-dark': {
    backgroundColor: DARK,
  },

  'type-see-through-light': {
    backgroundColor: SEE_THROUGH_WHITE,
  },
  'type-see-through-dark': {
    background: SEE_THROUGH_DARK,
  },

  visibleWideModal: {
    extend: popupZDepth,
  },

  absoluteWideModal: {
    position: 'absolute',
    maxHeight: 'calc(100% - 80px)',
    minHeight: '80%',
    boxShadow: 'none',
    overflowY: 'auto',
  },
};

const WidePopup = ({
  absolutePosition,
  children,
  wrapWithContainer,
  containerProps,
  wrapWithPortal,
  leftTopHeaderButtons,
  title,
  classes,
  style,
  className,
  expandedContent,
  allowOverflow,
  paddingSize,
  centredHeader,
  noPadding,
  noCloseButton,
  closeButtonProps,
  onClose,
  holderStyle,

  // from withDurationDelayedUnmount
  animProps,
  animDuration,
  visible,
  modalHolderRef,
  fullPage,
  headerSeparator,
  type,
  seeThrough,
}) => {
  useHotKeys(
    {
      /** ESC */ 27: onClose,
    },
  );

  const content = (
    <>
      <ModalHolder
        in={visible}
        style={{
          background: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
        }}
        absolutePosition={absolutePosition}
        onClick={onClose}
      />
      <SlideToUpAppear
        duration={animDuration}
        in={visible}
        {...animProps}
      >
        {transitionStyle => (
          <CustomScrollbars>
            <div
              ref={modalHolderRef}
              style={(
                style
                  ? {...style, ...transitionStyle}
                  : transitionStyle
              )}
              className={c(
                className,
                classes.wideModal,
                classes[`type-${seeThrough ? 'see-through-' : ''}${type}`],
                absolutePosition && classes.absoluteWideModal,
                visible && classes.visibleWideModal,
              )}
              {...STOP_MODAL_PROPAGATION_LISTENERS}
            >
              <WidePopupHolder
                {...{
                  expandedContent,
                  paddingSize,
                  centredHeader,
                  noPadding,
                  noCloseButton,
                  leftTopHeaderButtons,
                  title,
                  className,
                  allowOverflow,
                  wrapWithContainer,
                  containerProps,
                  absolutePosition,
                  fullPage,
                  headerSeparator,
                  type,
                  onClose,
                  closeButtonProps,
                  style: holderStyle,
                }}
              >
                {children}
              </WidePopupHolder>
            </div>
          </CustomScrollbars>
        )}
      </SlideToUpAppear>
    </>
  );

  if (!wrapWithPortal)
    return content;

  return (
    <ModalPortal>
      {content}
    </ModalPortal>
  );
};

WidePopup.displayName = 'WidePopup';

WidePopup.propTypes = {
  animProps: PROPS_SCHEMA,
  type: PropTypes.oneOf(['light', 'dark', 'see-through-light', 'see-through-dark']),

  absolutePosition: PropTypes.bool, // mount as absolute instead fixed position
  wrapWithPortal: PropTypes.bool,
  wrapWithContainer: PropTypes.bool, // if true - content is centered
  leftTopHeaderButtons: PropTypes.node,
};

WidePopup.defaultProps = {
  wrapWithPortal: true,
  wrapWithContainer: true,
  type: 'light',
};

export default R.compose(
  injectClassesSheet(css),
  withDurationDelayedUnmount(200, false),
)(WidePopup);
