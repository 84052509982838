import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import {GQLAutocomplete} from '@ding/controls/src/Autocomplete';
import {PAGE_SEARCH_QUERY} from '../gql/pageSearchQuery';

const GQLPageSearchAutocomplete = provideProps(
  {
    query: PAGE_SEARCH_QUERY,
    fetchWhenEmptyPhrase: false,
  },
)(GQLAutocomplete);

export default GQLPageSearchAutocomplete;
