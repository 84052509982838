import c from 'classnames';
import styled from '@adretail/fast-stylesheet/src/react/styled';

const ExpandedImage = styled.img(
  {
    base: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },

    muted: {
      opacity: 0.2,
      filter: 'grayscale(100%)',
    },
  },
  {
    omitProps: [
      'muted',
    ],
    classSelector: (classes, {muted}) => c(
      muted && classes.muted,
    ),
  },
);
ExpandedImage.displayName = 'ExpandedImage';

export default ExpandedImage;
