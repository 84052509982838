import React, {useEffect} from 'react';
import * as R from 'ramda';

import useWindowAPI from './useWindowAPI';

const useBroadcastRouteMetadata = (routeName, action, skipComparator = false) => {
  const api = useWindowAPI();

  return (metadata) => {
    if (!api)
      return;

    api.push(
      handle => handle.actions.$stream.notify(
        {
          action: action || `Route:${routeName}`,
          data: metadata,
        },
        skipComparator,
      ),
    );
  };
};

export const BroadcastRouteMeta = React.memo(({action, routeName, value, valueKey}) => {
  const broadcastRouteMeta = useBroadcastRouteMetadata(
    routeName,
    action,
  );

  useEffect(
    () => {
      broadcastRouteMeta(value);
    },
    [
      R.defaultTo(value, valueKey),
    ],
  );

  return null;
});

export default useBroadcastRouteMetadata;
