import styled from '@adretail/fast-stylesheet/src/react/styled';
import PropTypes from 'prop-types';

import {LIGHT_CARD_GRAY, CARD_GRAY} from '@ding/constants/src/colorSchema';

/**
 * @typedef {object} SkeletonProps
 * @property {boolean} props.inline - use display inline-block
*/

/**
 * Skeleton element that using full available space
 * @type {React.ForwardRefExoticComponent<SkeletonProps & React.RefAttributes<'div'>>}
 */
const Skeleton = styled(
  'div',
  {
    '@keyframes skeleton-pulse': {
      from: {
        backgroundColor: CARD_GRAY,
      },
      to: {
        backgroundColor: LIGHT_CARD_GRAY,
      },
    },

    base: {
      width: '100%',
      height: '100%',

      borderRadius: 8,
      background: CARD_GRAY,

      animation: 'skeleton-pulse 1s ease infinite alternate',
    },

    inline: {
      display: 'inline-block',
    },

  },
  {
    omitProps: ['inline'],
    classSelector: (classes, {inline}) => [
      inline && classes.inline,
    ],
  },
);

Skeleton.displayName = 'Skeleton';

Skeleton.propTypes = {
  inline: PropTypes.bool,
};

export default Skeleton;
