import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {
  Text,
  UndecoratedLink,
} from '../Predefined';

const MoreDescriptionLink = ({
  children,
  to,
  linkTitle,
  linkComponent: LinkComponent,
  separator,
}) => {
  const t = useTranslate();

  return (
    <Text
      weight={600}
      size='medium-small'
    >
      {children}
      {separator}
      <LinkComponent
        to={to}
        type='primary'
      >
        {(
          R.isNil(linkTitle)
            ? t('website.shared.titles.more')
            : linkTitle
        )}
      </LinkComponent>
    </Text>
  );
};

MoreDescriptionLink.displayName = 'MoreDescriptionLink';

MoreDescriptionLink.propTypes = {
  separator: PropTypes.string,
  to: PropTypes.string,

  linkComponent: REACT_COMPONENT_CLASS_SCHEMA,
  linkTitle: PropTypes.node,
};

MoreDescriptionLink.defaultProps = {
  linkComponent: UndecoratedLink,

  separator: ' - ',
  to: '#',
};

export default MoreDescriptionLink;
