import React from 'react';
import PropTypes from 'prop-types';

import {LoadingSpinner} from '@ding/core/src/components/Parts';
import DefaultTinyQuery from '../DefaultTinyQuery';

import ExpandableFunctionalChunksList from '.';

const ListLoadingSpinner = () => (
  <LoadingSpinner
    style={{
      height: 100,
    }}
  />
);

const OffsetQueryChunksList = React.forwardRef(({
  variables, initialExpand,
  expandBy, asyncChunkPropsFn,
  loadingComponent,
  ...props
}, ref) => (
  <ExpandableFunctionalChunksList
    ref={ref}
    asyncChunkResolverComponent={DefaultTinyQuery}
    loadingComponent={loadingComponent || ListLoadingSpinner}
    pickChunkInfoFn={
      (page, {totalItems}) => {
        const remainItems = Math.max(0, totalItems - (initialExpand + page * expandBy));
        const totalPages = 1 + Math.max(0, Math.ceil((totalItems - initialExpand) / expandBy));

        return {
          index: page,
          totalChunks: totalPages,
          remainItems,
        };
      }
    }
    asyncChunkPropsFn={
      (...args) => {
        const {chunkIndex} = args[0];

        return {
          ...asyncChunkPropsFn?.(...args),
          variables: {
            ...variables,
            ...(
              chunkIndex > 0
                ? {limit: expandBy, offset: initialExpand + (chunkIndex - 1) * expandBy}
                : {limit: initialExpand, page: 1}
            ),
          },
        };
      }
    }
    {...props}
  />
));

OffsetQueryChunksList.displayName = 'OffsetQueryChunksList';

OffsetQueryChunksList.propTypes = {
  initialExpand: PropTypes.number,
  expandBy: PropTypes.number,
};

OffsetQueryChunksList.defaultProps = {
  initialExpand: 35,
  expandBy: 50,
};

export default OffsetQueryChunksList;
