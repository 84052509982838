import 'isomorphic-fetch';
import * as R from 'ramda';

import MemCache from '@ding/mem-cache';

import ssr from '@adretail/basic-helpers/src/base/ssr';
import batch from '@adretail/basic-helpers/src/base/batch';
import safeArray from '@adretail/basic-helpers/src/base/safeArray';
import {methodMeasure} from '@adretail/basic-helpers/src/time/measureFnPerformance';

import * as CACHE_POLICY from './constants/fetchPolicy';

import * as DataUtils from './helpers/dataUtils';

import displayErrorsList, {errorsListToString} from './helpers/displayErrorsList';
import pickResponseErrors, {safeMapErrorsMessages} from './helpers/pickResponseErrors';
import {pickFirstOperationResponseKey} from './helpers';

import gql, {GraphQLFragment} from './helpers/gql';

export {
  CACHE_POLICY,
  pickResponseErrors,
  gql,
  GraphQLFragment,
};

const {FORCE_SHOW_GQL_LOGS} = process.env;

/**
 * Ensures that errors array from API is always array
 * [{mesage, code?}, ...]
 */
export class GQLErrors extends Error {
  constructor(errors) {
    super(
      errorsListToString(errors),
    );

    this.gqlErrors = errors;
  }
}

export const throwIfErrorsResponse = (response) => {
  const errors = pickResponseErrors(response);
  if (!errors)
    return response;

  throw new GQLErrors(errors);
};

/**
 * Instead of apollo it is much lighter and can be modified faster
 *
 * @export
 */
export default class TinyGqlClient {
  constructor({
    uri, // optional if proxy provided
    proxy,
    batching,
    silent = false,
    cacheStore = new MemCache({
      defaultKeyExpire: 300, // 5min
    }),
    headers = {},
    onError = displayErrorsList,
  }) {
    this.silent = silent;
    this.cacheStore = cacheStore;
    this.uri = uri;
    this.proxy = proxy;
    this.headers = headers;
    this.batching = ssr ? 0 : batching; // fixme: check batching in SSR
    this.onError = onError;

    this.batchQueryCall = batch(
      {
        delay: this.batching,
      },
    )(
      argsArray => TinyGqlClient.safeGQLCall(
        this,
        {
          // first argument is always query
          query: R.map(
            R.nth(0),
            argsArray,
          ),
        },
        headers,
      ),
    );

    this.query = (...args) => TinyGqlClient.query(this, ...args);
    this.mutate = (...args) => TinyGqlClient.mutate(this, ...args);
  }

  /**
   * Appends headers to client instance, creating new client.
   * Used in some context providers(adds auth tokens)
   *
   * @param {Client} client
   * @param {Object} headers
   * @param {Object} params
   *
   * @return {Client}
   */
  static cloneWithHeaders(client, headers, params) {
    return new TinyGqlClient({
      uri: client.uri,
      cacheStore: client.cacheStore, // todo: check it should be cloned or killed
      headers: {
        ...client.headers,
        ...headers,
      },
      onError: client.onError,
      ...params,
    });
  }

  static from(context) {
    return new TinyGqlClient(context);
  }

  /**
   * @param {TinyGQLConfig} context             Settings in tiny gql client
   * @param {Object}        content             Form data
   * @param {Object}        headers             XHR request headers
   *
   * @returns {Promise}
   */
  @methodMeasure(
    'TinyGqlClient::safeGQLCall',
    (execPath, [context, content], time, result) => {
      // do not log operations for mutations
      if (!FORCE_SHOW_GQL_LOGS && (context.silent || content?.__meta?.mutation))
        return;

      let logger = ::console.warn;
      if (ssr && FORCE_SHOW_GQL_LOGS) {
        logger = (...args) => {
          R.forEach(
            (arg) => {
              if (R.is(Object, arg))
                console.dir(arg, {depth: null}); // eslint-disable-line no-console
              else
                console.warn(arg);
            },
            args,
          );
        };
      }

      if (R.is(Array, content?.query) && context.batching) {
        logger(
          `${execPath}(batch): ${time}ms`,
          ` total batched calls: ${content.query.length}`,
          ' queries:', content.query,
          ' Result: ', result,
        );
      } else if (!FORCE_SHOW_GQL_LOGS && ssr) {
        logger(
          `${execPath}(\x1b[96m${content.operationName}\x1b[0m): \x1b[97m${time}\x1b[0mms`,
        );
      } else {
        logger(
          `${execPath}(${content.operationName}): ${time}ms`,
          ' Variables: ', content.variables,
          ' Result: ', result,
          ' Query:', {query: content.query},
        );
      }
    },
    false,
  )
  static safeGQLCall(context, content, headers) {
    const {proxy, onError} = context;

    const parseResponse = R.unless(
      R.isNil,
      ({error, ...data}) => DataUtils.pickDataAttribute({
        ...data,

        // due to API issues force rename it
        ...error && {
          errors: [error],
        },
      }),
    );

    const originalFetchResolver = (customConfig = {}) => fetch(
      context.uri,
      {
        method: 'POST',
        body: JSON.stringify(
          customConfig.body || (
            '__meta' in content
              ? R.omit(['__meta'], content)
              : content
          ),
        ),
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          ...context.headers,
          ...(customConfig.headers || headers),
        },
      },
    )
      .then(res => res.json());

    const safeFetchPromise = new Promise(
      (resolve) => {
        let fetchPromise = null;

        if (proxy) {
          fetchPromise = proxy(
            context,
            {
              originalFetchResolver,
              content,
              headers,
            },
          );
        } else
          fetchPromise = originalFetchResolver();

        // critical error - it should never happen!
        if (!fetchPromise)
          throw new Error('TinyGqlClient::safeGQLCall: Missing proxy promise!');

        fetchPromise
          .then(
            R.ifElse(
              R.is(Array),
              R.map(parseResponse),
              parseResponse,
            ),
          )
          .then(resolve)
          .catch((error) => {
            resolve({
              errors: [
                {
                  message: `Network error - ${error.message}`,
                  locations: [{line: 0, column: 0}],
                },
              ],
            });
          });
      },
    );

    return safeFetchPromise.then(
      (res) => {
        res && R.forEach(
          (item) => {
            const errors = pickResponseErrors(item);
            if (errors && onError) {
              onError(
                safeMapErrorsMessages(
                  R.concat(`(operation: ${content.operationName}): `),
                  errors,
                ),
              );
            }
          },
          safeArray(res),
        );

        return res;
      },
    );
  }

  /**
   * @param {TinyGQLConfig} context             Settings in tiny gql client
   * @param {Document}      queryDocument       Document from graphql-tag
   * @param {Object}        variables           Variables passed to server
   * @param {Object}        headers             XHR request headers
   * @param {CachePolicy}   clientFetchPolicy   If some flags are passed query might be cached
   *                                            key, type, allowBatching.
   *
   *                                            entries:
   *                                            type = CACHE_AND_NETWORK
   *                                            key = string
   *                                            cacheCheck = {read, write}
   *
   * @returns {Promise}
   */
  static query(context, queryDocument, variables, headers = {}, clientFetchPolicy = {}) {
    const {cacheStore} = context;
    const batching = clientFetchPolicy.allowBatching !== false && context.batching;

    const {cachePolicy = {}} = clientFetchPolicy;
    const cacheEnabled = (
      !ssr
        && cacheStore
        && clientFetchPolicy.type === CACHE_POLICY.CACHE_AND_NETWORK
    );

    if (cacheEnabled && cachePolicy.read !== false) {
      if (!clientFetchPolicy.key)
        console.warn('TinyGqlClient::safeGQLCall: clientFetchPolicy.key is null! Fix me!');

      const cachedPromise = cacheStore.get(clientFetchPolicy.key);
      if (cachedPromise)
        return cachedPromise;
    }

    const content = {
      variables,
      ...DataUtils.pickDocumentRequestData('query', queryDocument),
    };

    // promise is attached later to cacheStore to reuse it
    let promise = null;

    if (batching && R.isEmpty(headers)) {
      // batching
      const queueIndex = context.batchQueryCall.queueLength;
      promise = context
        .batchQueryCall(content)
        .then(
          R.nth(queueIndex),
        );
    } else {
      // regular call
      promise = TinyGqlClient.safeGQLCall(context, content, headers);
    }

    // store promise in cache
    if (cacheEnabled && cachePolicy.write !== false) {
      cacheStore.setex(
        clientFetchPolicy.key,
        promise,
      );
    }

    return promise;
  }

  /**
   * @throws {GQLErrors}
   *
   * @param {TinyGQLConfig} context       Settings in tiny gql client
   * @param {Document}      queryDocument Document from graphql-tag
   * @param {Object}        variables     Variables passed to server
   * @param {Object}        headers       XHR request headers

   * @returns {Promise}
   */
  static async mutate(context, queryDocument, variables, headers = {}) {
    const content = {
      variables,
      ...DataUtils.pickDocumentRequestData('mutation', queryDocument),
      __meta: {
        mutation: true,
      },
    };

    // handle network errors (syntax parsing etc)
    const response = throwIfErrorsResponse(
      await TinyGqlClient.safeGQLCall(context, content, headers),
    );

    // handle operation errors (app logic errors)
    const operationResponse = pickFirstOperationResponseKey(response);
    const errors = pickResponseErrors(operationResponse);

    if (errors) {
      context.onError?.(errors); // eslint-disable-line no-unused-expressions
      throw new GQLErrors(errors);
    }

    return operationResponse;
  }
}
