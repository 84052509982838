import React from 'react';
import PropTypes from 'prop-types';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {popupZDepth} from '@ding/core/src/styles/zDepth';

import AlwaysFocusInput from '@adretail/basic-components/src/AlwaysFocusInput';
import SearchIcon from '@ding/core/src/icons/Search';
import ExpandedInput from '@ding/controls/src/Input/ExpandedInput';
import PageSearchEnterListener from './PageSearchEnterListener';

const SearchInputHolder = styled(
  ExpandedInput,
  {
    base: {
      extend: popupZDepth,
      transform: 'translate3d(0, 0, 0)',
    },

    active: {
      boxShadow: 'initial !important',
    },
  },
  {
    omitProps: ['active', 'flat'],
    classSelector: (classes, {active, flat}) => (
      (flat || active) && classes.active
    ),
  },
  {
    index: 2,
  },
);

const ExpandedSearchInput = ({
  className, active, value, flat, onChange, onValueChange, ...props
}) => {
  const t = useTranslate();
  return (
    <AlwaysFocusInput>
      <PageSearchEnterListener
        onChange={(v) => {
          onChange(v);
          onValueChange?.(v);
        }}
        value={value}
      >
        {listeners => (
          <SearchInputHolder
            type='text'
            color='light'
            placeholder={
              t('website.header.search.truncated_placeholder')
            }
            iconLeft={(
              <SearchIcon
                type='dark'
                style={{
                  position: 'relative',
                  top: -1,
                }}
              />
            )}
            active={active}
            flat={flat}
            className={className}
            {...props}
            {...listeners}
          />
        )}
      </PageSearchEnterListener>
    </AlwaysFocusInput>
  );
};

ExpandedSearchInput.displayName = 'ExpandedSearchInput';

ExpandedSearchInput.propTypes = {
  flat: PropTypes.bool,
  onValueChange: PropTypes.func,
};

export default ExpandedSearchInput;
