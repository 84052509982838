import env from '@cdd/internal-server/src/constants/env';
import TinyGqlClient from '@ding/tiny-gql';

const GRAPH_API_URL = env.currentEnv.graphApiUrl;

export const silentRootGqlClient = new TinyGqlClient(
  {
    uri: GRAPH_API_URL,
    silent: true,
  },
);

export default new TinyGqlClient(
  {
    uri: GRAPH_API_URL,
  },
);
