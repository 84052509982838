const addMonthsDelta = (
  delta,
  dateString,
  dayValue,
) => {
  const date = new Date(dateString);
  const deltaMonth = date.getMonth() + delta;
  const day = dayValue || date.getDate();

  date.setMonth(deltaMonth, day);

  return date;
};

export default addMonthsDelta;
