import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import * as R from 'ramda';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/schemas/basic';

import Flex from '../Predefined/Flex';
import Margin from '../Predefined/Margin';

/**
 * Rendering LoadingSpiiner if not error occurs
 * Errors are reported by passing props error or/and timedOut (boolean) to returned component
 * Used mainly by react-loadable to render SomethingGoneWrong page
 * when error occurs during loading chunk
 * @param {*} Component Component rendered if error occurs
 * @see LoadingSpinnerErrorHandling
 * @see LoadingSpinnerSectionErrorHandling
 * @returns Component which render passed Component or LoadingSpinner
 */
export const wrapSpinnerInErrorHandling = Component => ({error, timedOut, ...props}) => (
  (error || timedOut) ? <Component /> : <StyledLoadingSpinner {...props} />
);

const createSpinnerSize = width => ({
  top: width / 2,
  left: width / 2,
  width,
  height: width,
});

const css = {
  '@keyframes spinner-rotating': {
    from: {
      transform: 'translate(-50%, -50%) rotate(0deg)',
    },
    to: {
      transform: 'translate(-50%, -50%) rotate(360deg)',
    },
  },

  placeholder: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },

  fixed: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 99999,
    background: '#fff',
  },

  absolute: {
    composes: '$fixed',
    position: 'absolute',
  },

  expanded: {
    width: '100%',
    height: '100%',
  },

  spinner: {
    position: 'relative',
    opacity: 0.5,

    display: 'inline-block',

    border: '6px solid rgba(128, 128, 128, 0.35)',
    borderTopColor: '#fff',
    borderRadius: '50%',
    animation: 'spinner-rotating 1s linear infinite',
  },

  tiny: createSpinnerSize(20),
  small: createSpinnerSize(28),
  medium: createSpinnerSize(42),
  big: createSpinnerSize(64),
};

/**
 * forwardRef is used to measure Nav
 */
const LoadingSpinner = React.forwardRef(
  (
    {
      className, classes, size, style,
      title, expandHeight, expanded, fixed, absolute,
      onClick,
    },
    ref,
  ) => (
    <Flex.Centered
      ref={ref}
      className={c(
        className,
        classes.placeholder,
        absolute && classes.absolute,
        fixed && classes.fixed,
        expanded && classes.expanded,
      )}
      column
      {...{
        style,
        expandHeight,
        onClick,
      }}
    >
      <span
        className={c(
          classes.spinner,
          classes[size],
        )}
      />
      {title && (
        <Margin
          top={3}
          block
        >
          {title}
        </Margin>
      )}
    </Flex.Centered>
  ),
);

LoadingSpinner.displayName = 'LoadingSpinner';

LoadingSpinner.propTypes = {
  size: SIZE_ARRAY_SCHEMA,
  title: PropTypes.node,
  expanded: PropTypes.bool,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  size: 'medium',
};

const StyledLoadingSpinner = R.compose(
  React.memo,
  injectClassesSheet(css),
)(LoadingSpinner);

export const FullscreenSpinner = () => (
  <StyledLoadingSpinner
    style={{
      width: '100%',
      height: '100vh',
    }}
  />
);

export default StyledLoadingSpinner;
