export default {
  titles: {
    localize_me: 'Zlokalizuj mnie',
    browsing_place_offers: 'Przeglądasz oferty dostępne w miejscu:',
  },

  toast: {
    success: 'Pomyślnie zlokalizowano!',
    error: 'Automatyczna lokalizacja nie jest możliwa!',
    automatic_changed_geolocation: 'Zmieniono lokalizację na: %{}',
  },

  buttons: {
    open_geolocation_modal: 'Zlokalizuj mnie',
    set_route: 'Nawiguj',
  },
};
