import React from 'react';

import {ControlsWizard} from '@ding/controls/src/';
import * as Steps from './Steps';

const RESET_PASSWORD_WIZARD_FIELDS = {
  FORM: 'FORM',
  EMAIL_SENT: 'EMAIL_SENT',
};

const SendEmailResetLinkSection = () => (
  <ControlsWizard>
    <ControlsWizard.Step id={RESET_PASSWORD_WIZARD_FIELDS.FORM}>
      {({onChangeTab}) => (
        <Steps.Form
          onEmailSent={
            ({email}) => onChangeTab(
              RESET_PASSWORD_WIZARD_FIELDS.EMAIL_SENT,
              {
                email,
              },
            )
          }
        />
      )}
    </ControlsWizard.Step>

    <ControlsWizard.Step id={RESET_PASSWORD_WIZARD_FIELDS.EMAIL_SENT}>
      <Steps.Confirm />
    </ControlsWizard.Step>
  </ControlsWizard>
);

SendEmailResetLinkSection.displayName = 'SendEmailResetLinkSection';

export default React.memo(SendEmailResetLinkSection);
