import React from 'react';

import {OAUTH_REGISTER_ROUTE_STATE} from '@ding/auth/src/constants/schemas';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import Section, {SectionCenteredContent} from '@ding/control-groups/src/Section';
import OAuthRegisterForm from './OAuthRegisterForm';

const OAuthRegisterSection = ({prevStepAuth}) => {
  const t = useTranslate();

  return (
    <Section
      title={
        t('website.auth.titles.create_account')
      }
      header={
        t('website.auth.titles.register_form')
      }
    >
      <SectionCenteredContent
        title={
          t('website.auth.oauth_register.accept_rules_to_register')
        }
      >
        <OAuthRegisterForm prevStepAuth={prevStepAuth} />
      </SectionCenteredContent>
    </Section>
  );
};

OAuthRegisterSection.displayName = 'OAuthRegisterSection';

OAuthRegisterSection.propTypes = {
  prevStepAuth: OAUTH_REGISTER_ROUTE_STATE.isRequired,
};

export default OAuthRegisterSection;
