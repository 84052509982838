import * as R from 'ramda';

/**
 * @example
 * Transfroms slide index from:
 *  0, 1, 2, 3, 4, 5
 *
 * to visible pages indices:
 *  [0], [1, 2], [3, 4], [5, 6], ..
 *
 * @param {Number} pagesPerSlide
 * @param {Number} slideIndex
 * @param {Number} totalSlides
 *
 * @returns {Number[]}  page indices
 */
export const slideToPagesIndices = (pagesPerSlide, slideIndex, totalPages) => {
  if (slideIndex < 0)
    return [];

  const visiblePage = (slideIndex * pagesPerSlide) - (slideIndex ? (pagesPerSlide - 1) : 0);

  // prevent last slide overflow if totalSlides provided
  if (visiblePage && (R.isNil(totalPages) || visiblePage + (pagesPerSlide - 1) < totalPages)) {
    return R.times(
      R.add(visiblePage),
      pagesPerSlide,
    );
  }

  // todo: Implement support for more pagesPerSlide modes
  return R.of(visiblePage); // cover page is single
};

/**
 * Transforms visible page index to slide number
 *
 * @example
 *  [0] [1, 2]  [3, 4]
 *  to
 *  0    1  1    2  2
 *
 * @param {Number} pagesPerSlide
 * @param {Number} pageIndex
 *
 * @returns {Number}  slide number
 */
export const pageIndexToSlide = (pagesPerSlide, pageIndex) => (
  pageIndex
    ? Math.floor((pageIndex + (pagesPerSlide - 1)) / pagesPerSlide)
    : 0
);
