import React from 'react';
import PropTypes from 'prop-types';

import {
  ITEM_TYPE_SCHEMA,
  ITEM_TYPES,
} from '@ding/constants';

import ProductLink from './ProductLink';
import ContractorLink from './ContractorLink';
import LeafletLink from './LeafletLink';

const LINK_COMPONENTS = {
  [ITEM_TYPES.PRODUCT]: ProductLink,
  [ITEM_TYPES.CONTRACTOR]: ContractorLink,
  [ITEM_TYPES.LEAFLET]: LeafletLink,
};

const KindItemLink = ({item, kind, renderChildrenIfUnknown, ...props}) => {
  const LinkComponent = LINK_COMPONENTS[kind || item?.kind];

  if (!LinkComponent)
    return renderChildrenIfUnknown ? props.children : null;

  return (
    <LinkComponent
      item={item}
      {...props}
    />
  );
};

KindItemLink.displayName = 'KindItemLink';

KindItemLink.propTypes = {
  kind: ITEM_TYPE_SCHEMA,
  renderChildrenIfUnknown: PropTypes.bool,
  item: PropTypes.shape({
    kind: ITEM_TYPE_SCHEMA,
  }),
};

export default KindItemLink;
