import React from 'react';
import * as R from 'ramda';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import Text from './Text';

const TextButton = styled.button(
  {
    base: {
      display: 'flex',
      alignItems: 'center',

      outline: 0,
      padding: 0,
      margin: 0,
      border: 0,
      background: 'none',
      color: 'inherit',
      cursor: 'pointer',
    },

    disabled: {
      opacity: 0.35,
      pointerEvents: 'none',
    },

    inline: {
      display: 'inline-flex',
    },
  },
  {
    type: 'button',
    omitProps: ['inline', 'eventData'],
    classSelector: (classes, {inline, disabled}) => [
      disabled && classes.disabled,
      inline && classes.inline,
    ],
  },
);

TextButton.displayName = 'TextButton';

TextButton.Primary = ({children, uppercase, weight, ...props}) => (
  <TextButton {...props}>
    <Text
      type='primary'
      weight={weight || 700}
      uppercase={R.defaultTo(true, uppercase)}
    >
      {children}
    </Text>
  </TextButton>
);

TextButton.Muted = ({children, ...props}) => (
  <TextButton {...props}>
    <Text type='muted'>
      {children}
    </Text>
  </TextButton>
);

export default TextButton;
