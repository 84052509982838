import React from 'react';
import {Route} from 'react-router-dom';

import {OAUTH_REGISTER_ROUTE_STATE} from '@ding/auth/src/constants/schemas';

import translate from '@ding/i18n';

import showUserToast from '@ding/controls/src/ToastAlert/types/showUserToast';
import {toBirthDateSelectValue} from '@ding/control-groups/src/Form/Groups/BirthDateSelect';

import OAuthActionMutation from '@ding/auth/src/gql/components/OAuthActionMutation';
import OAuthRegisterForm from './OAuthRegisterForm';

const toOAuthFormInitials = ({user}) => ({
  age: user.birthday && toBirthDateSelectValue(user.birthday),
});

export default
@translate
class OAuthRegisterFormContainer extends React.PureComponent {
  static propTypes = {
    prevStepAuth: OAUTH_REGISTER_ROUTE_STATE.isRequired,
  };

  onOAuthRegisterSubmit = (oauthAction, history) => async (formValue) => {
    const {t} = this.props;

    try {
      const {token} = await oauthAction.fetchOAuthToken(formValue);
      if (!token)
        throw new Error('Register fail, fetchOAuthToken should not be null!');

      // assign token and redirect
      await oauthAction.setTokenAndRefetch(token);
      history.push('/');

      showUserToast(
        t('website.auth.toast.login.success'),
      );
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const {prevStepAuth} = this.props;
    const initialData = toOAuthFormInitials(prevStepAuth);

    return (
      <Route
        render={({history}) => (
          <OAuthActionMutation prevStepAuth={prevStepAuth}>
            {({oauthAction}) => (
              <OAuthRegisterForm
                errors={oauthAction.errors}
                initialData={initialData}
                onSubmit={
                  this.onOAuthRegisterSubmit(oauthAction, history)
                }
              />
            )}
          </OAuthActionMutation>
        )}
      />
    );
  }
}
