import React from 'react';
import PropTypes from 'prop-types';

import DecoratedInput from '../Input/DecoratedInput';

/**
 * Input that fills with gray background and bold text
 * when user selectes autocomplete
 *
 * @export
 */
const AutocompleteInput = ({active, color, className, ...props}) => (
  <DecoratedInput
    color={color}
    {...props}

    // dropdown content is white and text field will be not visible
    {...color === 'light' && active && {
      color: 'lightGray',
    }}
  />
);

AutocompleteInput.displayName = 'AutocompleteInput';

AutocompleteInput.propTypes = {
  active: PropTypes.bool,
};

export default AutocompleteInput;
