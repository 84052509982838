import React from 'react';
import createIcon from '../utils/createIcon';

export default createIcon(
  'Geo',
  {
    viewBox: '0 0 18 24',
  },
  <>
    <path d='M14.292,12.25a2.042,2.042,0,1,1-2.042,2.042,2.042,2.042,0,0,1,2.042-2.042m0-2.042a4.083,4.083,0,1,0,4.083,4.083A4.083,4.083,0,0,0,14.292,10.208Z' transform='translate(-5.292 -5.26)' />
    <path d='M9,2a6.93,6.93,0,0,1,7,6.84,6.5,6.5,0,0,1-.6,2.76A49.45,49.45,0,0,1,9,21.19,50.08,50.08,0,0,1,2.6,11.6,6.5,6.5,0,0,1,2,8.84,6.93,6.93,0,0,1,9,2M9,0A8.93,8.93,0,0,0,0,8.84a8.49,8.49,0,0,0,.79,3.6A55.56,55.56,0,0,0,8.62,23.83a.51.51,0,0,0,.76,0,55.56,55.56,0,0,0,7.83-11.39A8.34,8.34,0,0,0,18,8.84,8.93,8.93,0,0,0,9,0Z' transform='translate(0 0.001)' />
  </>,
);
