import React from 'react';
import PropTypes from 'prop-types';

import {ControlsWizard} from '@ding/controls/src/';
import * as Steps from './Steps';

const REGISTER_WIZARD_FIELDS = {
  FORM: 'FORM',
  CONFIRM: 'CONFIRM',
};

export default class RegisterForm extends React.PureComponent {
  static propTypes = {
    onRegisterUser: PropTypes.func,
    onChangeModalAuthTab: PropTypes.func,
  };

  onSubmitRegistration = onChangeTab => async (value) => {
    const {
      onRegisterUser,
      onRequestHeadless,
    } = this.props;

    if (!onRegisterUser)
      return;

    try {
      await onRegisterUser(value);
      onRequestHeadless(true);
      onChangeTab(
        REGISTER_WIZARD_FIELDS.CONFIRM,
        {
          email: value.auth.email,
        },
      );
    } catch (e) {
      // errors
    }
  };

  render() {
    const {
      errors,
      onChangeModalAuthTab,
    } = this.props;

    return (
      <ControlsWizard>
        <ControlsWizard.Step id={REGISTER_WIZARD_FIELDS.FORM}>
          {({onChangeTab}) => (
            <Steps.Form
              errors={errors}
              onChangeModalAuthTab={onChangeModalAuthTab}
              onSubmit={
                this.onSubmitRegistration(onChangeTab)
              }
            />
          )}
        </ControlsWizard.Step>

        <ControlsWizard.Step id={REGISTER_WIZARD_FIELDS.CONFIRM}>
          {({tab: {transferredState}}) => (
            <Steps.Confirm email={transferredState.email} />
          )}
        </ControlsWizard.Step>
      </ControlsWizard>
    );
  }
}
