import React, {useContext, useState} from 'react';

const DingFeaturesContext = React.createContext({});

export const useDingFeatures = () => useContext(DingFeaturesContext);

export const ProvideDingFeatures = ({features, children}) => {
  const [mutableFeatures, setFeatures] = useState(features);

  const toggleFeature = (feature, value) => setFeatures(
    {
      ...mutableFeatures,
      [feature]: value,
    },
  );

  return (
    <DingFeaturesContext.Provider
      value={{
        ...mutableFeatures,
        toggleFeature,
      }}
    >
      {children}
    </DingFeaturesContext.Provider>
  );
};

export default DingFeaturesContext;
