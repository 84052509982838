import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import Layer from '@ding/core/src/components/Predefined/Layer';

const LeafletArchivedLayer = () => {
  const t = useTranslate();

  return (
    <Layer.Dark>
      {t('website.shared.cards.leaflet.archived')}
    </Layer.Dark>
  );
};

LeafletArchivedLayer.displayName = 'LeafletArchivedLayer';

export const GrayScaleLayer = () => <Layer.GrayScale />;

LeafletArchivedLayer.GrayScale = GrayScaleLayer;

export default LeafletArchivedLayer;
