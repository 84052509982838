import React from 'react';
import * as R from 'ramda';

import {MUTED_GRAY} from '@ding/constants';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import SearchLogoItem from './SearchLogoItem';
import SearchLink from './SearchLink';

const css = {
  base: {},
  contractor: {
    marginBottom: 2,
    textTransform: 'uppercase',
    color: MUTED_GRAY,
    fontSize: '8.5px',
  },
};

const SearchProductItem = ({classes, item}) => {
  const t = useTranslate();

  return (
    <SearchLogoItem
      item={item}
      linkComponent={SearchLink}
      name={(
        <>
          <div className={classes.contractor}>
            {item.contractor?.name}
          </div>
          <strong className={classes.name}>
            {item.name}
          </strong>
        </>
      )}
    >
      {(
        R.isNil(item.price)
          ? null
          : t('website.shopping_list.list.pln', [item.price.toFixed(2)])
      )}
    </SearchLogoItem>
  );
};

SearchProductItem.displayName = 'SearchProductItem';

export default injectClassesSheet(css)(SearchProductItem);
