import React from 'react';
import * as R from 'ramda';

import {withSidebarModal} from '@ding/controls/src/ResponsiveModalToggle';
import {trackComponent} from '@ding/interia';

import ShoppingListCountQuery from '@ding/shopping-list/src/gql/ShoppingListCountQuery';
import CounterIconButton from '@ding/controls/src/Button/CounterIconButton';
import ListIcon from '@ding/core/src/icons/navbar/ShoppingList';
import ShoppingListSidebar from '@ding/shopping-list';

import {HeaderItem} from '../../HeaderToolbar';

export const PRODUCTS_COUNTER_ICON_ID = 'shopping-list-counter';

const ProductsItem = (
  {
    t, innerRef, subtitle, type,
    toggled, iconProps, children,
    style, margin, onClick, neverInvert,
  },
) => {
  const title = t('website.shopping_list.buttons.open_shopping_list');

  return (
    <ShoppingListCountQuery alwaysRenderComponent>
      {count => (
        <HeaderItem
          style={style}
          innerRef={innerRef}
          subtitle={subtitle}
          margin={margin}
          onClick={onClick}
        >
          <CounterIconButton
            id={PRODUCTS_COUNTER_ICON_ID}
            title={title}
            aria-label={title}
            aria-haspopup
            type={type || 'light'}
            inverted={toggled && !neverInvert}
            icon={ListIcon}
            iconProps={{
              size: 'big',
              ...iconProps,
            }}
            count={count}
          />
          {children}
        </HeaderItem>
      )}
    </ShoppingListCountQuery>
  );
};

ProductsItem.displayName = 'ProductsItem';

export const withShoppingListBarTrigger = (
  {
    toggleProps = {
      uuid: 'shopping-list',
    },
  } = {},
) => R.compose(
  trackComponent(
    {
      onToggle({iwa, ga4}) {
        iwa.triggerShoppingList();
        ga4.menuClickShoppingList();
      },

      onUnmount({iwa}, props, {source}) {
        // only if user press X
        if (source === 'button')
          iwa.closeShoppingList();
      },
    },
  ),
  withSidebarModal(
    {
      modalContentComponent: ShoppingListSidebar,
      toggleProps: ({t}) => ({
        ...toggleProps,
        modalProps: {
          title: t('website.shared.titles.shopping_list'),
          padding: 'none',
        },
      }),
    },
  ),
);

export default withShoppingListBarTrigger()(ProductsItem);
