import React from 'react';
import {gql, GraphQLFragment} from '@ding/tiny-gql';

import CustomerSettingsFragment from '@ding/auth/src/gql/fragments/CustomerSettings.graphql';

import TinyGqlMutation from '@ding/tiny-gql/src/react/TinyGqlMutation';
import {
  settingsToFormValue,
  formValueToSettings,
} from './utils';

export const CHANGE_ACCOUNT_SETTINGS_MUTATION = new GraphQLFragment(
  'changeAccountSettingsMutation',
  gql`
    ${CustomerSettingsFragment}
    mutation changeAccountSettingsMutation(
      $settings: CustomerSettingsInput!,
      $removeEmpty: Boolean = false,
    ) {
      updateSettingsCustomer(input: {
        settings: $settings,
        removeEmpty: $removeEmpty,
      }) {
        errors
        settings {
          ...CustomerSettings
        }
      }
    }
  `,
);

const ChangeAccountSettingsMutation = ({children, ...props}) => (
  <TinyGqlMutation
    mutation={CHANGE_ACCOUNT_SETTINGS_MUTATION}
    {...props}
  >
    {(mutate, utils) => {
      const changeAccountSettings = async (value) => {
        const response = await mutate(
          {
            settings: formValueToSettings(value),
          },
        );

        return settingsToFormValue(response?.settings || {});
      };

      return children(changeAccountSettings, utils);
    }}
  </TinyGqlMutation>
);

ChangeAccountSettingsMutation.displayName = 'ChangeAccountSettingsMutation';

export default ChangeAccountSettingsMutation;
