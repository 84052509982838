import PropTypes from 'prop-types';
import styled from '@adretail/fast-stylesheet/src/react/styled';

const barStyle = {
  marginBottom: 10,
};

const absoluteStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 9,
};

const SectionHeaderToolbar = styled.div(
  {
    bar: barStyle,
    absolute: absoluteStyle,
  },
  {
    omitProps: ['type'],
    classSelector: (classes, {type}) => classes[type],
  },
);

SectionHeaderToolbar.displayName = 'SectionHeaderToolbar';

SectionHeaderToolbar.propTypes = {
  type: PropTypes.oneOf(['bar', 'absolute', 'auto']).isRequired,
};

export default SectionHeaderToolbar;
