import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import {injectClassesSheet} from '@adretail/fast-stylesheet/src/react';

import withESI from '@ding/server/src/cache/withESI';
import {Margin} from '@ding/core/src/components/Predefined';

import MobileHeaderHolder from './MobileHeaderHolder';
import HeaderToolbar from '../HeaderToolbar';
import HeaderLogo from '../Desktop/HeaderLogo';

import ProductItem from '../Desktop/Items/Products';
import NotificationItem from '../Desktop/Items/Notifications';
import SearchItem from './Items/Search';

import BottomBar from './BottomBar';

import GoToPageTop from './GoToPageTop';

import {HEADER_SIDEBRANDING_STYLES} from '../Desktop';
import LanguageHeaderAlert from '../LanguageHeaderAlert';

const Header = () => (
  <div
    className='page-header'
    role='presentation'
    style={{
      transition: 'top 600ms ease',
      width: '100%',
    }}
  >
    <LanguageHeaderAlert />
    <MobileHeaderHolder>
      {/* Logos */}
      <HeaderLogo />

      {/* Right menu */}
      <Margin left='auto'>
        <HeaderToolbar>
          <SearchItem />
          <ProductItem />
          <NotificationItem />
        </HeaderToolbar>
      </Margin>
    </MobileHeaderHolder>
    <GoToPageTop />
    <BottomBar />
  </div>
);

Header.propTypes = {
  withNav: PropTypes.bool,
};

Header.defaultProps = {
  withNav: true,
};

export default R.compose(
  injectClassesSheet(HEADER_SIDEBRANDING_STYLES, {index: 3}),
  withESI,
)(Header);
