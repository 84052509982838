import React from 'react';

import {WHITE, PRIMARY_FILL} from '@ding/constants/src/colorSchema';

import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {useTrackers} from '@ding/interia';

import flexCenter from '@ding/core/src/styles/flexCenter';
import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';

import HeaderToolbar from '../../HeaderToolbar';
import * as Items from './Buttons';

const css = {
  base: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    padding: [8, 5, 5],
    background: WHITE,
    color: '#4a4a4a',
    zIndex: 999,
    boxShadow: '0px -4px 15px 0px rgba(0,0,0,0.15)',
    borderTop: '1px solid #c3c3c3',
    paddingBottom: 'max(env(safe-area-inset-bottom), 5px)', // because of home bar on ios add extra bottom spacing
  },

  toolbar: {
    justifyContent: 'space-evenly',
    color: 'inherit',

    '& li button, a': {
      extend: flexCenter,
    },

    '& svg': {
      width: '100%',
      maxHeight: '100%',
      color: '#989898',
    },
  },

  primaryItem: {
    color: PRIMARY_FILL,

    '& svg': {
      color: PRIMARY_FILL,
    },
  },

  '@global': {
    '#react-hydrate-root': createBreakpointClasses(
      {
        xs: {
          paddingBottom: 56,
        },
        md: {
          paddingBottom: 'initial',
        },
      },
    ),
  },
};

const BottomBar = ({classes}) => {
  const t = useTranslate('website.bottom_bar');
  const {iwa, ga4} = useTrackers();

  return (
    <nav className={classes.base} aria-label='primary-navigation'>
      <HeaderToolbar className={classes.toolbar} role='menubar'>
        <li role='menuitem'>
          <Items.Leaflets
            subtitle={t('leaflets')}
            onClick={
              () => {
                iwa.bottomMenuClick({name: 'gazetki'});
                ga4.menuClickLeaflets();
              }
            }
          />
        </li>
        <li role='menuitem'>
          <Items.Products
            subtitle={t('products')}
            onClick={
              () => {
                iwa.bottomMenuClick({name: 'oferty'});
                ga4.menuClickProducts();
              }
            }
          />
        </li>
        <li role='menuitem'>
          <Items.Search
            subtitle={t('search')}
            onClick={
              () => iwa.bottomMenuClick({name: 'szukaj'})
            }
          />
        </li>
        <li role='menuitem'>
          <Items.Blog
            subtitle={t('blog')}
            onClick={
              () => {
                iwa.bottomMenuClick({name: 'blog'});
                ga4.menuClickBlogs();
              }
            }
          />
        </li>
        <li role='menuitem'>
          <Items.Menu
            subtitle={t('menu')}
            onClick={
              () => {
                iwa.bottomMenuClick({name: 'wiecej'});
                ga4.menuClickDotModal();
              }
            }
          />
        </li>
      </HeaderToolbar>
    </nav>
  );
};

BottomBar.displayName = 'BottomBar';

export default injectClassesSheet(css)(BottomBar);
