export default {
  search: {
    placeholder: 'Dopisz coś do listy',
  },
  list: {
    empty: 'Brak produktów na liście zakupów',
    pln: '%{} zł',
    items: '%{} szt.',
  },
  footer: {
    to_buy_count: 'Do kupienia:',
    sum_price: 'Za',
  },
  item: {
    pcs: '%{} x %{} szt.',
    save: 'zapisz',
    counter_label: 'szt.',
    offers_count: 'Ofert',
    promotion_end_date: 'Ważne do %{}',
  },
  floating_edit_box: {
    name: 'Nazwa',
    your_name_of_product: 'Moja nazwa oferty wyciętej z gazetki',
    default_name: 'Oferta z gazetki %{contractor}, %{name}, str. %{page}',
    price: {
      label: 'Cena',
      suffix: 'zł',
    },
    quantity: {
      label: 'Ilość',
      suffix: 'szt.',
    },
    save: 'Zapisz',
    add: 'Dodaj',
    cancel: 'Anuluj',
  },
  toasts: {
    item_has_been_saved: {
      success: 'Zmiany zostały zapisane',
      error: 'Błąd podczas zapisywania zmian :(',
    },
    items_have_been_removed: {
      success: 'Produkty zostały usunięte',
      error: 'Błąd podczas usuwania produktów :(',
    },
  },
  buttons: {
    open_shopping_list: 'Otwórz listę zakupową',
    add_to_shopping_list: 'Dodaj do listy',
    in_shopping_list: 'Na liście',
    email_share_list: 'Prześlij listę na e-mail',
    remove_all_done: 'Usuń wszystkie',
    remove_product_from_list: 'Usuń produkt z listy',
  },
  toast: {
    item_added_to_list: {
      success: 'Produkt dodany do Twojej listy zakupów',
      error: 'Nastąpił problem podczas dodawania produktu do listy',
    },
    item_removed_form_list: {
      success: 'Produkt usunięty z Twojej listy zakupowej',
      error: 'Nastąpił problem podczas usuwania produktu z listy',
    },
  },
  modals: {
    email_share_list: {
      title: 'Wyślij listę na e-mail',
      sent_title: 'Wysłano!',

      type_email: 'Wpisz adres e-mail, pod który chcesz wysłać listę:',
      list_sent_to_email: 'List zakupowa została wysłana na adres %{}',

      buttons: {
        send: 'Wyślij',
      },
    },

    select_from_leaflet: {
      title: 'Dodaj do listy zakupowej',
      description: 'Możesz dodać zaznaczony przez siebie obszar do listy zakupowej i nazwać go dowolnie',
    },
  },
  tag: {
    products: {
      title: 'w ofertach (%{}):',
    },
    leaflet_pages: {
      title: 'w gazetkach (%{}):',
    },
  },
};
