import * as R from 'ramda';
import base64 from '@adretail/basic-helpers/src/base/base64';

const beaerToken = R.unless(
  R.isNil,
  R.concat('Bearer '),
);

const basicAuthToken = R.compose(
  R.concat('Basic '),

  // encoding pair
  base64.btoa,
  R.join(':'),
  R.reject(R.isNil),

  // it support also arrays
  R.unless(
    R.is(Array),
    ({email, password}) => ([email, password]),
  ),
);

const createAuthorizationHeaders = (bearer, basicAuth) => {
  let token = beaerToken(bearer);
  if (basicAuth && !R.isEmpty(basicAuth))
    token += `, ${basicAuthToken(basicAuth)}`;

  return {
    Authorization: token,
  };
};

export default createAuthorizationHeaders;
