import React from 'react';

import env from '@cdd/internal-server/src/constants/env';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {
  Text,
  Margin,
} from '@ding/core/src/components/Predefined';

import FooterColumn from './FooterColumn';

const FooterDescription = () => {
  const t = useTranslate();
  const description = t(
    'website.footer.description',
    {
      address: env.shared.website.titleAddress,
    },
  );

  return (
    <FooterColumn md={12} lg={12}>
      <Margin top={1}>
        <Text.Muted
          size='small'
          justify
        >
          <span
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </Text.Muted>
      </Margin>
    </FooterColumn>
  );
};

FooterDescription.displayName = 'FooterDescription';

export default FooterDescription;
