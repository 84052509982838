import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {CREATE_FAVOURITE_INPUT_SCHEMA} from '@ding/notifications/src/constants/schemas';
import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';
import * as FAVOURITE_TYPES from '@ding/notifications/src/constants/favouriteTypes';

import capitalize from '@adretail/basic-helpers/src/base/capitalize';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import withOptimisticLinkedValue from '@adretail/basic-decorators/src/inputs/withOptimisticLinkedValue';
import {trackComponent} from '@ding/interia';

import TitledCheckbox from '@ding/controls/src/CheckBox/TitledCheckbox';
import showNotificationsUserToast from '../../helpers/showNotificationsUserToast';

export const useNotificationsToggleToast = (favouriteCreateInfo, toastModalProps) => {
  const t = useTranslate('website.notifications_list.toast');

  return active => showNotificationsUserToast(
    ({error}) => (
      error
        ? t('error')
        : t(`${active ? 'subscribed_to_list' : 'unsubscribed_from_list'}.${favouriteCreateInfo.kind}`, [capitalize(favouriteCreateInfo.name)])
    ),
    {
      type: active ? 'success' : 'dark',
      ...toastModalProps,
    },
  );
};

const FavouriteTrackedSwitchHolder = ({
  white, size, ringAnim,
  muteInactive, children,
  allowTitleToggle,
  iconProps,
  style,
  value,
  l,
  triggerComponent,
  favouriteCreateInfo,
  createCustomerFavourite,
  destroyCustomerFavourite,
  toggleRef,
  titleRef,
  muteWhenActive,
  buttonProps,
  toastModalProps,
}) => {
  const TriggerComponent = triggerComponent || TitledCheckbox;
  const showNotificationToggleToast = useNotificationsToggleToast(
    favouriteCreateInfo, toastModalProps,
  );

  const onChange = async (newValue) => {
    // it should never happen but prevent from assign to the same mutation
    if (value === newValue)
      return;

    // is checked
    l.onOptimisticEvent(
      {
        queueUUID: 'promise',
        optimisticValue: newValue,
        promiseFn: () => (
          newValue
            ? createCustomerFavourite
            : destroyCustomerFavourite
        )(
          R.omit(['source'], favouriteCreateInfo),
        ),
      },
    );

    // notification
    showNotificationToggleToast(newValue);
  };

  return (
    <TriggerComponent
      toggleRef={toggleRef}
      titleRef={titleRef}
      style={style}
      type='bell'
      muteWhenActive={muteWhenActive}
      buttonProps={buttonProps}
      ringAnim={ringAnim}
      white={white}
      iconProps={iconProps}
      muteInactive={
        R.defaultTo(true, muteInactive)
      }
      value={value}
      size={size}
      allowTitleToggle={allowTitleToggle}
      onChange={onChange}
    >
      {children}
    </TriggerComponent>
  );
};

FavouriteTrackedSwitchHolder.propTypes = {
  allowTitleToggle: PropTypes.bool,

  triggerComponent: REACT_COMPONENT_CLASS_SCHEMA,
  favouriteCreateInfo: CREATE_FAVOURITE_INPUT_SCHEMA.isRequired,

  createCustomerFavourite: PropTypes.func,
  destroyCustomerFavourite: PropTypes.func,
};

FavouriteTrackedSwitchHolder.defaultProps = {
  allowTitleToggle: true,

  createCustomerFavourite: () => {},
  destroyCustomerFavourite: () => {},
};

export default R.compose(
  trackComponent(
    {
      onChange({ga4, iwa}, {favouriteCreateInfo: {kind, name, source}}, value) {
        if (kind === FAVOURITE_TYPES.CONTRACTOR) {
          ga4.toggleContractorNotifications({
            contractorName: source.name,
            toggled: value,
          });

          iwa.toggleContractorNotifications(
            {
              toggled: value,
              contractorId: source.id,
              origin: source.origin,
            },
          );
        } else {
          iwa.toggleTagNotifications(
            {
              toggled: value,
              name,
            },
          );
        }
      },
    },
  ),
  withOptimisticLinkedValue(
    {
      waitForQueueResolve: true,
    },
  ),
)(FavouriteTrackedSwitchHolder);
