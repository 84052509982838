import * as R from 'ramda';

import {createBlankValue} from '@ding/controls/src/Autocomplete/Autocomplete';

import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';
import useSearchItemRedirectHandler from '../hooks/useSearchItemRedirectHandler';

const PageSearchEnterListener = ({l, value, children}) => {
  const redirectToItem = useSearchItemRedirectHandler();

  return children(
    {
      ...l.input(),

      onKeyPress: (e) => {
        if (e.charCode !== 13)
          return;

        const item = R.when(R.is(String), createBlankValue)(value);
        if (!item.name)
          return;

        redirectToItem(item);
      },
    },
  );
};

PageSearchEnterListener.displayName = 'PageSearchEnterListener';

export default linkedInputs(null, true)(PageSearchEnterListener);
