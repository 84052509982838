import React from 'react';
import PropTypes from 'prop-types';

import {
  ITEM_TYPES,
  ITEM_TYPE_SCHEMA,
} from '@ding/constants';

import FavouriteItemSwitch from '@ding/notifications/src/containers/FavouriteItemSwitch';
import SearchLogoItem from './SearchLogoItem';
import SearchLink from './SearchLink';
import SearchProductItem from './SearchProductItem';

const SearchKindItem = ({item, ...props}) => {
  if (item.kind === ITEM_TYPES.PRODUCT) {
    return (
      <SearchProductItem
        item={item}
        {...props}
      />
    );
  }

  return (
    <SearchLogoItem
      {...props}
      linkComponent={SearchLink}
      item={item}
    >
      <FavouriteItemSwitch item={item} />
    </SearchLogoItem>
  );
};

SearchKindItem.displayName = 'SearchKindItem';

SearchKindItem.propTypes = {
  item: PropTypes.shape({
    kind: ITEM_TYPE_SCHEMA.isRequired,
  }).isRequired,
};

export default SearchKindItem;
