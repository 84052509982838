import * as R from 'ramda';
import {gql, GraphQLFragment} from '@ding/tiny-gql';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import TinyGqlQuery, {FETCH_POLICY} from '@ding/tiny-gql/src/react/TinyGqlQuery';

export const CUSTOMER_NOTIFICATIONS_COUNT_QUERY = new GraphQLFragment(
  'getCustomerNotificationsCount',
  gql`
    query getCustomerNotificationsCount @locale(lang: ${APP_LANGUAGE}) {
      customerNotifications {
        unseenCount
      }
    }
  `,
);

export default provideProps(
  {
    query: CUSTOMER_NOTIFICATIONS_COUNT_QUERY,
    clientFetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    allowSSR: false,
    responseSelector: R.pathOr(0, ['customerNotifications', 'unseenCount']),
  },
)(TinyGqlQuery);
