import styled from '@adretail/fast-stylesheet/src/react/styled';

const HiddenCheckboxInput = styled.input(
  {
    position: 'absolute',
    top: 0,
    left: 0,

    zIndex: 0,
    width: '100%',
    height: '100%',

    opacity: 0,
    margin: 0,
    padding: 0,
    cursor: 'inherit',

    pointerEvents: 'none',
  },
  {
    type: 'checkbox',
  },
);

HiddenCheckboxInput.displayName = 'HiddenCheckboxInput';

export default HiddenCheckboxInput;
