import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'ArrowBack',
  {
    viewBox: '0 0 50 50',
  },
  <g>
    <path d='M25.3525 20.3505C25.1575 20.1566 24.8425 20.1566 24.6475 20.3505L10.3567 34.5577C10.16 34.7532 10.16 35.0714 10.3567 35.2669L10.6899 35.5982C11.47 36.3737 12.73 36.3737 13.5101 35.5982L25 24.1754L36.4899 35.5982C37.27 36.3737 38.53 36.3737 39.3101 35.5982L39.6433 35.2669C39.84 35.0714 39.84 34.7532 39.6433 34.5577L25.3525 20.3505Z' />
    <rect x='11' y='13' width='28' height='3' />
    <path fillRule='evenodd' clipRule='evenodd' d='M11 18H22.0517L19.1034 21H11V18ZM30.8966 21H39V18H27.9483L30.8966 21Z' />
    <path d='M4 21.0633C4 21.5275 4.18437 21.9726 4.51256 22.3008C4.84075 22.629 5.28587 22.8133 5.75 22.8133C6.21413 22.8133 6.65925 22.629 6.98744 22.3008C7.31563 21.9726 7.5 21.5275 7.5 21.0633V12.4167C7.5 10.8067 8.80667 9.5 10.4167 9.5H39.5833C41.1933 9.5 42.5 10.8067 42.5 12.4167V21.0633C42.5 21.5275 42.6844 21.9726 43.0126 22.3008C43.3407 22.629 43.7859 22.8133 44.25 22.8133C44.7141 22.8133 45.1592 22.629 45.4874 22.3008C45.8156 21.9726 46 21.5275 46 21.0633V12.4167C46 10.7149 45.324 9.08276 44.1206 7.8794C42.9172 6.67604 41.2851 6 39.5833 6H10.4167C8.71486 6 7.08276 6.67604 5.8794 7.8794C4.67604 9.08276 4 10.7149 4 12.4167V21.0633Z' />
  </g>,
);
