import {useCookies} from '@adretail/basic-components/src/Context/SSRCookiesProvider';
import cookieEnv from '@ding/constants/src/envCookieConfig';

const useLayoutVisitsCounter = () => {
  const cookies = useCookies();

  const getVisitsCount = () => {
    const count = cookies.get(cookieEnv.visitsCounter.name);
    return Number.parseInt(count, 10) || 0;
  };

  const incrementVisits = () => {
    const newVisits = getVisitsCount() + 1;

    cookies.set(
      cookieEnv.visitsCounter.name,
      newVisits,
      cookieEnv.visitsCounter,
    );

    return newVisits;
  };

  return {
    getVisitsCount,
    incrementVisits,
  };
};

export default useLayoutVisitsCounter;
