import React from 'react';
import createIcon from '../utils/createIcon';

export default createIcon(
  'Search',
  {
    viewBox: '0 0 24 24',
  },
  <>
    <rect
      width='24'
      height='24'
      fill='none'
    />
    <path
      d='M15.851,14.887l.072-.091a7.2,7.2,0,1,0-1.132,1.13l.091-.072,4.969,4.966.082.084.966-.968Zm.264-4.574A5.807,5.807,0,1,1,10.309,4.5a5.808,5.808,0,0,1,5.806,5.812v0'
      fill='currentColor'
    />
  </>,
);
