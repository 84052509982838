import * as R from 'ramda';

const _toBasicString = (y, m = 0, d = 1) => {
  const year = y;
  const month = (m + 1).toString().padStart(2, '0');
  const day = d.toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const padFormatDate = (...args) => {
  if (R.is(Date, args[0])) {
    const date = args[0];

    return _toBasicString(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    );
  }

  return _toBasicString(...args);
};

export default padFormatDate;
