import * as ITEM_TYPES from './itemTypes';
import * as CALENDAR_TYPES from './calendarTypes';
import * as PRODUCT_TEMPLATE_TYPES from './productTemplateItemType';
import * as NOTIFICATION_SOURCES from './notificationSources';

export * from './typeSchema';
export * from './colorSchema';

export const MIN_CUSTOMER_AGE = 16;

export const TIME_SUFFIXES = {
  SECONDS: 'SECONDS',
  MINUTES: 'MINUTES',
  HOURS: 'HOURS',
  DAYS: 'DAYS',
  WEEKS: 'WEEKS',
  MONTHS: 'MONTHS',
  YEARS: 'YEARS',
};

export {
  ITEM_TYPES,
  CALENDAR_TYPES,
  PRODUCT_TEMPLATE_TYPES,
  NOTIFICATION_SOURCES,
};
