import React from 'react';
import PropTypes from 'prop-types';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import TimesIcon from '@ding/core/src/icons/Times';
import {Margin} from '@ding/core/src/components/Predefined';

import RectangleIconButton from '../Button/RectangleIconButton';

const TagButtonHolder = styled(
  RectangleIconButton,
  {
    padding: [0, 15],
    textTransform: 'initial',
  },
  {
    index: 3,
  },
);

const TagButton = ({children, onRemove, ...props}) => (
  <TagButtonHolder
    type='secondary'
    radius='small'
    textAlign='left'
    iconSpacing='auto'
    icon={(
      <TimesIcon
        size='tiny-xs'
        onClick={onRemove}
      />
    )}
    {...props}
  >
    <Margin right={2}>
      {children}
    </Margin>
  </TagButtonHolder>
);

TagButton.displayName = 'TagButton';

TagButton.propTypes = {
  onRemove: PropTypes.func,
};

export default TagButton;
