import React, {createRef} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import suppressEvent from '@adretail/basic-helpers/src/inputs/suppressEvent';
import triggerEvent from '@adretail/basic-helpers/src/inputs/triggerEvent';

import {HeaderItem} from '@ding/layout/src/components/LayoutParts/Header/HeaderToolbar';
import {IconButton} from '@ding/controls/src/';

import {
  Flex,
  Margin,
} from '@ding/core/src/components/Predefined';

const ToolbarButton = React.forwardRef(
  ({
    tagName, redirectClickToIcon,
    active, margin, type, iconType,
    text, children, headerItemProps, onClick,
    onClose,
    ...props
  }, ref) => {
    const iconRef = createRef(null);
    const content = (
      <>
        <IconButton
          innerRef={
            (innerRef) => {
              iconRef.current = innerRef;
            }
          }
          type={iconType || (
            active
              ? 'primary'
              : 'dark'
          )}
          {...tagName === 'a' && {
            component: 'span',
          }}
          {...props}
        />
        {children}
        {text && (
          <Margin
            left={2}
            {...redirectClickToIcon && {
              onClick: (e) => {
                suppressEvent(e);
                triggerEvent(
                  ReactDOM.findDOMNode(iconRef.current).firstChild,
                  'click',
                );
              },
            }}
          >
            {text}
          </Margin>
        )}
      </>
    );

    return (
      <HeaderItem
        ref={ref}
        component={tagName}
        margin={
          margin || 'medium'
        }
        type={
          type || 'horizontal'
        }
        onClick={(e) => {
          onClick(e);
          onClose?.();
        }}
        {...headerItemProps}
      >
        {(
          text
            ? (
              <Flex
                align='center'
                justify='flex-start'
                row
              >
                {content}
              </Flex>
            )
            : content
        )}
      </HeaderItem>
    );
  },
);

ToolbarButton.displayName = 'ToolbarButton';

ToolbarButton.propTypes = {
  redirectClickToIcon: PropTypes.bool, // if true, onClick is called on icon
  active: PropTypes.bool,
  tagName: PropTypes.any,
  type: PropTypes.string,
  iconType: PropTypes.string,
};

ToolbarButton.defaultProps = {
  tagName: 'li',
};

export default ToolbarButton;
