import ViewerLang from './viewer';
import ProductLang from './product';
import ShoppingListLang from './shoppingList';
import NotificationsListLang from './notificationsList';
import MapLang from './map';
import AuthLang from './auth';
import AccountSettingsLang from './accountSettings';
import GeolocationLang from './geolocation';
import StoreLang from './store';
import ContractorLang from './contractor';
import TagLang from './tag';
import HintsLang from './hints';
import VoteLang from './vote';
import StoriesLang from './stories';
import LeafletsLang from './leaflets';
import LanguageLang from './language';
import advertisementLang from './advertisement';

export default {
  auth: AuthLang,
  product: ProductLang,
  viewer: ViewerLang,
  map: MapLang,
  shopping_list: ShoppingListLang,
  notifications_list: NotificationsListLang,
  account_settings: AccountSettingsLang,
  geolocation: GeolocationLang,
  store: StoreLang,
  contractor: ContractorLang,
  tag: TagLang,
  hints: HintsLang,
  vote: VoteLang,
  stories: StoriesLang,
  leaflets: LeafletsLang,
  language: LanguageLang,
  advertisement: advertisementLang,
};
