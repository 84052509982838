import createLinkComponent from '../../utils/createLinkComponent';
import {
  optionalNamePairSegment,
  optionalLinkSegment,
} from '../../utils/optionalSegment';

export const CONTRACTORS_URL_SCHEMA = [
  '/sklepy/:categoryName,:categoryId(\\d+)/:page(\\d+)?',
  '/sklepy/:page(\\d+)?',
];

export const genContractorsURL = ({page, category} = {}) => (
  `/sklepy${optionalNamePairSegment(category)}${optionalLinkSegment(page)}`
);

export default createLinkComponent(
  [
    'page',
    'category',
  ],
  (_, props) => genContractorsURL(props),
);
