import React from 'react';

import {
  MUTED_GRAY,
  PRIMARY_FILL,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import {
  CheckScaledIcon,
  squareHolderSizes,
} from './SquareHolder';

const SquareOutlineHolder = styled(
  ({active, children, parentProps, ...props}) => (
    <div {...props}>
      {active && (
        <CheckScaledIcon />
      )}
      {children}
    </div>
  ),
  {
    base: {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      textAlign: 'center',
      borderRadius: 4,
      border: `2px solid ${MUTED_GRAY}`,
      transition: 'all 400ms ease-in-out',

      '&:hover': {
        borderColor: PRIMARY_FILL,
      },

      '& > i': {
        top: '45%',
        left: '50%',
        width: 12.5,
        transform: 'translate(-50%, -50%)',
      },
    },

    ...squareHolderSizes,

    active: {
      background: [PRIMARY_FILL, '!important'],
      borderColor: PRIMARY_FILL,
    },
  },
  {
    index: 2,
    classSelector: (classes, {active, size}) => [
      active && classes.active,
      classes[size || 'small'],
    ],
  },
);

SquareOutlineHolder.displayName = 'SquareOutlineHolder';

export default SquareOutlineHolder;
