import React from 'react';
import {STOP_MODAL_PROPAGATION_LISTENERS} from '@adretail/basic-helpers/src/inputs/suppressEvent';

const ModalEventSuppress = ({children}) => (
  <div
    {...STOP_MODAL_PROPAGATION_LISTENERS}
    style={{
      display: 'none',
    }}
  >
    {children}
  </div>
);

export default ModalEventSuppress;
