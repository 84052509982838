import * as R from 'ramda';

import {PRODUCT_SEARCH_PARAM} from '@ding/slide-viewer/src/Viewer/constants/searchParams';

import {parameterize} from '@ding/core/src/helpers';
import createLinkComponent from '../../utils/createLinkComponent';

export const LEAFLET_URL_SCHEMA = '/gazetka/:contractor-:name,:id(\\d+)/:page(\\d+)?';

export const genLeafletURL = (
  {id, name, contractor},
  page = 1,
  product = null,
) => (
  `/gazetka/${parameterize(contractor.name)}-${parameterize(name)},${id}/${page}${product ? `#${PRODUCT_SEARCH_PARAM}=${R.defaultTo(product.id, product.productId)}` : ''}`
);

export default createLinkComponent(
  'leaflet',
  (leaflet, {page, product}) => leaflet && genLeafletURL(
    leaflet,
    R.defaultTo(
      (leaflet.originPage?.index || 0) + 1,
      page,
    ),
    product,
  ),
);
