import React from 'react';
import LazyHydrate from 'react-lazy-hydration';

import {DefaultContractorsGridSection} from '@ding/cards/src/Contractor/Section';
import SelectedForUserLeafletsSection from '@ding/cards/src/Leaflet/Section/SelectedForUserLeafletsSection';
import {Layout} from '@ding/layout/src/components/LayoutParts';

import PageLoadingErrorMetaTags from './PageLoadingErrorMetaTags';
import PageLoadingErrorContent from './PageLoadingErrorContent';

//NOTE: Remember that PageLoadingError is inside main chunk so imports of index.js(x) files
// can impact size of main chunk!
const PageLoadingError = () => (
  <Layout>
    <PageLoadingErrorMetaTags />

    <PageLoadingErrorContent />

    <LazyHydrate ssrOnly>
      <SelectedForUserLeafletsSection small />
      <DefaultContractorsGridSection dark />
    </LazyHydrate>
  </Layout>
);

PageLoadingError.displayName = 'PageLoadingErrorPage';

export default React.memo(PageLoadingError);
