import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import LeafletCard from './LeafletCard';

const CurrentlyVisibleLeafletCard = (props) => {
  const t = useTranslate();

  return (
    <LeafletCard
      {...props}
      showLogo={false}
      showDate={false}
      background='primary'
      title={
        t('website.shared.cards.leaflet.currently_visible')
      }
    />
  );
};

export default React.memo(CurrentlyVisibleLeafletCard);
