import React from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';
import * as R from 'ramda';

import {OAUTH_PROVIDER_SCHEMA} from '@ding/auth/src/constants/schemas';

import translate from '@ding/i18n';
import suppressEvent from '@adretail/basic-helpers/src/inputs/suppressEvent';

import showUserToast from '@ding/controls/src/ToastAlert/types/showUserToast';
import {genOAuthRegisterURL} from '../Layout/Links/OAuthRegisterLink';

import OAuthActionMutation from '../../gql/components/OAuthActionMutation';

export default
@translate
class OAuthButtonProvider extends React.PureComponent {
  static propTypes = {
    provider: OAUTH_PROVIDER_SCHEMA.isRequired,

    onFetchUserInfo: PropTypes.func.isRequired, // downloads email etc

    // post login callbacks
    onBeforeLoadToken: PropTypes.func,
    onBeforeRegisterRedirect: PropTypes.func,
  };

  onTriggerOAuth = (oauthAction, history) => async (e) => {
    const {
      t,
      provider,
      onFetchUserInfo,
      onBeforeRegisterRedirect,
      onBeforeLoadToken,
    } = this.props;

    suppressEvent(e);

    const {
      user,
      authData,
    } = await onFetchUserInfo();

    const auth = {
      provider,
      keys: R.pick(['expiresIn', 'accessToken'], authData),
      user: R.pick(['id', 'birthday', 'email'], user),
    };

    // check if user has already account
    const {alreadyRegistered, token} = await oauthAction.fetchOAuthToken(null, auth, true);
    if (alreadyRegistered) {
      showUserToast(
        t('website.auth.toast.email_already_taken'),
        {
          type: 'danger',
        },
      );
      return;
    }

    if (token) {
      showUserToast(
        t('website.auth.toast.login.success'),
      );

      await oauthAction.setFreshLogin(true);
      await onBeforeLoadToken?.(); // wait until all animations are done
      await oauthAction.setTokenAndRefetch(token);

      return;
    }

    // if no - redirect to register Page and provide state to it
    await onBeforeRegisterRedirect?.(); // wait until all animations are done
    history.push(
      {
        pathname: genOAuthRegisterURL(),
        state: {
          prevStepAuth: auth,
        },
      },
    );
  };

  render() {
    const {children} = this.props;

    return (
      <Route
        render={({history}) => (
          <OAuthActionMutation>
            {({oauthAction}) => children(
              {
                onTriggerOAuth: this.onTriggerOAuth(oauthAction, history),
              },
            )}
          </OAuthActionMutation>
        )}
      />
    );
  }
}
