import React from 'react';
import c from 'classnames';

import {PRIMARY_FILL} from '@ding/constants';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import TextButton from '@ding/core/src/components/Predefined/TextButton';

const css = {
  base: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'inherit',

    '& i, svg': {
      color: 'inherit !important',
    },
  },

  active: {
    color: PRIMARY_FILL,
  },

  title: {
    marginTop: 4,
    fontWeight: 600,
    fontSize: '10px',
  },
};

const BottomBarButton = ({active, classes, component, children, subtitle, title, onClick}) => {
  const Component = component || TextButton;

  return (
    <Component
      className={c(
        classes.base,
        active && classes.active,
      )}
      title={title}
      aria-label={title}
      onClick={onClick}
    >
      {children}

      <div className={classes.title}>
        {subtitle}
      </div>
    </Component>
  );
};

BottomBarButton.displayName = 'BottomBarButton';

export default injectClassesSheet(css)(BottomBarButton);
