import styled from '@adretail/fast-stylesheet/src/react/styled';

const Paragraph = styled(
  'p',
  {
    margin: [0, 0, 10, 0],
    padding: 0,
  },
);

Paragraph.displayName = 'Paragraph';

export default Paragraph;
