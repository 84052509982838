import * as R from 'ramda';

import geolocationEnv from '@ding/geolocation/src/constants/geolocationEnv';
import {safeParseCoords} from '../../helpers/parseCoordsKeys';

import {GEOLOCATION_QUERY} from '../../gql/GeolocationQuery';

/**
 * Fetches HTML5 coords as promise, in dev mode return default
 *
 * @returns {Promise}
 */
const getHTML5GeolocationPromise = () => new Promise(
  (resolve, reject) => {
    // geolocation not work on localhost with HTTP address, test only
    if (process.env.NODE_ENV === 'development') {
      const {defaultCoords} = geolocationEnv;

      resolve(
        {
          coords: {
            latitude: defaultCoords.lat,
            longitude: defaultCoords.lon,
          },
        },
      );
    } else
      navigator.geolocation.getCurrentPosition(resolve, reject);
  },
);

/**
 * @param {TinyGQLClient} gqlClient
 *
 * @returns {Address}
 */
const getHTML5BasedAddress = async (gqlClient) => {
  const coords = R.compose(
    safeParseCoords,
    R.prop('coords'),
  )(
    await getHTML5GeolocationPromise(),
  );

  return (await gqlClient.query(GEOLOCATION_QUERY, {coords}))?.geoLocation;
};

export default getHTML5BasedAddress;
