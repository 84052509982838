import React from 'react';

import {Margin} from '@ding/core/src/components/Predefined';
import {PredefinedInputs} from '@ding/controls/src/';
import {RecaptchaLinkRow} from '@ding/auth/src/client/Recaptcha';
import {SubmitFormRow} from '@ding/control-groups/src/Form';

import FormRow from '@ding/control-groups/src/Form/FormRow';
import SendEmailButton from './SendEmailButton';

const EmailResetPasswordGroup = ({l, loading}) => (
  <>
    <FormRow>
      <PredefinedInputs.EMail
        required
        {...l.input('email')}
      />
    </FormRow>

    <FormRow>
      <RecaptchaLinkRow />
    </FormRow>

    <Margin
      top={4}
      block
    >
      <SubmitFormRow
        buttonComponent={SendEmailButton}
        loading={loading}
      />
    </Margin>
  </>
);

EmailResetPasswordGroup.displayName = 'EmailResetPasswordGroup';

export default EmailResetPasswordGroup;
