import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  REACT_COMPONENT_CLASS_SCHEMA,
  ID_SCHEMA,
} from '@adretail/schemas';

import customResultFind from '@adretail/basic-helpers/src/functors/customResultFind';
import createContextPack from '@ding/tiny-context-state';
import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';

import {
  RelativeContainer,
  Margin,
} from '@ding/core/src/components/Predefined';

import DefaultErrorMessage from './Messages/DefaultErrorMessage';
import DecoratedInput from './DecoratedInput';

export const createMessageValidator = (validatorFn, message) => (...args) => (
  validatorFn(...args)
    ? {status: true} // no error
    : {status: false, message} // error
);

const createBlankErrorLog = () => ({
  status: true,
  message: null,
});

const wrapBoolResultToLog = R.when(
  R.either(
    R.complement(R.is)(Object),
    R.complement(R.has)('status'),
  ),
  status => ({
    message: null,
    status: !!status,
  }),
);

/**
 * Executes array of validators, if any of them fails
 * stop on it and return message with status
 */
const runValidatorsArray = validators => (value) => {
  const flag = customResultFind(
    (nestedValidator) => {
      const result = nestedValidator && nestedValidator(value);

      if (result === false || (result && result.status === false))
        return wrapBoolResultToLog(result);

      return null;
    },
    validators,
  );

  return R.defaultTo(true, flag);
};

/**
 * Detects if provided executor is function or array,
 * if array - it must be composition of validators
 */
const validatorExecutor = (validatorFn, value) => {
  const executor = (
    R.is(Array, validatorFn)
      ? runValidatorsArray(validatorFn)
      : validatorFn
  );

  return R.compose(
    wrapBoolResultToLog,
    executor,
  )(value);
};

export const {
  Context: ValidationContext,
  Consumer: ValidationGroupConsumer,
  Provider: ValidationGroupProvider,
} = createContextPack(
  {
    initialState: {},
    selectors: {
      isValidated: () => R.either(
        R.isEmpty,
        R.compose(
          R.all(
            R.equals(true),
          ),
          R.values,
        ),
      ),
    },
    actions: {
      setValidationFlag: (uuid, flag) => R.unless(
        R.propEq(uuid, flag),
        R.assoc(uuid, flag),
      ),

      removeValidationFlag: uuid => R.when(
        R.has(uuid),
        R.omit([uuid]),
      ),
    },
  },
);

export default
@linkedInputs(null, true)
class ValidatedInput extends React.Component {
  static uuidCounter = 0;

  static propTypes = {
    inputComponent: REACT_COMPONENT_CLASS_SCHEMA,
    errorComponent: REACT_COMPONENT_CLASS_SCHEMA,

    inputRenderFn: PropTypes.func,

    showErrorMessage: PropTypes.bool,
    validateEmpty: PropTypes.bool,
    validatorFn: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.func,
        ]),
      ),
      PropTypes.func,
    ]),
    validationUUID: ID_SCHEMA,
  };

  static defaultProps = {
    inputComponent: DecoratedInput,
    errorComponent: DefaultErrorMessage,

    showErrorMessage: true,
    validateEmpty: false,
    validatorFn: createBlankErrorLog,
    validationUUID: null,
  };

  static contextType = ValidationContext;

  inputContainerRef = React.createRef();

  state = {
    prevValue: null,
    validationUUID: null,
    errorLog: createBlankErrorLog(),
  };

  static getDerivedStateFromProps(props, state) {
    const {
      validatorFn,
      value,
      validateEmpty,
    } = props;

    const newValidationUUID = (
      state.validationUUID
        || props.validationUUID
        || `input-${ValidatedInput.uuidCounter++}`
    );

    const updateData = {
      prevValue: props.value,
      validationUUID: newValidationUUID,
    };

    if (!R.isNil(value) && (value || validateEmpty) && state.prevValue !== value)
      updateData.errorLog = validatorExecutor(validatorFn, value);
    else {
      const newUUID = newValidationUUID !== state.validationUUID;

      updateData.errorLog = (
        newUUID || !value
          ? createBlankErrorLog()
          : state.errorLog
      );
    }

    return updateData;
  }

  componentDidUpdate({value: prevValue}) {
    const {value} = this.props;
    const {
      removeValidationFlag,
      setValidationFlag,
    } = this.context;

    if (!R.equals(value, prevValue)) {
      const {
        validationUUID,
        errorLog: {
          status,
          message,
        },
      } = this.state;

      const node = this.inputContainerRef;
      const input = node.querySelector('input, textarea');

      // update context, if present
      if (!status && setValidationFlag) {
        setValidationFlag(
          validationUUID,
          status,
        );
      } else if (status && removeValidationFlag)
        removeValidationFlag(validationUUID);

      input && input.setCustomValidity(
        status
          ? ''
          : R.defaultTo('', message),
      );
    }
  }

  componentWillUnmount() {
    const {validationUUID} = this.state;
    const {removeValidationFlag} = this.context;

    if (removeValidationFlag)
      removeValidationFlag(validationUUID);
  }

  render() {
    const {
      inputComponent: InputComponent,
      errorComponent: ErrorComponent,

      inputRenderFn,

      l,
      validatorFn,
      validationUUID,
      validateEmpty,
      showErrorMessage,

      ...props
    } = this.props;

    const {errorLog} = this.state;
    const hasError = !errorLog.status;

    const inputProps = {
      ...props,
      ...l.input(),
      error: hasError,
    };

    const input = (
      inputRenderFn
        ? inputRenderFn(inputProps)
        : <InputComponent {...inputProps} />
    );

    return (
      <RelativeContainer
        innerRef={(node) => {
          this.inputContainerRef = node;
        }}
      >
        {input}
        {hasError && showErrorMessage && (
          <Margin top={1}>
            <ErrorComponent
              errorLog={errorLog}
              value={props.value}
            />
          </Margin>
        )}
      </RelativeContainer>
    );
  }
}
