import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import UserIcon from '@ding/core/src/icons/User';
import PrimaryMenuItem from '../PrimaryMenuItem';

const LoginMenuItem = (props) => {
  const t = useTranslate();

  return (
    <PrimaryMenuItem
      icon={UserIcon}
      title={
        t('website.auth.titles.login')
      }
      {...props}
    />
  );
};

LoginMenuItem.displayName = 'LoginMenuItem';

export default LoginMenuItem;
