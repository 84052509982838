import {useCallback} from 'react';
import * as R from 'ramda';

import {useAggregateMutation} from '../../gql/AggregateEventMutation';

const createAggregatorHookPartial = (kind, paramsParserFn = R.identity) => () => {
  const [mutate] = useAggregateMutation();

  return useCallback(
    params => mutate(
      {
        kind,
        params: paramsParserFn(params),
      },
    ),
    [mutate],
  );
};

export default createAggregatorHookPartial;
