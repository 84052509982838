import React from 'react';
import PropTypes from 'prop-types';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {GQLWorthToSeeGrid} from '@ding/cards/src/Leaflet/Grid';
import {Grid, Text} from '@ding/core/src/components/Predefined';

const WorthToSeeHeader = styled(
  Text,
  {
    margin: [5, 0],
  },
);

export const DefaultWorthToSeeHeader = (props) => {
  const t = useTranslate();

  return (
    <Grid.Column xs={12}>
      <WorthToSeeHeader
        weight={700}
        center
        uppercase
        block
        {...props}
      >
        {t('website.viewer.info.worth_to_see')}
      </WorthToSeeHeader>
    </Grid.Column>
  );
};

const WorthToSeeSearchGrid = ({withTitle, headerComponent: GridHeader, ...props}) => (
  <GQLWorthToSeeGrid
    emptyPlaceholderComponent={null}
    skeletonLoading
    headerRenderFn={
      list => (
        withTitle && list.length > 0 && (
          <GridHeader />
        )
      )
    }
    itemProps={{
      size: 'square-small',
      showTitles: false,
      bordered: true,
    }}
    gridConfig={{
      xs: 6,
    }}
    {...props}
  />
);

WorthToSeeSearchGrid.displayName = 'WorthToSeeSearchGrid';

WorthToSeeSearchGrid.propTypes = {
  headerComponent: REACT_COMPONENT_CLASS_SCHEMA,
  noPadding: PropTypes.bool,
  withTitle: PropTypes.bool,
};

WorthToSeeSearchGrid.defaultProps = {
  headerComponent: DefaultWorthToSeeHeader,
  withTitle: true,
};

export default React.memo(WorthToSeeSearchGrid);
