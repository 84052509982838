import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import {PROPS_SCHEMA} from '@adretail/schemas';
import {
  WHITE,
  PRIMARY_FILL,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import flexCenterStyle from '@ding/core/src/styles/flexCenter';

import {popupZDepth} from '@ding/core/src/styles/zDepth';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import withLockDocumentScroll from '@adretail/basic-decorators/src/content/withLockDocumentScroll';

import BasicModal from '@adretail/basic-components/src/BasicModal';
import {Header} from '@ding/core/src/components/Predefined';

import CloseCornerButton from '../../Button/Predefined/CloseCornerButton';
import CustomScrollbars from '../../CustomScrollbars';

export const PRIMARY_HEADER_HEIGHT = 126;

export const PrimaryHeaderHolder = styled.div(
  {
    base: {
      extend: flexCenterStyle,

      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: PRIMARY_HEADER_HEIGHT,
      borderRadius: 'inherit',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      lineHeight: '18px',
      fontSize: 16,
      background: PRIMARY_FILL,
      color: WHITE,
      fontWeight: 700,
    },

    'padding-big': {
      padding: [30, 40],
    },

    'padding-small': {
      padding: [0, 10],
    },
  },
  {
    omitProps: ['padding'],
    classSelector: (classes, {padding}) => classes[`padding-${padding}`],
  },
);

const css = {
  modal: {
    extend: popupZDepth,

    position: 'relative',
    minHeight: 200,
    margin: '40px auto',
    padding: 50,
    background: WHITE,
    borderRadius: 4,
  },

  'padding-medium': {
    padding: '50px 30px 30px',
  },

  noMargin: {
    margin: 0,
  },

  header: createBreakpointClasses(
    {
      xs: {
        fontSize: '20px',
      },

      md: {
        fontSize: '27px',
      },
    },
  ),
};

export const RoundedModalHolder = injectClassesSheet(css)(
  ({
    title, children, style,
    classes, noMargin, padding,
    closeButtonProps,
    onClose,
  }) => (
    <div
      style={style}
      className={c(
        classes.modal,
        noMargin && classes.noMargin,
        padding && classes[`padding-${padding}`],
      )}
    >
      <CloseCornerButton
        onClick={
          onClose && (() => onClose(
            {
              source: 'button',
            },
          ))
        }
        {...closeButtonProps}
      />

      {title && (
        <Header.H1 className={classes.header}>
          {title}
        </Header.H1>
      )}

      {children}
    </div>
  ),
);

RoundedModalHolder.displayName = 'RoundedModalHolder';

/**
 * Modal with background
 *
 * @export
 */
const DesktopPopup = ({
  style, centered, title, padding, modalProps,
  closeButtonProps, children, onUnmount,
}) => (
  <BasicModal
    {...modalProps}
    holderStyle={{
      background: 'rgba(0, 0, 0, 0.8)',
      ...modalProps?.holderStyle,
      ...centered && {
        alignItems: 'center',
      },
    }}
    onUnmount={onUnmount}
  >
    {onClose => (
      <CustomScrollbars>
        <RoundedModalHolder
          noMargin={centered}
          {...{
            padding,
            closeButtonProps,
            title,
            style,
            onClose,
          }}
        >
          {children(onClose)}
        </RoundedModalHolder>
      </CustomScrollbars>
    )}
  </BasicModal>
);

DesktopPopup.displayName = 'DesktopPopup';

DesktopPopup.propTypes = {
  closeButtonProps: PROPS_SCHEMA,
  title: PropTypes.string,
  centered: PropTypes.bool,
  onUnmount: PropTypes.func,
};

export default withLockDocumentScroll(DesktopPopup);
