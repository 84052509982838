export const SORT_GET_PARAM = 'sortBy';

export const SORT_OPTIONS = {
  POPULARITY: 'POPULARITY',
  DISTANCE: 'DISTANCE',
  PUB_DATE: 'PUB_DATE',
  END_DATE: 'END_DATE',
  NAME: 'NAME',
  SIZE: 'SIZE',
  POSITION: 'POSITION',
  PRICE_ASC: 'PRICE_ASC',
  PRICE_DESC: 'PRICE_DESC',
};

export const SORT_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const DEFAULT_SORT_OPTIONS = [
  SORT_OPTIONS.POPULARITY,
  SORT_OPTIONS.DISTANCE,
  SORT_OPTIONS.NAME,
];
