import React from 'react';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import cookieToggleable from '@adretail/basic-decorators/src/base/cookieToggleable';

import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';

import IconButton from '@ding/controls/src/Button/IconButton';
import flexCenter from '@ding/core/src/styles/flexCenter';
import TimesIcon from '@ding/core/src/icons/Times';

const ExpandedAlert = styled.div(
  {
    extend: flexCenter,

    width: '100%',
    flex: 1,
    fontWeight: 600,

    color: '#856404',
    background: '#fff3cd',
    border: '1px solid #ffeeba',
    position: 'relative',
    zIndex: 1000,
    fontSize: '12.5px',

    ...createBreakpointClasses({
      xs: {
        height: 55,
        flexDirection: 'column',

      },
      md: {
        height: 30,
        flexDirection: 'row',
      },
    }),
  },
  {
    role: 'alert',
  },
);

ExpandedAlert.displayName = 'ExpandedAlert';

export default ExpandedAlert;

const Wrapper = styled.div(
  {
    position: 'absolute',
    right: 25,

    ...createBreakpointClasses({
      xs: {
        top: 12,
      },
      md: {
        top: 5,
      },
    }),
  },
);

const ToggleableExpandedAlert = ({children, toggled, onToggle, onClearToggle, ...props}) => {
  if (toggled)
    return null;

  return (
    <ExpandedAlert {...props}>
      {children(onToggle)}
      <Wrapper>
        <IconButton
          titled
          type='none'
          size='tiny'
          iconSize='tiny-xs'
          icon={TimesIcon}
          onClick={onToggle}
        />
      </Wrapper>
    </ExpandedAlert>
  );
};

export const CookieToggleableExpandedAlert = cookieToggleable({
  cookiePropName: 'toggleableCookieConfig',
  config: {
    renderToggled: true,
  },
})(ToggleableExpandedAlert);
