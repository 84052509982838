export default {
  title: 'Aplikacja mobilna',

  scroll_sections: [
    {
      headerTag: 'H1',
      header: '<span class="text-primary">Oszczędzaj</span> na zakupach z aplikacją Ding!',
      items: [
        '<strong>Największa baza gazetek promocyjnych</strong>',
        'Łatwe wyszukiwanie promocji w gazetkach promocyjnych',
        '<strong>Dopasowanie ofert</strong> do Twoich upodobań i potrzeb',
        'Najnowsze <strong>promocje na produkty</strong> spożywcze, kosmetyczne, produkty dla dzieci, z kategorii RTV i AGD i wiele innych korzystnych ofert',
      ],
    },

    {
      header: '<span class="text-primary">Stały wgląd</span> w bieżące promocje i okazje',
      items: [
        '<strong>Wszystkie gazetki promocyjne</strong> na wyciągnięcie ręki',
        'Sprawdzaj gazetki ulubionych sklepów bez zakładania konta',
        'Gazetki i produkty promocyjne z Biedronki, Lidla, Auchan i innych sieci zawsze <strong>w Twoim telefonie</strong>',
      ],
    },

    {
      header: 'Wygodne i <span class="text-primary">udane zakupy</span> z aplikacją Ding!',
      items: [
        'Przejrzysta <strong>lista zakupów</strong>',
        'Zawsze aktualny <strong>kalendarz niedzieli handlowych</strong>',
        'Spersonalizowane <strong>powiadomienia</strong> o ulubionych produktach',
      ],
    },
  ],

  download_jumbotron: {
    header: 'Pobierz <span class="text-primary">darmową aplikację</span> Ding!',
    content: [
      '<strong>Z aplikacją Ding</strong> nie przegapisz najlepszych promocji.',
      'Stwórz własną listę ulubionych produktów i sieci i <strong>nie przepłacaj więcej w sklepie!</strong>',
    ],
  },

  opinions: {
    header: 'Opinie naszych użytkowników:',
    list: [
      {
        id: 1,
        stars: 4,
        nick: 'Alina Ryczkowska',
        date: '2022-03-03',
        content: 'Fajna apka wszystkie sklepy w jednym miejscu,wszystkie promocje',
      },
      {
        id: 2,
        stars: 5,
        nick: 'Cezary D',
        date: '2022-02-25',
        content: 'Najlepsza apka z gazetkami!',
      },
      {
        id: 3,
        stars: 5,
        nick: 'Cimci Rimci',
        date: '2022-02-16',
        content: 'Przydatna aplikacja nic dodać nic ująć',
      },
      {
        id: 4,
        stars: 5,
        nick: 'Jane Shepard',
        date: '2022-01-12',
        content: 'Wreszcie jakaś apka która pomoże mi znaleźć sklepy z promocją na awokado 🙂 Bardzo pożyteczna!',
      },
      {
        id: 5,
        stars: 5,
        nick: 'Małgorzata Lubik',
        date: '2022-01-12',
        content: 'Jestem bardzo zadowolona z aplikacji.🙂',
      },
    ],
  },
};
