import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {validatePasswordToFlags} from '@ding/controls/src/Input/Predefined/ValidatedPasswordInput';
import {
  Grid,
  Text,
  Margin,
} from '@ding/core/src/components/Predefined';

import {RecaptchaForm} from '@ding/auth/src/client/Recaptcha';
import SetPasswordInputGroup from './SetPasswordInputGroup';
import HightlightedFlagsList from './HightlightedFlagsList';

const ChangePasswordForm = ({errors, ...props}) => {
  const t = useTranslate();

  return (
    <RecaptchaForm {...props}>
      {loading => (l, value) => (
        <Grid>
          <Grid.DividedColumn>
            <SetPasswordInputGroup
              {...{
                value,
                errors,
                loading,
                l,
              }}
            />
          </Grid.DividedColumn>

          <Grid.DividedColumn
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Margin bottom={4}>
              <Text
                size='medium'
                weight={700}
              >
                {`${t('website.auth.titles.correct_password')}:`}
              </Text>
            </Margin>

            <HightlightedFlagsList
              flags={{
                length: t('website.auth.validation.length'),
                letter: t('website.auth.validation.letter'),
                special: t('website.auth.validation.special'),
              }}
              hightlight={
                validatePasswordToFlags(value?.passwords?.master)
              }
            />
          </Grid.DividedColumn>
        </Grid>
      )}
    </RecaptchaForm>
  );
};

ChangePasswordForm.displayName = 'ChangePasswordForm';

export default ChangePasswordForm;
