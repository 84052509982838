import React from 'react';
import PropTypes from 'prop-types';

import {WHITE} from '@ding/constants/src/colorSchema';
import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';
import styled from '@adretail/fast-stylesheet/src/react/styled';
import {extendedDropdownZDepth} from '@ding/core/src/styles/zDepth';

import ExpandedSearchInput from './ExpandedSearchInput';
import PageSearchGroupedLists from './PageSearchGroupedLists';
import GQLPageSearchAutocomplete from './GQLPageSearchAutocomplete';

const ExpandedDropdownHolder = styled.div(
  {
    extend: extendedDropdownZDepth,

    position: 'absolute',
    left: 0,
    width: '100%',
    background: WHITE,
    zIndex: 1001,
    maxHeight: '50vh',
    overflowY: 'auto',
  },
);

export const ExpandedAutocompleteDropdown = React.forwardRef(({
  animStyle,
  layerOffset,
  children,
}, ref) => (
  <ExpandedDropdownHolder
    innerRef={ref}
    style={{
      ...animStyle,
      top: layerOffset,
    }}
  >
    {children}
  </ExpandedDropdownHolder>
));

/**
 * It is used as a head in some panels(e.g. viewer)
 *
 * @export
 */
const ExpandedSearchAutocomplete = ({
  style, className, showPopupWhenEmptyPhrase, dropdownComponent,
  dropdownContentMargin, resetOnSelect, ...props
}) => (
  <GQLPageSearchAutocomplete
    style={style}
    className={className}
    inputComponent={ExpandedSearchInput}
    inputProps={props}
    dropdownComponent={dropdownComponent}
    fetchWhenEmptyPhrase={false}
    showPopupWhenEmptyPhrase={showPopupWhenEmptyPhrase}
    dropdownContentMargin={dropdownContentMargin}
    resetOnSelect={resetOnSelect}
  >
    {(searchData, value, {onSelectItem}) => (
      <PageSearchGroupedLists
        phrase={value?.name}
        searchData={searchData}
        onSelectItem={onSelectItem}
      />
    )}
  </GQLPageSearchAutocomplete>
);

ExpandedSearchAutocomplete.displayName = 'ExpandedSearchAutocomplete';

ExpandedSearchAutocomplete.propTypes = {
  showPopupWhenEmptyPhrase: PropTypes.bool,
  resetOnSelect: PropTypes.bool,
  dropdownComponent: REACT_COMPONENT_CLASS_SCHEMA,
};

ExpandedSearchAutocomplete.defaultProps = {
  showPopupWhenEmptyPhrase: false,
  resetOnSelect: true,
  dropdownComponent: ExpandedAutocompleteDropdown,
};

export default ExpandedSearchAutocomplete;
