import React from 'react';
import createIcon from '../utils/createIcon';

export default createIcon(
  'Blog',
  {
    viewBox: '0 0 24 24',
  },
  <>
    <rect fill='none' width={24} height={24} />
    <path fill='currentColor' d='M11.415,8.805a.757.757,0,1,1-.757.757h0a.756.756,0,0,1,.756-.756h0m2.646.757a.758.758,0,1,0,.759-.757h0a.755.755,0,0,0-.756.755h0m-6.814,0A.758.758,0,1,0,8.007,8.8h0a.755.755,0,0,0-.756.755h0M20.9,16.66a2.271,2.271,0,0,1-3.211,3.211l-4.155-4.145a.786.786,0,0,1-.192-.316l-.9-2.98a.758.758,0,0,1,.927-.949l3.057.846a.758.758,0,0,1,.333.194Zm-6.161-1.874,2.8,2.8,1.071-1.071-2.787-2.778-1.537-.426Zm5.094,2.944-.147-.146-1.072,1.072.146.145a.758.758,0,1,0,1.072-1.072h0' />
    <path fill='currentColor' d='M12.443,16.559H9.856L9.314,17.63l-.541-1.071h-4.1a.907.907,0,0,1-.907-.906V5.7a.909.909,0,0,1,.907-.9H18.148a.91.91,0,0,1,.908.907v7.169l1.3,1.294V5.7a2.2,2.2,0,0,0-2.2-2.2H4.672a2.206,2.206,0,0,0-2.2,2.2v9.95h0a2.2,2.2,0,0,0,2.2,2.2h3.3L9.313,20.5h0l.8-1.59.535-1.056h3.088Z' />
  </>,
);
