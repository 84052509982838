import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';
import {CREATE_FAVOURITE_INPUT_SCHEMA} from '@ding/notifications/src/constants/schemas';
import {
  ITEM_TYPE_SCHEMA,
  CUSTOMER_FAVOURITABLE_ITEM_SCHEMA,
} from '@ding/constants';

import {stopPropagation} from '@adretail/basic-helpers/src/inputs/suppressEvent';
import castToFavouriteKind from '@ding/notifications/src/helpers/castToFavouriteKind';

import IconButton from '@ding/controls/src/Button/IconButton';
import ArrowRightIcon from '@ding/core/src/icons/ArrowRight';
import {Margin} from '@ding/core/src/components/Predefined';

import {useTempFavouritesState} from '../context/TempFavouritesState';
import useCustomerFavouriteMutations from '../gql/hooks/useCustomerFavouriteMutations';

import FavouriteTrackedSwitchHolder from './Parts/FavouriteTrackedSwitchHolder';

/**
 * Transforms object with normal kind to object with notification kind
 *
 * @param {Object} item
 * @param {NotificationKind} kind
 */
export const castItemToFavouriteDescription = (item, kind) => ({
  name: item.name || (R.is(String, item) ? item : null),
  kind: castToFavouriteKind(item.kind || kind),
  source: item,
});

/**
 * Authorized checkbox behves normal - it has logic,
 * TempFavouritesStateConsumer is cache - do not refetch all
 * queries if one checkbox changed, just update temp store
 */
const AuthorizedItemSwitch = ({
  allowTitleToggle,
  createInfo,
  item,
  children,
  onChange,
  ...props
}) => {
  const mutations = useCustomerFavouriteMutations();

  const {
    isFavouriteItem,
    setFavouriteItemState,
  } = useTempFavouritesState();

  const cachedFlag = isFavouriteItem(createInfo);

  return (
    <FavouriteTrackedSwitchHolder
      createCustomerFavourite={mutations.create}
      destroyCustomerFavourite={mutations.destroy}

      favouriteCreateInfo={createInfo}
      allowTitleToggle={allowTitleToggle}
      value={
        R.defaultTo(
          item.customerFlags?.favourite,
          cachedFlag,
        )
      }
      onChange={
        (value) => {
          setFavouriteItemState(createInfo, value);
          onChange?.(createInfo, value); // eslint-disable-line no-unused-expressions
        }
      }
      {...props}
    >
      {children}
    </FavouriteTrackedSwitchHolder>
  );
};

/**
 * It adds support to item {id, kind} decoding inside FavouriteSwitch
 *
 * @export
 */
const FavouriteItemSwitch = ({
  kind,
  item,
  favouriteCreateInfo,
  children,
  authCheck,
  linkPhrase,
  linkComponent: Link,
  triggerComponent,
  ...props
}) => {
  const createInfo = favouriteCreateInfo || castItemToFavouriteDescription(item, kind);
  const safeItem = item || createInfo;

  if (R.isNil(createInfo.name))
    return null;

  let title = children;
  if (Link) {
    const rightArrow = (
      <Margin left={1}>
        <IconButton
          icon={ArrowRightIcon}
          iconSize='tiny'
          type='primary'
          title={title}
          aria-label={title}
          // fix bassline auto property for inline-block when you dont use text
          style={{display: 'block'}}
          iconStyle={{display: 'block'}}
        />
      </Margin>
    );

    title = (
      <Link
        rel='dofollow'
        item={safeItem}
        style={{
          display: 'inherit',
        }}
        onClick={stopPropagation}
        accented
      >
        {children}
        {rightArrow}
      </Link>
    );
  }

  return (
    <AuthorizedItemSwitch
      linkComponent={Link}
      allowTitleToggle={
        !Link || !linkPhrase
      }
      {...props}
      {...{
        item: safeItem,
        createInfo,
        triggerComponent,
      }}
    >
      {title}
    </AuthorizedItemSwitch>
  );
};

FavouriteItemSwitch.displayName = 'FavouriteItemSwitch';

FavouriteItemSwitch.propTypes = {
  kind: ITEM_TYPE_SCHEMA,
  authCheck: PropTypes.bool,
  item: CUSTOMER_FAVOURITABLE_ITEM_SCHEMA,
  linkPhrase: PropTypes.bool,
  linkComponent: REACT_COMPONENT_CLASS_SCHEMA,
  favouriteCreateInfo: CREATE_FAVOURITE_INPUT_SCHEMA,
};

export default React.memo(FavouriteItemSwitch);
