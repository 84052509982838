import React from 'react';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import flexCenter from '@ding/core/src/styles/flexCenter';
import {Text} from '@ding/core/src/components/Predefined';

const EmptySearchResultHolder = styled(
  Text.Muted,
  {
    extend: flexCenter,
    width: '100%',
    margin: '10px 0',
    textAlign: 'center',
    fontSize: 14,
  },
);

const EmptySearchResult = ({phrase, ...props}) => {
  const t = useTranslate();

  return (
    <EmptySearchResultHolder {...props}>
      {t('website.placeholders.empty_search_result', phrase)}
    </EmptySearchResultHolder>
  );
};

EmptySearchResult.displayName = 'EmptySearchResult';

export default React.memo(EmptySearchResult);
