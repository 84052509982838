export default {
  stores_by_city: {
    header: '%{} sklepy według miast',
  },

  city_stores: {
    header: '%{} sklepy w %{}',
    other_stores_header: 'Inne sklepy w %{}',
  },

  nearest_stores: {
    header: '%{} sklepy w Twojej okolicy',
    other_stores_header: 'Sklepy innych sieci w Twojej okolicy',
  },

  open_hours: {
    header: 'Godziny otwarcia',

    buttons: {
      see_sunday_calendar: 'Zobacz kalendarz niedziel handlowych',
    },
  },

  address_map: {
    store_localization: 'Lokalizacja sklepu',
  },

  titles: {
    store_not_exists: 'Ten sklep już nie istnieje',
  },
};
