import React from 'react';
import createIcon from '../utils/createIcon';

export default createIcon(
  'Shops',
  {
    viewBox: '0 0 27.236 23.834',
  },
  <>
    <path d='M687.314,310.945l-3.991-7.021a.868.868,0,0,0-.744-.436h-17.54a.85.85,0,0,0-.744.437l-3.985,7.019a.882.882,0,0,0-.116.422,4.055,4.055,0,0,0,2.3,3.65v11.449a.863.863,0,0,0,.858.857H684.26a.863.863,0,0,0,.857-.858V315.027a4.062,4.062,0,0,0,2.313-3.659A.8.8,0,0,0,687.314,310.945Zm-5.237-5.739,2.892,5.089h-22.32l2.887-5.089Zm3.547,6.8a2.331,2.331,0,0,1-4.479,0Zm-8.915,4.235h-5.755A2.464,2.464,0,0,0,668.5,318.7v6.906h-4.29v-10.19h.03a4.045,4.045,0,0,1,6.382,0,4.04,4.04,0,0,1,6.377,0,4.04,4.04,0,0,1,6.377,0h.024V325.6h-4.238v-6.9A2.462,2.462,0,0,0,676.709,316.246Zm-6.083-2.556h0a2.339,2.339,0,0,1-2.243-1.684h4.482A2.326,2.326,0,0,1,670.626,313.69Zm6.38,0H677a2.326,2.326,0,0,1-2.237-1.684h4.479A2.324,2.324,0,0,1,677.006,313.69Zm-12.873,0A2.318,2.318,0,0,1,662,312.006h4.482a2.33,2.33,0,0,1-2.241,1.684h0Zm8.95,11.908h-2.861v-6.9a.736.736,0,0,1,.736-.735h2.081Zm4.369-6.9v6.9h-2.76l-.046-7.638h2.07A.738.738,0,0,1,677.452,318.7Z' transform='translate(-660.194 -303.488)' />
  </>,
);
