import * as R from 'ramda';

const areBlankCoords = R.anyPass(
  [
    R.isNil,
    R.complement(R.is(Object)),
    ({lat, lon}) => !lat || !lon,
  ],
);

export default areBlankCoords;
