import {showPromiseToast} from '@ding/controls/src/ToastAlert/showToast';
import renderFunctionalChildren from '@adretail/basic-helpers/src/ui/renderFunctionalChildren';
import omitProps from '@adretail/basic-decorators/src/base/omitProps';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import ssr from '@adretail/basic-helpers/src/base/ssr';

export const writeToClipboard = text => !ssr && navigator.clipboard.writeText(text);

const copyTextToClipboard = ({text, toastText, toastProps}) => (Component) => {
  const t = useTranslate();

  const toastMessage = (error) => {
    const value = renderFunctionalChildren(toastText, error);
    if (!value || (value.props && !value.props.children)) {
      return t(`website.shared.toasts.copied_text.${error ? 'failed' : 'success'}`);
    }
    return value;
  };

  const onClick = (onCopied) => {
    showPromiseToast(
      error => toastMessage(error),
      toastProps,
      () => writeToClipboard(text),
    );

    onCopied();
  };

  return (
    provideProps(
      ({onCopied}) => ({
        onClick: () => onClick(onCopied || null),
      }),
    )(omitProps(['onCopied'])(Component))
  );
};

export default copyTextToClipboard;
