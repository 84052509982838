import React from 'react';
import {useRouteMatch} from 'react-router-dom';

import ProductsIcon from '@ding/core/src/icons/bottom-bar/Products';
import ProductsLink, {PRODUCTS_URL_SCHEMA} from '@ding/layout/src/components/Links/ProductsLink';
import BottomBarButton from '../BottomBarButton';

const ProductsItem = ({subtitle, onClick}) => {
  const match = useRouteMatch(PRODUCTS_URL_SCHEMA);

  return (
    <BottomBarButton
      active={!!match?.isExact}
      component={ProductsLink}
      subtitle={subtitle.name}
      title={subtitle.title}
      onClick={onClick}
    >
      <ProductsIcon size='medium' />
    </BottomBarButton>
  );
};

ProductsItem.displayName = 'ProductsItem';

export default ProductsItem;
