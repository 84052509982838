import {parameterize} from '@ding/core/src/helpers';
import {languages} from '@ding/i18n/src/languages';

import createLinkComponent, {optionalLinkSegment} from '../../utils/createLinkComponent';

export const AUTHOR_URL_SCHEMA = `${languages.uk.prefix}/blog/avtory/:author,:id(\\d+)/:page(\\d+)?`;

export const genAuthorURL = ({id, name}, {page} = {}) => (
  `${languages.uk.prefix}/blog/avtory/${parameterize(name)},${id}${optionalLinkSegment(page)}`
);

export default createLinkComponent(
  'author',
  genAuthorURL,
);
