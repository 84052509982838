import React from 'react';
import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import SearchIcon from '@ding/core/src/icons/Search';
import {LogoPlain} from '@ding/controls/src/Autocomplete/Items';

const SearchLogoItem = provideProps(
  {
    defaultIcon: <SearchIcon type='muted' />,
  },
)(LogoPlain);

SearchLogoItem.displayName = 'SearchLogoItem';

export default SearchLogoItem;
