import {gql, GraphQLFragment} from '@ding/tiny-gql';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import useMutation from '@ding/tiny-gql/src/react/hooks/useMutation';

import TinyGqlMutation from '@ding/tiny-gql/src/react/TinyGqlMutation';

export const AGGREGATE_EVENT_MUTATION = new GraphQLFragment(
  'aggregateEventMutation',
  gql`
    mutation aggregateEventMutation(
      $kind: AggregateEventKind!,
      $params: Hash,
    ) {
      aggregateEvent(input: {
        kind: $kind,
        params: $params,
      }) {
        errors
      }
    }
  `,
);

export const useAggregateMutation = () => useMutation(AGGREGATE_EVENT_MUTATION, {silent: true});

export default provideProps(
  {
    mutation: AGGREGATE_EVENT_MUTATION,
  },
)(TinyGqlMutation);
