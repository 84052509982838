import * as R from 'ramda';

import encodeUrl from '@adretail/basic-helpers/src/url/encodeUrl';
import {parameterize} from '@ding/core/src/helpers';

export const optionalLinkBlock = divider => R.ifElse(
  R.either(
    R.isEmpty,
    R.isNil,
  ),
  R.always(''),
  R.concat(divider),
);

export const optionalLinkSegment = optionalLinkBlock('/');
export const optionalLinkSearch = R.compose(
  optionalLinkBlock('?'),
  R.when(
    R.is(Object),
    encodeUrl,
  ),
);

export const parameterizedLinkSegment = R.compose(
  optionalLinkSegment,
  parameterize,
);

export const optionalNamePairSegment = pair => (
  optionalLinkSegment(pair && `${parameterize(pair.name)},${pair.id}`)
);
