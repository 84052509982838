import {OAUTH_REGISTER_URL_SCHEMA} from '../Links/OAuthRegisterLink';
import {RESET_PASSWORD_URL_SCHEMA} from '../Links/ResetPasswordLink';
import {CONFIRM_ACCOUNT_URL_SCHEMA} from '../Links/ConfirmAccountLink';

import AuthConditionalRoute from '../../AuthConditionalRoute';

import ResetPassword from './ResetPassword';
import OAuthRegister from './OAuthRegister';
import ConfirmAccount from './ConfirmAccount';

export default [
  // Auth
  {
    path: CONFIRM_ACCOUNT_URL_SCHEMA,
    component: ConfirmAccount,
  },

  {
    path: OAUTH_REGISTER_URL_SCHEMA,
    component: OAuthRegister,
    routeComponent: AuthConditionalRoute.GuestOnly,
  },

  {
    path: RESET_PASSWORD_URL_SCHEMA,
    component: ResetPassword,
    routeComponent: AuthConditionalRoute.GuestOnly,
  },
];
