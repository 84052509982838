import React from 'react';
import PropTypes from 'prop-types';

import {useUAConditionalFlags} from '@adretail/basic-components/src/Context/UAProvider';
import Margin from './Margin';

const UAMargin = ({margins, ...props}) => {
  const uaProps = useUAConditionalFlags(margins || {});

  return (
    <Margin
      {...props}
      {...uaProps}
    />
  );
};

UAMargin.displayName = 'UAMargin';

UAMargin.propTypes = {
  margins: PropTypes.objectOf(
    PropTypes.shape(
      {
        left: PropTypes.number,
      },
    ),
  ),
};

export default UAMargin;
