export const CUSTOM = 'CUSTOM';

export const USER_SELECTION = 'USER_SELECTION';

export const PRODUCT = 'PRODUCT';

export const LEAFLET = 'LEAFLET';

export const TAG = 'TAG';

export const CONTRACTOR = 'CONTRACTOR';

export const CALENDAR = 'CALENDAR';

export const ARTICLE = 'ARTICLE';

export const COMMON = 'COMMON'; // for category

export const STORE = 'STORE';

export const PHRASE = 'PHRASE';
