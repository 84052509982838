import React, {useMemo} from 'react';

import useReactRouter from '@ding/core/src/hooks/useReactRouter';
import UndecoratedLink from './UndecoratedLink';

import {
  dropProtocol,
  dropRootDomain,
  isRootDomainURL,
} from '../Utils/interceptRelativeReactRedirect';

const UserDefinedLink = ({to, ...props}) => {
  const {location} = useReactRouter();
  const safeProps = useMemo(
    () => {
      const nonProtocolURL = dropProtocol(to || '');
      if (isRootDomainURL(nonProtocolURL)) {
        return {
          to: dropRootDomain(nonProtocolURL),
        };
      }

      return {
        rel: 'nofollow noopener noreferrer',
        target: '_blank',
        to,
      };
    },
    [location.pathname, to],
  );

  return (
    <UndecoratedLink
      {...props}
      {...safeProps}
    />
  );
};

export default UserDefinedLink;
