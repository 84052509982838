import React from 'react';
import * as R from 'ramda';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import Autocomplete from '../Autocomplete';

const mapMonthsListToItems = R.addIndex(R.map)(
  (name, id) => ({
    id,
    name,
  }),
);

const MonthSelect = (props) => {
  const t = useTranslate();

  return (
    <Autocomplete
      {...props}
      expandIcon
      returnIdOnly // returns 0-11 months, name is not necessary
      allowInputChange={false}
      list={
        mapMonthsListToItems(
          t('shared.titles.date.months'),
        )
      }
    />
  );
};

MonthSelect.displayName = 'MonthSelect';

export default MonthSelect;
