import React from 'react';
import PropTypes from 'prop-types';

import {CornerIconHolder} from '@ding/core/src/components/Predefined';
import RectangleButton from './RectangleButton';

/**
 * @see
 *  Text is always visible! Icon is spaced using margin!
 *
 * @example
 *  [[icon] text]
 *  [text, [icon]]
 *
 * @export
 */
const RectangleIconButton = ({
  textAlign,
  icon,
  iconOnRight,
  iconOnly,
  iconSpacing,
  children,
  ...props
}) => (
  <RectangleButton
    {...props}
    shrinked={iconOnly}
    expandedPadding={false}
  >
    <CornerIconHolder
      {...{
        textAlign,
        icon,
        iconOnly,
        iconOnRight,
        iconSpacing,
      }}
    >
      {children}
    </CornerIconHolder>
  </RectangleButton>
);

RectangleIconButton.displayName = 'RectangleIconButton';

RectangleIconButton.propTypes = {
  textAlign: PropTypes.string,
  iconOnRight: PropTypes.bool,
  iconSpacing: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

RectangleIconButton.defaultProps = {
  iconOnRight: true,
  iconSpacing: 2,
};

export default RectangleIconButton;
