import React from 'react';
import Cookie from 'js-cookie';
import * as R from 'ramda';

import {USER_TYPES} from '@ding/auth/src/constants';

import {useCookies} from '@adretail/basic-components/src/Context/SSRCookiesProvider';
import decodeJWT from '@adretail/basic-helpers/src/getters/jwt/decodeJWT';

import createContextPack from '@ding/tiny-context-state';
import cookieEnv from '@ding/constants/src/envCookieConfig';

const {jwt: JWT_COOKIE, freshLogin: FRESH_LOGIN_COOKIE} = cookieEnv;

const loadTokenState = (jwtToken) => {
  const decoded = jwtToken && decodeJWT(jwtToken);

  return {
    token: jwtToken,
    user: decoded?.payload,
  };
};

export const pickUserKind = R.propOr(USER_TYPES.GUEST, 'kind');

const pickTokenUser = R.compose(
  R.defaultTo({}),
  R.prop('user'),
);

const pickTokenKind = R.compose(
  pickUserKind,
  pickTokenUser,
);

const {
  Consumer,
  Provider,
  useStateContext,
} = createContextPack(
  {
    selectors: {
      getToken: R.always(R.prop('token')),
      getUser: R.always(pickTokenUser),

      isAuthSupported: R.always(
        R.compose(
          R.where(
            {
              customer: R.complement(R.isNil),
            },
          ),
          pickTokenUser,
        ),
      ),

      isOAuth: R.always(
        R.compose(
          R.equals(USER_TYPES.OAUTH),
          pickTokenKind,
        ),
      ),

      isLogged: R.always(
        R.compose(
          ({kind, confirmed}) => (
            kind === USER_TYPES.OAUTH
              || (kind === USER_TYPES.NORMAL && confirmed)
          ),
          R.defaultTo({}),
          pickTokenUser,
        ),
      ),

      // treat not confirmed user as guest
      isGuest: R.always(
        R.compose(
          R.anyPass([
            R.propEq('guest', true),
            R.propEq('confirmed', false),
            R.compose(
              R.equals(USER_TYPES.GUEST),
              pickUserKind,
            ),
          ]),
          pickTokenUser,
        ),
      ),

      isFreshLogin: R.always(
        R.compose(
          R.anyPass([
            R.equals(true),
            R.equals('true'),
          ]),
          R.prop('freshLogin'),
        ),
      ),
    },

    // actions generally should be executed only on client side
    // so it will be good to assume that Cookie object can be mutable here
    actions: {
      setToken: token => (state) => {
        const newState = loadTokenState(token);
        Cookie.set(
          JWT_COOKIE.name,
          token,
          {
            expires: new Date(newState.user.exp),
          },
        );

        return {
          ...state,
          ...newState,
        };
      },

      setFreshLogin: value => (state) => {
        Cookie.set(
          FRESH_LOGIN_COOKIE.name,
          value,
        );

        return {
          ...state,
          freshLogin: value,
        };
      },

    },
  },
);

export const useAuthState = useStateContext;

export const AuthStateConsumer = Consumer;

/**
 * Provide initial state from JWT token
 *
 * @todo
 *  Use hooks
 *
 * @export
 */
export const AuthStateProvider = ({children}) => {
  const cookies = useCookies();
  const jwtToken = cookies.get(JWT_COOKIE.name) || null;
  const freshLogin = cookies.get(FRESH_LOGIN_COOKIE.name) || null;

  return (
    <Provider
      initialState={
        {
          ...loadTokenState(jwtToken),
          freshLogin,
        }
      }
    >
      {children}
    </Provider>
  );
};
