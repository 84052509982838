import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import AuthConditionalRoute from '@ding/auth/src/client/AuthConditionalRoute';
import {AccountSettings, AccountFavourites, UnauthorizedDeleteAccount} from '@ding/routes/src/LazyLoadableRoutes';

import {
  ACCOUNT_SETTINGS_SCHEMA,
  UNAUTHORIZED_DELETE_ACCOUNT_SCHEMA,
  ACCOUNT_SETTINGS_DELETE_ACCOUNT_SCHEMA,
} from '@ding/layout/src/components/Links/AccountSettingsLink';
import {FAVOURITES_SCHEMA} from '@ding/layout/src/components/Links/FavouritesLink';

const AuthConditionalRouteToAccountRemove = provideProps({
  unauthorizedRedirectPath: ACCOUNT_SETTINGS_DELETE_ACCOUNT_SCHEMA,
})(AuthConditionalRoute.GuestOnly);

export default [
  {
    path: FAVOURITES_SCHEMA,
    component: AccountFavourites,
    routeModule: './AccountFavouritesRoute',
  },
  {
    path: ACCOUNT_SETTINGS_SCHEMA,
    component: AccountSettings,
    routeModule: './AccountSettingsRoute',
    routeComponent: AuthConditionalRoute.LoggedOnly,
    exact: true,
  },
  {
    path: UNAUTHORIZED_DELETE_ACCOUNT_SCHEMA,
    component: UnauthorizedDeleteAccount,
    routeModule: './UnauthorizedDeleteAccountRoute',
    routeComponent: AuthConditionalRouteToAccountRemove,
    exact: true,
  },
];
