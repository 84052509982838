import React from 'react';

import {setPreferedLanguageCookie} from '@ding/i18n/src/languages';
import HomeLink from '../uk/HomeLink';

const LanguageSwitchLink = ({onClick, linkComponent: LinkComponent = HomeLink, ...props}) => (
  <LinkComponent
    {...props}
    hrefLang='uk'
    plainAnchor
    onClick={() => {
      setPreferedLanguageCookie('uk');
      onClick?.();
    }}
  />
);

LanguageSwitchLink.displayName = 'LanguageSwitchLink';

export default LanguageSwitchLink;
