import {parameterize} from '@ding/core/src/helpers';
import {languages} from '@ding/i18n/src/languages';

import createLinkComponent from '../../utils/createLinkComponent';
import {optionalLinkSegment} from '../../utils/optionalSegment';

export const LATEST_CONTRACTOR_LEAFLET_URL_SCHEMA = `${languages.uk.prefix}/:name,:id/aktualna-gazetka`;

export const CONTRACTOR_URL_SCHEMA = [
  `${languages.uk.prefix}/:name,:id(\\d+)/:cityName,:cityId(\\d+)`,
  `${languages.uk.prefix}/:name,:id(\\d+)`, // used in StoresByCity
];

export const genContractorURL = ({id, name}, city) => (
  `${languages.uk.prefix}/${parameterize(name)},${id}${optionalLinkSegment(city && `${parameterize(city.name)},${city.id}`)}`
);

export default createLinkComponent(
  [
    'contractor',
    'city',
  ],
  (_, {contractor, item, city}) => genContractorURL(contractor || item, city),
);
