import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {Tabs} from '@ding/controls/src';

import {PRIMARY_HEADER_HEIGHT} from '@ding/controls/src/ResponsiveModalToggle/Modals/DesktopPopup';
import AUTH_TABS from './constants/authTabs';

import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import AuthModalPrimaryHeader from './AuthModalPrimaryHeader';

export {
  AUTH_TABS,
};

const AuthModal = ({ua, style, initialTab, onCloseModal}) => {
  const t = useTranslate();
  const [headless, setHeadless] = useState(false);

  const onRequestHeadless = newHeadless => setHeadless(newHeadless);

  return (
    <>
      <AuthModalPrimaryHeader small={ua.mobile} />
      <div
        style={{
          // due to <AuthModalPrimaryHeader />
          marginTop: (
            ua.desktop
              ? 90
              : PRIMARY_HEADER_HEIGHT
          ),
          maxWidth: '100%',
          ...style,
        }}
      >
        <Tabs
          initialData={initialTab}
          headless={headless}
        >
          <Tabs.Item
            id={AUTH_TABS.LOGIN}
            title={
              t('website.auth.titles.login')
            }
          >
            {({onChangeTab}) => (
              <LoginForm
                onRequestHeadless={onRequestHeadless}
                onCloseModal={onCloseModal}
                onChangeModalAuthTab={onChangeTab}
              />
            )}
          </Tabs.Item>

          <Tabs.Item
            id={AUTH_TABS.REGISTER}
            title={
              t('website.auth.titles.create_account')
            }
          >
            {({onChangeTab}) => (
              <RegisterForm
                onRequestHeadless={onRequestHeadless}
                onCloseModal={onCloseModal}
                onChangeModalAuthTab={onChangeTab}
              />
            )}
          </Tabs.Item>
        </Tabs>
      </div>
    </>
  );
};

AuthModal.propTypes = {
  initialTab: PropTypes.oneOf(R.values(AUTH_TABS)),
  onCloseModal: PropTypes.func,
};

AuthModal.defaultProps = {
  initialTab: AUTH_TABS.LOGIN,
};

export default React.memo(AuthModal);
