import * as PAGE_TYPES from '../../constants/pageTypes';

export const createInfoPage = (last = false) => ({
  id: PAGE_TYPES.INFO,
  kind: PAGE_TYPES.INFO,
  last,
});

export const createRecommendedPage = () => ({
  id: PAGE_TYPES.RECOMMENDED,
  kind: PAGE_TYPES.RECOMMENDED,
  nonURLPage: true,
});
