import React from 'react';

import {ConfirmAccountMutation} from '@ding/auth/src/gql/components';
import ConfirmTokenRoute from '../ConfirmTokenRoute';

export const TOKEN_GET_PARAM = 'confirmation_token';

const ConfirmAccount = props => (
  <ConfirmAccountMutation>
    {confirmUser => (
      <ConfirmTokenRoute
        {...props}
        tokenName={TOKEN_GET_PARAM}
        tokenVerifyFn={confirmUser}
        toastTranslationPath='website.auth.toast.account_confirm'
      />
    )}
  </ConfirmAccountMutation>
);

ConfirmAccount.displayName = 'ConfirmAccount';

export default ConfirmAccount;
