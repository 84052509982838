import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import {ContractorsQuery} from '@ding/api/src/gql/contractorsQuery';
import PaginatedGQLGrid from '@ding/control-groups/src/PaginatedGQLGrid';

import ContractorsGrid from './ContractorsGrid';

export default provideProps(
  {
    queryComponent: ContractorsQuery,
    gridComponent: ContractorsGrid,
  },
)(PaginatedGQLGrid);
