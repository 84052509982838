import PropTypes from 'prop-types';
import styled from '@adretail/fast-stylesheet/src/react/styled';

import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';

const ControlsList = styled.div(
  {
    base: {
      '& > *': {
        ...createBreakpointClasses({
          xs: {
            display: 'inline-block',
            marginBottom: 7,
            width: '100%',

            '&:last-child': {
              marginBottom: 0,
            },
          },

          md: {
            display: 'inline-flex !important', // fix jss ignoring index issue

            marginRight: 15,
            width: 'initial',

            '&:last-child': {
              marginBottom: 'initial',
              marginRight: 0,
            },
          },
        }),
      },
    },

    flex: {
      display: 'flex',
    },

    inline: {
      '& > *': {
        display: 'inline-flex !important', // fix jss ignoring index issue

        width: 'auto',
        marginRight: 5,
        marginBottom: 0,

        '&:last-child': {
          marginBottom: 'initial',
          marginRight: 0,
        },
      },
    },

    center: {
      textAlign: 'center',
    },

    right: {
      textAlign: 'right',
    },

    wrap: {
      flexWrap: 'wrap',
    },
  },
  {
    index: 2,
    omitProps: ['flex', 'inline', 'center', 'right', 'wrap'],
    classSelector: (classes, {center, right, flex, inline, wrap}) => ([
      flex && classes.flex,
      inline && classes.inline,
      center && classes.center,
      right && classes.right,
      wrap && classes.wrap,
    ]),
  },
);

ControlsList.displayName = 'ControlsList';

ControlsList.propTypes = {
  flex: PropTypes.bool,
  center: PropTypes.bool,
  inline: PropTypes.bool,
  right: PropTypes.bool,
};

export default ControlsList;
