export default {
  meta: {
    title: 'Gazetka %{contractorName} &#x1f31f; Aktualna %{formattedDate} &#x2714; Promocje tego tygodnia!',
    tab_title: 'Gazetka %{contractorName} \uD83C\uDF1F Aktualna %{formattedDate} \u2714 Promocje tego tygodnia!',
    description: 'Gazetka %{contractorName} od %{formattedDate} &#x2705; Aktualne promocje i akcje rabatowe w marketach %{contractorName} &#x2764;&#xfe0f;. Kupuj taniej z %{PageTitle} i oszczędzaj &#x1f603; Sprawdź! &#x2b95;',

    city_title: 'Gazetka %{contractorName} %{cityName} &#x1f31f; Aktualna %{formattedDate} &#x2714; Promocje tego tygodnia!',
    tab_city_title: 'Gazetka %{contractorName} %{cityName} \uD83C\uDF1F Aktualna %{formattedDate} \u2714 Promocje tego tygodnia!',
    city_description: 'Gazetka %{contractorName} %{cityName} od %{formattedDate} &#x2705; Aktualne promocje i akcje rabatowe w marketach %{contractorName} &#x2764;&#xfe0f;. Kupuj taniej z %{PageTitle} i oszczędzaj &#x1f603; Sprawdź! &#x2b95;',

    og: {
      title: 'Gazetka %{contractorName} &#x1f31f; Aktualna %{formattedDate} &#x2714; Promocje tego tygodnia!',
    },
  },

  titles: {
    loading_route: 'Ładowanie najbliższych gazetek sieci...',
  },
};
