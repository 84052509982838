import * as HASH_LINKS from './hashLinks';
import * as MODULE_TYPES from './moduleTypes';
import * as MODULE_TRACING_NAMES from './moduleTrackingNames';
import * as PAGE_TYPES from './pageTypes';
import * as SEARCH_PARAMS from './searchParams';

export * from './pageTypes';
export * from './size';

export const VIEWER_EVENTS = {
  URL_CHANGED: 'URL_CHANGED',
  PAGE_SHOW: 'PAGE_SHOW',
  HTML_INSERT_CLICK: 'HTML_INSERT_CLICK',
};

export {
  HASH_LINKS,
  MODULE_TYPES,
  MODULE_TRACING_NAMES,
  PAGE_TYPES,
  SEARCH_PARAMS,
};
