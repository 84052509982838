import React from 'react';
import PropTypes from 'prop-types';

import {PROPS_SCHEMA} from '@adretail/schemas';
import {useUA} from '@adretail/basic-components/src/Context/UAProvider';
import applyIfFunction from '@adretail/basic-helpers/src/base/applyIfFunction';

import SeparatedItemsList, {SeparatedListItem} from '@ding/core/src/components/Parts/Lists/SeparatedItemsList';
import PopoverPopupToggle from './PopoverPopupToggle';
import PopoverWideMobileToggle from './PopoverWideMobileToggle';

const PopoverListToggle = ({
  children, mapListItems, popoverProps,
  wrapMappedListItems, listItemProps,
  mobileDropdown, mobileDropdownProps,
  listProps, ...props
}) => {
  const ua = useUA();
  const contentRender = (onClose) => {
    let content = children(onClose);
    if (mapListItems) {
      content = React.Children.map(
        content,
        child => child && (
          <SeparatedListItem
            wrapContent={wrapMappedListItems}
            {...listItemProps}
          >
            {child}
          </SeparatedListItem>
        ),
      );
    }

    return (
      <SeparatedItemsList {...listProps}>
        {content}
      </SeparatedItemsList>
    );
  };

  if (mobileDropdown && ua.mobile) {
    return (
      <PopoverWideMobileToggle
        {...props}
        {...applyIfFunction([], mobileDropdownProps)}
      >
        {contentRender}
      </PopoverWideMobileToggle>
    );
  }

  return (
    <PopoverPopupToggle
      {...props}
      popoverProps={{
        ...popoverProps,
        style: {
          paddingTop: 0,
          paddingBottom: 0,
          ...popoverProps?.style,
        },
      }}
    >
      {contentRender}
    </PopoverPopupToggle>
  );
};

PopoverListToggle.displayName = 'PopoverListToggle';

PopoverListToggle.propTypes = {
  mapListItems: PropTypes.bool,
  mobileDropdown: PropTypes.bool,
  wrapMappedListItems: PropTypes.bool,
  listItemProps: PROPS_SCHEMA,
};

PopoverListToggle.defaultProps = {
  mapListItems: true,
  wrapMappedListItems: true,
};

export default React.memo(PopoverListToggle);
