import React from 'react';

import {Margin} from '@ding/core/src/components/Predefined';
import {LoadingSpinner} from '@ding/core/src/components/Parts';

import AsyncSpinnerControl from '../AsyncSpinnerControl';
import Button from '.';

const AsyncSpinnerButton = ({children, ...props}) => (
  <AsyncSpinnerControl
    controlComponent={Button}
    {...props}
  >
    {loading => (
      <>
        {children}
        {loading && (
          <Margin left={3}>
            <LoadingSpinner size='small' />
          </Margin>
        )}
      </>
    )}
  </AsyncSpinnerControl>
);

AsyncSpinnerButton.displayName = 'AsyncSpinnerButton';

export default AsyncSpinnerButton;
