import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import ToggleCheckbox from './ToggleCheckbox';
import ImageCheckbox from './ImageCheckbox';
import TextTimesCheckbox from './TextTimesCheckbox';

export default {
  DotOutlinedCircle: provideProps({type: 'dot-outlined-circle'})(ToggleCheckbox),
  Square: provideProps({type: 'square'})(ToggleCheckbox),
  SquareOutline: provideProps({type: 'square-outline'})(ToggleCheckbox),
  Bell: provideProps({type: 'bell'})(ToggleCheckbox),
  Switch: ToggleCheckbox,
  Image: ImageCheckbox,
  TextTimes: TextTimesCheckbox,
};
