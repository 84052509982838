import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import {Ad} from '@adretail/react-ads';
import {useLazyAdblockCheck} from '@ding/adblock-bait/src/hooks/useAdblockCheck';

import SSRRenderSwitch from '@adretail/basic-components/src/SSRRenderSwitch';
import styled from '@adretail/fast-stylesheet/src/react/styled';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';
import flexCenter from '@ding/core/src/styles/flexCenter';

import AdTitle from './AdTitle';
import AdPlaceholder from './AdPlaceholder';
import AdAdvertiserTag from './AdAdvertiserTag';

const AdContent = styled.span(
  {
    base: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 10,
      maxWidth: '100vw',
      textAlign: 'center',
    },

    preserveInnerBoxSpace: {
      '& .inner-box': {
        extend: flexCenter,

        ...createBreakpointClasses(
          {
            xs: {
              minHeight: 296, // 280 + AdAdvertiserTag (16)

              '&[data-type="gora_srodek"]': {
                minHeight: 376, // 360 + AdAdvertiserTag (16)
              },

              '&[data-type="dol_srodek"]': {
                minHeight: 416, // 400 + AdAdvertiserTag (16)
              },
            },

            md: {
              minHeight: 338, // 322 + 16

              '&[data-type="gora_srodek"]': {
                minHeight: 316, // 300 + AdAdvertiserTag (16)
              },

              '&[data-type="dol_srodek"]': {
                minHeight: 416, // 400 + AdAdvertiserTag (16)
              },
            },
          },
        ),
      },
    },

    spacing: createBreakpointClasses({
      xs: {
        margin: '10px 0 5px',
      },

      md: {
        margin: [20, 0],
      },
    }),

    noSpacing: {
      margin: 0,
      padding: 0,
    },

    hidden: {
      margin: '0 !important',

      '& .inner-box': {
        minHeight: '0 !important',
        height: '0 !important',
        margin: '0 !important',
      },
    },
  },
  {
    omitProps: ['noSpacing', 'spacing', 'hidden', 'preserveInnerBoxSpace'],
    classSelector: (
      classes,
      {
        spacing,
        noSpacing,
        hidden,
        preserveInnerBoxSpace = true,
      },
    ) => [
      spacing && classes.spacing,
      noSpacing && classes.noSpacing,
      hidden && classes.hidden,
      preserveInnerBoxSpace && classes.preserveInnerBoxSpace,
    ],
  },
);

/**
 * Ad box
 *
 * @example
 * <AdHolder name='gora_srodek' />
 * <AdHolder
 *   name='outofpage'
 *   titled={false}
 *   hidden
 * />
 *
 */
const AdHolder = ({
  withSpacing, className, style, cid, pid,
  hidden, titled, onLoaded,
  preserveInnerBoxSpace,
  ...declaration
}) => {
  const [advertiserId, setAdvertiserId] = useState(undefined);
  const adblock = useLazyAdblockCheck();

  const onLoadedHandle = useCallback(
    /**
     * @typedef {React.ComponentProps<Ad>} AdProps
     * @param {AdProps['onLoaded']} slot
     */
    (slot) => {
      slot.adserverHandle.$adserverEvents?.subscribe((event/*, unmountListener*/) => {
        if (!event || event.type !== 3)
          return;

        // NOTE: because ad refresh you cant unmount listener on load because it will not refresh tag
        // BUG: when ad will be remove (like in leaflets slider this event don't destroy itself)
        // unmountListener();

        //TODO: move this to @adretail/react-ads with ability to pass own style
        if (advertiserId !== event.originalEvent.advertiserId && event.originalEvent.advertiserId)
          setAdvertiserId(event.originalEvent.advertiserId);
      }, false, true);

      onLoaded?.(slot);
    },
    [onLoaded],
  );

  if (adblock)
    return null;

  return (
    <AdContent
      spacing={withSpacing}
      style={style}
      className={className}
      hidden={hidden}
      preserveInnerBoxSpace={
        preserveInnerBoxSpace ?? true
      }
      aria-label='ad-box'
      role='img'
    >
      {titled && (
        <AdTitle />
      )}

      <div className='inner-box' data-type={declaration.name}>
        <SSRRenderSwitch ssrEnabled={false} loaderComponent={!hidden && AdPlaceholder}>
          {() => (
            <Ad
              declaration={declaration}
              onLoaded={onLoadedHandle}
            >
              {({uuid: divId}) => (
                <div>
                  {/* NOTE: most if this are dirty hacks because declarationRef is ref and all data fill asynchonistly not in one batch */}
                  <AdAdvertiserTag advertiserId={advertiserId} />
                  <span id={divId} />
                </div>
              )}
            </Ad>

          )}
        </SSRRenderSwitch>
      </div>
    </AdContent>
  );
};

AdHolder.displayName = 'AdHolder';

AdHolder.propTypes = {
  withSpacing: PropTypes.bool,
  titled: PropTypes.bool,
};

AdHolder.defaultProps = {
  withSpacing: true,
  titled: true,
};

export default React.memo(AdHolder);
