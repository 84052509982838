import React from 'react';
import * as R from 'ramda';

import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import ResponsiveModalToggle from '@ding/controls/src/ResponsiveModalToggle';
import Button from '@ding/controls/src/Button';
import CheckBoxList from '@ding/controls/src/CheckBoxList';
import {Margin, Text, UndecoratedLink} from '@ding/core/src/components/Predefined';

import {LANGUAGES, prefixWithLanguage} from '@ding/i18n/src/languages';

import FlagPolish from '@ding/core/src/icons/FlagPolish';
import FlagUkrainian from '@ding/core/src/icons/FlagUkrainian';
import {genLanguagesList} from '@ding/controls/src/Autocomplete/Predefined/LanguageSelect';
import {LanguageSwitchLink} from '../../Links';

const Flags = {
  [LANGUAGES.PL]: FlagPolish,
  [LANGUAGES.UK]: FlagUkrainian,
};

export const CurrentLanguageLabel = () => {
  const t = useTranslate('website.language');
  const FlagComponent = Flags[APP_LANGUAGE];

  return (
    <div>
      {t('current_language', t(`languages.${APP_LANGUAGE}`))}
      {/* NOTE: Due to parent override on desktop toolbar here's style instead of size property */}
      <Margin left={1} style={{verticalAlign: 'middle'}}>
        <FlagComponent style={{borderRadius: 999, width: 15, height: 15, lineHeight: '1px'}} />
      </Margin>
    </div>
  );
};

const ModalContent = ({l, value}) => {
  const t = useTranslate('website.language.change_language_popup_with_checkbox');

  return (
    <div>
      <Text
        size='tiny-small'
        weight={600}
        uppercase
        block
      >
        {t('question')}
      </Text>
      <Margin top={4} bottom={5}>
        <CheckBoxList
          assignItemInfoToValue={false}
          singleValueAtOnce
          itemProps={{
            type: 'dot-outline-circle',
          }}
          list={genLanguagesList(useTranslate())}
          {...l.input('language')}
        />
      </Margin>

      <LanguageSwitchLink
        to={prefixWithLanguage('/', R.head(R.keys(value?.language)))}
        linkComponent={UndecoratedLink}
      >
        <Button
          expanded
          type='primary'
          style={{
            padding: '0 32px',
          }}
        >
          {t('button')}
        </Button>
      </LanguageSwitchLink>
    </div>
  );
};

const LinkedModalContent = linkedInputs('value', false)(ModalContent);

const ChangeLanguageWithCheckboxPopup = (props) => {
  const t = useTranslate('website.language.change_language_popup_with_checkbox');

  return (
    <ResponsiveModalToggle
      modalProps={{
        title: t('title'),
        style: {
          width: 420,
        },
      }}
      modalContentFn={() => (
        <LinkedModalContent
          initialData={{language: {[APP_LANGUAGE]: true}}}
        />
      )}
      {...props}
    />
  );
};

export default ChangeLanguageWithCheckboxPopup;
