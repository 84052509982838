import React from 'react';
import createIcon from '../utils/createIcon';

export default createIcon(
  'ShoppingList',
  {
    viewBox: '0 0 20.16 24',
  },
  <>
    <path d='M185.47,113.75H171.39a3.043,3.043,0,0,0-3.04,3.04v17.92a3.043,3.043,0,0,0,3.04,3.04h14.08a3.043,3.043,0,0,0,3.04-3.04V116.79a3.043,3.043,0,0,0-3.04-3.04m1.44,20.96a1.443,1.443,0,0,1-1.44,1.44H171.39a1.443,1.443,0,0,1-1.44-1.44V116.79a1.443,1.443,0,0,1,1.44-1.44h14.08a1.443,1.443,0,0,1,1.44,1.44Z' transform='translate(-168.35 -113.75)' />
    <rect width='1.6' height='1.6' transform='translate(4.16 5.44)' />
    <rect width='1.6' height='1.6' transform='translate(4.16 9.28)' />
    <rect width='1.6' height='1.6' transform='translate(4.16 13.12)' />
    <rect width='1.6' height='1.6' transform='translate(4.16 16.96)' />
    <rect width='9.28' height='1.6' transform='translate(6.72 5.44)' />
    <rect width='9.28' height='1.6' transform='translate(6.72 9.28)' />
    <rect width='9.28' height='1.6' transform='translate(6.72 13.12)' />
    <rect width='9.28' height='1.6' transform='translate(6.72 16.96)' />
  </>,
);
