import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import * as R from 'ramda';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';

import {Flex} from '@ding/core/src/components/Predefined';

import Skeleton from '@ding/core/src/components/Predefined/Skeleton';
import CardHolder from '../CardHolder';

/** @satisfies {{[x:string]: React.CSSProperties | {[x:string]: React.CSSProperties}}} */
const css = {
  contractor: {
    display: 'flex',
    maxWidth: '100%',
    flexDirection: 'column',
    padding: 20,
  },

  'contractor-small': {
    padding: 10,
  },

  logoContainer: {
    margin: '15px 0 25px',
    height: 55,

    '& > div': {
      maxWidth: '78%',
    },
  },

  'logoContainer-small': {
    height: 45,
  },

  title: {
    justifyContent: 'space-between',
    height: 24,

    '& > div:first-child': {
      width: '75%',
    },
    '& > div:last-child': {
      width: 24,
    },
  },
};

/**
 * Skeleton placeholder for ProductCard component
 * @param {object} props - component props
 * @param {string} props.size - display lead fragment
 * @param {boolean} [props.hideFavouriteSwitch] - hide favorite contractor button
 *
 * @see ContractorCard - main component
 * @returns skeleton for LeafletCard component
 */
const ContractorCardSkeleton = ({
  classes,
  className,
  size,
  hideFavouriteSwitch,
}) => (
  <CardHolder
    className={c(
      className,
      classes.contractor,
      size && classes[`contractor-${size}`],
    )}
    hoverable
    bordered
  >
    <Flex.Centered
      className={c(
        classes.logoContainer,
        size && classes[`logoContainer-${size}`],
      )}
    >
      <Skeleton />
    </Flex.Centered>

    <Flex.Centered className={classes.title} justify='space-between'>
      <Skeleton />

      {!hideFavouriteSwitch && (
        <Skeleton />
      )}
    </Flex.Centered>
  </CardHolder>
);

ContractorCardSkeleton.displayName = 'ContractorCardSkeleton';

ContractorCardSkeleton.propTypes = {
  hideFavouriteSwitch: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'auto']),
};

export default R.compose(
  React.memo,
  injectClassesSheet(css, {index: 1}),
)(ContractorCardSkeleton);
