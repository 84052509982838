import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {DescriptionFormRow} from '@ding/control-groups/src/Form/Groups';

const ContactGroup = () => {
  const t = useTranslate('website.contact_info');

  return (
    <>
      <DescriptionFormRow {...t('service_provider')} />
      <DescriptionFormRow {...t('location')} />
      <DescriptionFormRow {...t('contact')} />
    </>
  );
};

ContactGroup.displayName = 'ContactGroup';

export default React.memo(ContactGroup);
