import * as R from 'ramda';
import areBlankCoords from './areBlankCoords';

const dropBlankAddressCoords = R.when(
  R.both(
    R.is(Object),
    R.propSatisfies(areBlankCoords, 'coords'),
  ),
  R.omit(['coords']),
);

export default dropBlankAddressCoords;
