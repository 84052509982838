import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  REACT_COMPONENT_CLASS_SCHEMA,
  LIST_ITEM_SCHEMA,
  PROPS_SCHEMA,
} from '@adretail/schemas';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';
import {rejectById} from '@ding/core/src/helpers';

import {PlainUnorderedList} from '@ding/core/src/components/Predefined';
import TagButton from './TagButton';

export {
  TagButton,
};

export const InlineTagsList = styled(
  PlainUnorderedList,
  {
    flexWrap: 'wrap',

    '& > li': {
      '&:not(:last-child)': {
        marginRight: 10,
        marginBottom: 8,
      },
    },
  },
  {
    inline: true,
  },
);

const TagsSelect = ({
  value,
  listWrapped,
  tagProps,
  tagComponent: TagComponent,
  tagNameRenderFn,
  l,
  ...props
}) => {
  const list = (value || []).map(
    tag => (
      <li key={tag.id}>
        <TagComponent
          {...tagProps}
          onRemove={
            () => l.onGroupEvent(rejectById(tag.id)(value))
          }
        >
          {tagNameRenderFn(tag)}
        </TagComponent>
      </li>
    ),
  );

  if (!listWrapped)
    return list;

  return (
    <InlineTagsList {...props}>
      {list}
    </InlineTagsList>
  );
};

TagsSelect.displayName = 'TagsSelect';

TagsSelect.propTypes = {
  value: PropTypes.arrayOf(LIST_ITEM_SCHEMA),
  listWrapped: PropTypes.bool,
  tagProps: PROPS_SCHEMA,
  tagComponent: REACT_COMPONENT_CLASS_SCHEMA,
  tagNameRenderFn: PropTypes.func,
};

TagsSelect.defaultProps = {
  value: [],
  listWrapped: true,
  tagComponent: TagButton,
  tagNameRenderFn: R.prop('name'),
};

export default linkedInputs(
  {
    groupName: 'value',
    fotwardOnChange: true,
    arrayGroup: true,
  },
)(TagsSelect);
