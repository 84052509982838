import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import MobileFullscreenPopup from '@ding/controls/src/ResponsiveModalToggle/Modals/MobileFullscreenPopup';
import MobileMenuList from '../MobileMenuList';
import SectionsLinksList from './SectionsLinksList';

const MenuModal = ({onUnmount}) => {
  const t = useTranslate();

  return (
    <MobileFullscreenPopup
      type='primary'
      title={
        t('website.shared.header.menu')
      }
      headerToolbar={(
        <MobileMenuList />
      )}
      onUnmount={onUnmount}
    >
      {() => (
        <SectionsLinksList />
      )}
    </MobileFullscreenPopup>
  );
};

MenuModal.displayName = 'MenuModal';

export default MenuModal;
