import React from 'react';
import PropTypes from 'prop-types';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import ArrowLeftIcon from '@ding/core/src/icons/ArrowLeft';
import ArrowRightIcon from '@ding/core/src/icons/ArrowRight';
import {ConditionalUAComponent} from '@adretail/basic-components/src/Context/UAProvider';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {UndecoratedLink} from '@ding/core/src/components/Predefined';

import RectangleIconButton from '@ding/controls/src/Button/RectangleIconButton';
import IconButton from '@ding/controls/src/Button/IconButton';

const UAConditionalRectangleButton = provideProps(
  {
    mobileComponent: IconButton,
    desktopComponent: RectangleIconButton,
    uaPropsFn: ua => ua.mobile && ({
      filled: true,
      size: 'medium',
      right: undefined,
    }),
  },
)(ConditionalUAComponent);

const PaginationArrow = ({
  right,
  number,
  totalPages,
  genLinkPropsFn,
  onClick,
}) => {
  const t = useTranslate('website.pagination');

  const outOfRange = !(number > 0 && number <= totalPages);

  const IconComponent = (
    right
      ? ArrowRightIcon
      : ArrowLeftIcon
  );

  const roleName = right ? t('next', number) : t('prev', number);

  const content = (
    <UAConditionalRectangleButton
      title={roleName}
      aria-label={roleName}
      type='secondary-light'
      iconOnRight={!!right}
      iconSpacing='auto'
      icon={
        <IconComponent size='tiny' type='inherit' />
      }
      disabled={
        outOfRange
        || (!onClick && !genLinkPropsFn)
      }
      {...onClick && {onClick}}
    />
  );

  if (genLinkPropsFn && !outOfRange) {
    return (
      <UndecoratedLink
        {...genLinkPropsFn(number)}
        rel={
          right
            ? 'next'
            : 'prev'
        }
      >
        {content}
      </UndecoratedLink>
    );
  }

  return content;
};

PaginationArrow.displayName = 'PaginationArrow';

PaginationArrow.propTypes = {
  right: PropTypes.bool,
  number: PropTypes.number,
  totalPages: PropTypes.number,
  genLinkPropsFn: PropTypes.func,
};

export default PaginationArrow;
