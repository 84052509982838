import React from 'react';
import LeafletsLink from './LeafletsLink';

const CategoryLink = ({item, category, ...props}) => (
  <LeafletsLink
    category={item || category}
    {...props}
  />
);

export default CategoryLink;
