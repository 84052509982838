import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import textValidators from '@adretail/basic-helpers/src/validators/textValidators';

import ValidatedInput from '../ValidatedInput';

const EMailInput = (props) => {
  const t = useTranslate();

  return (
    <ValidatedInput
      {...props}
      type='email'
      validatorFn={textValidators.email}
      placeholder={
        props.placeholder || t('website.shared.titles.email')
      }
    />
  );
};

EMailInput.displayName = 'EMailInput';

export default EMailInput;
