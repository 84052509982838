import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  PROPS_SCHEMA,
  REACT_COMPONENT_CLASS_SCHEMA,
} from '@adretail/schemas';

import {Debounce} from '@adretail/basic-decorators/src/base/balancer/debounce';
import LoadingSpinner from '@ding/core/src/components/Parts/LoadingSpinner';
import SeparatedItemsList, {SeparatedListItem} from '@ding/core/src/components/Parts/Lists/SeparatedItemsList';

import TinyGqlQuery, {FETCH_POLICY} from '@ding/tiny-gql/src/react/TinyGqlQuery';
import Autocomplete from './Autocomplete';
import AutocompleteItemsList from './AutocompleteItemsList';
import {Plain} from './Items';

export const listSelector = R.when(
  R.has('list'),
  R.prop('list'),
);

const ListLoadingItem = () => (
  <SeparatedItemsList>
    <SeparatedListItem
      size='medium'
      wrapContent={false}
    >
      <LoadingSpinner
        size='tiny'
        style={{
          display: 'flex',
        }}
      />
    </SeparatedListItem>
  </SeparatedItemsList>
);

export default class GQLAutocomplete extends React.PureComponent {
  static propTypes = {
    responseSelector: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
    ]),

    // useful in Selectboxes etc, allows to show selected option using only UUID
    preloadList: PropTypes.bool,
    list: PropTypes.any,
    listProps: PROPS_SCHEMA,
    itemProps: PROPS_SCHEMA,

    query: PropTypes.any, // if list is provided, query is not necessary
    queryVariables: PropTypes.objectOf(PropTypes.any),
    showPopupWhenEmptyPhrase: PropTypes.bool,
    fetchWhenEmptyPhrase: PropTypes.bool,
    providePhraseVariable: PropTypes.bool,

    loadingComponent: REACT_COMPONENT_CLASS_SCHEMA,
    itemComponent: REACT_COMPONENT_CLASS_SCHEMA,
    listComponent: REACT_COMPONENT_CLASS_SCHEMA,
  };

  static defaultProps = {
    showPopupWhenEmptyPhrase: false,
    fetchWhenEmptyPhrase: true,
    providePhraseVariable: true,

    responseSelector: listSelector,
    itemComponent: Plain,
    listComponent: AutocompleteItemsList,
    loadingComponent: ListLoadingItem,
  };

  renderContent(data, value, handlers) {
    const {
      children,
      itemComponent,
      itemProps,
      listProps,
      listComponent: ListComponent,
      addEmptyOption,
    } = this.props;

    if (children)
      return children(data, value, handlers);

    return (
      itemComponent && ListComponent
        ? (
          <ListComponent
            addEmptyOption={addEmptyOption}
            list={data}
            onSelectItem={
              handlers.onSelectItem || R.F
            }
            onBlur={
              handlers.onBlur
            }
            {...listProps}
            {...{
              value,
              itemProps,
              itemComponent,
            }}
          />
        )
        : null
    );
  }

  render() {
    const {
      query,
      queryVariables,
      providePhraseVariable,
      responseSelector,
      showPopupWhenEmptyPhrase,
      fetchWhenEmptyPhrase,
      clientFetchPolicy,
      loadingComponent,
      preloadList,
      ...props
    } = this.props;

    if (props.list)
      return <Autocomplete {...props} />;

    const renderListQuery = (children, value) => (
      <TinyGqlQuery
        query={query}
        responseSelector={responseSelector}
        clientFetchPolicy={
          clientFetchPolicy || FETCH_POLICY.NETWORK_ONLY
        }
        loadingComponent={loadingComponent}
        variables={{
          ...queryVariables,
          ...providePhraseVariable && {
            phrase: value?.name || '',
          },
        }}
      >
        {children}
      </TinyGqlQuery>
    );

    if (!providePhraseVariable && preloadList) {
      return renderListQuery(list => (
        <Autocomplete
          {...props}
          showPopupWhenEmptyPhrase={showPopupWhenEmptyPhrase}
          list={list}
        />
      ));
    }

    return (
      <Autocomplete
        {...props}
        showPopupWhenEmptyPhrase={showPopupWhenEmptyPhrase}
      >
        {(value, handlers) => (
          <Debounce
            updateKey={value?.name}
            delay={160}
            loadingComponent={loadingComponent}
          >
            {() => {
              if ((!fetchWhenEmptyPhrase || !showPopupWhenEmptyPhrase) && !value?.name)
                return this.renderContent([], value, {});

              return renderListQuery(
                data => (
                  this.renderContent(data, value, handlers)
                ),
                value,
              );
            }}
          </Debounce>
        )}
      </Autocomplete>
    );
  }
}
