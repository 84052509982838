import styled from '@adretail/fast-stylesheet/src/react/styled';

import badgeImage from '../res/img/title-badge-medium.jpg';

const EUBadgeImage = styled.img(
  {
    base: {
      display: 'inline-block',
    },

    fixedSize: {
      width: 576,
      maxWidth: 'calc(100vw - 32px)',
      objectFit: 'contain',
      height: 52,
    },

    expanded: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  {
    src: badgeImage,
    alt: 'Eu Badge',
    loading: 'lazy',
    omitProps: ['expanded'],
    classSelector: (classes, {expanded}) => classes[expanded ? 'expanded' : 'fixedSize'],
  },
);

EUBadgeImage.displayName = 'EUBadgeImage';

export default EUBadgeImage;
