import modalMounter from '@adretail/basic-helpers/src/ui/modalMounter';
import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import cookieEnv from '@ding/constants/src/envCookieConfig';

import {JssNsfwCheckProvider} from '@adretail/nsfw-utils/src/variants/jss';
import NsfwModal from './NsfwModal';

const showNsfwModal = () => modalMounter(
  {
    modalComponent: NsfwModal,
  },
);

const NsfwProvider = provideProps(
  {
    confirmShowFn: showNsfwModal,
    nsfwCookieConfig: cookieEnv.nsfw,
  },
)(JssNsfwCheckProvider);

NsfwProvider.displayName = 'NsfwProvider';

export default NsfwProvider;
