import styled from '@adretail/fast-stylesheet/src/react/styled';
import DecoratedInput from './DecoratedInput';

/**
 * Input that is expanded to whole div size
 *
 * @export
 */
const ExpandedInput = styled(
  DecoratedInput,
  {
    width: '100%',
    height: '100%',
    borderRadius: 0,
  },
  {
    index: 3,
  },
);

ExpandedInput.displayName = 'ExpandedInput';

export default ExpandedInput;
