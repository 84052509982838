import productsDescription from '../docs/productsDescription.txt';

export default {
  title: 'Superoferty',

  section: {
    title: 'Top oferty',
    header: 'Wybrane oferty z gazetek',
    description: productsDescription,
  },

  filters: {
    categories: 'Kategorie',
    show_archival: 'Pokaż oferty archiwalne',

    product_name: {
      title: 'Nazwa produktu',
      placeholder: 'Wpisz nazwę produktu',
    },
  },

  tags: {
    name: 'Nazwa:',
    contractor: 'Sieć:',
    category: 'Kategoria:',

    min_price: 'Cena od:',
    max_price: 'Cena do:',
  },

  meta: {
    title: 'Oferty z gazetek promocyjnych – Promocje i super okazje%{pageSuffix}%{domainSuffix}',
    description: 'Oferty promocyjne z gazetek – Sprawdź aktualne promocje na wybrane produkty.',

    categories_suffix: 'kategorie: %{}',
    filters_title: '%{phrasePrefix}Oferty z gazetek promocyjnych%{filtersSuffix}%{pageSuffix}%{domainSuffix}',

    og: {
      title: 'Oferty z gazetek promocyjnych – Promocje i super okazje%{pageSuffix}%{domainSuffix}',
    },
  },
};
