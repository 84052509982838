import createLinkComponent from '../../utils/createLinkComponent';
import {optionalLinkSegment} from '../../utils/optionalSegment';

import {
  CONTRACTOR_URL_SCHEMA,
  genContractorURL,
} from './ContractorLink';

export const STORES_BY_CITY_URL_SCHEMA = `${CONTRACTOR_URL_SCHEMA[1]}/sklepy-wg-miast/:page(\\d+)?`;

export const genStoresByCityURL = ({page, contractor} = {}) => (
  `${genContractorURL(contractor)}/sklepy-wg-miast${optionalLinkSegment(page)}`
);

export default createLinkComponent(
  [
    'page',
    'contractor',
  ],
  (_, props) => genStoresByCityURL(props),
);
