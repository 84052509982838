import {gql, GraphQLFragment} from '@ding/tiny-gql';
import useMutation from '@ding/tiny-gql/src/react/hooks/useMutation';

export const CONTACT_FORM_TYPES = {
  REPORT_ISSUE: 'REPORT_ISSUE',
  SHARE_OPINION: 'SHARE_OPINION',
  MISSING_STORE: 'MISSING_STORE',
  OTHER: 'OTHER',
};

export const CONTACT_FORM_MUTATION = new GraphQLFragment(
  'contactFormMutation',
  gql`
    mutation contactFormMutation(
      $recaptcha: Recaptcha!,
      $info: ContactFormInfo!,
    ) {
      deliverContactForm(input: {
        recaptcha: $recaptcha,
        info: $info
      }) {
        success
        errors
      }
    }
  `,
);

export const useFormContactMutation = () => useMutation(CONTACT_FORM_MUTATION);
