export default {
  notifications: {
    title: 'Powiadomienia',
    content: 'Dodawaj sklepy i produkty do ulubionych, a otrzymasz powiadomienia o nowych gazetkach i promocjach na wybrane produkty. Żadna okazja Cię nie ominie!',
  },

  shopping_list: {
    title: 'Lista zakupowa',
    content: 'Stwórz wygodną listę zakupową! Dodawaj do niej produkty z gazetek, zabierz ją na zakupy i udostępnij mailem, sms-em lub w ulubionym komunikatorze.',
  },

  add_to_shopping_list: {
    title: 'Dodaj do listy zakupów',
    content: 'Zaznacz specjalną ikonkę, aby dodać do listy zakupów.',
  },

  favourite_product_notifications: {
    title: 'Powiadomienia o ulubionych produktach',
    content: 'Spodobała Ci się jakaś oferta? Zapamiętaj ją w ulubionych zaznaczając ikonkę dzwonka. Powiadomimy Cię, kiedy pojawi się w nowych gazetkach promocyjnych.',
  },

  favourite_contractor_notifications: {
    title: 'Dodaj sieć do ulubionych',
    content: 'Otrzymuj powiadomienia o najnowszych gazetkach ulubionych sieci.',
  },

  pin_ios_app: {
    title: 'Miej Ding.pl pod ręką',
    subtitle: 'Dodaj go do ekranu początkowego na swoim iPhonie za darmo',
    icon_title: 'Tapnij %{} i dodaj do "Ekranu początkowego"',
  },

  mobile_app_promotion: {
    title: 'DING do zakupów',
    subtitle: 'Wszystkie promocje i okazje w jednej aplikacji',
    call_to_action: 'Pobierz',
  },
};
