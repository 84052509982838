import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {ITEM_TYPES} from '@ding/constants';

import {PlainUnorderedList} from '@ding/core/src/components/Predefined';
import {SEARCH_DATA_SCHEMA} from '../constants';

import SearchKindItem from './SearchKindItem';
import EmptySearchResult from './EmptySearchResult';

const addItemsType = kind => R.map(R.assoc('kind', kind));

/**
 * @param {SearchData} searchData
 */
const searchDataDefaultSelector = R.compose(
  R.evolve({
    tags: addItemsType(ITEM_TYPES.TAG),
    contractors: addItemsType(ITEM_TYPES.CONTRACTOR),
    products: addItemsType(ITEM_TYPES.PRODUCT),
  }),
  ({contractors, tags, products}) => ({
    contractors: contractors || [],
    tags: tags || [],
    products: products || [],
  }),
);

const PageSearchGroupedLists = ({
  phrase,
  searchData,
  showEmptyPlaceholder,
  onSelectItem,
}) => {
  const {
    contractors,
    tags,
    products,
  } = searchDataDefaultSelector(searchData);

  if (!contractors.length && !tags.length && !products.length) {
    return (
      showEmptyPlaceholder
        ? (
          <EmptySearchResult
            phrase={phrase}
            style={{
              margin: 0,
              fontWeight: 'initial',
              minHeight: 56,
            }}
          />
        )
        : null
    );
  }

  return (
    <PlainUnorderedList>
      {contractors.map(
        contractor => (
          <SearchKindItem
            key={contractor.id}
            item={contractor}
            onClick={() => onSelectItem(contractor)}
          />
        ),
      )}

      {products.map(
        product => (
          <SearchKindItem
            key={product.id}
            item={product}
            onClick={() => onSelectItem(product)}
          />
        ),
      )}

      {tags.map(
        tag => (
          <SearchKindItem
            key={tag.id}
            item={tag}
            onClick={() => onSelectItem(tag)}
          />
        ),
      )}
    </PlainUnorderedList>
  );
};

PageSearchGroupedLists.displayName = 'PageSearchGroupedLists';

PageSearchGroupedLists.propTypes = {
  searchData: SEARCH_DATA_SCHEMA.isRequired,
  showEmptyPlaceholder: PropTypes.bool,
  onSelectItem: PropTypes.func,
};

PageSearchGroupedLists.defaultProps = {
  showEmptyPlaceholder: true,
  onSelectItem: R.F,
};

export default React.memo(PageSearchGroupedLists);
