import {parameterize} from '@ding/core/src/helpers';

import {languages} from '@ding/i18n/src/languages';
import createLinkComponent, {optionalLinkSegment} from '../../utils/createLinkComponent';

export const ARTICLES_URL_SCHEMA = [
  `${languages.uk.prefix}/blog/:categoryName,:categoryId(\\d+)/:page(\\d+)?`,
  `${languages.uk.prefix}/blog/:page(\\d+)?`,
];

export const genArticlesURL = ({page, category} = {}) => (
  `${languages.uk.prefix}/blog${optionalLinkSegment(category && `${parameterize(category.name)},${category.id}`)}${optionalLinkSegment(page)}`
);

export default createLinkComponent(
  [
    'page',
    'category',
  ],
  (props, params) => genArticlesURL(params),
);
