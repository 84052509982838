import {gqlQueryCacheKey} from '../../helpers/gqlOperationCacheKey';

/**
 * Generates cacheKey if
 *
 * @param {Query} query
 * @param {Object} cacheKey
 * @param {Object} variables
 */
const defaultQueryCacheKey = (query, cacheKey, variables) => {
  cacheKey ??= {};

  if (cacheKey.expire && cacheKey.name)
    return {
      ...cacheKey,
      name: `${APP_LANGUAGE}-${cacheKey.name}`,
    };

  let name = cacheKey.name || gqlQueryCacheKey(
    query,
    variables,
  );

  if (cacheKey.prefix)
    name = `${cacheKey.prefix}${name}`;

  return {
    expire: cacheKey.expire ?? 600,
    name: `${APP_LANGUAGE}-${name}`,
  };
};

export default defaultQueryCacheKey;
