import React from 'react';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {reactFormat} from '@ding/core/src/helpers';

import Section from '@ding/control-groups/src/Section';
import {Flex, Header, Text} from '@ding/core/src/components/Predefined';
import HomeLink from '@ding/layout/src/components/Links/HomeLink';

const PageLoadingErrorWrapper = styled(
  Flex,
  {
    maxWidth: 600,
    textAlign: 'center',
    margin: '0 auto',

    ...createBreakpointClasses(
      {
        xs: {
          '& > h1': {
            fontSize: '20px',
            marginBlock: '22px 10px',
          },

          '& > p': {
            fontSize: '14px',
            lineHeight: '1.6em',

            paddingInline: 40,
            marginTop: 0,
          },
        },
        md: {
          paddingBlock: 40,

          '& > h1': {
            fontSize: '25px',
            marginBlock: '30px 10px',
          },

          '& > p': {
            fontSize: '16px',

            paddingInline: 40,
            marginTop: 0,
          },
        },
      },
    ),
  },
);

const PageLoadingErrorContent = () => {
  const t = useTranslate('website.routes.pageLoadingError.section');
  return (
    <Section>
      <PageLoadingErrorWrapper column>
        <Header.H1>{t('header')}</Header.H1>
        <Text tagName='p'>
          {reactFormat(
            t('caption.text'),
            [
              <HomeLink key='homeLinkText' underlineHover type='primary' weight={700}>
                {t('caption.action')}
              </HomeLink>,
            ],
          )}
        </Text>
      </PageLoadingErrorWrapper>
    </Section>
  );
};

PageLoadingErrorContent.displayName = 'PageLoadingErrorContent';

export default React.memo(PageLoadingErrorContent);
