import React from 'react';
import PropTypes from 'prop-types';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';
import {WHITE, DARK, MUTED_GRAY, PRIMARY_FILL, DEFAULT_BUTTON_BACKGROUND} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import TimesIcon from '@ding/core/src/icons/Times';

import Button from './Button';

const ChipsButton = styled(
  Button,
  {
    base: {
      gap: 10,
      padding: [0, 15],
      textTransform: 'initial',
    },

    uppercase: {
      textTransform: 'uppercase',
    },

    primary: {
      background: WHITE,
      color: DARK,
      borderColor: MUTED_GRAY,

      '&:hover': {
        color: PRIMARY_FILL,
        borderColor: PRIMARY_FILL,
      },
    },
    'primary-selected': {
      background: PRIMARY_FILL,
      color: WHITE,
      borderColor: PRIMARY_FILL,
    },

    secondary: {
      background: DEFAULT_BUTTON_BACKGROUND,
      color: DARK,

      '&:hover': {
        background: '#d8d8d8',
        borderColor: '#d8d8d8',
      },
    },
    'secondary-selected': {
      background: '#d8d8d8',
      borderColor: '#d8d8d8',
    },
  },
  {
    index: 3,
    omitProps: ['type', 'uppercase'],
    classSelector: (classes, {type, uppercase}) => [
      classes[type],
      uppercase && classes.uppercase,
    ],
  },
);

/**
 * @description standard chips with optional close button and icon
 *              to show multiple interactive elements in the same area
 *              Icon can be svg icon or any JSX.Element
 */
const Chips = ({
  icon: IconComponent,
  selected, type, uppercase,
  removeBtnOnlyWhenActive,
  onClick, onRemove,
  children, ...props
}) => (
  <ChipsButton
    icon={IconComponent}
    type={selected ? `${type}-selected` : type}
    uppercase={uppercase}
    radius='big'
    size='small'
    expandedPadding={false}
    onClick={onClick}
    {...props}
  >

    {IconComponent && (
      React.isValidElement(IconComponent)
        ? IconComponent
        : (
          <IconComponent
            size='medium-small'
            type='inherit'
          />
        )
    )}

    {children}

    {onRemove && (selected && removeBtnOnlyWhenActive) && (
      <TimesIcon
        size='xs'
        type='inherit'
        onClick={(e) => {
          e.stopPropagation();
          onRemove();
        }}
      />
    )}
  </ChipsButton>
);

Chips.displayName = 'Chips';

Chips.propTypes = {
  icon: PropTypes.oneOfType([
    REACT_COMPONENT_CLASS_SCHEMA,
    PropTypes.node,
  ]),
  type: PropTypes.oneOf([
    'primary',
    'secondary',
  ]),
  selected: PropTypes.bool,
  uppercase: PropTypes.bool,
  removeBtnOnlyWhenActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
};

Chips.defaultProps = {
  type: 'secondary',
  selected: false,
  removeBtnOnlyWhenActive: false,
};

export default Chips;
