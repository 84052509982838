import {gql, GraphQLFragment} from '@ding/tiny-gql';
import * as R from 'ramda';

import useQuery from '@ding/tiny-gql/src/react/hooks/useQuery';

const CUSTOMER_LINK_QUERY = new GraphQLFragment(
  'customerPrivacyLinkQuery',
  gql`
    query customerPrivacyLinkQuery($withRodo: Boolean = false) @locale(lang: ${APP_LANGUAGE}) {
      customer {
        links {
          rodo @include(if: $withRodo)
        }
      }
    }
  `,
);

export const usePrivacyLinkQuery = () => useQuery(
  CUSTOMER_LINK_QUERY,
  {
    responseSelector: R.pathOr(null, ['customer', 'links', 'rodo']),
    variables: {
      withRodo: true,
    },
  },
);

export default CUSTOMER_LINK_QUERY;
