import React from 'react';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/typeSchema';
import {
  TOGGLE_CIRCLE,
  PRIMARY_FILL,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import CheckboxContainer from '../CheckboxContainer';

const SwitchCircle = styled.span(
  {
    base: {
      position: 'absolute',
      background: TOGGLE_CIRCLE,
      borderRadius: '50%',
      transition: 'left 300ms ease-in-out',
    },

    small: {
      top: 2,
      left: 2,
      width: 10,
      height: 10,
    },

    'small-active': {
      left: 'calc(100% - 12px)',
    },

    medium: {
      top: 3,
      left: 3,
      width: 19,
      height: 19,
    },

    'medium-active': {
      left: 'calc(100% - 22px)',
    },
  },
  {
    omitProps: ['active', 'size'],
    classSelector: (classes, {size, active}) => [
      active && classes[`${size}-active`],
      classes[size],
    ],
  },
);

const SwitchHolder = styled(
  ({active, size, children, ...props}) => (
    <CheckboxContainer {...props}>
      <SwitchCircle
        active={active}
        size={size || 'small'}
      />
      {children}
    </CheckboxContainer>
  ),
  {
    base: {},

    active: {
      boxShadow: `inset 0 0 0 20px ${PRIMARY_FILL}`,
    },

    small: {
      borderRadius: 8,
      width: 24,
      height: 14,
    },

    medium: {
      borderRadius: 45,
      width: 45,
      height: 25,
    },
  },
  {
    index: 2,
    classSelector: (classes, {size, active}) => [
      active && classes.active,
      classes[size],
    ],
  },
);

SwitchHolder.displayName = 'SwitchHolder';

SwitchHolder.propTypes = {
  size: SIZE_ARRAY_SCHEMA,
};

SwitchHolder.defaultProps = {
  size: 'small',
};

export default SwitchHolder;
