const createListItem = (id, name) => ({
  id,
  name,
});

export const createNameItemPair = name => ({
  id: name,
  name,
});

export default createListItem;
