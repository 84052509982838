import * as R from 'ramda';

const truncateString = R.curry(
  ({breakWord, truncateCharacters}, maxLength, str) => {
    if (str.length < maxLength)
      return str;

    let trimmed = R.slice(0, maxLength, str);
    if (!breakWord) {
      const lastSpaceIndex = R.lastIndexOf(' ', trimmed);
      if (lastSpaceIndex !== -1)
        trimmed = R.slice(0, lastSpaceIndex, trimmed);
    }

    return `${trimmed}${truncateCharacters}`;
  },
);

export const truncateEllipsisString = truncateString(
  {
    breakWord: false,
    truncateCharacters: '...',
  },
);

export default truncateString;
