import React from 'react';

import {ADDRESS_SCHEMA} from '@ding/constants/src/typeSchema';

import Address from '@ding/core/src/components/Parts/Titles/Address';

const GeolocationHeader = ({address, ...props}) => (
  <Address
    address={address}
    weight={800}
    underline
    size='small'
    {...props}
  />
);

GeolocationHeader.displayName = 'GeolocationHeader';

GeolocationHeader.propTypes = {
  address: ADDRESS_SCHEMA.isRequired,
};

export default React.memo(GeolocationHeader);
