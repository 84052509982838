import React, {useEffect} from 'react';

import {useAuthState} from '@ding/auth/src/client/AuthContext';
import {useSingleAccountSettingQuery} from '@ding/account-settings/src/gql/hooks';
import {FETCH_POLICY} from '@ding/tiny-gql/src/react/TinyGqlQuery';

import {findAlternateHrefInDOM} from '@ding/i18n/src/languages';

const RedirectToPreferredI18nPath = () => {
  const {setFreshLogin} = useAuthState();

  const {
    data,
    loading,
  } = useSingleAccountSettingQuery(
    'language', {
      clientFetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
    });

  useEffect(() => {
    if (data && !loading) {
      const href = findAlternateHrefInDOM(data.toLowerCase());
      if (href && href !== window.location.href) {
        // NOTE: window.location.href because we need full reload when change language
        window.location.href = href;
        setFreshLogin(false);
      }
    }
  }, [data]);

  return null;
};

const RedirectToI18nPathAfterLogin = () => {
  const {isFreshLogin} = useAuthState();

  if (isFreshLogin()) {
    return <RedirectToPreferredI18nPath />;
  }

  return null;
};

RedirectToI18nPathAfterLogin.displayName = 'RedirectToI18nPathAfterLogin';

export default RedirectToI18nPathAfterLogin;
