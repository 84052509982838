import React, {useRef, useEffect} from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {useTrackers} from '@ding/interia';

import {AdHolder} from '@ding/gpt';
import {AdblockDetect} from '@ding/adblock-bait';

import {Grid} from '@ding/core/src/components/Predefined';
import Button from '@ding/controls/src/Button';

const chunkClickCounter = {
  current: 0,
};

const SidebarInsertChunk = ({index, showScrollButton, style}) => {
  const t = useTranslate();
  const columnRef = useRef();
  const {iwa} = useTrackers();

  const onScrollNext = () => {
    const {current: columnNode} = columnRef;
    if (!columnNode)
      return;

    // eslint-disable-next-line no-unused-expressions
    columnNode.nextSibling?.scrollIntoView(
      {
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      },
    );

    // trackers
    chunkClickCounter.current++;

    iwa.clickMoreLeafletsInSidebar(
      {
        totalClicks: chunkClickCounter.current,
      },
    );
  };

  useEffect(
    () => {
      if (index === 1)
        chunkClickCounter.current = 0;
    },
    [],
  );

  return (
    <AdblockDetect hideDuringCheck={false}>
      {adblockState => !adblockState?.detected && (
        <Grid.Column
          ref={columnRef}
          xs={12}
          noLeftPadding
          noRightPadding
        >
          {showScrollButton && (
            <Button
              type='primary'
              size='small'
              outline
              onClick={onScrollNext}
            >
              {t('website.shared.titles.more')}
            </Button>
          )}
          <AdHolder
            name='box300x250'
            preserveInnerBoxSpace={false}
            style={style}
          />
        </Grid.Column>
      )}
    </AdblockDetect>
  );
};

SidebarInsertChunk.displayName = 'SidebarInsertChunk';

export default React.memo(SidebarInsertChunk);
