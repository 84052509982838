import * as R from 'ramda';

import removeNullValues, {removeValues} from '@adretail/basic-helpers/src/base/removeNullValues';

const removeEmptySearchParams = removeValues(
  R.anyPass(
    [
      R.isEmpty,
      R.isNil,
      R.equals(false),
    ],
  ),
);

export {
  removeNullValues,
};

export default removeEmptySearchParams;
