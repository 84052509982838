import React, {useMemo} from 'react';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@ding/constants';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {useUA} from '@adretail/basic-components/src/Context/UAProvider';

import NextGenPicture from './NextGenPicture';
import {Margin} from '../../Predefined';

const LeftAlignHolder = styled.div(
  {
    base: {
      minHeight: 120,
    },

    flex: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  {
    omitProps: ['flex'],
    classSelector: (classes, {flex}) => flex && classes.flex,
  },
);

export const DefaultDescriptionLogo = styled(
  NextGenPicture,
  {
    base: {
      width: 256,
      height: 'auto',
      objectFit: 'scale-down',
      verticalAlign: 'bottom',
    },

    expanded: {
      width: '100%',
      height: 'auto',
    },
  },
  {
    index: 1,
    omitProps: [
      'expanded',
    ],
    classSelector: (classes, {expanded}) => (
      expanded && classes.expanded
    ),
  },
);

const LeftAlignLogoDescription = ({
  children,
  logoComponent: LogoComponent,
  spacing = {
    right: 6,
    bottom: 4,
  },
  ...props
}) => {
  const ua = useUA();

  const small = ua.mobile && !ua.tablet;

  const logo = useMemo(() => (
    <Margin
      block
      bottom={small ? spacing.bottom : undefined}
      right={!small ? spacing.right : undefined}
    >
      <LogoComponent {...props} expanded={small} />
    </Margin>
  ), [small]);

  return (
    <LeftAlignHolder flex={!small}>
      {logo}
      {children && (
        <div style={{flex: 1}}>
          {children}
        </div>
      )}
    </LeftAlignHolder>
  );
};

LeftAlignLogoDescription.displayName = 'LeftAlignLogoDescription';

LeftAlignLogoDescription.propTypes = {
  logoComponent: REACT_COMPONENT_CLASS_SCHEMA,
};

LeftAlignLogoDescription.defaultProps = {
  logoComponent: DefaultDescriptionLogo,
};

export default LeftAlignLogoDescription;
