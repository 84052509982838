import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {matchPath} from 'react-router';
import * as R from 'ramda';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {buildUrl} from '@adretail/basic-helpers/src/url/encodeUrl';
import injectScript, {promiseInjectScript} from '@adretail/basic-helpers/src/async/injectScript';

import {alwaysArray} from '@ding/core/src/helpers';
import {useChunksRoutesState} from '@ding/routes/src/ChunksRoutesProvider';
import {DEFAULT_DELAY, DEFAULT_DELAY_WHEN_PRELOADED} from '@ding/routes/src/constants';
import FORCE_REDIRECT_URLS from '@ding/server/src/helpers/forceRedirectUrls';

import {UndecoratedLink} from '@ding/core/src/components/Predefined';
import LinkRedirectStateContext from '../context/LinkRedirectStateContext';

import prefixWithRootAddress from './prefixWithRootAddress';

export * from './optionalSegment';

const waitPromise = async (p, waitTime) => (
  await Promise.all([
    p,
    new Promise(resolve => setTimeout(resolve, waitTime)),
  ])
)[0];

const pickRouteChunk = (routings, urlPath) => R.find(
  ({path, exact, strict}) => matchPath(urlPath, {
    path, exact, strict,
  }),
  routings,
);

const createLinkComponent = (
  prop, urlGenerator, defaultTranslation, additionalProps,
) => {
  const urlGeneratorFn = R.when(
    R.is(String),
    R.always,
    urlGenerator,
  );

  const isSingleProp = !R.isNil(prop) && R.is(String, prop);
  const omitProp = R.omit(
    [
      ...alwaysArray(prop),
      'item',
    ],
  );

  const ContainerLink = ({
    searchParams, state, absolute, href,
    hash, utm, disableCustomItemUrl, injectOnHover, ...props
  }) => {
    const stateContext = useContext(LinkRedirectStateContext);
    const {getChunksRoutes} = useChunksRoutesState();
    const chunksRoutes = R.defaultTo(
      [],
      getChunksRoutes?.(),
    );

    const item = (
      (isSingleProp && props[prop]) || props.item
    );

    // url should be provided ONLY for
    // cross domain link items or in MPA apps
    let url = href || (!disableCustomItemUrl && item?.url);
    if (!url) {
      url = urlGeneratorFn(item, props);

      if (R.is(Object, url) && 'searchParams' in url) {
        url = url.url;
        searchParams = {
          ...searchParams,
          ...url.searchParams,
        };
      }

      if (absolute)
        url = prefixWithRootAddress(url);
    }

    const chunkUrl = pickRouteChunk(chunksRoutes, url)?.chunkUrl;
    if (utm) {
      searchParams = searchParams || {};
      R.forEachObjIndexed(
        (value, key) => {
          searchParams[`utm_${key}`] = value;
        },
        utm,
      );
    }

    let to = (
      searchParams
        ? buildUrl(url, searchParams)
        : url
    );

    if (hash && !R.contains('#', to))
      to = `${to}#${hash}`;

    if (stateContext || state) {
      to = {
        pathname: to,
        state: {
          ...stateContext,
          ...state,
        },
      };
    }
    return (
      <UndecoratedLink
        plainAnchor={!!FORCE_REDIRECT_URLS[to]}
        {...chunkUrl && {
          ...injectOnHover && {
            onMouseEnter: () => injectScript(
              chunkUrl,
            ),
          },

          awaitPromise: () => waitPromise(
            promiseInjectScript(chunkUrl),
            injectOnHover ? DEFAULT_DELAY_WHEN_PRELOADED : DEFAULT_DELAY,
          ),
        }}
        to={to}
        {...omitProp(props)}
      />
    );
  };

  ContainerLink.displayName = 'ContainerLink';

  ContainerLink.propTypes = {
    searchParams: PropTypes.objectOf(PropTypes.any),
    absolute: PropTypes.bool,
  };

  if (!defaultTranslation)
    return ContainerLink;

  return ({children, ...props}) => {
    const t = useTranslate();

    return (
      <ContainerLink
        {...props}
        {...additionalProps}
      >
        {children || t(defaultTranslation)}
      </ContainerLink>
    );
  };
};

export default createLinkComponent;
