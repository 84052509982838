import React from 'react';
import * as R from 'ramda';

import providePageI18n from '@ding/i18n/src/providePageI18n';

import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';

import ConfirmModal from '@ding/controls/src/Modals/ConfirmModal';
import {Margin} from '@ding/core/src/components/Predefined';
import {TitledCheckbox} from '@ding/controls/src';

const NsfwModal = ({t, l, ...props}) => (
  <ConfirmModal
    {...props}
    title={
      t('nsfw.modal.confirm_age')
    }
    disableButtons={{
      confirm: !props.value,
    }}
  >
    {() => (
      <div
        style={{
          width: 400,
          maxWidth: '100%',
        }}
      >
        <div>
          {t('nsfw.modal.confirm_title')}
        </div>

        <Margin top={3}>
          <TitledCheckbox
            type='square-outline'
            {...l.input()}
            size='medium'
          >
            {t('nsfw.modal.confirm_checkbox_title')}
          </TitledCheckbox>
        </Margin>
      </div>
    )}
  </ConfirmModal>
);

NsfwModal.displayName = 'NsfwModal';

export default R.compose(
  providePageI18n,
  linkedInputs(null, true),
)(NsfwModal);
