import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter,
  StaticRouter,
} from 'react-router-dom';

import ssr from '@adretail/basic-helpers/src/base/ssr';

export const RouterSessionContext = React.createContext(null);

export const useRouterSession = () => useContext(RouterSessionContext);

const RouterComponent = (
  ssr
    ? StaticRouter
    : BrowserRouter
);

const SSRRouter = ({routerSessionContext, ...props}) => (
  <RouterSessionContext.Provider value={{value: routerSessionContext || {}}}>
    <RouterComponent {...props} />
  </RouterSessionContext.Provider>
);

SSRRouter.propTypes = {
  routerSessionContext: PropTypes.any,
};

export default SSRRouter;
