import React from 'react';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import outsideClick from '@adretail/basic-decorators/src/events/outsideClick';

import {DARK, WHITE} from '@ding/constants/src/colorSchema';
import {popupZDepth} from '@ding/core/src/styles/zDepth';

const LAYER_STYLES = {
  position: 'absolute',
  left: -4,
  top: -4,
  borderRadius: 6,
};

const DropdownHolder = styled.div(
  {
    extend: [
      popupZDepth,
      LAYER_STYLES,
    ],

    width: 'calc(100% + 8px)',
    minHeight: 'calc(100% + 8px)',
    padding: '5px 0',
    textAlign: 'left',
    background: WHITE,
    color: DARK,
  },
);

const DropdownListHolder = styled.div(
  {
    width: '100%',
    padding: '0 10px',
    overflowY: 'auto',
  },
);

const AutocompleteDropdown = React.forwardRef(
  ({layerOffset, style, children, maxListHeight, ...props}, ref) => (
    <div
      style={{
        ...style,
        position: 'relative',
        zIndex: 10,
      }}
    >
      <DropdownHolder
        {...props}
        ref={ref}
        style={{
          paddingTop: layerOffset + 10,
        }}
      >
        <DropdownListHolder
          style={{
            maxHeight: maxListHeight,
          }}
        >
          {children}
        </DropdownListHolder>
      </DropdownHolder>
    </div>
  ),
);

export default outsideClick(AutocompleteDropdown);
