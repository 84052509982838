import React from 'react';
import * as R from 'ramda';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import isBetween from '@adretail/basic-helpers/src/base/isBetween';
import parseDateValue from '@adretail/basic-helpers/src/time/parseDateValue';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';

import {Flex} from '@ding/core/src/components/Predefined';
import ValidatedInput from '@ding/controls/src/Input/ValidatedInput';
import MonthSelect from '@ding/controls/src/Autocomplete/Predefined/MonthSelect';

export const toBirthDateSelectValue = R.compose(
  date => ({
    year: date.getFullYear(),
    month: date.getMonth(),
    day: date.getDate(),
    date,
  }),
  parseDateValue,
);

export const castBirthFieldsToDate = R.unless(
  R.either(
    R.is(Date),
    R.isNil,
  ),
  ({year, month, day}) => new Date(
    year || null,
    month || null,
    day || null,
  ),
);

const ALLOWED_DATES = {
  DAY: {
    min: 1,
    max: 31,
  },

  YEAR: {
    min: 1900,
    max: new Date().getFullYear(),
  },
};

const TinyValidatedInput = styled(
  ValidatedInput,
  {
    width: 80,
  },
  {
    showErrorMessage: false,
  },
);

const MonthColumn = styled(
  Flex.Grow,
  {
    margin: '0 10px',
    flexShrink: 0,
    minWidth: 120,
  },
);

const BirthDateSelect = ({l, required}) => {
  const t = useTranslate('website.shared.date');

  return (
    <Flex.Row>
      <TinyValidatedInput
        placeholder={
          t('day')
        }
        validatorFn={
          isBetween(ALLOWED_DATES.DAY)
        }
        required={required}
        {...l.input('day')}
        {...ALLOWED_DATES.DAY}
      />

      <MonthColumn>
        <MonthSelect
          placeholder={
            t('month')
          }
          required={required}
          {...l.input('month')}
        />
      </MonthColumn>

      <TinyValidatedInput
        placeholder={
          t('year')
        }
        validatorFn={
          isBetween(ALLOWED_DATES.YEAR)
        }
        required={required}
        {...l.input('year')}
        {...ALLOWED_DATES.YEAR}
      />
    </Flex.Row>
  );
};

BirthDateSelect.displayName = 'BirthDateSelect';

export default R.compose(
  React.memo,
  provideProps(
    ({onChange}) => ({
      onChange: onChange && (
        newValue => onChange({
          ...newValue,
          date: castBirthFieldsToDate(newValue),
        })
      ),
    }),
    true,
  ),
  linkedInputs(
    {
      groupName: 'value',
      singleValueProp: null,
    },
  ),
)(BirthDateSelect);
