import {useEffect} from 'react';

import showToast from '@ding/controls/src/ToastAlert/showToast';

import useReactRouter, {getRouterFullPath} from '@ding/core/src/hooks/useReactRouter';
import {useRouterSession} from '../components/SSRRouter';
import usePrevRoutePathAccessor from './usePrevRoutePathAccessor';

const useLayoutSessionHandler = ({savePathToSession} = {savePathToSession: true}) => {
  const {location} = useReactRouter();
  const {value: routerSession} = useRouterSession();
  const prevRoutePathAccessor = usePrevRoutePathAccessor();

  const fullPath = getRouterFullPath(location);
  useEffect(
    () => {
      // show toasts if it was already redirected
      if (routerSession.redirect) {
        const {toast} = routerSession.redirect;

        // show post redirect toast
        if (toast) {
          const {message, ...props} = toast;

          showToast(message, props);
          delete routerSession.redirect.toast;
        }
      }

      if (savePathToSession) {
        prevRoutePathAccessor.set(
          {
            fullPath,
            state: location.state,
          },
        );
      }
    },
    [
      savePathToSession,
      fullPath,
      location.state,
    ],
  );
};

export default useLayoutSessionHandler;
