import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';

import {dropSearchParams} from '@adretail/basic-helpers/src/url/encodeUrl';
import {dropPageInURL} from '@ding/control-groups/src/Pagination/utils';

import useReactRouter from '@ding/core/src/hooks/useReactRouter';

import {UndecoratedLink} from '@ding/core/src/components/Predefined';
import SortSelectButton from '../SortSelectButton';

const remapLinksToId = (exactSearchParams) => {
  const mapperFn = (
    exactSearchParams
      ? R.identity
      : dropSearchParams
  );

  return R.map(
    item => ({
      ...item,
      id: mapperFn(
        R.has('id', item)
          ? item.id
          : item.to,
      ),
    }),
  );
};

const SelectableLink = ({item: {to}, children, ...props}) => {
  if (!to)
    return children;

  return (
    <UndecoratedLink
      {...props}
      to={to}
    >
      {children}
    </UndecoratedLink>
  );
};

const LinkSelectButton = ({
  emptyOptionURL,
  list,
  linkComponent,
  itemProps,
  exactSearchParams,
  selectComponent: SelectComponent,
  ...props
}) => {
  const {location: {pathname, search}} = useReactRouter();
  const url = (
    exactSearchParams
      ? `${pathname}${search}`
      : pathname
  );

  const mappedList = useMemo(
    () => remapLinksToId(exactSearchParams)(
      emptyOptionURL
        ? [{emptyItem: true, to: emptyOptionURL}, ...list]
        : list,
    ),
    [
      url,
      list,
    ],
  );

  return (
    <SelectComponent
      {...props}
      addEmptyOption={false}
      value={
        dropPageInURL(url)
      }
      list={mappedList}
      itemProps={{
        linkComponent,
        ...itemProps,
      }}
    />
  );
};

LinkSelectButton.displayName = 'LinkSelectButton';

LinkSelectButton.propTypes = {
  emptyOptionURL: PropTypes.string,
  exactSearchParams: PropTypes.bool,
  selectComponent: REACT_COMPONENT_CLASS_SCHEMA,
  linkComponent: REACT_COMPONENT_CLASS_SCHEMA,
};

LinkSelectButton.defaultProps = {
  exactSearchParams: true,
  selectComponent: SortSelectButton,
  linkComponent: SelectableLink,
};

export default React.memo(LinkSelectButton);
