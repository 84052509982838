import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  WHITE,
  MUTED_GRAY,
  PRIMARY_FILL,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {browserPrefixClass} from '@adretail/fast-stylesheet/src/utils';

const getRangePercentage = (min, max, value) => ((value - min) / (max - min));

const RangeInputHolder = styled.input(
  {
    position: 'relative',
    top: -3, // fix chrome rendering

    '-webkit-appearance': 'none',
    appearance: 'none',

    minWidth: 130,
    height: 4,
    background: MUTED_GRAY,
    outline: 0,
    margin: 0,
    border: 0,

    ...browserPrefixClass(
      [
        '&::-webkit-slider-thumb',
        '&::-moz-range-thumb',
      ],
      {
        '-webkit-appearance': 'none',
        appearance: 'none',

        width: 18,
        height: 18,
        borderRadius: '100%',
        border: `1px solid ${MUTED_GRAY}`,
        background: `${WHITE} !important`,

        cursor: '-webkit-grab',
      },
    ),

    // fix firefox border
    '&::-moz-focus-outer': {
      border: 0,
    },
  },
  {
    type: 'range',
  },
);

RangeInputHolder.displayName = 'RangeInputHolder';

const RangeInput = ({
  style, value, min, max,
  ...props
}) => {
  const safeValue = R.defaultTo((min + max) / 2, value);
  const fillProgressPercentage = getRangePercentage(min, max, safeValue);

  return (
    <RangeInputHolder
      {...props}
      {...{
        min,
        max,
      }}
      value={safeValue}
      style={{
        ...style,
        backgroundImage: `-webkit-gradient(linear, left top, right top, color-stop(${fillProgressPercentage * 100}%, ${PRIMARY_FILL}), color-stop(${fillProgressPercentage * 100}%, ${MUTED_GRAY}))`,
      }}
    />
  );
};

RangeInput.displayName = 'RangeInput';

RangeInput.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default RangeInput;
