import {cacheOneCall} from '@adretail/basic-helpers/src/base/memoizeOne';

// selector is faster than getCurrentSlide
const cacheBySlideIndex = cacheOneCall(
  ([{ui: prevUI, resources: res}], [{ui: UI, resources: prevRes}]) => (
    prevUI.currentSlide !== UI.currentSlide
      || prevUI.pagesPerSlide !== UI.pagesPerSlide
      || prevUI.maxPagesPerSlide !== UI.maxPagesPerSlide
      || prevUI.modulesEnabled !== UI.modulesEnabled
      || (res.leaflet && prevRes.leaflet && res.leaflet.__updatedAt !== prevRes.leaflet.__updatedAt)
  ),
);

export default cacheBySlideIndex;
