import React from 'react';
import * as R from 'ramda';

import {AccountDescriptionQuery} from '@ding/auth/src/gql/components';
import {TitledLoadingSpinner} from '@ding/core/src/components/Parts';

import * as AuthButtons from '@ding/auth/src/client/Buttons';

import {AccountSettingsLink, FavouritesLink} from '@ding/layout/src/components/Links';
import UserIcon from '@ding/core/src/icons/User';

import PrimaryMenuItem from '../PrimaryMenuItem';

const MOBILE_MENU_LINKS = [
  {key: 'settings', link: <AccountSettingsLink />},
  {key: 'favourites', link: <FavouritesLink />},
];

const LoggedActionsList = React.memo(() => [
  ...R.map(
    ({key, link}) => (
      <PrimaryMenuItem
        key={key}
        title={link}
        type='dark'
      />
    ),
    MOBILE_MENU_LINKS,
  ),

  <AuthButtons.Logout
    key='logout'
    type='dark'

    titleProp='title'
    triggerComponent={PrimaryMenuItem}
  />,
]);

const LoggedMenuItem = props => (
  <AccountDescriptionQuery alwaysRenderComponent>
    {account => (
      <PrimaryMenuItem
        icon={UserIcon}
        title={(
          account?.email || <TitledLoadingSpinner iconOnRight />
        )}
        {...props}
      >
        {() => <LoggedActionsList />}
      </PrimaryMenuItem>
    )}
  </AccountDescriptionQuery>
);

LoggedMenuItem.displayName = 'LoggedMenuItem';

export default React.memo(LoggedMenuItem);
