import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'GLetter',
  {
    viewBox: '0 0 23 23',
  },
  <>
    <path fillRule='evenodd' clipRule='evenodd' d='m23 12-1-2H12v4h6l-2 3v3h3c2-2 4-5 4-8Z' fill='#4285F4' />
    <path fillRule='evenodd' clipRule='evenodd' d='M12 23c3 0 5-1 7-3l-3-3a7 7 0 0 1-11-3H2v3c2 3 5 6 10 6Z' fill='#34A853' />
    <path fillRule='evenodd' clipRule='evenodd' d='M5 14a7 7 0 0 1 0-4V7H2a11 11 0 0 0 0 10l3-3Z' fill='#FBBC05' />
    <path fillRule='evenodd' clipRule='evenodd' d='m12 5 4 2 3-4-7-2C7 1 4 3 2 7l3 3c1-3 4-5 7-5Z' fill='#EA4335' />
  </>,
);
