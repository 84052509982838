import React from 'react';

import {LEAFLET_CARD_SCHEMA} from '@ding/constants/src/typeSchema';
import prefixWithRootAddress from '@ding/layout/src/components/Links/utils/prefixWithRootAddress';

import removeNullValues from '@adretail/basic-helpers/src/base/removeNullValues';
import {genLeafletURL} from '@ding/layout/src/components/Links/LeafletLink';

import JsonLD from '@adretail/basic-components/src/JsonLD';

const pickDurationMicrodata = duration => duration && removeNullValues({
  startDate: duration.begin,
  endDate: duration.end,
});

const LeafletJsonLD = ({leaflet}) => {
  const {
    id,
    name,
    cover,
    duration,
    link,
    contractor,
  } = leaflet;

  return (
    <JsonLD
      json={{
        '@type': 'Newspaper',
        '@id': id,
        image: cover,
        name,
        url: contractor ? prefixWithRootAddress(genLeafletURL(leaflet)) : link,
        ...pickDurationMicrodata(duration),
      }}
    />
  );
};

LeafletJsonLD.displayName = 'LeafletJsonLD';

LeafletJsonLD.propTypes = {
  leaflet: LEAFLET_CARD_SCHEMA.isRequired,
};

export default React.memo(LeafletJsonLD);
