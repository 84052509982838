import React from 'react';
import * as R from 'ramda';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';
import getImageURLFromFile from '@adretail/basic-helpers/src/getters/getImageURLFromFile';

import LoadingSpinner from '../LoadingSpinner';
import ImageIconPlaceholder from './ImageIconPlaceholder';

class GracefulImage extends React.PureComponent {
  static propTypes = {
    fallbackComponent: REACT_COMPONENT_CLASS_SCHEMA,
    loadingComponent: REACT_COMPONENT_CLASS_SCHEMA,
  }

  static defaultProps = {
    fallbackComponent: ImageIconPlaceholder,
    loadingComponent: LoadingSpinner,
  }

  state = {
    error: false,
    loading: true,
    prevSrc: null,
  };

  static getDerivedStateFromProps({src}, {loading, src: prevSrc}) {
    return {
      loading: loading || !prevSrc || src !== prevSrc,
      src,
      prevSrc,
    };
  }

  componentDidMount() {
    this.loadImage(this.props.src);
  }

  componentDidUpdate(prevProps, prevState) {
    const {src} = this.props;
    if (prevState.src !== src)
      this.loadImage(src);
  }

  componentWillUnmount() {
    const {img} = this;
    this.unmounted = true;

    if (img) {
      img.onload = null;
      img.onerror = null;
    }
  }

  loadImage = async (src) => {
    const onLoad = (img, error) => !this.unmounted && this.setState({
      loading: false,
      error: !src || error || !img.naturalWidth,
    });

    if (!src) {
      onLoad(false);
      return;
    }

    if (!this.state.loading) {
      this.setState(
        {
          loading: true,
        },
      );
    }

    const img = new Image;
    const loadedSrc = await R.when(
      R.is(File),
      getImageURLFromFile,
    )(src);

    this.img = img;
    img.src = loadedSrc;

    if (img.complete)
      onLoad(img, false);
    else {
      img.onload = onLoad.bind(this, img, false);
      img.onerror = onLoad.bind(this, img, true);
    }
  }

  hydrateImage() {
    const {imageRef: {current: imgElement}} = this;

    // error
    if (imgElement.complete && !imgElement.naturalWidth)
      this.onError();
  }

  render() {
    const {
      fallback,
      src,
      alt,
      loadingComponent: LoadingComponent,
      fallbackComponent: FallbackComponent,
      children,
      ...props
    } = this.props;

    const {
      loading,
      error,
    } = this.state;

    if (R.is(Function, children)) {
      return children(
        {
          src,
          loading,
          error,
        },
      );
    }

    let Component = 'img';
    if (!src || (error && FallbackComponent))
      Component = FallbackComponent;

    else if (loading && LoadingComponent)
      Component = LoadingComponent;

    return (
      <Component
        {...!error && !loading && {
          src,
          alt,
          onError: this.onError,
        }}
        {...props}
      />
    );
  }
}

export default GracefulImage;
