import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {translateAddress} from '@ding/core/src/components/Parts/Titles/Address';

import AddressAutocompleteItem from '../Items/Address';
import GQLAutocomplete from '../GQLAutocomplete';

const createAddressID = address => `${address.province?.id}-${address.city?.id}-${address.street?.id}-${address.streetNo}`;

const appendItemParamsToAddresses = t => R.map(
  (address) => {
    const name = translateAddress(t, address);

    return {
      ...address,
      id: createAddressID(address),
      name,
    };
  },
);

const AddressAutocomplete = ({variables, onlyCity, ...props}) => {
  const t = useTranslate();

  return (
    <GQLAutocomplete
      responseSelector={
        R.compose(
          appendItemParamsToAddresses(t),
          R.pathOr([], ['addresses', 'items']),
        )
      }
      itemComponent={AddressAutocompleteItem}
      placeholder={
        t(`website.form.address_autocomplete.${onlyCity ? 'city_' : ''}placeholder`)
      }
      {...props}
      queryVariables={{
        ...variables,
        filters: {
          ...variables?.filters,
          onlyCity,
        },
      }}
    />
  );
};

AddressAutocomplete.displayName = 'AddressAutocomplete';

AddressAutocomplete.propTypes = {
  query: PropTypes.any.isRequired,
  onlyCity: PropTypes.bool,
};

AddressAutocomplete.defaultProps = {
  onlyCity: false,
};

export default React.memo(AddressAutocomplete);
