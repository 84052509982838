const LETTERS = {
  pl: [
    [/ą/g, 'a'], [/Ą/g, 'A'],
    [/ć/g, 'c'], [/Ć/g, 'C'],
    [/ę/g, 'e'], [/Ę/g, 'E'],
    [/ł/g, 'l'], [/Ł/g, 'L'],
    [/ń/g, 'n'], [/Ń/g, 'N'],
    [/ó/g, 'o'], [/Ó/g, 'O'],
    [/ś/g, 's'], [/Ś/g, 'S'],
    [/ż/g, 'z'], [/Ź/g, 'Z'],
    [/ź/g, 'z'], [/Ż/g, 'Z'],
  ],

  uk: [
    [/а/g, 'a'], [/А/g, 'A'],
    [/б/g, 'b'], [/Б/g, 'B'],
    [/в/g, 'v'], [/В/g, 'V'],
    [/Г/g, 'G'], [/Ґ/g, 'G'],
    [/г/g, 'g'], [/ґ/g, 'g'],
    [/д/g, 'd'], [/Д/g, 'D'],
    [/е/g, 'e'], [/Е/g, 'E'],
    [/є/g, 'je'], [/Є/g, 'JE'],
    [/ж/g, 'zh'], [/Ж/g, 'ZH'],
    [/з/g, 'z'], [/З/g, 'Z'],
    [/и/g, 'y'], [/И/g, 'Y'],
    [/і/g, 'i'], [/І/g, 'I'],
    [/ї/g, 'ji'], [/Ї/g, 'JI'],
    [/Ъ/g, ''], [/Ъ/g, ''],
    [/й/g, 'j'], [/Й/g, 'J'],
    [/к/g, 'k'], [/К/g, 'K'],
    [/л/g, 'l'], [/Л/g, 'L'],
    [/м/g, 'm'], [/М/g, 'M'],
    [/н/g, 'n'], [/Н/g, 'N'],
    [/о/g, 'o'], [/О/g, 'O'],
    [/п/g, 'p'], [/П/g, 'P'],
    [/р/g, 'r'], [/Р/g, 'R'],
    [/с/g, 's'], [/С/g, 'S'],
    [/т/g, 't'], [/Т/g, 'T'],
    [/у/g, 'u'], [/У/g, 'U'],
    [/ф/g, 'f'], [/Ф/g, 'F'],
    [/х/g, 'h'], [/Х/g, 'H'],
    [/ц/g, 'ts'], [/Ц/g, 'TS'],
    [/ч/g, 'ch'], [/Ч/g, 'CH'],
    [/ш/g, 'sh'], [/Ш/g, 'SH'],
    [/щ/g, 'shch'], [/Щ/g, 'SHCH'],
    [/ь/g, ''], [/Ь/g, ''],
    [/ю/g, 'ju'], [/Ю/g, 'JU'],
    [/я/g, 'ja'], [/Я/g, 'JA'],
  ]};

const escapeDiacritics = (str) => {
  let unescaped = str;

  Object.keys(LETTERS).forEach((lang) => {
    for (let i = LETTERS[lang].length - 1; i >= 0; --i) {
      const template = LETTERS[lang][i];
      unescaped = unescaped.replace(template[0], template[1]);
    }
  });

  return unescaped;
};

export default escapeDiacritics;
