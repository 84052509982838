import PropTypes from 'prop-types';

import {TRUNCATE_NAV_SIZE} from '@ding/layout/src/constants/navSize';

import {PRIMARY_FILL} from '@ding/constants/src/colorSchema';
import styled from '@adretail/fast-stylesheet/src/react/styled';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';

import {popupZDepth} from '@ding/core/src/styles/zDepth';

/**
 * @see
 *  used in:
 *    MobileHeaderHolder
 *    DesktopHeaderHolder
 */
const HeaderHolder = styled.header(
  {
    base: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      position: 'relative',
      padding: '0 10px',
      zIndex: 999,
      width: '100%',
      background: PRIMARY_FILL,

      ...createBreakpointClasses(
        {
          xs: {
            height: 50,
          },
          md: {
            height: TRUNCATE_NAV_SIZE,
          },
        },
      ),
    },

    shadow: {
      extend: popupZDepth,
    },
  },
  {
    index: 1,
    omitProps: [
      'truncated',
      'shadow',
    ],

    classSelector: (classes, {shadow}) => [
      shadow && classes.shadow,
    ],
  },
);

HeaderHolder.displayName = 'HeaderHolder';

HeaderHolder.propTypes = {
  truncated: PropTypes.bool,
  shadow: PropTypes.bool,
};

export default HeaderHolder;
