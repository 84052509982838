import React from 'react';
import PropTypes from 'prop-types';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {ValidationGroupConsumer} from '@ding/controls/src/Input/ValidatedInput';
import AsyncSpinnerButton from '@ding/controls/src/Button/AsyncSpinnerButton';
import FormRow from './FormRow';

export const SendFormButton = ({title, ...props}) => {
  const t = useTranslate();

  return (
    <AsyncSpinnerButton
      type='primary'
      {...props}
    >
      {title || t('website.shared.titles.send')}
    </AsyncSpinnerButton>
  );
};

const SubmitFormRow = ({
  buttonComponent: ButtonComponent,
  loading, centered, title, forceDisable,
}) => (
  <FormRow centered={centered}>
    <ValidationGroupConsumer>
      {({isValidated}) => (
        <ButtonComponent
          loading={loading}
          disabled={forceDisable || !isValidated()}
          title={title}
          submit
        />
      )}
    </ValidationGroupConsumer>
  </FormRow>
);

SubmitFormRow.displayName = 'SubmitFormRow';

SubmitFormRow.propTypes = {
  buttonComponent: REACT_COMPONENT_CLASS_SCHEMA,
  title: PropTypes.string,
  forceDisable: PropTypes.bool,
};

SubmitFormRow.defaultProps = {
  buttonComponent: SendFormButton,
  title: null,
  forceDisable: false,
};

export default SubmitFormRow;
