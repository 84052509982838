import React from 'react';
import PropTypes from 'prop-types';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {Button} from '@ding/controls/src/';
import {LeafletsLink} from '@ding/layout/src/components/Links';

const MoreLeafletsButton = ({translation, linkProps, ...props}) => {
  const t = useTranslate();

  return (
    <LeafletsLink {...linkProps}>
      <Button.Primary
        outline
        {...props}
      >
        {t(translation || 'website.shared.sections.leaflets.see_more')}
      </Button.Primary>
    </LeafletsLink>
  );
};

MoreLeafletsButton.displayName = 'MoreLeafletsButton';

MoreLeafletsButton.propTypes = {
  translation: PropTypes.string,
};

export default MoreLeafletsButton;
