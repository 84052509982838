export default {
  title: 'Tagi',

  description: {
    header: '%{} - oferty i gazetki promocyjne',
    image_alt: 'Promocje %{}',
  },

  meta: {
    title: '%{name} – promocje i gdzie można tanio kupić%{domainSuffix}',
    description: 'Sprawdź aktualne promocje na: %{name}! Gazetki promocyjne, rabaty i oferty sklepów. Zobacz gdzie można tanio kupić!',

    og: {
      title: '%{name} – najlepsze oferty, największe przeceny%{domainSuffix}',
    },
  },
};
