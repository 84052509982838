import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import ExpandableContent from '@adretail/basic-components/src/ExpandableContent';
import {OutlineExpandToolbarRow} from '../ExpandableFunctionalChunksList/toolbars/OutlineExpandToolbarRow';

/**
 * @see
 *  Instead of ExpandableFunctionalChunksList it loads whole
 *  array and hides content, it is used for SEO
 */
const ExpandableOverflowGrid = ({
  list, toolbarRenderFn, initialExpand,
  tolerance,
  gridComponent: GridComponent,
  ...props
}) => {
  const lists = useMemo(
    () => {
      if (!list)
        return list;

      if ((1 + tolerance) * initialExpand >= list.length)
        return [list, []];

      return R.splitAt(initialExpand, list);
    },
    [
      list?.length,
      initialExpand,
    ],
  );

  if (!lists)
    return null;

  return (
    <ExpandableContent animDuration={400}>
      {({
        toggled,
        contentStyle,
        contentRef,
        onToggle,
      }) => (
        <>
          <GridComponent
            list={lists[0]}
            {...props}
          />

          {!R.isEmpty(lists[1]) && (
            <>
              <div style={contentStyle}>
                <GridComponent
                  list={lists[1]}
                  ref={contentRef}
                  {...props}
                />
              </div>

              {toolbarRenderFn(
                {
                  toggled,
                  onToggle,
                },
              )}
            </>
          )}
        </>
      )}
    </ExpandableContent>
  );
};

ExpandableOverflowGrid.displayName = 'ExpandableOverflowGrid';

ExpandableOverflowGrid.propTypes = {
  initialExpand: PropTypes.number,
  toolbarRenderFn: PropTypes.func,
  tolerance: PropTypes.number,
};

ExpandableOverflowGrid.defaultProps = {
  initialExpand: 16,
  tolerance: 0.5,
  toolbarRenderFn: ({toggled, onToggle}) => (
    <OutlineExpandToolbarRow
      mode={
        toggled
          ? 'less'
          : 'more'
      }
      expanded={false}
      onClick={onToggle}
    />
  ),
};

export default ExpandableOverflowGrid;
