import * as R from 'ramda';

import {ITEM_TYPES} from '@ding/constants';
import cacheByLeafletId from '../cache/cacheByLeafletId';

export const getAdsConfig = R.prop('ads');

export const getUIState = R.prop('ui');

export const getResources = R.prop('resources');

const createUIStateSelector = prop => R.compose(
  R.prop(prop),
  getUIState,
);

export const areModulesEnabled = createUIStateSelector('modulesEnabled');

export const getZoom = createUIStateSelector('zoom');

export const getFlags = createUIStateSelector('flags');

export const isDragging = createUIStateSelector('dragging');

export const getZoomPercentage = (state) => {
  const config = getZoomConfig(state);
  const zoom = R.defaultTo(config.min, getZoom(state)?.zoom);

  return (zoom - config.min) / (config.max - config.min);
};

export const getZoomConfig = createUIStateSelector('zoomConfig');

export const getCurrentSlide = createUIStateSelector('currentSlide');

export const getPagesPerSlide = createUIStateSelector('pagesPerSlide');

export const getLeafletContent = R.compose(
  R.prop('leaflet'),
  getResources,
);

export const getLeafletInfo = R.compose(
  R.prop('info'),
  getLeafletContent,
);

export const getLeafletPages = R.compose(
  R.prop('pages'),
  getLeafletContent,
);

export const getTotalPages = R.compose(
  R.length,
  getLeafletPages,
);

/** @see LEAFLET_CARD_SCHEMA */
export const getLeafletCard = (state) => {
  const info = getLeafletInfo(state);

  return {
    kind: ITEM_TYPES.LEAFLET,
    originPage: null,
    ...R.pick(
      [
        'id', 'name', 'cover',
        'contractor', 'duration', 'archived',
      ],
      info,
    ),
  };
};

export const selectors = {
  getAdsConfig: () => getAdsConfig,

  areModulesEnabled: () => areModulesEnabled,
  isDragging: () => isDragging,

  getPagesPerSlide: () => getPagesPerSlide,
  getLeafletContent: () => getLeafletContent,
  getLeafletPages: () => getLeafletPages,
  getLeafletInfo: () => getLeafletInfo,
  getFlags: () => getFlags,

  getUIState: () => getUIState,
  getCurrentSlide: () => getCurrentSlide,
  getTotalPages: () => getTotalPages,
  getZoom: () => getZoom,
  getZoomConfig: () => getZoomConfig,
  getZoomPercentage: () => getZoomPercentage,
  getLeafletCard: () => cacheByLeafletId(getLeafletCard),
};
