import styled from '@adretail/fast-stylesheet/src/react/styled';

import {DARK} from '@ding/constants/src/colorSchema';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';

const PADDING_STYLES = {
  'padding-small': {
    paddingLeft: 40,
    '&:before': {
      width: 20,
    },
  },

  'padding-big': {
    paddingLeft: 100,
    '&:before': {
      width: 60,
    },
  },
};

const SectionTitle = styled.span(
  {
    base: {
      display: 'flex',
      position: 'relative',
      fontSize: 10,
      fontWeight: 700,
      textTransform: 'uppercase',

      // line before title
      '&:before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 5,
        height: 2,
        background: DARK,
      },
    },

    ...PADDING_STYLES,

    'padding-auto': createBreakpointClasses({
      xs: PADDING_STYLES['padding-small'],
      md: PADDING_STYLES['padding-big'],
    }),
  },
  {
    omitProps: ['padding'],
    classSelector: (classes, {padding}) => (
      classes[`padding-${padding || 'auto'}`]
    ),
  },
);

SectionTitle.displayName = 'SectionTitle';

export default SectionTitle;
