import * as R from 'ramda';

const createNullsArray = length => R.times(R.always(null), length);

/**
 * Counts all chunks without exec splitPagesToChunks which is sloow
 *
 * @param {Number}  chunksSize
 * @param {Any[]}   pages
 *
 * @return {Number}
 */
export const getTotalPagesChunks = (chunksSize, pages) => (
  // first is info page
  1 + Math.ceil((pages.length - 1) / chunksSize)
);

/**
 * Slice flat pages list to chunks, first should be
 * cover of slide, rest(except last) are normal pages
 *
 * @example
 * [1, 2, 3, 4, 5] => [[null, 1], [2, 3], [4, 5], [2, null]]
 *
 * @param {Number}  chunksSize
 * @param {Any[]}   pages
 *
 * @returns {Any[]}
 */
const splitPagesToChunks = R.curry(
  (chunksSize, pages) => R.compose(
    // ZO pages, fill with nulls if pages length < every
    chunks => R.adjust(
      R.ifElse(
        array => array.length < chunksSize,
        array => R.concat(
          array,
          createNullsArray(chunksSize - array.length),
        ),
        R.identity,
      ),
      chunks.length - 1,
    )(chunks),

    // PP pages, fill with nulls
    R.adjust(
      R.concat(
        createNullsArray(chunksSize - 1),
      ),
      0,
    ),

    // only split
    R.converge(
      R.prepend,
      [
        R.pipe(R.head, R.of),
        R.compose(
          R.splitEvery(chunksSize),
          R.slice(1, Infinity),
        ),
      ],
    ),

    // add indexes
    R.addIndex(R.map)(
      (page, index) => ({
        ...page,
        index,
      }),
    ),
  )(pages),
);

export default splitPagesToChunks;
