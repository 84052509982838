import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {WHITE} from '@ding/constants/src/colorSchema';
import translate from '@ding/i18n';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import {withResponsiveModal} from '@ding/controls/src/ResponsiveModalToggle';

import MarkerOutlineIcon from '@ding/core/src/icons/navbar/Geo';
import {Flex, TextButton} from '@ding/core/src/components/Predefined';
import {IconButton} from '@ding/controls/src/';

import {useGeolocationAddress} from '../GeolocationContext';
import GeolocationChangeModal from '../GeolocationChangeModal';
import GeolocationTitle from './GeolocationTitle';
import GeolocationHeader from './GeolocationHeader';

const css = {
  geolocation: {
    color: WHITE,
    cursor: 'pointer',
  },

  icon: {
    '&:not(:only-child)': {
      marginRight: 10,
    },
  },
};

const GeolocationModalTrigger = ({t, withIcon, withTitle, classes, onClick}) => {
  const address = useGeolocationAddress();
  const title = t('website.geolocation.titles.localize_me');

  return (
    <Flex.Row
      role='presentation'
      className={classes.geolocation}
      onClick={onClick}
    >
      {withIcon && (
        <IconButton
          icon={MarkerOutlineIcon}
          type='light'
          iconSize='medium'
          aria-label={title}
          aria-haspopup
          title={title}
          className={classes.icon}
        />
      )}
      {address && withTitle && (
        <GeolocationTitle address={address} />
      )}
    </Flex.Row>
  );
};

GeolocationModalTrigger.displayName = 'GeolocationButton';

GeolocationModalTrigger.propTypes = {
  withIcon: PropTypes.bool,
  withTitle: PropTypes.bool,
};

GeolocationModalTrigger.defaultProps = {
  withIcon: true,
  withTitle: true,
};

export {
  GeolocationTitle,
};

/**
 * @see
 *  used in:
 *    MobileHeader/MenuModal
 */
export const withGeolocationModal = withResponsiveModal(
  {
    modalContentComponent: GeolocationChangeModal,
    toggleProps: ({t, onToggle}) => ({
      modalProps: {
        title: t('website.modals.geolocalize.your_localization'),
      },
      onToggle,
    }),
  },
);

export const GeolocalizeTextButton = R.compose(
  translate,
  withGeolocationModal,
)(
  ({t, ...props}) => <TextButton {...props} />,
);

export const GeolocationButton = R.compose(
  React.memo,
  translate,
  withGeolocationModal,
)(
  ({t, ...props}) => {
    const address = useGeolocationAddress();
    const title = t('website.geolocation.titles.localize_me');

    return (
      <IconButton
        icon={MarkerOutlineIcon}
        type='light'
        iconSize='medium'
        titled
        aria-label={title}
        title={title}
        style={{textTransform: 'none'}}
        {...props}
      >
        <GeolocationHeader address={address} />
      </IconButton>
    );
  },
);

export default R.compose(
  React.memo,
  translate,
  withGeolocationModal,
  injectClassesSheet(css),
)(GeolocationModalTrigger);
