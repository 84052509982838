import PropTypes from 'prop-types';
import * as R from 'ramda';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/typeSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import flexCenter from '@ding/core/src/styles/flexCenter';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';

import * as Colors from '@ding/constants/src/colorSchema';
import {floatingButtonShadow} from '@ding/core/src/styles/zDepth';

export const BUTTON_TYPE = {
  DARK: 'dark',

  LIGHT: 'light',

  THIN_GRAY: 'thin-gray',

  GREEN: 'green',
  GREEN_OUTLINE: 'green-outline',

  PRIMARY: 'primary',
  PRIMARY_OUTLINE: 'primary-outline',

  PRIMARY_GREEN: 'primary-green',

  SECONDARY: 'secondary',
  SECONDARY_LIGHT: 'secondary-light',
  NO_DECORATION: 'no-decoration',
};

const createJustifyClass = direction => ({
  justifyContent: direction,
  textAlign: direction,
});

const css = {
  base: {
    extend: flexCenter,

    position: 'relative',
    height: 50,
    padding: [0, 20],
    fontSize: 13,
    fontWeight: 700,
    outline: 0,
    textTransform: 'uppercase',
    cursor: 'pointer',
    transition: '250ms ease-in-out',
    transitionProperty: 'min-width, background, color, border',

    border: '1.5px solid transparent',
  },

  truncateHeight: createBreakpointClasses(
    {
      xs: {
        height: 40,
      },

      md: {
        height: 50,
      },
    },
  ),

  expandedPadding: createBreakpointClasses(
    {
      xs: {
        padding: [0, 20],
      },

      md: {
        padding: [0, 50],
      },
    },
  ),

  'size-tiny': {
    height: 28,
    padding: '0 10px',
    fontSize: 10,
  },

  'size-small': {
    height: 35,
    padding: '0 15px',
    fontSize: 10,
  },

  'size-medium-small': {
    height: 45,
    padding: '0 28px',
    fontSize: 11,
  },

  'text-left': createJustifyClass('left'),
  'text-center': createJustifyClass('center'),
  'text-right': createJustifyClass('right'),

  'radius-none': {borderRadius: 0},
  'radius-tiny': {borderRadius: 4},
  'radius-small': {borderRadius: 8},
  'radius-medium': {borderRadius: 16},
  'radius-big': {borderRadius: 24},

  expanded: {
    width: '100%',
  },

  centered: {
    margin: '0 auto',
  },

  disabled: {
    opacity: 0.35,
    pointerEvents: 'none',
  },

  shadow: {
    extend: floatingButtonShadow,
    overflow: 'visible',
  },

  // GREEEN
  [BUTTON_TYPE.GREEN]: {
    background: Colors.GRASS_GREEN,
    color: Colors.WHITE,
  },

  [`${BUTTON_TYPE.GREEN}-hoverable`]: {
    '&:hover': {
      background: 'inherit',
      color: Colors.LIGHT_MUTED_GRASS_GREEN,
      border: `1.5px solid ${Colors.LIGHT_MUTED_GRASS_GREEN}`,
    },
  },

  [BUTTON_TYPE.GREEN_OUTLINE]: {
    background: 'inherit',
    color: Colors.GRASS_GREEN,
    borderColor: Colors.GRASS_GREEN,
  },

  [`${BUTTON_TYPE.GREEN_OUTLINE}-hoverable`]: {
    '&:hover': {
      background: Colors.GRASS_GREEN,
      color: Colors.WHITE,
    },
  },

  // DARK
  [BUTTON_TYPE.DARK]: {
    background: Colors.DARK,
    color: Colors.WHITE,
  },

  // LIGHT
  [BUTTON_TYPE.LIGHT]: {
    background: Colors.WHITE,
    color: Colors.DARK,
  },
  [`${BUTTON_TYPE.LIGHT}-hoverable`]: {
    '&:hover': {
      background: Colors.HOVERED_LIGHT_BORDER,
    },
  },

  [`${BUTTON_TYPE.LIGHT}-outline`]: {
    background: 'none',
    color: Colors.WHITE,
    borderColor: Colors.WHITE,
  },
  [`${BUTTON_TYPE.LIGHT}-outline-hoverable`]: {
    '&:hover': {
      color: Colors.HOVERED_LIGHT_BORDER,
      borderColor: Colors.HOVERED_LIGHT_BORDER,
    },
  },

  // PRIMARY
  [BUTTON_TYPE.PRIMARY]: {
    background: Colors.LIGHT_RED,
    color: Colors.WHITE,
  },

  [`${BUTTON_TYPE.PRIMARY}-hoverable`]: {
    '&:hover': {
      background: 'inherit',
      color: Colors.LIGHT_RED,
      border: `1.5px solid ${Colors.LIGHT_RED}`,
    },
  },

  [BUTTON_TYPE.PRIMARY_OUTLINE]: {
    background: 'inherit',
    color: Colors.LIGHT_RED,
    borderColor: Colors.LIGHT_RED,
  },

  [`${BUTTON_TYPE.PRIMARY_OUTLINE}-hoverable`]: {
    '&:hover': {
      background: Colors.HIGHTLIGHT_PRIMARY_FILL,
      color: Colors.WHITE,
    },
  },

  // GRAY with light border
  [`${BUTTON_TYPE.THIN_GRAY}-outline`]: {
    background: 'initial',
    color: Colors.THIN_GRAY_BUTTON_COLOR,
    borderColor: Colors.THIN_GRAY_BUTTON_BORDER,
    borderWidth: 1,
    fontWeight: 'initial',
  },

  // PRIMARY on hover becames GREEN
  [BUTTON_TYPE.PRIMARY_GREEN]: {
    composes: `$${BUTTON_TYPE.PRIMARY}`,
  },

  [`${BUTTON_TYPE.PRIMARY_GREEN}-hoverable`]: {
    '&:hover': {
      background: Colors.GRASS_GREEN,
      color: Colors.WHITE,
    },
  },

  // SECONDARY with white text
  [`${BUTTON_TYPE.SECONDARY_LIGHT}-hoverable`]: {
    background: Colors.DEFAULT_BUTTON_BACKGROUND,
    color: Colors.DARK,

    '&:hover': {
      background: Colors.HIGHTLIGHT_PRIMARY_FILL,
      color: Colors.WHITE,
    },
  },

  // SECONDARY with dark text
  [`${BUTTON_TYPE.SECONDARY}-hoverable`]: {
    background: Colors.DEFAULT_BUTTON_BACKGROUND,
    color: Colors.DARK,

    '&:hover': {
      background: '#d8d8d8',
      border: '1.5px solid #d8d8d8',
    },
  },

  // NO DECORATION
  [BUTTON_TYPE.NO_DECORATION]: {
    height: 'auto',
    padding: 0,
    margin: 0,
    background: 'inherit',
    color: 'inherit',
  },

  [`${BUTTON_TYPE.NO_DECORATION}-hoverable`]: {
    composes: `$${BUTTON_TYPE.NO_DECORATION}`,
  },

  mutedHover: {
    transitionProperty: 'opacity',

    '&:hover': {
      opacity: 0.35,
    },
  },

  muted: {
    color: `${Colors.MUTED_GRAY}`,
    background: 'inherit',
    border: `1px solid ${Colors.MUTED_GRAY}`,
  },
};

const Button = styled.button(
  css,
  {
    index: 0,
    role: 'button',
    type: 'button',

    omitProps: [
      'type',
      'outline',
      'noHover',
      'disabled',
      'shadow',
      'radius',
      'textAlign',
      'expanded',
      'submit',
      'centered',
      'size',
      'mutedHover',
      'muted',
      'expandedPadding',
      'truncateHeight',
      'eventData',
    ],

    mergeProps: ({submit}) => submit && ({
      type: 'submit',
    }),

    classSelector: (
      classes,
      {
        centered, expanded, textAlign, type,
        radius, outline, disabled, noHover,
        size, expandedPadding, shadow, mutedHover, muted,
        truncateHeight,
      },
    ) => {
      const typeClassName = `${type}${outline ? '-outline' : ''}`;

      return [
        classes[typeClassName],
        !noHover && !mutedHover && classes[`${typeClassName}-hoverable`],
        mutedHover && classes.mutedHover,
        classes[`radius-${radius}`],
        classes[`text-${textAlign}`],
        size && classes[`size-${size}`],
        disabled && classes.disabled,
        expanded && classes.expanded,
        centered && classes.centered,
        muted && classes.muted,
        shadow && classes.shadow,
        expandedPadding && classes.expandedPadding,
        truncateHeight && classes.truncateHeight,
      ];
    },
  },
);

Button.displayName = 'Button';

Button.Type = BUTTON_TYPE;

const BUTTON_TYPES_SCHEMA = PropTypes.oneOf(R.values(BUTTON_TYPE));

Button.propTypes = {
  type: BUTTON_TYPES_SCHEMA,
  disabled: PropTypes.bool,
  mutedHover: PropTypes.bool,
  expandedPadding: PropTypes.bool,
  expanded: PropTypes.bool,
  centered: PropTypes.bool,
  radius: SIZE_ARRAY_SCHEMA,
  size: SIZE_ARRAY_SCHEMA,
  textAlign: PropTypes.oneOf([
    'left',
    'center',
    'right',
  ]),
  submit: PropTypes.bool,
  outline: PropTypes.bool,
  noHover: PropTypes.bool,
  truncateHeight: PropTypes.bool,
  shadow: PropTypes.bool,
};

Button.defaultProps = {
  radius: 'big',
  textAlign: 'center',
  expandedPadding: true,
};

Button.Primary = provideProps(
  {
    type: BUTTON_TYPE.PRIMARY,
  },
)(Button);

Button.Secondary = provideProps(
  {
    type: BUTTON_TYPE.SECONDARY,
  },
)(Button);

export default Button;
