import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {useUA} from '@adretail/basic-components/src/Context/UAProvider';

import {Flex} from '@ding/core/src/components/Predefined';
import Button from '@ding/controls/src/Button';

export const OutlineExpandToolbarRow = ({mode, remainItems, onClick, children, ...props}) => {
  const t = useTranslate();
  const ua = useUA();

  const title = t(`website.shared.titles.${mode}`);
  return (
    <Flex.Row>
      <Button
        type='primary'
        outline
        style={{
          margin: '10px auto 0',
        }}
        expanded={ua.mobile}
        onClick={onClick}
        {...props}
      >
        {(
          R.isNil(remainItems)
            ? title
            : `${title} (${remainItems})`
        )}
        {children}
      </Button>
    </Flex.Row>
  );
};

OutlineExpandToolbarRow.displayName = 'ExpandTextToolbarRow';

OutlineExpandToolbarRow.propTypes = {
  mode: PropTypes.string,
  remainItems: PropTypes.number,
  onLoadNextChunk: PropTypes.func,
};

OutlineExpandToolbarRow.defaultProps = {
  mode: 'more',
};

export const outlineButtonToolbarFn = ({size, chunkInfo, onLoadNextChunk}) => (
  !chunkInfo.remainItems
    ? null
    : (
      <OutlineExpandToolbarRow
        size={size}
        remainItems={chunkInfo.remainItems}
        onClick={onLoadNextChunk}
      />
    )
);

export const customizedButtonToolbarFn = props => ({chunkInfo, onLoadNextChunk}) => (
  !chunkInfo.remainItems
    ? null
    : (
      <OutlineExpandToolbarRow
        {...props}
        remainItems={chunkInfo.remainItems}
        onClick={onLoadNextChunk}
      />
    )
);

export default OutlineExpandToolbarRow;
