import * as R from 'ramda';

export const PL_DATE_SEPARATOR_SYMBOL = '–';

/**
 * @description wrapper for `Intl.DateTimeFormat` to format date
 *
 * @example
 *  07.02
 *  07.02.2022
 *  7 Feb 2022"
 *
 * @param {string|Date} inputDate date in string format corresponding to ECMA spec or Date object
 * @param {object} [options={month: '2-digit', day: '2-digit'}] optional props for
 *                 Intl.DateTimeFormat,
 * @param {string} [locales=pl] locales in BCP 47 language tag for Intl.DateTimeFormat
 * @returns {string} formatted data
 */
const formatSingleDate = (inputDate, options = {month: '2-digit', day: '2-digit'}, locales = 'pl') => {
  if (!inputDate) return null; // because null is valid input for Data.parse

  const date = R.is(Date, inputDate) ? inputDate : new Date(inputDate);

  if (date.toString() === 'Invalid Date') return null;

  const dateTimeFormat = new Intl.DateTimeFormat(locales, {...options});

  return dateTimeFormat.format(date);
};

/**
 * @description wrapper for `Intl.DateTimeFormat` to format 2 dates as range date
 *
 * @example
 *  07.02-12.02
 *  07-12.02.2022
 *  7 Feb 2022-12 Apr 2023
 *
 * @param {{begin:string|Date,end:string|Date}} duration object with begin and end date
 *                                              in ECMA date format
 * @param {object} [options={month: '2-digit', day: '2-digit'}] optional props for
 *                 Intl.DateTimeFormat,
 * @param {string} [locales=pl] locales in BCP 47 language tag for Intl.DateTimeFormat
 * @returns {string} formatted data
 */
export const formatDateRange = (duration, options = {month: '2-digit', day: '2-digit'}, locales = 'pl') => {
  const {begin, end} = R.map(x => (R.is(Date, x) ? x : new Date(x || '')), duration);

  //TODO: this checking is very slow, maybe change to state machine or binary logic?
  if (begin.toString() === 'Invalid Date' && end.toString() === 'Invalid Date')
    return null;

  if (begin.toString() === 'Invalid Date' && end.toString() !== 'Invalid Date')
    return `~${PL_DATE_SEPARATOR_SYMBOL}${formatSingleDate(end, options, locales)}`;

  if (begin.toString() !== 'Invalid Date' && end.toString() === 'Invalid Date')
    return `${formatSingleDate(begin, options, locales)}${PL_DATE_SEPARATOR_SYMBOL}~`;

  const dateTimeFormat = new Intl.DateTimeFormat(locales, {...options});

  try {
    return dateTimeFormat.formatRange?.(begin, end);
  } catch (e) {
    if (e instanceof RangeError) {
      console.error('formatDateRange: ', e);
    } else {
      console.error(e);
    }
    return null;
  }
};

export default formatSingleDate;
