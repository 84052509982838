export default {
  tiny: {
    fontSize: 10,
  },

  'tiny-small': {
    fontSize: 11,
  },

  small: {
    fontSize: 13,
  },

  'medium-small': {
    fontSize: 14,
  },

  medium: {
    fontSize: 16,
  },

  big: {
    fontSize: 20,
  },
};
