import React from 'react';

import {LEAFLET_CARD_SCHEMA} from '@ding/constants/src/typeSchema';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import createComponentsGrid from '@ding/core/src/components/Utils/createComponentsGrid';

import Text from '@ding/core/src/components/Predefined/Text';
import LoadingSpinner from '@ding/core/src/components/Parts/LoadingSpinner';
import EmptyTitlePlaceholder from '@ding/core/src/components/Parts/EmptyTitlePlaceholder';
import LeafletCard from '../LeafletCard';
import LeafletCardSkeleton from '../LeafletCard/LeafletCardSkeleton';

export const NoLeaflets = provideProps(
  {
    translatePath: 'website.placeholders.empty_leaflets',
  },
)(EmptyTitlePlaceholder);

export const LoadingLeafletsSpinner = (props) => {
  const t = useTranslate();

  return (
    <LoadingSpinner
      title={(
        <Text.Muted>
          {t('website.placeholders.loading_leaflets')}
        </Text.Muted>
      )}
      style={props.style || {
        minHeight: 200,
      }}
      {...props}
    />
  );
};

export default createComponentsGrid(
  {
    itemSchema: LEAFLET_CARD_SCHEMA,
    itemComponent: LeafletCard,
    skeletonComponent: LeafletCardSkeleton,
    emptyPlaceholderComponent: NoLeaflets,
    defaultGrid: {
      xs: 6,
      md: 4,
      lg: 3,
    },
  },
);
