import {PRIMARY_FILL_SHADOW} from '@ding/constants/src/colorSchema';

export const zDepthTransitionBasic = {
  cursor: 'pointer',
  transition: '100ms ease-in-out',
  transitionProperty: 'box-shadow, transform',
};

export const floatingLayerZDepth = {
  boxShadow: '0px 4px 26px -2px rgba(0,0,0,0.20)',
};

export const popupZDepth = {
  boxShadow: '0px 0px 23px -2px rgba(0,0,0,0.27)',
};

export const extendedDropdownZDepth = {
  boxShadow: '0px 6px 26px -2px rgba(0,0,0,0.27)',
};

export const floatingButtonShadow = {
  boxShadow: '0px 5px 5px rgba(0,0,0,0.20)',
};

export const textShadow = {
  filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 1))',
};

export const createHoverableZDepth = (color, scale = 1.01, size = 23) => ({
  extend: zDepthTransitionBasic,

  '&:hover': {
    boxShadow: `0px 0px ${size}px -2px ${color}`,
    transform: `scale(${scale}, ${scale})`,
  },
});

export const primaryZDepth = createHoverableZDepth(PRIMARY_FILL_SHADOW, 1.04, 23);

export const cardZDepth = createHoverableZDepth('rgba(0,0,0,0.16)', 1.01, 32);

export default createHoverableZDepth('rgba(0,0,0,0.27)');
