import PropTypes from 'prop-types';
import styled from '@adretail/fast-stylesheet/src/react/styled';

const HORIZONTAL_PADDING = 10;

const PageContainer = styled.div(
  {
    base: {
      height: 'inherit',
      width: '100%',

      margin: '0 auto',
    },

    'padding-small': {padding: `0 ${HORIZONTAL_PADDING}px`},
    'padding-medium': {padding: `0 ${HORIZONTAL_PADDING * 2}px`},

    'top-padding-small': {paddingTop: `${HORIZONTAL_PADDING}px`},
    'top-padding-medium': {paddingTop: `${HORIZONTAL_PADDING * 2}px`},

    noPadding: {
      padding: 0,
    },

    noMargin: {
      margin: 0,
    },

    viewportExpanded: {
      width: 1300 - (HORIZONTAL_PADDING * 2),
      maxWidth: '100%',
    },
  },
  {
    index: 3,
    omitProps: ['padding', 'topPadding', 'noPadding', 'noMargin', 'expandToViewport'],
    classSelector: (classes, {padding, topPadding, noPadding, noMargin, expandToViewport}) => [
      !noPadding && classes[`padding-${padding || 'small'}`],
      topPadding && classes[`top-padding-${topPadding || 'small'}`],
      noPadding && classes.noPadding,
      noMargin && classes.noMargin,
      expandToViewport && classes.viewportExpanded,
    ],
  },
);

PageContainer.displayName = 'PageContainer';

PageContainer.propTypes = {
  noPadding: PropTypes.bool,
  expandToViewport: PropTypes.bool,
};

PageContainer.defaultProps = {
  expandToViewport: true,
};

export default PageContainer;
