import * as R from 'ramda';

import {languages} from '@ding/i18n/src/languages';
import {createLinkComponent} from '../../utils';

export const HOME_URL_SCHEMA = languages.pl.prefix;

const HomeLink = createLinkComponent(
  null,
  R.always(HOME_URL_SCHEMA),
);

HomeLink.displayName = 'HomeLink';

export default HomeLink;
