import React from 'react';
import {Helmet} from 'react-helmet';

import defaultPostThumbnailUrl from '@ding/server/resources/default-post-image.jpg';

import useReactRouter from '@ding/core/src/hooks/useReactRouter';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {prefixWithRootAddress} from '@ding/layout/src/components/Links/utils';
import AlternateLinksUpdate from './AlternateLinksUpdate';

const RootLayoutMetaTags = () => {
  const t = useTranslate('website.meta.defaults');
  const {location: {pathname}} = useReactRouter();
  const rootUrl = prefixWithRootAddress(pathname);

  return (
    <>
      <AlternateLinksUpdate key={pathname} />
      <Helmet>
        <meta
          key='viewport-meta'
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=5.0, viewport-fit=cover'
        />
        <link rel='canonical' href={rootUrl} />
        <meta property='og:type' content='website' />
        <meta property='og:description' content={t('og.description')} />
        <meta property='og:title' content={t('og.title')} />
        <meta property='og:url' content={rootUrl} />
        <meta property='og:image' content={defaultPostThumbnailUrl} />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
      </Helmet>
    </>
  );
};

RootLayoutMetaTags.displayName = 'RootLayoutMetaTags';

export default React.memo(RootLayoutMetaTags);
