import React from 'react';

/**
 * AD Holder placeholder in svg format
 * NOTE: this should not be svg in the end because this component will be remove after loading so it expensive
 * @ding/server/resources/ad_placeholder.svg
 */
const AdPlaceholderVector = () => (
  <svg xmlns='http://www.w3.org/2000/svg' data-name='ad_placeholder' viewBox='0 0 84 59' height={90}>
    <path d='M17.983 0v9.551C12.732 6.042 5.631 7.454 2.122 12.705-1.386 17.956.026 25.057 5.277 28.566a11.435 11.435 0 0 0 12.707 0v1.927h5.083V0h-5.083Zm-6.352 25.41a6.352 6.352 0 1 1 0-12.704 6.352 6.352 0 0 1 0 12.704M26.624 7.609h5.083v22.884h-5.083V7.609Zm52.099.014v1.931c-5.251-3.509-12.352-2.097-15.861 3.154s-2.097 12.352 3.154 15.861a11.435 11.435 0 0 0 12.707 0 6.352 6.352 0 0 1-6.352 6.352v5.083c6.315-.001 11.433-5.12 11.434-11.435V7.623h-5.082ZM72.371 25.41a6.352 6.352 0 1 1 0-12.704 6.352 6.352 0 0 1 0 12.704ZM46.698 36.59a4.571 4.571 0 0 0 4.576-4.559H42.13a4.572 4.572 0 0 0 4.57 4.559m11.433-17.532c-.059-6.315-5.227-11.387-11.542-11.327-6.232.058-11.269 5.096-11.327 11.327v11.434h.952v.015h4.123V19.058a6.352 6.352 0 0 1 12.7 0v11.449h5.083V19.058h.011Z' style={{fill: '#e5e5e5'}} />
    <path d='m13.978 56-1.661-2.365a6.823 6.823 0 0 1-.484.021H9.93V56h-1.1v-7.701h3.003c2.002 0 3.212 1.012 3.212 2.685 0 1.188-.616 2.046-1.694 2.432l1.826 2.585h-1.199Zm-.033-5.017c0-1.101-.737-1.728-2.146-1.728h-1.87v3.466h1.87c1.408 0 2.146-.639 2.146-1.738Zm10.67 4.06V56h-5.589v-7.701h5.435v.957h-4.334v2.365h3.861v.936h-3.861v2.486h4.489Zm6.49-2.465-1.408 1.431V56h-1.1v-7.701h1.1v4.302l4.181-4.302h1.254l-3.289 3.466L35.341 56h-1.287l-2.948-3.422Zm7.601-4.279h1.1v6.744h4.17V56h-5.27v-7.701Zm13.673 5.776h-4.093L47.438 56h-1.133l3.488-7.701h1.088L54.38 56h-1.155l-.847-1.925Zm-.386-.881-1.66-3.773-1.662 3.773h3.322ZM64.864 56l-.012-5.61-2.783 4.675h-.506l-2.783-4.643V56h-1.057v-7.701h.902l3.213 5.413 3.168-5.413h.902L65.92 56h-1.057Zm10.472-1.925h-4.093L70.395 56h-1.133l3.488-7.701h1.088L77.337 56h-1.155l-.847-1.925Zm-.386-.881-1.66-3.773-1.662 3.773h3.322Z' style={{fill: '#d5d5d5', isolation: 'isolate'}} />
  </svg>
);

export default AdPlaceholderVector;
