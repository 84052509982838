import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route} from 'react-router-dom';
import * as R from 'ramda';

import {
  REACT_COMPONENT_CLASS_SCHEMA,
  PROPS_SCHEMA,
} from '@adretail/schemas';

import applyIfFunction from '@adretail/basic-helpers/src/base/applyIfFunction';

import {LoadingSpinner} from '@ding/core/src/components/Parts';
import PaginationBar from './Pagination/PaginationBar';
import {safeReplacePage} from './Pagination/utils/defaultLinkBuilder';

import QueryChunksList from './ExpandableFunctionalChunksList/QueryChunksList';
import {viewportIntersectionToolbarFn} from './ExpandableFunctionalChunksList/toolbars/ViewportIntersectionToolbar';

const defaultPage = R.defaultTo(1);
const pageInRange = (current, total) => !(current < 1 || current > R.max(1, total));

/** placeholder value for first chunk placeholder */
const initChunkVariable = {
  visibleChunksCount: 1,
  chunkIndex: 0,
  prevChunk: null,
  info: {index: 0, totalChunks: undefined, remainItems: undefined},
};

const PaginatedGQLGrid = ({
  variables,
  wrapWithRoute,
  withPaginationBar,
  lazyLoadOnScroll,
  gridComponent: GridComponent,
  queryComponent: QueryComponent,
  lazyChunkListComponent: LazyChunkListComponent,
  jsonSchemaComponent: JsonSchemaComponent,
  lazyChunkListProps,

  loadingComponent,
  /** show skeletonLoading instead of classic spinner, if skeleton loading show empty list check if limit in query pros is set, this don't work on search page */
  skeletonLoading,

  gridProps,
  chunkGridProps,
  queryProps,
  rememberScrollPosition,
}) => {
  const skeletonChunk = () => (
    <>
      <GridComponent
        // BUG: i don't know how many items in page can be so i render always request limit
        // TODO: BUG: this will cause cls if skeleton count is bigger then actual content
        placeholder={variables.limit}
        {...gridProps}
        // NOTE: because first in loading i don't have access to chunk mockup data for first chunk
        // NOTE: BUG: DO NOT USE THIS ON SEARCH PAGE!!! This still brake grid in search page because i don't have all the data.
        {...chunkGridProps && applyIfFunction([[], initChunkVariable], chunkGridProps)}
      />
      {/* TODO: add placeholder rof paginationBar */}
      <div style={{height: 50, marginTop: 32}} />
    </>
  );

  const renderContent = (activePage, location) => {
    const gridVariables = {
      page: activePage,
      ...variables,
    };

    const renderChunk = ({items: list, totalPages}, chunkVariables) => (
      <GridComponent
        {...{
          list,
          totalPages,
        }}
        {...gridProps}
        {...chunkGridProps && applyIfFunction([list, chunkVariables], chunkGridProps)}
      />
    );

    if (lazyLoadOnScroll && (!R.has('page', variables) || variables.page === 1)) {
      const insertRenderFn = !withPaginationBar ? null : (
        ({chunkIndex, visibleChunksCount, prevChunk: {totalChunks}}) => (
          chunkIndex + 1 === visibleChunksCount && totalChunks > 0 && (
            <PaginationBar
              disabled
              activePage={
                Math.max(
                  activePage,
                  chunkIndex + 1,
                )
              }
              totalPages={totalChunks}
            />
          )
        )
      );

      return (
        <LazyChunkListComponent
          asyncChunkResolverComponent={QueryComponent}
          listRenderFn={renderChunk}
          toolbarRenderFn={viewportIntersectionToolbarFn}
          insertRenderFn={insertRenderFn}
          variables={gridVariables}
          rememberScrollPosition={rememberScrollPosition}
          allowIdleLoad
          {...loadingComponent && {
            loadingComponent: skeletonLoading ? skeletonChunk : loadingComponent,
          }}
          {...lazyChunkListProps}
        />
      );
    }

    return (
      <QueryComponent
        loadingComponent={skeletonLoading ? skeletonChunk : loadingComponent}
        // NOTE: because of page redirect i can not use `alwaysRenderComponent` or `optimisticResponse` to get loading state and render grid with skeleton loading or dummy data before request finalize. Redirect to proper page after request has finish, create weird ux. On slow network out of bound page will show with static content, skeleton and buttons, and after couple seconds redirect happened and re render whole page with new request, I think this is very confusing and is worst experience for user then have more skeleton cards then actual cards.
        {...queryProps}

        variables={gridVariables}
      >
        {(data) => {
          if (wrapWithRoute && !pageInRange(activePage, data.totalPages))
            return <Redirect to={safeReplacePage((data.totalPages || 1))(location.pathname)} />;

          return (
            <>
              {renderChunk(data)}
              {JsonSchemaComponent && <JsonSchemaComponent data={data.items} />}
              {withPaginationBar && (
                <PaginationBar
                  activePage={activePage}
                  totalPages={data.totalPages}
                />
              )}
            </>
          );
        }}
      </QueryComponent>
    );
  };

  if (wrapWithRoute) {
    return (
      <Route
        render={
          ({match: {params}, location}) => renderContent(defaultPage(+params.page), location)
        }
      />
    );
  }

  return renderContent(
    defaultPage(+variables?.page),
  );
};

PaginatedGQLGrid.displayName = 'PaginatedGQLGrid';

PaginatedGQLGrid.propTypes = {
  rememberScrollPosition: PropTypes.bool,

  variables: PropTypes.any,
  loadingComponent: PropTypes.func,
  wrapWithRoute: PropTypes.bool,
  lazyLoadOnScroll: PropTypes.bool,
  withPaginationBar: PropTypes.bool,

  gridProps: PROPS_SCHEMA,
  chunkGridProps: PropTypes.any,
  queryProps: PROPS_SCHEMA,
  lazyChunkListProps: PROPS_SCHEMA,

  gridComponent: REACT_COMPONENT_CLASS_SCHEMA.isRequired,
  queryComponent: REACT_COMPONENT_CLASS_SCHEMA.isRequired,
  lazyChunkListComponent: REACT_COMPONENT_CLASS_SCHEMA,
  jsonSchemaComponent: REACT_COMPONENT_CLASS_SCHEMA,
};

PaginatedGQLGrid.defaultProps = {
  loadingComponent: () => <LoadingSpinner style={{height: 200}} />,
  lazyChunkListComponent: QueryChunksList,
  lazyLoadOnScroll: false,
  skeletonLoading: true,
  withPaginationBar: true,
  variables: {
    page: 1,
  },
};

export default PaginatedGQLGrid;
