export default {
  change_language_popup: {
    title: 'Відкрийте для себе Ding українською',
    question: 'Ми помітили, що мова вашого браузера українська. Перейти на українську версію сайту?',
    button: 'ПЕРЕЙТИ',
    link: 'Залишитися на польській версії сайту',
  },

  change_language_popup_with_checkbox: {
    title: 'Język serwisu',
    question: 'Wybierz język serwisu:',
    button: 'Zapisz',
  },

  languages: {
    pl: 'Polski',
    uk: 'Ukraiński',
  },

  current_language: 'Język serwisu: %{}',
};
