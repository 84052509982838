import PropTypes from 'prop-types';

export const NOTIFICATIONS_FREQUENCY_SCHEMA = PropTypes.shape(
  {
    never: PropTypes.bool,
    collectively: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  },
);

export const NOTIFICATIONS_TRIGGERS_SCHEMA = PropTypes.shape(
  {
    newContractorLeaflet: PropTypes.bool,
    contractorLeafletExpires: PropTypes.number,
    favouriteNewProduct: PropTypes.bool,
    favouriteProductExpires: PropTypes.number,
    holiday: PropTypes.number,
  },
);

export const ACCOUNT_NOTIFICATIONS_SCHEMA = PropTypes.shape(
  {
    frequency: NOTIFICATIONS_FREQUENCY_SCHEMA,
    triggers: NOTIFICATIONS_TRIGGERS_SCHEMA,
  },
);

export const NOTIFICATIONS_SETTINGS_FETCHERS_SCHEMA = PropTypes.shape(
  {
    accountSettings: ACCOUNT_NOTIFICATIONS_SCHEMA,
    changeSettings: PropTypes.func.isRequired,
  },
);
