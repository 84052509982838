import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import {
  Text,
  Margin,
} from '@ding/core/src/components/Predefined';

import SelectButton from '.';

const isBlankPhrase = R.compose(
  R.isEmpty,
  R.when(
    R.is(String),
    R.trim,
  ),
);

const SelectButtonTitle = React.memo(
  ({ua, title, phrase}) => {
    const blankPhrase = !phrase || isBlankPhrase(phrase);

    if (ua.mobile) {
      return (
        <Text truncate>
          {title && (
            <Text
              size={
                blankPhrase
                  ? 'small'
                  : 'tiny'
              }
              block
            >
              {title}
            </Text>
          )}

          {!blankPhrase && (
            <Margin
              top={title ? 1 : 0}
              block
            >
              {phrase}
            </Margin>
          )}
        </Text>
      );
    }

    const phraseElement = (
      <span>
        {phrase}
      </span>
    );

    return (
      <>
        {title}
        {!blankPhrase && (
          title
            ? (
              <Margin left={3}>
                {phraseElement}
              </Margin>
            )
            : phraseElement
        )}
      </>
    );
  },
);

const SortSelectButton = provideProps(
  ({title, inputProps}) => ({
    inputProps: {
      ...inputProps,
      radius: 'small',
    },
    title: (phrase, ua) => (
      <SelectButtonTitle
        {...{
          ua,
          title,
          phrase,
        }}
      />
    ),
  }),
  true,
)(SelectButton);

SortSelectButton.displayName = 'SortSelectButton';

SortSelectButton.propTypes = {
  title: PropTypes.any,
};

export default SortSelectButton;
