import {NOTIFICATIONS_FREQUENCY} from '@ding/account-settings/src/constants';

const formValueToSettings = ({triggers, frequency}) => ({
  // due to backend .merge() issues force pass all triggerers
  favouriteProductExpires: null,
  notificationDelivery: null,
  emailDelivery: null,
  holiday: null,
  ...triggers,

  // frequency
  ...frequency?.never && {
    notificationsInterval: NOTIFICATIONS_FREQUENCY.NEVER,
    emailDelivery: null,
  },

  ...frequency?.emailDelivery && {
    notificationsInterval: NOTIFICATIONS_FREQUENCY.DAILY,
    emailDelivery: frequency?.emailDelivery,
  },
});

export default formValueToSettings;
