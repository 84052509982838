import PropTypes from 'prop-types';
import * as R from 'ramda';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import Text from './Text';

const Label = styled(
  Text,
  {
    base: {
      display: 'inline-block',
      padding: 0,
    },

    weighted: {
      fontWeight: 600,
    },

    row: {
      margin: [10, 0],
    },
  },
  {
    tagName: 'label',
    mergeProps: ({size}) => ({
      size: size || 'medium-small',
    }),

    omitProps: ['row'],
    classSelector: (classes, {row, weight}) => [
      row && classes.row,
      R.isNil(weight) && classes.weighted,
    ],
  },
);

Label.displayName = 'Label';

Label.propTypes = {
  row: PropTypes.bool,
};

export default Label;
