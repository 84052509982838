import styled from '@adretail/fast-stylesheet/src/react/styled';
import Button from '.';

const RectangleButton = styled(
  Button,
  {
    base: {},
    expanded: {
      minWidth: 100,
    },
  },
  {
    omitProps: ['shrinked', 'eventData'],
    classSelector: (classes, {shrinked}) => !shrinked && classes.expanded,
  },
);

RectangleButton.displayName = 'RectangleButton';

export default RectangleButton;
