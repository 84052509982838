import * as R from 'ramda';
import * as MODULE_TYPES from '@ding/slide-viewer/src/Viewer/constants/moduleTypes';

const iwaTracking = {
  events: {
    selectedForUserMobileSliderClick: ['trackEvent', 'bloczek_wybrane_dla_ciebie', '', 'klik', '%{leafletId}', '%{categoryId}', '%{contractorId}'],
    selectedForUserMobileSliderShow: ['trackEvent', 'bloczek_wybrane_dla_ciebie', '', 'view'],

    autopromotionClickLeft: ['trackEvent', 'strzalka_lewo', '', 'klik'],
    autopromotionClickRight: ['trackEvent', 'strzalka_prawo', '', 'klik'],
    autopromotionClickDot: ['trackEvent', 'przeklik_kropka', '', 'klik'],

    bottomMenuClick: ['trackEvent', 'pasek_dol', '%{name}', 'klik'],

    viewLeaflet: ['trackEvent', '%{id}', '%{categoryId}', 'RPV', '%{pageNumber}', '%{contractorId}'],
    viewPageLeaflet: ['trackEvent', '%{id}', '%{categoryId}', 'PPV', '%{pageNumber}', '%{contractorId}'],
    clickMoreLeafletsInSidebar: ['trackEvent', 'wiecej', 'szpalta_gazetek', 'klik', '%{totalClicks}'],
    sidebarScrollDepth: ['trackEvent', 'scroll_blenda', '%{depth}'],

    embedViewerPresent: ['trackEvent', 'embed_display', '%{identifier}', 'on site'],
    embedViewerView: ['trackEvent', 'embed_display', '%{identifier}', 'view'],
    embedViewerClick: ['trackEvent', 'embed_display', '%{identifier}', 'klik', 'pierwsza_akcja'],

    // leaflet-viewer dropDown menu
    viewerMenuToggleNotifications: ['trackEvent', 'viewer', 'ulubiona_siec', '%{active}', '%{contractorId}', 'rozwijane_menu'],
    viewerMenuItemClick: ['trackEvent', 'viewer', '%{eventKey}', 'klik', '%{leafletId}', '%{categoryId}', '%{contractorId}'],

    //leaflet last page
    goToNextLeaflet: ['trackEvent', 'viewer', 'nastepna_gazetka', 'klik', '%{id}', '%{categoryId}', '%{contractorId}'],
    stayOnCurrentLeaflet: ['trackEvent', 'viewer', 'pozostan_na_gazetce', 'klik', '%{id}', '%{categoryId}', '%{contractorId}'],
    clickLeafletInSlider: ['trackEvent', 'strona_zaostatnia', 'wiecej_gazetek_slajder', 'klik', '%{leafletId}', '%{categoryId}', '%{contractorId}'],

    articleClickFollowUsOnButton: ['trackEvent', 'blog_obserwuj_zewnetrzne', 'klik', '%{fromPage}'],

    viewTagRecipes: ['trackEvent', 'Polecane_przepisy', '%{tag}', 'view'],
    clickTagRecipe: ['trackEvent', 'Polecane_przepisy', '%{tag}', 'klik', '%{name}'],

    recommendedLeafletsSectionClick: ['trackEvent', 'sg - wybrane dla Ciebie', '%{contractorName}', 'klik', '%{leafletId}', '%{position}'],

    recommendedLeafletsSectionShow: iwa => ({contractorsNames, leafletsIds, positions}) => {
      iwa(
        'trackEvent',
        'sg - wybrane dla Ciebie',
        contractorsNames,
        'load',
        leafletsIds,
        positions,
      );
    },

    //#region leaflet viewer spider
    // leaflet viewer link module and spider redirect link
    clickLeafletModule: iwa => (item) => {
      const cv = item.kind === MODULE_TYPES.PRODUCT
        ? {modul: 'modul_spider', produkt: item.productName}
        : {modul: item.label ? 'extra' : 'modul_spider', typ: item.label || 'spider'};

      iwa(
        'trackEvent',
        item.leafletId,
        item.categoryId,
        'klik_modul',
        item.pageNumber,
        item.id,
        item.contractorId,
        {
          ...cv,
        },
      );
    },

    // leaflet viewer spider product link
    clickLeafletProduct: iwa => (item) => {
      iwa(
        'trackEvent',
        item.leafletId,
        item.productCategoryId,
        'klik_produkt',
        item.pageNumber,
        item.id,
        item.contractorId,
        {
          produkt: item.name,
          ...!R.isNil(item.url) && {
            modul: 'przeklikowo_ofertowy',
          },
        },
      );
    },

    // leaflet viewer active spider with module info
    activeLeafletSpiderOnModule: iwa => (item) => {
      iwa(
        'trackEvent',
        'Ding',
        item.leafletId,
        'active_spider',
        item.pageNumber,
        item.categoryId,
        item.id,
        {
          modul: item.modul,
          cdd_contractor_id: item.contractorId,
          produkt: item.productName,
        },
      );
    },

    // leaflet viewer close active spider using button
    closeSpiderButton: iwa => (item) => {
      iwa(
        'trackEvent',
        item.leafletId,
        item.categoryId,
        'klik_x',
        item.pageNumber,
        item.id,
        item.contractorId,
      );
    },
    //#endregion

    clickProductCardContractor: iwa => (item) => {
      iwa(
        'trackEvent',
        'zobacz_siec',
        item.superoffer ? 'oferta_specjalna' : 'oferta',
        'klik',
        '-',
        item.contractorId,
        item.productId,
        {
          produkt: item.productName,
        },
      );
    },

    // add product module in insert to shopping list
    clickInsertProductModule: iwa => (item) => {
      iwa(
        'trackEvent',
        'insert',
        item.moduleId,
        'dodanie_do_listy',
        item.insertId,
        item.contractorId,
        item.moduleName,
      );
    },

    triggerShoppingList: ['trackEvent', 'header', 'lista_zakupowa', 'klik'],
    closeShoppingList: ['trackEvent', 'header', 'lista_zakupowa', 'klik_X'],

    // shopping list
    ...(() => {
      const createShoppingListAction = (action, place = 'lista_zakupowa') => iwa => (item) => {
        iwa('trackEvent', place, item.productId ?? '-', action, item.leafletId ?? '-', item.categoryId ?? '-', item.contractorId ?? '-', {produkt: item.name});
      };

      const genEditListItemVariables = item => ({
        nazwa: item.name,
        ilosc: item.quantity,
        cena: item.price,
      });

      return {
        appendShoppingListViewerItem: createShoppingListAction('dodanie_do_listy', 'modul_gazetki'),
        deleteShoppingListViewerItem: createShoppingListAction('usuniecie_z_listy', 'modul_gazetki'),
        cancelShoppingListViewerAppendItem: ['trackEvent', 'modul_gazetki', '-', 'dodanie_do_listy_anuluj', '-', '-', '%{contractorId}'],
        appendShoppingListViewerCutItem: iwa => (item) => {
          iwa(
            'trackEvent', 'modul_gazetki', '-', 'dodanie_do_listy', '-', '-', item.contractorId,
            {
              ...genEditListItemVariables(item),
              typ: 'wyciete',
            },
          );
        },

        deleteShoppingListBarItem: createShoppingListAction('usuniecie_z_listy'),
        appendShoppingListBarItem: createShoppingListAction('dopisane_do_listy'),
        checkShoppingListBarItem: createShoppingListAction('kupione'),
        openShoppingListTagMatch: iwa => (productName) => {
          iwa('trackEvent', 'lista_zakupowa', '-', 'otwarcie_propozycji_dopasowan', '-', '-', '-', {produkt: productName});
        },
        matchShoppingListProduct: iwa => (item) => {
          iwa('trackEvent', 'lista_zakupowa', item.id, 'dopasowanie_produktu', item.leafletId, item.productCategoryId, item.contractorId, {produkt: item.name});
        },
        openShoppingListEdit: iwa => (item) => {
          iwa(
            'trackEvent', 'lista_zakupowa', item.productId, 'edycja_otworz', item.leafletId, item.productCategoryId, item.contractorId,
            genEditListItemVariables(item),
          );
        },
        saveShoppingListEdit: iwa => (item) => {
          iwa(
            'trackEvent', 'lista_zakupowa', item.productId, 'edycja_zapisz', item.leafletId, item.productCategoryId, item.contractorId,
            genEditListItemVariables(item),
          );
        },
        cancelShoppingListEdit: iwa => (item) => {
          iwa('trackEvent', 'lista_zakupowa', item.productId, 'edycja_anuluj', item.leafletId, item.productCategoryId, item.contractorId);
        },
        removeShoppingListEdit: iwa => (item) => {
          iwa('trackEvent', 'lista_zakupowa', item.productId, 'edycja_usun', item.leafletId, item.productCategoryId, item.contractorId);
        },
        reorderShoppingListContractor: iwa => (contractorId, up) => {
          iwa('trackEvent', 'lista_zakupowa', '-', 'zmiana_kolejnosci_sieci', '-', up ? 'do_gory' : 'w_dol', contractorId);
        },
        reorderShoppingListItem: iwa => (up) => {
          iwa('trackEvent', 'lista_zakupowa', '-', 'zmiana_kolejnosci_produktu', '-', up ? 'do_gory' : 'w_dol', '-');
        },
      };
    })(),

    redirectShoppingListBarItem: iwa => ({superoffer, leafletId, contractorId, productId}) => (
      iwa(
        'trackEvent',
        'zobacz_produkt',
        superoffer ? 'oferta_specjalna' : 'oferta',
        'klik_lista_zakupowa',
        leafletId,
        contractorId,
        productId,
      )
    ),

    clickShoppingListBarLogin: ['trackEvent', 'lista_zakupowa', '-', 'klik', 'zaloguj_sie'],
    clickShoppingListBarRegister: ['trackEvent', 'lista_zakupowa', '-', 'klik', 'zaloz_konto'],

    triggerShareShoppingList: ['trackEvent', 'lista_zakupowa', '-', 'klik', 'przeslij_liste_mailem'],
    sendShareShoppingList: ['trackEvent', 'lista_zakupowa', '-', 'wyslij', 'przeslij_liste_mailem'],
    closeShareShoppingList: ['trackEvent', 'lista_zakupowa', '-', 'klik_X', 'przeslij_liste_mailem'],

    productNotificationsListClick: iwa => (item) => {
      iwa('trackEvent', 'alert', item.id, 'klik_OFERTA', item.leafletId, item.categoryId, item.contractorId, {produkt: item.name});
    },

    triggerNotificationsList: ['trackEvent', 'header', 'alert', 'klik'],
    clickSelectedForUserCard: ['trackEvent', 'bloczek_wybrane_dla_ciebie', '-', 'klik', '%{leafletId}', '%{categoryId}', '%{contractorId}'],

    // standalone cards
    appendShoppingListProduct: iwa => (item) => {
      iwa(
        'trackEvent',
        'lista_zakupowa',
        item.id,
        item.added ? 'dopisanie_do_listy' : 'usuniecie_z_listy',
        item.leafletId,
        item.categoryId,
        item.contractorId,
        {
          produkt: item.name,
        },
      );
    },

    // todo add leaflet card
    openProductCard: iwa => ({cover, ...item}) => {
      iwa(
        'trackEvent',
        'zobacz_produkt',
        item.superoffer ? 'oferta_specjalna' : 'oferta',
        'klik',
        item.leafletId,
        item.contractorId,
        item.id,
        {
          kategoria_produktu: item.categoryId,
          produkt: item.name,
          view: cover ? 'blenda_oferty' : 'strona_oferty',
        },
      );
    },

    seeProductLeaflet: iwa => (item) => {
      iwa(
        'trackEvent',
        'zobacz_produkt',
        item.superoffer ? 'oferta_specjalna' : 'oferta',
        'klik_oferta',
        item.leafletId,
        item.contractorId,
        item.id,
        {
          produkt: item.name,
        },
      );
    },

    toggleTagNotifications: iwa => ({toggled, name}) => {
      iwa(
        'trackEvent',
        'sweep',
        'alert_obserwowany_produkt',
        toggled ? 'on' : 'off',
        name,
      );
    },

    toggleContractorNotifications: iwa => ({origin, toggled, contractorId}) => {
      iwa(
        'trackEvent',
        origin || 'sweep',
        'ulubiona_siec',
        toggled ? 'on' : 'off',
        contractorId,
      );
    },
  },
};

export default iwaTracking;
