import React from 'react';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import {trackComponent} from '@ding/interia';

import LeafletsLink from '@ding/layout/src/components/Links/LeafletsLink';
import LeafletIcon from '@ding/core/src/icons/navbar/Leaflets';
import IconButton from '@ding/controls/src/Button/IconButton';
import {HeaderItem} from '../../HeaderToolbar';

const LeafletsIcon = ({subtitle, margin, onClick}) => (
  <li>
    <HeaderItem
      subtitle={subtitle.name}
      title={subtitle.title}
      aria-label={subtitle.title}
      margin={margin}
      component={IconButton}
      componentProps={{
        link: provideProps({injectOnHover: true, onClick})(LeafletsLink),
        icon: LeafletIcon,
        type: 'light',
        iconProps: {
          size: 'big',
        },
      }}
    />
  </li>
);

LeafletsIcon.displayName = 'LeafletsIcon';

export default trackComponent(
  {
    onClick({ga4}) {
      ga4.menuClickLeaflets();
    },
  },
)(LeafletsIcon);
