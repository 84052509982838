import padFormatDate from './padFormatDate';

const getMonthsDiff = (
  dateString1,
  dateString2 = padFormatDate(new Date()),
) => {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  const monthsDiff = Math.abs(
    date1.getMonth() - date2.getMonth(),
  );
  const yearsDiff = Math.abs(
    date1.getFullYear() - date2.getFullYear(),
  );

  return monthsDiff + 12 * yearsDiff;
};

export default getMonthsDiff;
