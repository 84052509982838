import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'ArrowLeft',
  {
    viewBox: '0 0 9 16',
  },
  <path d='M8.68,15.67a1.13,1.13,0,0,0,0-1.58L2.67,8l6-6.09a1.13,1.13,0,0,0,0-1.58,1.1,1.1,0,0,0-1.57,0L.32,7.21A1.14,1.14,0,0,0,0,8a1.11,1.11,0,0,0,.33.79l6.78,6.88A1.1,1.1,0,0,0,8.68,15.67Z' />,
);
