import React from 'react';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import flexCenter from '@ding/core/src/styles/flexCenter';

import {URLModalTrigger} from '@ding/controls/src/ResponsiveModalToggle';
import ThreeDotsIcon from '@ding/core/src/icons/bottom-bar/ThreeDots';
import BottomBarButton from '../BottomBarButton';
import MenuModal from '../../MenuModal';

const HamburgerSmallerIcon = styled(
  ThreeDotsIcon,
  {
    extend: flexCenter,

    '& svg': {
      position: 'relative',
      top: '1px',
      transform: 'scale(1.05)',
    },
  },
);

const MenuItem = ({subtitle, onClick}) => (
  <URLModalTrigger
    provideToggleStateProps
    uuid='menu'
    modalComponent={MenuModal}
    triggerRenderFn={({toggled, onToggle}) => (
      <BottomBarButton
        active={toggled}
        subtitle={subtitle.name}
        aria-label={subtitle.title}
        title={subtitle.title}
        onClick={() => {
          onToggle();
          onClick?.();
        }}
      >
        <HamburgerSmallerIcon size='medium' />
      </BottomBarButton>
    )}
  />
);

MenuItem.displayName = 'MenuItem';

export default MenuItem;
