import * as R from 'ramda';

const createErrorMessage = (message, line = 0) => ({
  message,
  locations: [
    {line, column: 0},
  ],
});

const displayErrorsList = mapperFn => R.compose(
  R.map(
    ({message, locations}) => {
      const [{line, column}] = locations || [{line: 0, column: 0}];

      return (
        mapperFn(`GQL Error(Line: ${line}, Column: ${column}): ${message}`)
      );
    },
  ),
  R.addIndex(R.map)(
    (item, index) => (
      R.is(String, item)
        ? createErrorMessage(item, index)
        : item
    ),
  ),
);

export const errorsListToString = R.compose(
  R.join('\n'),
  displayErrorsList(R.identity),
);

export default displayErrorsList(::console.error);
