import * as R from 'ramda';
import Cookie from 'js-cookie';
import {generatePath} from 'react-router';

import {prefixWithRootAddress} from '@ding/layout/src/components/Links/utils';
import {parameterize} from '@ding/core/src/helpers';

export const PREFERED_LANGUAGE_COOKIE_NAME = 'ding_lang';
export const ALTERNALE_LINKS_PARAMS_QUERY_PREFIX = 'alternate-links-';
export const LANGUAGES = {
  PL: 'pl',
  UK: 'uk',
};

export const languages = {
  [LANGUAGES.UK]: {
    prefix: '/ua',
  },
  [LANGUAGES.PL]: {
    prefix: '/',
  },
};

/**
 * Search in object for fields, used to find fields in query result
 * when responseSelector isn't available (during SSR)
 *
 * @param {Object} object
 *
 * @returns Object with more than one field
 */
const deepSelectParams = (object) => {
  const keys = object && Object.keys(object);
  if (keys?.length === 1)
    return deepSelectParams(object[keys[0]]);
  return object;
};

/**
 *
 * Transform query results with fields in format [lang]_[urlParam]: <value> into params
 * in format [urlParam]: {[lang]: <value>}
 *
 * @param {Object} queryData query results
 *
 * @returns Object in format [urlParam]: {[lang]: <value>}
 */
export const queryFieldsIntoRouteParams = (queryData) => {
  const params = {};

  let selectedData = null;
  try {
    selectedData = deepSelectParams(queryData);
  } catch (e) {
    return params;
  }

  R.forEachObjIndexed((value, param) => {
    const [lang, paramName] = param.split('_');
    if (!params[paramName])
      params[paramName] = {};

    params[paramName][lang] = value;
  }, selectedData || {});

  return params;
};

/**
 *
 * @param {Object} route route object, one of returned from createChunksRoutes.
 * createChunksRoutes create information about all routes and that's used also by chunks
 * @param {Object} match matched result from react-router matchPath() function.
 * matchPath() contains information about current path used by react-router
 * @param {Object} translatedParams object in format {[field]: { [lang]: <value>}}
 * with params to replace in react-router match, used to pass translated params
 *
 * @returns Array with data for alternate links (hrefLang, href) for all possible languages
 */
export const createAlternateLinks = (route, match, translatedParams) => {
  if (!route?.i18nPathes) return [];

  const alternateLinks = [];
  const pathWithI18nVersions = route.i18nPathes.find(
    possiblePath => R.values(possiblePath).find(path => path === match.path),
  );

  if (!pathWithI18nVersions) return [];

  R.forEachObjIndexed((i18nPath, language) => {
    const newParams = {};
    R.forEachObjIndexed((value, param) => {
      if (value?.[language])
        newParams[param] = parameterize(value[language]);
    }, translatedParams || {});

    alternateLinks.push({
      hrefLang: `${language}-pl`,
      href: prefixWithRootAddress(generatePath(i18nPath, {
        ...match.params,
        ...newParams,
      })),
    });
  }, pathWithI18nVersions);

  return alternateLinks;
};

export const setPreferedLanguageCookie = (language) => {
  if (R.has(language)(languages))
    Cookie.set(PREFERED_LANGUAGE_COOKIE_NAME, language);
};

export const isDefaultLanguage = R.equals(LANGUAGES.PL);
export const prefixWithLanguage = (path, language = APP_LANGUAGE) => R.concat(
  (!isDefaultLanguage(language) ? languages[language].prefix : ''),
  path,
);

// NOTE: Hacky but atm best option to got ukrainan link for current page
export const findAlternateHrefInDOM = language => document
  .head
  .querySelector(`link[rel='alternate'][hrefLang='${language}-pl']:not([type])`)?.href;
