import {parameterize} from '@ding/core/src/helpers';
import {languages} from '@ding/i18n/src/languages';
import createLinkComponent from '../../utils/createLinkComponent';

export const ARTICLE_URL_SCHEMA = `${languages.uk.prefix}/blog/statti/:title,:id`;

export const genArticleURL = ({id, title, slug}) => (
  slug
    ? `${languages.uk.prefix}/blog/statti/${slug},${id}`
    : `${languages.uk.prefix}/blog/statti/${parameterize(title)},${id}`
);

export default createLinkComponent(
  'article',
  genArticleURL,
);
