import React from 'react';
import createIcon from '../utils/createIcon';

export default createIcon(
  'Offers',
  {
    viewBox: '0 0 24 24',
  },
  <>
    <path
      d='M5.285,18.054h0a.662.662,0,0,0,.657.667.721.721,0,0,0,.47-.2l12.114-12.1a.658.658,0,0,0,0-.931l0,0a.663.663,0,0,0-.474-.2.649.649,0,0,0-.465.192L5.479,17.584a.647.647,0,0,0-.194.469'
      fill='currentColor'
    />
    <path
      d='M8.332,4A3.109,3.109,0,1,0,11.44,7.11h0A3.113,3.113,0,0,0,8.332,4m0,4.891a1.782,1.782,0,1,1,1.781-1.782h0A1.785,1.785,0,0,1,8.332,8.891'
      fill='currentColor'
    />

    <path
      d='M15.669,13.782a3.109,3.109,0,1,0,3.109,3.109,3.11,3.11,0,0,0-3.109-3.109m0,4.891a1.782,1.782,0,1,1,1.781-1.782h0a1.783,1.783,0,0,1-1.781,1.782'
      fill='currentColor'
    />
  </>,
);
