import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';

import LoadingSpinner from '@ding/core/src/components/Parts/LoadingSpinner';
import NextGenPicture from './NextGenPicture';

/** @satisfies {{[x:string]: React.CSSProperties | {[x:string]: React.CSSProperties}}} */
const css = {
  '@keyframes image-pulse': {
    from: {
      opacity: 0.45,
    },
    to: {
      opacity: 0.15,
    },
  },

  base: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },

  spinner: {
    background: 'none',
  },

  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',

    opacity: 0.45,
    animation: 'image-pulse 1s ease infinite alternate',
  },
};

/**
 * Skeleton for image that preload given image and add loading spinner as overlay
 * @param {object} param
 * @param {string} param.src - image src
 * @param {React.ComponentProps<NextGenPicture>} param.imageProps props for NextGenPicture component
 * @returns expanded box with flashing image and loading spinner as overlay
 */
const ImageSkeleton = ({className, classes, src, imageProps, ...props}) => (
  <div className={c(className, classes.base)} {...props}>
    <LoadingSpinner className={classes.spinner} absolute />
    <NextGenPicture
      className={classes.image}
      src={src}
      alt='leaflet page preview image'
      preload
      {...imageProps}
    />
  </div>
);

ImageSkeleton.displayName = 'ImageSkeleton';

ImageSkeleton.propTypes = {
  src: PropTypes.string.isRequired,
};

export default injectClassesSheet(css)(ImageSkeleton);
