import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import mapObjValuesToPromise from '@adretail/basic-helpers/src/base/mapObjValuesToPromise';

import {CLIENT_SCHEMA} from '../constants/schema';

import {gqlQueryCacheKey, findQueryOperationName, hasSameQueryOperation} from '../helpers/gqlOperationCacheKey';
import TinyGqlContext from './TinyGqlContext';
import TinyGqlClient from '../client';

export {
  TinyGqlContext,
};

/**
 * @see
 *  Due to memory issues when already changed client
 *  requests refetch all queries `getClient` must be
 *  function
 *
 * @param {Object} visibleQueries
 * @param {Function} getClient
 */
export const createClientContext = (visibleQueries, getClient = () => {}) => {
  if (!visibleQueries)
    throw new Error('visibleQueries must be mutable object!');

  const context = {
    client: getClient(),
    visibleQueries,

    detachQuery(uuid, invokerUUID) {
      const cached = visibleQueries[uuid];

      if (R.is(Array, cached)) {
        visibleQueries[uuid] = R.reject(
          R.propEq('invokerUUID', invokerUUID),
          cached,
        );

        if (R.isEmpty(visibleQueries[uuid]))
          delete visibleQueries[uuid];
      }
    },

    attachQuery(uuid, description) {
      // prevent duplicate queries
      const cached = visibleQueries[uuid];
      if (cached)
        visibleQueries[uuid] = [...cached, description];
      else
        visibleQueries[uuid] = [description];
    },

    /**
     * Fetches again currently visible queries
     *
     * @param {Query|Query[]} query
     * @param {Object} variables
     * @param {Object} flags
     */
    async refetchQuery(query, variables, flags) {
      const refetchQueries = async (group) => {
        if (!group || !group.length)
          return null;

        const data = await group[0].refetchData(
          {
            ...flags,
            client: getClient(),
            cachePolicy: {
              read: false,
              write: true,
            },
          },
        );

        for (let i = 1; i < group.length; ++i)
          group[i].loadData(data);

        return data;
      };

      // refetches ALL queries
      // kills all cache (not all queries are visible, some are in cache)
      if (query === '*') {
        getClient().cacheStore?.clear(); // eslint-disable-line no-unused-expressions

        return mapObjValuesToPromise(
          R.identity,
          R.mapObjIndexed(
            refetchQueries,
            visibleQueries,
          ),
        );
      }

      // fetch array of queries
      if (R.is(Array, query)) {
        return Promise.all(
          R.map(
            context.refetchQuery,
            query,
          ),
        );
      }

      // match multiple uuids without providing variables
      if (variables === undefined) {
        const operationName = findQueryOperationName(query);
        const groups = R.compose(
          R.map(R.nth(1)),
          R.filter(
            ([key]) => hasSameQueryOperation(operationName, key),
          ),
          R.toPairs,
        )(visibleQueries);

        return Promise.all(
          R.map(
            refetchQueries,
            groups,
          ),
        );
      }

      const uuid = gqlQueryCacheKey(query, variables);
      return refetchQueries(visibleQueries[uuid]);
    },
  };

  return context;
};

/**
 * Provides gql client to all children
 *
 * @export
 */
export default class TinyGqlProvider extends React.Component {
  /**
   * Reads client from cache by URL, if not provided
   * creates new one using new instance of TinyGqlClient
   *
   * @param {TinyGQLConfig} clientConfig  Variables passed to constructor of TinyGqlClient
   * @returns {TinyGqlClient}
   */
  static getCachedClient(clientConfig) {
    const {uri} = clientConfig;
    const cache = TinyGqlProvider.cachedClients[uri];

    if (cache)
      return cache;

    const client = new TinyGqlClient(clientConfig);
    TinyGqlProvider.cachedClients[uri] = client;
    return client;
  }

  static propTypes = {
    client: CLIENT_SCHEMA,
    clientConfig: PropTypes.any,
  };

  static cachedClients = {};

  // do not ever try to copy this object!
  // reference must be preserved during
  visibleQueries = {};

  render() {
    const {visibleQueries} = this;
    const {
      client,
      clientConfig,
      children,
    } = this.props;

    const cachedClient = client || TinyGqlProvider.getCachedClient(clientConfig);

    if (!cachedClient)
      return children;

    return (
      <TinyGqlContext.Provider
        value={
          createClientContext(
            visibleQueries,
            () => this.props.client,
          )
        }
      >
        {children}
      </TinyGqlContext.Provider>
    );
  }
}
