import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';
import renderFunctionalChildren from '@adretail/basic-helpers/src/ui/renderFunctionalChildren';

const arrayPropWrapper = wrapperFn => (asyncHandlers, props) => R.reduce(
  (acc, key) => {
    if (props[key])
      acc[key] = wrapperFn(props[key]);

    return acc;
  },
  {},
  asyncHandlers,
);

/**
 * @todo
 * Move to basic-components and use it in asyncOperaitonSpinner
 */
export default class AsyncSpinnerControl extends React.Component {
  static propTypes = {
    controlComponent: REACT_COMPONENT_CLASS_SCHEMA.isRequired,
    spinnerComponent: REACT_COMPONENT_CLASS_SCHEMA,
    asyncHandlers: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    asyncHandlers: ['onClick'],
  };

  constructor(props) {
    super(props);

    this.wrapAsyncProps = arrayPropWrapper(this.wrapWithAsyncListener);
    this.mounted = true;
    this.state = {
      loading: false,
      asyncOperations: 0,
    };
  }

  static getDerivedStateFromProps(props) {
    if (!R.isNil(props.loading)) {
      return {
        loading: props.loading,
      };
    }

    return null;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  wrapWithAsyncListener = fn => async (...args) => {
    this.setState(
      state => ({
        loading: true,
        asyncOperations: state.asyncOperations + 1,
      }),
    );

    let result = null;
    try {
      result = await fn(...args);
    } catch (e) {
      console.error(e);
    }

    this.mounted && this.setState(
      (state) => {
        const asyncOperations = Math.max(0, state.asyncOperations - 1);
        return {
          loading: asyncOperations > 0,
          asyncOperations,
        };
      },
    );

    return result;
  };

  render() {
    const {loading} = this.state;
    const {
      controlComponent: Control,
      spinnerComponent: SpinnerComponent,
      asyncHandlers,
      children,
      loading: _loading,
      ...props
    } = this.props;

    // renderFunctionalChildren may return function
    // and controlComponent might be functional, like forms
    let content = null;
    if (SpinnerComponent) {
      content = (
        <SpinnerComponent />
      );
    } else
      content = renderFunctionalChildren(children, loading);

    return (
      <Control
        {...props}
        {...asyncHandlers && this.wrapAsyncProps(asyncHandlers, props)}
      >
        {content}
      </Control>
    );
  }
}
