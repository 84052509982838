import React, {useContext} from 'react';

const AbTestsContext = React.createContext({});

export const useAbTest = name => useContext(AbTestsContext)[name];

export const AbTestsProvider = ({tests, children}) => (
  <AbTestsContext.Provider value={tests}>
    {children}
  </AbTestsContext.Provider>
);

AbTestsProvider.displayName = 'AbTestsProvider';

export default AbTestsProvider;
