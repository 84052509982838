import React, {useContext} from 'react';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import useSubscribedStateContext from './useSubscribedStateContext';

import Provider from '../Provider';

const createContextPack = (providerProps) => {
  const Context = React.createContext({});
  const useStateContext = selectorFn => useSubscribedStateContext(
    useContext(Context),
    selectorFn,
  );

  return {
    useStateContext,

    Context,
    Consumer: ({children}) => children(useStateContext()),

    Provider: provideProps(
      {
        ...providerProps,
        contextComponent: Context,
      },
    )(Provider),
  };
};

export default createContextPack;
