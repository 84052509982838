import styled from '@adretail/fast-stylesheet/src/react/styled';
import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import flexCenter from '../../styles/flexCenter';

const Flex = styled.div(
  {
    base: {
      extend: flexCenter,
    },

    inline: {
      display: 'inline-flex',
    },

    'align-flex-start': {alignItems: 'flex-start'},
    'align-center': {alignItems: 'center'},

    'justify-flex-start': {justifyContent: 'flex-start'},
    'justify-flex-end': {justifyContent: 'flex-end'},
    'justify-center': {justifyContent: 'center'},
    'justify-space-between': {justifyContent: 'space-between'},
    'justify-space-around': {justifyContent: 'space-around'},
    'justify-space-evenly': {justifyContent: 'space-evenly'},

    'gap-small': {gap: 5},
    'gap-medium': {gap: 10},
    'gap-big': {gap: 15},
    'gap-large': {gap: 20},

    row: {
      flexDirection: 'row',
    },

    column: {
      flexDirection: 'column',
    },

    expandHeight: {
      height: '100%',
    },

    grow: {
      flexGrow: '1',
    },

    wrap: {
      flexWrap: 'wrap',
    },
  },
  {
    omitProps: [
      'row',
      'column',
      'align',
      'justify',
      'gap',
      'expandHeight',
      'grow',
      'wrap',
      'inline',
    ],
    classSelector: (classes, props) => ([
      props.row && classes.row,
      props.column && classes.column,
      props.align && classes[`align-${props.align}`],
      props.justify && classes[`justify-${props.justify}`],
      props.gap && classes[`gap-${props.gap}`],
      props.expandHeight && classes.expandHeight,
      props.grow && classes.grow,
      props.wrap && classes.wrap,
      props.inline && classes.inline,
    ]),
  },
);

Flex.displayName = 'Flex';

// just allias
Flex.Centered = Flex;

Flex.Centered.displayName = 'Flex.Centered';

Flex.Row = provideProps(
  {
    row: true,
    justify: 'flex-start',
    align: 'center',
  },
)(Flex.Centered);

Flex.Row.displayName = 'Flex.Row';

Flex.Column = provideProps(
  {
    column: true,
    justify: 'flex-start',
    align: 'flex-start',
  },
)(Flex.Centered);

Flex.AlignEnd = styled.div({alignSelf: 'flex-end'});

Flex.Grow = styled.div(
  {
    flexGrow: 1,
    flexBasis: '0',
  },
);

export default Flex;
