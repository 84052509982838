import suppressEvent from '@adretail/basic-helpers/src/inputs/suppressEvent';

const handleItemRedirect = (link, e) => {
  if (!link)
    return false;

  const {url, target} = link;
  if (!url)
    return false;

  window.open(
    url,
    target || '_blank',
  );

  e && suppressEvent(e);
  return true;
};

export default handleItemRedirect;
