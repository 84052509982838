/**
 * @description wrapper for `Intl.NumberFormat` to format distance in meters
 *
 * @example
 *  334 -> '334 m'
 *  5042 -> '5,32 km'
 *  14285042 -> '14 285,04 km'
 *
 * @param {string|number} inputDistance distance in meter format
 * @param {string} [locales=pl] locales in BCP 47 language tag for Intl.NumberFormat
 * @returns {string} formatted distance
 */
const formatDistance = (inputDistance, locales = 'pl') => {
  const defaultOption = {style: 'unit', maximumFractionDigits: 2};
  const [options, distance] = inputDistance < 1000
    ? [{...defaultOption, unit: 'meter'}, inputDistance]
    : [{...defaultOption, unit: 'kilometer'}, inputDistance / 1000];

  const distanceFormatter = new Intl.NumberFormat(locales, {...options});

  return distanceFormatter.format(distance);
};

export default formatDistance;
