import React from 'react';
import PropTypes from 'prop-types';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/typeSchema';

import suppressEvent from '@adretail/basic-helpers/src/inputs/suppressEvent';
import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';

import Holders from './Holders';
import HiddenCheckboxInput from './HiddenCheckboxInput';

export default
@linkedInputs(null, true)
class ToggleCheckbox extends React.PureComponent {
  static propTypes = {
    type: PropTypes.oneOf([
      'square', 'square-outline',
      'switch', 'bell', 'dot-outline-circle',
    ]),
    size: SIZE_ARRAY_SCHEMA,
  };

  static defaultProps = {
    type: 'switch',
    size: null,
  };

  onToggle = (e) => {
    const {l, value} = this.props;

    l.onInputEvent(!value);
    suppressEvent(e);
  };

  render() {
    const {
      value,
      required,
      className,
      type,
      size,
      fill,
      innerRef,
      ...props
    } = this.props;

    const ContainerComponent = Holders[type];
    if (!ContainerComponent)
      return null;

    return (
      <ContainerComponent
        parentProps={props}
        className={className}
        active={!!value}
        onClick={this.onToggle}
        {...innerRef && {
          innerRef,
        }}
        {...fill && {
          fill,
        }}
        {...size && {
          size,
        }}
      >
        <HiddenCheckboxInput
          required={required}
          checked={(
            value
              ? 'checked'
              : false
          )}
          onChange={suppressEvent}
        />
      </ContainerComponent>
    );
  }
}
