import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'Check',
  {
    viewBox: '0 0 26 20',
  },
  <path d='M.3,11a1,1,0,0,1,0-1.4L1.7,8.2a1,1,0,0,1,1.4,0l.1.1,5.5,5.9a.48.48,0,0,0,.7,0L22.8.3h.1a1,1,0,0,1,1.4,0l1.4,1.4a1,1,0,0,1,0,1.4h0L9.7,19.7a1,1,0,0,1-1.4,0L.5,11.3Z' />,
);
