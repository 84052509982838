import React from 'react';
import PropTypes from 'prop-types';

import {OAUTH_PROVIDERS} from '@ding/auth/src/constants/schemas';

import {AsyncSpinnerButton} from '@ding/controls/src/';
import OAuthButtonProvider from '../OAuthButtonProvider';
import {TitledFBButton} from './FBButton';
import FBApiConsumer from './FBApiConsumer';

export default class FButtonContainer extends React.PureComponent {
  static propTypes = {
    onBeforeLoadToken: PropTypes.func,
    onBeforeRegisterRedirect: PropTypes.func,
  };

  onFetchUserInfo = fb => async () => {
    const authData = await fb.loginUser();
    const user = await fb.apiCall(
      '/me',
      'get',
      {
        fields: 'id, email, birthday', // not used for now: name, location, picture, gender,
      },
    );

    return {
      user,
      authData,
    };
  };

  render() {
    const {
      t,
      onBeforeRegisterRedirect,
      onBeforeLoadToken,
      ...props
    } = this.props;

    return (
      <FBApiConsumer>
        {fb => (
          <OAuthButtonProvider
            provider={OAUTH_PROVIDERS.FACEBOOK}
            onFetchUserInfo={
              this.onFetchUserInfo(fb)
            }
            {...{
              onBeforeRegisterRedirect,
              onBeforeLoadToken,
            }}
          >
            {({onTriggerOAuth}) => (
              <AsyncSpinnerButton
                {...props}
                controlComponent={TitledFBButton}
                onClick={onTriggerOAuth}
              />
            )}
          </OAuthButtonProvider>
        )}
      </FBApiConsumer>
    );
  }
}
