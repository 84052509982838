import * as R from 'ramda';
import formatArray from '@adretail/basic-helpers/src/base/formatArray';

export const createFormattedArrayCaller = R.curry(
  (callerFn, templateArray) => params => callerFn(
    ...formatArray(templateArray, params),
  ),
);

export const createFormattedCallers = (callerFn, templates) => {
  const mappedFunctions = R.mapObjIndexed(
    R.ifElse(
      R.is(Function),
      R.applyTo(callerFn),
      createFormattedArrayCaller(callerFn),
    ),
    templates,
  );

  const fn = (...args) => callerFn(...args);
  Object.assign(fn, mappedFunctions);

  return fn;
};
