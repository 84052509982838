import React from 'react';
import PropTypes from 'prop-types';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/typeSchema';
import {WHITE} from '@ding/constants/src/colorSchema';

import c from 'classnames';
import styled from '@adretail/fast-stylesheet/src/react/styled';
import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';

import {PlainUnorderedList} from '@ding/core/src/components/Predefined';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';

const HeaderToolbar = styled(
  PlainUnorderedList,
  {
    base: {
      position: 'relative',
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      color: WHITE,
    },

    spaceBetween: {
      justifyContent: 'space-between',
    },
  },
  {
    omitProps: [
      'spaceBetween',
    ],
    classSelector: (classes, {spaceBetween}) => [
      spaceBetween && classes.spaceBetween,
    ],
    mergeProps: () => ({
      inline: true,
    }),
  },
);

const css = {
  base: {
    position: 'relative', // for hints
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },

  overrideIconSize: {
    '& i': {
      width: 26,
      height: 26,

      '& > svg': {
        width: '100%',
        height: '100%',
      },
    },
  },

  'margin-none': {
    margin: 0,
  },

  'margin-small': {
    margin: 5,
  },

  'margin-medium': {
    margin: 11,
  },

  'margin-auto': createBreakpointClasses({
    xs: {
      margin: 11,
    },

    md: {
      margin: 16,
    },
  }),

  horizontal: {
    marginTop: 0,
    marginBottom: 0,

    '&:first-child': {
      marginLeft: 0,
    },

    '&:last-child': {
      marginRight: 0,
    },
  },

  vertical: {
    marginLeft: 0,
    marginRight: 0,

    '&:first-child': {
      marginTop: 0,
    },

    '&:last-child': {
      marginBottom: 0,
    },
  },

  'with-subtitle': {
    flexDirection: 'column',
  },

  subtitle: {
    marginTop: 4,
    cursor: 'pointer',
    fontSize: 9,
    fontWeight: 600,
  },

  truncateSubtitle: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export const HeaderItem = injectClassesSheet(css, {index: 2})(React.forwardRef((
  {
    type, margin, subtitle, component: Component = 'li',
    children, classes, className, truncateSubtitle,
    overrideIconSize = true,
    componentProps, ...props
  },
  ref,
) => (
  <Component
    ref={ref}
    className={c(
      classes.base,
      type && classes[type],
      classes[`margin-${margin || 'auto'}`],
      subtitle && classes['with-subtitle'],
      overrideIconSize && classes.overrideIconSize,
      className,
    )}
    {...componentProps}
    {...props}
  >
    {children}
    {subtitle && (
      <span
        className={c(
          classes.subtitle,
          truncateSubtitle && classes.truncateSubtitle,
        )}
      >
        {subtitle}
      </span>
    )}
  </Component>
),
));

HeaderItem.displayName = 'HeaderItem';

HeaderItem.propTypes = {
  margin: SIZE_ARRAY_SCHEMA,
  type: PropTypes.oneOf([
    'vertical',
    'horizontal',
  ]),
};

HeaderItem.defaultProps = {
  margin: 'auto',
};

export default HeaderToolbar;
