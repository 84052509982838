import React from 'react';
import * as R from 'ramda';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {Address} from '@ding/core/src/components/Parts';
import {
  Margin,
  Text,
} from '@ding/core/src/components/Predefined';

import PlainAutocompleteItem from './Plain';
import {isEmptyItem} from './EmptyItem';

const isContractorItem = R.has('contractor');

const CityProvinceTitle = ({address: {city, province}}) => (
  <>
    <div>
      {city.name}
      <Text.Muted weight={500}>
        <Margin left={1}>
          {`(${province.name})`}
        </Margin>
      </Text.Muted>
    </div>
  </>
);

export const ItemAddressTitle = ({item}) => {
  const t = useTranslate();

  if (isEmptyItem(item))
    return item.name;

  const {contractor, address} = (
    isContractorItem(item)
      ? item
      : {address: item}
  );

  return (
    <span>
      {contractor && (
        <Text weight={600}>
          {`${contractor.name},`}
          &nbsp;
        </Text>
      )}

      {/* Due to Edge issues do not wrap address with <Text> tag */}
      {(
        address.city && address.street
          ? <Address address={address} textWrap={false} />
          : <CityProvinceTitle address={address} />
      )}

      {address.region && (
        <div>
          <Text.Muted
            weight={500}
            size='tiny-small'
          >
            {t('website.shared.titles.address.region', address.region.name)}
          </Text.Muted>
        </div>
      )}
    </span>
  );
};

ItemAddressTitle.displayName = 'ItemAddressTitle';

const AddressAutocompleteItem = ({item, ...props}) => (
  <PlainAutocompleteItem {...props}>
    <ItemAddressTitle item={item} />
  </PlainAutocompleteItem>
);

AddressAutocompleteItem.displayName = 'AddressAutocompleteItem';

export default AddressAutocompleteItem;
