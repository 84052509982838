import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {withRouter} from 'react-router-dom';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import applyIfFunction from '@adretail/basic-helpers/src/base/applyIfFunction';
import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import {withUA} from '@adretail/basic-components/src/Context/UAProvider';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {
  Flex,
  PlainUnorderedList,
} from '@ding/core/src/components/Predefined';

import {defaultLinkBuilder} from './utils';
import {getPageIndexFromURL} from './utils/defaultPageLinkDecoder';

import PaginationArrow from './PaginationArrow';
import PaginationItem from './PaginationItem';
import PaginationTruncateItem from './PaginationTruncateItem';

const PaginationHolder = styled(
  Flex.Row,
  createBreakpointClasses(
    {
      xs: {
        width: '100%',
      },

      md: {
        width: 'initial',
      },
    },
  ),
);

const PaginationListHolder = styled(
  PlainUnorderedList,
  {
    flexGrow: 1,

    ...createBreakpointClasses({
      xs: {
        margin: '0 10px',
      },

      md: {
        margin: '0 64px',
      },
    }),
  },
  {
    index: 3,
  },
);

const PaginationIndicesList = ({
  activePage,
  totalPages,
  fromIndex,
  toIndex,
  genLinkPropsFn,
}) => R.times(
  (offset) => {
    const number = fromIndex + offset + 1;

    return (
      <PaginationItem
        key={number}
        totalPages={totalPages}
        number={number}
        active={number === activePage}
        genLinkPropsFn={genLinkPropsFn}
      />
    );
  },
  Math.max(0, toIndex - fromIndex),
);

/**
 * @see
 * http://react-component.github.io/pagination/examples/jumper.html
 */
const Pagination = ({
  ua,
  activePage: activePageFn,
  totalPages,
  visibleItems,
  genLinkPropsFn,

  // react-router props
  location,
  match,
}) => {
  const t = useTranslate('website.pagination');
  if (totalPages <= 1)
    return null;

  const activePage = applyIfFunction(
    [location.pathname],
    activePageFn,
  );

  const indicesProps = {
    genLinkPropsFn: (...args) => genLinkPropsFn({location, match}, ...args),
    activePage,
    totalPages,
  };

  // A B [C] D E
  // C is active, offset between C and E is 2
  const activeCenterOffset = Math.floor(visibleItems / 2);

  // A [B] [C] [D] E
  // range from 1, to 3
  const activeRange = {
    fromIndex: Math.max(0, activePage - activeCenterOffset - 1), // -1 is current active
    toIndex: Math.min(totalPages, activePage + activeCenterOffset),
  };

  if (activeRange.fromIndex < 2)
    activeRange.fromIndex = 0;

  if (activeRange.toIndex > totalPages - 2)
    activeRange.toIndex = totalPages;

  // A .. C [B] D .. E
  // A is left index
  const leftIndices = ua.mobile || activeRange.fromIndex === 0 ? null : (
    <>
      <PaginationIndicesList
        fromIndex={0}
        toIndex={1}
        {...indicesProps}
      />
      <PaginationTruncateItem />
    </>
  );

  // E is rihgt index
  const rightIndices = (
    (ua.mobile && Math.abs(activeRange.fromIndex - activeRange.toIndex) > 2)
        || activeRange.toIndex === totalPages
      ? null
      : (
        <>
          <PaginationTruncateItem />
          <PaginationIndicesList
            fromIndex={totalPages - 1}
            toIndex={totalPages}
            {...indicesProps}
          />
        </>
      )
  );

  const indices = (
    <>
      {leftIndices}
      <PaginationIndicesList
        {...activeRange}
        {...indicesProps}
      />
      {rightIndices}
    </>
  );

  return (
    <PaginationHolder role='navigation' aria-label={t('label', activePage)}>
      <PaginationArrow
        totalPages={totalPages}
        number={activePage - 1}
        genLinkPropsFn={indicesProps.genLinkPropsFn}
      />

      <PaginationListHolder aria-label={t('list', activePage)}>
        {(
          ua.mobile
            ? <Flex.Centered>{indices}</Flex.Centered>
            : indices
        )}
      </PaginationListHolder>

      <PaginationArrow
        right
        totalPages={totalPages}
        number={activePage + 1}
        genLinkPropsFn={indicesProps.genLinkPropsFn}
      />
    </PaginationHolder>
  );
};

Pagination.displayName = 'Pagination';

Pagination.propTypes = {
  genLinkPropsFn: PropTypes.func,

  totalPages: PropTypes.number.isRequired,
  activePage: PropTypes.oneOfType([
    PropTypes.number, // predefined
    PropTypes.func, // link decoder
  ]),

  visibleItems: PropTypes.number, // items visible before and after active
};

Pagination.defaultProps = {
  genLinkPropsFn: defaultLinkBuilder,
  activePage: getPageIndexFromURL,
  visibleItems: 5,
};

export default R.compose(
  withUA,
  withRouter,
  provideProps(
    ({ua}) => ({
      visibleItems: ua.mobile ? 2 : 5,
    }),
  ),
)(Pagination);
