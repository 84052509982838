import React from 'react';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {Button} from '@ding/controls/src/';
import {ContractorsLink} from '@ding/layout/src/components/Links';

const MoreContractorsButton = ({category, ...props}) => {
  const t = useTranslate();

  return (
    <ContractorsLink category={category}>
      <Button.Primary
        outline
        {...props}
      >
        {t('website.shared.sections.contractors.see_more')}
      </Button.Primary>
    </ContractorsLink>
  );
};

MoreContractorsButton.displayName = 'MoreContractorsButton';

export default MoreContractorsButton;
