import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {Text} from '@ding/core/src/components/Predefined';
import ResetPasswordLink from '../../../Layout/Links/ResetPasswordLink';

const ResetPasswordTitledLink = (props) => {
  const t = useTranslate();

  return (
    <ResetPasswordLink {...props}>
      <Text size='medium-small'>
        {t('website.auth.titles.reset_password')}
      </Text>
    </ResetPasswordLink>
  );
};

ResetPasswordTitledLink.displayName = 'LoginButton';

export default ResetPasswordTitledLink;
