import React from 'react';
import {gql, GraphQLFragment} from '@ding/tiny-gql';

import TinyGqlMutation from '@ding/tiny-gql/src/react/TinyGqlMutation';
import {useAuthState} from '../../client/AuthContext';

const CONFIRM_ACCOUNT_MUTATION = new GraphQLFragment(
  'confirmAccountMutation',
  gql`
    mutation confirmAccountMutation($token: String!) {
      confirmCustomer(input: {
        confirmationToken: $token
      }) {
        token
        errors
      }
    }
  `,
);

const ConfirmAccountMutation = ({children}) => {
  const authState = useAuthState();

  return (
    <TinyGqlMutation mutation={CONFIRM_ACCOUNT_MUTATION}>
      {(mutate) => {
        const confirmUser = async (confirmationToken) => {
          const {token} = await mutate(
            {
              token: confirmationToken,
            },
          );

          authState.setToken(token);
          // await utils.refetchQuery('*');

          return token;
        };

        return children(confirmUser);
      }}
    </TinyGqlMutation>
  );
};

ConfirmAccountMutation.displayName = 'ConfirmAccountMutation';

export default ConfirmAccountMutation;
