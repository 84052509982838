import React from 'react';
import * as R from 'ramda';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';

import withESI from '@ding/server/src/cache/withESI';
import {Sticky} from '@ding/core/src/components/Parts';

import DesktopHeader from './DesktopHeader';
import HeaderAlert from '../HeaderAlert';
import LanguageHeaderAlert from '../LanguageHeaderAlert';

export const HEADER_SIDEBRANDING_STYLES = {
  '@global': {
    body: {
      '&.body__ad-spons--collapsed:not(.body__ad-spons--sticky) .page-header': {
        top: '150px !important',
      },

      '&.body__ad-spons--sticky:not(.body__ad-spons--hidden):not(.body__ad-spons--expanded) .page-header': {
        top: '60px !important',
      },

      '&.body__ad-spons--sticky.body__ad-spons--expanded:not(.body__ad-spons--hidden) .page-header': {
        top: '500px !important',
      },
    },
  },
};

const DesktopExpandableHeader = React.memo(React.forwardRef(
  ({showNav, style, sticky, ...props}, ref) => (
    <>
      <div
        className='page-header'
        role='presentation'
        style={{
          ...style,
          transition: 'top 600ms ease',
          width: '100%',
        }}
        ref={ref}
      >
        <LanguageHeaderAlert />
        <HeaderAlert />

        <DesktopHeader
          {...props}
          truncated={sticky}
          shadow={!showNav && sticky}
        />
      </div>
    </>
  ),
));

const StickyHeader = () => (
  <Sticky
    stickyActivateFn={
      ({scrollY}) => scrollY >= 10
    }
  >
    {({
      style, sticky,
      delta, changeDirectionScrollY,
    }) => (
      <DesktopExpandableHeader
        style={style}
        sticky={sticky}
        showNav={
          sticky && changeDirectionScrollY > 100 && delta < 0
        }
      />
    )}
  </Sticky>
);

StickyHeader.displayName = 'StickyHeader';

export default R.compose(
  React.memo,
  withESI,
  injectClassesSheet(HEADER_SIDEBRANDING_STYLES, {index: 3}),
)(StickyHeader);
