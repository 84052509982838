import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import Margin from './Margin';

const IconHolderContainer = styled.div(
  {
    base: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      maxWidth: '100%',
    },

    'text-left': {justifyContent: 'flex-start'},
    'text-center': {justifyContent: 'center'},
    'text-right': {justifyContent: 'flex-end'},
  },
  {
    omitProps: ['textAlign'],
    classSelector: (classes, {textAlign}) => (
      textAlign && classes[`text-${textAlign}`]
    ),
  },
);

const CornerIconHolder = ({
  icon: IconComponent,
  iconOnRight,
  iconOnly,
  iconSpacing,
  textAlign,
  children,
}) => {
  const iconContent = IconComponent && (
    React.isValidElement(IconComponent)
      ? React.cloneElement(IconComponent, {type: 'inherit'})
      : <IconComponent type='inherit' />
  );

  const icon = iconContent && (
    iconOnly
      ? iconContent
      : (
        <Margin
          {...{
            [iconOnRight ? 'left' : 'right']: R.defaultTo(1, iconSpacing),
          }}
          style={{
            display: 'inline-flex',
          }}
        >
          {iconContent}
        </Margin>
      )
  );

  return (
    <IconHolderContainer textAlign={textAlign}>
      {!iconOnRight ? icon : null}
      {!iconOnly && children}
      {iconOnRight ? icon : null}
    </IconHolderContainer>
  );
};

CornerIconHolder.displayName = 'CornerIconHolder';

CornerIconHolder.propTypes = {
  textAlign: PropTypes.string,
  icon: PropTypes.oneOfType([
    REACT_COMPONENT_CLASS_SCHEMA,
    PropTypes.node,
  ]),
  iconOnRight: PropTypes.bool,
  iconSpacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string, // margin-left: auto
  ]),
};

CornerIconHolder.defaultProps = {
  iconSpacing: 1,
};

export default CornerIconHolder;
