import * as R from 'ramda';

import customResultFilter from '@adretail/basic-helpers/src/functors/customResultFilter';
import splitPagesToChunks, {getTotalPagesChunks} from '../../splitPagesToChunks';

import {
  slideToPagesIndices,
  pageIndexToSlide,
} from '../../slideNumbersEncoder';

import {
  createInfoPage,
  createRecommendedPage,
} from '../predefinedPages';

import cacheBySlideIndex from '../cache/cacheBySlideIndex';

import * as S from './basic';
import * as PAGE_TYPES from '../../../constants/pageTypes';

/**
 * Returns total number of slides
 *
 * @param {State} state
 */
export const getTotalSlides = state => getTotalPagesChunks(
  S.getPagesPerSlide(state),
  S.getLeafletPages(state),
);

export const isFirstCurrentSlide = state => (
  S.getCurrentSlide(state) === 0
);

export const isLastCurrentSlide = state => (
  S.getCurrentSlide(state) === getTotalSlides(state) - 1
);

/**
 * Returns leaflet pages split into chunks [[page], [page1, page2], ...]
 * Used in viewer thumbnails
 *
 * @param {State} state
 *
 * @returns {Object}
 */
export const getThumbnailsChunks = (state, _pagesPerSlide) => {
  const pagesPerSlide = R.defaultTo(
    S.getPagesPerSlide(state),
    _pagesPerSlide,
  );

  return {
    pagesPerSlide,
    chunks: splitPagesToChunks(
      pagesPerSlide,
      S.getLeafletPages(state),
    ),
  };
};

/**
 * Get indexes of pages that are visible in current slide
 *
 * @param {Number} slideOffset
 */
export const getVisiblePagesIndices = (slideOffset = 0) => (state) => {
  const pagesPerSlide = S.getPagesPerSlide(state);
  const totalPages = S.getTotalPages(state);

  let slideIndex = S.getCurrentSlide(state) + slideOffset;
  if (!slideOffset) {
    slideIndex = R.clamp(
      0,
      getTotalSlides(state) - 1,
      slideIndex,
    );
  }

  return {
    pagesPerSlide,
    slideIndex,
    indices: slideToPagesIndices(
      pagesPerSlide,
      slideIndex,
      totalPages,
    ),
  };
};

/**
 * Get slide index for provided mode, used in thumbnails render
 *
 * @param {State}   state
 * @param {Number}  pagesPerSlide
 */
export const getModeCurrentSlide = (state, pagesPerSlide) => {
  const {indices} = getVisiblePagesIndices(0)(state);

  return pageIndexToSlide(
    pagesPerSlide,
    indices[0] || indices[1] || 0,
  );
};

/**
 * Returns last leaflet page
 */
export const getFirstLeafletPage = (state) => {
  const leafletPages = S.getLeafletPages(state);

  return R.head(leafletPages);
};

/**
 * Returns last leaflet page
 */
export const getLastLeafletPage = (state) => {
  const leafletPages = S.getLeafletPages(state);

  return R.last(leafletPages);
};

/**
 * Transforms current slide index to array of pages
 * from leaflet that are visible on each slide, it transforms
 * single number to array of indices
 *
 * @todo
 *  Check if it is working correctly when `getPagesPerSlide` changes
 *
 * @param {Number}  slideOffset
 * @param {State}   state
 */
export const getVisiblePages = (slideOffset = 0) => {
  const indicesSelector = getVisiblePagesIndices(slideOffset);

  return (state) => {
    const flags = S.getFlags(state);
    const leafletPages = S.getLeafletPages(state);
    const modulesEnabled = S.areModulesEnabled(state);
    const {
      pagesPerSlide,
      slideIndex,
      indices: visiblePagesIndices,
    } = indicesSelector(state);

    const pagesList = R.compose(
      R.when(
        R.isEmpty,
        R.always(null),
      ),

      // disable modules
      modulesEnabled
        ? R.identity
        : R.map(R.assoc('modules', [])),

      customResultFilter(
        R.nth(R.__, leafletPages),
      ),
    )(visiblePagesIndices);

    /**
     * Desktop mode(that contains 2 page on single slide)
     * allows to display PP and ZO pages
     */
    if (pagesPerSlide === 2 && pagesList?.length === 1) {
      // PP
      if (!slideIndex)
        return [createInfoPage(), ...pagesList];

      // ZO
      if (slideIndex + 1 === getTotalSlides(state)) {
        return [
          ...pagesList,
          flags.mobile.recommendedPage
            ? createRecommendedPage()
            : createInfoPage(true),
        ];
      }
    }

    // ZO for single mode
    if (flags.mobile.recommendedPage
        && slideOffset === 1
        && pagesList === null
        && pagesPerSlide === 1)
      return [createRecommendedPage()];

    return pagesList;
  };
};

/**
 * Gets visible pages and buffered slides(next / prev)
 * It is used in main leaflet slider, there must be at least 3 slides
 *
 * @param {State} state
 */
export const getBufferedVisiblePages = state => ({
  prev: getVisiblePages(-1)(state),
  current: getVisiblePages(0)(state),
  next: getVisiblePages(1)(state),
});

export const getActiveInsertBySlide = (slideIndex) => {
  if (!slideIndex)
    return null;

  if (!(slideIndex % 10))
    return 2;

  if (!(slideIndex % 5))
    return 1;

  return null;
};

export const getActiveInsert = (state) => {
  const slide = S.getCurrentSlide(state);
  const inserts = S.getAdsConfig(state).insert || {};

  return R.mapObjIndexed(
    R.unless(
      R.isNil,
      (index) => {
        const insert = inserts[index] || null;

        return {
          index,
          ...(
            R.is(Function, insert)
              ? insert(
                {
                  slide,
                },
              )
              : insert
          ),
        };
      },
    ),
    {
      current: (
        isLastCurrentSlide(state) || state.ui.changedPagesCount <= 1
          ? null
          : getActiveInsertBySlide(slide)
      ),

      prev: (
        slide - 1 <= 0
          ? null
          : getActiveInsertBySlide(slide - 1)
      ),
    },
  );
};

export const getFirstNotNullPage = pages => pages && (pages[0] || pages[1]);

export const getVisibleImagePages = R.compose(
  R.filter(
    R.propEq('kind', PAGE_TYPES.IMAGE),
  ),
  getVisiblePages(0),
);

export const getFirstVisiblePageCover = R.compose(
  R.unless(
    R.isNil,
    R.propOr(null, 'postThumbnail'),
  ),
  R.head,
  getVisibleImagePages,
);

export const convertPageIndexToSlide = (state, pageIndex) => pageIndexToSlide(
  S.getPagesPerSlide(state),
  pageIndex,
);

/**
 * List for selected user
 */
export const selectors = {
  isFirstCurrentSlide: () => isFirstCurrentSlide,
  isLastCurrentSlide: () => isLastCurrentSlide,

  getThumbnailsChunks: () => getThumbnailsChunks,
  getModeCurrentSlide: () => getModeCurrentSlide,

  getActiveInsert: () => cacheBySlideIndex(getActiveInsert),
  getFirstVisiblePageCover: () => cacheBySlideIndex(getFirstVisiblePageCover),
  getVisibleImagePages: () => cacheBySlideIndex(getVisibleImagePages),

  getTotalSlides: () => cacheBySlideIndex(getTotalSlides),
  getVisiblePagesIndices: () => cacheBySlideIndex(getVisiblePagesIndices(0)),
  getVisiblePages: () => cacheBySlideIndex(getVisiblePages(0)),
  getBufferedVisiblePages: () => cacheBySlideIndex(getBufferedVisiblePages),

  getFirstLeafletPage: () => getFirstLeafletPage,
  getLastLeafletPage: () => getLastLeafletPage,

  convertPageIndexToSlide: () => convertPageIndexToSlide,
};
