import PropTypes from 'prop-types';

import {useInterval} from '@adretail/basic-hooks/src/base';
import useRefetchTokenSetter from './utils/useRefetchTokenSetter';

const IntervalRefreshToken = ({timeout}) => {
  const {refreshToken} = useRefetchTokenSetter();

  useInterval(
    () => refreshToken(),
    timeout,
  );

  return null;
};

IntervalRefreshToken.displayName = 'IntervalRefreshToken';

IntervalRefreshToken.propTypes = {
  timeout: PropTypes.number,
};

IntervalRefreshToken.defaultProps = {
  timeout: 1 * 60 * 1000, // 1 min
};

export default IntervalRefreshToken;
