import React from 'react';
import PropTypes from 'prop-types';

import {ControlsList} from '@ding/controls/src/';
import * as AuthButtons from './Base';

const LoginRegisterControlsList = ({onToggleRegister, onToggleLogin, ...props}) => (
  <ControlsList
    inline
    center
    {...props}
  >
    <AuthButtons.Login
      onToggle={onToggleLogin}
    />

    <AuthButtons.Register
      translation='create_account'
      outline
      onToggle={onToggleRegister}
    />
  </ControlsList>
);

LoginRegisterControlsList.displayName = 'LoginRegisterControlsList';

LoginRegisterControlsList.propTypes = {
  onToggleLogin: PropTypes.func,
  onToggleRegister: PropTypes.func,
};

export default LoginRegisterControlsList;
