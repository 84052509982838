import React from 'react';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import {trackComponent} from '@ding/interia';

import ProductsLink from '@ding/layout/src/components/Links/ProductsLink';
import ProductIcon from '@ding/core/src/icons/navbar/Products';
import IconButton from '@ding/controls/src/Button/IconButton';

import {HeaderItem} from '../../HeaderToolbar';

const OffersIcon = ({subtitle, iconProps, margin, onClick}) => (
  <li>
    <HeaderItem
      subtitle={subtitle.name}
      title={subtitle.title}
      aria-label={subtitle.title}
      margin={margin}
      component={IconButton}
      componentProps={{
        link: provideProps({injectOnHover: true, onClick})(ProductsLink),
        icon: ProductIcon,
        type: 'light',
        iconProps: {
          size: 'big',
          ...iconProps,
        },
      }}
    />
  </li>
);

OffersIcon.displayName = 'OffersIcon';

export default trackComponent(
  {
    onClick({ga4}) {
      ga4.menuClickProducts();
    },
  },
)(OffersIcon);
