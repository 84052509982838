import React from 'react';

import {TitledDivider} from '@ding/core/src/components/Parts';

import {OAuthButtonsRow} from '../../Shared';
import RegisterBufferedControlGroup from '../RegisterBufferedControlGroup';

const RegisterFormStep = ({errors, onChangeModalAuthTab, onSubmit}) => (
  <>
    <OAuthButtonsRow />
    <TitledDivider.OR space='medium' />
    <RegisterBufferedControlGroup
      {...{
        errors,
        onSubmit,
        onChangeModalAuthTab,
      }}
    />
  </>
);

RegisterFormStep.displayName = 'RegisterFormStep';

export default RegisterFormStep;
