import * as R from 'ramda';
import renameKeys from '@adretail/basic-helpers/src/base/renameKeys';

export const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 800,
  lg: 992,
  xl: 1200,
};

export const createBreakpoint = {
  minWidth: minWidth => `@media (min-width: ${minWidth}px)`,
  maxWidth: maxWidth => `@media (max-width: ${maxWidth}px)`,
  betweenWidth: (minWidth, maxWidth) => `@media (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`,
};

/**
 * Reduces object of xs, sm etc. keys to media keys
 *
 * @example
 *  fn({xl: {...}, lg: {...}}) => ({@media-query ..., @media-query ...})
 *
 * @param {Object}  breakpointClasses
 * @returns {Object}
 */
export const createBreakpointClasses = renameKeys(
  R.mapObjIndexed(
    createBreakpoint.minWidth,
    BREAKPOINTS,
  ),
);
