import React from 'react';

import DefaultTinyQuery from '../DefaultTinyQuery';
import ExpandableFunctionalChunksList from '.';

export const paginationChunkMappers = {
  pickChunkInfoFn: (page, {
    items,
    totalPages,
  }) => {
    const pageSize = (items?.length || 0);
    const remainItems = (totalPages - page - 1) * pageSize;

    return {
      index: page,
      totalChunks: totalPages,
      remainItems,
    };
  },

  asyncChunkPropsFn: ({chunkIndex}, {variables}) => ({
    variables: {
      ...variables,
      page: chunkIndex + 1,
    },
  }),
};

const QueryChunksList = props => (
  <ExpandableFunctionalChunksList
    asyncChunkResolverComponent={DefaultTinyQuery}

    {...paginationChunkMappers}
    {...props}
  />
);

QueryChunksList.displayName = 'QueryChunksList';

export default QueryChunksList;
