import {parameterize} from '@ding/core/src/helpers';
import createLinkComponent from '../../utils/createLinkComponent';

export const ARTICLE_URL_SCHEMA = '/blog/artykuly/:title,:id';

export const genArticleURL = ({id, title, slug}) => (
  slug
    ? `/blog/artykuly/${slug},${id}`
    : `/blog/artykuly/${parameterize(title)},${id}`
);

export default createLinkComponent(
  'article',
  genArticleURL,
);
