import PropTypes from 'prop-types';
import c from 'classnames';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/typeSchema';

import {
  WHITE,
  DARK,

  ERROR_INPUT_BACKGROUND,
  ERROR_INPUT_BORDER,

  INPUT_BACKGROUND,
  INPUT_FOREGROUND,
  INPUT_LIGHT_GRAY_BACKGROUND,
  INPUT_LIGHT_FOREGROUND,
  INPUT_LIGHT_BACKGROUND,
} from '@ding/constants/src/colorSchema';

const Input = styled.input(
  {
    base: {
      height: 50,
      width: '100%',

      padding: '0 20px',
      fontSize: 13,
      fontWeight: 500,

      borderRadius: 24,
      border: 0,
      outline: 0,

      transition: ['250ms', 'ease-in-out'],
      transitionProperty: ['background', 'height'],

      '&::placeholder': {
        fontWeight: 'initial',
      },
    },

    medium: {
      height: 40,
    },

    spaceLeft: {
      paddingLeft: 50,
    },

    spaceRight: {
      paddingRight: 50,
    },

    white: {
      background: WHITE,
      color: DARK,
    },

    gray: {
      background: INPUT_BACKGROUND,
      color: INPUT_FOREGROUND,

      '&::placeholder': {
        fontWeight: 600,
        fontSize: 13,
      },
    },

    lightGray: {
      background: INPUT_LIGHT_GRAY_BACKGROUND,
      color: INPUT_FOREGROUND,

      '&::placeholder': {
        fontWeight: 600,
        fontSize: 13,
      },
    },

    light: {
      background: INPUT_LIGHT_BACKGROUND,
      color: INPUT_LIGHT_FOREGROUND,
    },

    error: {
      background: ERROR_INPUT_BACKGROUND,
      border: [2, 'solid', ERROR_INPUT_BORDER],
    },

    // within text
    inline: {
      height: 'calc(1em + 12px)',
      minWidth: 90,
      maxWidth: 100,
    },

    inlineExpanded: {
      height: 'calc(1em + 12px)',
      minWidth: 120,
      maxWidth: 130,
    },
  },
  {
    omitProps: [
      'color',
      'spaceLeft',
      'spaceRight',
      'error',
      'inline',
      'inlineExpanded',
      'size',
    ],
    classSelector: (classes, {
      inline, inlineExpanded, error,
      color, size, spaceLeft,
      spaceRight,
    }) => c(
      classes[color],
      size && classes[size],
      error && classes.error,
      inline && classes.inline,
      inlineExpanded && classes.inlineExpanded,
      {
        [classes.spaceLeft]: spaceLeft,
        [classes.spaceRight]: spaceRight,
      },
    ),
  },
);

Input.displayName = 'Input';

Input.propTypes = {
  error: PropTypes.bool,
  inline: PropTypes.bool,
  size: SIZE_ARRAY_SCHEMA,
  color: PropTypes.oneOf([
    'white',
    'gray',
    'light',
    'lightGray',
  ]),
};

Input.defaultProps = {
  error: false,
  color: 'gray',
};

Input.Text = provideProps(
  {
    type: 'text',
  },
)(Input);

export default Input;
