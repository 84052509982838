import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import {
  WHITE,
  DARK,
} from '@ding/constants/src/colorSchema';

import {
  DIRECTION_SCHEMA,
  DIRECTION,
  SIZE_ARRAY_SCHEMA,
  PROPS_SCHEMA,
} from '@ding/constants/src/typeSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {popupZDepth} from '@ding/core/src/styles/zDepth';

export const ARROW_SIZE = 7;

export const ARROW_OFFSET = 14;

export const getDirectionMargins = (direction, size) => {
  switch (direction) {
    case DIRECTION.BOTTOM_RIGHT:
      return {
        marginRight: Math.floor(size.width / 2) - ARROW_OFFSET - ARROW_SIZE,
        marginBottom: size.height / 2 - ARROW_SIZE + 4,
      };

    case DIRECTION.TOP:
      return {
        marginTop: size.height + 5,
        marginRight: Math.floor(size.width / 2) - ARROW_OFFSET - ARROW_SIZE,
        marginBottom: size.height / 2 - ARROW_SIZE + 4,
      };

    default:
      return {
        marginLeft: size.width / 2,
        marginTop: size.height / 2 - ARROW_OFFSET - ARROW_SIZE,
      };
  }
};

const PopoverArrow = styled.span(
  {
    base: {
      position: 'absolute',
    },

    [DIRECTION.TOP]: {
      top: -ARROW_SIZE,
      right: ARROW_OFFSET,

      borderLeft: `${ARROW_SIZE}px solid transparent !important`,
      borderRight: `${ARROW_SIZE}px solid transparent !important`,
      borderBottom: `${ARROW_SIZE}px solid ${WHITE}`,
    },

    [DIRECTION.TOP_CENTER]: {
      top: -ARROW_SIZE,
      left: `calc(50% - ${ARROW_SIZE / 2}px)`,

      borderLeft: `${ARROW_SIZE}px solid transparent !important`,
      borderRight: `${ARROW_SIZE}px solid transparent !important`,
      borderBottom: `${ARROW_SIZE}px solid ${WHITE}`,
    },

    [DIRECTION.TOP_LEFT]: {
      top: -ARROW_SIZE,
      left: ARROW_OFFSET,

      borderLeft: `${ARROW_SIZE}px solid transparent !important`,
      borderRight: `${ARROW_SIZE}px solid transparent !important`,
      borderBottom: `${ARROW_SIZE}px solid ${WHITE}`,
    },

    [DIRECTION.RIGHT]: {
      top: ARROW_OFFSET,
      right: -ARROW_SIZE,

      borderTop: `${ARROW_SIZE}px solid transparent !important`,
      borderBottom: `${ARROW_SIZE}px solid transparent !important`,
      borderLeft: `${ARROW_SIZE}px solid ${WHITE}`,
    },

    [DIRECTION.LEFT]: {
      top: ARROW_OFFSET,
      left: -ARROW_SIZE,

      borderTop: `${ARROW_SIZE}px solid transparent !important`,
      borderBottom: `${ARROW_SIZE}px solid transparent !important`,
      borderRight: `${ARROW_SIZE}px solid ${WHITE}`,
    },

    [DIRECTION.BOTTOM_RIGHT]: {
      bottom: -ARROW_SIZE,
      right: ARROW_OFFSET,

      borderLeft: `${ARROW_SIZE}px solid transparent !important`,
      borderRight: `${ARROW_SIZE}px solid transparent !important`,
      borderTop: `${ARROW_SIZE}px solid ${WHITE}`,
    },

    dark: {
      borderColor: DARK,
    },
  },
  {
    omitProps: [
      'direction',
      'dark',
    ],

    classSelector: (classes, {dark, direction}) => c(
      classes[direction],
      dark && classes.dark,
    ),
  },
);

const PopoverContentHolder = styled.div(
  {
    base: {
      extend: popupZDepth,

      position: 'absolute',
      padding: '20px 10px',
      borderRadius: 6,
      zIndex: 999,

      background: WHITE,
      color: DARK,
    },

    noPadding: {
      padding: 0,
    },

    [DIRECTION.TOP]: {
      top: 0,
      right: 0,
    },

    [DIRECTION.TOP_CENTER]: {
      top: 0,
      left: 0,
      transform: 'translateX(-50%)',
    },

    [DIRECTION.TOP_LEFT]: {
      top: 0,
      left: 0,
    },

    [DIRECTION.RIGHT]: {
      top: 0,
      right: 0,
    },

    [DIRECTION.LEFT]: {
      top: 0,
      left: '100%',
    },

    [DIRECTION.BOTTOM_RIGHT]: {
      bottom: '100%',
      right: 0,
    },

    dark: {
      background: DARK,
      color: WHITE,
    },

    'size-small': {
      minWidth: 200,
    },

    'size-medium': {
      minWidth: 325,
    },
  },
  {
    omitProps: [
      'direction',
      'dark',
      'noPadding',
    ],

    classSelector: (classes, {noPadding, dark, direction, size}) => c(
      classes[direction],
      dark && classes.dark,
      noPadding && classes.noPadding,
      size && classes[`size-${size}`],
    ),
  },
);

const PopoverHolder = React.forwardRef(({
  dark, direction, noPadding, aria,
  children, style, arrowStyle, size,
}, ref) => (
  <PopoverContentHolder
    {...aria}
    ref={ref}
    {...{
      style,
      dark,
      direction,
      noPadding,
      size,
    }}
  >
    {children}
    <PopoverArrow
      style={arrowStyle}
      {...{
        dark,
        direction,
      }}
    />
  </PopoverContentHolder>
));

PopoverHolder.displayName = 'PopoverHolder';

PopoverHolder.propTypes = {
  direction: DIRECTION_SCHEMA,
  dark: PropTypes.bool,
  noPadding: PropTypes.bool,
  arrowStyle: PropTypes.any,
  size: SIZE_ARRAY_SCHEMA,
  aria: PROPS_SCHEMA,
};

PopoverHolder.defaultProps = {
  direction: DIRECTION.TOP,
  dark: false,
  size: 'small',
};

export default PopoverHolder;
