import {useContext} from 'react';
import {__RouterContext} from 'react-router';

import ssr from '@adretail/basic-helpers/src/base/ssr';

import {prefixWithRootDomain} from '@ding/layout/src/components/Links/utils/prefixWithRootAddress';
import useSubscribedStateContext from '@ding/tiny-context-state/src/utils/useSubscribedStateContext';

export const getRouterFullPath = (location, withDomain = false) => {
  const {pathname, hash, search} = location;
  const fullPath = `${pathname}${search || ''}${hash || ''}`;

  return (
    withDomain
      ? prefixWithRootDomain(fullPath)
      : fullPath
  );
};

const useReactRouter = () => useContext(__RouterContext);

/** OPTIMIZED VIEWER CONTEXT - USE CACHE */
const HTMLRouterContext = {
  subscribe: (fn) => {
    window.addEventListener('popstate', fn);
  },

  unsubscribe: (fn) => {
    window.removeEventListener('popstate', fn);
  },

  getState() {
    if (ssr)
      return {};

    const {
      location,
      history,
    } = window;

    return {
      location,
      state: history.state?.state,
    };
  },
};

export const useDOMSubscribedRouter = selectorFn => (
  useSubscribedStateContext(HTMLRouterContext, selectorFn)
);

export default useReactRouter;
