import React from 'react';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import Tabs from './Tabs';

const WizardStep = ({children}) => (
  <div>
    {children}
  </div>
);

const ControlsWizard = provideProps(
  {
    headless: true,
  },
  true,
)(Tabs);

ControlsWizard.displayName = 'ControlsWizard';

ControlsWizard.Step = WizardStep;

export default ControlsWizard;
