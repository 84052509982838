import * as R from 'ramda';

export default class MemCache {
  memory = {
    // [key]: {timeout, data},
    // todo: use WeakMap if seconds = 0?
  };

  constructor({defaultKeyExpire} = {}) {
    this.defaultKeyExpire = defaultKeyExpire;
  }

  del(key) {
    const {memory} = this;
    const oldKey = memory[key];

    if (oldKey) {
      clearTimeout(oldKey.timeout);
      delete memory[key];
    }
  }

  setex(key, value, seconds = this.defaultKeyExpire) {
    this.del(key);

    const timeoutHandle = setTimeout(
      () => this.del(key),
      seconds * 1000,
    );

    this.memory[key] = {
      timeout: timeoutHandle,
      data: value,
    };

    return value;
  }

  get(key) {
    const {data} = this.memory[key] || {data: null};
    return data;
  }

  clear() {
    R.forEachObjIndexed(
      ({timeout}) => {
        clearTimeout(timeout);
      },
      this.memory,
    );

    this.memory = {};
    return this;
  }
}
