import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/typeSchema';
import {
  TEXT_MUTED,
  TEXT_DARK_MUTED,
  PRIMARY_FILL,
  WHITE,
} from '@ding/constants/src/colorSchema';

import textEllipsisOverflow from '../../styles/textEllipsisOverflow';

import textSize from '../../styles/textSize';
import {textShadow} from '../../styles/zDepth';

const styles = {
  '@global': {
    '.text-primary': {color: PRIMARY_FILL},
    '.text-strong': {fontWeight: 'bold'},
  },

  base: {
    display: 'inline-block',
  },

  block: {
    display: 'block',
  },

  expanded: {
    width: '100%',
  },

  noSelect: {
    userSelect: 'none',
  },

  noWrap: {
    whiteSpace: 'nowrap',
  },

  truncate: {
    extend: textEllipsisOverflow,
    maxWidth: '100%',
  },

  light: {
    color: WHITE,
  },

  muted: {
    color: TEXT_MUTED,
  },

  'dark-muted': {
    color: TEXT_DARK_MUTED,
  },

  primary: {
    color: PRIMARY_FILL,
  },

  justify: {
    textAlign: 'justify',
  },

  underline: {
    textDecoration: 'underline',
  },

  lowercase: {
    textTransform: 'lowercase',
  },

  uppercase: {
    textTransform: 'uppercase',
  },

  left: {
    textAlign: 'left !important',
  },

  center: {
    width: '100%',
    textAlign: 'center',
  },

  shadow: {
    extend: textShadow,
  },

  'default-weight': {fontWeight: 'initial'},
  500: {fontWeight: 500},
  600: {fontWeight: 600},
  700: {fontWeight: 700},
  800: {fontWeight: 800},
  900: {fontWeight: 900},

  ...textSize,
};

const Text = injectClassesSheet(styles)(({
  tagName: Component,
  classes, lowercase,
  className, underline,
  size, center, type,
  justify, uppercase, weight,
  truncate, noSelect, noWrap,
  block, expanded, left, shadow,
  ...props
}) => (
  <Component
    {...props}
    className={c(
      className,
      classes[type],
      underline && classes.underline,
      block && classes.block,
      justify && classes.justify,
      uppercase && classes.uppercase,
      lowercase && classes.lowercase,
      truncate && classes.truncate,
      center && classes.center,
      size && classes[size],
      weight && classes[weight],
      noSelect && classes.noSelect,
      noWrap && classes.noWrap,
      expanded && classes.expanded,
      left && classes.left,
      shadow && classes.shadow,
    )}
  />
));

Text.displayName = 'Text';

Text.propTypes = {
  tagName: PropTypes.string,
  left: PropTypes.bool,
  block: PropTypes.bool,
  justify: PropTypes.bool,
  uppercase: PropTypes.bool,
  center: PropTypes.bool,
  noSelect: PropTypes.bool,
  noWrap: PropTypes.bool,
  shadow: PropTypes.bool,
  size: SIZE_ARRAY_SCHEMA,
  weight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  type: PropTypes.oneOf([
    'light',
    'muted',
    'dark-muted',
    'primary',
    'default',
  ]),
};

Text.defaultProps = {
  tagName: 'span',
  type: 'default',
};

Text.DarkMuted = provideProps({type: 'dark-muted'})(Text);
Text.Muted = provideProps({type: 'muted'})(Text);
Text.Primary = provideProps({type: 'primary'})(Text);
Text.Light = provideProps({type: 'light'})(Text);

Text.Center = provideProps({center: true})(Text);

export default Text;
