import {gql, GraphQLFragment} from '@ding/tiny-gql';
import useMutation from '@ding/tiny-gql/src/react/hooks/useMutation';

import {useAuthState} from '../../client/AuthContext';
import {useCreateGuestUserMutation} from './CreateGuestUserMutation';

const LOGOUT_USER_MUTATION = new GraphQLFragment(
  'LogoutCustomerMutation',
  gql`
    mutation LogoutCustomerMutation($clientMutationId: String!) {
      logoutCustomer(input: {clientMutationId: $clientMutationId}) {
        errors
        success
      }
    }
  `,
);

const LogoutMutation = ({children}) => {
  const authState = useAuthState();

  const [createGuestUser, utils] = useCreateGuestUserMutation();
  const [invalidateUserToken] = useMutation(LOGOUT_USER_MUTATION);

  const logoutUser = async () => {
    // invalidate current user token
    await invalidateUserToken({clientMutationId: authState.getToken()});

    // create geest user
    const {token} = await createGuestUser();
    authState.setToken(token);
    utils.refetchQuery('*');

    return null;
  };

  return children(logoutUser);
};

LogoutMutation.displayName = 'LogoutMutation';

export default LogoutMutation;
