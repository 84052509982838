import React from 'react';
import * as R from 'ramda';

import withDecodedLocationSearch from '@ding/core/src/decorators/withDecodedLocationSearch';

import {Layout} from '@ding/layout/src/components/LayoutParts';
import SendEmailResetLinkSection from './SendEmailResetLink';
import SetPasswordSection from './SetPassword';

export const RESET_PASSWORD_TOKEN_GET_PARAM = 'reset_password_token';

const ResetPasswordRoute = ({searchParams}) => {
  const token = searchParams[RESET_PASSWORD_TOKEN_GET_PARAM];

  return (
    <Layout>
      {(
        token
          ? <SetPasswordSection token={token} />
          : <SendEmailResetLinkSection />
      )}
    </Layout>
  );
};

ResetPasswordRoute.displayName = 'ResetPasswordRoute';

export default R.compose(
  React.memo,
  withDecodedLocationSearch,
)(ResetPasswordRoute);
