import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {usePrivacyLinkQuery} from '@ding/api/src/gql/customerLinkQuery';

import {UndecoratedLink} from '@ding/core/src/components/Predefined';

const PrivacyLink = ({children, ...props}) => {
  const t = useTranslate();
  const {data: link} = usePrivacyLinkQuery();

  return (
    <UndecoratedLink
      {...props}
      to={link || 'https://prywatnosc.interia.pl/'}
      target='_blank'
      rel='nofollow noopener noreferrer'
    >
      {children || t('website.shared.titles.privacy')}
    </UndecoratedLink>
  );
};

PrivacyLink.displayName = 'PrivacyLink';

export default React.memo(PrivacyLink);
