import React from 'react';
import createIcon from '../utils/createIcon';

export default createIcon(
  'Leaflets',
  {
    viewBox: '0 0 27.913 24',
  },
  <>
    <path d='M105.2,132.708,93.95,134.016l-10.924-1.307a2.873,2.873,0,0,0-2.87,2.87v18.26a2.876,2.876,0,0,0,2.881,2.87h.014l11.108-1.3,11.04,1.3a2.873,2.873,0,0,0,2.87-2.87v-18.26A2.873,2.873,0,0,0,105.2,132.708Zm1.038,21.13a1.04,1.04,0,0,1-.95,1.045L94.461,153.6v-5.739H93.24v5.814l-10.261,1.2a1.04,1.04,0,0,1-.988-1.042l-.01-18.261a1.04,1.04,0,0,1,.949-1.043l10.306,1.234v6.68h1.221v-6.661l10.792-1.245a1.039,1.039,0,0,1,.988,1.04Z' transform='translate(-80.156 -132.708)' />
  </>,
);
