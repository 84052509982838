export default {
  meta: {
    title: 'Wynik wyszukiwania dla "%{}" (%{})',
  },
  not_found: {
    title: 'Brak wyników wyszukiwania dla podanej frazy: %{}',
    tips: {
      title: 'Podpowiedzi:',
      list: [
        'Sprawdź czy wszystkie słowa zostały poprawnie napisane',
        'Spróbuj użyć innych słów kluczowych',
        'Spróbuj użyć bardziej ogólnych słów kluczowych',
      ],
    },
  },
  min_len: {
    title: 'Podana fraza "%{}" jest za krótka, powinna składać się z minimum %{} znaków',
  },
  tabs: {
    ALL: 'Wszystkie',
    CONTRACTORS: 'Sklepy',
    LEAFLETS: 'Gazetki',
    PRODUCTS: 'Produkty',
    LEAFLETS_PAGES: 'Strony gazetek',
    ARTICLES: 'Newsy',
    more: 'Więcej',
    categories: 'Kategorie',
  },
  chips: {
    more: 'Więcej',
    clear: 'Wyczyść',
  },
};
