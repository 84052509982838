import {gql, GraphQLFragment} from '@ding/tiny-gql';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import TinyGqlMutation from '@ding/tiny-gql/src/react/TinyGqlMutation';

const SEND_RESET_PASSWORD_TOKEN_MUTATION = new GraphQLFragment(
  'sendResetPasswordTokenCustomerMutation',
  gql`
    mutation sendResetPasswordTokenCustomerMutation(
      $email: String!,
      $recaptcha: Recaptcha!,
    ) {
      sendResetPasswordTokenCustomer(input: {
        email: $email,
        recaptcha: $recaptcha,
      }) {
        errors
      }
    }
  `,
);

export default provideProps(
  {
    mutation: SEND_RESET_PASSWORD_TOKEN_MUTATION,
  },
)(TinyGqlMutation);
