import React from 'react';
import * as R from 'ramda';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import translate from '@ding/i18n';

import {AsyncSpinnerButton} from '@ding/controls/src/';

const CreateAccountButton = ({t, ...props}) => (
  <AsyncSpinnerButton
    expanded
    type='primary'
    {...props}
  >
    {t('website.auth.titles.create_account')}
  </AsyncSpinnerButton>
);

CreateAccountButton.displayName = 'CreateAccountButton';

export default R.compose(
  translate,
  Component => styled(
    Component,
    {
      margin: '0 auto',
      maxWidth: 340,
    },
  ),
)(CreateAccountButton);
