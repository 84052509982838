import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {
  Redirect,
  withRouter,
} from 'react-router-dom';

import {ID_SCHEMA} from '@adretail/schemas';

import translate from '@ding/i18n';
import withDecodedLocationSearch from '@ding/core/src/decorators/withDecodedLocationSearch';

import showUserToast from '@ding/controls/src/ToastAlert/types/showUserToast';

class PostMountConfirmExecutor extends React.Component {
  static propTypes = {
    token: ID_SCHEMA.isRequired,
    promiseFn: PropTypes.func.isRequired,

    onRequestConfirmExec: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    const {
      token,
      promiseFn,
      onRequestConfirmExec,
    } = this.props;

    onRequestConfirmExec(
      () => promiseFn(token),
    );
  }

  render() {
    return null;
  }
}

const ConfirmTokenRoute = ({
  t, toastTranslationPath, tokenName,
  history, searchParams,
  redirectUrl, tokenVerifyFn,
}) => {
  const token = searchParams[tokenName];
  if (!token)
    return <Redirect to={redirectUrl.fail} />;

  return (
    <PostMountConfirmExecutor
      token={token}
      promiseFn={tokenVerifyFn}
      onRequestConfirmExec={
        (promiseFn) => {
          showUserToast(
            ({error}) => t(`${toastTranslationPath}.${error ? 'error' : 'success'}`),
            null,
            promiseFn,
          );

          history.push(redirectUrl.success);
        }
      }
    />
  );
};

ConfirmTokenRoute.displayName = 'ConfirmTokenRoute';

ConfirmTokenRoute.propTypes = {
  tokenName: PropTypes.string,
  tokenVerifyFn: PropTypes.func.isRequired,
  toastTranslationPath: PropTypes.string.isRequired,

  redirectUrl: PropTypes.shape({
    success: PropTypes.string.isRequired,
    fail: PropTypes.string.isRequired,
  }),
};

ConfirmTokenRoute.defaultProps = {
  tokenName: 'token',
  redirectUrl: {
    success: '/',
    fail: '/',
  },
};

export default R.compose(
  translate,
  withRouter,
  withDecodedLocationSearch,
)(ConfirmTokenRoute);
