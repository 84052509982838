import React from 'react';
import PropTypes from 'prop-types';

import {LEAFLET_CARD_SCHEMA} from '@ding/constants/src/typeSchema';

import textEllipsisOverflow from '@ding/core/src/styles/textEllipsisOverflow';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import Text from '@ding/core/src/components/Predefined/Text';

const css = {
  base: {
    extend: textEllipsisOverflow,

    display: 'inline-block',
    width: '100%',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: 10,
    textAlign: 'center',
    margin: '0 auto',
  },
};

const LeafletTitleBar = ({classes, t, leaflet, prefixedTitle, ...props}) => {
  const contractorName = leaflet.contractor?.name || leaflet.name;

  return (
    <Text.Muted className={classes.base} {...props}>
      {(
          prefixedTitle
            ? t('website.shared.cards.leaflet.title', contractorName)
            : contractorName
        )}
    </Text.Muted>
  );
};

LeafletTitleBar.displayName = 'LeafletTitleBar';

LeafletTitleBar.propTypes = {
  leaflet: LEAFLET_CARD_SCHEMA.isRequired,
  prefixedTitle: PropTypes.bool,
};

export default injectClassesSheet(css)(LeafletTitleBar);
