import React from 'react';
import PropTypes from 'prop-types';
import {Route, withRouter} from 'react-router-dom';
import * as R from 'ramda';

import {PROPS_SCHEMA} from '@adretail/schemas';
import suppressEvent from '@adretail/basic-helpers/src/inputs/suppressEvent';

import * as idleCallback from '@adretail/basic-helpers/src/async/idleCallback';

import memoizeOne from '@adretail/basic-helpers/src/base/memoizeOne';
import decodeUrl from '@adretail/basic-helpers/src/url/decodeUrl';
import {buildUrl} from '@adretail/basic-helpers/src/url/encodeUrl';

import ModalEventSuppress from '@ding/core/src/components/Parts/ModalEventSuppress';

export const URL_MODES = {
  HASH: 'HASH',
  GET: 'GET',
};

const URL_ENCODERS = {
  [URL_MODES.HASH]: {
    encode: ({pathname, search}, uuid) => `${pathname}${search}#${uuid}`,
    match: ({hash}, uuid) => hash === `#${uuid}`,
    reset: ({pathname, search}) => `${pathname}${search}`,
  },

  [URL_MODES.GET]: (() => {
    // it will not generate SSR memory leak,
    // memoizeOne holds only one URL in mem
    const cachedDecoder = memoizeOne(decodeUrl);

    return ({getParamName = 'popup'} = {}) => ({
      encode: ({pathname, search}, uuid) => buildUrl(
        pathname,
        {
          ...cachedDecoder(search),
          [getParamName]: uuid,
        },
      ),

      match: ({search}, uuid) => {
        const params = cachedDecoder(search) || {};
        return params[getParamName] === uuid;
      },

      // removes modal from URL
      reset: ({pathname, search}) => buildUrl(
        pathname,
        R.compose(
          R.omit([getParamName]),
          cachedDecoder,
        )(search),
      ),
    });
  })(),
};

const getUrlEncoderMode = (urlMode) => {
  if (R.is(Function, urlMode))
    return urlMode();

  const encoder = URL_ENCODERS[urlMode.type];

  return R.when(
    R.is(Function),
    R.applyTo(urlMode.params),
    encoder,
  );
};

/**
 * It is clone of basic-component/ModalTriggerComponent
 * but it supports also routing. If user triggers modal
 * it modifies URL,
 *
 * @see
 *  - Do not add URLModalTrigger inside other URLModalTrigger!
 *  - SSR does not support Portals, for now!
 *
 * @example
 *  <URLModalTrigger modalRenderFn={modalContentFn}>Click</URLModalTrigger>
 *  <URLModalTrigger
 *    modalRenderFn={modalContentFn}
 *    triggerRenderFn={fn}
 *  />
 *  <URLModalTrigger
 *    modalComponent={ModalComponent}
 *    triggerComponent={Component}
 *  />
 *
 * @export
 */
export default
@withRouter
class URLModalTrigger extends React.Component {
  static propTypes = {
    modalRenderFn: PropTypes.func,
    modalComponent: PropTypes.any,

    triggerRenderFn: PropTypes.func,
    triggerComponent: PropTypes.any,
    modalProps: PROPS_SCHEMA,

    wrapWithPortal: PropTypes.bool,
    provideToggleStateProps: PropTypes.bool,
    renderNonMountedModal: PropTypes.bool,
    watchEvent: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),

    // url name
    uuid: PropTypes.string,

    // mode triggers GET / HASH change or something else
    urlMode: PropTypes.oneOfType(
      [
        PropTypes.func,
        PropTypes.shape(
          {
            type: PropTypes.oneOf(R.values(URL_MODES)),
            params: PropTypes.any,
            state: PropTypes.any,
          },
        ),
      ],
    ),

    // after modal is removed
    onUnmount: PropTypes.func,
    onToggle: PropTypes.func,
  };

  static defaultProps = {
    wrapWithPortal: true,
    provideToggleStateProps: false,
    urlMode: {
      type: URL_MODES.HASH,
    },
    triggerComponent: 'span',
    watchEvent: 'onClick',

    onToggle: R.T,
  };

  state = {
    uuid: null,
    silentToggled: false,
  };

  static getDerivedStateFromProps(props, state) {
    const {urlMode, location} = props;
    const uuid = props.uuid || state.uuid || null;

    // nil match for SSR
    const urlMatch = (
      !R.isNil(uuid)
        && !R.isNil(state.urlMatch)
        && getUrlEncoderMode(urlMode).match(location, uuid)
    );

    return {
      uuid,
      urlMatch,

      // detect if urlMatch is being deactived in URL
      ...!state.silentToggled === !state.urlMatch && {
        silentToggled: urlMatch,
      },
    };
  }

  componentDidMount() {
    this.mounted = true;

    // getters are not properly compiled
    // todo: check why hasMatch not work
    if (!this.state.silentToggled && this.checkLocationMatch()) {
      this.setState(
        {
          silentToggled: true,
          urlMatch: true,
        },
      );
    }
  }

  componentDidCatch(e) {
    console.error(e);
    this.onToggle(false);
  }

  get nonURLModal() {
    const {uuid} = this.state;

    return R.isNil(uuid);
  }

  get location() {
    const {history: {location}} = this.props;

    return location;
  }

  get mode() {
    const {urlMode} = this.props;

    return getUrlEncoderMode(urlMode);
  }

  onToggle = (e) => {
    const {urlMode, history, onToggle} = this.props;
    const {location, mode} = this;
    const {uuid} = this.state;

    suppressEvent(e);

    this.setState(
      prevState => ({
        silentToggled: !prevState.silentToggled,
      }),
      () => {
        onToggle(this.state.silentToggled);

        !this.nonURLModal && idleCallback.create(() => {
          history.replace(
            mode.encode(location, uuid),
            {
              ...location.state,
              ...urlMode?.state || null,
            },
          );
        });
      },
    );
  };

  onUnmount = (flags) => {
    const {urlMatch} = this.state;
    const {
      history,
      onUnmount,
    } = this.props;

    this.setState(
      {
        silentToggled: false,
      },
    );

    if (urlMatch) {
      setTimeout(() => {
        const {location, mode} = this;

        history.replace(
          mode.reset(location),
        );
      }, 40);
    }

    onUnmount?.(flags || {}); // eslint-disable-line
  }

  checkLocationMatch() {
    const {location, mode} = this;
    const {uuid} = this.state;

    return mode.match(location, uuid);
  }

  renderModal = (rendererProps) => {
    const {silentToggled} = this.state;
    const {
      wrapWithPortal,
      modalComponent: Modal,
      modalProps,
      modalRenderFn,
    } = this.props;

    return (
      modalRenderFn
        ? modalRenderFn(
          {
            ...modalProps,
            wrapWithPortal: silentToggled || wrapWithPortal,
            mounted: silentToggled,
            onUnmount: this.onUnmount,
            ...rendererProps,
          },
        )
        : (
          <Modal
            wrapWithPortal={silentToggled || wrapWithPortal}
            mounted={silentToggled}
            onUnmount={this.onUnmount}
            {...modalProps}
            {...rendererProps}
          />
        )
    );
  };

  render() {
    const {mounted, nonURLModal} = this;
    const {silentToggled} = this.state;
    const {
      triggerComponent: Component,
      modalComponent: Modal,
      modalProps,
      children,
      watchEvent,
      provideToggleStateProps,
      modalRenderFn,
      triggerRenderFn,
      history,
      staticContext,
      location,
      match,
      uuid,
      urlMode,
      renderNonMountedModal,
      wrapWithPortal,
      onUnmount,
      ...props
    } = this.props;

    const toggled = mounted && silentToggled;
    const providedProps = props;

    if (watchEvent)
      providedProps[watchEvent] = this.onToggle;

    if (provideToggleStateProps) {
      providedProps.toggled = toggled;
      providedProps.onToggle = this.onToggle;
    }

    const visibleModal = renderNonMountedModal || toggled;
    const portalWrapper = (
      wrapWithPortal
        ? content => <ModalEventSuppress>{content}</ModalEventSuppress>
        : R.identity
    );

    return (
      <>
        {(triggerRenderFn || Component) && (
          triggerRenderFn
            ? triggerRenderFn(providedProps)
            : (
              <Component {...providedProps}>
                {children}
              </Component>
            )
        )}

        {nonURLModal && visibleModal && portalWrapper(
          this.renderModal(),
        )}

        {!nonURLModal && visibleModal && portalWrapper(
          <Route
            path={history.location.pathname}
            render={() => this.renderModal()}
            exact
          />,
        )}
      </>
    );
  }
}
