import * as R from 'ramda';

export const decodeAddressCookie = R.when(
  R.is(String),
  R.tryCatch(
    R.compose(
      address => (
        R.isNil(address?.city?.id)
          ? null
          : address
      ),
      ::JSON.parse,
    ),
    R.always(null),
  ),
);

export const encodeAddressCookie = R.when(
  R.is(Object),
  ::JSON.stringify,
);
