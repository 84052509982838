import React from 'react';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import {DIRECTION} from '@ding/constants/src/schemas/basic';

import PopoverListToggle from '@ding/controls/src/PopoverToggle/PopoverListToggle';

export const HEADER_POPOVER_MARGINS = {
  [DIRECTION.RIGHT]: {
    marginRight: 20,
  },

  [DIRECTION.LEFT]: {
    marginLeft: 20,
  },

  [DIRECTION.TOP]: {
    marginLeft: 'initial',
    marginTop: 54,
  },

  [DIRECTION.BOTTOM]: {
    marginBottom: 54,
  },
};

const HeaderListPopover = provideProps(
  ({direction}) => ({
    provideActiveFlagAs: 'inverted',
    popoverStyle: HEADER_POPOVER_MARGINS[direction],
  }),
)(PopoverListToggle);

export default React.memo(HeaderListPopover);
