import {useMemo} from 'react';

import {gql, GraphQLFragment} from '@ding/tiny-gql';
import * as R from 'ramda';

import {FETCH_POLICY} from '@ding/tiny-gql/src/react/TinyGqlQuery';
import useQuery from '@ding/tiny-gql/src/react/hooks/useQuery';

const useSingleAccountSettingQuery = (settingFlag, config) => {
  const {
    query: SETTING_QUERY,
    responseSelector,
  } = useMemo(
    () => ({
      responseSelector: R.pathOr(false, ['customer', 'settings', settingFlag]),
      query: new GraphQLFragment(
        'customerQuery',
        gql`
          query customerQuery {
            customer {
              settings {
                ${settingFlag}
              }
            }
          }
        `,
      ),
    }),
    [settingFlag],
  );

  return useQuery(
    SETTING_QUERY,
    {
      clientFetchPolicy: FETCH_POLICY.NETWORK_ONLY,
      responseSelector,
      ...config,
    },
  );
};

export default useSingleAccountSettingQuery;
