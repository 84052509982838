import * as R from 'ramda';
import safeArray from '@adretail/basic-helpers/src/base/safeArray';

const safeFormatErrorsArray = R.compose(
  R.map(
    R.cond(
      [
        [R.isNil, R.always(null)],
        [R.is(String), R.objOf('message')],
        [R.has('message'), R.pick(['message', 'code'])],
      ],
    ),
  ),
  safeArray,
);

export const safeMapErrorsMessages = R.curry((fn, errors) => {
  if (!R.is(Array, errors))
    errors = [errors];

  return R.map(
    (err) => {
      if (R.has('message', err)) {
        return {
          ...err,
          message: fn(err.message),
        };
      }

      if (R.is(String, err))
        return fn(err);

      return err;
    },
    errors,
  );
});

export const hasErrors = r => (r && !!(r.error || r.errors));

const pickResponseErrors = (r) => {
  const list = (r && (r.error || r.errors)) || null;
  if (!list)
    return list;

  return safeFormatErrorsArray(list);
};

export default pickResponseErrors;
