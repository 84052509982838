import {gql, GraphQLFragment} from '@ding/tiny-gql';
import * as R from 'ramda';

import useQuery from '@ding/tiny-gql/src/react/hooks/useQuery';

const GUEST_PERSONALIZED_DATA_MIGRATION_QUERY = new GraphQLFragment(
  'guestPersonalizedDataQuery',
  gql`
    query guestPersonalizedDataQuery @locale(lang: ${APP_LANGUAGE}) {
      guestPersonalizedData {
        guestDataPresence
      }
    }
  `,
);

export const useGuestPersonalizedDataQuery = () => useQuery(
  GUEST_PERSONALIZED_DATA_MIGRATION_QUERY,
  {
    responseSelector: R.pathOr(null, ['guestPersonalizedData', 'guestDataPresence']),
  },
);

export default GUEST_PERSONALIZED_DATA_MIGRATION_QUERY;
