import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import useIntersectionObserver from '@adretail/basic-hooks/src/base/useIntersectionObserver';

const ViewportIntersectionToolbar = ({intersectionParams, onLoadNextChunk}) => {
  const elementRef = createRef();

  useIntersectionObserver(
    {
      rootRef: {
        current: null,
      },
      ref: elementRef,
      rootMargin: '600px',
      onIntersect: (intersection) => {
        if (intersection && onLoadNextChunk)
          onLoadNextChunk();
      },
      ...intersectionParams,
    },
  );

  return (
    <div ref={elementRef} />
  );
};

ViewportIntersectionToolbar.displayName = 'ViewportIntersectionToolbar';

ViewportIntersectionToolbar.propTypes = {
  onLoadNextChunk: PropTypes.func,
};

ViewportIntersectionToolbar.defaultProps = {
  onLoadNextChunk: R.always(null),
};

export const viewportIntersectionToolbarFn = ({chunkInfo, intersectionParams, onLoadNextChunk}) => (
  chunkInfo.remainItems
    ? (
      <ViewportIntersectionToolbar
        intersectionParams={intersectionParams}
        onLoadNextChunk={onLoadNextChunk}
      />
    )
    : null
);

export default ViewportIntersectionToolbar;
