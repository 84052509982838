import Loadable from 'react-loadable';
import LoadingSpinnerSectionErrorHandling from '@ding/core/src/components/Parts/LoadingSpinnerSectionErrorHandling';
import {DEFAULT_CHUNK_TIMEOUT} from './constants';

//NOTE: 404 chunks are created in @ding/routes/src/404/index.jsx
export const Home = Loadable(
  {
    loader: () => import(/* webpackChunkName: "home" */ './Home'),
    modules: ['./Home'],
    webpack: () => [require.resolveWeak('./Home')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const Leaflet = Loadable(
  {
    loader: () => import(/* webpackChunkName: "slide-viewer" */ './Leaflet'),
    modules: ['./Leaflet'],
    webpack: () => [require.resolveWeak('./Leaflet')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const Leaflets = Loadable(
  {
    loader: () => import(/* webpackChunkName: "leaflets" */ './Leaflets'),
    modules: ['./Leaflets'],
    webpack: () => [require.resolveWeak('./Leaflets')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const Contractors = Loadable(
  {
    loader: () => import(/* webpackChunkName: "contractors" */ './Contractors'),
    modules: ['./Contractors'],
    webpack: () => [require.resolveWeak('./Contractors')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const Contact = Loadable(
  {
    loader: () => import(/* webpackChunkName: "contact" */ './Contact'),
    modules: ['./Contact'],
    webpack: () => [require.resolveWeak('./Contact')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const Store = Loadable(
  {
    loader: () => import(/* webpackChunkName: "store" */ './Store'),
    modules: ['./Store'],
    webpack: () => [require.resolveWeak('./Store')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const StoresByCity = Loadable(
  {
    loader: () => import(/* webpackChunkName: "stores-by-city" */ './StoresByCity'),
    modules: ['./StoresByCity'],
    webpack: () => [require.resolveWeak('./StoresByCity')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const Tag = Loadable(
  {
    loader: () => import(/* webpackChunkName: "tag" */ './Tag'),
    modules: ['./Tag'],
    webpack: () => [require.resolveWeak('./Tag')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const Products = Loadable(
  {
    loader: () => import(/* webpackChunkName: "products" */ './Products'),
    modules: ['./Products'],
    webpack: () => [require.resolveWeak('./Products')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const Product = Loadable(
  {
    loader: () => import(/* webpackChunkName: "product" */ './Product'),
    modules: ['./Product'],
    webpack: () => [require.resolveWeak('./Product')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const Author = Loadable(
  {
    loader: () => import(/* webpackChunkName: "author" */ './Author'),
    modules: ['./Author'],
    webpack: () => [require.resolveWeak('./Author')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const Article = Loadable(
  {
    loader: () => import(/* webpackChunkName: "article" */ './Article'),
    modules: ['./Article'],
    webpack: () => [require.resolveWeak('./Article')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const Articles = Loadable(
  {
    loader: () => import(/* webpackChunkName: "articles" */ './Articles'),
    modules: ['./Articles'],
    webpack: () => [require.resolveWeak('./Articles')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const ShoppingSundays = Loadable(
  {
    loader: () => import(/* webpackChunkName: "shopping-sundays" */ './ShoppingSundays'),
    modules: ['./ShoppingSundays'],
    webpack: () => [require.resolveWeak('./ShoppingSundays')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const Search = Loadable(
  {
    loader: () => import(/* webpackChunkName: "search" */ './Search'),
    modules: ['./Search'],
    webpack: () => [require.resolveWeak('./Search')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const MobileApp = Loadable(
  {
    loader: () => import(/* webpackChunkName: "mobile-app" */ './MobileApp'),
    modules: ['./MobileApp'],
    webpack: () => [require.resolveWeak('./MobileApp')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const Contractor = Loadable(
  {
    loader: () => import(/* webpackChunkName: "contractor" */ './Contractor'),
    modules: ['./Contractor'],
    webpack: () => [require.resolveWeak('./Contractor')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const LatestContractorLeaflet = Loadable(
  {
    loader: () => import(/* webpackChunkName: "latest-contractor-leaflet" */ './LatestContractorLeafletRoute'),
    modules: ['./LatestContractorLeafletRoute'],
    webpack: () => [require.resolveWeak('./LatestContractorLeafletRoute')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const AccountFavourites = Loadable(
  {
    loader: () => import(/* webpackChunkName: "account-favourites" */ './AccountFavouritesRoute'),
    modules: ['./AccountFavouritesRoute'],
    webpack: () => [require.resolveWeak('./AccountFavouritesRoute')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const AccountSettings = Loadable(
  {
    loader: () => import(/* webpackChunkName: "account-settings" */ './AccountSettingsRoute'),
    modules: ['./AccountSettingsRoute'],
    webpack: () => [require.resolveWeak('./AccountSettingsRoute')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);

export const UnauthorizedDeleteAccount = Loadable(
  {
    loader: () => import(/* webpackChunkName: "unauthorized-delete-account" */ './UnauthorizedDeleteAccountRoute'),
    modules: ['./UnauthorizedDeleteAccountRoute'],
    webpack: () => [require.resolveWeak('./UnauthorizedDeleteAccountRoute')],
    loading: LoadingSpinnerSectionErrorHandling,
    timeout: DEFAULT_CHUNK_TIMEOUT,
  },
);
