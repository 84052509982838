import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import Text from '../Predefined/Text';

const SomethingGoneWrong = () => {
  const t = useTranslate();

  return (
    <Text.Center
      type='muted'
      block
    >
      {t('website.shared.sections.error.loading_error')}
    </Text.Center>
  );
};

SomethingGoneWrong.displayName = 'SomethingGoneWrong';

export default SomethingGoneWrong;
