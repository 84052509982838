import React from 'react';
import PropTypes from 'prop-types';

import {Margin} from '@ding/core/src/components/Predefined';
import {SubmitFormRow} from '@ding/control-groups/src/Form';

import {RecaptchaForm} from '../../Recaptcha';
import * as RegisterControls from './Controls';

import TransferDataCheckbox from './TransferDataCheckbox';
import {
  LoginPasswordGroup,
  AuthSubmitErrors,
} from '../Shared';

export const BasicAgreementAuthGroup = ({l, value, loading, errors, onChangeModalAuthTab}) => (
  <>
    <Margin top={3}>
      <TransferDataCheckbox {...l.input('transferGuestData')} />
      <RegisterControls.RegisterAgreementGroup {...l.input('agreement')} />
      <Margin
        top={3}
        bottom={2}
        block
      >
        <SubmitFormRow
          buttonComponent={RegisterControls.CreateAccountButton}
          loading={loading}
        />
      </Margin>
    </Margin>

    <AuthSubmitErrors
      email={value?.auth?.email}
      errors={errors}
      onChangeModalAuthTab={onChangeModalAuthTab}
    />

    <Margin
      top={2}
      bottom={5}
    >
      <RegisterControls.LawDescriptionLinks />
    </Margin>
  </>
);

BasicAgreementAuthGroup.displayName = 'BasicAgreementAuthGroup';

const RegisterBufferedControlGroup = ({errors, onChangeModalAuthTab, ...props}) => (
  <RecaptchaForm
    {...props}
    initialData={{
      transferGuestData: true,
    }}
    beforeSubmitValueMapper={({auth: {passwords, ...auth}, ...data}) => ({
      ...data,
      auth: {
        ...auth,
        password: passwords?.master || null,
      },
    })}
  >
    {loading => (l, value) => (
      <>
        <LoginPasswordGroup
          {...l.input('auth')}
          validatePassword
        />

        <BasicAgreementAuthGroup
          {...{
            loading,
            l,
            value,
            errors,
            onChangeModalAuthTab,
          }}
        />

        <Margin
          top={2}
          bottom={3}
        >
          <RegisterControls.ContactGroup />
          <RegisterControls.LawUsefulLinks />
        </Margin>

        <RegisterControls.CompanyIdentifiersRow />
      </>
    )}
  </RecaptchaForm>
);

RegisterBufferedControlGroup.displayName = 'RegisterBufferedControlGroup';

RegisterBufferedControlGroup.propTypes = {
  onChangeModalAuthTab: PropTypes.func,
};

export default React.memo(RegisterBufferedControlGroup);
