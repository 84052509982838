import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import * as R from 'ramda';

import {
  ITEM_TYPES,
  CONTRACTOR_SCHEMA,
  IMAGE_COMPONENT_PROPS,
} from '@ding/constants';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';

import NextGenPicture from '@ding/core/src/components/Parts/Images/NextGenPicture';
import {FavouriteItemSwitch} from '@ding/notifications/src/containers';
import {ContractorLink} from '@ding/layout/src/components/Links';
import {Flex, Text} from '@ding/core/src/components/Predefined';

import CardHolder from '../CardHolder';

/** @satisfies {{[x:string]: React.CSSProperties | {[x:string]: React.CSSProperties}}} */
const css = {
  contractor: {
    display: 'flex',
    maxWidth: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 20,
  },

  'contractor-small': {
    padding: 10,
  },

  logoContainer: {
    margin: '15px 0 25px',
    height: 55,
  },

  'logoContainer-small': {
    height: 45,
  },

  logoPicture: {
    textAlign: 'center',
  },

  logo: {
    width: 'auto',
    height: 'auto',
    maxWidth: '78%',
  },

  title: {
    justifyContent: 'space-between',
    fontWeight: '500',
    fontSize: 15,
    lineHeight: '24px', // button size
    margin: 0,
  },
};

/**
 * Skeleton placeholder for ProductCard component
 * @param {object} props - component props
 * @param {object} props.item - contractor data
 * @param {string} props.size - display lead fragment
 * @param {string} [props.headerTag=h3] - change header tag
 * @param {boolean} [props.hideFavouriteSwitch] - hide favorite contractor button
 * @param {boolean} [props.preloadImage] - preload image using NextGenPicture
 * @param {object} [props.imageProps] - pass props to NextGenPicture
 * @param {string} [props.className] - pass classes (used in ContractorSelectGroup to pass remove button)
 *
 * @see ContractorCardSkeleton - skeleton component
 * @returns ContractorCard component
 */
const ContractorCard = ({
  classes, className, size, children,
  hideFavouriteSwitch, headerTag,
  item: contractor,
  preloadImage, imageProps,
}) => {
  const t = useTranslate();

  return (
    <CardHolder
      title={contractor.name}
      aria-label={contractor.name}
      className={c(
        className,
        classes.contractor,
        size && classes[`contractor-${size}`],
      )}
      hoverable
      bordered
    >
      <ContractorLink contractor={contractor}>
        <Flex.Centered
          className={c(
            classes.logoContainer,
            size && classes[`logoContainer-${size}`],
          )}
        >
          <NextGenPicture
            pictureTagClassName={classes.logoPicture}
            className={classes.logo}
            src={contractor.logo}
            alt={t('website.shared.cards.contractor.logo_alt', contractor.name)}
            loading='lazy'
            preload={preloadImage}
            {...imageProps}
          />
        </Flex.Centered>

        <Flex.Centered className={classes.title} component={headerTag}>
          <Text truncate>
            {contractor.name}
          </Text>

          {!hideFavouriteSwitch && (
            <FavouriteItemSwitch
              kind={ITEM_TYPES.CONTRACTOR}
              item={contractor}
            />
          )}
        </Flex.Centered>
      </ContractorLink>

      {children}
    </CardHolder>
  );
};

ContractorCard.displayName = 'ContractorCard';

ContractorCard.propTypes = {
  hideFavouriteSwitch: PropTypes.bool,
  item: CONTRACTOR_SCHEMA.isRequired,
  size: PropTypes.oneOf(['small', 'auto']),
  headerTag: PropTypes.string,
  // image
  preloadImage: PropTypes.bool,
  imageProps: IMAGE_COMPONENT_PROPS,
};

ContractorCard.defaultProps = {
  headerTag: 'h3',
};

export default R.compose(
  React.memo,
  injectClassesSheet(css, {index: 1}),
)(ContractorCard);
