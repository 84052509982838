export default {
  titles: {
    settings: 'Ustawienia',
    profile: 'Profil',
    favourites: 'Ulubione',
    account_label: 'Konto:',
  },

  toast: {
    add_list_error: 'Na liście znajduje się element o podanej nazwie!',
  },

  unauthorized_delete_account: {
    header: 'Zaloguj się aby usunąć konto',
    text: 'Jeśli chcesz usunąć swoje konto Ding, zaloguj się, a następnie przejdź do zakładki "Ustawienia" w menu swojego konta',
  },

  sections: {
    delete_account: {
      header: 'Usuń konto',
      sad_header: 'Usuń konto :(',
      are_you_sure: 'Czy aby na pewno? :)',
      description: 'Jeśli usuniesz swoje konto, stracisz dostęp do listy zakupowej i nie będziesz otrzymywać powiadomień o promocjach z Twoich ulubionych sieci :(',
      form: {
        account_password: 'Hasło do konta',
        delete_account: 'Usuń konto',
      },
      toast: {
        success: 'Konto zostało usunięte :(',
        error: 'Błąd podczas usuwania konta!',
      },
    },

    email_nick: {
      header: 'Login / E-mail',

      toast: {
        success: 'Wysłano link z potwierdzeniem pod nowy adres e-mail!',
        error: 'Nastąpił problem podczas zmiany e-mail :(',
      },

      form: {
        nick: 'Nazwa użytkownika',
        old_email: 'Twój aktualny e-mail',
        new_email: 'Twój nowy e-mail',
        send_confirm_email: 'Wyślij e-mail z potwierdzeniem',
        equal_emails: 'Podany adres e-mail jest taki sam jak poprzedni!',
      },

      labels: {
        current_email_address: 'Twój aktualny adres email:',
        nick: 'Nazwa użytkownika może być np. przydatna, gdy udostępnisz komuś listę zakupową',
        confirm_email_warn: 'Uwaga! Na podany adres email zostanie wysłany e-mail z prośbą o potwierdzenie adresu. Zmiana nastąpi po kliknięciu w link zawarty w wiadomości.',
      },
    },

    favourite_contractors: {
      header: 'Ulubione sklepy',
      create_list: 'Stwórz listę ulubionych sieci, aby otrzymywać powiadomienia o najnowszych ofertach!',
    },

    favourite_products: {
      header: 'Ulubione produkty',
      create_list: 'Stwórz listę ulubionych lub poszukiwanych produktów, a my powiadomimy Cię o nowych ofertach!',
      example_list: 'Możesz wprowadzić dowolne słowa, na przykład: telefon, Samsung, gitara, spodnie itp.',
    },

    notification_settings: {
      header: 'Ustawienia powiadomień',

      shared: {
        at_hour: 'o godzinie',
      },

      triggers: {
        title: 'Chcę otrzymywać powiadomienia o następujących zdarzeniach:',
        product_expires: 'Gdy kończy się oferta na ulubiony produkt %{} dni przed',
        holiday: 'Gdy zbliża się niedziela lub święto z zakazem handlu w następujące dni:',
      },
    },

    language_settings: {
      header: 'Język serwisu',
      toast: {
        success: 'Pomyślnie zmieniono język serwisu',
        error: 'Wystąpił błąd podczas zmiany języka',
      },
      triggers: {
        title: 'Wybierz język serwisu:',
        subtitle: 'Język',
        warning: '(Zmiana języka spowoduje odświeżenie strony)',
      },
    },

    change_password: {
      header: 'Hasło',

      form: {
        old_password: 'Stare hasło',
        new_password: 'Nowe hasło',
        repeat_new_password: 'Powtórz nowe hasło',
      },

      toast: {
        success: 'Zmieniono hasło, wylogowano!',
        error: 'Nastąpił problem podczas zmiany hasła :(',
      },
    },
  },
};
