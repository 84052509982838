import React from 'react';
import createIcon from '../utils/createIcon';

export default createIcon(
  'Leaflets',
  {
    viewBox: '0 0 24 24',
  },
  <>
    <rect
      width='24'
      height='24'
      fill='none'
    />
    <path
      d='M19.853,3.5l-7.968.926L4.147,3.5A2.035,2.035,0,0,0,2.114,5.533V18.467A2.037,2.037,0,0,0,4.155,20.5h.01l7.868-.924,7.82.924a2.035,2.035,0,0,0,2.033-2.033V5.533A2.035,2.035,0,0,0,19.853,3.5Zm.735,14.967a.737.737,0,0,1-.673.74L12.247,18.3V14.235h-.865v4.118l-7.268.853a.737.737,0,0,1-.7-.738L3.407,5.533a.737.737,0,0,1,.672-.739l7.3.874V10.4h.865V5.682L19.888,4.8a.736.736,0,0,1,.7.737Z'
      fill='currentColor'
    />
  </>,
);
