import React from 'react';
import PropTypes from 'prop-types';

import {UndecoratedLink} from '@ding/core/src/components/Predefined';
import useSearchItemRedirectHandler from '../../hooks/useSearchItemRedirectHandler';

const SearchLink = ({item, children, onClick}) => {
  const redirectToItem = useSearchItemRedirectHandler();

  const handleClick = async (e) => {
    e?.preventDefault(); // eslint-disable-line no-unused-expressions

    onClick?.(item); // eslint-disable-line no-unused-expressions
    redirectToItem(item);
  };

  return (
    <UndecoratedLink
      role='button'
      to='#'
      onClick={handleClick}
    >
      {children}
    </UndecoratedLink>
  );
};

SearchLink.propTypes = {
  item: PropTypes.any.isRequired,
};

export default React.memo(SearchLink);
