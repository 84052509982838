import * as R from 'ramda';

export const allFlagsPass = R.compose(
  R.all(R.equals(true)),
  R.values,
);

/**
 * @example
 *  const str = 'abc';
 *
 *  validateToFlags(
 *    {
 *      str: R.equals('abc'),
 *      str2: R.is(Number),
 *    },
 *    str,
 *  ); //=> {str: true, str2: false}
 */
const validateToFlags = R.curry(
  (flags, value) => (
    value
      ? R.mapObjIndexed(R.applyTo(value), flags)
      : {}
  ),
);

export default validateToFlags;
