import React from 'react';
import PropTypes from 'prop-types';

import {
  DARK,
  WHITE,
  LIGHT_BORDER,
  HOVERED_LIGHT_BORDER,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import Button from '@ding/controls/src/Button';
import {UndecoratedLink} from '@ding/core/src/components/Predefined';

const PaginationButton = styled(
  Button,
  {
    base: {
      background: LIGHT_BORDER,
      borderRadius: 6,
      ...createBreakpointClasses(
        {
          xs: {
            padding: 0,
            width: 40,
            height: 40,
          },

          md: {
            width: 50,
            height: 50,
          },
        },
      ),

      '&:hover': {
        background: HOVERED_LIGHT_BORDER,
      },
    },

    active: {
      background: DARK,
      color: WHITE,

      '&:hover': {
        background: DARK,
        color: WHITE,
      },
    },
  },
  {
    index: 1,
    omitProps: ['active'],
    classSelector: (classes, {active}) => active && classes.active,
  },
);

PaginationButton.displayName = 'PaginationButton';

const PaginationItemHolder = styled.li(
  {
    display: 'inline-block',
    ...createBreakpointClasses(
      {
        xs: {
          margin: '0 3px !important',
        },

        md: {
          margin: '0 5px !important',
        },
      },
    ),
  },
);

PaginationItemHolder.displayName = 'PaginationItemHolder';

const PaginationItem = ({
  active,
  number,
  genLinkPropsFn,
}) => {
  const t = useTranslate('website.pagination');
  const title = t('page', number);

  let content = (
    <PaginationButton active={active} title={title} aria-label={title} {...active && {'aria-current': 'true'}}>
      {number}
    </PaginationButton>
  );

  if (genLinkPropsFn) {
    content = (
      <UndecoratedLink {...genLinkPropsFn(number)}>
        {content}
      </UndecoratedLink>
    );
  }

  return (
    <PaginationItemHolder>
      {content}
    </PaginationItemHolder>
  );
};

PaginationItem.displayName = 'PaginationItem';

PaginationItem.propTypes = {
  number: PropTypes.number.isRequired,
  active: PropTypes.bool,
  totalPages: PropTypes.number,
  genLinkPropsFn: PropTypes.func,
  aria: PropTypes.objectOf(PropTypes.any),
};

export default PaginationItem;
