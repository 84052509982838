import {gql, GraphQLFragment} from '@ding/tiny-gql';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import DefaultTinyQuery from '@ding/control-groups/src/DefaultTinyQuery';

import ContractorFragment from './fragments/Contractor.graphql';
import CustomerFlagsFragment from './fragments/CustomerFlags.graphql';

export const CONTRACTORS_QUERY = new GraphQLFragment(
  'contractorsQuery',
  gql`
    ${ContractorFragment}
    ${CustomerFlagsFragment}
    query contractorsQuery(
      $offset: Int,
      $page: Int = 1,
      $limit: Int = 12,
      $filters: ContractorListFilters,
      $phrase: String,
      $orderBy: ContractorListSort,
      $order: SortOrder = ASC,
      $withCustomerFlags: Boolean = true
    ) @locale(lang: ${APP_LANGUAGE}) {
      contractors(
        page: $page,
        offset: $offset,
        limit: $limit,
        filters: $filters,
        phrase: $phrase,
        orderBy: $orderBy,
        order: $order,
      ) {
        items {
          ...Contractor
          customerFlags @include(if: $withCustomerFlags) {
            ...CustomerFlags
          }
        }
        totalPages
        totalItems
      }
    }
  `,
);

export const ContractorsQuery = provideProps(
  {
    query: CONTRACTORS_QUERY,
    responseSelector: 'contractors',
    cacheKey: {
      expire: 0,
    },
  },
)(DefaultTinyQuery);

export default CONTRACTORS_QUERY;
