import PropTypes from 'prop-types';
import * as R from 'ramda';

import {useAuthState} from './AuthContext';

const AuthConditionalRender = ({
  guestOnly,
  loggedOnly,
  children,
  authorizeFn,
  authorizedRenderFn = children,
  unauthorizedRenderFn,
}) => {
  const authState = useAuthState();

  if (authorizeFn) {
    if (authorizeFn(authState))
      return authorizedRenderFn(authState);

    return unauthorizedRenderFn(authState);
  }

  const guest = authState.isGuest();
  return (
    (guestOnly && guest) || (!guestOnly && loggedOnly && !guest)
      ? authorizedRenderFn(authState)
      : unauthorizedRenderFn(authState)
  );
};

AuthConditionalRender.displayName = 'AuthConditionalRender';

AuthConditionalRender.propTypes = {
  guestOnly: PropTypes.bool,
  loggedOnly: PropTypes.bool,
  authorizeFn: PropTypes.func,

  // renderers
  authorizedRenderFn: PropTypes.func,
  unauthorizedRenderFn: PropTypes.func,
  children: PropTypes.any,
};

AuthConditionalRender.defaultProps = {
  loggedOnly: true,
  unauthorizedRenderFn: R.always(null),
  authorizedRenderFn: R.always(null),
};

export default AuthConditionalRender;
