import React from 'react';
import PropTypes from 'prop-types';
import LazyHydrate from 'react-lazy-hydration';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {EUBadgeImage} from '@ding/eu';
import {
  Margin,
  Grid,
  Flex,
  PageContainer,
} from '@ding/core/src/components/Predefined';

import CopyrightBar from './CopyrightBar';
import FooterDescription from './FooterDescription';
import * as Columns from './Columns';

const Footer = ({breadcrumbs}) => {
  const t = useTranslate();

  return (
    <LazyHydrate whenVisible>
      {breadcrumbs && (
        <PageContainer component='nav' aria-label='Breadcrumb'>
          <Margin top={4} block>{breadcrumbs}</Margin>
        </PageContainer>
      )}

      <footer>
        <PageContainer>
          <Margin
            top={5}
            bottom={6}
            block
          >
            <Grid>
              <Columns.Navigation t={t} />
              <Columns.Contractors t={t} />
            </Grid>

            <FooterDescription />

            <Flex.Centered>
              <Margin top={1}>
                <EUBadgeImage />
              </Margin>
            </Flex.Centered>
          </Margin>

        </PageContainer>

        <CopyrightBar />
      </footer>
    </LazyHydrate>
  );
};

Footer.displayName = 'Footer';

Footer.propTypes = {
  breadcrumbs: PropTypes.node,
};

export default React.memo(Footer);
