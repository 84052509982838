import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {ERRORS_LIST_SCHEMA} from '@ding/constants/src/typeSchema';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {ErrorsList} from '@ding/core/src/components/Parts/Lists';
import {
  Text,
  Margin,
} from '@ding/core/src/components/Predefined';

import FormRow from './FormRow';

export const mapErrorsList = ({t, codeI18n, errors}) => R.compose(
  R.reject(R.isNil),
  R.map(
    (err) => {
      if (R.isNil(err) || R.is(String, err))
        return err;

      if (R.has('code', err)) {
        const mapper = codeI18n[err.code];
        if (mapper) {
          return (
            <span>
              {mapper(t)}
            </span>
          );
        }
      }

      return err.message || null;
    },
  ),
)(errors);

const FormSubmitErrors = ({codeI18n, errors}) => {
  const t = useTranslate('website.errors.form.submit');
  if (!errors || R.isEmpty(errors))
    return null;

  const defaultErrorMessage = R.is(Boolean, errors);

  const list = (
    defaultErrorMessage
      ? [t('default')]
      : mapErrorsList(
        {
          t,
          codeI18n,
          errors,
        },
      )
  );

  return (
    <FormRow>
      <Margin
        bottom={2}
        expanded
        block
      >
        <Text.Muted size='small'>
          {`${t(list.length > 1 ? 'multiple' : 'single')}:`}
        </Text.Muted>
      </Margin>

      <Margin bottom={3}>
        <ErrorsList list={list} />
      </Margin>
    </FormRow>
  );
};

FormSubmitErrors.displayName = 'FormSubmitErrors';

FormSubmitErrors.propTypes = {
  errors: ERRORS_LIST_SCHEMA,
  codeI18n: PropTypes.objectOf(PropTypes.func),
};

FormSubmitErrors.defaultProps = {
  codeI18n: {},
};

export default FormSubmitErrors;
