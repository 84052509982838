import React from 'react';
import PropTypes from 'prop-types';

import WidePopup from './WidePopup';

const MobileWidePopup = ({children, onUnmount, fullPage, ...props}) => (
  <WidePopup
    onUnmount={onUnmount}
    paddingSize='none'
    fullPage={fullPage}
    // NOTE: Fix a bug on mobile google chrome
    // After SlideToUpAppear animation, div with transition style have to contains height 100%
    // Otherwise, after scroll, hidden by default google chrome URL address bar will be come over
    // on WidePopup
    {...fullPage && {style: {height: '100%'}}}
    {...props}
  >
    {children}
  </WidePopup>
);

MobileWidePopup.displayName = 'MobileWidePopup';

MobileWidePopup.propTypes = {
  fullPage: PropTypes.bool,
};

MobileWidePopup.defaultProps = {
  fullPage: true,
};

export default MobileWidePopup;
