import React from 'react';
import PropTypes from 'prop-types';

import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';

import FormRow from '@ding/control-groups/src/Form/FormRow';
import {PredefinedInputs} from '@ding/controls/src/';
import {RecaptchaLinkRow} from '../../Recaptcha';
import EnterPasswordsGroup from '../../Inputs/EnterPasswordsGroup';

/**
 * Do not rename inputs, it is used in RegisterMutation, LoginMutation
 * as auth key
 *
 * @export
 */
const LoginPasswordGroup = ({l, validatePassword}) => {
  let passwordElement = null;

  if (validatePassword) {
    passwordElement = (
      <EnterPasswordsGroup {...l.input('passwords')} />
    );
  } else {
    const PasswordInput = PredefinedInputs[validatePassword ? 'ValidatedPassword' : 'Password'];

    passwordElement = (
      <FormRow>
        <PasswordInput
          required
          {...l.input('password')}
        />
      </FormRow>
    );
  }

  return (
    <>
      <FormRow>
        <PredefinedInputs.EMail
          required
          {...l.input('email')}
        />
      </FormRow>

      {passwordElement}

      <FormRow>
        <RecaptchaLinkRow />
      </FormRow>
    </>
  );
};

LoginPasswordGroup.displayName = 'LoginPasswordGroup';

LoginPasswordGroup.propTypes = {
  validatePassword: PropTypes.bool,
};

export default linkedInputs(
  {
    groupName: 'value',
    singleValueProp: null,
  },
)(LoginPasswordGroup);
