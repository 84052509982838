import React from 'react';
import PropTypes from 'prop-types';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';

import {Grid} from '@ding/core/src/components/Predefined';
import PrimaryHeader from './PrimaryHeader';

const css = {
  column: createBreakpointClasses({
    xs: {
      '&:not(:last-child)': {
        marginBottom: 28,
      },
    },

    md: {
      marginBottom: 0,
    },
  }),
};

const FooterColumn = ({classes, children, title, ...props}) => (
  <Grid.Column
    className={classes.column}
    xs={12}
    md={6}
    lg={4}
    {...props}
  >
    {title && (
      <PrimaryHeader>
        {title}
      </PrimaryHeader>
    )}

    {children}
  </Grid.Column>
);

FooterColumn.displayName = 'FooterColumn';

FooterColumn.propTypes = {
  title: PropTypes.string,
};

export default injectClassesSheet(css)(FooterColumn);
