import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'Search',
  {
    viewBox: '0 0 22 22',
  },
  <path d='M22,20.6l-6.15-6.15a8.91,8.91,0,1,0-1.4,1.4L20.6,22ZM2,8.9a6.9,6.9,0,1,1,6.9,6.9A6.91,6.91,0,0,1,2,8.9Z' />,
);
