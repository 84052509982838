import useReactRouter from '@ding/core/src/hooks/useReactRouter';
import {FRIENDLY_TABS_NAMES, genSearchUrl} from '@ding/layout/src/components/Links/SearchLink';

const useSearchItemRedirectHandler = () => {
  const {history} = useReactRouter();

  return (item) => {
    const newUrl = genSearchUrl({
      tab: FRIENDLY_TABS_NAMES.ALL,
      page: '1',
      phrase: item.name,
    });

    history.push(`${newUrl}#iwa_source=szukaj_tag`);
  };
};

export default useSearchItemRedirectHandler;
