import React from 'react';
import * as R from 'ramda';

import b64 from '@adretail/basic-helpers/src/base/base64';
import ssr from '@adretail/basic-helpers/src/base/ssr';
import createContextPack from '@ding/tiny-context-state';

const loadChunksRoutes = () => {
  const encodedChunks = !ssr && document.getElementById('chunks-routes')?.innerText;
  if (!encodedChunks) {
    return {};
  }

  return JSON.parse(
    b64.atob(encodedChunks),
  );
};

const {
  Provider,
  useStateContext,
} = createContextPack({
  selectors: {
    getChunksRoutes: R.always(R.prop('chunksRoutes')),
  },
});

export const useChunksRoutesState = useStateContext;

export const ChunksRoutesProvider = ({children}) => (
  <Provider
    initialState={{
      chunksRoutes: loadChunksRoutes(),
    }}
  >
    {children}
  </Provider>
);
