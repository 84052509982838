import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import {
  JssNsfwCaptureClick,
  JssNsfwLockContent,
} from 'nsfw-utils/variants/jss';

import {PROPS_SCHEMA} from '@adretail/schemas';
import {LIGHT_BORDER} from '@ding/constants/src/colorSchema';
import {
  LEAFLET_CARD_SCHEMA,
  SIZE_ARRAY_SCHEMA,
  IMAGE_COMPONENT_PROPS,
} from '@ding/constants/src/typeSchema';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {useGqlClient} from '@ding/tiny-gql/src/react/hooks';
import {useTrackers} from '@ding/interia';

import {preloadLeaflet} from '@ding/slide-viewer/src/Viewer/gql/LeafletQuery';

import {UndecoratedLink} from '@ding/core/src/components/Predefined';
import LeafletLink from '@ding/layout/src/components/Links/LeafletLink';
import LeafletJsonLD from '../SEO/LeafletJsonLD';

import CardHolder from '../../CardHolder';
import ContractorLogoBar from './ContractorLogoBar';
import LeafletTitleBar from './LeafletTitleBar';
import LeafletDurationBar from './LeafletDurationBar';
import LeafletArchivedLayer from './LeafletArchivedLayer';
import LeafletCover from './LeafletCover';
import Ribbon from './Ribbon';
import LeafletCardTitle from './LeafletCardTitle';

/** @satisfies {{[x:string]: React.CSSProperties | {[x:string]: React.CSSProperties}}} */
const css = {
  leaflet: {
    position: 'relative',
    width: '100%',
    padding: 4,
    overflow: 'hidden',

    '& div[data-nsfw-layer] > span': {
      zIndex: 'initial !important',
    },
  },

  // NOTE: DEADCODE: this style is unused
  noPaddingBottom: {
    paddingBottom: [0, '!important'],
  },

  // NOTE: DEADCODE: this style is unused
  bordered: {
    border: `1px solid ${LIGHT_BORDER}`,
  },
};

/**
 * @note variant size='tiny' is inconsistent with itself because of baseline and font sizes, depend of other props and theirs values rendered size may differ between others cards in same row
 *
 * @todo TODO: component is in state where need to be redesigned, size and behavior i quite unpredictable
 *
 * @see LeafletCardSkeleton - skeleton component
 */
const LeafletCard = ({
  spaced, className, classes,
  size,
  showTitles,
  showDate,
  dateFormat,
  durationProps,
  showLogo,
  grayscaleArchived,
  background,
  bordered,
  rounded,
  linkProps,
  prefixedContractorTitle,
  item: leaflet,
  title,
  headerTag,
  onClick,
  imageProps,
  preloadImage,
}) => {
  const t = useTranslate();
  const preloaded = useRef();
  const gqlClient = useGqlClient();
  const {ga4} = useTrackers();

  const {archived, contractor, originPage} = leaflet;
  const cover = (
    originPage
      ? originPage.image
      : leaflet.cover
  );
  const nsfwFlag = originPage?.flags || leaflet;
  const LinkComponent = contractor ? LeafletLink : UndecoratedLink;

  let linkContent = [
    <JssNsfwCaptureClick
      item={nsfwFlag}
      key='image'
    >
      {cover && (
        <JssNsfwLockContent
          lang={APP_LANGUAGE}
          data={nsfwFlag}
          layerAsChild
          small={size === 'tiny'}
          onAcceptedNSFW={() => ga4.acceptNSFWModalLeaflet({leafletId: leaflet.id})}
          stopEventPropagation
        >
          {(sharedProps, {layer, hasNsfwLock}) => (
            <LeafletCover
              src={!hasNsfwLock && cover}
              size={size}
              alt={leaflet.name}
              preload={preloadImage}
              {...imageProps}
            >
              {layer}
            </LeafletCover>
          )}
        </JssNsfwLockContent>
      )}

      {showLogo && (
        <ContractorLogoBar
          // NOTE: BUG: do not use responsiveData this will brake margin in this component and some other card styles
          size={size}
          {...contractor ? {
            src: contractor.logo,
            alt: contractor.name,
          } : {
            src: leaflet.logo,
            alt: leaflet.name,
          }}
        />
      )}

      {/* NOTE: this is only for selected leaflets inside leaflet-viewer to show what is current viewed */}
      {/* NOTE: BUG: because how baseline work this don't have same size as what this is replacing */}
      {/* NOTE: BUG: same bug with last item like in ContractorLogoBar */}
      {title && (
        <LeafletCardTitle size={size} component={headerTag}>
          {title}
        </LeafletCardTitle>
      )}

      {(showDate || showTitles) && (
        <LeafletDurationBar
          dateFormat={dateFormat || (size === 'small' ? 'short' : 'long')}
          leaflet={leaflet}
          {...size === 'tiny' && {style: {fontSize: 11}}}
          {...durationProps}
        />
      )}
      {showTitles && (
        <LeafletTitleBar
          prefixedTitle={prefixedContractorTitle}
          {...{
            t,
            leaflet,
          }}
          {...!title && {
            tagName: headerTag,
          }}
        />
      )}

    </JssNsfwCaptureClick>,

    archived && !grayscaleArchived && (
      <LeafletArchivedLayer key='archived-layer' />
    ),

    archived && grayscaleArchived && (
      <LeafletArchivedLayer.GrayScale key='archived-layer' />
    ),
  ];

  if (!archived) {
    linkContent = (
      <LinkComponent
        {...contractor ? {leaflet} : {to: leaflet.link}}
        {...linkProps}
      >
        {linkContent}
      </LinkComponent>
    );
  }

  const onHover = () => {
    if (preloaded.current)
      return;

    preloadLeaflet(
      {
        client: gqlClient,
        leafletId: leaflet.id,
      },
    );
    preloaded.current = true;
  };

  return (
    <CardHolder
      title={leaflet.name}
      aria-label={leaflet.name}
      spaced={spaced}
      rounded={rounded}
      background={background}
      bordered={bordered}
      className={c(
        classes.leaflet,
        // classes[`leaflet-${size}`], // DEADCODE: this do nothing
        className,
      )}
      hoverable={!archived}
      onMouseEnter={onHover}
      onClick={onClick}
    >
      {linkContent}

      {!archived && leaflet.hit && (
        <Ribbon
          color='green'
          small={
            size === 'small'
          }
        >
          {t('website.shared.cards.leaflet.ribbons.hit')}
        </Ribbon>
      )}

      <LeafletJsonLD leaflet={leaflet} />
    </CardHolder>
  );
};

LeafletCard.displayName = 'LeafletCard';

LeafletCard.propTypes = {
  background: PropTypes.string,
  bordered: PropTypes.bool,
  rounded: PropTypes.bool,
  spaced: PropTypes.bool,
  showTitles: PropTypes.bool,
  showDate: PropTypes.bool,
  showLogo: PropTypes.bool,
  dateFormat: PropTypes.string,
  grayscaleArchived: PropTypes.bool,
  prefixedContractorTitle: PropTypes.bool,
  item: LEAFLET_CARD_SCHEMA.isRequired,
  size: PropTypes.oneOfType([
    SIZE_ARRAY_SCHEMA,
    PropTypes.oneOf([
      'square-small',
    ]),
  ]),
  linkProps: PROPS_SCHEMA,
  title: PropTypes.node,
  headerTag: PropTypes.string,
  durationProps: PROPS_SCHEMA,
  // image
  imageProps: IMAGE_COMPONENT_PROPS,
  preloadImage: PropTypes.bool,
};

LeafletCard.defaultProps = {
  spaced: false,
  bordered: true,
  showTitles: true,
  showDate: false,
  showLogo: true,
  grayscaleArchived: false,
  rounded: true,
  prefixedContractorTitle: true,
  // TODO: 'auto' is unused and JssNsfwLockContent brake layout
  size: 'small',
  headerTag: 'h3',
};

const CSSLeafletCard = injectClassesSheet(css, {index: 1})(LeafletCard);

export const MemoizedLeafletCard = React.memo(CSSLeafletCard);

export default CSSLeafletCard;
