import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import translate from '@adretail/i18n/src/decorators/i18n';
import modalMounter from '@adretail/basic-helpers/src/ui/modalMounter';

import {getHOCDisplayName} from '@adretail/basic-helpers/src/getters/getDisplayName';
import applyIfFunction from '@adretail/basic-helpers/src/base/applyIfFunction';

import {useUA} from '@adretail/basic-components/src/Context/UAProvider';

import URLModalTrigger from './URLModalTrigger';

import * as Modals from './Modals';

export {
  URLModalTrigger,
};

export const ResponsiveModalContainer = ({
  wide, sidebar, popup,
  uaModalPropsFn, children, ...props
}) => {
  const ua = useUA();

  let ModalContainer = null;
  if (ua.mobile && !popup)
    ModalContainer = Modals.MobileFullscreenPopup;

  else if (sidebar)
    ModalContainer = Modals.DesktopSidebar;

  else if (wide)
    ModalContainer = Modals.WidePopup;

  else
    ModalContainer = Modals.DesktopPopup;

  return (
    <ModalContainer
      {...props}
      {...uaModalPropsFn && uaModalPropsFn(ua)}
    >
      {onClose => children && children(onClose, ua)}
    </ModalContainer>
  );
};

export const mountResponsiveModal = props => modalMounter(
  {
    ...props,
    modalComponent: ResponsiveModalContainer,
  },
);

/**
 * Some modals are separated but contains the same content.
 * ResponsiveModalToggle autodetects environment and displays
 * modal suited for specified UA
 *
 * @export
 */
const ResponsiveModalToggle = ({
  modalContentFn,
  uaModalPropsFn,
  sidebar,
  wide,
  popup,
  ...props
}) => (
  <URLModalTrigger
    {...props}
    modalRenderFn={
      modalProps => (
        <ResponsiveModalContainer
          {...{
            wide,
            sidebar,
            popup,
            uaModalPropsFn,
          }}
          {...modalProps}
        >
          {modalContentFn}
        </ResponsiveModalContainer>
      )
    }
  />
);

ResponsiveModalToggle.propTypes = {
  sidebar: PropTypes.bool,
  wide: PropTypes.bool, // modal on desktop has 100% page width
  uaModalPropsFn: PropTypes.func, // props passed to ModalContainer based on ua
  modalContentFn: PropTypes.func.isRequired,
};

/**
 * Just wrap less advanced components to provide modal
 * on e.g. click on it. Modal props is passed to ResponsiveModalContainer
 *
 * @example
  * withResponsiveModal(
    {
      modalContentComponent: GeolocationModalContent,
      toggleProps: () => ({
        modalProps: {
          title: 'Test modal',
        },
      }),
    },
  )
 */
export const withResponsiveModal = ({
  toggleProps,
  modalContentFn,
  modalContentComponent: ModalContent,
  toggleComponent: ToggleComponent = ResponsiveModalToggle,
}) => (Component) => {
  const Wrapped = (props) => {
    const defaultProps = applyIfFunction([props], toggleProps);

    return (
      <ToggleComponent
        {...defaultProps}
        {...props}
        modalProps={{
          ...defaultProps?.modalProps,
          ...props.modalProps,
        }}
        triggerComponent={Component}
        modalContentFn={
          (onClose, ua) => (
            modalContentFn
              ? modalContentFn(onClose, ua, props)
              : <ModalContent ua={ua} onClose={onClose} />
          )
        }
      />
    );
  };

  Wrapped.displayName = getHOCDisplayName('withResponsiveModal', Component);

  return React.memo(Wrapped);
};

export const withSidebarModal = ({
  toggleProps,
  modalContentComponent,
}) => R.compose(
  translate,
  withResponsiveModal(
    {
      modalContentComponent,
      toggleProps: props => ({
        sidebar: true,
        provideToggleStateProps: true,
        ...toggleProps(props),
      }),
    },
  ),
);

export default ResponsiveModalToggle;
