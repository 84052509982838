import React from 'react';

import {MIN_CUSTOMER_AGE} from '@ding/constants';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {getTodayDateDiff} from '@adretail/basic-helpers/src/time/utils';

import FormRow from '@ding/control-groups/src/Form/FormRow';
import BirthDateSelect from '@ding/control-groups/src/Form/Groups/BirthDateSelect';
import {ValidatedInput} from '@ding/controls/src/';
import {
  Margin,
  Text,
} from '@ding/core/src/components/Predefined';

const CustomerAgeValidatedRow = ({required, ...props}) => {
  const t = useTranslate();

  return (
    <FormRow
      title={
        t('website.auth.labels.your_birth_date')
      }
    >
      <ValidatedInput
        showErrorMessage={false}
        validatorFn={
          value => !!value && (
            // todo: not every year has 365 days
            getTodayDateDiff(value.date, false) >= MIN_CUSTOMER_AGE * 365
          )
        }
        inputRenderFn={
          ({error, ...inputProps}) => (
            <>
              <BirthDateSelect
                {...inputProps}
                required={required}
              />

              <Margin top={2}>
                <Text
                  size='small'
                  {...(
                    error
                      ? {type: 'primary', weight: 600}
                      : {type: 'default'}
                  )}
                >
                  {t('website.auth.labels.minimum_age', MIN_CUSTOMER_AGE)}
                </Text>
              </Margin>
            </>
          )
        }
        {...props}
      />
    </FormRow>
  );
};

CustomerAgeValidatedRow.displayName = 'CustomerAgeValidatedRow';

export default CustomerAgeValidatedRow;
