import React from 'react';

import {AuthConditionalRender} from '@ding/auth/src/client';
import * as AuthButtons from '@ding/auth/src/client/Buttons';

import LoginMenuItem from './LoginMenuItem';
import LoggedMenuItem from './LoggedMenuItem';

const AuthMenuItem = () => (
  <AuthConditionalRender
    loggedOnly
    authorizedRenderFn={
      () => (
        <LoggedMenuItem />
      )
    }
    unauthorizedRenderFn={
      () => (
        <AuthButtons.Login triggerComponent={LoginMenuItem} />
      )
    }
  />
);

AuthMenuItem.displayName = 'AuthMenuItem';

export default React.memo(AuthMenuItem);
