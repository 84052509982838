import React from 'react';
import PropTypes from 'prop-types';

import renderFunctionalChildren from '@adretail/basic-helpers/src/ui/renderFunctionalChildren';

import UserIcon from '@ding/core/src/icons/User';
import {IconText} from '@ding/core/src/components/Predefined';

import {showPromiseToast} from '../showToast';

export const UserIconText = ({title, ...props}) => (
  <IconText
    title={
      renderFunctionalChildren(title, props)
    }
    icon={(
      <UserIcon
        type='light'
        size='medium'
      />
    )}
  />
);

UserIconText.displayName = 'UserIconText';

UserIconText.propTypes = {
  title: PropTypes.any,
};

const showUserToast = (title, props, promiseFn) => showPromiseToast(
  error => <UserIconText {...{title, error}} />,
  props,
  promiseFn,
);

export default showUserToast;
