import Loadable from 'react-loadable';
import LoadingSpinnerErrorHandling from '@ding/core/src/components/Parts/LoadingSpinnerErrorHandling';

const CategoryProductsStoriesSection = Loadable(
  {
    loader: () => import(/* webpackChunkName: "stories-section" */ './components/Section/CategoryProductsStoriesSection'),
    modules: ['./components/Section/CategoryProductsStoriesSection'],
    webpack: () => [require.resolveWeak('./components/Section/CategoryProductsStoriesSection')],
    loading: LoadingSpinnerErrorHandling,
  },
);

export default CategoryProductsStoriesSection;
