import React, {useState} from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import * as R from 'ramda';

import {ICON_SIZE_ARRAY} from '@ding/core/src/components/Predefined/Icon';
import {PROPS_SCHEMA} from '@adretail/schemas';
import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/typeSchema';
import {
  BELL_INACTIVE_GRAY,
  LIGHT_GRAY,
  WHITE,
  PRIMARY_FILL,
} from '@ding/constants/src/colorSchema';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import useInterval from '@adretail/basic-hooks/src/base/useInterval';

import BellOutlineIcon from '@ding/core/src/icons/BellOutline';

const BellCheckBoxHolder = React.forwardRef(({
  active, children, classes,
  parentProps, ringAnim, muteInactive,
  size, primaryColor, white, iconProps, ...props
}, ref) => {
  const ringEnabled = !active && (parentProps?.ringAnim || ringAnim);
  const [ringing, setRinging] = useState(true);

  useInterval(
    () => setRinging(!ringing),
    ringEnabled
      ? 6000
      : null,
  );

  return (
    <BellOutlineIcon
      ref={ref}
      {...props}
      {...(parentProps.iconProps || iconProps)}
      size='parent'
      className={c(
        classes.base,
        classes[`${!active && (white || parentProps.white) ? 'white' : 'colorized'}-${active ? 'filled' : 'outline'}`],
        (muteInactive || parentProps.muteInactive) && classes.muteInactive,
        ringEnabled && ringing && classes.ringAnim,
        size && classes[size],
      )}
    />
  );
});

BellCheckBoxHolder.displayName = 'BellCheckBoxHolder';

BellCheckBoxHolder.propTypes = {
  parentProps: PropTypes.any,
  ringAnim: PropTypes.bool,
  muteInactive: PropTypes.bool,
  iconProps: PROPS_SCHEMA,
  size: SIZE_ARRAY_SCHEMA,
  white: PropTypes.bool, // if true - render only black / white
};

BellCheckBoxHolder.defaultProps = {
  size: null,
  parentProps: {},
};

const RING_ROTATE_ANGLES = {
  '0%': 0,
  '1%': 30,
  '3%': -28,
  '5%': 34,
  '7%': -32,
  '9%': 30,
  '11%': -28,
  '13%': 26,
  '15%': -24,
  '17%': 22,
  '19%': -20,
  '21%': 18,
  '23%': -16,
  '25%': 14,
  '27%': -12,
  '29%': 10,
  '31%': -8,
  '33%': 6,
  '35%': -4,
  '37%': 2,
  '39%': -1,
  '41%': 1,
  '43%': 0,
  '100%': 0,
};

export default injectClassesSheet(
  {
    '@keyframes ring-anim': R.mapObjIndexed(
      value => ({
        transform: `rotateZ(${value / 2}deg)`,
      }),
      RING_ROTATE_ANGLES,
    ),

    base: {
      cursor: 'pointer',
      width: 24,
      height: 24,
      transition: '250ms color ease-in-out',

      '& svg': {
        transition: '250ms fill ease-in-out',
      },
    },

    muteInactive: {
      color: `${BELL_INACTIVE_GRAY}`,
    },

    ringAnim: {
      animation: 'ring-anim 4s .7s ease-in-out',
      transformOrigin: '50% 4px',
    },

    'colorized-outline': {
      '&:hover': {
        color: PRIMARY_FILL,
      },
    },

    'colorized-filled': {
      color: PRIMARY_FILL,

      '& svg': {
        fill: 'currentcolor',
      },
    },

    'white-outline': {
      color: WHITE,

      '&:hover': {
        color: LIGHT_GRAY,
      },
    },

    'white-filled': {
      color: WHITE,

      '& svg': {
        fill: 'currentcolor',
      },
    },

    ...ICON_SIZE_ARRAY,
  },
  {
    index: 4,
  },
)(BellCheckBoxHolder);
