import * as R from 'ramda';

import env from '@cdd/internal-server/src/constants/env';
import {parameterize} from '@ding/core/src/helpers';

const genLeafletPdfURL = ({name, id, pdfIdentifier}) => {
  if (R.isNil(pdfIdentifier))
    return null;

  return `${env.client?.pdfAssetsUrl || ''}/gazetka/${pdfIdentifier}/${parameterize(name)},${id}.pdf`;
};

export default genLeafletPdfURL;
