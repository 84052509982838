import styled from '@adretail/fast-stylesheet/src/react/styled';
import {DIRECTION} from '@ding/constants/src/typeSchema';

export const SLIDER_ANIM_PROPS = {
  [DIRECTION.RIGHT]: {
    states: {
      entering: {
        transform: 'translateX(100%)',
      },

      entered: {
        transform: 'translateX(0%)',
      },

      exiting: {
        transform: 'translateX(100%)',
      },

      exited: {
        transform: 'translateX(100%)',
      },
    },

    defaultStyle: {
      transform: 'translateX(100%)',
    },
  },

  [DIRECTION.LEFT]: {
    states: {
      entering: {
        transform: 'translateX(-100%)',
      },

      entered: {
        transform: 'translateX(0%)',
      },

      exiting: {
        transform: 'translateX(-100%)',
      },

      exited: {
        transform: 'translateX(-100%)',
      },
    },

    defaultStyle: {
      transform: 'translateX(-100%)',
    },
  },
};

const SidebarHolder = styled.div(
  {
    base: {
      position: 'fixed',
      top: 0,
      width: 460,
      height: '100vh',
      zIndex: 998,
      boxShadow: '-5px 0px 40px 0px rgba(0,0,0,0.1)',
    },

    vertical: {
      zIndex: 998,
    },

    horizontal: {
      zIndex: 1003, // bigger than sticky
    },

    [DIRECTION.RIGHT]: {
      right: 0,
    },

    [DIRECTION.LEFT]: {
      left: 0,
    },
  },
  {
    omitProps: ['direction', 'vertical'],
    classSelector: (classes, {direction, vertical}) => [
      classes[direction],
      vertical
        ? classes.vertical
        : classes.horizontal,
    ],
  },
);

export default SidebarHolder;
