import React from 'react';
import PropTypes from 'prop-types';

import {OAUTH_PROVIDERS} from '@ding/auth/src/constants/schemas';

import {AsyncSpinnerButton} from '@ding/controls/src/';
import OAuthButtonProvider from '../OAuthButtonProvider';
import GoogleApiConsumer from './GoogleApiConsumer';
import {TitledGoogleButton} from './GoogleButton';

export default class GoogleButtonContainer extends React.PureComponent {
  static propTypes = {
    onBeforeLoadToken: PropTypes.func,
    onBeforeRegisterRedirect: PropTypes.func,
  };

  /**
   * @description Used by OAuthButtonProvider to display google sign in popup and
   * resolve promise with oauth access token
   * @param {Function} loginUser Function which returns
   * @see OAuthButtonProvider for onFetchUserInfo call
   * @see GoogleApiConsumer for loginUser function definition
   * @returns
   */
  onFetchUserInfo = loginUser => async () => {
    const {access_token: accessToken, expires_in: expiresIn} = await loginUser();
    return {
      authData: {
        accessToken,
        expiresIn,
      },
      user: {},
    };
  };

  render() {
    const {
      t,
      onBeforeRegisterRedirect,
      onBeforeLoadToken,
      ...props
    } = this.props;

    return (
      <GoogleApiConsumer>
        {({loginUser}) => (
          <OAuthButtonProvider
            provider={OAUTH_PROVIDERS.GOOGLE}
            onFetchUserInfo={
              this.onFetchUserInfo(loginUser)
            }
            {...{
              onBeforeRegisterRedirect,
              onBeforeLoadToken,
            }}
          >
            {({onTriggerOAuth}) => (
              <AsyncSpinnerButton
                {...props}
                controlComponent={TitledGoogleButton}
                onClick={onTriggerOAuth}
              />
            )}
          </OAuthButtonProvider>
        )}
      </GoogleApiConsumer>
    );
  }
}
