import React from 'react';
import PropTypes from 'prop-types';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {Text} from '@ding/core/src/components/Predefined';
import showUserToast from '@ding/controls/src/ToastAlert/types/showUserToast';

import {LogoutMutation} from '../../../gql/components';

const LogoutButton = ({
  titleProp,
  triggerComponent: TriggerComponent,
  ...props
}) => {
  const t = useTranslate();

  return (
    <LogoutMutation>
      {logoutUser => (
        <TriggerComponent
          {...props}
          {...{
            [titleProp]: props[titleProp] || t('website.auth.titles.logout'),
          }}
          onClick={
            () => {
              showUserToast(
                ({error}) => t(`website.auth.toast.logout.${error ? 'error' : 'success'}`),
                null,
                logoutUser,
              );
            }
          }
        />
      )}
    </LogoutMutation>
  );
};

LogoutButton.displayName = 'LogoutButton';

LogoutButton.propTypes = {
  triggerComponent: REACT_COMPONENT_CLASS_SCHEMA,
  titleProp: PropTypes.string,
};

LogoutButton.defaultProps = {
  triggerComponent: Text,
  titleProp: 'children',
};

export default LogoutButton;
