import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {WrapElementsList} from '@ding/core/src/components/Parts/Lists';
import {
  Margin,
  Text,
} from '@ding/core/src/components/Predefined';

const HightlightedFlagsList = ({hightlight, flags}) => (
  <WrapElementsList>
    {R.compose(
      R.values,
      R.mapObjIndexed(
        (obj, key) => (
          <Margin
            key={key}
            bottom={1}
            block
          >
            <Text
              weight={(
                hightlight?.[key]
                  ? null
                  : 600
              )}
            >
              {flags[key]}
            </Text>
          </Margin>
        ),
      ),
    )(flags)}
  </WrapElementsList>
);

HightlightedFlagsList.displayName = 'HightlightedFlagsList';

HightlightedFlagsList.propTypes = {
  hightlight: PropTypes.objectOf(PropTypes.bool),
  flags: PropTypes.objectOf(PropTypes.string),
};

export default HightlightedFlagsList;
