import PropTypes from 'prop-types';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/typeSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {cardZDepth} from '@ding/core/src/styles/zDepth';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';

import {
  WHITE,
  PRIMARY_FILL,
  LIGHT_CARD_BORDER,
} from '@ding/constants/src/colorSchema';

export const HOVER_TITLE_ATTR = 'hover-title';

export const CardHoverActiveTitle = styled.div(
  {
    transition: 'color 100ms ease-in-out',
    color: 'inherit',
  },
  {
    [HOVER_TITLE_ATTR]: 'true',
  },
);

const CardHolder = styled(
  'article',
  {
    base: {
      position: 'relative',
      background: WHITE,

      [`&:hover [${HOVER_TITLE_ATTR}]`]: {
        color: PRIMARY_FILL,
      },
    },

    'background-primary': {
      background: PRIMARY_FILL,
      color: WHITE,

      [`&:hover [${HOVER_TITLE_ATTR}]`]: {
        color: WHITE,
      },
    },

    'padding-small': {padding: 10},
    'padding-medium': {padding: 20},
    'padding-big': {padding: 32},

    spaced: createBreakpointClasses({
      md: {
        margin: '0 10px 10px',
      },
    }),

    rounded: {
      borderRadius: 6,
    },

    bordered: {
      border: `1px solid ${LIGHT_CARD_BORDER}`,
    },

    expanded: {
      width: '100%',
    },

    hoverable: {
      extend: cardZDepth,
    },
  },
  {
    omitProps: [
      'bordered',
      'hoverable',
      'spaced',
      'padding',
      'background',
      'expanded',
      'rounded',
    ],

    classSelector: (
      classes,
      {
        background, padding, expanded,
        spaced, bordered, hoverable, rounded,
      },
    ) => [
      bordered && classes.bordered,
      hoverable && classes.hoverable,
      spaced && classes.spaced,
      expanded && classes.expanded,
      rounded && classes.rounded,
      padding && classes[`padding-${padding}`],
      background && classes[`background-${background}`],
    ],
  },
);

CardHolder.displayName = 'CardHolder';

CardHolder.propTypes = {
  bordered: PropTypes.bool,
  hoverable: PropTypes.bool,
  spaced: PropTypes.bool,
  rounded: PropTypes.bool,
  padding: SIZE_ARRAY_SCHEMA,
  background: PropTypes.oneOf(
    [
      'primary',
    ],
  ),
};

CardHolder.defaultProps = {
  bordered: false,
  hoverable: false,
  rounded: true,
  padding: 'small',
};

export default CardHolder;
