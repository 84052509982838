import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import styled from '@adretail/fast-stylesheet/src/react/styled';

import {
  WHITE,
  DIVIDER_GRAY,
} from '@ding/constants/src/colorSchema';

import {
  Divider,
  RelativeContainer,
  Text,
} from '../Predefined';

const DividerTitle = styled(
  Text,
  {
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding: [0, 16],
    fontSize: 14,
    transform: 'translate(-50%, -50%)',
    background: WHITE,
    color: DIVIDER_GRAY,
  },
  {
    uppercase: true,
    weight: 700,
  },
);

const TitledDivider = ({children, ...props}) => (
  <RelativeContainer block>
    <Divider {...props} />
    {children && (
      <DividerTitle>
        {children}
      </DividerTitle>
    )}
  </RelativeContainer>
);

TitledDivider.displayName = 'TitledDivider';

const createPredefinedDivider = translationPath => (props) => {
  const t = useTranslate();

  return (
    <TitledDivider {...props}>
      {t(translationPath)}
    </TitledDivider>
  );
};

TitledDivider.OR = createPredefinedDivider('website.shared.titles.or');

export default TitledDivider;
