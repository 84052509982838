import styled from '@adretail/fast-stylesheet/src/react/styled';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/schemas/basic';
import {VIEWER_TOOLBAR_DIVIDER} from '@ding/constants/src/colorSchema';

import {createBreakpointClasses} from '../../helpers/createBreakpoints';

const ListItemsSeparator = styled.span(
  {
    base: {
      display: 'inline-block',
      width: 1,
      height: '1em',
      background: VIEWER_TOOLBAR_DIVIDER,
      verticalAlign: 'bottom',
    },

    tiny: {
      height: '1.5em',
    },

    small: {
      height: '2em',
    },

    'spaced-medium': createBreakpointClasses(
      {
        xs: {
          margin: [0, 5],
        },

        md: {
          margin: [0, 10],
        },
      },
    ),

    'spaced-big': createBreakpointClasses(
      {
        xs: {
          margin: [0, 10],
        },

        md: {
          margin: [0, 20],
        },
      },
    ),
  },
  {
    omitProps: ['spaced', 'size'],
    classSelector: (classes, {size, spaced}) => [
      spaced && classes[`spaced-${spaced}`],
      size && classes[size],
    ],
  },
);

ListItemsSeparator.displayName = 'ListItemsSeparator';

ListItemsSeparator.propTypes = {
  spaced: SIZE_ARRAY_SCHEMA,
  size: SIZE_ARRAY_SCHEMA,
};

ListItemsSeparator.defaultProps = {
  size: 'tiny',
};

export default ListItemsSeparator;
