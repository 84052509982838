import React from 'react';

import {
  PRIMARY_FILL,
  HOVERED_LIGHT_BORDER,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {squareHolderSizes} from './SquareHolder';

const DotOulineCircleHolder = styled(
  ({active, children, parentProps, ...props}) => (
    <div {...props}>
      {children}
    </div>
  ),
  {
    base: {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      textAlign: 'center',
      borderRadius: '100%',
      border: `2px solid ${HOVERED_LIGHT_BORDER}`,
      transition: 'border-color 250ms ease-in-out',

      '&:after': {
        content: '""',
        position: 'absolute',
        left: '50%',
        top: '50%',
        width: '65%',
        height: '65%',
        transform: 'translate3d(-50%, -50%, 0)',
        background: HOVERED_LIGHT_BORDER,
        borderRadius: '100%',
        transition: 'background 250ms ease-in-out',
      },
    },

    ...squareHolderSizes,

    active: {
      borderColor: PRIMARY_FILL,

      '&:after': {
        background: PRIMARY_FILL,
      },
    },
  },
  {
    index: 2,
    classSelector: (classes, {active, size}) => [
      active && classes.active,
      classes[size || 'small'],
    ],
  },
);

DotOulineCircleHolder.displayName = 'DotOulineCircleHolder';

export default DotOulineCircleHolder;
