import env from '@cdd/internal-server/src/constants/env';
import * as R from 'ramda';

export const ROOT_DOMAIN = env.shared?.website?.address;

export const dropLastSlash = R.when(
  R.endsWith('/'),
  R.init,
);

export const prefixWithRootPrefix = (prefix) => {
  if (!prefix)
    return R.identity;

  const prefixPath = R.unless(
    R.startsWith('http'),
    R.compose(
      // concat with prefix
      R.concat(
        R.unless(
          R.endsWith('/'),
          R.concat(R.__, '/'),
        )(prefix),
      ),

      // drop first slash from path
      R.when(
        R.startsWith('/'),
        R.tail,
      ),
    ),
  );

  return R.ifElse(
    R.isEmpty,
    R.always(prefix),
    prefixPath,
  );
};

// uses real prod address
export const prefixWithRootDomain = prefixWithRootPrefix(ROOT_DOMAIN);

export default prefixWithRootPrefix(env.client?.rootUrl);
