import React from 'react';
import {useRouteMatch} from 'react-router-dom';

import LeafletsLink, {LEAFLETS_URL_SCHEMA} from '@ding/layout/src/components/Links/LeafletsLink';
import LeafletsIcon from '@ding/core/src/icons/bottom-bar/Leaflets';
import BottomBarButton from '../BottomBarButton';

const LeafletsItem = ({subtitle, onClick}) => {
  const match = useRouteMatch(LEAFLETS_URL_SCHEMA);

  return (
    <BottomBarButton
      active={!!match?.isExact}
      component={LeafletsLink}
      subtitle={subtitle.name}
      title={subtitle.title}
      onClick={onClick}
    >
      <LeafletsIcon size='medium' />
    </BottomBarButton>
  );
};

LeafletsItem.displayName = 'LeafletsItem';

export default LeafletsItem;
