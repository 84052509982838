import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';
import * as idleCallback from '@adretail/basic-helpers/src/async/idleCallback';

import BorderedRoundedBox from '@ding/core/src/components/Parts/BorderedRoundedBox';
import {PageContainer} from '@ding/core/src/components/Predefined';

import ExpandSectionBar from './ExpandSectionBar';
import SectionHolder from './SectionHolder';
import SectionTitle from './SectionTitle';
import SectionHeader from './SectionHeader';
import SectionCenteredContent from './SectionCenteredContent';
import SectionHeaderToolbar from './SectionHeaderToolbar';

export {
  SectionCenteredContent,
};

const SectionMessageHolder = styled.div(
  {
    margin: [0, 6, 10, 6],
  },
);

export const SectionDangerMessage = ({children}) => (
  <SectionMessageHolder>
    <BorderedRoundedBox type='danger-bold'>
      {children}
    </BorderedRoundedBox>
  </SectionMessageHolder>
);

const SectionTitleWrapper = styled.div(
  {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',

    ...createBreakpointClasses(
      {
        xs: {
          flexDirection: 'column',

          '& > div:first-child': {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            gap: 40,
          },
        },

        md: {
          flexDirection: 'row',

          '& > div:first-child': {
            justifyContent: 'normal',
          },

          '& > div > div:only-child': {
            flexGrow: 1,
          },
        },
      },
    ),
  },
);

const Section = ({
  beforeHeader,
  message,
  title,
  titleToolbar,
  padding,
  header,
  headerToolbar,
  children,
  expandMoreAnchor,
  contentInContainer,
  noContainerPadding,
  subsection,
  headerButton,
  headerTag,
  toolbarType,
  ...props
}) => {
  const holderRef = useRef(null);
  const {id} = props;

  // autoscroll to section if hash === ID
  useEffect(
    () => {
      if (!R.isNil(id) && document.location.hash === `#${id}`) {
        idleCallback.create(() => {
          holderRef.current.scrollIntoView(
            {
              block: 'center',
            },
          );
        });
      }
    },
    [id],
  );

  let content = (
    <>
      {beforeHeader}

      {(title || header || headerToolbar) && (
        <SectionTitleWrapper>
          <div>
            <div>
              {!subsection && title && (
              <SectionTitle padding={padding}>
                {title}
              </SectionTitle>
              )}

              {header && (
              <SectionHeader
                padding={padding}
                noLeftPadding={
                  !title || subsection
                }
                headerTag={headerTag}
                subsection={subsection}
              >
                {header}
              </SectionHeader>
              )}
            </div>
            {(headerButton && !subsection) && headerButton}
          </div>

          {headerToolbar && (
            <SectionHeaderToolbar
              type={
                toolbarType || 'bar'
              }
            >
              {headerToolbar}
            </SectionHeaderToolbar>
          )}
        </SectionTitleWrapper>
      )}

      {message && (
        <SectionDangerMessage>
          {message}
        </SectionDangerMessage>
      )}

      {children}

      {expandMoreAnchor && (
        <ExpandSectionBar>
          {expandMoreAnchor}
        </ExpandSectionBar>
      )}
    </>
  );

  // wrap with div - trick CSS to check if content
  // is empty - if so, disable padding
  if ((subsection && contentInContainer === true)
      || (!subsection && contentInContainer !== false)) {
    content = (
      <PageContainer noPadding={noContainerPadding}>
        {content}
      </PageContainer>
    );
  } else if (!subsection) {
    content = (
      <div>
        {content}
      </div>
    );
  }

  return (
    <SectionHolder
      {...props}
      innerRef={(ref) => {
        holderRef.current = ref;
      }}
      subsection={subsection}
      wrapInnerContent={!!subsection}
    >
      {content}
    </SectionHolder>
  );
};

Section.displayName = 'Section';

Section.propTypes = {
  subsection: PropTypes.bool, // if true - its section inside other section, usually without title
  noContainerPadding: PropTypes.bool,
  contentInContainer: PropTypes.bool,
  expandMoreAnchor: PropTypes.node,
  headerToolbar: PropTypes.node,
  toolbarType: PropTypes.string,
  title: REACT_COMPONENT_CLASS_SCHEMA,
  header: PropTypes.node,
  headerButton: PropTypes.node,
  headerTag: PropTypes.string,
  message: PropTypes.node,
};

export default Section;
