import * as R from 'ramda';

import createLinkComponent from '../../utils/createLinkComponent';
import {createAccountSettingsPath} from './AccountSettingsLink';

export const FAVOURITE_CONTRACTORS_HASH = 'sieci';

export const FAVOURITE_PRODUCTS_HASH = 'produkty';

export const FAVOURITES_SCHEMA = createAccountSettingsPath('ulubione');

export const genFavouritesURL = R.always(
  createAccountSettingsPath('ulubione'),
);

export default createLinkComponent(
  null,
  genFavouritesURL(),
  'website.account_settings.titles.favourites',
);
