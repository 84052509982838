import React from 'react';
import createIcon from '../utils/createIcon';

export default createIcon(
  'Profile',
  {
    viewBox: '0 0 20 24',
  },
  <>
    <g transform='translate(3)' fill='none' stroke='currentColor' strokeWidth='2'>
      <circle cx='6.5' cy='6.5' r='6.5' stroke='none' />
      <circle cx='6.5' cy='6.5' r='5.5' fill='none' />
    </g>
    <path d='M659.825,293.713v-.5a4.888,4.888,0,0,1,4.726-5.025h5.7a4.888,4.888,0,0,1,4.726,5.025v.5H677.4v-.5a7.339,7.339,0,0,0-7.149-7.5h-5.7a7.34,7.34,0,0,0-7.151,7.5v.5Z' transform='translate(-657.4 -269.713)' />
  </>,
);
