import Loadable from 'react-loadable';
import LoadingSpinnerErrorHandling from '@ding/core/src/components/Parts/LoadingSpinnerErrorHandling';

const NotificationsSidebar = Loadable(
  {
    loader: () => import(/* webpackChunkName: "notifications" */ './containers/NotificationsSidebar'),
    loading: LoadingSpinnerErrorHandling,
  },
);

export default NotificationsSidebar;
