import React from 'react';
import * as R from 'ramda';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {LANGUAGES} from '@ding/i18n/src/languages';
import Autocomplete from '../Autocomplete';

export const genLanguagesList = R.memoizeWith(
  R.identity,
  t => R.values(LANGUAGES).map(language => ({
    id: language,
    name: t(`website.language.languages.${language}`),
  })),
);

const LanguageSelect = (props) => {
  const t = useTranslate();

  return (
    <Autocomplete
      {...props}
      expandIcon
      returnIdOnly
      allowInputChange={false}
      list={genLanguagesList(t)}
    />
  );
};

LanguageSelect.displayName = 'LanguageSelect';

export default LanguageSelect;
