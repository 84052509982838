import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import ValidatedInput from '../ValidatedInput';

const PasswordInput = (props) => {
  const t = useTranslate();

  return (
    <ValidatedInput
      type='password'
      placeholder={
        t('website.shared.titles.password')
      }
      {...props}
    />
  );
};

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
