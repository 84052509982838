import {CONTACT_FORM_TYPES} from '@ding/api/src/gql/contactFormMutation';

export default {
  title: 'Kontakt',

  section: {
    title: 'Gazetki',
    header: 'Napisz do nas',
  },

  form: {
    thanks_for_sending: 'Dziękujemy za przesłanie zgłoszenia!',

    placeholders: {
      your_email: 'Twój email',
      content: 'Treść',
    },

    report_types: {
      [CONTACT_FORM_TYPES.REPORT_ISSUE]: 'Chcę zgłosić błąd w działaniu',
      [CONTACT_FORM_TYPES.SHARE_OPINION]: 'Chcę podzielić się opinią / zasugerować zmiany',
      [CONTACT_FORM_TYPES.MISSING_STORE]: 'Mojego sklepu nie ma na mapach / w serwisie',
      [CONTACT_FORM_TYPES.OTHER]: 'Inny temat',
    },
  },
};
