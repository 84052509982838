import React from 'react';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import ShoppingSundaysLink from '@ding/layout/src/components/Links/ShoppingSundaysLink';
import CalendarIcon from '@ding/core/src/icons/navbar/Calendar';
import IconButton from '@ding/controls/src/Button/IconButton';

import {trackComponent} from '@ding/interia';
import {HeaderItem} from '../../HeaderToolbar';

const CalendarItem = ({subtitle, margin, onClick}) => (
  <li>
    <HeaderItem
      subtitle={subtitle.name}
      title={subtitle.title}
      aria-label={subtitle.title}
      margin={margin}
      component={IconButton}
      componentProps={{
        link: provideProps({injectOnHover: true, onClick})(ShoppingSundaysLink),
        icon: CalendarIcon,
        type: 'light',
        iconProps: {
          size: 'big',
        },
      }}
    />
  </li>
);

CalendarItem.displayName = 'CalendarItem';

export default trackComponent(
  {
    onClick({ga4}) {
      ga4.menuClickSundays();
    },
  },
)(CalendarItem);
