import {gql} from '@ding/tiny-gql';

import NotifyTagFragment from '../NotifyTag.graphql';
import TagFragment from '../Tag.graphql';
import CustomerFlagsFragment from '../CustomerFlags.graphql';

const NotifyTagComposedFragment = gql`
  ${TagFragment}
  ${NotifyTagFragment}
  ${CustomerFlagsFragment}
`;

export default NotifyTagComposedFragment;
