import PropTypes from 'prop-types';
import * as R from 'ramda';

import styled from '@adretail/fast-stylesheet/src/react/styled';

const MARGIN_SCHEMA = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

const DIRECTIONS = {
  left: 'marginLeft',
  right: 'marginRight',
  top: 'marginTop',
  bottom: 'marginBottom',
  base: 'margin',
};

const genDirectionStyle = (propName, step = 5) => R.compose(
  R.assoc(
    `${propName}-auto`,
    {
      [propName]: 'auto',
    },
  ),
  R.fromPairs,
  R.times(
    index => ([
      `${propName}-${index + 1}`,
      {
        [propName]: (index + 1) * step,
      },
    ]),
  ),
)(7);

const getMarginClassName = (classes, props) => {
  let className = '';
  for (const prop in props) { // eslint-disable-line no-restricted-syntax
    if (Object.prototype.hasOwnProperty.call(DIRECTIONS, prop)) {
      const value = props[prop];
      if (value)
        className += `${classes[`${DIRECTIONS[prop]}-${value}`]} `;
    }
  }

  return className;
};

const css = R.compose(
  R.reduce(
    (acc, value) => ({
      ...acc,
      ...genDirectionStyle(value),
    }),
    {},
  ),
  R.values,
)(DIRECTIONS);

const Margin = styled.span(
  {
    base: {
      display: 'inline-block',

      '&:empty': {
        margin: 0,
      },
    },

    block: {
      display: 'block',
    },

    flex: {
      display: 'flex',
    },

    expanded: {
      width: '100%',
    },

    ...css,
  },
  {
    index: 2,
    omitProps: [
      ...R.keys(DIRECTIONS),
      'expanded',
      'block',
      'flex',
    ],
    classSelector: (classes, props) => [
      getMarginClassName(classes, props),
      props.block && classes.block,
      props.flex && classes.flex,
      props.expanded && classes.expanded,
    ],
  },
);

Margin.displayName = 'Margin';

Margin.propTypes = {
  flex: PropTypes.bool,
  block: PropTypes.bool, // display: block
  expanded: PropTypes.bool, // width: '100%

  left: MARGIN_SCHEMA,
  right: MARGIN_SCHEMA,
  top: MARGIN_SCHEMA,
  bottom: MARGIN_SCHEMA,
  base: MARGIN_SCHEMA,
};

export default Margin;
