import React from 'react';
import createIcon from '../utils/createIcon';

export default createIcon(
  'Start',
  {
    viewBox: '0 0 24 24',
  },
  <>
    <path
      fill='currentColor'
      d='M11.864,21.02a2.39,2.39,0,0,0,2.39-2.383H9.475a2.39,2.39,0,0,0,2.389,2.383'
    />
    <path
      fill='currentColor'
      d='M11.544,4.315a6.285,6.285,0,0,1,6.278,6.278v7.57H19.1v-7.57a.963.963,0,0,1,0-.121,7.56,7.56,0,0,0-15.118.121v7.57H5.266v-7.57a6.283,6.283,0,0,1,6.278-6.278'
    />
  </>,
);
