import React from 'react';
import PropTypes from 'prop-types';

import ADDRESSES_QUERY from '@ding/api/src/gql/addressesQuery';

import {AddressesAutocomplete} from '@ding/controls/src/Autocomplete/Predefined';

import {useGeolocationState} from '@ding/geolocation/src/client/components/GeolocationContext';
import useLocalizeCallback from './hooks/useLocalizeCallback';

const LocalizeAutocomplete = ({onLocalized, ...props}) => {
  const [requestLocalize] = useLocalizeCallback();
  const geo = useGeolocationState();

  return (
    <AddressesAutocomplete
      resetOnSelect
      allowReturnEmptyID={false}
      query={ADDRESSES_QUERY}
      variables={{
        filters: {
          location: geo.getFilterAddressInput(),
        },
      }}
      onChange={
        async (address) => {
          await requestLocalize(address);
          onLocalized?.(); // eslint-disable-line
        }
      }
      {...props}
    />
  );
};

LocalizeAutocomplete.displayName = 'LocalizeAutocomplete';

LocalizeAutocomplete.propTypes = {
  onLocalized: PropTypes.func,
};

export default React.memo(LocalizeAutocomplete);
