import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import FormRow from '@ding/control-groups/src/Form/FormRow';
import {WrapElementsList} from '@ding/core/src/components/Parts';
import {
  PortalRegulationsLink,
  WebsiteRegulationsLink,
  WithdrawalContractLink,
} from '../../../Layout/Links/Law';

const LawUsefulLinks = () => {
  const t = useTranslate();

  return (
    <FormRow
      title={
        t('website.shared.titles.useful_links')
      }
      titleProps={{
        size: 'medium',
      }}
    >
      <WrapElementsList
        clonedProps={{
          type: 'primary',
          weight: 600,
          block: true,
          size: 'small',
        }}
      >
        <PortalRegulationsLink />
        <WebsiteRegulationsLink />
        <WithdrawalContractLink />
      </WrapElementsList>
    </FormRow>
  );
};

LawUsefulLinks.displayName = 'LawUsefulLinks';

export default React.memo(LawUsefulLinks);
