export default {
  title: 'Strona 404',

  meta: {
    title: 'Strona nie znaleziona Ding.pl',

    og: {
      title: 'Strona 404 Ding.pl',
    },
  },

  section: {
    header: 'Strona nie istnieje lub została przeniesiona!',
    caption: {
      text: 'Skorzystaj z wyszukiwarki, aby znaleźć interesujący Cię produkt albo przejdź do %{} serwisu.',
      action: 'strony głównej',
    },
    image_alt: 'Błąd 404 - strony nie znaleziono',
  },
};
