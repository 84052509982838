import React from 'react';
import PropTypes from 'prop-types';

import {DEFAULT_DING_API_VARIABLE} from './constants/globals';

const PreHydrateWindowAPIHeader = ({globalVariable}) => (
  <script
    dangerouslySetInnerHTML={{
      __html: `
        (() => {
          window.${globalVariable} = {
            q: [],
            push: fn => new Promise((resolve, reject) => {
              const api = window.${globalVariable};
              const queue = api.q;

              const executorFn = (...args) => {
                resolve(
                  fn(...args)
                );
              };

              if (queue)
                queue.push(executorFn);
              else
                executorFn(api);
            }),
          };
        })();
      `,
    }}
  />
);

PreHydrateWindowAPIHeader.displayName = 'PreHydrateWindowAPIHeader';

PreHydrateWindowAPIHeader.propTypes = {
  globalVariable: PropTypes.string,
};

PreHydrateWindowAPIHeader.defaultProps = {
  globalVariable: DEFAULT_DING_API_VARIABLE,
};

export default PreHydrateWindowAPIHeader;
