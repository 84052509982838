import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'User',
  {
    viewBox: '0 0 20 22',
  },
  <>
    <path d='M15.51,14l1.8,6H2.69l1.8-6h11M17,12H3L0,22H20L17,12Z' />
    <path d='M10,2A3,3,0,1,1,7,5a3,3,0,0,1,3-3m0-2a5,5,0,1,0,5,5,5,5,0,0,0-5-5Z' />
  </>,
);
