import modalMounter from '@adretail/basic-helpers/src/ui/modalMounter';
import applyIfFunction from '@adretail/basic-helpers/src/base/applyIfFunction';

import * as idleCallback from '@adretail/basic-helpers/src/async/idleCallback';
import ToastAlertModal from './ToastAlertModal';

/**
 * Mount toast in idle thread, they are not critical thing
 *
 * @param {ReactElement} children Content of toast
 * @param {Any} props
 */
const showToast = (children, props) => new Promise((resolve) => {
  idleCallback.create(() => {
    modalMounter(
      {
        modalComponent: ToastAlertModal,
        children,
        ...props,
      },
    )
      .then(resolve);
  });
});

export const showPromiseToast = async (children, props, promiseFn) => {
  const showFunctionalToast = status => showToast(
    children(status),
    {
      type: 'success',
      ...applyIfFunction([status], props),
      ...status && {
        type: 'danger',
      },
    },
  );

  try {
    let result = null;
    if (promiseFn)
      result = await promiseFn();

    showFunctionalToast(false);
    return {
      status: true,
      result,
    };
  } catch (e) {
    console.error(e);
    showFunctionalToast(true);
    return {
      status: false,
      result: undefined,
    };
  }
};

export default showToast;
