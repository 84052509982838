import React from 'react';

import {LIGHT_CARD_GRAY} from '@ding/constants/src/colorSchema';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {reactFormat} from '@ding/core/src/helpers';

import Text from '@ding/core/src/components/Predefined/Text';

/**
 * Publisher data where id is object key
 * @property {object}  publisherData - key of object is publisher id
 * @property {string}  publisherData.name - publisher name
 * @property {string}  publisherData.url -  url to publisher privacy policy page
 * @todo when all is accepted move this to @adretail/react-ads
 */
const ADVERTISERS_DATA = {
  1433918215: {name: 'Adform', url: 'https://site.adform.com/privacy-center/platform-privacy/product-and-services-privacy-policy/'},
  4475718232: {name: 'Adform', url: 'https://site.adform.com/privacy-center/platform-privacy/product-and-services-privacy-policy/'},
  4772133101: {name: 'Xandr', url: 'https://www.xandr.com/privacy/platform-privacy-policy/'},
  4567345159: {name: 'Criteo', url: 'https://www.criteo.com/privacy/'},
  1447027735: {name: 'Equativ', url: 'https://equativ.com/end-users-privacy-policy/'},
  1279036135: {name: 'Google', url: 'https://business.safety.google/privacy/'},
  1399169095: {name: 'Google', url: 'https://business.safety.google/privacy/'},
  '-1': {name: 'Google', url: 'https://business.safety.google/privacy/'},
  0: {name: 'Google', url: 'https://business.safety.google/privacy/'},
  4861661181: {name: 'Index Exchange', url: 'https://www.indexexchange.com/privacy'},
  5252664410: {name: 'Magnite', url: 'https://www.magnite.com/legal/advertising-technology-privacy-policy/'},
  1431711415: {name: 'PubMatic', url: 'https://pubmatic.com/legal/privacy/'},
  1430256055: {name: 'RTB House', url: 'https://www.rtbhouse.com/privacy-center'},
  4712116072: {name: 'Teads', url: 'https://www.teads.com/privacy-policy/'},
  5172117040: {name: 'BusinessClick', url: 'https://www.businessclick.com/o-nas/'},
};

/** @satisfies {{[x:string]: React.CSSProperties | {[x:string]: React.CSSProperties}}} */
const css = {
  base: {
    position: 'relative',
    minWidth: 100,

    display: 'flex',
    alignItems: 'center',
    gap: 5,
    marginBottom: 3,

    cursor: 'context-menu',

    '&:hover > *:last-child': {
      visibility: 'visible',
    },
  },

  icon: {
    backgroundImage: 'url(https://js.iplsc.com/inpl.uanc/1.3.174//images/info.svg)',
    width: 12,
    height: 12,
  },

  popover: {
    textWrap: 'wrap',
    position: 'absolute',
    top: '100%',
    left: 0,
    width: 200,

    visibility: 'hidden',
    zIndex: 1,

    padding: 10,
    backgroundColor: LIGHT_CARD_GRAY,
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 8px 16px 0px',
  },
};

/**
 * Add tag with ifo about advertiser and link to theirs privacy policy if is
 * @param {object} param - react component params
 * @param {number | string} param.advertiserId - id of
 * @param {{[K in keyof css]: string}} param.classes - current classes
 * @returns tag label with pop out that link to advertiser privacy policy
 */
const AdAdvertiserTag = ({classes, advertiserId}) => {
  const t = useTranslate('website.advertisement');
  const advertiserData = ADVERTISERS_DATA[advertiserId];

  if (!advertiserData)
    return null;

  return (
    <span className={classes.base} aria-haspopup='true'>
      <span className={classes.icon} />
      <Text type='dark-muted' size='tiny' weight='default-weight'>
        {t('advertiser_tag.label')}
      </Text>
      <Text size='tiny' className={classes.popover}>
        {reactFormat(
          t('advertiser_tag.popover'),
          [
            <a key='tag-popover' href={advertiserData.url} target='_blank' rel='noreferrer'>
              {advertiserData.name}
            </a>,
          ],
        )}
      </Text>
    </span>
  );
};

AdAdvertiserTag.displayName = 'AdAdvertiserTag';

export default injectClassesSheet(css)(AdAdvertiserTag);
