import React from 'react';
import PropTypes from 'prop-types';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import renderFunctionalChildren from '@adretail/basic-helpers/src/ui/renderFunctionalChildren';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import Button from '../Button';
import ControlsList from '../ControlsList';
import {ResponsiveModalContainer} from '../ResponsiveModalToggle';

const ConfirmButton = styled(
  Button.Primary,
  {
    minWidth: 130,
  },
  {
    index: 7,
  },
);

const ConfirmContentHolder = styled.div(
  {
    minHeight: 160,
    marginBottom: 40,
  },
);

const ConfirmModalHolder = React.memo(
  ({children, disableButtons, onClose}) => {
    const t = useTranslate();

    return (
      <>
        <ConfirmContentHolder>
          {renderFunctionalChildren(children, [onClose])}
        </ConfirmContentHolder>

        <ControlsList center>
          <ConfirmButton
            disabled={disableButtons?.confirm}
            onClick={() => onClose(null, true)}
          >
            {t('website.shared.confirm.ok')}
          </ConfirmButton>

          <ConfirmButton
            outline
            disabled={disableButtons?.cancel}
            onClick={() => onClose(null, false)}
          >
            {t('website.shared.confirm.cancel')}
          </ConfirmButton>
        </ControlsList>
      </>
    );
  },
);

const ConfirmModal = ({children, disableButtons, ...props}) => (
  <ResponsiveModalContainer
    {...props}
    uaModalPropsFn={
      ({mobile}) => mobile && ({
        contentProps: {
          padding: 'huge',
        },
      })
    }
  >
    {onClose => (
      <ConfirmModalHolder
        {...{
          disableButtons,
          onClose,
        }}
      >
        {children}
      </ConfirmModalHolder>
    )}
  </ResponsiveModalContainer>
);

ConfirmModal.displayName = 'ConfirmModal';

ConfirmModal.propTypes = {
  disableButtons: PropTypes.shape(
    {
      confirm: PropTypes.bool,
      disableButtons: PropTypes.shape({
        confirm: PropTypes.bool,
        cancel: PropTypes.bool,
      }),
    },
  ),
  onClose: PropTypes.func,
};

export default ConfirmModal;
