import {useRef, useEffect} from 'react';
import useWindowAPI from './useWindowAPI';

export const useSendBroadcast = () => {
  const api = useWindowAPI();

  return (action, metadata, skipComparator) => {
    if (!api)
      return;

    api.push(
      handle => handle.actions.$stream.notify(
        {
          action,
          data: metadata,
        },
        skipComparator,
      ),
    );
  };
};

export const useSubscribeBroadcast = (fn) => {
  const api = useWindowAPI();
  const ref = useRef();
  ref.current = fn;

  useEffect(
    () => {
      const handler = (event) => {
        ref.current(event);
      };

      return api.actions.$stream.subscribe(handler);
    },
    [],
  );
};

export default useSendBroadcast;
