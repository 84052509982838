import React from 'react';
import PropTypes from 'prop-types';

import {AUTH_TABS} from '@ding/auth/src/client/AuthModal';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {Button} from '@ding/controls/src/';
import AuthResponsiveModalToggle from '../AuthResponsiveModalToggle';

const RegisterButton = ({children, translation, ...props}) => {
  const t = useTranslate();

  return (
    <AuthResponsiveModalToggle
      triggerComponent={Button.Primary}
      initialTab={AUTH_TABS.REGISTER}
      {...props}
    >
      {children || t(`website.auth.titles.${translation}`)}
    </AuthResponsiveModalToggle>
  );
};

RegisterButton.displayName = 'RegisterButton';

RegisterButton.propTypes = {
  translation: PropTypes.string,
};

RegisterButton.defaultProps = {
  translation: 'register',
};

export default RegisterButton;
