import React from 'react';

import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';
import styled from '@adretail/fast-stylesheet/src/react/styled';

import TimesIcon from '@ding/core/src/icons/Times';
import {IconText} from '@ding/core/src/components/Predefined';

const TextCheckboxHolder = styled(
  IconText,
  {
    height: 21,
    cursor: 'pointer',
  },
  {
    block: true,
  },
);

const TextTimesCheckbox = ({value, l, children, weight, ...props}) => (
  <TextCheckboxHolder
    title={children}
    {...(
      value
        ? {
          type: 'primary',
          weight: 600,
          iconSpacing: 2,
          icon: (
            <TimesIcon
              type='muted'
              size='tiny-xs'
            />
          ),
        }
        : {
          type: 'default',
          weight,
        }
    )}
    {...props}
    onClick={
      () => l.onInputEvent(!value)
    }
  />
);

TextTimesCheckbox.displayName = 'TextTimesCheckbox';

export default linkedInputs(null, true)(TextTimesCheckbox);
