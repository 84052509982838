import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import env from '@cdd/internal-server/src/constants/env';
import {buildUrl} from '@adretail/basic-helpers/src/url/encodeUrl';

import injectScript from '@adretail/basic-helpers/src/async/injectScript';
import RecaptchaLinkRow from './RecaptchaLinkRow';

export {
  RecaptchaLinkRow,
};

const recaptchaEnv = env.client?.google?.recaptcha || {};

const MAGIC_WINDOW_ONLOAD_HANDLER = '__afterLoadGrecaptcha';

/**
 * Simple V3 google recaptcha implementation
 *
 * @export
 */
export default class Recaptcha extends React.Component {
  static propTypes = {
    scriptUrl: PropTypes.string,
    siteKey: PropTypes.string,
    action: PropTypes.oneOf([
      'homepage',
      'login',
    ]).isRequired,

    onReceiveToken: PropTypes.func,
  };

  static defaultProps = {
    scriptUrl: recaptchaEnv.script,
    siteKey: recaptchaEnv.siteKey,
    onReceiveToken: R.T,
  };

  componentDidMount() {
    const {scriptUrl, siteKey, onReceiveToken} = this.props;
    const {grecaptcha} = window;

    const idleExecutor = () => {
      if (!grecaptcha) {
        window[MAGIC_WINDOW_ONLOAD_HANDLER] = (...args) => {
          if (onReceiveToken)
            this.fetchToken(...args);

          delete window[MAGIC_WINDOW_ONLOAD_HANDLER];
        };

        injectScript(
          buildUrl(
            scriptUrl,
            {
              render: siteKey,
              onload: MAGIC_WINDOW_ONLOAD_HANDLER,
            },
          ),
        );
      } else if (onReceiveToken)
        this.fetchToken();
    };

    setTimeout(idleExecutor, 250);
  }

  fetchToken = () => {
    const {action, siteKey, onReceiveToken} = this.props;
    const {grecaptcha} = window;

    try {
      return (
        grecaptcha
          .execute(siteKey, {action})
          .then((token) => {
            onReceiveToken?.(token); // eslint-disable-line
            return token;
          })
      );
    } catch (e) {
      console.error('Recaptcha fetchToken error! Check domain!', e);
    }

    return null;
  };

  render() {
    const {children} = this.props;
    if (!children)
      return null;

    return children(this.fetchToken);
  }
}
