import * as R from 'ramda';

import clamp from '@adretail/basic-helpers/src/base/clamp';

import findIndexById from '@adretail/basic-helpers/src/base/findIndexById';
import {getPagesPerSlide} from './selectors/basic';
import {pageIndexToSlide} from '../slideNumbersEncoder';

import * as PAGE_TYPES from '../../constants/pageTypes';

export const isImagePage = R.propEq('kind', PAGE_TYPES.IMAGE);

const updateUiState = (path, fn) => R.over(
  R.lensPath(['ui', ...path]),
  fn,
);

//NOTE: Function from @adretail/image-zoom
//placed here to prevent import whole @adretail/image-zoom to main chunk
const getZoomValueCenter = ({zoom, region}) => ({
  x: clamp(-(1) + (1 / zoom), 0, (-region.x) + (region.x / zoom)),
  y: clamp(-(1) + (1 / zoom), 0, (-region.y) + (region.y / zoom)),
});

export const setDraggingState = newFlag => updateUiState(['dragging'], R.always(newFlag));

export const setContainerDimensions = dimensions => updateUiState(['dimensions'], R.always(dimensions));

export const toggleModules = newFlag => state => ({
  ...state,
  ui: {
    ...state.ui,
    modulesEnabled: R.defaultTo(!state.ui.modulesEnabled, newFlag),
  },
});

/**
 * Focuses on first visible page and centers to it
 */
const getCenteredZoomRegion = (visiblePages) => {
  if (R.all(isImagePage, visiblePages)) {
    return {
      x: 0.5,
      y: 0.5,
    };
  }

  const lastImagePageIndex = R.findLastIndex(isImagePage, visiblePages);
  const slideNormalizedWidth = 1.0 / visiblePages.length;
  return {
    x: ((lastImagePageIndex + 1) * slideNormalizedWidth) - (slideNormalizedWidth / 2),
    y: 0.5,
  };
};

export const changeZoom = zoom => (state) => {
  if (state?.ui?.zoom === zoom)
    return state;

  return {
    ...state,
    ui: {
      ...state.ui,
      zoom: zoom && {
        ...state.ui.zoom,
        ...zoom,
      },
    },
  };
};

export const resetZoom = () => changeZoom(null);

export const incrementZoom = (sign = 1, stepSize = 1 / 3, absolute = false) => (
  state,
  {
    getZoom,
    getZoomConfig,
    getVisiblePages,
  },
) => {
  const zoomValue = getZoom();
  const config = getZoomConfig();

  const offset = (
    absolute
      ? config.min
      : (zoomValue?.zoom || 1.0)
  );

  const zoomParams = {
    zoom: clamp(
      config.min,
      config.max,
      offset + sign * ((config.max - config.min) * stepSize),
    ),
    region: zoomValue?.region || getCenteredZoomRegion(
      getVisiblePages(),
    ),
  };

  return changeZoom(
    {
      ...zoomParams,
      center: getZoomValueCenter(zoomParams),
    },
  )(state);
};

export const setPercentageZoom = percentage => (state, selectors) => incrementZoom(
  1, percentage, true,
)(
  state, selectors,
);

export const changeVisiblePagesPerSlide = pagesPerSlide => (state) => {
  const {ui} = state;

  if (ui.pagesPerSlide === pagesPerSlide)
    return state;

  return {
    ...state,
    ui: {
      ...ui,
      pagesPerSlide,
    },
  };
};

/**
 * Sets slide index, slide index indexes
 * chunks, not flat pages!
 *
 * @param {Number} slideIndex
 * @param {Boolean} noFlipCountChange
 */
export const changeSlide = (slideIndex, noFlipCountChange) => (state) => {
  const {ui} = state;
  const {flags} = ui;

  if (!ui.dragging && ui.currentSlide === slideIndex)
    return state;

  return {
    ...state,
    ui: {
      ...state.ui,
      ...ui.changedPagesCount > 0 && flags.unlockAutoplayAfterPageChange && !flags.withAutoplay && {
        flags: {
          ...flags,
          withAutoplay: true,
        },
      },
      ...!noFlipCountChange && {
        changedPagesCount: (state.ui.changedPagesCount || 0) + 1,
      },
      currentSlide: slideIndex,
      zoom: null,
      dragging: false,
    },
  };
};

/**
 * Sets slide index by page index, page index is index from 0 to N
 * pages in viewer leaflet, it is used primary for routing
 *
 * @param {Number} pageIndex
 * @param {Boolean} noFlipCountChange
 */
export const changeSlideByPageIndex = (pageIndex, noFlipCountChange) => (state) => {
  const slideIndex = pageIndexToSlide(
    getPagesPerSlide(state),
    pageIndex,
  );

  return changeSlide(slideIndex, noFlipCountChange)(state);
};

/**
 * Relative change slide
 *
 * @param {Number} offset
 */
export const flipSlide = offset => state => (
  !offset
    ? state
    : changeSlide(state.ui.currentSlide + offset)(state)
);

/**
 * Save zoom to state, zoom should lock some browser options
 *
 * @param {Number} zoomValue
 */
const zoomSlide = zoomValue => (
  updateUiState(
    ['paperZoom'],
    R.always(zoomValue),
  )
);

/**
 * Modify leafelt content, __updatedAt flushes selectors cache
 *
 * @param {Leaflet} newLeaflet
 */
const loadLeafletPatch = newLeaflet => state => ({
  ...state,
  resources: {
    ...state?.resources,
    leaflet: {
      ...newLeaflet,
      __updatedAt: +Date.now(),
    },
  },
});

/**
 * Modify leafelt content, __updatedAt flushes selectors cache
 *
 * @param {String} pageID
 * @param {Function} pagePatchFn
 */
const loadLeafletPagePatch = (pageID, pagePatchFn) => (state) => {
  const {leaflet} = state.resources;

  return {
    ...state,
    resources: {
      ...state.resources,
      leaflet: {
        ...leaflet,
        pages: R.adjust(
          pagePatchFn,
          findIndexById(pageID, leaflet.pages),
          leaflet.pages,
        ),
        __updatedAt: +Date.now(),
      },
    },
  };
};

/**
 * @param {String} action
 * @param {Object} data
 */
const broadcastAction = (action, data) => (state) => {
  state.ui.$events.notify(
    {
      action,
      data,
    },
  );

  return state;
};

export const actions = {
  incrementZoom,
  resetZoom,
  setPercentageZoom,

  loadLeafletPatch,
  loadLeafletPagePatch,

  changeZoom,
  changeSlide,
  changeVisiblePagesPerSlide,
  changeSlideByPageIndex,

  toggleModules,
  zoomSlide,
  flipSlide,

  setDraggingState,
  setContainerDimensions,
  broadcastAction,
};

export const effects = {};
