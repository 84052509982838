import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';
import {
  WHITE,
  DARK,
  DARK_FILL_DIVIDER,
  PRIMARY_FILL,
  PRIMARY_FILL_DIVIDER,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import textEllipsisOverflow from '@ding/core/src/styles/textEllipsisOverflow';

import ExpandableContent from '@adretail/basic-components/src/ExpandableContent';
import ExpandIcon from '@ding/core/src/icons/Expand';

import {
  Margin,
  IconText,
  PlainUnorderedList,
} from '@ding/core/src/components/Predefined';

const PrimaryMenuItemHolder = styled.li(
  {
    base: {
      padding: '0 10px !important',
      height: 60,
      fontWeight: 700,
      fontSize: 14,
      color: WHITE,
    },

    primary: {
      background: PRIMARY_FILL,

      '&:not(:last-child) > div': {
        borderBottom: `1px solid ${PRIMARY_FILL_DIVIDER}`,
      },
    },

    dark: {
      background: DARK,

      '&:not(:last-child) > div': {
        borderBottom: `1px solid ${DARK_FILL_DIVIDER}`,
      },
    },
  },
  {
    index: 3,
    omitProps: ['type'],
    classSelector: (classes, {type}) => classes[type],
  },
);

const PrimaryMenuItemWrapper = styled.div(
  {
    display: 'flex',
    alignItems: 'center',
    width: '100%', // - 20px margin
    height: '100%',
    padding: [0, 10],
  },
);

const ItemText = styled.div(
  {
    extend: textEllipsisOverflow,
    maxWidth: 'calc(100% - 50px)',
  },
);

/**
 * @see
 *  used in:
 *    MenuModal
 */
const PrimaryMenuItem = ({icon: IconComponent, title, children, ...props}) => {
  const renderItem = ({toggled, ...additionalProps}) => {
    const toggleable = !R.isNil(toggled);
    const titleElement = (
      toggleable
        ? (
          <>
            <ItemText>
              {title}
            </ItemText>
            <Margin left='auto'>
              <ExpandIcon
                verticalFlip={toggled}
                size='tiny'
                type='light'
              />
            </Margin>
          </>
        )
        : title
    );

    return (
      <PrimaryMenuItemHolder
        {...props}
        {...additionalProps}
      >
        <PrimaryMenuItemWrapper>
          <IconText
            expanded
            title={titleElement}
            icon={
              IconComponent && (
                <IconComponent type='light' />
              )
            }
          />
        </PrimaryMenuItemWrapper>
      </PrimaryMenuItemHolder>
    );
  };

  if (R.is(Function, children)) {
    return (
      <ExpandableContent>
        {({
          toggled,
          contentStyle,
          renderContent,
          contentRef,
          onToggle,
        }) => (
          <>
            {renderItem(
              {
                toggled,
                onClick: onToggle,
              },
            )}
            <li style={contentStyle}>
              {renderContent && (
                <PlainUnorderedList innerRef={contentRef}>
                  {children()}
                </PlainUnorderedList>
              )}
            </li>
          </>
        )}
      </ExpandableContent>
    );
  }

  return renderItem({});
};

PrimaryMenuItem.display = 'PrimaryMenuItem';

PrimaryMenuItem.propTypes = {
  icon: REACT_COMPONENT_CLASS_SCHEMA,
  type: PropTypes.oneOf([
    'primary',
    'dark',
  ]),
};

PrimaryMenuItem.defaultProps = {
  type: 'primary',
};

export default PrimaryMenuItem;
