import React from 'react';
import PropTypes from 'prop-types';

import {Text} from '@ding/core/src/components/Predefined';

import TagsSelect from '.';
import TagButton from './TagButton';

export const PrefixedTagButton = ({prefixTitle, children, ...props}) => (
  <TagButton {...props}>
    <span style={{paddingRight: 10}}>
      <Text
        weight='default-weight'
        truncate
      >
        {prefixTitle}
        &nbsp;
      </Text>
      <Text truncate>
        {children}
      </Text>
    </span>
  </TagButton>
);

const PrefixTitleTagsSelect = ({prefixTitle, tagNameRenderFn, ...props}) => (
  <TagsSelect
    {...props}
    tagComponent={PrefixedTagButton}
    tagProps={{
      prefixTitle,
    }}
  />
);

PrefixTitleTagsSelect.displayName = 'PrefixTitleTagsSelect';

PrefixTitleTagsSelect.propTypes = {
  prefixTitle: PropTypes.string.isRequired,
};

export default PrefixTitleTagsSelect;
