import React from 'react';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import TinyGqlQuery, {FETCH_POLICY} from '@ding/tiny-gql/src/react/TinyGqlQuery';

import {
  LoadingSpinner,
  SomethingGoneWrong,
} from '@ding/core/src/components/Parts';

export {
  FETCH_POLICY,
};

export const DefaultLoadingSpinner = () => <LoadingSpinner style={{height: 200}} />;

const DefaultTinyQuery = provideProps(
  {
    errorComponent: SomethingGoneWrong,
    loadingComponent: DefaultLoadingSpinner,
  },
)(TinyGqlQuery);

DefaultTinyQuery.displayName = 'DefaultTinyQuery';

export default DefaultTinyQuery;
