import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import {LeafletsQuery} from '@ding/api/src/gql/leafletsQuery';
import PaginatedGQLGrid from '@ding/control-groups/src/PaginatedGQLGrid';

import LeafletsGrid from './LeafletsGrid';

export default provideProps(
  ({extraGridProps, itemProps}) => (
    {
      queryComponent: LeafletsQuery,
      gridComponent: LeafletsGrid,
      gridProps: {
        preloadFirstRow: true,
        gridConfig: {
          xs: 6,
          md: 3,
          lg: 2,
        },
        ...extraGridProps,
        itemProps: {
          size: 'small',
          ...itemProps,
        },
      },
    }),
)(PaginatedGQLGrid);
