import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';

import {useUA} from '@adretail/basic-components/src/Context/UAProvider';
import Grid from '../Predefined/Grid';

/**
 * Check if current grid item is in first row based on current device and grid Config
 * @param {{xs: number, md: number, lg: number}} gridConfig - grid config
 * @param {{mobile: boolean, tablet: boolean, desktop: boolean}} ua - user agent, current device
 * @param {number} index - curet grid item index
 * @return {boolean} return true if current grid item is in first row
 */
const countFirstRowItems = (gridConfig, ua, index) => {
  switch (true) {
    case ua.desktop: return 12 / gridConfig.lg > index;
    case ua.tablet: return 12 / gridConfig.md > index;
    case ua.mobile: return 12 / gridConfig.xs > index;
    default: return false;
  }
};

export const ComponentsGrid = React.forwardRef(({
  itemComponent,
  itemRenderFn,
  itemValuePropName,
  itemKeyFn,

  /** render skeleton instead pass how many items to render */
  placeholder,
  skeletonComponent,
  /** component to render if list is empty */
  emptyPlaceholderComponent: EmptyPlaceholder,

  style,
  className,

  headerRenderFn,
  /**
   * add extra component to grid in passed slot
   * @note remember to pass key in data for component (inside list) so component don't rerender when placeholder will be replaced
   */
  insert,

  itemProps,
  itemPropsFn,

  gridConfig,
  preloadFirstRow,

  noPadding,
  padding,

  list,
  children,

  onClick,
}, ref) => {
  const ua = useUA();
  const size = (
    ua.mobile
      ? 'small'
      : 'auto'
  );

  // NOTE: this version do not support persisting placeholder prop to stop rendering skeleton placeholder prop need to be undefined
  if ((!list || R.isEmpty(list)) && placeholder) {
    list = new Array(placeholder).fill({id: 'placeholder'});
  }

  // TODO: at this moment when `list` is empty and `placeholder` ended (is undefined) we render `EmptyPlaceholder` but we can just leave rendered skeleton components (just turn of animation). other option is to always render skeletons and just replace head of list wit those that we received from api (we want render 6 items but received 2 so we render 2 wit content and 4 skeletons)
  if (!list || R.isEmpty(list)) {
    return (
      EmptyPlaceholder
        ? <EmptyPlaceholder />
        : null
    );
  }

  const ItemComponent = placeholder ? skeletonComponent : itemComponent;

  return (
    <Grid
      ref={ref}
      style={style}
      className={className}
      onClick={onClick}
    >
      {headerRenderFn && headerRenderFn(list)}
      {list.map(
        (item, index) => {
          if (!item || R.isEmpty(item))
            return null;

          const preloadImage = preloadFirstRow && countFirstRowItems(gridConfig, ua, index);

          const props = {
            size,
            preloadImage,
            ...{
              [itemValuePropName]: item,
            },
            ...itemProps,
            ...itemPropsFn && itemPropsFn(item, index),
          };

          const key = props.key || itemKeyFn(item, index);
          const element = (
            <Grid.Column
              key={key}
              noPadding={noPadding}
              padding={padding}
              gridConfig={gridConfig}
            >
              {(
                itemRenderFn
                  ? itemRenderFn(props, index)
                  : <ItemComponent {...props} />
              )}
            </Grid.Column>
          );

          const insertFn = insert && insert[index + 1];
          if (insertFn !== undefined) {
            const columnProps = {
              noPadding,
              gridConfig,
            };

            return (
              <React.Fragment key={`insert-group-${key}`}>
                {insertFn(index + 1, columnProps, props)}
                {element}
              </React.Fragment>
            );
          }

          return element;
        },
      )}
      {children}
    </Grid>
  );
});

ComponentsGrid.displayName = 'ComponentsGrid';

ComponentsGrid.propTypes = {
  itemComponent: REACT_COMPONENT_CLASS_SCHEMA,
  itemRenderFn: PropTypes.func,
  itemValuePropName: PropTypes.string,
  itemKeyFn: PropTypes.func,

  skeletonComponent: REACT_COMPONENT_CLASS_SCHEMA,
  placeholder: PropTypes.number,
  emptyPlaceholderComponent: REACT_COMPONENT_CLASS_SCHEMA,

  noPadding: PropTypes.bool, // disabled padding in columns
  padding: PropTypes.string,

  itemProps: PropTypes.any,
  itemPropsFn: PropTypes.func,

  headerRenderFn: PropTypes.func,
  insert: PropTypes.objectOf(PropTypes.func),

  list: PropTypes.arrayOf(PropTypes.any),
  gridConfig: PropTypes.objectOf(PropTypes.number),
  /** if true first row in grid will pass preloadImage prop */
  preloadFirstRow: PropTypes.bool,
};

ComponentsGrid.defaultProps = {
  itemValuePropName: 'item',
  emptyPlaceholderComponent: R.always(null),
  itemKeyFn: ({key, id}, index) => (
    R.isNil(key)
      ? id + ' ' + index // eslint-disable-line prefer-template
      : key
  ),
};

/**
 * Creates grid with components and default config
 *
 * @param {Object} config
 */
const createComponentsGrid = (
  {
    itemSchema = PropTypes.any,
    defaultGrid = {
      xs: 6,
      md: 4,
      lg: 3,
    },
    ...predefinedProps
  },
) => {
  const Wrapped = React.forwardRef(({gridConfig, ...props}, ref) => (
    <ComponentsGrid
      ref={ref}
      {...predefinedProps}
      {...props}
      gridConfig={gridConfig || defaultGrid}
    />
  ));

  Wrapped.propTypes = {
    list: PropTypes.arrayOf(itemSchema),
  };

  return React.memo(Wrapped);
};

export default createComponentsGrid;
