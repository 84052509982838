import styled from '@adretail/fast-stylesheet/src/react/styled';
import {PlainUnorderedList} from '@ding/core/src/components/Predefined';

/**
 * @see
 *  used in:
 *    MenuModal
 */
const PrimaryMenuList = styled(
  PlainUnorderedList,
  {
    width: '100%',
  },
  {
    index: 3,
  },
);

PrimaryMenuList.displayName = 'PrimaryMenuList';

export default PrimaryMenuList;
