import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';
import {LIGHT_GRAY} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import {Flex} from '../../Predefined';
import AlertIcon from '../../../icons/Alert';

const ImageIconHolder = styled(
  Flex.Centered,
  {
    base: {
      width: '100%',
      height: '100%',
      textAlign: 'center',
      background: [LIGHT_GRAY, '!important'],
      color: 'rgba(127, 127, 127, 0.5)',
    },

    scaleIcon: {
      '& i': {
        maxWidth: '10% !important',
      },
    },
  },
  {
    omitProps: [
      'scaleIcon',
    ],

    classSelector: (classes, {scaleIcon}) => c(
      scaleIcon && classes.scaleIcon,
    ),
  },
);

const ImageIconPlaceholder = ({
  iconComponent: IconComponent,
  ...props
}) => (
  <ImageIconHolder {...props}>
    <IconComponent
      type={null}
      size='big'
      style={{
        margin: '0 20px',
      }}
    />
  </ImageIconHolder>
);

ImageIconPlaceholder.displayName = 'ImageIconPlaceholder';

ImageIconPlaceholder.propTypes = {
  iconComponent: REACT_COMPONENT_CLASS_SCHEMA,
  scaleIcon: PropTypes.bool,
};

ImageIconPlaceholder.defaultProps = {
  iconComponent: AlertIcon,
  scaleIcon: true,
};

export default ImageIconPlaceholder;
