import React from 'react';
import createIcon from '../utils/createIcon';

export default createIcon(
  'Calendar',
  {
    viewBox: '0 0 24.033 24.033',
  },
  <>
    <path d='M733.248,305.943h-2.454v-1.6a.951.951,0,1,0-1.9,0v1.6H718.877v-1.6a.951.951,0,1,0-1.9,0v1.6h-2.455a2.657,2.657,0,0,0-2.653,2.653v16.173a2.657,2.657,0,0,0,2.653,2.653h18.727a2.656,2.656,0,0,0,2.653-2.653V308.6A2.656,2.656,0,0,0,733.248,305.943ZM734,313.8v10.966a.754.754,0,0,1-.752.752H714.521a.753.753,0,0,1-.751-.752V313.8Zm-16.073-3.4a.952.952,0,0,0,.95-.951v-1.6h10.016v1.6a.951.951,0,1,0,1.9,0v-1.6h2.454a.754.754,0,0,1,.752.752v3.3H713.77v-3.3a.753.753,0,0,1,.751-.752h2.455v1.6A.952.952,0,0,0,717.927,310.4Z' transform='translate(-711.868 -303.389)' />
    <path d='M727.448,317.631l-.441-.441a.747.747,0,0,0-1.057,0l-2.431,2.432-1.679-1.679a.666.666,0,0,0-.939,0l-.6.6a.665.665,0,0,0,0,.939l2.677,2.677a.654.654,0,0,0,.494.189.744.744,0,0,0,.533-.218l3.447-3.446A.747.747,0,0,0,727.448,317.631Z' transform='translate(-711.868 -303.389)' />
  </>,
);
