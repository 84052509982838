import React from 'react';

import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {createMessageValidator} from '@ding/controls/src/Input/ValidatedInput';

import {
  FormRow,
} from '@ding/control-groups/src/Form';

import {PredefinedInputs} from '@ding/controls/src/';

const EnterPasswordsGroup = ({l, value}) => {
  const t = useTranslate();

  return (
    <>
      <FormRow>
        <PredefinedInputs.ValidatedPassword
          {...l.input('master')}
          required
        />
      </FormRow>

      <FormRow>
        <PredefinedInputs.ValidatedPassword
          key={value?.master}

          {...l.input('slave')}
          required
          placeholder={
            t('website.shared.titles.repeat_password')
          }
          validatorFn={
            createMessageValidator(
              newValue => !(value?.master !== newValue),
              t('website.errors.validators.passwords_not_equal'),
            )
          }
        />
      </FormRow>
    </>
  );
};

EnterPasswordsGroup.displayName = 'EnterPasswordsGroup';

export default linkedInputs(
  {
    groupName: 'value',
  },
)(EnterPasswordsGroup);
