import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';

import * as Colors from '@ding/constants/src/colorSchema';

import CheckIcon from '@ding/core/src/icons/Check';
import Button from '@ding/controls/src/Button';
import {RoundedIconBox} from '@ding/core/src/components/Parts';

const css = {
  base: {
    position: 'relative',
    border: 0,
    color: Colors.MUTED_GRAY,
    '&:hover': {
      color: Colors.DARK,
    },
  },

  checked: {
    color: Colors.LIGHT_RED,
    '&:hover': {
      color: Colors.LIGHT_MUTED_RED,
    },
  },

  icon: {
    position: 'absolute',
    left: '2px',
    bottom: '2px',
  },

  iconChecked: {
    left: '8px',
    bottom: '6px',
  },
};

const ImageCheckbox = ({classes, src, alt, withSmallIcon, value, onChange}) => (
  <Button
    type={Button.Type.NO_DECORATION}
    className={c(
      classes.base,
      value && classes.checked,
    )}
    onClick={
      () => onChange(!value)
    }
  >
    <RoundedIconBox
      src={src}
      alt={alt}
      muted={value}
    />

    {(value || withSmallIcon) && (
      <CheckIcon
        className={c(
          classes.icon,
          value && classes.iconChecked,
        )}
        type={null}
        size={
          value ? 'medium' : 'xs'
        }
      />
    )}
  </Button>
);

ImageCheckbox.displayName = 'ImageCheckbox';

ImageCheckbox.propTypes = {
  withSmallIcon: PropTypes.bool,
  value: PropTypes.bool,
  src: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
};

ImageCheckbox.defaultProps = {
  value: false,
  withSmallIcon: false,
};

export default injectClassesSheet(css, {index: 1})(ImageCheckbox);
