import React from 'react';
import PropTypes from 'prop-types';

import OptionalGQLSection from '@ding/control-groups/src/Section/gql/OptionalGQLSection';
import {LeafletsQuery} from '@ding/api/src/gql/leafletsQuery';
import LeafletsGrid from '../Grid/LeafletsGrid';

const OptionalLeafletsGridSection = ({t, small, queryProps, ...props}) => (
  <OptionalGQLSection
    queryComponent={LeafletsQuery}
    queryProps={{
      ...queryProps,
      variables: {
        limit: small ? 6 : 4,
        ...queryProps?.variables,
      },
    }}
    gridComponent={LeafletsGrid}

    {...props}
    {...small && {
      gridProps: {
        ...props.gridProps,
        itemProps: {
          size: 'small',
          ...props.gridProps?.itemProps,
        },
        gridConfig: {
          xs: 6,
          md: 3,
          lg: 2,
        },
      },
    }}
  />
);

OptionalLeafletsGridSection.displayName = 'OptionalLeafletsGridSection';

OptionalLeafletsGridSection.propTypes = {
  small: PropTypes.bool,
};

export default OptionalLeafletsGridSection;
