import React from 'react';
import {Helmet} from 'react-helmet';

import logoVectorUrl from '@ding/server/resources/logo/vector-logo.svg';

import {useUA} from '@adretail/basic-components/src/Context/UAProvider';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';
import {trackComponent} from '@ding/interia';

import {HomeLink} from '@ding/layout/src/components/Links';

export const HeaderLogoHolder = styled(
  HomeLink,
  {
    display: 'inline-block',
    position: 'relative',
    top: 3,

    ...createBreakpointClasses(
      {
        xs: {
          width: 80,
          marginRight: 5,
        },

        md: {
          width: 90,
          marginRight: 13,
        },
      },
    ),
  },
  {
    index: 2,
  },
);

HeaderLogoHolder.displayName = 'HeaderLogoHolder';

const HeaderLogo = ({src, ...props}) => {
  const title = useTranslate('website.bottom_bar')('home');
  const ua = useUA();

  const logoSrc = src || logoVectorUrl;

  return (
    <>
      <Helmet>
        <link rel='preload' href={logoSrc} as='image' type='image/svg+xml' fetchPriority='high' />
      </Helmet>

      <HeaderLogoHolder {...props} title={title} aria-label={title}>
        <img
          alt='Ding website logo'
          {...(
            ua.mobile
              ? {width: 80, height: 38}
              : {width: 90, height: 43}
          )}
          src={logoSrc}
        />
      </HeaderLogoHolder>
    </>
  );
};

HeaderLogo.displayName = 'HeaderLogo';

export default trackComponent(
  {
    onClick({ga4}) {
      ga4.menuClickLogo();
    },
  },
)(HeaderLogo);
