import PropTypes from 'prop-types';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import {
  RIBBON_GREEN,
  RIBBON_RED,
  WHITE,
} from '@ding/constants/src/colorSchema';

const Ribbon = styled.div(
  {
    base: {
      position: 'absolute',
      top: 20,
      right: -35,
      fontWeight: 700,
      textTransform: 'uppercase',
      padding: [4, 15],
      transform: 'rotateZ(45deg)',
      width: 140,
      textAlign: 'center',
      letterSpacing: '1px',
      color: WHITE,
    },

    small: {
      top: 10,
      right: -50,
      padding: [2, 15],
      fontSize: '11px',
    },

    green: {
      background: RIBBON_GREEN,
    },

    red: {
      background: RIBBON_RED,
    },
  },
  {
    omitProps: ['color', 'small'],
    classSelector: (classes, {color, small}) => [
      classes[color],
      small && classes.small,
    ],
  },
);

Ribbon.displayName = 'Ribbon';

Ribbon.propTypes = {
  color: PropTypes.oneOf(['green', 'red']),
  small: PropTypes.bool,
};

Ribbon.defaultProps = {
  color: 'green',
};

export default Ribbon;
