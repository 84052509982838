import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'Minus',
  {
    viewBox: '0 0 16 2',
  },
  <path d='M0,0H16V2H0Z' />,
);
