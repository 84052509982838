import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'FlagPolish',
  {
    viewBox: '0 0 14 14',
  },
  <>
    <path fill='#F0F0F0' d='M0 0h14v7H0z' />
    <path fill='#D80027' d='M0 7h14v7H0z' />
  </>,
);
