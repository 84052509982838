import * as R from 'ramda';

import selectResponseData from '@adretail/basic-helpers/src/getters/selectResponseData';

import usePromiseCallback from '@adretail/basic-hooks/src/base/usePromiseCallback';
import useGqlContext from './useGqlContext';

const useMutation = (
  mutation,
  {
    silent,
    selectorFn = R.identity,
  } = {},
) => {
  const {
    client,
    refetchQuery,
  } = useGqlContext();

  const [promiseFn, promiseFlags] = usePromiseCallback(
    async (variables, headers) => selectResponseData(
      selectorFn,
      await client.mutate(mutation, variables, headers),
    ),
    {
      silent,
      rethrow: true,
      cacheKeys: [
        silent,
        client,
        mutation,
        selectorFn,
      ],
      errorSelectorFn: e => e.gqlErrors || true,
    },
  );

  return [
    promiseFn,
    {
      ...promiseFlags,
      refetchQuery,
    },
  ];
};

export default useMutation;
