import React from 'react';

import createGqlFunctionalWrapper from '@ding/api/src/gql/decorators/createGqlFunctionalWrapper';

import {ContractorsQuery} from '@ding/api/src/gql/contractorsQuery';
import ContractorsGrid from './ContractorsGrid';

const ContractorsGridContainer = ({contractors, ...props}) => (
  <ContractorsGrid
    list={
      contractors.items || contractors
    }
    {...props}
  />
);

export default createGqlFunctionalWrapper(
  ContractorsQuery,
  'contractors',
)(ContractorsGridContainer);
