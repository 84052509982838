import * as NOTIFICATION_TYPES from '@ding/notifications/src/constants/notificationTypes';
import * as NOTIFICATION_SOURCES from '@ding/constants/src/notificationSources';
import * as ITEM_TYPES from '@ding/constants/src/itemTypes';

export default {
  list: {
    empty: 'Brak powiadomień',
  },
  item: {
    expire: '%{} dni do końca',
    new: {
      [NOTIFICATION_TYPES.LEAFLET]: 'Nowa gazetka',
      [NOTIFICATION_TYPES.PRODUCT]: 'Nowa oferta',
    },
  },
  alerts: {
    geolocation: {
      title: 'Lepiej dopasowane oferty?',
      button: 'Zezwól na geolokalizację',
    },
  },
  buttons: {
    open_notifications_list: 'Otwórz listę powiadomień',
    clear_notifications: 'Wyczyść powiadomienia',
    mark_all_as_read: 'Oznacz wszystkie jako przeczytane',
    observe: 'Obserwuj',
    already_observed: 'Obserwujesz',
  },
  add_items_box: {
    header: 'Aby otrzymywać powiadomienia o najnowszych ofertach:',
    add: {
      contractors: 'Dodaj sieci handlowe',
      products: 'Dodaj ulubione produkty',
    },
  },
  toast: {
    error: 'Nastąpił problem podczas dodawania elementu do powiadomień :(',

    clear_list: {
      success: 'Wyczyszczono listę powiadomień!',
      error: 'Nastąpił problem podczas czyszczenia listy powiadomień :(',
    },
    subscribed_to_list: {
      [ITEM_TYPES.CONTRACTOR]: 'Ustawiono powiadomienia o nowych gazetkach sieci %{}',
      [ITEM_TYPES.CUSTOM]: 'Ustawiono powiadomienia o nowych produktach %{}',
    },
    unsubscribed_from_list: {
      [ITEM_TYPES.CONTRACTOR]: 'Wyłączono powiadomienia o nowych gazetkach sieci %{}',
      [ITEM_TYPES.CUSTOM]: 'Wyłączono powiadomienia o nowych produktach %{}',
    },
  },
  tabs: {
    [NOTIFICATION_TYPES.LEAFLET]: 'Gazetki',
    [NOTIFICATION_TYPES.PRODUCT]: 'Oferty',
  },
  titles: {
    sources: {
      [NOTIFICATION_SOURCES.CONTRACTOR]: 'Oferty z ulubionych sklepów',
      [NOTIFICATION_SOURCES.OTHER]: 'Oferty z innych sklepów',
    },
    calendar: {
      holiday: 'święto',
      shopping_sunday: 'niedziela handlowa',
      non_shopping_sunday: 'niedziela niehandlowa',
      shop_opened: 'możesz zrobić zakupy',
      shop_closed: 'sklepy zamknięte',
    },
    expire: {
      start_date: {
        absolute: 'ważna od %{}',
        other: 'ważna za %{} dni',
        one: 'ważna od jutra',
        zero: 'ważna od dzisiaj',
      },
      end_date: {
        absolute: 'wygaśnie dnia %{}',
        other: 'do końca %{} dni',
        one: 'do końca 1 dzień',
        zero: 'ostatni dzień!',
        expired: 'wygasła dnia %{}',
      },
    },
  },
};
