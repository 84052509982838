const addDaysDelta = (
  delta,
  dateString,
) => {
  const date = new Date(dateString);
  const deltaDay = date.getDate() + delta;

  date.setDate(deltaDay);

  return date;
};

export default addDaysDelta;
