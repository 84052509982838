import React, {useState, useImperativeHandle, forwardRef} from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import * as R from 'ramda';

import {DIRECTION} from '@ding/constants/src/schemas/basic';
import {
  WHITE,
  LIGHT_BORDER,
  DARK_BORDER,
  SEE_THROUGH_DARK,
  SEE_THROUGH_WHITE,
} from '@ding/constants/src/colorSchema';

import {useUA} from '@adretail/basic-components/src/Context/UAProvider';
import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';

import {Text} from '@ding/core/src/components/Predefined';
import IconButton from '@ding/controls/src/Button/IconButton';

// mobile
import Expand from '@ding/core/src/icons/Expand';
import ArrowRightIcon from '@ding/core/src/icons/ArrowRight';
import ArrowLeftIcon from '@ding/core/src/icons/ArrowLeft';

import TitledSidebar from './index';

const css = {
  base: {
    position: 'absolute',
    top: 0,
    zIndex: 1,

    height: '100%',
    display: 'flex',

    '& ::-webkit-scrollbar-track': {
      background: 'none',
    },
  },

  mobile: {
    top: '100%',
    width: '100%',
    height: '55%',
  },

  sideBookmark: {
    display: 'flex',
    flexFlow: 'column-reverse nowrap',
  },

  'sideBookmark-RIGHT': {borderRight: '1px solid', borderColor: 'inherit'},
  'sideBookmark-LEFT': {borderLeft: '1px solid', borderColor: 'inherit'},

  'direction-RIGHT': {right: 0},
  'direction-LEFT': {left: 0},

  light: {
    background: SEE_THROUGH_WHITE,
    borderColor: LIGHT_BORDER,
  },

  dark: {
    background: SEE_THROUGH_DARK,
    borderColor: DARK_BORDER,
    color: WHITE,
  },

};
/**
 * @requires DIRECTION from constants
 */
const checkDirections = (number, direction) => (
  direction === DIRECTION.LEFT ? R.negate(number) : number
);

const ExpandableInnerSidebar = forwardRef(({
  title, direction, type, sidebarWidth,
  classes, children, ...props
}, ref) => {
  const [toggle, setToggle] = useState(true);
  const ua = useUA();

  // allow change toggle state from outside
  useImperativeHandle(ref, () => ({
    value: toggle,
    setToggle: value => setToggle(value),
  }));

  const sideBookmark = (
    <IconButton
      type={type === 'light' ? 'dark' : 'light'}
      size='medium'
      iconSize='medium-small'
      icon={direction === DIRECTION.LEFT ? ArrowRightIcon : ArrowLeftIcon}
      onClick={() => setToggle(currState => !currState)}
      iconProps={{
        verticalFlip: toggle,
      }}
    />
  );

  const sidebar = (
    <TitledSidebar
      key='sidebar-body'
      style={{
        width: sidebarWidth,
      }}
      type='none'
      padding={null}
      noTitleStyles
      title={(
        <>
          <Text
            style={{padding: 20}}
            weight={600}
            uppercase
            block
          >
            {title}
          </Text>
        </>
        )}
      {...ua.mobile && {
        className: c(
          classes.base,
          classes.mobile,
          classes[type],
          classes[`direction-${direction}`],
        ),
        style: {
          transform: `translateY(${toggle ? '-100%' : '-56px'})`,
          transition: '400ms cubic-bezier(0,0,.2,1)',
        },
        closeButtonComponent: Expand,
        closeButtonProps: {
          verticalFlip: toggle,
          type: type === 'light' ? 'dark' : 'light',
        },
        onClose: () => setToggle(currState => !currState),
      }}
      {...props}
    >
      {children}
    </TitledSidebar>
  );

  const fullBody = [
    sidebar,
    (
      <div
        key='sidebar-sideBookmark'
        className={c(
          classes.sideBookmark,
          classes[`sideBookmark-${direction}`],
        )}
      >
        {sideBookmark}
      </div>
    ),
  ];

  return (
    ua.mobile
      ? sidebar
      : (
        <div
          className={c(
            classes.base,
            classes[type],
            classes[`direction-${direction}`],
          )}
          style={{
            transform: `translateX(${toggle ? '0' : checkDirections(sidebarWidth, direction)}px)`,
            transition: '400ms cubic-bezier(0,0,.2,1)',
          }}
        >
          {direction === DIRECTION.LEFT
            ? fullBody
            : R.reverse(fullBody)}
        </div>
      )
  );
});

ExpandableInnerSidebar.displayName = 'ExpandableSidebar';

ExpandableInnerSidebar.propTypes = {
  title: PropTypes.node.isRequired,
  direction: PropTypes.oneOf([DIRECTION.LEFT, DIRECTION.RIGHT]),
  type: PropTypes.oneOf(['light', 'dark']),
  sidebarWidth: PropTypes.number,
};

ExpandableInnerSidebar.defaultProps = {
  direction: DIRECTION.LEFT,
  type: 'light',
  sidebarWidth: 400,
};

export default injectClassesSheet(css)(ExpandableInnerSidebar);
