import React from 'react';
import {Route} from 'react-router-dom';

import {ID_SCHEMA} from '@adretail/schemas';

import translate from '@ding/i18n';
import showUserToast from '@ding/controls/src/ToastAlert/types/showUserToast';

import {ConfirmResetPasswordMutation} from '@ding/auth/src/gql/components';
import SetPasswordSection from './SetPasswordSection';

export default
@translate
class SetPasswordSectionContainer extends React.PureComponent {
  static propTypes = {
    token: ID_SCHEMA.isRequired,
  };

  onSubmitReset = (history, confirmResetPassword) => async ({recaptcha, passwords}) => {
    const {t, token} = this.props;

    const confirmed = await showUserToast(
      ({error}) => t(`website.auth.toast.reset_password.${error ? 'error' : 'success'}`),
      null,
      () => confirmResetPassword(
        {
          resetPasswordToken: token,
          password: passwords?.master,
          recaptcha,
        },
      ),
    );

    if (confirmed)
      history.push('/');
  };

  render() {
    return (
      <Route
        render={({history}) => (
          <ConfirmResetPasswordMutation>
            {({confirm, errors}) => (
              <SetPasswordSection
                errors={errors}
                onSubmit={
                  this.onSubmitReset(history, confirm)
                }
              />
            )}
          </ConfirmResetPasswordMutation>
        )}
      />
    );
  }
}
