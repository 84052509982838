import React from 'react';
import * as R from 'ramda';

import renderFunctionalChildren from '@adretail/basic-helpers/src/ui/renderFunctionalChildren';

const createFunctionalWrapper = (
  FunctionalComponent,
  propName,
  functionalPickProps = [],
) => (
  Component => ({children, ...props}) => (
    <FunctionalComponent {...R.pick(functionalPickProps, props)}>
      {data => (
        <Component
          {...R.omit(functionalPickProps, props)}
          {...{
            [propName]: data,
          }}
        >
          {children && renderFunctionalChildren(
            children,
            data,
          )}
        </Component>
      )}
    </FunctionalComponent>
  )
);

export default createFunctionalWrapper;
