import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import IconButton from '@ding/controls/src/Button/IconButton';
import TimesIcon from '@ding/core/src/icons/Times';

const CloseButton = ({type, ...props}) => {
  const title = useTranslate('website.shared.titles')('close');

  return (
    <IconButton
      title={title}
      type={type}
      size='small'
      iconSize='small'
      icon={TimesIcon}
      {...props}
    />
  );
};

CloseButton.displayName = 'CloseButton';

export default CloseButton;
