import * as R from 'ramda';
import {
  useRef,
  useReducer,
} from 'react';

import useIsomorphicLayoutEffect from '@adretail/basic-hooks/src/base/useIsomorphicLayoutEffect';
import {shallowDiffers} from '@adretail/basic-helpers/src/base/shallowCompareObjects';

const useSubscribedStateContext = (subscribeContext, selectorFn) => {
  const [, forceRender] = useReducer(s => s + 1, 0);

  const latestSelectorRef = useRef();
  const latestStateRef = useRef(null);

  latestSelectorRef.current = selectorFn;
  if (subscribeContext && latestStateRef.current === null) {
    let selectorState = null;

    if (!subscribeContext?.getState) {
      selectorState = {
        stateNotInitialized: true,
      };
    } else
      selectorState = (selectorFn || R.identity)(subscribeContext.getState());

    latestStateRef.current = selectorState;
  }

  useIsomorphicLayoutEffect(
    () => {
      const updateState = (newComputedState) => {
        if (selectorFn) {
          const selectorValue = latestSelectorRef.current(newComputedState);
          if (shallowDiffers(latestStateRef.current, selectorValue)) {
            latestStateRef.current = selectorValue;
            forceRender({});
          }

          return;
        }

        latestStateRef.current = newComputedState;
        forceRender({});
      };

      if (!subscribeContext?.subscribe)
        return undefined;

      subscribeContext.subscribe(updateState);
      return () => subscribeContext.unsubscribe(updateState);
    },
    [subscribeContext],
  );

  return latestStateRef.current;
};

export default useSubscribedStateContext;
