import shoppingSundayCalendarInfo from '../docs/shoppingSundayCalendarInfo.txt';
import shoppingSundayCalendarInfoVerbose from '../docs/shoppingSundayCalendarInfoVerbose.txt';

export default {
  title: 'Niedziele handlowe',

  section: {
    title: 'Niedziele handlowe',
    header: 'Niedziele i dni świąteczne z zakazem handlu',
    headerTag: 'H1',
  },

  titles: {
    month_description_header: 'Niedziele handlowe w %{} %{}',
  },

  descriptions: {
    default: shoppingSundayCalendarInfo,
    verbose: shoppingSundayCalendarInfoVerbose,
  },
};
