import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'Geolocalize',
  {
    viewBox: '0 0 24 24',
  },
  <>
    <path d='M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z' />
    <path d='M24,11H20.94A9,9,0,0,0,13,3.06V0H11V3.06A9,9,0,0,0,3.06,11H0v2H3.06A9,9,0,0,0,11,20.94V24h2V20.94A9,9,0,0,0,20.94,13H24ZM12,19a7,7,0,1,1,7-7A7,7,0,0,1,12,19Z' />
  </>,
);
