import React from 'react';
import PropTypes from 'prop-types';

import LinkRedirectStateContext from '@ding/layout/src/components/Links/context/LinkRedirectStateContext';

export {AGGREGATOR_SOURCES} from '../../constants';

const ProvideAggregatorLinkSource = ({source, children}) => (
  <LinkRedirectStateContext.Provider
    value={{
      source,
    }}
  >
    {children}
  </LinkRedirectStateContext.Provider>
);

ProvideAggregatorLinkSource.displayName = 'ProvideAggregatorLinkSource';

ProvideAggregatorLinkSource.propTypes = {
  source: PropTypes.string.isRequired,
};

export default ProvideAggregatorLinkSource;
