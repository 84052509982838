import {SORT_OPTIONS} from '@ding/constants/src/filtersOptions';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import dig from '@adretail/basic-helpers/src/base/dig';

import useQuery from '@ding/tiny-gql/src/react/hooks/useQuery';
import DefaultTinyQuery from '@ding/control-groups/src/DefaultTinyQuery';

import {LEAFLETS_QUERY} from '../leafletsQuery';

const DEFAULT_LATEST_VARIABLES = {
  limit: 1,
  orderBy: SORT_OPTIONS.PUB_DATE,
};

const firstLeafletSelector = dig(['leaflets', 'items', 0]);

const LatestLeafletQuery = provideProps(
  ({variables}) => ({
    query: LEAFLETS_QUERY,
    responseSelector: firstLeafletSelector,
    variables: {
      ...DEFAULT_LATEST_VARIABLES,
      ...variables,
    },
  }),
  true,
)(DefaultTinyQuery);

export const useLatestLeafletQuery = ({variables, ...params} = {}) => useQuery(
  LEAFLETS_QUERY,
  {
    ...params,
    responseSelector: firstLeafletSelector,
    variables: {
      ...DEFAULT_LATEST_VARIABLES,
      ...variables,
    },
  },
);

export default LatestLeafletQuery;
