import React from 'react';
import PropTypes from 'prop-types';
import {
  Redirect,
  Route,
} from 'react-router-dom';

import {PROPS_SCHEMA} from '@adretail/schemas';
import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import AuthConditionalRender from './AuthConditionalRender';

const AuthConditionalRoute = ({render, authProps, unauthorizedRedirectPath, ...props}) => (
  <Route
    {...props}
    render={
      routeProps => (
        <AuthConditionalRender
          {...authProps}
          authorizedRenderFn={
            () => render(routeProps)
          }
          unauthorizedRenderFn={
            () => (
              <Redirect to={unauthorizedRedirectPath} />
            )
          }
        />
      )
    }
  />
);

AuthConditionalRoute.displayName = 'AuthConditionalRoute';

AuthConditionalRoute.propTypes = {
  unauthorizedRedirectPath: PropTypes.string,
  authProps: PROPS_SCHEMA.isRequired,
  render: PropTypes.func.isRequired,
};

AuthConditionalRoute.defaultProps = {
  unauthorizedRedirectPath: '/',
};

AuthConditionalRoute.GuestOnly = provideProps(
  {
    authProps: {
      guestOnly: true,
    },
  },
)(AuthConditionalRoute);

AuthConditionalRoute.LoggedOnly = provideProps(
  {
    authProps: {
      loggedOnly: true,
    },
  },
)(AuthConditionalRoute);

export default AuthConditionalRoute;
