import {useViewerState} from '@ding/slide-viewer/src/Viewer/context/components/ViewerState';

const useCurrentlyGridVisibleLeaflet = () => useViewerState(
  ({
    getLeafletInfo,
    getVisiblePagesIndices,
  }) => ({
    id: getLeafletInfo().creatorMeta.id,
    pageIndices: getVisiblePagesIndices().indices,
  }),
);

export default useCurrentlyGridVisibleLeaflet;
