import React from 'react';

import authRoutes from '@ding/auth/src/client/Layout/Routes';
import accountSettingsRoutes from '@ding/account-settings/src/Layout/Routes';

import {HOME_URL_SCHEMA} from '@ding/layout/src/components/Links/HomeLink';
import {HOME_URL_SCHEMA as UK_HOME_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/uk/HomeLink';
import {HOME_URL_SCHEMA as PL_HOME_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/pl/HomeLink';

import {AUTHOR_URL_SCHEMA} from '@ding/layout/src/components/Links/AuthorLink';
import {AUTHOR_URL_SCHEMA as UK_AUTHOR_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/uk/AuthorLink';
import {AUTHOR_URL_SCHEMA as PL_AUTHOR_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/pl/AuthorLink';

import {ARTICLE_URL_SCHEMA} from '@ding/layout/src/components/Links/ArticleLink';
import {ARTICLE_URL_SCHEMA as UK_ARTICLE_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/uk/ArticleLink';
import {ARTICLE_URL_SCHEMA as PL_ARTICLE_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/pl/ArticleLink';

import {ARTICLES_URL_SCHEMA} from '@ding/layout/src/components/Links/ArticlesLink';
import {ARTICLES_URL_SCHEMA as UK_ARTICLES_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/uk/ArticlesLink';
import {ARTICLES_URL_SCHEMA as PL_ARTICLES_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/pl/ArticlesLink';

import {LEAFLET_URL_SCHEMA} from '@ding/layout/src/components/Links/LeafletLink';
import {LEAFLET_URL_SCHEMA as UK_LEAFLET_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/uk/LeafletLink';
import {LEAFLET_URL_SCHEMA as PL_LEAFLET_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/pl/LeafletLink';

import {LEAFLETS_URL_SCHEMA} from '@ding/layout/src/components/Links/LeafletsLink';
import {LEAFLETS_URL_SCHEMA as UK_LEAFLETS_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/uk/LeafletsLink';
import {LEAFLETS_URL_SCHEMA as PL_LEAFLETS_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/pl/LeafletsLink';

import {CONTACT_URL_SCHEMA} from '@ding/layout/src/components/Links/ContactLink';
import {CONTACT_URL_SCHEMA as UK_CONTACT_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/uk/ContactLink';
import {CONTACT_URL_SCHEMA as PL_CONTACT_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/pl/ContactLink';

import {CONTRACTORS_URL_SCHEMA} from '@ding/layout/src/components/Links/ContractorsLink';
import {CONTRACTORS_URL_SCHEMA as UK_CONTRACTORS_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/uk/ContractorsLink';
import {CONTRACTORS_URL_SCHEMA as PL_CONTRACTORS_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/pl/ContractorsLink';

import {LATEST_CONTRACTOR_LEAFLET_URL_SCHEMA, CONTRACTOR_URL_SCHEMA} from '@ding/layout/src/components/Links/ContractorLink';
import {LATEST_CONTRACTOR_LEAFLET_URL_SCHEMA as UK_LATEST_CONTRACTOR_LEAFLET_URL_SCHEMA, CONTRACTOR_URL_SCHEMA as UK_CONTRACTOR_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/uk/ContractorLink';
import {LATEST_CONTRACTOR_LEAFLET_URL_SCHEMA as PL_LATEST_CONTRACTOR_LEAFLET_URL_SCHEMA, CONTRACTOR_URL_SCHEMA as PL_CONTRACTOR_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/pl/ContractorLink';

import {STORE_URL_SCHEMA} from '@ding/layout/src/components/Links/StoreLink';
import {STORE_URL_SCHEMA as UK_STORE_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/uk/StoreLink';
import {STORE_URL_SCHEMA as PL_STORE_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/pl/StoreLink';

import {STORES_BY_CITY_URL_SCHEMA} from '@ding/layout/src/components/Links/StoresByCityLink';
import {STORES_BY_CITY_URL_SCHEMA as UK_STORES_BY_CITY_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/uk/StoresByCityLink';
import {STORES_BY_CITY_URL_SCHEMA as PL_STORES_BY_CITY_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/pl/StoresByCityLink';

import {PRODUCTS_URL_SCHEMA} from '@ding/layout/src/components/Links/ProductsLink';
import {PRODUCTS_URL_SCHEMA as UK_PRODUCTS_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/uk/ProductsLink';
import {PRODUCTS_URL_SCHEMA as PL_PRODUCTS_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/pl/ProductsLink';

import {PRODUCT_URL_SCHEMA} from '@ding/layout/src/components/Links/ProductLink';
import {PRODUCT_URL_SCHEMA as UK_PRODUCT_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/uk/ProductLink';
import {PRODUCT_URL_SCHEMA as PL_PRODUCT_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/pl/ProductLink';

import {TAG_URL_SCHEMA} from '@ding/layout/src/components/Links/TagLink';
import {TAG_URL_SCHEMA as UK_TAG_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/uk/TagLink';
import {TAG_URL_SCHEMA as PL_TAG_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/pl/TagLink';

import {MOBILE_APP_URL_SCHEMA} from '@ding/layout/src/components/Links/MobileAppLink';
import {MOBILE_APP_URL_SCHEMA as UK_MOBILE_APP_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/uk/MobileAppLink';
import {MOBILE_APP_URL_SCHEMA as PL_MOBILE_APP_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/pl/MobileAppLink';

import {SHOPPING_SUNDAYS_URL_SCHEMA} from '@ding/layout/src/components/Links/ShoppingSundaysLink';
import {SHOPPING_SUNDAYS_URL_SCHEMA as UK_SHOPPING_SUNDAYS_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/uk/ShoppingSundaysLink';
import {SHOPPING_SUNDAYS_URL_SCHEMA as PL_SHOPPING_SUNDAYS_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/pl/ShoppingSundaysLink';

import {SEARCH_URL_SCHEMA} from '@ding/layout/src/components/Links/SearchLink';
import {SEARCH_URL_SCHEMA as UK_SEARCH_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/uk/SearchLink';
import {SEARCH_URL_SCHEMA as PL_SEARCH_URL_SCHEMA} from '@ding/layout/src/components/Links/lang/pl/SearchLink';

import * as Routes from './LazyLoadableRoutes';

/** NOTE:
 * There are two tricky things, but they are important to working for:
 * 1. Chunks
 * Every position in baseRoutes contains field routeModule,
 * route module must be path to module provided in LazyLoadableRoutes
 *
 * 404 chunks are created in @ding/routes/src/404/index.jsx
 * and imported for routing here to prevent creating extra chunk with single
 * @ding/routes/src/404/index.jsx file
 *
 * 2. Alternate links (i18nPathes field and much imports)
 * i18nPathes have to contains all url schemas used by other languages
 * If path is a array, they have to be stripped to choose correct path and place correct
 * <link rel='alternate'/>
 */
/*

*/
import FourOFour from './404';

const baseRoutes = [
  ...authRoutes,
  ...accountSettingsRoutes,

  {
    path: HOME_URL_SCHEMA,
    i18nPathes: [{pl: PL_HOME_URL_SCHEMA, uk: UK_HOME_URL_SCHEMA}],
    component: () => <Routes.Home />,
    routeModule: './Home',
    exact: true,
  },
  {
    path: SEARCH_URL_SCHEMA,
    i18nPathes: [{pl: PL_SEARCH_URL_SCHEMA, uk: UK_SEARCH_URL_SCHEMA}],
    component: Routes.Search,
    routeModule: './Search',
  },

  {
    path: PRODUCT_URL_SCHEMA,
    i18nPathes: [{pl: PL_PRODUCT_URL_SCHEMA, uk: UK_PRODUCT_URL_SCHEMA}],
    component: Routes.Product,
    routeModule: './Product',
    exact: true,
  },

  {
    path: PRODUCTS_URL_SCHEMA,
    i18nPathes: [{pl: PL_PRODUCTS_URL_SCHEMA, uk: UK_PRODUCTS_URL_SCHEMA}],
    component: Routes.Products,
    routeModule: './Products',
  },

  {
    path: AUTHOR_URL_SCHEMA,
    i18nPathes: [{pl: PL_AUTHOR_URL_SCHEMA, uk: UK_AUTHOR_URL_SCHEMA}],
    component: Routes.Author,
    routeModule: './Author',
    exact: true,
  },

  {
    path: ARTICLE_URL_SCHEMA,
    i18nPathes: [{pl: PL_ARTICLE_URL_SCHEMA, uk: UK_ARTICLE_URL_SCHEMA}],
    component: Routes.Article,
    routeModule: './Article',
    exact: true,
  },

  {
    path: ARTICLES_URL_SCHEMA,
    i18nPathes: [
      {pl: PL_ARTICLES_URL_SCHEMA[0], uk: UK_ARTICLES_URL_SCHEMA[0]},
      {pl: PL_ARTICLES_URL_SCHEMA[1], uk: UK_ARTICLES_URL_SCHEMA[1]},
    ],
    component: Routes.Articles,
    routeModule: './Articles',
    exact: true,
  },

  {
    path: LEAFLET_URL_SCHEMA,
    i18nPathes: [{pl: PL_LEAFLET_URL_SCHEMA, uk: UK_LEAFLET_URL_SCHEMA}],
    component: Routes.Leaflet,
    routeModule: './Leaflet',
    exact: true,
  },

  {
    path: LEAFLETS_URL_SCHEMA,
    i18nPathes: [
      {pl: PL_LEAFLETS_URL_SCHEMA[0], uk: UK_LEAFLETS_URL_SCHEMA[0]},
      {pl: PL_LEAFLETS_URL_SCHEMA[1], uk: UK_LEAFLETS_URL_SCHEMA[1]},
    ],
    component: Routes.Leaflets,
    routeModule: './Leaflets',
    exact: true,
  },

  {
    path: CONTRACTORS_URL_SCHEMA,
    i18nPathes: [
      {pl: PL_CONTRACTORS_URL_SCHEMA[0], uk: UK_CONTRACTORS_URL_SCHEMA[0]},
      {pl: PL_CONTRACTORS_URL_SCHEMA[1], uk: UK_CONTRACTORS_URL_SCHEMA[1]},
    ],
    component: Routes.Contractors,
    routeModule: './Contractors',
    exact: true,
  },

  {
    path: TAG_URL_SCHEMA,
    i18nPathes: [{pl: PL_TAG_URL_SCHEMA, uk: UK_TAG_URL_SCHEMA}],
    component: Routes.Tag,
    routeModule: './Tag',
    exact: true,
  },

  {
    path: STORES_BY_CITY_URL_SCHEMA,
    i18nPathes: [{pl: PL_STORES_BY_CITY_URL_SCHEMA, uk: UK_STORES_BY_CITY_URL_SCHEMA}],
    component: Routes.StoresByCity,
    routeModule: './StoresByCity',
    exact: true,
  },

  {
    path: STORE_URL_SCHEMA,
    i18nPathes: [{pl: PL_STORE_URL_SCHEMA, uk: UK_STORE_URL_SCHEMA}],
    component: Routes.Store,
    routeModule: './Store',
    exact: true,
  },

  // it must be last(due to /:name)!
  {
    path: LATEST_CONTRACTOR_LEAFLET_URL_SCHEMA,
    i18nPathes: [{
      pl: PL_LATEST_CONTRACTOR_LEAFLET_URL_SCHEMA,
      uk: UK_LATEST_CONTRACTOR_LEAFLET_URL_SCHEMA,
    },
    ],
    component: Routes.LatestContractorLeaflet,
    routeModule: './LatestContractorLeafletRoute',
    exact: true,
  },

  {
    path: CONTRACTOR_URL_SCHEMA,
    i18nPathes: [
      {pl: PL_CONTRACTOR_URL_SCHEMA[0], uk: UK_CONTRACTOR_URL_SCHEMA[0]},
      {pl: PL_CONTRACTOR_URL_SCHEMA[1], uk: UK_CONTRACTOR_URL_SCHEMA[1]},
    ],
    component: Routes.Contractor,
    routeModule: './Contractor',
    exact: true,
  },

  {
    path: SHOPPING_SUNDAYS_URL_SCHEMA,
    i18nPathes: [{pl: PL_SHOPPING_SUNDAYS_URL_SCHEMA, uk: UK_SHOPPING_SUNDAYS_URL_SCHEMA}],
    component: Routes.ShoppingSundays,
    routeModule: './ShoppingSundays',
  },

  {
    path: CONTACT_URL_SCHEMA,
    i18nPathes: [{pl: PL_CONTACT_URL_SCHEMA, uk: UK_CONTACT_URL_SCHEMA}],
    component: Routes.Contact,
    routeModule: './Contact',
  },

  {
    path: MOBILE_APP_URL_SCHEMA,
    i18nPathes: [{pl: PL_MOBILE_APP_URL_SCHEMA, uk: UK_MOBILE_APP_URL_SCHEMA}],
    component: Routes.MobileApp,
    routeModule: './MobileApp',
  },

  // if no route hit show  404 page
  {
    path: '/',
    component: ({staticContext}) => {
      if (staticContext) {
        staticContext.statusCode = 404;
      }
      return <FourOFour />;
    },
    routeModule: './FourOFour',
    statusCode: 404,
  },
];

export default baseRoutes;
