import {parameterize} from '@ding/core/src/helpers';

import createLinkComponent, {optionalLinkSegment} from '../../utils/createLinkComponent';

export const AUTHOR_URL_SCHEMA = '/blog/autorzy/:author,:id(\\d+)/:page(\\d+)?';

export const genAuthorURL = ({id, name}, {page} = {}) => (
  `/blog/autorzy/${parameterize(name)},${id}${optionalLinkSegment(page)}`
);

export default createLinkComponent(
  'author',
  genAuthorURL,
);
