import React from 'react';
import PropTypes from 'prop-types';

import {PROPS_SCHEMA} from '@adretail/schemas';
import {
  DIRECTION,
  DIRECTION_SCHEMA,
} from '@ding/constants/src/typeSchema';

import {compareDimensions} from '@ding/core/src/helpers';
import * as idleCallback from '@adretail/basic-helpers/src/async/idleCallback';

import {withDurationDelayedUnmount} from '@adretail/basic-components/src/BasicModal';

import AppearWithTransition from '@adretail/basic-components/src/Anim/AppearWithTransition';
import ModalPortal from '@adretail/basic-components/src/BasicModal/ModalPortal';

import getPageHeaderSize from './utils/getPageHeaderSize';

import CustomScrollbars from '../../../CustomScrollbars';
import TitledSidebar from '../../../TitledSidebar';
import SidebarHolder, {SLIDER_ANIM_PROPS} from './SidebarHolder';

const sidebarModalParentSelector = () => document.querySelector('#react-hydrate-root main') || document.body;

/**
 * @todo Add SSR support!
 */
export default
@withDurationDelayedUnmount(150)
class DesktopSidebar extends React.PureComponent {
  static propTypes = {
    animDuration: PropTypes.number,
    modalHolderRef: PropTypes.func,
    contentProps: PROPS_SCHEMA,
    direction: DIRECTION_SCHEMA,
    title: PropTypes.node,
    headerToolbar: PropTypes.element,

    onUnmount: PropTypes.func,
  };

  static defaultProps = {
    direction: DIRECTION.RIGHT,
  };

  state = {
    headerSize: null,
  };

  static getDerivedStateFromProps(props, {headerSize}) {
    return (
      headerSize
        ? null
        : {
          headerSize: getPageHeaderSize(),
        }
    );
  }

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll);
    idleCallback.create(this.onScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  }

  /**
   * Watches top bar height to truncate sidebar height
   *
   * @todo
   *  It should be optional
   */
  onScroll = () => {
    const {headerSize} = this.state;
    const newHeaderSize = getPageHeaderSize();

    if (!compareDimensions(newHeaderSize, headerSize)) {
      this.setState({
        headerSize: newHeaderSize,
      });
    }
  }

  isVerticalNavbar = () => {
    const {headerSize} = this.state;

    return headerSize?.width < headerSize?.height;
  }

  render() {
    const {headerSize} = this.state;
    const {
      animDuration,
      className,
      children,
      title,
      headerToolbar,
      rightTitlebarToolbar,
      contentProps,
      direction,
      padding,

      // from withDurationDelayedUnmount
      modalHolderRef,
      visible,
      onClose,
    } = this.props;

    const content = (
      <AppearWithTransition
        in={visible}
        duration={animDuration}
        transition={
          `transform ${animDuration}ms cubic-bezier(0,0,.2,1)`
        }
        {...SLIDER_ANIM_PROPS[direction]}
      >
        {(transitionStyle) => {
          const vertical = this.isVerticalNavbar();

          return (
            <SidebarHolder
              ref={modalHolderRef}
              direction={direction}
              className={className}
              vertical={vertical}
              style={{
                ...transitionStyle,
                ...(
                  vertical
                    ? {
                      margin: `0 ${headerSize.width}px`,
                      height: '100vh',
                    }
                    : {
                      margin: `${headerSize.height}px 0`,
                      height: `calc(100vh - ${headerSize.height}px)`,
                    }
                ),
              }}
            >
              <CustomScrollbars>
                <TitledSidebar
                  type='light'
                  onClose={
                    onClose && (() => onClose(
                      {
                        source: 'button',
                      },
                    ))
                  }
                  {...{
                    title,
                    padding,
                    headerToolbar,
                    rightTitlebarToolbar,
                  }}
                  {...contentProps}
                >
                  {children}
                </TitledSidebar>
              </CustomScrollbars>
            </SidebarHolder>
          );
        }}
      </AppearWithTransition>
    );

    return (
      <ModalPortal portalParentSelector={sidebarModalParentSelector}>
        {content}
      </ModalPortal>
    );
  }
}
