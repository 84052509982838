import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {PROPS_SCHEMA} from '@adretail/schemas';

import AsyncSpinnerForm from '@ding/control-groups/src/Form/AsyncSpinnerForm';
import Recaptcha from './Recaptcha';

/**
 * Adds captcha value key value before submit form
 *
 * @export
 */
export default class RecaptchaForm extends React.Component {
  static propTypes = {
    recaptchaProps: PROPS_SCHEMA,
    beforeSubmitValueMapper: PropTypes.func,
  };

  static defaultProps = {
    beforeSubmitValueMapper: R.identity,
    recaptchaProps: {
      action: 'login',
    },
  };

  beforeSubmitValueMapper = fetchRecaptchaToken => async (formValue) => {
    const {beforeSubmitValueMapper} = this.props;

    return beforeSubmitValueMapper(
      {
        recaptcha: {
          token: await fetchRecaptchaToken(),
        },
        ...formValue,
      },
    );
  };

  render() {
    const {recaptchaProps, ...props} = this.props;

    return (
      <Recaptcha {...recaptchaProps}>
        {fetchRecaptchaToken => (
          <AsyncSpinnerForm
            {...props}
            beforeSubmitValueMapper={
              this.beforeSubmitValueMapper(fetchRecaptchaToken)
            }
          />
        )}
      </Recaptcha>
    );
  }
}
