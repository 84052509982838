import React from 'react';
import PropTypes from 'prop-types';

import {WHITE} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';

import {Flex} from '@ding/core/src/components/Predefined';
import PlusIcon from '@ding/core/src/icons/Plus';
import MinusIcon from '@ding/core/src/icons/Minus';

import IconButton from '../../Button/IconButton';
import MinMaxValidatedInput from './MinMaxValidatedInput';

const Container = styled(
  Flex.Centered,
  {
    justifyContent: 'space-around',
    width: 200,
    height: 40,
    padding: 5,
    borderRadius: 5,
    backgroundColor: WHITE,
  },
);

const SmallInput = styled(
  MinMaxValidatedInput,
  {
    width: 40,
    height: 30,
    padding: [0, 10],
    lineHeight: '30px',
    fontSize: 13.5,
    textAlign: 'center',
    borderRadius: 20,
  },
);

export default
@linkedInputs(null, true)
class PlusMinusInput extends React.Component {
  static propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
  }

  static defaultProps = {
    step: 1,
    min: 0,
    max: Number.MAX_SAFE_INTEGER,
  }

  onSubtract = () => {
    const {
      step, min,
      value, l,
    } = this.props;

    l.onInputEvent(
      Math.max(min, +value - step),
    );
  }

  onAdd = () => {
    const {
      step, max,
      value, l,
    } = this.props;

    l.onInputEvent(
      Math.min(max, +value + step),
    );
  }

  render() {
    const {
      l, valueLabel,
      min, max,
      children,
    } = this.props;

    return (
      <Container>
        <IconButton
          icon={MinusIcon}
          iconSize='small'
          onClick={this.onSubtract}
        />

        <SmallInput
          {...l.input()}
          showErrorMessage={false}
          range={{
            min,
            max,
          }}
        />

        <IconButton
          icon={PlusIcon}
          iconSize='small'
          onClick={this.onAdd}
        />

        {valueLabel}
        {children}
      </Container>
    );
  }
}
