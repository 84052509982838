import React from 'react';

import toggleable from '@adretail/basic-decorators/src/base/toggleable';
import MobileSelectBottomList from '../SelectButton/MobileSelectBottomList';

const PopoverWideMobileToggle = ({
  toggleRenderFn, toggled,
  children,
  relativeWrap,
  onToggle, onClearToggle,
  ...props
}) => (
  <>
    {toggled && (
      <MobileSelectBottomList
        {...props}
        onBlur={onClearToggle}
      >
        {children}
      </MobileSelectBottomList>
    )}
    {toggleRenderFn(
      null,
      {
        toggled,
        onToggle,
      },
    )}
  </>
);

PopoverWideMobileToggle.displayName = 'PopoverWideMobileToggle';

export default toggleable(false, true)(PopoverWideMobileToggle);
