import ssr from '@adretail/basic-helpers/src/base/ssr';

export const PWA_INSTALLED_KEY = 'pwa-installed';

if (!ssr) {
  try {
    if (window.matchMedia('(display-mode: standalone)').matches)
      localStorage[PWA_INSTALLED_KEY] = 1;
  } catch (e) {
    console.error(e);
  }
}

export const isPWAInstalled = () => (
  ssr
    ? false
    : localStorage[PWA_INSTALLED_KEY] === '1'
);

const useIsPWAInstalled = () => isPWAInstalled();

export default useIsPWAInstalled;
