import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';
import styled from '@adretail/fast-stylesheet/src/react/styled';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/typeSchema';

import Skeleton from '@ding/core/src/components/Predefined/Skeleton';

import CardHolder from '../../CardHolder';

/** @satisfies {{[x:string]: React.CSSProperties | {[x:string]: React.CSSProperties}}} */
const css = {
  leaflet: {
    position: 'relative',
    width: '100%',
    padding: 4,
    overflow: 'hidden',
  },
};

const CoverSkeleton = styled(
  Skeleton,
  {
    base: {
      border: '1px solid rgba(255, 255, 255, 0.16)',
    },

    'size-big': {
      paddingBottom: '141.42%',
    },
    'size-medium': {
      paddingBottom: '125.42%',
    },
    'size-small': {
      paddingBottom: '119.42%',
    },
    'size-tiny': {
      paddingBottom: '115.47%',
    },
    'size-square-small': {
      paddingBottom: '111.43%',
    },
  },
  {
    classSelector: (classes, {size}) => [
      size && classes[`size-${size}`],
    ],
  },
);

const ContractorLogoSkeleton = styled(
  Skeleton,
  {
    base: {
      height: 36,
      width: 100,
      margin: '5px auto',
      padding: '2px 0',
      // NOTE: WARNING: background-clip restrict background only to content, and because Skeleton has rounded corners adding padding cause that background color don't reach to border
      backgroundClip: 'content-box',
    },

    'size-tiny': {
      height: 24,
      width: '100%',
    },
  },
  {
    classSelector: (classes, {size}) => [
      size && classes[`size-${size}`],
    ],
  },
);

/**
 * @note this component is only visuals consistent with original component any change to title means that styles need to be recalculated
 */
const CardTitleSkeleton = styled(
  Skeleton,
  {
    base: {
      height: 36,
      margin: '5px auto',
      padding: '2px 0',
      backgroundClip: 'content-box',
    },

    'size-tiny': {
      height: 42,
    },
  },
  {
    classSelector: (classes, {size}) => [
      size && classes[`size-${size}`],
    ],
  },
);

const DurationSkeleton = styled(
  Skeleton,
  {
    base: {
      height: 21,
      width: '70%',
      marginInline: 'auto',
      padding: '2px 0',
      backgroundClip: 'content-box',
    },

    'size-tiny': {
      height: 18,
      width: '90%',
    },
  },
  {
    classSelector: (classes, {size}) => [
      size && classes[`size-${size}`],
    ],
  },
);

const ContractorTitleSkeleton = styled(
  Skeleton,
  {
    height: 17,
    width: '50%',
    marginInline: 'auto',
    padding: '2px 0',
    backgroundClip: 'content-box',
  },
);

/**
 * Skeleton placeholder for LeafletCard component
 * @param {object} props component props
 * @param {string} [props.size='small'] - display lead fragment
 * @param {boolean} [props.spaced=false] -
 * @param {boolean} [props.showTitles=true] -
 * @param {boolean} [props.showDate=false] -
 * @param {boolean} [props.showLogo=true] -
 * @param {boolean} [props.bordered=true] -
 * @param {boolean} [props.rounded=true] -
 * @param {string} [props.background] -
 * @param {string} [props.title] -
 *
 * @note archived variant is not supported because i need to know result from api to know if leaflet is archived
 * @note Ribbon variant is not supported because i need to know result from api to know if leaflet has ribbon
 *
 * @see LeafletCard - main component
 * @returns skeleton for LeafletCard component
 */
const LeafletCardSkeleton = ({
  spaced, className, classes,
  size,
  showTitles,
  showDate,
  showLogo,
  bordered,
  rounded,
  background,
  title,
}) => (
  <CardHolder
    spaced={spaced}
    rounded={rounded}
    background={background}
    bordered={bordered}
    className={c(
      classes.leaflet,
      className,
    )}
    hoverable
  >
    <CoverSkeleton size={size} />

    {showLogo && (
      <ContractorLogoSkeleton size={size} />
    )}

    {title && (
      <CardTitleSkeleton size={size} />
    )}

    {(showDate || showTitles) && (
      <DurationSkeleton size={size} />
    )}

    {showTitles && (
      <ContractorTitleSkeleton />
    )}
  </CardHolder>
);

LeafletCardSkeleton.displayName = 'LeafletCardSkeleton';

LeafletCardSkeleton.propTypes = {spaced: PropTypes.bool,
  size: PropTypes.oneOfType([
    SIZE_ARRAY_SCHEMA,
    PropTypes.oneOf([
      'square-small',
    ]),
  ]),
  showTitles: PropTypes.bool,
  showDate: PropTypes.bool,
  showLogo: PropTypes.bool,
  bordered: PropTypes.bool,
  rounded: PropTypes.bool,
  background: PropTypes.string,
  title: PropTypes.node,
};
LeafletCardSkeleton.defaultProps = {
  size: 'small',
  spaced: false,
  showTitles: true,
  showDate: false,
  showLogo: true,
  bordered: true,
  rounded: true,
};

const CSSLeafletCard = injectClassesSheet(css, {index: 1})(LeafletCardSkeleton);

export const MemoizedLeafletCard = React.memo(CSSLeafletCard);

export default CSSLeafletCard;
