import React from 'react';

import {RegisterMutation} from '@ding/auth/src/gql/components';
import RegisterForm from './Form';

const RegisterFormContainer = props => (
  <RegisterMutation>
    {(mutate, {errors}) => (
      <RegisterForm
        {...props}
        errors={errors}
        onRegisterUser={mutate}
      />
    )}
  </RegisterMutation>
);

RegisterFormContainer.displayName = 'RegisterFormContainer';

export default React.memo(RegisterFormContainer);
