import React from 'react';

import {useTranslate} from '@ding/i18n';
import {useTrackers} from '@ding/interia';

import ThreeDots from '@ding/core/src/icons/bottom-bar/ThreeDots';
import IconButton from '@ding/controls/src/Button/IconButton';
import {SeparatedListItem} from '@ding/core/src/components/Parts/Lists/SeparatedItemsList';

import {ContactLink, MobileAppLink} from '@ding/layout/src/components/Links';
import ChangeLanguageWithCheckboxPopup, {CurrentLanguageLabel} from '@ding/layout/src/components/LayoutParts/Popups/ChangeLanguageWithCheckboxPopup';

import {HeaderItem} from '../../HeaderToolbar';
import HeaderListPopover from './HeaderListPopover';

const InfoList = () => {
  const t = useTranslate('website.bottom_bar.help');
  const {ga4} = useTrackers();

  return [
    <SeparatedListItem
      key='contact'
      wrapContent={false}
      role='menuitem'
    >
      <ContactLink
        rel='nofollow'
        onClick={() => ga4.menuClickContact()}
      >
        {t('contact')}
      </ContactLink>
    </SeparatedListItem>,

    <SeparatedListItem
      key='mobile_app'
      wrapContent={false}
      role='menuitem'
      onClick={() => ga4.menuClickDownloadApp()}
    >
      <MobileAppLink
        direct
        target='_blank'
        rel='nofollow'
      >
        {t('mobile_app')}
      </MobileAppLink>
    </SeparatedListItem>,

    <ChangeLanguageWithCheckboxPopup
      key='language'
      triggerRenderFn={
        ({onClick}) => (
          <SeparatedListItem
            key='language'
            wrapContent={false}
            role='menuitem'
            onClick={onClick}
          >
            <CurrentLanguageLabel />
          </SeparatedListItem>
        )
      }
    />,
  ];
};

const InfoItem = ({style, margin, subtitle, popoverDirection}) => {
  const t = useTranslate();
  const {ga4} = useTrackers();
  const title = t('website.bottom_bar.menu');

  return (
    <HeaderItem
      style={style}
      margin={margin}
      subtitle={subtitle}
    >
      <HeaderListPopover
        toggleComponent={IconButton}
        type='light'
        direction={popoverDirection}
        icon={ThreeDots}
        iconProps={{
          size: 'big',
        }}
        listProps={{role: 'presentation'}}
        popoverProps={{
          aria: {role: 'menu', 'aria-labelledby': 'headerMenuButton'},
        }}
        mapListItems={false}
        id='headerMenuButton'
        title={title.title}
        aria-label={title.title}
        aria-haspopup='menu'
        onToggle={() => ga4.menuClickPopover()}
      >
        {() => <InfoList />}
      </HeaderListPopover>
    </HeaderItem>
  );
};

InfoItem.displayName = 'InfoItem';

export default InfoItem;
