import PropTypes from 'prop-types';

import {LIST_ITEM_SCHEMA} from '@adretail/schemas';
import {
  CONTRACTOR_SCHEMA,
  TAG_SCHEMA,
  PRODUCT_SCHEMA,
} from '@ding/constants/src/typeSchema';

export const SEARCH_PARAMS_SCHEMA = PropTypes.shape({
  item: LIST_ITEM_SCHEMA,
});

export const SEARCH_DATA_SCHEMA = PropTypes.shape(
  {
    contractors: PropTypes.arrayOf(CONTRACTOR_SCHEMA),
    tags: PropTypes.arrayOf(TAG_SCHEMA),
    products: PropTypes.arrayOf(PRODUCT_SCHEMA),
  },
);
