import React from 'react';
import styled from '@adretail/fast-stylesheet/src/react/styled';

import {DARK} from '@ding/constants/src/colorSchema';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {useTrackers} from '@ding/interia';

import {PlainUnorderedList} from '@ding/core/src/components/Predefined';
import ChangeLanguageWithCheckboxPopup, {CurrentLanguageLabel} from '@ding/layout/src/components/LayoutParts/Popups/ChangeLanguageWithCheckboxPopup';
import * as Links from '@ding/layout/src/components/Links';

const MenuLinksHolder = styled(
  PlainUnorderedList,
  {
    padding: '10px 25px',
  },
  {
    index: 3,
  },
);

const MobileMenuLink = styled.li(
  {
    color: DARK,
    fontWeight: 700,
    fontSize: 19,
    lineHeight: '48px',
  },
);

const SectionsLinksList = () => {
  const t = useTranslate('website.header.menu_items');
  const {ga4} = useTrackers();

  return (
    <MenuLinksHolder role='navigation' aria-label='secondary-navigation'>
      <MobileMenuLink onClick={() => ga4.menuClickDotModalLeaflets()}>
        <Links.LeafletsLink>
          {t('leaflets')}
        </Links.LeafletsLink>
      </MobileMenuLink>

      <MobileMenuLink onClick={() => ga4.menuClickDotModalProducts()}>
        <Links.ProductsLink>
          {t('products')}
        </Links.ProductsLink>
      </MobileMenuLink>

      <MobileMenuLink onClick={() => ga4.menuClickDotModalShops()}>
        <Links.ContractorsLink>
          {t('contractors')}
        </Links.ContractorsLink>
      </MobileMenuLink>

      <MobileMenuLink onClick={() => ga4.menuClickDotModalBlogs()}>
        <Links.ArticlesLink>
          {t('articles')}
        </Links.ArticlesLink>
      </MobileMenuLink>

      <MobileMenuLink onClick={() => ga4.menuClickDotModalSundays()}>
        <Links.ShoppingSundays>
          {t('shopping_sundays')}
        </Links.ShoppingSundays>
      </MobileMenuLink>

      <MobileMenuLink onClick={() => ga4.menuClickDotModalContact()}>
        <Links.ContactLink>
          {t('contact')}
        </Links.ContactLink>
      </MobileMenuLink>

      <MobileMenuLink onClick={() => ga4.menuClickDotModalDownloadApp()}>
        <Links.MobileAppLink>
          {t('mobile_app')}
        </Links.MobileAppLink>
      </MobileMenuLink>

      <ChangeLanguageWithCheckboxPopup
        triggerRenderFn={({onClick}) => (
          <MobileMenuLink onClick={onClick}>
            <CurrentLanguageLabel />
          </MobileMenuLink>
        )}
      />
    </MenuLinksHolder>
  );
};

SectionsLinksList.displayName = 'SectionsLinksList';

export default React.memo(SectionsLinksList);
