import React from 'react';
import PropTypes from 'prop-types';

import * as R from 'ramda';

import env from '@cdd/internal-server/src/constants/env';
import ssr from '@adretail/basic-helpers/src/base/ssr';
import providePageI18n from '@ding/i18n/src/providePageI18n';
import withRollbarErrorBoundry from '@adretail/rollbar/src/decorators/withRollbarErrorBoundry';

import {injectClassesSheet} from '@adretail/fast-stylesheet/src/react';
import baseRoutes from '@ding/routes';
import {WindowAPIProvider} from '@ding/window-api';

import {CustomScrollbars} from '@ding/controls/src/';
import hideRecaptchaBox from '@ding/auth/src/client/styles/hideRecaptchaBox';

import RouteMapper from './components/RouteMapper';
import SSRRouter from './components/SSRRouter';
import PageProviders from './PageProviders';

const css = {
  '@global': {
    'html, body': {
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,
      border: 0,
    },

    body: {
      extend: hideRecaptchaBox,
      fontSize: 15,
      fontWeight: 500,

      '& *': {
        boxSizing: 'border-box',
        fontFamily: 'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      },
    },
  },
};

const PageRoot = ({routerProps, routeProps, provideData}) => (
  <CustomScrollbars>
    <PageProviders
      features={env.client.features}
      abTests={provideData.ab}
    >
      <SSRRouter {...routerProps}>
        <WindowAPIProvider />
        <RouteMapper
          routes={baseRoutes}
          routeProps={routeProps}
        />
      </SSRRouter>
    </PageProviders>
  </CustomScrollbars>
);

PageRoot.displayName = 'PageRoot';

PageRoot.propTypes = {
  routerProps: PropTypes.shape({
    location: PropTypes.string,
    context: PropTypes.any,
  }),
};

export default R.compose(
  providePageI18n,
  withRollbarErrorBoundry(
    env[ssr ? 'server' : 'client'].rollbarId,
    {
      hostSafeList: [env.shared.website.address],

      /**
      * @description Ignore some JS errors in rollbar:
      * - from Ahrefs site audit bot
      * - missing rodoAPI errors (occured when user blocks Interia RODO scripts)
      * - pushpushgo.com script for push notifications
      */
      checkIgnore: (isUncaught, args, payload) => payload.client.javascript.browser.includes('AhrefsSiteAudit')
            || payload.body.trace.exception.message.match(/missing window\.rodoAPI instance on window/)
            || payload.body.trace.exception.message.match(/ChunkLoadError: Loading chunk[\S\s]+pushpushgo\.com/),
    },
  ),
  injectClassesSheet(css),
)(PageRoot);
