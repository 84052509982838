import React from 'react';

import showToast from '@ding/controls/src/ToastAlert/showToast';

import GeolocalizeIcon from '@ding/core/src/icons/Geolocalize';
import {IconText} from '@ding/core/src/components/Predefined';

const showGeolocationToast = (title, props) => showToast(
  <IconText
    title={title}
    icon={(
      <GeolocalizeIcon
        type='light'
        size='medium'
      />
    )}
  />,
  props,
);

export default showGeolocationToast;
