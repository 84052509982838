import React from 'react';
import {Helmet} from 'react-helmet';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

const PageLoadingErrorMetaTags = () => {
  const t = useTranslate('website.routes.pageLoadingError.meta');

  return (
    <Helmet>
      <title>{t('title')}</title>
      <meta name='title' content={t('title')} />

      <meta property='og:title' content={t('og.title')} />
    </Helmet>
  );
};

PageLoadingErrorMetaTags.displayName = 'PageLoadingErrorMetaTags';

export default React.memo(PageLoadingErrorMetaTags);
