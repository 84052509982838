import React from 'react';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import RectangleIconButton from '@ding/controls/src/Button/RectangleIconButton';
import BellHolder from '@ding/controls/src/CheckBox/ToggleCheckbox/Holders/BellHolder';

import FavouriteItemSwitch from '../FavouriteItemSwitch';

const BellSubscribeIconButton = ({
  style, value, ringAnim,
  children, onChange,
  muteWhenActive, size,
  buttonProps, iconProps,
}) => {
  const t = useTranslate('website.notifications_list.buttons');

  return (
    <RectangleIconButton
      style={style}
      type='primary'
      outline={value}
      size={size}
      muted={value && muteWhenActive}
      icon={(
        <BellHolder
          size={size}
          ringAnim={ringAnim}
          active={value}
          {...iconProps}
        />
      )}
      iconOnRight={false}
      mutedHover
      onClick={
        () => onChange(!value)
      }
      {...buttonProps}
    >
      {t(value ? 'already_observed' : 'observe')}
      {children}
    </RectangleIconButton>
  );
};

export default provideProps(
  {
    triggerComponent: BellSubscribeIconButton,
  },
)(FavouriteItemSwitch);
