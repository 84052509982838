import React from 'react';
import * as R from 'ramda';

import {PROPS_SCHEMA} from '@adretail/schemas';
import PlainUnorderedList from '../../Predefined/PlainUnorderedList';

const WrapElementsList = ({children, clonedProps, ...props}) => (
  <PlainUnorderedList {...props}>
    {React.Children.map(
      children,
      element => (
        R.isNil(element) || element === false
          ? null
          : (
            <li>
              {(
                clonedProps
                  ? React.cloneElement(element, clonedProps)
                  : element
              )}
            </li>
          )
      ),
    )}
  </PlainUnorderedList>
);

WrapElementsList.displayName = 'WrapElementsList';

WrapElementsList.propTypes = {
  clonedProps: PROPS_SCHEMA,
};

export default WrapElementsList;
