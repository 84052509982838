import React from 'react';
import PropTypes from 'prop-types';

import ssr from '@adretail/basic-helpers/src/base/ssr';
import env from '@cdd/internal-server/src/constants/env';

import SingleComponentHTMLRoot from '@cdd/internal-server/src/components/SingleComponentHTMLRoot';
import HeadTags from '../HeadTags';
import NoscriptTags from '../NoscriptTags';
import PageRoot from '../PageRoot';

import safePickEnvFields from '../helpers/safePickEnvFields';

const PageHTMLSkeleton = props => (
  <SingleComponentHTMLRoot
    {...props}
    escapedHydration
    contentOnlyHydrate
    childComponent={PageRoot}
    headTags={
      ssr && (
        <HeadTags manifest={props.provideData.manifest} />
      )
    }
    htmlProps={{lang: APP_LANGUAGE}}
    noscriptTags={
      ssr && (
        <NoscriptTags />
      )
    }
    {...ssr && {
      provideData: {
        ...props.provideData,
        // do not EVER expose server data
        env: safePickEnvFields(env),
      },
    }}
  />
);

PageHTMLSkeleton.displayName = 'PageHTMLSkeleton';

PageHTMLSkeleton.propTypes = {
  provideData: PropTypes.shape({
    manifest: PropTypes.objectOf(PropTypes.string),
  }),
};

export default PageHTMLSkeleton;
