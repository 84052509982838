export default {
  title: 'Coś poszło nie tak',

  meta: {
    title: 'Błąd podczas ładowania',

    og: {
      title: 'Błąd podczas ładowania Ding.pl',
    },
  },

  section: {
    header: 'Coś poszło nie tak',
    caption: {
      text: 'Nie udało się załadować treści, spróbuj odświeżyć stronę lub przejdź do %{} serwisu.',
      action: 'strony głównej',
    },
  },
};
