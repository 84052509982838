import createLinkComponent from '@ding/layout/src/components/Links/utils/createLinkComponent';

export default createLinkComponent(
  null,
  '/regulamin-serwisu.pdf',
  'website.auth.links.website_regulations',
  {
    absolute: true,
  },
);
