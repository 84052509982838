import useFavouriteAggregate, {AGGREGATOR_CRUD} from '@ding/aggregator/src/client/aggregators/useFavouriteAggregate';

import useCreateCustomerFavouriteMutation from './useCreateCustomerFavouriteMutation';
import useDestroyCustomerFavouriteMutation from './useDestroyCustomerFavouriteMutation';

const wrapWithAggregator = (crudAction, aggregate, fn) => async (item) => {
  const result = await fn(item);

  // aggregation must be executed after result is returned
  await aggregate(
    {
      ...item,
      action: crudAction,
    },
  );

  return result;
};

const useCustomerFavouriteMutations = (silent) => {
  const aggregate = useFavouriteAggregate();

  return {
    create: wrapWithAggregator(
      AGGREGATOR_CRUD.CREATE,
      aggregate,
      useCreateCustomerFavouriteMutation(silent),
    ),

    destroy: wrapWithAggregator(
      AGGREGATOR_CRUD.DELETE,
      aggregate,
      useDestroyCustomerFavouriteMutation(silent),
    ),
  };
};

export default useCustomerFavouriteMutations;
