import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {LoadingSpinner} from '@ding/core/src/components/Parts';
import SelectedForUserLeafletsQuery from '@ding/api/src/gql/geo/SelectedForUserLeafletsQuery';
import LeafletsLink from '@ding/layout/src/components/Links/LeafletsLink';
import ProvideAggregatorLinkSource, {AGGREGATOR_SOURCES} from '@ding/aggregator/src/client/components/ProvideAggregatorLinkSource';

import MoreLeafletsButton from '../Buttons/MoreLeafletsButton';
import OptionalLeafletsGridSection from './OptionalLeafletsGridSection';

export const LeafletsLoadingSpinner = () => (
  <LoadingSpinner
    style={{
      height: 400,
    }}
  />
);

const SelectedForUserLeafletsSection = ({
  placement, children, variables,
  withoutLinks,
  ...props
}) => {
  const t = useTranslate();
  const {title, header} = t('website.shared.sections.leaflets_for_user');
  const LinkComponent = withoutLinks ? 'span' : LeafletsLink;

  return (
    <ProvideAggregatorLinkSource source={AGGREGATOR_SOURCES.CUSTOMER_SUGGESTED_LEAFLETS}>
      <OptionalLeafletsGridSection
        title={
          !props.subsection && (
            <LinkComponent>
              {title}
            </LinkComponent>
          )
        }
        header={
          header && (
            <LinkComponent>
              {header}
            </LinkComponent>
          )
        }
        queryComponent={SelectedForUserLeafletsQuery}
        queryProps={{
          placement,
          loadingComponent: LeafletsLoadingSpinner,
          variables: {
            limit: 12,
            ...variables,
          },
        }}
        gridProps={{
          itemProps: {
            imageProps: {
              imageClass: 'C326',
              responsiveData: [
                {breakpoint: 500, imageClass: 'C105'},
                {breakpoint: 990, imageClass: 'C115'},
              ],
            },
          },
        }}
        showLoadingPlaceholder
        skeletonLoading
        expandMoreAnchor={(
          <MoreLeafletsButton translation='website.shared.titles.see_all' />
        )}
        {...props}
      />
    </ProvideAggregatorLinkSource>
  );
};

SelectedForUserLeafletsSection.displayName = 'SelectedForUserLeafletsSection';

export default SelectedForUserLeafletsSection;
