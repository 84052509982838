import * as R from 'ramda';

import {LOCATION_ALREADY_AGGREGATED_ERR_CODE} from '@ding/geolocation/src/constants/formErrors';

import showGeolocationToast from '@ding/controls/src/ToastAlert/types/showGeolocationToast';

import {usePromiseCallback} from '@adretail/basic-hooks/src/base';
import {useGqlClient} from '@ding/tiny-gql/src/react/hooks';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import useGeolocationAggregate from '@ding/aggregator/src/client/aggregators/useGeolocationAggregate';
import getHTML5BasedAddress from '../../../helpers/getHTML5BasedAddress';

import {useGeolocationState} from '../../GeolocationContext';

/**
 * Fetches address from ding api and saves it to context,
 * if error occurs - display toast
 *
 * @export
 */
const useLocalizeCallback = (showToast = true) => {
  const t = useTranslate();
  const gqlClient = useGqlClient();
  const aggregateGeolocation = useGeolocationAggregate();
  const {setAddress} = useGeolocationState();

  const afterExecFn = ({
    errors,
    hasErrors,
  }) => {
    const displayErrorModal = hasErrors && !(
      errors.length
        && errors[0].gqlErrors?.length
        && errors[0].gqlErrors[0].code === LOCATION_ALREADY_AGGREGATED_ERR_CODE
    );

    showToast && showGeolocationToast(
      t(`website.geolocation.toast.${(
        displayErrorModal
          ? 'error'
          : 'success'
      )}`),
      {
        type: displayErrorModal ? 'dark' : 'default',
      },
    );
  };

  return usePromiseCallback(
    // if address is not passed - automatic localize
    async (address) => {
      const data = address || await getHTML5BasedAddress(gqlClient);
      if (!data)
        throw new Error('No provided address!');

      setAddress(data);

      // async geolocation tracker, executed in idle
      try {
        await aggregateGeolocation(
          R.pick(['coords'], data),
        );
      } catch (e) {
        console.error(e);
      }
    },
    {
      rethrow: true,
      afterExecFn,
      cacheKeys: [
        showToast,
      ],
    },
  );
};

export default useLocalizeCallback;
