import styled from '@adretail/fast-stylesheet/src/react/styled';
import {logoBarStyles} from './ContractorLogoBar';

const LeafletCardTitle = styled(
  'h3',
  {
    base: {
      extend: logoBarStyles,

      fontSize: 12,
      fontWeight: 800,
      textAlign: 'center',
      textTransform: 'uppercase',
      lineHeight: `${logoBarStyles.height - 2}px`,
      margin: '5px auto',
    },

    'size-tiny': {
      lineHeight: '20px', // -2 px because of baseline
      height: 42,
    },
  },
  {
    index: 2,
    omitProps: ['size'],
    classSelector: (classes, {size}) => [
      size && classes[`size-${size}`],
    ],
  },
);

LeafletCardTitle.displayName = 'LeafletCardTitle';

export default LeafletCardTitle;
