import useLocalStorageFlagAccessor from '@ding/core/src/hooks/useLocalStorageFlagAccessor';

const usePrevRoutePathAccessor = () => useLocalStorageFlagAccessor(
  'prev-route',
  {
    storage: 'sessionStorage',
  },
);

export default usePrevRoutePathAccessor;
