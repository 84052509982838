import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import textEllipsisOverflow from '@ding/core/src/styles/textEllipsisOverflow';

import {RoundedIconBox} from '@ding/core/src/components/Parts/Images';
import {
  Flex,
  Margin,
} from '@ding/core/src/components/Predefined';

import PlainAutocompleteItem from './Plain';

const NameItemHolder = styled.div(
  {
    extend: textEllipsisOverflow,
    marginLeft: 10,
  },
);

const LogoItemPair = ({logo, name, defaultIcon, children}) => (
  <Flex.Row>
    <RoundedIconBox
      src={logo}
      alt={
        R.is(String, name)
          ? name
          : null
      }
      muted={false}
      color={null}
      {...defaultIcon && {
        defaultIcon,
      }}
    />

    <NameItemHolder>
      {name}
    </NameItemHolder>

    {children && (
      <Margin
        left='auto'
        right={2}
      >
        {children}
      </Margin>
    )}
  </Flex.Row>
);

LogoItemPair.displayName = 'LogoItemPair';

const LogoLinkPlain = ({
  item,
  name,
  linkComponent,
  defaultIcon,
  children,
  ...props
}) => (
  <PlainAutocompleteItem
    {...props}
    linkComponent={linkComponent}
    size={props.size ?? 'small'}
    item={item}
  >
    <LogoItemPair
      logo={
        item.logo || item.image
      }
      name={name || item.name}
      defaultIcon={defaultIcon}
    >
      {children}
    </LogoItemPair>
  </PlainAutocompleteItem>
);

LogoLinkPlain.displayName = 'LogoLinkPlain';

LogoLinkPlain.propTypes = {
  defaultIcon: PropTypes.node,
  linkComponent: REACT_COMPONENT_CLASS_SCHEMA,
};

export default LogoLinkPlain;
