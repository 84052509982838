import React from 'react';
import PropTypes from 'prop-types';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/typeSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';

import {
  Margin,
  Flex,
  Text,
} from '@ding/core/src/components/Predefined';

import ToggleCheckbox from './ToggleCheckbox';

const SIZE_SPACING = {
  small: 2,
  medium: 2,
  big: 3,
};

const CheckboxTitle = styled(
  Text,
  {
    base: {
      display: 'inline-flex',
      alignItems: 'center',
    },

    checked: {
      fontWeight: 600,
    },
  },
  {
    index: 3,
    noSelect: true,
    size: 'medium-small',
    omitProps: ['checked'],
    classSelector: (classes, {checked}) => checked && classes.checked,
  },
);

const TitledCheckbox = ({
  className, children, l,
  toggleRef,
  titleRef,
  flexAlignToggle,
  allowTitleToggle,
  ...props
}) => {
  const toggle = (
    <ToggleCheckbox
      innerRef={toggleRef}
      {...props}
      {...l.input()}
    />
  );

  if (!children)
    return toggle;

  return (
    <Flex
      align={flexAlignToggle}
      inline
      row
      {...allowTitleToggle && {
        style: {
          cursor: 'pointer',
        },
        onClick: () => l.onInputEvent(!props.value),
      }}
    >
      <Margin
        right={SIZE_SPACING[props.size || 'small']}
        flex
      >
        {toggle}
      </Margin>

      {/* Fix flex rendring on WebKit */}
      <CheckboxTitle
        innerRef={titleRef}
        checked={props.value}
        {...flexAlignToggle === 'flex-start' && ({
          style: {
            marginTop: -4,
          },
        })}
      >
        {children}
      </CheckboxTitle>
    </Flex>
  );
};

TitledCheckbox.displayName = 'TitledCheckbox';

TitledCheckbox.propTypes = {
  size: SIZE_ARRAY_SCHEMA,
  flexAlignToggle: PropTypes.string,
  allowTitleToggle: PropTypes.bool,
};

TitledCheckbox.defaultProps = {
  flexAlignToggle: 'center',
  allowTitleToggle: true,
};

export default linkedInputs(null, true)(TitledCheckbox);
