export default {
  about_contractor: '%{} o sieci',
  about_contractor_extended: '%{} dalej o sieci',

  notification_section: {
    header: '%{} gazetki promocyjne',
    city_header: '%{} gazetki promocyjne: %{}',
    street_header: '%{} - %{}, %{}%{}',

    toggle: 'Włącz powiadomienia o najnowszych gazetkach sieci %{}',
    toggle_all_notifications: 'Włącz powiadomienia o gazetkach ulubionych sieci handlowych',
  },

  latest_leaflet: {
    header: 'Aktualna gazetka sieci %{}',
  },

  leaflets: {
    header: ' %{} aktualne gazetki promocyjne',
  },

  archived_leaflets: {
    header: '%{} archiwalne gazetki - %{} zakończone promocje',
  },

  products: {
    header: '%{} najtańsze produkty',
  },

  article: {
    header: '%{} wiadomości handlowe',
  },

  titles: {
    contractor_not_exists: 'Już nie obsługujemy sieci %{}',
  },

  other_category_leaflets: {
    header: 'Inne gazetki promocyjne z kategorii %{}',
  },

  messages: {
    no_leaflets: 'Brak gazetek z sieci %{}',
    city_inactive_stores: {
      title: 'Na dzień %{date} w mieście %{city} nie ma placówek sieci %{contractor}',
      info: 'Jeśli masz informacje o sklepie w tym mieście, %{}',
      action: 'napisz do nas!!',
    },
  },

  pdfs: {
    active: {
      header: '%{} aktualne gazetki w pdf',
    },

    inactive: {
      header: '%{} archiwalne gazetki w pdf',
    },
  },

  faq: {
    header: 'FAQ',
    questions: [
      {
        question: 'Jakie są aktualne promocje i oferty specjalne w sieci sklepów %{contractorName}?',
        answer: 'Aktualne promocje i oferty specjalne w sieci sklepów <b>%{contractorName}</b> można sprawdzić w <a href="#leaflets">gazetkach promocyjnych</a>, dostępnych na naszej stronie lub aplikacji mobilnej Ding.',
      },
      {
        question: 'Czy %{contractorName} publikuje gazetki promocyjne i jakie są w nich zniżki?',
        answer: 'Tak, <b>%{contractorName}</b> publikuje gazetki promocyjne ze zniżkami i ofertami specjalnymi. Gazetki są dystrybuowane regularnie do sklepów oraz dostępne w formie elektronicznej na naszej stronie.',
      },
      {
        question: 'Jak na bieżąco śledzić promocje i oferty w sieci %{contractorName}?',
        answer: 'Najlepszym sposobem na śledzenie promocji i ofert w sieci <b>%{contractorName}</b> jest regularne odwiedzanie strony internetowej Ding.pl lub korzystanie z aplikacji mobilnej Ding. Oprócz tego można zapisać się na powiadomienia o nowych gazetkach sieci na naszej stronie.',
      },
      {
        question: 'Ile sklepów należy do sieci %{contractorName} w Polsce?',
        answer: 'Sieć <b>%{contractorName}</b> obecnie posiada <b>%{storesCount}</b> sklepów w Polsce. Sieć <b>%{contractorName}</b> stale się rozwija i otwiera nowe lokalizacje w całym kraju.',
      },
      {
        question: 'W jakich godzinach otwarte są sklepy %{contractorName}?',
        answer: 'Godziny otwarcia sklepów <b>%{contractorName}</b> mogą się różnić w zależności od lokalizacji i dnia tygodnia. Aktualne godziny otwarcia konkretnej placówki można znaleźć na naszej stronie internetowej.',
      },
      {
        question: 'Gdzie znajdują się sklepy sieci %{contractorName}?',
        answer: 'Sklepy sieci <b>%{contractorName}</b> znajdują się w różnych lokalizacjach w Polsce. Aktualną listę sklepów można znaleźć w sekcji "<a href="#nearest-stores">%{contractorName} sklepy w Twojej okolicy</a>"',
      },
      {
        question: 'Jakie produkty są objęte promocjami w gazetkach sieci %{contractorName}?',
        answer: 'W gazetkach sieci %{contractorName} zwykle znajdują się promocje na różne produkty, w zależności od sezonu i aktualnych ofert. Aktualnie w gazetce "%{leafletName}" obowiązującej od %{durationBegin} do %{durationEnd} do są promocje na: %{productNames}',
      },
    ],
  },
};
