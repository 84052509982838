import React from 'react';
import PropTypes from 'prop-types';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import Section, {SectionCenteredContent} from '@ding/control-groups/src/Section';

const SetNewPasswordSection = ({formTitle, children}) => {
  const t = useTranslate();
  const {title, header} = t('website.auth.sections.reset_password');

  return (
    <Section {...{title, header}}>
      <SectionCenteredContent title={formTitle}>
        {children}
      </SectionCenteredContent>
    </Section>
  );
};

SetNewPasswordSection.displayName = 'SetNewPasswordSection';

SetNewPasswordSection.propTypes = {
  formTitle: PropTypes.string.isRequired,
};

export default SetNewPasswordSection;
