import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'MarkerOutline',
  {
    viewBox: '0 0 18 24',
  },
  <>
    <path d='M9,7A2,2,0,1,1,7,9,2,2,0,0,1,9,7M9,5a4,4,0,1,0,4,4A4,4,0,0,0,9,5Z' />
    <path d='M9,2a6.93,6.93,0,0,1,7,6.84,6.5,6.5,0,0,1-.6,2.76A49.45,49.45,0,0,1,9,21.19,50.08,50.08,0,0,1,2.6,11.6,6.5,6.5,0,0,1,2,8.84,6.93,6.93,0,0,1,9,2M9,0A8.93,8.93,0,0,0,0,8.84a8.49,8.49,0,0,0,.79,3.6A55.56,55.56,0,0,0,8.62,23.83a.51.51,0,0,0,.76,0,55.56,55.56,0,0,0,7.83-11.39A8.34,8.34,0,0,0,18,8.84,8.93,8.93,0,0,0,9,0Z' />
  </>,
);
