import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  TOAST_BACKGROUND,
  TOAST_FOREGROUND,

  TOAST_DANGER_BACKGROUND,
  TOAST_DANGER_FOREGROUND,

  TOAST_DARK_BACKGROUND,
  TOAST_DARK_FOREGROUND,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import {popupZDepth} from '@ding/core/src/styles/zDepth';
import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';

import BasicModal from '@adretail/basic-components/src/BasicModal';

const AnimContainer = styled.div(
  {
    base: {
      position: 'fixed',
      maxWidth: '80vw',
      left: '50%',
      zIndex: 999999,
      transform: 'translateX(-50%)',
    },
    'side-top': {
      top: 0,
    },
    'side-bottom': {
      top: '80%',
    },
  },
  {
    omitProps: ['side'],
    classSelector: (classes, {side}) => classes[`side-${side || 'top'}`],
  },

);

const ToastModalHolder = styled.div(
  {
    base: {
      extend: popupZDepth,
      fontWeight: 700,

      ...createBreakpointClasses({
        xs: {
          minWidth: 250,
          maxWidth: 'calc(100vw - 40px)',

          marginTop: 10,
          padding: 20,
          fontSize: 12,

          borderRadius: 16,
        },

        md: {
          minWidth: 320,
          marginTop: 90,
          padding: 30,
          fontSize: 14,
          borderRadius: 8,
        },
      }),
    },

    dark: {
      background: TOAST_DARK_BACKGROUND,
      color: TOAST_DARK_FOREGROUND,
    },

    success: {
      background: TOAST_BACKGROUND,
      color: TOAST_FOREGROUND,
    },

    danger: {
      background: TOAST_DANGER_BACKGROUND,
      color: TOAST_DANGER_FOREGROUND,
    },

    default: {
      composes: '$success',
    },
  },
  {
    omitProps: ['type'],
    classSelector: (classes, {type}) => classes[type || 'dark'],
  },
);

const ToastAlertModal = ({children, hideTimeout, type, side, onUnmount}) => (
  <BasicModal
    withBackground={false}
    hideTimeout={hideTimeout}
    provideAnimStyle
    onUnmount={onUnmount}
  >
    {(onClose, animStyle) => (
      <AnimContainer
        style={R.omit(['transform'], animStyle)} //NOTE: I didn't want to override transform prop from base class
        side={side}
      >
        <ToastModalHolder type={type}>
          {children}
        </ToastModalHolder>
      </AnimContainer>
    )}
  </BasicModal>
);

ToastAlertModal.displayName = 'ToastAlertModal';

ToastAlertModal.propTypes = {
  hideTimeout: PropTypes.number,
  type: PropTypes.string,
  side: PropTypes.oneOf(['top', 'bottom']),
};

ToastAlertModal.defaultProps = {
  hideTimeout: 3000,
  type: 'default',
  side: 'top',
};

export default ToastAlertModal;
