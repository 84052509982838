import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import translate from '@ding/i18n';

import {SendResetPasswordTokenMutation} from '@ding/auth/src/gql/components';
import SetNewPasswordSection from '../../../SetNewPasswordSection';
import EmailResetPasswordForm from './EmailResetPasswordForm';

const EmailResetPasswordFormContainer = ({t, onEmailSent, ...props}) => (
  <SetNewPasswordSection
    formTitle={
      t('website.auth.sections.reset_password.type_login_email')
    }
  >
    <SendResetPasswordTokenMutation>
      {(resetPasswordToken, {errors}) => (
        <EmailResetPasswordForm
          {...props}
          errors={errors}
          onSubmit={
            async (formValue) => {
              await resetPasswordToken(formValue);
              onEmailSent?.(formValue); // eslint-disable-line
            }
          }
        />
      )}
    </SendResetPasswordTokenMutation>
  </SetNewPasswordSection>
);

EmailResetPasswordFormContainer.displayName = 'EmailResetPasswordFormContainer';

EmailResetPasswordFormContainer.propTypes = {
  onEmailSent: PropTypes.func,
};

export default R.compose(
  React.memo,
  translate,
)(EmailResetPasswordFormContainer);
