import * as R from 'ramda';
import ssr from '@adretail/basic-helpers/src/base/ssr';

const getLazyLoadGlobalFunction = (windowVariable) => {
  if (ssr)
    return R.T;

  if (!window[windowVariable]) {
    const queueExecutorFn = (() => {
      const q = [];
      const fn = (...args) => {
        q.push(args);
      };

      fn.q = q;
      return fn;
    })();

    window[windowVariable] = queueExecutorFn;
  }

  return (...args) => window[windowVariable](...args);
};

export default getLazyLoadGlobalFunction;
