import {gql, GraphQLFragment} from '@ding/tiny-gql';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import DefaultTinyQuery from '@ding/control-groups/src/DefaultTinyQuery';
import AddressFragment from './fragments/Address.graphql';

const ADDRESSES_QUERY = new GraphQLFragment(
  'addressesQuery',
  gql`
    ${AddressFragment}
    query addressesQuery(
      $page: Int = 1,
      $limit: Int = 12,
      $phrase: String,
      $withProvince: Boolean = true,
      $withRegion: Boolean = true,
      $withPostcode: Boolean = true,
      $filters: AddressesFilters,
    ) @locale(lang: ${APP_LANGUAGE}) {
      addresses(
        page: $page,
        limit: $limit,
        phrase: $phrase,
        filters: $filters,
      ) {
        items {
          ...Address
        }
        totalPages
      }
    }
  `,
);

export const AddressesQuery = provideProps(
  {
    query: ADDRESSES_QUERY,
    responseSelector: 'addresses',
  },
)(DefaultTinyQuery);

export default ADDRESSES_QUERY;
