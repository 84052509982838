import {languages} from '@ding/i18n/src/languages';
import createLinkComponent from '../../utils/createLinkComponent';
import {
  optionalNamePairSegment,
  optionalLinkSegment,
} from '../../utils/optionalSegment';

// todo: Check why optional matching for categoryName group is not working
export const LEAFLETS_URL_SCHEMA = [
  `${languages.uk.prefix}/reklamni-gazetky/:categoryName,:categoryId(\\d+)/:page(\\d+)?`,
  `${languages.uk.prefix}/reklamni-gazetky/:page(\\d+)?`,
];

export const genLeafletsURL = ({page, category} = {}) => (
  `${languages.uk.prefix}/reklamni-gazetky${optionalNamePairSegment(category)}${optionalLinkSegment(page)}`
);

export default createLinkComponent(
  [
    'page',
    'category',
  ],
  (_, props) => genLeafletsURL(props),
);
