import styled from '@adretail/fast-stylesheet/src/react/styled';

import {WHITE} from '@ding/constants/src/colorSchema';

const FixedFullscreenLayer = styled.div(
  {
    base: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 99,
      background: WHITE,
      animation: 'fixed-layer-show 500ms normal forwards',
    },

    '@keyframes fixed-layer-show': {
      '0%': {
        opacity: 0,
        transform: 'scale3d(.9,.9,.9) translateY(10px)',
      },
      '100%': {
        opacity: 1,
      },
    },
  },
);

FixedFullscreenLayer.displayName = 'FixedFullscreenLayer';

export default FixedFullscreenLayer;
