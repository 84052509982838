import React from 'react';
import * as R from 'ramda';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {Text} from '@ding/core/src/components/Predefined';

export const addEmptySelectItem = R.prepend(
  {
    id: null,
    emptyItem: true,
  },
);

export const isEmptyItem = R.propEq('emptyItem', true);

const EmptyItem = () => {
  const t = useTranslate();

  return (
    <Text.Muted
      weight={500}
      style={{
        opacity: 0.75,
      }}
    >
      {t('website.shared.titles.clear')}
    </Text.Muted>
  );
};

EmptyItem.displayName = 'EmptyItem';

export default EmptyItem;
