import {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import renderFunctionalChildren from '@adretail/basic-helpers/src/ui/renderFunctionalChildren';
import injectScript from '@adretail/basic-helpers/src/async/injectScript';

import showToast from '@ding/controls/src/ToastAlert/showToast';
import ssr from '@adretail/basic-helpers/src/base/ssr';
import {createObservable} from '@adretail/basic-helpers/src/async/observable';

import {useDingFeatures} from '@ding/features';
import {
  pickUserKind,
  useAuthState,
} from '@ding/auth/src/client/AuthContext';

import {DEFAULT_DING_API_VARIABLE} from './constants/globals';
import {useMiInstituteWatchers} from './hooks';
import useSitebrandingWatcher from './hooks/useSitebrandingWatcher';

const usePPGAccessors = (dingAPI) => {
  useEffect(
    () => {
      if (ssr || !dingAPI.auth?.info)
        return;

      window.ppgId = dingAPI.auth.info.id;

      if (process.env.APP_ENV === 'production')
        injectScript('https://s-eu-1.pushpushgo.com/js/5d9467e12a2414749ac297ae.js', true);
    },
    [],
  );
};

const WindowAPIProvider = ({globalVariable, children}) => {
  const androidBroadcastRef = useRef(null);
  const apiRef = useRef(null);
  const authState = useAuthState();
  const features = useDingFeatures();

  if (!ssr) {
    if (apiRef.current === null) {
      const sendAction = (action, data, broadcastToNativeFrame = false) => {
        let parsedData = null;
        try {
          parsedData = (
            R.is(String, data)
              ? JSON.parse(data)
              : data
          );

          apiRef.current.actions.$stream.notify(
            {
              action,
              broadcastToNativeFrame,
              data: parsedData,
            },
          );
        } catch (e) {
          console.error(e);
        }
      };

      apiRef.current = {
        push: (fn) => {
          try {
            fn(apiRef.current);
          } catch (e) {
            console.error(e);
          }
        },

        auth: null,
        dialog: {
          showToast,
        },

        actions: Object.freeze({
          send: sendAction,
          $stream: createObservable(
            {
              initialData: null,
            },
          ),
        }),
      };

      // watch if there is prehydrate API, if not - force set current ref
      if (!window[globalVariable])
        window[globalVariable] = apiRef.current;
    }

    if (authState.getUser) {
      const user = authState.getUser();
      apiRef.current.auth = {
        info: {
          id: user?.customer?.id || null,
          kind: pickUserKind(user),
        },
      };

      apiRef.current.features = features;
    }

    // broadcasts internal page events to android API
    if (androidBroadcastRef.current === null) {
      androidBroadcastRef.current = apiRef.current.actions.$stream.subscribe((obj) => {
        // DO NOT CHANGE, window.Android must be called using window.Android.onWebAppMessage
        // otherwise it is not firing on Android api
        if (obj?.broadcastToNativeFrame === false || !window.Android?.onWebAppMessage)
          return;

        window.Android.onWebAppMessage(obj.action, JSON.stringify(obj.data || {}));
      });
    }
  }

  useMiInstituteWatchers(apiRef.current);
  usePPGAccessors(apiRef.current);
  useEffect(
    () => {
      const oldQueue = window[globalVariable]?.q;

      window[globalVariable] = window[globalVariable] || {};
      Object.assign(window[globalVariable], apiRef.current); // preserve reference

      oldQueue && R.forEach(
        apiRef.current.push,
        oldQueue,
      );

      return () => {
        delete window[globalVariable];

        /* eslint-disable no-unused-expressions */
        androidBroadcastRef.current?.();
        /* eslint-enable no-unused-expressions */
      };
    },
    [],
  );

  useSitebrandingWatcher();

  return renderFunctionalChildren(
    children,
    {
      apiRef,
      androidBroadcastRef,
    },
  );
};

WindowAPIProvider.displayName = 'WindowAPIProvider';

WindowAPIProvider.propTypes = {
  globalVariable: PropTypes.string,
};

WindowAPIProvider.defaultProps = {
  globalVariable: DEFAULT_DING_API_VARIABLE,
};

export default WindowAPIProvider;
