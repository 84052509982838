import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import withLockDocumentScroll from '@adretail/basic-decorators/src/content/withLockDocumentScroll';
import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';

import {MobileWidePopup} from '../ResponsiveModalToggle/Modals';
import AutocompleteItemsList from '../Autocomplete/AutocompleteItemsList';

/**
 * It should contain <MobileSelectBottomList /> as child. Usedd in autocomplete
 *
 * @export
 */
const MobileSelectBottomListHolder = ({
  type, title, className,
  classes, children, onBlur,
  headerSeparator, closeButtonProps,
  fullPage, containerProps,
  ...props
}) => (
  <MobileWidePopup
    title={title}
    fullPage={fullPage}
    containerProps={containerProps}
    type={type}
    headerSeparator={headerSeparator}
    onUnmount={() => {
      onBlur?.(); // eslint-disable-line
    }}
    closeButtonProps={closeButtonProps}
  >
    {children || (() => (
      <AutocompleteItemsList
        {...props}
        className={classes.list}
      />
    ))}
  </MobileWidePopup>
);

MobileSelectBottomListHolder.displayName = 'MobileSelectBottomListHolder';

MobileSelectBottomListHolder.propTypes = {
  onOutsideClick: PropTypes.func, // provided from Autocomplete render()
};

export default R.compose(
  React.memo,
  withLockDocumentScroll,
  injectClassesSheet(
    {
      list: {
        '& > li': {
          fontWeight: 500,
          whiteSpace: 'normal',
        },
      },
    },
  ),
)(MobileSelectBottomListHolder);
