import React from 'react';
import * as R from 'ramda';

import Autocomplete from '../Autocomplete';

const genDaysList = R.memoizeWith(
  R.identity,
  () => R.times(
    day => ({
      id: day + 1,
      name: R.toString(day + 1),
    }),
    6,
  ),
);

const NthDaysBeforeSelect = props => (
  <Autocomplete
    {...props}
    expandIcon
    returnIdOnly
    allowInputChange={false}
    list={
      genDaysList()
    }
  />
);

NthDaysBeforeSelect.displayName = 'NthDaysBeforeSelect';

export default NthDaysBeforeSelect;
