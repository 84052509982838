import React from 'react';
import PropTypes from 'prop-types';

import {REACT_COMPONENT_CLASS_SCHEMA} from '@adretail/schemas';

const KindItemRenderSwitch = ({item, defaultComponent, kinds, ...props}) => {
  const Component = kinds[item.kind];

  return (
    Component
      ? <Component item={item} {...props} />
      : defaultComponent
  );
};

KindItemRenderSwitch.displayName = 'KindItemRenderSwitch';

KindItemRenderSwitch.propTypes = {
  item: PropTypes.any.isRequired,
  defaultComponent: REACT_COMPONENT_CLASS_SCHEMA,
  kinds: PropTypes.objectOf(REACT_COMPONENT_CLASS_SCHEMA).isRequired,
};

KindItemRenderSwitch.defaultProps = {
  defaultComponent: null,
};

export default React.memo(KindItemRenderSwitch);
