import {gql, GraphQLFragment} from '@ding/tiny-gql';

import decodeJWT from '@adretail/basic-helpers/src/getters/jwt/decodeJWT';
import rootApiGqlClient from '@ding/api/src/clients/rootApiGqlClient';

export const CREATE_GUEST_MUTATION = new GraphQLFragment(
  'registerGuestMutation',
  gql`
    mutation registerGuestMutation($kind: CustomerKind) {
      registerGuest(input: {kind: $kind}) {
        token
      }
    }
  `,
);

export const decodeResponseToken = async (promiseFn) => {
  try {
    const response = await promiseFn();
    const {token} = response || {};

    return {
      decoded: token && decodeJWT(token),
      token,
    };
  } catch (e) {
    return {
      token: null,
    };
  }
};

const createGuestUser = variables => decodeResponseToken(
  () => rootApiGqlClient.mutate(CREATE_GUEST_MUTATION, variables),
);

export default createGuestUser;
