import React, {useMemo} from 'react';
import * as R from 'ramda';

import ssr from '@adretail/basic-helpers/src/base/ssr';
import {getHOCDisplayName} from '@adretail/basic-helpers/src/getters/getDisplayName';

import useTrackers from '../helpers/useTrackers';

const trackComponent = (hooks, keys) => (Component) => {
  const Wrapped = (props) => {
    const trackers = useTrackers();
    const callbacks = useMemo(
      () => R.mapObjIndexed(
        (trackerFn, originalFnName) => (...args) => {
          const fn = props[originalFnName];

          try {
            trackerFn(trackers, props, ...args);
          } catch (e) {
            console.error(e);
          }

          return fn && fn(...args);
        },
        hooks,
      ),
      (keys && keys(props)) || undefined,
    );

    if (ssr)
      return <Component {...props} />;

    return (
      <Component
        {...props}
        {...callbacks}
      />
    );
  };

  Wrapped.displayName = getHOCDisplayName('trackComponent', Component);

  return React.memo(Wrapped);
};

export default trackComponent;
