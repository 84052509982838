import createContextPack from '@ding/tiny-context-state';
import {
  Actions,
  Selectors,
} from '../utils';

const {
  Consumer,
  Provider,
  useStateContext,
} = createContextPack(
  {
    actions: Actions.actions,
    effects: Actions.effects,
    selectors: Selectors,
  },
);

export const useViewerState = useStateContext;

export const ViewerStateConsumer = Consumer;

export const ViewerStateProvider = Provider;
