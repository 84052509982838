import * as R from 'ramda';

import {
  AGGREGATOR_TYPES,
  AGGREGATOR_CRUD,
} from '../../constants';

import createAggregatorHookPartial from '../hooks/createAggregatorHookPartial';

export {
  AGGREGATOR_CRUD,
};

/**
 * @param {String}  action
 * @param {Coords}  favouriteId
 */
const useFavouriteAggregate = createAggregatorHookPartial(
  AGGREGATOR_TYPES.FAVOURITE,
  R.pick(
    [
      'action',
      'kind',
      'name',
    ],
  ),
);

export default useFavouriteAggregate;
