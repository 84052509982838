import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

import {IMAGE_RESPONSIVE_DATA} from '@ding/constants/src/typeSchema';

import changeImageClass from '../../../helpers/changeImageClass';

/**
 * @typedef {{breakpoint: number, imageClass: string}[]} AttachmentsResponsiveData - attachment class variant
 */

const MIME_TYPE = 'image/webp';

/**
 * @description Generates preload link elements for images to prefetch based on responsive data using `<link rel="preload" {...}>`. This crete link tags for images to optimize loading performance by prefetching them based on specified media queries.
 * @param {object} props
 * @param {string} props.src - use custom function used to resolve image URLs.
 * @param {AttachmentsResponsiveData} [props.responsiveData] - specify extra breakpoints for sources media condition, breakpoint works like max-width. Default image data is still required and is used form max size breakpoint. responsiveData don't work when src is used
 * @returns {React.ReactElement | React.ReactElement[]} React Helmet with link tag(s) for prefetching images.
 * @see preload https://web.dev/articles/preload-responsive-images
 */
const PreloadImage = ({responsiveData, src}) => {
  if (responsiveData?.length) {
    // NOTE: Using non-overlapping media queries for images ensures optimal performance by preventing multiple versions of an image from being loaded unnecessarily
    const links = responsiveData.map(({breakpoint, imageClass}, index, array) => {
      let mediaQuery = `(max-width: ${breakpoint}px)`;
      if (index !== 0) {
        mediaQuery = `(min-width: ${array[index - 1].breakpoint + 0.1}px) and ${mediaQuery}`;
      }

      return (
        <link
          key={`${imageClass}-${breakpoint}`}
          rel='preload'
          href={changeImageClass(imageClass, src)}
          as='image'
          type={MIME_TYPE}
          fetchPriority='high'
          media={mediaQuery}
        />
      );
    });

    // default <link> (max size) need to have mediaquery otherwise browser will always prefetch this link
    const lastBreakpoint = responsiveData[responsiveData.length - 1];
    links.push(
      <link
        key={`${lastBreakpoint.imageClass}-${lastBreakpoint.breakpoint + 0.1}`}
        rel='preload'
        href={src}
        as='image'
        type={MIME_TYPE}
        fetchPriority='high'
        media={`(min-width: ${lastBreakpoint.breakpoint + 0.1}px)`}
      />,
    );

    return <Helmet>{links}</Helmet>;
  }

  return (
    <Helmet>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <link rel='preload' href={src} as='image' type={MIME_TYPE} fetchPriority='high' />
    </Helmet>
  );
};

/**
 * @typedef {object} NextGenPicture
 * @property {string} [imageClass] - overwrite attachment framing class in src
 * @property {boolean} [preload=false] - set images to be preloaded (add to `head` `link` with `rel="preload"`)
 * @property {AttachmentsResponsiveData} [responsiveData] - specify extra breakpoints for sources media condition, breakpoint works like max-width. Default image src is still required and is used form max size breakpoint.
 * @property {string} [pictureTagClassName] - pass class to picture element
 * @property {string} alt - image alt attribute
 * @property {string} [src] - image src attribute
*/

/**
 * @param {NextGenPicture & React.ImgHTMLAttributes<HTMLImageElement>} props - image arguments and NextGenPicture props
 * @see documentation https://wiki.inpl.work/Attachments_-_Interfejs_http_do_pobierania_za%C5%82%C4%85cznik%C3%B3w
 * @see preload https://web.dev/articles/preload-responsive-images
 * @return {React.ReactNode} return picture element and optional link element inserted inside <head>
 *
 * @example
 * <NextGenPicture src='https://i.iplsc.com/000CD8SRF6LIQ6ML-C114.webp' alt='image description' />
 * <NextGenPicture
 *   src='https://i.iplsc.com/000CD8SRF6LIQ6ML-C114.webp'
 *   alt='image description'
 *   // to change class only for mobile
 *   imageClass={ua.mobile ? 'C310' : undefined}
 * />
 * <NextGenPicture
 *   src='https://i.iplsc.com/000CD8SRF6LIQ6ML-C114.webp'
 *   alt='test'
 *   // add responsive image using on max-width
 *   responsiveData={[
 *     {breakpoint: 400, frameClass: 'C310'},
 *     {breakpoint: 768, frameClass: 'C306'},
 *   ]}
 * />
 * <NextGenPicture
 *   src='https://i.iplsc.com/000CD8SRF6LIQ6ML-C114.webp'
 *   alt='test'
 *   // preload image before html render
 *   preload
 *   responsiveData={[
 *     {breakpoint: 400, frameClass: 'C310'},
 *     {breakpoint: 768, frameClass: 'C306'},
 *     {breakpoint: 990, frameClass: 'C321'},
 *   ]}
 * />
 *
 * @todo TODO: test skeleton blur loading
 */
const NextGenPicture = ({
  src, alt, loading,
  pictureTagClassName,
  imageClass, responsiveData, preload,
  children, ...props
}) => {
  // NOTE: render picture with empty image because NsfwLockContent
  // TODO: fix card to works correctly without img tag
  if (!src) return <picture className={pictureTagClassName}><img alt={alt} /></picture>;

  const defaultSrc = imageClass ? changeImageClass(imageClass, src) : src;

  let imagesSources = [];
  if (responsiveData?.length) {
    imagesSources = responsiveData.map(({breakpoint, imageClass: frameClass}) => (
      <source
        key={breakpoint}
        media={`(max-width: ${breakpoint}px)`}
        srcSet={changeImageClass(frameClass, src)}
        type={MIME_TYPE}
      />
    ));
  }

  return (
    <>
      <picture className={pictureTagClassName}>
        {imagesSources}
        <img
          {...props}
          // `loading` need to be before `src` because of bug on safari/firefox
          // see https://github.com/facebook/react/issues/25883
          loading={preload ? 'eager' : loading}
          alt={alt}
          // NOTE: src need to be last because safari deal with props and updates,
          // if any prop affecting src (`media`, `sizes` or `srcSet) are before safari will request image immediately before all props changes
          src={defaultSrc}
        />
      </picture>
      {preload && <PreloadImage src={defaultSrc} responsiveData={responsiveData} />}
    </>
  );
};

NextGenPicture.displayName = 'NextGenPicture';

NextGenPicture.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  pictureTagClassName: PropTypes.string,
  preload: PropTypes.bool,
  imageClass: PropTypes.string,
  responsiveData: IMAGE_RESPONSIVE_DATA,
};

export default memo(NextGenPicture);
