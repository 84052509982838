import {useState, memo} from 'react';
import * as R from 'ramda';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';
import {useInterval} from '@adretail/basic-hooks/src/base';

import {TIME_SUFFIXES} from '@ding/constants';

const SUFFIX_TO_SECONDS = R.toPairs(
  {
    SECONDS: {seconds: 1, max: 60},
    MINUTES: {seconds: 60, max: 60},
    HOURS: {seconds: 3600, max: 24},
    DAYS: {seconds: 86400, max: 7},
    WEEKS: {seconds: 604800, max: 4},
    MONTHS: {seconds: 2629743, max: 12},
    YEARS: {seconds: 31556926, max: Infinity},
  },
);

const getDiff = (date) => {
  if (!date)
    return {};

  const diff = (new Date().getTime() - new Date(date).getTime()) / 1000;

  // eslint-disable-next-line no-restricted-syntax
  for (const [suffix, {seconds, max}] of SUFFIX_TO_SECONDS) {
    const value = diff / seconds;
    if (value < max) {
      return {
        suffix,
        value: Number.parseInt(value, 10),
      };
    }
  }

  return {};
};

const TimeAgo = ({date}) => {
  const t = useTranslate();
  const [{value, suffix}, setLabel] = useState(() => getDiff(date));

  useInterval(
    () => {
      setLabel(
        getDiff(date),
      );
    },
    [TIME_SUFFIXES.MINUTES, TIME_SUFFIXES.SECONDS].includes(suffix)
      ? 10_000
      : null,
  );

  return (
    date
      ? t(`website.shared.time.${suffix}`, value)
      : null
  );
};

export default memo(TimeAgo);
