import styled from '@adretail/fast-stylesheet/src/react/styled';

import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';
import {Header} from '@ding/core/src/components/Predefined';
import {
  PRIMARY_FILL,
  LIGHT_BORDER,
} from '@ding/constants';

export default styled(
  Header.H4,
  {
    width: '100%',
    marginTop: 0,
    color: PRIMARY_FILL,
    borderBottom: `1px solid ${LIGHT_BORDER}`,
    fontSize: 23,

    ...createBreakpointClasses({
      xs: {
        marginBottom: 15,
        paddingBottom: 8,
      },

      md: {
        marginBottom: 30,
        paddingBottom: 20,
      },
    }),
  },
);
