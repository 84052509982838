import React from 'react';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import AlertIcon from '../../../icons/Alert';

import IconText from '../../Predefined/IconText';
import AbstractComponentsList from './AbstractComponentsList';

const ErrorsList = provideProps(
  {
    itemComponent: IconText,
    itemValuePropName: 'title',
    itemProps: {
      type: 'primary',
      size: 'small',
      weight: 600,
      icon: <AlertIcon type='primary' />,
    },
  },
)(AbstractComponentsList);

ErrorsList.displayName = 'ErrorsList';

export default ErrorsList;
