import PropTypes from 'prop-types';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import {
  DEFAULT_BOX_BORDER,

  LIGHT_GREEN_BOX_BACKGROUND,
  LIGHT_GREEN_BOX_BORDER,

  LIGHT_RED_BOX_BACKGROUND,
  LIGHT_RED_BOX_BORDER,
  PRIMARY_FILL,

  WARN_BOX_COLOR,
  WARN_BOX_BACKGROUND,
  WARN_BOX_BORDER,
} from '@ding/constants/src/colorSchema';

import Flex from '../Predefined/Flex';

const BorderedRoundedBox = styled(
  Flex,
  {
    base: {
      width: '100%',
      padding: 15,
      borderRadius: 6,
      border: [1, 'solid'],
    },

    default: {
      background: 'initial',
      borderColor: DEFAULT_BOX_BORDER,
    },

    warn: {
      color: WARN_BOX_COLOR,
      background: WARN_BOX_BACKGROUND,
      borderColor: WARN_BOX_BORDER,
    },

    'light-green': {
      background: LIGHT_GREEN_BOX_BACKGROUND,
      borderColor: LIGHT_GREEN_BOX_BORDER,
    },

    'light-red': {
      background: LIGHT_RED_BOX_BACKGROUND,
      borderColor: LIGHT_RED_BOX_BORDER,
    },

    'danger-bold': {
      composes: '$light-red',

      color: PRIMARY_FILL,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
  },
  {
    omitProps: ['type'],
    classSelector: (classes, {type}) => classes[type],
  },
);

BorderedRoundedBox.displayName = 'BorderedRoundedBox';

BorderedRoundedBox.propTypes = {
  type: PropTypes.oneOf(
    [
      'warn',
      'default',
      'light-red',
      'light-green',
      'danger-bold',
    ],
  ),
};

BorderedRoundedBox.defaultProps = {
  type: 'default',
};

export default BorderedRoundedBox;
