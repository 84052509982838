import {STORE_SCHEMA} from '@ding/constants/src/typeSchema';

import createComponentsGrid from '@ding/core/src/components/Utils/createComponentsGrid';
import provideProps from '@adretail/basic-decorators/src/base/provideProps';

import ExpandableOverflowGrid from '@ding/control-groups/src/ExpandableOverflowGrid';
import TextLeafletCard from '../LeafletCard/TextLeafletCard';

const TextLeafletsGrid = createComponentsGrid(
  {
    itemValuePropName: 'leaflet',
    itemSchema: STORE_SCHEMA,
    itemComponent: TextLeafletCard,
    defaultGrid: {
      xs: 6,
      md: 4,
      lg: 3,
    },
  },
);

TextLeafletsGrid.displayName = 'TextLeafletsGrid';

export const OverflowExpandTextLeafletsGrid = provideProps(
  {
    gridComponent: TextLeafletsGrid,
    initialExpand: 8,
  },
)(ExpandableOverflowGrid);

export default OverflowExpandTextLeafletsGrid;
