import React from 'react';
import {SvgIcon} from '../../components/Predefined/Icon';

const createIcon = (displayName, additionalProps, svgData) => {
  const NewIcon = React.forwardRef((props, ref) => (
    <SvgIcon
      innerRef={ref}
      {...additionalProps}
      {...props}
    >
      {svgData}
    </SvgIcon>
  ));

  NewIcon.displayName = `Icon-${displayName}`;

  return NewIcon;
};

export default createIcon;
