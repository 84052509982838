import React from 'react';
import PropTypes from 'prop-types';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import {LeafletsGrid} from '@ding/cards/src/Leaflet/Grid';

const SidebarLeafletsGrid = provideProps(
  ({itemSize, itemProps, gridProps}) => ({
    itemProps: {
      spaced: false,
      showTitles: false,
      showDate: true,
      dateFormat: 'numeric',
      size: itemSize,
      imageFit: 'cover',
      ...itemProps,
    },
    padding: 'small',
    gridConfig: {
      xs: 4,
    },
    ...gridProps,
  }),
  true,
)(LeafletsGrid);

SidebarLeafletsGrid.displayName = 'SidebarLeafletsGrid';

SidebarLeafletsGrid.propTypes = {
  itemSize: PropTypes.string,
};

SidebarLeafletsGrid.defaultProps = {
  itemSize: 'tiny',
};

export default React.memo(SidebarLeafletsGrid);
