import {cacheOneCall} from '@adretail/basic-helpers/src/base/memoizeOne';

// selector is faster than getCurrentSlide
const cacheByLeafletId = cacheOneCall(
  ([{resources: prevResources}], [{resources}]) => (
    prevResources.leaflet?.info.id !== resources.leaflet?.info.id
  ),
);

export default cacheByLeafletId;
