import {gql, GraphQLFragment} from '@ding/tiny-gql';

import useMutation from '@ding/tiny-gql/src/react/hooks/useMutation';

import CustomerFlagsFragment from '@ding/api/src/gql/fragments/CustomerFlags.graphql';
import ContractorFragment from '@ding/api/src/gql/fragments/Contractor.graphql';

import FavouriteListItemFragment from '../fragments/FavouriteListItem.graphql';

const DESTROY_CUSTOMER_FAVOURITE = new GraphQLFragment(
  'destroyCustomerFavourite',
  gql`
    ${CustomerFlagsFragment}
    ${ContractorFragment}
    ${FavouriteListItemFragment}

    mutation destroyCustomerFavourite(
      $name: String!,
      $kind: CustomerFavouriteKind,
      $returnList: Boolean = false
    ) {
      destroyCustomerFavourite(input: {
        name: $name,
        kind: $kind,
      }) {
        errors
        success
        customerFavourites(
          filters: {kind: $kind},
          limit: 999999
        ) @include(if: $returnList) {
          items {
            ...FavouriteListItem
          }
        }
      }
    }
  `,
);

const useDestroyCustomerFavouriteMutation = (silent) => {
  const [mutate] = useMutation(
    DESTROY_CUSTOMER_FAVOURITE,
    {
      silent,
    },
  );

  return mutate;
};

export default useDestroyCustomerFavouriteMutation;
