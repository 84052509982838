export default {
  meta: {
    title: 'Niedziele handlowe %{formattedDate} – sprawdź kiedy będą otwarte sklepy',
    description: 'Sprawdź kiedy wypada niedziela handlowa. Kalendarz niedziel handlowych %{formattedDate}. Dowiedz się kiedy zrobisz zakupy w sklepach.',

    og: {
      title: 'Niedziele handlowe %{formattedDate} – sprawdź kiedy będą otwarte sklepy',
      description: 'Kliknij i zobacz promocje!',
    },
  },
};
