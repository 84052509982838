import React from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';

import translate from '@adretail/i18n/src/decorators/i18n';
import showUserToast from '@ding/controls/src/ToastAlert/types/showUserToast';

export default
@translate
class ToastRedirectOperationForm extends React.PureComponent {
  static propTypes = {
    mutateFn: PropTypes.func.isRequired,
    toastTranslation: PropTypes.string,
    redirectUrl: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
  };

  static defaultProps = {
    redirectUrl: '/',
  };

  onSubmit = history => async (formValue) => {
    const {
      t,
      toastTranslation,
      redirectUrl,
      mutateFn,
    } = this.props;

    const success = await showUserToast(
      ({error}) => t(`${toastTranslation}.${error ? 'error' : 'success'}`),
      null,
      () => mutateFn(formValue),
    );

    if (success && redirectUrl)
      history.push(redirectUrl);

    return success;
  };

  render() {
    const {children} = this.props;

    return (
      <Route
        render={
          ({history}) => children({
            onSubmit: this.onSubmit(history),
            history,
          })
        }
      />
    );
  }
}
