import React from 'react';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import {
  WHITE,
  FB_BUTTON_BACKGROUND,
  FB_BUTTON_HOVER_BACKGROUND,
} from '@ding/constants/src/colorSchema';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import FacebookFIcon from '@ding/core/src/icons/FacebookF';
import {RectangleIconButton} from '@ding/controls/src/';

const FBButton = styled(
  RectangleIconButton,
  {
    background: FB_BUTTON_BACKGROUND,
    color: WHITE,
    height: 35,

    '&:hover': {
      background: FB_BUTTON_HOVER_BACKGROUND,
    },
  },
  {
    icon: FacebookFIcon,
    expanded: true,
    mergeProps: ({iconOnly}) => iconOnly ?? true,
  },
);

FBButton.displayName = 'FBButton';

export const TitledFBButton = (props) => {
  const title = useTranslate('website.auth.buttons')('facebook_login');

  return (
    <FBButton
      {...props}
      iconOnRight={false}
      iconOnly={false}
      iconSpacing={3}
    >
      {title}
    </FBButton>
  );
};

export default FBButton;
