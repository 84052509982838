import React from 'react';
import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import ResponsiveModalToggle from '@ding/controls/src/ResponsiveModalToggle';
import AuthModal from '../AuthModal';

const DEFAULT_DESKTOP_STYLE = {
  width: 350,
};

const AuthResponsiveModalToggle = ({initialTab, ...props}) => {
  const t = useTranslate();

  return (
    <ResponsiveModalToggle
      uaModalPropsFn={
        ({mobile}) => ({
          closeButtonProps: !mobile && {
            type: 'light',
          },
          ...mobile && ({
            title: t('website.auth.titles.layer_title'),
            relativeContent: true,
          }),
        })
      }
      modalProps={{
        modalProps: {disableCloseOnOutsideClick: true},
      }}
      modalContentFn={
        (onCloseModal, ua) => (
          <AuthModal
            {...!ua.mobile && {
              style: DEFAULT_DESKTOP_STYLE,
            }}
            {...{
              ua,
              initialTab,
              onCloseModal,
            }}
          />
        )
      }
      {...props}
    />
  );
};

AuthResponsiveModalToggle.displayName = 'AuthResponsiveModalToggle';

export default AuthResponsiveModalToggle;
