import React from 'react';
import PropTypes from 'prop-types';

import * as idleCallback from '@adretail/basic-helpers/src/async/idleCallback';
import getFixedElementPos from '@adretail/basic-helpers/src/dom/getFixedElementPos';

export default class Sticky extends React.Component {
  static propTypes = {
    // optimize rerenders
    normalizeDelta: PropTypes.bool,

    // if 0, sticky starts from element top pos, if 10 element.top + 10
    topOffset: PropTypes.number,

    // if true, in place of component will be placed empty div to
    // prevent page jump during scroll
    compensation: PropTypes.bool,

    stickyActivateFn: PropTypes.func,
  }

  static defaultProps = {
    topOffset: 0,
    normalizeDelta: true,
    compensation: true,
  };

  nodeRef = null;

  state = {
    sticky: false,
    delta: 0,
  };

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll);
    idleCallback.create(this.updateStaticPos);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    const {initialStaticPos} = this;
    if (!initialStaticPos)
      return;

    const {pageYOffset} = window;
    const {normalizeDelta} = this.props;
    const {
      sticky: oldSticky,
    } = this.state;

    const {top: staticElementTop} = initialStaticPos;

    // check is still sticky
    const newStickyFlag = this.isStickyWindowScroll(staticElementTop);
    const stickyChanged = oldSticky !== newStickyFlag;

    // calc delta between old and new scroll
    if (this.oldScroll === undefined) {
      this.oldScroll = pageYOffset;
      this.oldDelta = this.state.delta;
    }

    const delta = pageYOffset - this.oldScroll;

    // due to mouse issues that causes weird "jumping" sometimes
    // during scroll, it is possible that it is broken mouse but it will
    // be better to create buffer
    this.deltaAccum = (
      !this.deltaAccum || Math.sign(delta) !== Math.sign(this.oldDelta)
        ? 0
        : this.deltaAccum
    ) + Math.abs(delta);

    // assign previous values
    this.oldDelta = delta;
    this.oldScroll = pageYOffset;

    // update state
    if (stickyChanged || this.deltaAccum > 140) {
      this.deltaAccum = 0;

      const newState = {
        changeDirectionScrollY: pageYOffset,
        sticky: newStickyFlag,
        delta: (
          normalizeDelta
            ? Math.sign(delta)
            : delta
        ),
      };

      if (stickyChanged || newState.delta !== this.state.delta)
        this.setState(newState);
    }
  };

  isStickyWindowScroll = (staticElementTop) => {
    const {topOffset, stickyActivateFn} = this.props;
    const {scrollY} = window;

    if (stickyActivateFn) {
      return stickyActivateFn(
        {
          scrollY,
          topOffset,
          staticElementTop,
        },
      );
    }

    return scrollY > staticElementTop + topOffset;
  };

  updateStaticPos = () => {
    const {nodeRef} = this;
    if (!nodeRef)
      return;

    this.initialStaticPos = getFixedElementPos(nodeRef);
    this.onScroll();
  }

  render() {
    const {initialStaticPos} = this;
    const {compensation, children} = this.props;
    const {
      changeDirectionScrollY,
      sticky, delta,
    } = this.state;

    const style = (
      sticky
        ? {
          position: 'fixed',
          top: 0,
          left: initialStaticPos.left,
          zIndex: 1002,
        }
        : null
    );

    const cloned = React.cloneElement(
      children(
        {
          sticky,
          initialStaticPos,
          style,
          delta,
          changeDirectionScrollY,
        },
      ),
      {
        // React.forwardRef not work correctly with styled() decorator
        // (which supports innerRef instead)
        ref: (node) => {
          this.nodeRef = node;
        },
      },
    );

    return (
      <>
        {cloned}
        {sticky && compensation && (
          <div
            style={{
              width: 1,
              height: initialStaticPos.height,
              maxWidth: '100%',
            }}
          />
        )}
      </>
    );
  }
}
