import PropTypes from 'prop-types';

import {
  ID_SCHEMA,
  LIST_ITEM_SCHEMA,
} from '@adretail/schemas';

export const COORDS_SCHEMA = PropTypes.shape(
  {
    lat: PropTypes.number,
    lon: PropTypes.number,
  },
);

export const ADDRESS_SCHEMA = PropTypes.shape(
  {
    coords: COORDS_SCHEMA,
    city: LIST_ITEM_SCHEMA,
    street: LIST_ITEM_SCHEMA,
    province: LIST_ITEM_SCHEMA,
    streetNo: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  },
);

export const MARKER_SCHEMA = PropTypes.shape(
  {
    id: ID_SCHEMA.isRequired,
    address: ADDRESS_SCHEMA,
  },
);

export const CITY_SCHEMA = PropTypes.shape(
  {
    id: ID_SCHEMA.isRequired,
    name: PropTypes.string,
    image: PropTypes.string,
    address: PropTypes.shape(
      {
        postcodes: PropTypes.arrayOf(LIST_ITEM_SCHEMA),
        coords: COORDS_SCHEMA,
        province: LIST_ITEM_SCHEMA,
      },
    ),
  },
);
