import React from 'react';
import createIcon from './utils/createIcon';

export default createIcon(
  'Expand',
  {
    viewBox: '0 0 11 6',
  },
  <path d='M10.77.22a.78.78,0,0,0-1.08,0l-4.19,4L1.31.22A.78.78,0,0,0,.23.22a.7.7,0,0,0,0,1L5,5.78A.77.77,0,0,0,6,5.78l4.73-4.52A.7.7,0,0,0,10.77.22Z' />,
);
