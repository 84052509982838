import {parameterize} from '@ding/core/src/helpers';

import createLinkComponent, {optionalLinkSegment} from '../../utils/createLinkComponent';

export const ARTICLES_URL_SCHEMA = [
  '/blog/:categoryName,:categoryId(\\d+)/:page(\\d+)?',
  '/blog/:page(\\d+)?',
];

export const genArticlesURL = ({page, category} = {}) => (
  `/blog${optionalLinkSegment(category && `${parameterize(category.name)},${category.id}`)}${optionalLinkSegment(page)}`
);

export default createLinkComponent(
  [
    'page',
    'category',
  ],
  (props, params) => genArticlesURL(params),
);
