import React from 'react';
import PropTypes from 'prop-types';

import formatDate from '@adretail/basic-helpers/src/time/formatDate';
import genLeafletPdfURL from '@ding/slide-viewer/src/Viewer/containers/Desktop/ContentItemsToolbar/Items/PdfButton/genLeafletPdfURL';

import useTranslate from '@adretail/i18n/src/hooks/useTranslate';

import LeafletLink from '@ding/layout/src/components/Links/LeafletLink';
import {
  Margin,
  Text,
  UndecoratedLink,
} from '@ding/core/src/components/Predefined';

const TextLeafletCard = ({leaflet, pdfLink}) => {
  const {duration, name} = leaflet;
  const t = useTranslate('website.leaflets.card');
  const content = (
    <>
      <Margin bottom={1}>
        <Text
          weight={700}
          size='medium'
        >
          {name}
        </Text>
      </Margin>

      <Text.Muted
        size='small'
        block
      >
        {t(
          'duration',
          {
            begin: formatDate(duration.begin),
            end: formatDate(duration.end),
          },
        )}
      </Text.Muted>
    </>
  );

  return (
    pdfLink
      ? (
        <UndecoratedLink
          plainAnchor
          to={
            genLeafletPdfURL(leaflet)
          }
          title={
            t('download_leaflet_pdf')
          }
          target='_blank'
          rel='noreferrer'
        >
          {content}
        </UndecoratedLink>
      )
      : (
        <LeafletLink leaflet={leaflet}>
          {content}
        </LeafletLink>
      )
  );
};

TextLeafletCard.displayName = 'TextLeafletCard';

TextLeafletCard.propTypes = {
  leaflet: PropTypes.any.isRequired,
  pdfLink: PropTypes.bool,
};

export default TextLeafletCard;
