import React from 'react';

import {GeolocationModalTrigger} from '@ding/geolocation/src/client/components';
import {trackComponent} from '@ding/interia';
import {HeaderItem} from '../../HeaderToolbar';

const GeolocationItem = ({margin, subtitle, onToggle}) => (
  <HeaderItem
    subtitle={subtitle}
    margin={margin}
  >
    <GeolocationModalTrigger
      withTitle={false}
      onToggle={onToggle}
    />
  </HeaderItem>
);

export default trackComponent(
  {
    onToggle({ga4}) {
      ga4.menuClickLocalization();
    },
  },
)(GeolocationItem);
