import PropTypes from 'prop-types';

import styled from '@adretail/fast-stylesheet/src/react/styled';
import NextGenPicture from '@ding/core/src/components/Parts/Images/NextGenPicture';

export const logoBarStyles = {
  display: 'block',
  height: 36,
  padding: [2, 0],
  margin: '12px auto',

  // TODO: BUG: because we using NextGenPicture this is always true but should be true only when `title` `showDate` `showTitles` is true, even after fix this will not work because of archived layer variant
  // TODO: BUG: this stop working 3 year ago
  // TODO: because of legacy creep component need rewrite to new leafletCard
  '&:last-child': {
    margin: '5px auto',
  },
};

const ContractorLogoBar = styled(
  NextGenPicture,
  {
    base: {
      extend: logoBarStyles,
      width: 100,
      objectFit: 'contain',
    },

    'size-tiny': {
      height: 24,
      width: '100%',
    },
  },
  {
    index: 2,
    loading: 'lazy',
    omitProps: ['size'],
    classSelector: (classes, {size}) => [
      size && classes[`size-${size}`],
    ],
  },
);

ContractorLogoBar.displayName = 'ContractorLogoBar';

ContractorLogoBar.propTypes = {
  size: PropTypes.string,
};

export default ContractorLogoBar;
