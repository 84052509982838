import styled from '@adretail/fast-stylesheet/src/react/styled';
import {PAGE_BACKGROUND} from '@ding/constants/src/colorSchema';

const LayoutHolder = styled(
  'main',
  {
    display: 'flow-root',
    background: PAGE_BACKGROUND,
    width: 'inherit',
    height: 'inherit',
    minHeight: 'inherit',
  },
);

LayoutHolder.displayName = 'LayoutHolder';

export default LayoutHolder;
