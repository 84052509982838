import React from 'react';
import {useRouteMatch} from 'react-router-dom';

import BlogIcon from '@ding/core/src/icons/bottom-bar/Blog';
import ArticlesLink, {ARTICLES_URL_SCHEMA} from '@ding/layout/src/components/Links/ArticlesLink';
import BottomBarButton from '../BottomBarButton';

const BlogItem = ({subtitle, onClick}) => {
  const match = useRouteMatch(ARTICLES_URL_SCHEMA);

  return (
    <BottomBarButton
      active={!!match?.isExact}
      component={ArticlesLink}
      subtitle={subtitle.name}
      title={subtitle.title}
      onClick={onClick}
    >
      <BlogIcon size='medium' />
    </BottomBarButton>
  );
};

BlogItem.displayName = 'BlogItem';

export default BlogItem;
