import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {ID_SCHEMA} from '@adretail/schemas';

import env from '@cdd/internal-server/src/constants/env';
import * as idleCallback from '@adretail/basic-helpers/src/async/idleCallback';

import {promiseInjectScript} from '@adretail/basic-helpers/src/async/injectScript';
import asyncOnceFork from '../helper/asyncOnceFork';

const googleOAuthEnv = env.client?.google?.oauth;

const isGoogleApiLoaded = () => !!window.google?.__googleApiResolved;

const asyncLoadGoogleAPI = asyncOnceFork(() => {
  if (isGoogleApiLoaded())
    return window.google;

  // exec if not already loaded
  return new Promise(
    async (resolve, reject) => {
      try {
        await promiseInjectScript('https://accounts.google.com/gsi/client');
        window.google.__googleApiResolved = true;
        resolve(window.google);
      } catch (e) {
        reject(e);
      }
    },
  );
});

export default class GoogleApiConsumer extends React.Component {
  static propTypes = {
    clientId: ID_SCHEMA,
    scope: PropTypes.string,
    loadOnMount: PropTypes.bool,
  };

  static defaultProps = {
    scope: 'profile email',
    loadOnMount: true,

    ...R.pick(
      ['clientId'],
      googleOAuthEnv || {},
    ),
  };

  state = {
    error: false,
    loading: !isGoogleApiLoaded(),
  };

  componentDidMount() {
    const {loadOnMount} = this.props;
    const {loading} = this.state;

    if (loadOnMount && loading)
      idleCallback.create(::this.asyncLoadScript);
  }

  /**
   * @description Wrapper which returns function,
   * used to initialize log in with google account popup
   * @param {Object} config Fields passed to initTokenClient
   * @see https://developers.google.com/identity/protocols/oauth2/javascript-implicit-flow
   * @see https://developers.google.com/identity/oauth2/web/reference/js-reference
   * @returns Promise, resolved when user authorize account
   * and rejected when error occurs or user closed popup
   */
  loginUser = ({clientId, ...config}) => () => new Promise(
    async (resolve, reject) => {
      const googleApi = await this.asyncLoadScript();
      const client = googleApi.accounts.oauth2.initTokenClient({
        client_id: clientId,
        callback: tokenResponse => (
          !tokenResponse.error
            ? resolve(tokenResponse)
            : reject(tokenResponse)
        ),
        error_callback: reason => reject(reason),
        ...config,
      });
      client.requestAccessToken();
    },
  );

  /**
   * @description Inject Google Sign In library
   * @returns Google API object
   */
  asyncLoadScript = async () => {
    if (isGoogleApiLoaded())
      return window.google;

    try {
      const handler = await asyncLoadGoogleAPI();
      this.setState(
        {
          loading: false,
          error: false,
        },
      );

      return handler;
    } catch (e) {
      console.error(e);
      this.setState(
        {
          loading: false,
          error: true,
        },
      );
    }

    return null;
  }

  render() {
    const {error, loading} = this.state;
    const {children} = this.props;

    return children(
      {
        error,
        loading,
        asyncLoadScript: this.asyncLoadScript,
        loginUser: this.loginUser(
          R.pick(
            ['clientId', 'scope'],
            this.props,
          ),
        ),
      },
    );
  }
}
