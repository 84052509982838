import {gql, GraphQLFragment} from '@ding/tiny-gql';

import provideProps from '@adretail/basic-decorators/src/base/provideProps';
import useQuery from '@ding/tiny-gql/src/react/hooks/useQuery';

import TinyGqlQuery, {FETCH_POLICY} from '@ding/tiny-gql/src/react/TinyGqlQuery';

import CustomerFragment from '../fragments/Customer.graphql';
import CustomerSettingsFragment from '../fragments/CustomerSettings.graphql';

export const CUSTOMER_ACCOUNT_QUERY = new GraphQLFragment(
  'customerQuery',
  gql`
    ${CustomerFragment}
    ${CustomerSettingsFragment}
    query customerQuery(
      $withBasicInfo: Boolean = true,
      $withSettings: Boolean = false
    ) @locale(lang: ${APP_LANGUAGE}) {
      customer {
        ... @include(if: $withBasicInfo) {
          ...Customer
        }

        settings @include(if: $withSettings) {
          ...CustomerSettings
        }
      }
    }
  `,
);

export const useCustomerAccountQuery = flags => useQuery(
  CUSTOMER_ACCOUNT_QUERY,
  {
    responseSelector: 'customer',
    ...flags,
  },
);

const AccountDescriptionQuery = provideProps(
  {
    query: CUSTOMER_ACCOUNT_QUERY,
    responseSelector: 'customer',
    clientFetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  },
)(TinyGqlQuery);

AccountDescriptionQuery.displayName = 'AccountDescriptionQuery';

export default AccountDescriptionQuery;
