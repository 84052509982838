import PropTypes from 'prop-types';

import {
  ID_SCHEMA,
  DURATION_SCHEMA,
} from '@adretail/schemas';

import {
  RATING_SCHEMA,
  ITEM_TYPE_SCHEMA,
} from './basic';

import {ADDRESS_SCHEMA} from './geo';

export const TAG_SCHEMA = PropTypes.shape(
  {
    id: ID_SCHEMA,
    kind: ITEM_TYPE_SCHEMA,
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  },
);

export const CONTRACTOR_SCHEMA = PropTypes.shape(
  {
    id: ID_SCHEMA.isRequired,
    kind: ITEM_TYPE_SCHEMA,
    name: PropTypes.string,
    logo: PropTypes.string,
    description: PropTypes.string,
    rating: RATING_SCHEMA,
  },
);

export const STORE_SCHEMA = PropTypes.shape(
  {
    id: ID_SCHEMA.isRequired,
    contractor: CONTRACTOR_SCHEMA.isRequired,
    address: ADDRESS_SCHEMA,
  },
);

export const PRODUCT_TEMPLATE_SCHEMA = PropTypes.shape(
  {
    name: PropTypes.string,
    attributes: PropTypes.arrayOf(
      {
        name: PropTypes.string,
        label: PropTypes.string,
        items: PropTypes.any,
      },
    ),
  },
);

export const PRODUCT_SCHEMA = PropTypes.shape(
  {
    id: ID_SCHEMA.isRequired,
    kind: ITEM_TYPE_SCHEMA,
    price: PropTypes.number,
    oldPrice: PropTypes.number,
    image: PropTypes.string,
    contractor: CONTRACTOR_SCHEMA,
    description: PropTypes.string,
    archived: PropTypes.bool,
    templateProduct: PRODUCT_TEMPLATE_SCHEMA,
  },
);

export const LEAFLET_CARD_SCHEMA = PropTypes.shape(
  {
    id: ID_SCHEMA.isRequired,
    kind: ITEM_TYPE_SCHEMA,
    name: PropTypes.string,
    cover: PropTypes.string,
    contractor: CONTRACTOR_SCHEMA,
    duration: DURATION_SCHEMA,
    coverOnlyForAdults: PropTypes.bool,
    archived: PropTypes.bool,
    promoted: PropTypes.bool, // ribbon

    // page on which leaflet is redirecting by default
    originPage: PropTypes.shape(
      {
        id: ID_SCHEMA,
        index: PropTypes.number.isRequired, // starts from 0
      },
    ),
  },
);
