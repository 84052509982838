import PropTypes from 'prop-types';

import styled from '@adretail/fast-stylesheet/src/react/styled';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/schemas/basic';

const PlainUnorderedList = styled.ul(
  {
    base: {
      listStyleType: 'none',
      padding: 0,
      margin: 0,

      '& > li': {
        padding: 0,
      },
    },

    disc: {
      listStyleType: 'disc',
      listStylePosition: 'inside',
    },

    'space-small': {
      '& > li': {
        margin: 5,
      },
    },

    'space-medium': {
      '& > li': {
        margin: 8,
      },
    },

    'space-big': {
      '& > li': {
        margin: 12,
      },
    },

    'space-large': {
      '& > li': {
        margin: 16,
      },
    },

    inline: {
      display: 'flex',

      '& > li': {
        display: 'inline-flex',
        marginTop: 0,
        marginBottom: 0,

        '&:first-child': {
          marginLeft: 0,
        },

        '&:last-child': {
          marginRight: 0,
        },
      },
    },

    vertical: {
      '& > li': {
        marginLeft: 0,
        marginRight: 0,
      },
    },

    expanded: {
      width: '100%',
    },

    wrap: {
      flexWrap: 'wrap',
    },

    'justify-right': {
      justifyContent: 'flex-end',
    },

    'align-center': {
      alignItems: 'center',
    },
  },
  {
    index: 0,
    omitProps: [
      'inline',
      'type',
      'size',
      'itemSpace',
      'expanded',
      'wrap',
      'justify',
    ],
    classSelector: (classes, {expanded, type, itemSpace, inline, wrap, justify, align}) => [
      expanded && classes.expanded,
      classes[inline ? 'inline' : 'vertical'],
      type && classes[type],
      itemSpace && classes[`space-${itemSpace}`],
      justify && classes[`justify-${justify}`],
      align && classes[`align-${align}`],
      wrap && classes.wrap,
    ],
  },
);

PlainUnorderedList.displayName = 'PlainUnorderedList';

PlainUnorderedList.propTypes = {
  wrap: PropTypes.bool,
  inline: PropTypes.bool,
  expanded: PropTypes.bool,
  itemSpace: SIZE_ARRAY_SCHEMA,
  type: PropTypes.oneOf([
    'disc',
    null,
  ]),
};

export default PlainUnorderedList;
