import styled from '@adretail/fast-stylesheet/src/react/styled';
import PropTypes from 'prop-types';

import {SIZE_ARRAY_SCHEMA} from '@ding/constants/src/schemas/basic';
import {DIVIDER_GRAY} from '@ding/constants/src/colorSchema';

const Divider = styled(
  'hr',
  {
    base: {
      display: 'inline-block',
      border: 0,
      background: DIVIDER_GRAY,
    },

    'space-small': {
      margin: 15,
    },

    'space-medium': {
      margin: 35,
    },

    'space-big': {
      margin: 40,
    },

    'space-none': {
      margin: 0,
    },

    horizontal: {
      width: '100%',
      height: 1,
      marginLeft: 0,
      marginRight: 0,
    },

    vertical: {
      width: 1,
      height: '100%',
      marginTop: 0,
      marginBottom: 0,
    },
  },
  {
    omitProps: ['space', 'horizontal'],
    classSelector: (classes, {horizontal, space}) => {
      const direction = horizontal ? 'horizontal' : 'vertical';

      return [
        space && classes[`space-${space}`],
        classes[direction],
      ];
    },
  },
);

Divider.displayName = 'Divider';

Divider.propTypes = {
  space: SIZE_ARRAY_SCHEMA,
  horizontal: PropTypes.bool,
};

Divider.defaultProps = {
  space: 'small',
  horizontal: true,
};

export default Divider;
