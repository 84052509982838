import React from 'react';
import {gql, GraphQLFragment} from '@ding/tiny-gql';

import formatDate from '@adretail/basic-helpers/src/time/formatDate';
import createAuthorizationHeaders from '@ding/auth/src/helpers/createAuthorizationHeaders';

import TinyGqlMutation from '@ding/tiny-gql/src/react/TinyGqlMutation';
import useRefetchTokenSetter from './utils/useRefetchTokenSetter';

const DESTROY_CUSTOMER_FAVOURITE = new GraphQLFragment(
  'createCustomerMutation',
  gql`
    mutation createCustomerMutation(
      $info: CustomerInfo!,
      $recaptcha: Recaptcha!
    ) {
      createCustomer(input: {
        info: $info,
        recaptcha: $recaptcha
      }) {
        errors
      }
    }
  `,
);

const toMutationInput = ({transferGuestData, age, agreement, recaptcha}) => ({
  recaptcha,
  info: {
    transferGuestData,
    birthDate: formatDate(age?.date),
    marketingTerms: !!agreement?.rodo,
  },
});

const RegisterMutation = ({children, ...props}) => {
  const {authState, refreshToken} = useRefetchTokenSetter();

  return (
    <TinyGqlMutation
      mutation={DESTROY_CUSTOMER_FAVOURITE}
      {...props}
    >
      {(mutate, utils) => {
        const registerUser = async (formValue) => {
          // register user
          await mutate(
            toMutationInput(formValue),
            createAuthorizationHeaders(
              authState.getToken(),
              formValue.auth, // {login, password}
            ),
          );

          // if register succeed - refresh token
          await refreshToken();
        };

        return children(registerUser, utils);
      }}
    </TinyGqlMutation>
  );
};

RegisterMutation.displayName = 'RegisterMutation';

export default RegisterMutation;
