import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  LIST_ITEM_SCHEMA,
  PROPS_SCHEMA,
  REACT_COMPONENT_CLASS_SCHEMA,
} from '@adretail/schemas';

import {castToBoolean} from '@ding/core/src/helpers';

import linkedInputs from '@adretail/basic-decorators/src/inputs/linkedInputs';
import CachedFunctionalRender from '@adretail/basic-components/src/Functional/CachedFunctionalRender';

import {AbstractComponentsList} from '@ding/core/src/components/Parts';
import {PlainUnorderedList} from '@ding/core/src/components/Predefined';

import TitledCheckbox from '../CheckBox/TitledCheckbox';
import CheckBoxListItem from './CheckBoxListItem';

export {
  CheckBoxListItem,
};

const renderCheckBoxFunctionalList = ({
  value,
  children,
  itemValueSelector,
  onChangeItem,
}) => {
  const linkCheckBox = item => ({
    value: itemValueSelector(value[item.id]),
    onChange: onChangeItem(item),
  });

  const childs = children(linkCheckBox);
  const mapperFn = (child) => {
    const {name} = child.props;

    return (
      <CheckBoxListItem>
        {(
          name
            ? React.cloneElement(child, linkCheckBox({id: name}))
            : child
        )}
      </CheckBoxListItem>
    );
  };

  return (
    <PlainUnorderedList>
      {React.Children.map(
        childs,
        mapperFn,
      )}
    </PlainUnorderedList>
  );
};

export default
@linkedInputs('value', true)
class CheckBoxList extends React.PureComponent {
  static propTypes = {
    singleValueAtOnce: PropTypes.bool,
    assignItemInfoToValue: PropTypes.bool, // if true - appends whole item as item value to assoc

    checkboxComponent: REACT_COMPONENT_CLASS_SCHEMA,
    list: PropTypes.arrayOf(LIST_ITEM_SCHEMA),
    itemProps: PROPS_SCHEMA,
    itemTitleRenderFn: PropTypes.func,
    itemValueSelector: PropTypes.func,
  };

  static defaultProps = {
    assignItemInfoToValue: true,

    checkboxComponent: TitledCheckbox,
    itemTitleRenderFn: R.prop('name'),
    itemValueSelector: castToBoolean,
    itemProps: {
      type: 'square',
    },
  };

  onChangeItem = R.memoizeWith(
    (item, index) => `${item.id}-${index}`,
    (item, index = null) => (inputValue) => {
      const {
        l,
        value,
        singleValueAtOnce,
        assignItemInfoToValue,
      } = this.props;

      // if checkbox returns something other than bool
      // assign it instead whole item, it is used in some
      // user settings page components
      const {id} = item;
      const itemValue = castToBoolean(inputValue) && (
        R.is(Boolean, inputValue)
          // assigns true if assignItemInfoToValue = false
          // assigns item info if assignItemInfoToValue = true
          ? (!assignItemInfoToValue || {index, appendDate: new Date(), ...item})
          : inputValue
      );

      let newValue = null;
      if (singleValueAtOnce) {
        newValue = (
          itemValue
            ? {[item.id]: itemValue}
            : {}
        );
      } else {
        newValue = (
          itemValue
            ? R.assoc(id, itemValue, value)
            : R.omit([id], value)
        );
      }

      return l.onGroupEvent(newValue, false);
    },
  );

  render() {
    const {
      l, value, singleValueAtOnce,
      list,
      itemTitleRenderFn,
      itemValueSelector,
      checkboxComponent: CheckBoxComponent,
      children,
      ...props
    } = this.props;

    let content = list && (
      <AbstractComponentsList
        {...props}
        list={list}
        itemRenderFn={
          (itemProps, item, index) => (
            <CachedFunctionalRender
              key={itemProps.key}
              cacheKey={
                `${item.id}-${!!value[item.id]}`
              }
            >
              {() => (
                <CheckBoxListItem>
                  <CheckBoxComponent
                    {...itemProps}
                    value={
                      itemValueSelector(value[item.id])
                    }
                    onChange={
                      this.onChangeItem(item, index)
                    }
                  >
                    {itemTitleRenderFn(item)}
                  </CheckBoxComponent>
                </CheckBoxListItem>
              )}
            </CachedFunctionalRender>
          )
        }
      />
    );

    if (children) {
      content = (
        <>
          {content}
          {renderCheckBoxFunctionalList(
            {
              value,
              children,
              itemValueSelector,
              onChangeItem: this.onChangeItem,
            },
          )}
        </>
      );
    }

    return content;
  }
}
