import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import {
  PROPS_SCHEMA,
  REACT_COMPONENT_CLASS_SCHEMA,
} from '@adretail/schemas';

import {
  WHITE,
  DARK,
  CARD_GRAY,
  LIGHT_BORDER,
  DARK_BORDER,
} from '@ding/constants/src/colorSchema';

import renderFunctionalChildren from '@adretail/basic-helpers/src/ui/renderFunctionalChildren';

import {createBreakpointClasses} from '@ding/core/src/helpers/createBreakpoints';
import injectClassesSheet from '@adretail/fast-stylesheet/src/react/decorators/injectClassesStylesheet';

import TitledSidebarHeader from './TitledSidebarHeader';

const css = {
  holder: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    zIndex: 99,
    overflow: 'auto',
  },

  content: {
    height: '100%',
    maxHeight: 'calc(100% - 50px)',
    overflowY: 'auto',
    flexGrow: 1,
  },

  relativeContent: {
    position: 'relative',
  },

  'padding-huge': createBreakpointClasses({
    xs: {
      padding: 20,
    },
    md: {
      padding: 30,
    },
  }),

  'padding-big': createBreakpointClasses({
    xs: {
      padding: 10,
    },
    md: {
      padding: 20,
    },
  }),

  'padding-medium': createBreakpointClasses({
    xs: {
      padding: 5,
    },
    md: {
      padding: 10,
    },
  }),

  'padding-small': {
    padding: 2,
  },

  'padding-none': {
    padding: 0,
  },

  noVerticalPadding: {
    paddingTop: [0, '!important'],
    paddingBottom: [0, '!important'],
  },

  'fill-gray': {
    background: CARD_GRAY,
  },

  light: {
    background: WHITE,
    color: DARK,

    '& ::-webkit-scrollbar-track': {
      background: LIGHT_BORDER,
    },
  },

  dark: {
    background: DARK,
    color: WHITE,

    '& ::-webkit-scrollbar-track': {
      background: DARK_BORDER,
    },
  },

  none: {
    background: 'none',
    color: 'inherit',
    borderColor: 'inherit',
  },
};

const TitledSidebar = ({
  title,
  relativeContent,
  header,
  classes,
  headerToolbar,
  rightTitlebarToolbar,
  leftTopHeaderButtons,
  headerProps,
  closeButtonProps,
  closeButtonComponent,
  type,
  className, style,
  styleChildrenContainer,
  noTitleStyles,
  noVerticalPadding,
  fill, padding, children,
  onClose,
  onWheel,
  onClick,
  onMouseDown,
}) => {
  const headerRow = renderFunctionalChildren(header, onClose) || (
    <TitledSidebarHeader
      {...headerProps}
      {...{
        closeButtonProps,
        closeButtonComponent,
        leftTopHeaderButtons,
        type,
        noTitleStyles,
        onClose,
      }}
      rightToolbar={rightTitlebarToolbar}
    >
      {title}
    </TitledSidebarHeader>
  );

  return (
    <div
      className={c(
        classes.holder,
        classes[type],
        className,
      )}
      {...{
        style,
        onWheel,
        onClick,
        onMouseDown,
      }}
    >
      {headerRow}

      {renderFunctionalChildren(headerToolbar, onClose)}

      <div
        className={c(
          classes.content,
          fill && classes[`fill-${fill}`],
          padding && classes[`padding-${padding}`],
          noVerticalPadding && classes.noVerticalPadding,
          relativeContent && classes.relativeContent,
        )}
        style={styleChildrenContainer}
      >
        {renderFunctionalChildren(children, onClose)}
      </div>
    </div>
  );
};

TitledSidebar.displayName = 'TitledSidebar';

TitledSidebar.propTypes = {
  title: PropTypes.node,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),

  noTitleStyles: PropTypes.bool, // reset padding etc in header
  noVerticalPadding: PropTypes.bool, // reset top and bototm padding, used in notifications

  closeButtonProps: PROPS_SCHEMA,
  closeButtonComponent: REACT_COMPONENT_CLASS_SCHEMA,

  headerProps: PROPS_SCHEMA,
  headerToolbar: PropTypes.any, // for mobile menu
  leftTopHeaderButtons: PropTypes.node, // left button before title
  padding: PropTypes.oneOf([
    'small',
    'medium',
    'big',
    'huge',
    'none',
    null,
  ]),
  fill: PropTypes.oneOf([
    'gray',
    'transparent',
  ]),
  type: PropTypes.string,

  onClose: PropTypes.func, // if not provided times icon is not shown
};

TitledSidebar.defaultProps = {
  padding: 'big',
  type: 'light',
};

export default injectClassesSheet(css)(TitledSidebar);
