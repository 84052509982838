import {parameterize} from '@ding/core/src/helpers';

import React, {useMemo, useEffect} from 'react';
import {concat} from 'ramda';
import PropTypes from 'prop-types';

import {useUA} from '@adretail/basic-components/src/Context/UAProvider';
import {useCookies} from '@adretail/basic-components/src/Context/SSRCookiesProvider';

import useReactRouter from '@ding/core/src/hooks/useReactRouter';
import {
  prefixWithRootPrefix,
  prefixWithRootDomain,
} from '@ding/layout/src/components/Links/utils/prefixWithRootAddress';

import * as Ads from '@adretail/ads';
import {AdsProvider} from '@adretail/react-ads';

export const ADS_CONTEXT_CONFIG = PropTypes.shape(
  {
    basePageURL: PropTypes.string,
    bootstrapMode: PropTypes.number,
  },
);

export const getContractorKeywords = contractor => contractor && ({
  Adretail_ID: contractor.id,
  Adretail_KS: `ks_${parameterize(contractor.category?.name || '')}`,
  Adretail_siec: `siec_${parameterize(contractor.name)}`,
});

export const getLeafletKeywords = leaflet => leaflet && ({
  ...getContractorKeywords(leaflet.contractor),
  Adretail_ID: leaflet.id,
  Adretail_KG: `kg_${parameterize(leaflet.mainCategory?.name || '')}`,
});

const AdProvider = ({
  contextAdConfig, slotsDescription, children,
  lazyLoad, contextConfigResetKey,
  pauseBootstraping, area, keywords, rootDomain, excludePath,
}) => {
  const ua = useUA();
  const cookies = useCookies();
  const {pathname, search} = useReactRouter().location;

  let absoluteAddress = excludePath ? '' : pathname;

  if (absoluteAddress === '/')
    absoluteAddress = concat(absoluteAddress, '*');

  if (rootDomain)
    absoluteAddress = prefixWithRootPrefix(rootDomain)(absoluteAddress);
  else
    absoluteAddress = prefixWithRootDomain(absoluteAddress);

  const cachedConfig = useMemo(
    () => !lazyLoad && ({
      ...Ads.Vendors.Interia.getDefaultInteriaContextConfig(
        {
          mobile: ua.mobile,
          observeSlotHolderSelector: '.inner-box',
          bootstrapMode: Ads.BootstrapMode.MANUAL,
          basePageURL: absoluteAddress,
          netsprint: {
            atmScript: 'https://atm.api.dmp.nsaudience.pl/atm.js?sourceId=ding.pl',
            parserScript: 'https://interia.api.dmp.nsaudience.pl/frontend/api/custom.js?sourceId=ding_static',
          },
        },
      ),
      ...contextAdConfig,
    }),
    [
      lazyLoad,
      contextConfigResetKey,
      contextAdConfig,
      ua,
    ],
  );

  if (cachedConfig) {
    cachedConfig.slotsDescriptionMergeConfig = {
      DFP: {
        keywords: {
          ...keywords,
          ...area && {
            struktura: area,
          },
        },
      },
    };
  }

  // import uanc style only when panel is active is active
  useEffect(() => {
    if (cachedConfig.services.debugger?.config) {
      const {cookieConfig, urlActivateRegex} = cachedConfig.services.debugger.config;
      const activatedByCookie = cookies.get(cookieConfig.name) === cookieConfig.toggledValue;

      if (search.match(urlActivateRegex) || activatedByCookie) {
        import(/* webpackChunkName: "ad-style-uanc" */ '@adretail/ads/dist/es/index.css');
      }
    }
  }, [search, cachedConfig, cookies]);

  return (
    <AdsProvider
      pauseBootstraping={pauseBootstraping}
      contextConfigResetKey={contextConfigResetKey}
      lazyLoad={lazyLoad}
      config={cachedConfig}
      slotsDescription={slotsDescription}
    >
      {children}
    </AdsProvider>
  );
};

export default AdProvider;
