import React from 'react';

import withESI from '@ding/server/src/cache/withESI';

import OptionalGQLSection from '@ding/control-groups/src/Section/gql/OptionalGQLSection';
import {ContractorsQuery} from '@ding/api/src/gql/contractorsQuery';

import {ContractorsGrid} from '../Grid';

const OptionalContractorsGridSection = ({t, queryProps, ...props}) => (
  <OptionalGQLSection
    queryComponent={ContractorsQuery}
    queryProps={{
      ...queryProps,
      variables: {
        limit: 12,
        ...queryProps?.variables,
      },
    }}
    gridComponent={ContractorsGrid}
    {...props}
  />
);

OptionalContractorsGridSection.displayName = 'OptionalContractorsGridSection';

export default withESI(OptionalContractorsGridSection);
